import { useEffect, useState } from "react";
import { HeaderComponent } from "../Common";
import activeSetting from "../../assets/icons/activeIcons/activeSettings.svg";
import { Menu, Row, Col } from "antd";
import General from "./components/general";
import { AllowanceNew } from "./components";
// import Allowances from "./components/allowances";
import Designation from "./components/designation";
import Groups from "./components/groups";
import Status from "./components/status";
import Department from "./components/department";
import { connect } from "react-redux";
import organisation from "../../redux/modules/organisation";
import Payout from "./components/payout";
import Access from "./components/access/access";
import Location from "./components/location/location";
import Freeze from "./components/freeze";
import { updateEmployerOnBoarding } from "../../apis/organisation";
import { useParams } from "react-router-dom";
import axios from "../../apis"


const { SubMenu } = Menu;

const TabPanes = [
    {
        name: "General",
        key: 0,
        hasSubMenu: false
    },
    // {
    //     name: "Allowance",
    //     key: 111,
    //     hasSubMenu: true,
    //     subMenu: [
    //         {
    //             name: "Allowances",
    //             key: 1,
    //         },
    //         {
    //             name: "Groups",
    //             key: 2,
    //         },
    //     ]
    // },
    {
        name: "Allowance",
        key: 111,
        hasSubMenu: true,
        subMenu: [
            {
                name: "Allowance",
                key: 1,
            },
            {
                name: "Group",
                key: 2,
            },
        ]
    },
    {
        name: "Access",
        key: 3,
        hasSubMenu: false
    },
    {
        name: "Designation",
        key: 4,
        hasSubMenu: false
    },
    {
        name: "Status",
        key: 5,
        hasSubMenu: false
    },
    {
        name: "Department",
        key: 6,
        hasSubMenu: false
    },
    {
        name: "Payout",
        key: 7,
        hasSubMenu: false
    },
    {
        name: "Location",
        key: 8,
        hasSubMenu: false
    },
    {
        name: "Freeze",
        key: 222,
        hasSubMenu: true,
        subMenu: [
            {
                name: "Allowance",
                key: 9,
            },
            {
                name: "Declarations",
                key: 10,
            },
        ]
    },
    
]

const OrganisationSettingV2 = (props) => {
  const [currentSelectedTab, setCurrentSelectedTab] = useState("0");
  let { path } = useParams();

  useEffect(() => {
    if (parseInt(path)) {
      setCurrentSelectedTab(path);
    }
  }, [path]);
  let componentProps = {
    employeeList: props.employeeList,
    organisationReducer: props.organisationReducer,
    user: props.user,
    deviceWidth: props.deviceWidth,
    organisationSettings: props.organisationSettings,
    orgLoading: props.orgLoading,
    groups: props.groups,
    organisationFreeze: props.organisationFreeze,
    complainceCredentials: props.complainceCredentials
  };
  const updateOnboarding = async (type) => {
    const { onboarding, organisationReducer, user } = props;
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployerOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id
      });
      organisationReducer.updateEmployerOnBoarding({
        organisationId: user.organisationId,
        id: user._id
      });
    }
  };
  
  const handleAction = async (url, type) => {
    let res = await axios({
      url, //your url
      method: 'GET',
      responseType: 'blob',
    })
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = urlDownload;
    link.setAttribute('download', `${type}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  const componentArray = [
    <General {...componentProps} />,
    <AllowanceNew {...componentProps} />,
    // <Allowances {...componentProps} />,
    <Groups {...componentProps} updateOnboarding={updateOnboarding} />,
    <Access {...componentProps} />,
    <Designation {...componentProps} updateOnboarding={updateOnboarding} handleAction={handleAction} />,
    <Status {...componentProps} updateOnboarding={updateOnboarding} handleAction={handleAction} />,
    <Department {...componentProps} updateOnboarding={updateOnboarding} handleAction={handleAction} />,
    <Payout {...componentProps} />,
    <Location {...componentProps} updateOnboarding={updateOnboarding} />,
    <Freeze type={0} {...componentProps} />,
    <Freeze type={1} {...componentProps} />,
  ];

  useEffect(() => {
    document.title = 'Organization | Settings';
  });
  return (
    <div className="site-layout">
      <HeaderComponent
        show={false}
        showMoolCoins={true}
        icon={activeSetting}
        name="Configuration"
      />
      <div className="app-main-content-container paper-wrapper">
        <Row className="blue-menu-container">
          <Col xs={4} md={4} lg={4} xl={4}>
            <Menu
              style={{
                marginRight: 16,
                height: "100%"
              }}
              defaultSelectedKeys={[currentSelectedTab]}
              defaultOpenKeys={[currentSelectedTab]}
              selectedKeys={currentSelectedTab}
              mode="inline"
              onClick={(e) => {
                setCurrentSelectedTab(e.key);
              }}
              className=""
            >
              {TabPanes.map((data) => {
                if (!data.hasSubMenu) {
                  return <Menu.Item key={data.key}>{data.name}</Menu.Item>;
                } else {
                  return (
                    <SubMenu key={data.key} title={data.name}>
                      {data.subMenu.map((subData) => {
                        return (
                          <Menu.Item key={subData.key}>
                            {subData.name}
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  );
                }
              })}
            </Menu>
          </Col>
          <Col xs={20} md={20} lg={20} xl={20}>
            <div
              style={{
                marginLeft: 30
              }}
            >
              {componentArray[currentSelectedTab]}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const OrganisationSettingV2Container = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    deviceWidth: state.responsive.deviceWidth,
    organisationSettings: state.organisation.organisationSettings,
    orgLoading: state.organisation.loading,
    groups: state.organisation.groups,
    organisationFreeze: state.organisation.organisationFreeze,
    complainceCredentials: state.organisation.complainceCredentials,
    onboarding: state.organisation.onboarding,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch)
  })
)(OrganisationSettingV2);

export default OrganisationSettingV2Container;
