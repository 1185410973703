import "./fileLogos.less"

const FileLogos = ({ type, padding, margin, block, fontSize, fontWeight, widthHeight }) => {

    let styles = {
        padding: padding ? `${padding[0]}px ${padding[1]}px` : "",
        margin: margin ? `${margin[0]}px ${margin[1]}px` : "",
        display: block ? "block" : "",
        fontSize: fontSize ? fontSize : "",
        fontWeight: fontWeight ? fontWeight : "",
        width: widthHeight ? widthHeight[0] : "",
        height: widthHeight ? widthHeight[1] : "",
    }

    return (
        <div className={`file-logos-type-background file-logos-type-${type}`} styles={styles}>
            <span className={"file-logos-type-text"}>
                {
                    type === 1 || type === "pdf"
                        ? "PDF"
                        : type === 2 || type === "jpg"
                            ? "JPG"
                            : type === 3 || type === "svg"
                                ? "SVG"
                                : type === 4 || type === "png"
                                    ? "PNG"
                                    : type === 5 || type === "doc"
                                        ? "DOC"
                                        : type === 6 || type === "xls"
                                            ? "XLS"
                                            : type === 7 || type === "txt"
                                                ? "TXT"
                                                : ""
                }
            </span>
        </div>
    )
}

export default FileLogos;