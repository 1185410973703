import {
  Spin,
  Table,
  notification,
  Tree,
  Select,
  Typography,
  Modal,
} from "antd";
import React, { useState, useEffect } from "react";
import { NormalButton } from "../../../ComponentLibrary";
import plus from "../../../../assets/icons/whitePlus.svg";
import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { updateAccess } from "../../../../apis/organisation";
import {
  accessLabels,
  defaultUserAccess,
  validateAccess,
} from "../../../../utils/config";
import {
  deleteLogo,
  editLogo,
} from "../../../../assets/common/editAndDeleteLogos";
import organisation from "../../../../redux/modules/organisation";
import { connect } from "react-redux";
import { HeaderComponent } from "../../../Common";
import { createLog } from "../../../../apis/user";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"

const { Option } = Select;
const { Text } = Typography;

const Access = ({ employeeList, deviceWidth, user, isTrial, organisationReducer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeWithoutAccess, setEmployeeWithoutAccess] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pageState, setPageState] = useState(0);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [accessData, setAccessData] = useState([]);
  const [id, setId] = useState(null);
  const [updateOrView, setUpdateOrView] = useState("");
  const [deleteAccessUser, setDeleteAccessUser] = useState({});

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };
  useEffect(() => {
    document.title = 'Settings | Access Management';
  });
  useEffect(() => {
    let empWithAccess = [];
    let empWithoutAccess = [];
    employeeList.forEach((employeeObj) => {
      if (employeeObj.access) {
        let access = employeeObj.access;
        let accesses = "";
        Object.keys(access).forEach((item) => {
          if (Object.keys(access[item].subAccess).length !== 0) {
            let subAccess = "";
            Object.keys(access[item].subAccess).forEach((subItem) => {
              if (access[item].subAccess[subItem].access) {
                if (subAccess === "") {
                  subAccess += `${accessLabels[subItem]}`;
                } else {
                  subAccess += `, ${accessLabels[subItem]}`;
                }
              }
            });
            if (subAccess !== "") {
              if (accesses === "") {
                accesses += `${accessLabels[item]} ( ${subAccess} )`;
              } else {
                accesses += `, ${accessLabels[item]} ( ${subAccess} )`;
              }
            }
          } else if (access[item].access) {
            if (accesses === "") {
              accesses += `${accessLabels[item]}`;
            } else {
              accesses += `, ${accessLabels[item]}`;
            }
          }
        });
        if (accesses === "") {
          empWithoutAccess.push(employeeObj);
        } else {
          employeeObj.accessString = accesses;
          empWithAccess.push(employeeObj);
        }
      } else {
        empWithoutAccess.push(employeeObj);
      }
    });
    setEmployeeData(empWithAccess);
    setEmployeeWithoutAccess(empWithoutAccess);
  }, [employeeList]);

  const generateTreeForUser = (u) => {
    if (u.access && Object.keys(u.access).length > 0) {
      if (validateAccess(u.access)) {
        setTreeData({ ...u.access });
      }
    } else {
      setTreeData({ ...defaultUserAccess });
    }
  };

  const setTreeData = (access) => {
    let checked = [];
    let tree = Object.keys(access).map((acs) => {
      let node = {
        key: acs,
        title: accessLabels[acs],
        icon: <CloseCircleFilled style={{ color: "red" }} />,
        children: [],
      };
      let childrenArray = Object.keys(access[acs].subAccess);
      if (childrenArray.length > 0) {
        let children = childrenArray.map((cacs) => {
          let leaf = {
            key: cacs,
            icon: <CloseCircleFilled style={{ color: "red" }} />,
            title: accessLabels[cacs],
          };

          if (access[acs].subAccess[cacs].access) {
            checked.push(cacs);
            leaf.icon = <CheckCircleFilled style={{ color: "#40d2ac" }} />;
            node.icon = <CheckCircleFilled style={{ color: "#40d2ac" }} />;
          }
          return leaf;
        });
        node.children = children;
      }
      if (access[acs].access) {
        node.icon = <CheckCircleFilled style={{ color: "#40d2ac" }} />;
        checked.push(acs);
      }
      return node;
    });
    setCheckedKeys([...checked]);
    setAccessData([...tree]);
  };

  const updateAccessManage = async () => {
    setIsLoading(true);
    let access = {};
    accessData.forEach((acs) => {
      let accessNode = {
        access: availableInChecked(acs.key),
        subAccess: {},
      };
      if (acs.children.length > 0) {
        let subAccess = {};
        for (const element of acs.children) {
          let leafNode = {
            access: availableInChecked(element.key),
            subAccess: {},
          };
          subAccess[element.key] = leafNode;
        }
        accessNode.subAccess = subAccess;
      }
      access[acs.key] = accessNode;
    });
    let postBody = {
      id,
      access: access,
    };
    let response = await updateAccess(postBody)
    if (response.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has updaed the access control`,
        category: 14,
      });
      organisationReducer.fetchEmployees({
        organisationId: user.organisationId,
      });
      notification.success({
        message: "Successfully added access for this user.",
      });
      setCheckedKeys([]);
      setPageState(0);
    } else {
      notification.error({ message: "Unable to map the supervisor" });
    }
    setIsLoading(false);
  };

  const onDeleteConfirm = async () => {
    setIsLoading(true);
    let postBody = {
      id: deleteAccessUser._id,
      access: defaultUserAccess,
    };
    let response = await updateAccess(postBody);
    if (response.data.success) {
      organisationReducer.fetchEmployees({
        organisationId: user.organisationId,
      });
      notification.success({
        message: `Successfully removed all access permission for ${deleteAccessUser.displayName}.`,
      });
      setDeleteAccessUser({});
      setShowDeleteModal(false);
    } else {
      notification.error({
        message: "Failed to remove access permission, please try again.",
      });
    }
    setIsLoading(false);
  };

  const availableInChecked = (key) => {
    return checkedKeys.some((checked) => checked === key);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: ["currentOrganisationMeta", "employeeId"],
      width: "30%",
    },
    {
      title: "Name",
      dataIndex: "displayName",
      width: "30%",
    },
    {
      title: "Access levels",
      dataIndex: "accessString",
      width: "30%",
      maxWidth: 400,
      render: (accessString) => (
        <Text ellipsis={true} style={{ width: "100%" }}>
          {accessString}
        </Text>
      ),
    },
    {
      title: "",
      dataIndex: "isDefault",
      width: "30%",
      render: (isDefault, rowData) => (
        <div>
          {!isDefault && (
            <React.Fragment>
              <NormalButton
                type={5}
                icon={editLogo}
                margin={[3, 0]}
                onClick={() => {
                  generateTreeForUser(rowData);
                  setUpdateOrView(rowData.displayName);
                  setPageState(1);
                  setId(rowData._id);
                }}
              />
              <NormalButton
                type={5}
                margin={[3, 0]}
                icon={deleteLogo}
                onClick={() => {
                  setDeleteAccessUser(rowData);
                  setShowDeleteModal(true);
                }}
              />
            </React.Fragment>
          )}
        </div>
      ),
    },
  ];
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Configure Mool as per your company policy"} bgImage={configurationsBgImage}
    /> :
    <div className="site-layout">
      <HeaderComponent
        show={false}
        name="Access Management"
      />
      <div className="app-main-content-container paper-wrapper">
        {pageState === 0 && (
          <div>
            <h1 className="h1-bold-24">Access</h1>
            <p className="tab-subtitle-1">
              Add employees to define their access levels for payroll
            </p>
            <div className="flex justify-between align-center">
              <p className="h5-bold-20">List ({employeeData.length})</p>
              <NormalButton
                icon={plus}
                buttonText="Add New"
                type={13}
                onClick={() => {
                  setTreeData(defaultUserAccess);
                  setUpdateOrView("");
                  setId(null);
                  setPageState(1);
                }}
              />
            </div>
            <Table
              style={{ fontWeight: 500, marginTop: 16 }}
              columns={columns}
              dataSource={employeeData.reverse()}
              pagination={false}
              scroll={{ y: 520 }}
            />
          </div>
        )}
        {pageState === 1 && (
          <Spin spinning={isLoading} size="large">
            <div className="heading-style-2-container flex justify-between align-center">
              <div className="flex align-center">
                <ArrowLeftOutlined
                  height={18}
                  onClick={() => {
                    setPageState(0);
                  }}
                  className="arrow-left-button"
                />
                <h1 className="font-size-global-24 font-weight-global-700">
                  {updateOrView ? "Update User's Access" : "New User Access"}
                </h1>
              </div>
              <div>
                <NormalButton
                  type={5}
                  buttonText="Cancel"
                  margin={[0, 16]}
                  onClick={() => {
                    setPageState(0);
                    setUpdateOrView("");
                  }}
                />
                {id && (
                  <NormalButton
                    type={13}
                    buttonText="Save"
                    onClick={updateAccessManage}
                  />
                )}
              </div>
            </div>
            <div className="access-editor">
              <div className="access-employee-name">
                <div className="app-input-container-1">
                  {updateOrView ? (
                    <span className="font-size-16 font-weight-600">
                      {updateOrView}
                    </span>
                  ) : (
                    <DropdownComponent
                      size="large"
                      options={employeeWithoutAccess.map(item => item.displayName)}
                      labeltext={"Employee Name"}
                      values={employeeWithoutAccess.map(item => item._id)}
                      value={id}
                      style={{
                        width: 400,
                        backgroundColor: "transparent",
                      }}
                      onChange={(e) => setId(e)}
                    >
                      {employeeWithoutAccess.map((employeeObj, index) => (
                        <Option
                          value={employeeObj._id}
                          key={`emplooyee-${index}`}
                        >
                          {employeeObj.displayName} ( ID :{" "}
                          {employeeObj.currentOrganisationMeta.employeeId})
                        </Option>
                      ))}
                    </DropdownComponent>
                  )}
                </div>
              </div>
              {id && (
                <div>
                  <p className="app-label-5 m-bottom-16">Access</p>
                  <Tree
                    defaultExpandAll
                    checkable
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    treeData={accessData}
                  />
                </div>
              )}
            </div>
          </Spin>
        )}
        <Modal
          spinning={isLoading}
          centered
          closable={false}
          visible={showDeleteModal}
          className="modal-container"
          footer={null}
          onCancel={() => {
            setDeleteAccessUser({});
            setShowDeleteModal(false);
          }}
        >
          <Spin spinning={isLoading}>
            <div className="">
              <div className="flex justify-between">
                <h1 className="app-heading-2">Remove Access Permission</h1>
              </div>
              <div className="">
                <p className="font-size-16 font-weight-600">
                  Are you sure you want to remove all access permission of "
                  {deleteAccessUser.displayName}"
                </p>
              </div>
              <div className="m-top-36  text-align-right">
                <NormalButton
                  type={5}
                  buttonText={"cancel"}
                  margin={[0, 12]}
                  onClick={() => {
                    setDeleteAccessUser({});
                    setShowDeleteModal(false);
                  }}
                />
                <NormalButton
                  type={5}
                  buttonText="Delete"
                  onClick={() => {
                    onDeleteConfirm();
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    </div>
  );
};
const AccessContainer = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    deviceWidth: state.responsive.deviceWidth,
    organisationSettings: state.organisation.organisationSettings,
    orgLoading: state.organisation.loading,
    groups: state.organisation.groups,
    organisationFreeze: state.organisation.organisationFreeze,
    complainceCredentials: state.organisation.complainceCredentials,
    onboarding: state.organisation.onboarding,
    isTrial: state.auth.user.isTrial,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(Access);

export default AccessContainer;
