import React, { useState } from "react";
import { Input, notification,  Spin, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { FileLogos, NormalButton } from "../../ComponentLibrary";
import config from "../../../utils/config";
import { connect } from "react-redux";
import { newSupportRequest, removeS3Object } from "../../../apis/employee";
import { deleteLogo } from "../../../assets/common/editAndDeleteLogos";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";


const { TextArea } = Input;
const { Dragger } = Upload;

const Form = ({ user, updateSupportData }) => {
  const [supportRequestData, setSupportRequestData] = useState({
    category: "default",
    reason: "",
    phone: null,
    id: user._id,
    attachments: [],
  });
  const [loading, setLoading] = useState(false);


  const removeFileFromUploadList = async (index, doc) => {
    setLoading(true);
    await removeS3Object({ Key: doc.key });
    let tempSuppportData = supportRequestData.attachments;
    tempSuppportData.splice(index, 1);
    setSupportRequestData({
      ...supportRequestData,
      attachments: tempSuppportData,
    });
    setLoading(false);
  };

  const draggerProps = {
    maxCount: 1,
    name: "file",
    multiple: false,
    action: `${config.rootUrl}/api/document/support`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    showUploadList: false,
    beforeUpload: config.beforeFileUpload,
    async onChange(info) {
      let { response } = info.file;
      if (response) {
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        let allDocumentsDetails = supportRequestData.attachments;
        allDocumentsDetails.length !== 1 &&
          allDocumentsDetails.push(documentDetails);
        setSupportRequestData({
          ...supportRequestData,
          attachments: allDocumentsDetails,
        });
        setLoading(false);
      }
    },
  };

  const requestSupport = async () => {
    if (
      !supportRequestData.reason ||
      supportRequestData.phone.length !== 10 ||
      !supportRequestData.attachments.length
    ) {
      notification.error({ message: "Please enter every details" });
      return;
    }
    setLoading(true)
    let response = await newSupportRequest(supportRequestData);
    updateSupportData()
    if (response.data.success) {
      setSupportRequestData({
        category: "",
        reason: "",
        phone: null,
        id: user._id,
        attachments: [],
      });
      notification.success({ message: "Support Request Created Successfully" });
    } else {
      notification.error({
        message: "Failed to create support request,Please try again!!",
      });
    }
    setLoading(false)
  };

  return (
    <Spin spinning={loading} size="large">
      <div className="form-container">
        <h2 className="form-heading">
          <b>Get in touch with us</b>
        </h2>
        {/* <div className="form-data-container">
          <label className="form-labels">Choose the type of Support</label>
          <Select
            placeholder="Select a option"
            className='form-type'
            allowClear
            name="category"
            value={supportRequestData.category}
            onChange = { (e)=>{setSupportRequestData({
              ...supportRequestData,
              category:e
            })}}
          >
            <Option value="Technical">Technical</Option>
            <Option value="Non-Technical">Non-Technical</Option>
            <Option value="other">other</Option>
          </Select>
        </div> */}

        <div className="form-data-container">
          <label className="form-labels">How can we help you?</label>
          <TextArea
            rows={4}
            name="reason"
            value={supportRequestData.reason}
            onChange={(e) => {
              setSupportRequestData({
                ...supportRequestData,
                reason: e.target.value,
              });
            }}
            autoSize={{ minRows: 10, maxRows: 11 }}
          />
        </div>

        <div className="form-data-container">
          <div className="phone-number-holder">
            
            <InputFieldComponent
              labeltext={"Phone Number"}
              className="general-ant-field-input phone-number"
              bordered={false}
              size={"large"}
              type="number"
              style={{
                width: "100%",
                borderBottom: "1px solid black",
              }}
              name="phone"
              value={supportRequestData.phone}
              onChange={(e) => {
                if (e.target.value.length < 11) {
                  setSupportRequestData({
                    ...supportRequestData,
                    phone: e.target.value,
                  });
                }

              }}
            />
          </div>
        </div>
        <div>
          {supportRequestData.attachments.map((value, index) => {
            return (
              <div
                key={`upload-doc-main-${index}`}
                className="uploaded-docs-card"
              >
                <div className="uploaded-docs-left">
                  <FileLogos
                    type={
                      value.key.split(".").pop() === "pdf"
                        ? 1
                        : value.key.split(".").pop() === "jpg"
                          ? 2
                          : value.key.split(".").pop() === "svg"
                            ? 3
                            : 4
                    }
                    margin={[0, 5]}
                  />
                  <span className="uploaded-docs-card-name">
                    {value.key.split("reimbursement-").pop()}
                  </span>
                </div>
                <NormalButton
                  type={5}
                  margin={[0, 0]}
                  icon={deleteLogo}
                  onClick={() => removeFileFromUploadList(index, value)}
                />
              </div>
            );
          })}
        </div>
        <div className="dregger">
          {supportRequestData.attachments.length < 1 && (
            <Dragger name="files" action="/upload.do" {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Dragger>
          )}
        </div>

        <div className="submit-container">
          <NormalButton
            className=""
            buttonText="Submit Request"
            onClick={requestSupport}
            type={13}
            block={true}
          />
        </div>
      </div>
    </Spin>
  );
};

const FormContainer = connect((state) => ({
  user: state.auth.user,
}))(Form);

export default FormContainer;
