import { useState, useEffect } from "react";
import { Col, message, Spin } from "antd";
import { NormalButton } from "../../../ComponentLibrary";
import config from "../../../../utils/config";
import editIcon from "../../../../assets/icons/edit.svg";
import InfoBtn from "../../../ComponentLibrary/InfoButtonComponent/InfoBtn";
import { HeaderComponent } from "../../../Common";
import { connect } from "react-redux";
import organisation from "../../../../redux/modules/organisation";
import { createLog } from "../../../../apis/user";
import InputNumberComponent from "../../../InputLibrary/InputNumberComponent/InputNumberComponent";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"


const FnFConfig = ({
    organisationSettings,
    organisationReducer,
    user,
    orgLoading,
    isTrial
}) => {
    const [editable, setEditable] = useState(false);
    const [orgSettings, setOrgSettings] = useState({
        ...organisationSettings,
        fnfSalaryType: "",
        fnfFactor: 0,
    });
    useEffect(() => {
        document.title = 'Settings | FnF Configuration';
    });
    useEffect(() => {
        updateOrgSettings(organisationSettings);
    }, [organisationSettings]);

    const updateOrgSettings = (orgSett) => {
        setOrgSettings({
            ...orgSett,
            fnfSalaryType: orgSett.fnfSalaryType || "",
            fnfFactor: orgSett.fnfFactor || 0,
        });
    };
    const onSave = async () => {
        if (orgSettings.fnfSalaryType === "") {
            message.error("Please select component value");
            return;
        }
        if (orgSettings.fnfFactor <= 0) {
            message.error("Please enter a valid factor");
            return;
        }
        organisationReducer.updateSettings({
            organisationSettings: orgSettings,
            organisationId: user.organisationId,
        });

        await createLog({
            name: user.displayName,
            userId: user._id,
            organisationId: user.organisationId,
            message: `User has updated the general configuration`,
            category: 14,
        });
        setEditable(false);
    };

    const onCancel = () => {
        updateOrgSettings(organisationSettings);
        setEditable(false);
    };

    return (
        isTrial ? <TrialPage user={user} trialMessage={"Configure Mool as per your company policy"} bgImage={configurationsBgImage}
        /> :
            <div className="site-layout">
                <HeaderComponent
                    show={false}
                    // icon={ }
                    name="FnF Configuration"
                />
                <div className="app-main-content-container paper-wrapper">
                    <Spin spinning={orgLoading}>
                        <div className="flex justify-between">
                            <div>
                                <h1 className="h1-bold-24 m-bottom-5">FnF Configuration</h1>
                                <p className="tab-subtitle-1 grey">
                                    Configure the full and final settings for your organization. This will
                                    be applied to all employees within the organization.{" "}
                                </p>
                            </div>
                            <div>
                                {editable ? (
                                    <div>
                                        <NormalButton
                                            type={5}
                                            buttonText="Cancel"
                                            onClick={onCancel}
                                        />
                                        <NormalButton buttonText="Save" type={13} onClick={onSave} />
                                    </div>
                                ) : (
                                    <NormalButton
                                        type={13}
                                        icon={editIcon}
                                        buttonText="Edit"
                                        onClick={() => setEditable(true)}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="m-top-20">
                            {
                                <div className="config-container">
                                    <div className="flex align-center justify-start">
                                        <p className="font-weight-global-700 font-size-global-18">Leave Encashment Configuration</p>
                                        <InfoBtn
                                            infoText={"Select salary type and factor on which Full and Final is to be calculated"}
                                        /></div>
                                    <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "25%", minWidth: "15" }}>
                                        <Col sm={24} md={24} lg={24} xl={24}>
                                            {/* <div className="flex align-center justify-start">
                                            <InfoBtn
                                                infoText={"Select salary type on which Full and Final is to be calculated"}
                                            />
                                        </div> */}
                                            {editable ? (
                                                <DropdownComponent
                                                    margin={[20, 0, 20, 0]}
                                                    value={orgSettings.fnfSalaryType}
                                                    options={config.fnfCalculationBasis}
                                                    labeltext="Select Component"
                                                    // disabled={!editable}
                                                    onChange={(e) => {
                                                        setOrgSettings({
                                                            ...orgSettings,
                                                            fnfSalaryType: config.fnfCalculationBasis[e],
                                                        })
                                                    }
                                                    }
                                                />
                                            ) : (
                                                <div className="app-input-value">
                                                    <DropdownComponent
                                                        margin={[20, 0, 20, 0]}
                                                        value={orgSettings.fnfSalaryType}
                                                        options={config.fnfCalculationBasis}
                                                        labeltext="Select Component"
                                                        disabled={true}
                                                        onChange={(e) => {
                                                            setOrgSettings({
                                                                ...orgSettings,
                                                                fnfSalaryType: config.fnfCalculationBasis[e],
                                                            })
                                                        }
                                                        }
                                                    />

                                                </div>
                                            )}
                                        </Col>
                                        <Col sm={8} md={8} lg={12} xl={12}><div className="flex align-center justify-start"></div></Col>
                                        <Col sm={24} md={24} lg={24} xl={24}>
                                            {/* <div className="flex align-center justify-start">
                                            <p className="font-weight-global-700 font-size-global-18">Factor</p>
                                            <InfoBtn
                                                infoText={"Select factor by which Full and Final is to be calculated"}
                                            />
                                        </div> */}
                                            {editable ? (
                                                <InputNumberComponent
                                                    min={1}
                                                    max={10}
                                                    value={(orgSettings.fnfFactor === "0" && 1) || orgSettings.fnfFactor}
                                                    labeltext="Factor"
                                                    margin={[20, 0, 20, 0]}
                                                    // disabled={(!orgSettings.fnfFactor || 1) || !editable}
                                                    onChange={(e) => {
                                                        setOrgSettings({ ...orgSettings, fnfFactor: e })
                                                    }}
                                                />

                                            ) : (
                                                <div className="app-input-value">
                                                    <InputNumberComponent
                                                        min={1}
                                                        max={10}
                                                        value={orgSettings.fnfFactor}
                                                        labeltext="Factor"
                                                        margin={[20, 0, 20, 0]}
                                                        disabled={true}
                                                        onChange={(e) => {
                                                            setOrgSettings({ ...orgSettings, fnfFactor: e })
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Col>
                                    </div>
                                </div>
                            }
                        </div>
                    </Spin>
                </div>
            </div>
    );
};

const FnFContainer = connect(
    (state) => ({
        user: state.auth.user,
        organisationSettings: state.organisation.organisationSettings,
        orgLoading: state.organisation.loading,
        isTrial: state.auth.user.isTrial,
    }),
    (dispatch) => ({
        organisationReducer: organisation.getActions(dispatch),
    })
)(FnFConfig);

export default FnFContainer;