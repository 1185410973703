module.exports = {
  options: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  CTC_GROSS: [
    { label: "CTC", value: true },
    { label: "Gross", value: false },
  ],
  STATUTORY_BONUS_OPTION: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  pfContributionType: [
    { label: 'Mool Configuration (recommended)', value: true },
    { label: 'Custom Configuration', value: false },
  ],
  monthConfig: [
    { label: 'Next Month Cycle', value: true },
    { label: 'Current Month Cycle', value: false },
  ],
  orgPFContribution: [
    { label: 'Minimum (INR 1800)', value: false },
    { label: '12% Rule', value: true },
  ],
  percentage12PFContribution: [
    { label: '12% of Basic', value: true },
    { label: '12% of (Gross - HRA)', value: false },
  ],
  percentage1800PFContribution: [
    { label: 'Ratio of 1800', value: true },
    { label: 'As per PF Salary Computed', value: false },
  ],
  StatutoryBonusIncludeHRA: [
    { label: 'Basic', value: false },
    { label: 'Basic + HRA', value: true }
  ]
}