import config, { rootUrl } from "../../utils/config";
import moment from "moment";
import { NormalButton } from "../ComponentLibrary";
import { deleteLogo } from "../../assets/common/editAndDeleteLogos";
import CommonFunctionality from "./components/CommonFunctionality.js";
import { useState } from "react";
import { message, Select, Spin, notification, Popconfirm } from "antd";
import { monthList } from "../../utils/config";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";

let Option = Select;

const bonusTypes = {
  Interim_Bonus: "Interim Bonus",
  Puja_Bonus: "Puja Bonus",
};

const Bonus = ({
  monthCloseFields,
  user,
  employeeList,
  organisation,
  deleteData,
  startRefresh,
  loading,
  financialYearList,
  name,
}) => {
  const [isloading, setIsLoading] = useState(false);
  const [singleModalShow, setSingleModalShow] = useState(false);
  const [bulkModalShow, setBulkModalShow] = useState(false);

  const [fieldDetails, setFieldDetails] = useState({
    id: "",
    type: "credit",
    details: "Bonuses",
    subDetails: "",
    amount: null,
    othertext: "",
    taxable: true,
    month: new Date().getMonth(),
    financialYear: "",
  });

  const submitForm = async () => {
    if (!fieldDetails.id) {
      message.error("Please select an employee");
      return;
    }
    if (!fieldDetails.amount) {
      message.error("Please enter bonus amount");
      return;
    }
    if (!fieldDetails.subDetails) {
      message.error("Please enter bonus type");
      return;
    }
    if (!fieldDetails.financialYear) {
      message.error("Please enter Financial Year");
      return;
    }
    if (!fieldDetails.month && fieldDetails.month !== 0) {
      message.error("Please enter month");
      return;
    }
    setIsLoading(true);
    let response = await organisation.addMonthClose({
      monthClose: {
        ...fieldDetails,
        organisationId: user.organisationId,
      },
    });
    setIsLoading(false);
    if (response.data.success) {
      notification.success({ message: "Successfully added the bonus" });
    } else {
      notification.error({ message: "Unable to add the bonus" });
    }
    organisation.updateMonthClose({ organisationId: user.organisationId });
    reset();
  };

  const reset = () => {
    setFieldDetails({
      ...{
        id: "",
        type: "credit",
        details: "Bonuses",
        subDetails: "",
        amount: null,
        othertext: "",
        taxable: true,
        month: new Date().getMonth(),
        financialYear: "",
      },
    });
    setSingleModalShow(false);
  };

  const columns = [

    {
      title: "Employee Name",
      dataIndex: ["user", "displayName"],
    },
    {
      title: "Employee Id",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
    },
    {
      title: "Added On",
      dataIndex: "createdAt",
      defaultSortOrder: "aescend",
      render: (text) => <p>{moment(text).format("ll")}</p>,
    },
    {
      title: "Applicable On",
      dataIndex: "month",
      defaultSortOrder: "aescend",
      render: (text) => <p>{config.monthList[text]}</p>,
    },
    {
      title: "Reason",
      dataIndex: "subDetails",
      render: (text) => <p>{bonusTypes[text]}</p>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount, row) => (
        <p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
          {row.type === "debit" ? "-" : "+"}
          {amount}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (data, row) => (
        <Popconfirm title={"Are you sure ?"} onConfirm={() => deleteData(data)}>
          <NormalButton
            type={5}
            icon={deleteLogo}
          />
        </Popconfirm>
      ),
    }
  ];

  return (
    <CommonFunctionality
      columns={columns}
      user={user}
      employeeList={employeeList}
      monthCloseFields={monthCloseFields}
      organisation={organisation}
      deleteData={deleteData}
      loading={loading}
      singleModalShow={singleModalShow}
      bulkModalShow={bulkModalShow}
      setSingleModalShow={setSingleModalShow}
      setBulkModalShow={setBulkModalShow}
      name={name}
      startRefresh={startRefresh}
      reset={reset}
      downloadURL={`${rootUrl}/api/monthclose/generate/excel/bonus/${user.organisationId}`}
      actionUrl={`${rootUrl}/api/organisation/validate/bonus/${user.organisationId}`}
    >
      <div className="">
        <h2 className="font-size-global-24 font-weight-global-700">
          Add Bonus
        </h2>
        <Spin spinning={isloading} size="large">
          <div className="org-anb-data-container p-top-15">
            <DropdownComponent
              className="general-ant-field-select modal-alignment-center-global"
              value={fieldDetails.id}
              labeltext={"Employee Name"}
              values={employeeList.map((item) => item._id)}
              options={employeeList.map((item) => item.displayName)}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  id: e,
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            >
              <Option value={""}>Select An Employee</Option>
              {employeeList.map((val, index) => {
                return (
                  <Option key={`employees-${index}`} value={val._id}>
                    {val.displayName}
                  </Option>
                );
              })}
            </DropdownComponent>
          </div>

          <div className="org-anb-data-container p-top-15">
            <DropdownComponent
              labeltext={"Financial Year"}
              className="general-ant-field-select"
              value={fieldDetails.financialYear}
              values={financialYearList}
              options={financialYearList}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  financialYear: e,
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            >
              {financialYearList.map((val, index) => {
                return (
                  <Option key={`fyear-${index}`} value={val}>
                    {val}
                  </Option>
                );
              })}
            </DropdownComponent>
          </div>

          <div className="org-anb-data-container p-top-15">
            <DropdownComponent
              className="general-ant-field-select"
              labeltext={"Month"}
              value={fieldDetails.month}
              values={monthList.map((_val, index) => index)}
              options={monthList}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  month: e,
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {monthList.map((val, index) => {
                return (
                  <Option key={`employees-${index}`} value={index}>
                    {val}
                  </Option>
                );
              })}
            </DropdownComponent>
          </div>

          <div className="org-anb-data-container p-top-15">
            <DropdownComponent
              className="general-ant-field-select"
              labeltext={"Bonus type"}
              value={fieldDetails.subDetails}
              values={["Puja_Bonus", "Interim_Bonus"]}
              options={["Puja Bonus", "Interim Bonus"]}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  subDetails: e,
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            >
              <Option value={"Puja_Bonus"}>Puja Bonus</Option>
              <Option value={"Interim_Bonus"}>Interim Bonus</Option>
            </DropdownComponent>
          </div>

          <div className="org-anb-data-container p-top-15">
            <InputFieldComponent
              className="general-ant-field-input"
              labeltext={"Amount ( INR )"}
              type={"number"}
              width={"100%"}
              value={parseInt(fieldDetails.amount)}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  amount: isNaN(parseInt(e.target.value))
                    ? 0
                    : parseInt(e.target.value),
                });
              }}
              placeholder="Amount"
              size={"large"}
            />
          </div>

          <div className="select-button-container">
            <NormalButton
              type={13}
              buttonText={"Add to the month end data"}
              block={"100%"}
              onClick={submitForm}
              margin={[30, 0]}
            />
          </div>
        </Spin>
      </div>
    </CommonFunctionality>
  );
};

export default Bonus;
