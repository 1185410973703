import React, { useState, useEffect } from 'react'
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './ConfirmPasswordComponent.less'


function ConfirmPasswordComponent({ required, maxWidth, password, setPassworderror, passworderror, disabled, height,
  width, labeltext, display, value, margin, onChange }) {
  const [confirmPasswordVerify, setConfirmPasswordVerify] = useState(0);
  useEffect(() => {
    if (value !== "") {

      if (value !== password) {
        setPassworderror(3);
      } else {
        setPassworderror(-1);
      }
    } else {
      setPassworderror(0);
    }
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps
  const [defaultMessageIndicator, setDefaultMessageIndicator] = useState(0);
  const handleDefaultMessage = () => {
    if (disabled !== undefined && disabled !== false) {
      setDefaultMessageIndicator(!defaultMessageIndicator);
    }
  }
  const changeErrorstate = (e) => {
    setPassworderror(0);
    if (passwordprevvisible !== "") {
      e.target.type = passwordprevvisible;
    }
  }
  const [passwordprevvisible, setPasswordPrevVisible] = useState("");
  const handlepasswordvisible = (e) => {
    if (passworderror === -1) setPassworderror(0);
    setPasswordPrevVisible(e.target.type);
    e.target.type = "password";
  }
  let timer;
  const handleConfirmverify = () => {
    if (confirmPasswordVerify !== undefined) {
      setDefaultMessageIndicator(0);
      setConfirmPasswordVerify(0);
      
    }
  }
  let styles = {
    borderWidth: passworderror && passworderror !== 3 ? '2px' : `${value ? '2px' : '1px'}`,
    borderColor: passworderror && passworderror !== 3 ? `#F05050` : `${value ? '#5E5F62' : '#d9d9d9'}`,
    border: value ? '2px #5E5F62 solid' : '1px #d9d9d9 solid',
    width: width ? `${width}` : "288px",
    maxWidth: maxWidth ? `${maxWidth}` : "100%",
    height: height ? `${height}` : "50px",
    borderRadius: '8px',
    background: 'transparent'
  }
  let stylediv = {
    position: 'relative',
    display: display ? display : 'block',
    margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : "10px 0px",
  }
  return (
    <div className='passworddiv' style={stylediv}>
      <Input.Password
        required={required}
        onKeyDown={() => {
          setConfirmPasswordVerify(1);
          setDefaultMessageIndicator(1);
          clearTimeout(timer);
        }}
        onKeyUp={() => {
          clearTimeout(timer);
          timer = setTimeout(handleConfirmverify, 1000) 
        }}
        onMouseOver={handleDefaultMessage}
        onMouseLeave={handleDefaultMessage}
        className={passworderror ? 'error' : ''}
        onBlur={handlepasswordvisible}
        onPaste={e => e.preventDefault()}
        onFocus={changeErrorstate}
        disabled={disabled}
        style={styles}
        value={value}
        onChange={onChange}
        autoComplete={"off"}
        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
      <label className={`${value === "" ? 'labelnormal' : 'labelup'} ${(passworderror > 0 && passworderror !== 3) && 'error'}`}>{labeltext ? labeltext : 'Your Label'}</label>
      {disabled ? (<p className={`${defaultMessageIndicator ? 'activedefaultmessage' : 'confirmdefaultmessage'}`}>{`${confirmPasswordVerify ? `verifying......` : `Set password correctly first`}`}</p>) :
        (<p className={passworderror ? `${passworderror === -1 ? 'activesuccessmessage' : 'activeerrormessage'}` : 'errormessage'}>
          {value ? `${passworderror === -1 ? `Password Matched` : `Password should match`}`
            : 'This is a required field'}</p>)}
    </div>
  )
}

export default ConfirmPasswordComponent