import { Checkbox, Col, Layout, message, notification, Row, Spin } from "antd";
import React, { useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import * as auth from "../../apis/auth";
import registerArrow from "../../assets/icons/regArrow.svg";
// import registerImage from "../../assets/registerPage/OrganisationonboardingRegister.png";
import { validateEmail } from "../../utils/config";
import { NormalButton } from "../ComponentLibrary";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
import InputNumberComponent from "../InputLibrary/InputNumberComponent/InputNumberComponent";
import TermsAndCondition from "./TermsAndCondition";
import sidebar from "../../assets/sidebarlogo.svg";
import moolLogo from "../../assets/registerPage/moolLogo.svg";
import quotesSvg from "../../assets/registerPage/quotes.svg";
import registerPageDashboard from "../../assets/registerPage/registerPageDashboard.svg";

const { Content } = Layout;

function Register() {
  const [orgDetails, setOrgDetails] = useState({
    name: "",
    username: "",
    PAN: "",
    email: "",
    phone: "",
    designation: "",
    pointOfContact: "",
    organisationName: "",
    isTrial: false,
  });
  const [isChecked, setIsChecked] = useState(false)
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const registerEmployee = async () => {
    if (!/^[a-zA-Z ,.'-\d]+$/.test(String(orgDetails.name))) {
      message.error("Please Enter Organisation Name");
      return;
    }
    if (
      !/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}Z[A-Z\d]{1}$/.test(orgDetails.PAN)
    ) {
      message.error("Please Enter A Valid Organisation GST-IN");
      return;
    }
    if (!validateEmail(orgDetails.email)) {
      message.error("Please Enter A Valid Email");
      return;
    }
    if (!/^[a-zA-Z ,.'-\d]+$/.test(String(orgDetails.pointOfContact))) {
      message.error("Please Enter Point Of Contact");
      return;
    }
    if (!/^[a-zA-Z ,.'&-\d]+$/.test(String(orgDetails.designation))) {
      message.error("Please Enter Organisation Designation");
      return;
    }
    if (!/^[6-9]{1}[0-9]{9}$/.test(orgDetails.phone)) {
      message.error("Please Enter Correct Organisation Phone Number");
      return;
    }
    if (!termsAndCondition) {
      message.error("Please verify and accept the terms and conditions");
      return;
    }
    setLoading(true);
    let authResult = await auth.register({
      ...orgDetails,
      username: orgDetails.PAN,
    });
    if (authResult.data.success) {
      notification.success({
        message: "Organization created",
        description: "Successfully created the user moving to next step",
        placement: "topRight",
      });
      const isTrial = authResult.data.data.data.isTrial;
      if (isTrial) {
        const authToken = authResult.data.data.data.authToken;
        localStorage.setItem("mool_auth", authToken);
        window.location.reload("/login");
        return;
      }
      const organisationId = authResult.data.data.data.organisationId;
      localStorage.setItem("organisationId", organisationId);
      setOrgDetails({
        name: "",
        username: "",
        PAN: "",
        email: "",
        phone: "",
        pointOfContact: "",
        designation: "",
        organisationName: "",
        isTrial: false,
      });
      history.push("/pricemodel");
    } else {
      notification.error({
        message: authResult.data.message,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const checkUserAvailability = async (details, type) => {
    // type : 1 email
    let res = await auth.checkUserExist({ details, type });
    if (res.data) {
      if (type === 1) {
        notification.error({
          message: "Sorry user with the same email already exists",
        });
        setOrgDetails({ ...orgDetails, email: "" });
      }
      if (type === 3) {
        notification.error({
          message: "Sorry user with the same GST-IN number already exists",
        });
        setOrgDetails({ ...orgDetails, PAN: "" });
      }
    }
  };
  const trialVersion = () => {
    setIsChecked(!isChecked)
    setOrgDetails({
      ...orgDetails,
      isTrial: !isChecked ? true : false,
    });

  }

  return (
    <Content className='register-container'>
      <Spin spinning={loading} size='large'>
        <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
          <Col span={10} className='imageCol'>
            <div
              className='registerImageCarousel'
              style={{
                backgroundColor: "#EFF5FF",
                height: "100vh",
                paddingLeft: "50px",
                paddingTop: "48px",
                zIndex: "-2",
                overflow: "hidden",
              }}
            >
              <div style={{}}>
                <div style={{ marginBottom: "86px" }}>
                  <a href='https://www.moolfinance.com/' style={{}}>
                    <img src={moolLogo} alt='moolLogo.png' />
                  </a>
                </div>
                <div>
                  <img
                    src={quotesSvg}
                    alt=''
                    style={{ position: "absolute" }}
                  />
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "36px",
                      color: "#405CD2",
                      marginLeft: "50px",
                      paddingTop: "17px",
                      marginRight: "69px",
                    }}
                  >
                    A lot of our payroll related activity which we used to do
                    manually on spreadsheets was automated by Mool Vetan. Their
                    end-to-end automations saved us from so much headache in
                    this last quarter.{" "}
                  </p>
                </div>
                <div style={{ zIndex: "-1", marginTop: "93px" }}>
                  <img
                    src={registerPageDashboard}
                    alt=''
                    style={{
                      zIndex: "-1",
                      width: "60vw",
                      height: "auto",
                      border: "13px solid #171B2E",
                      borderRadius: "15px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col
            span={14}
            className='registerFormDiv'
            style={{ zIndex: "2", backgroundColor: "white" }}
          >
            <div>
              <div className='registerForm'>
                <a href='https://www.moolfinance.com/'>
                  <img src={sidebar} className='mool-Logo' alt='' />
                </a>
                <h1 className='h1-bold-32'>
                  Welcome to Mool{" "}
                  <span style={{ color: "#405cd2" }}>
                    <i>Vetan</i>
                  </span>
                </h1>
                <h1 className='h1-bold-32'>Let's get you onboard</h1>
                <div className='registerLabel' style={{ padding: "10px 0" }}>
                  {/* <p className="font-size-18">Fill up all the details</p> */}
                </div>
                <div>
                  <div className='w-full position-relative'>
                    <InputFieldComponent
                      className='input-register'
                      value={orgDetails.name}
                      autoFocus={true}
                      labeltext={"Organisation Name"}
                      width='500px'
                      margin={[5, 0, 5, 0]}
                      onChange={(e) => {
                        setOrgDetails({
                          ...orgDetails,
                          name: e.target.value,
                          organisationName: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='w-full position-relative'>
                  <InputFieldComponent
                    className='input-register'
                    value={orgDetails.PAN}
                    labeltext={"Organisation GSTIN"}
                    onBlur={() => checkUserAvailability(orgDetails.PAN, 3)}
                    width='500px'
                    margin={[5, 0, 5, 0]}
                    onChange={(e) => {
                      if (e.target.value.length < 16)
                        setOrgDetails({
                          ...orgDetails,
                          PAN: e.target.value.toUpperCase(),
                        });
                    }}
                  />
                </div>
                <div className='w-full position-relative'>
                  <InputFieldComponent
                    className='input-register'
                    type='email'
                    value={orgDetails.email}
                    labeltext={"Organisation Email"}
                    onBlur={() => checkUserAvailability(orgDetails.email, 1)}
                    width='500px'
                    margin={[5, 0, 5, 0]}
                    onChange={(e) =>
                      setOrgDetails({ ...orgDetails, email: e.target.value })
                    }
                  />
                </div>

                <div className='w-full position-relative'>
                  <InputFieldComponent
                    className='input-register'
                    value={orgDetails.pointOfContact}
                    labeltext={"Point of Contact Name"}
                    width='500px'
                    margin={[5, 0, 5, 0]}
                    onChange={(e) =>
                      setOrgDetails({
                        ...orgDetails,
                        pointOfContact: e.target.value,
                      })
                    }
                  />
                </div>
                <Row style={{ marginBottom: "0px", maxWidth: "500px" }}>
                  <Col span={12}>
                    <div className='w-full position-relative'>
                      <InputFieldComponent
                        className='input-register'
                        value={orgDetails.designation}
                        labeltext={"Designation"}
                        width='240px'
                        margin={[5, 0, 5, 0]}
                        onChange={(e) =>
                          setOrgDetails({
                            ...orgDetails,
                            designation: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      className='w-full position-relative'
                      style={{ paddingLeft: "10px" }}
                    >
                      <InputNumberComponent
                        className='input-register'
                        value={orgDetails.phone}
                        labeltext={"Phone Number"}
                        width='240px'
                        margin={[5, 0, 5, 0]}
                        onChange={(value) => {
                          if (value.toString().length === 10) {
                            setOrgDetails({ ...orgDetails, phone: value });
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Checkbox
                  checked={termsAndCondition}
                  onClick={(_data) => setTermsAndCondition(!termsAndCondition)}
                  className='terms-and-condition-checkbox subtitle3-med-16'
                >
                  I accept all the{" "}
                  <span
                    onClick={() => setIsModalVisible(true)}
                    style={{ color: "orange" }}
                  >
                    terms and conditions
                  </span>{" "}
                </Checkbox>
                <Checkbox
                  style={{ marginLeft: "-1px" }}
                  checked={isChecked}
                  onClick={trialVersion}
                  className='terms-and-condition-checkbox subtitle3-med-16'
                >
                  I want to use trail version
                </Checkbox>
                <TermsAndCondition
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                />
                <div className='register-btn-container'>
                  <NormalButton
                    type={13}
                    // heightWidth={["50px", "500px"]}
                    buttonText='Register Now'
                    onClick={registerEmployee}
                    className='submit-button'
                    htmlType='submit'
                    secondaryIcon={registerArrow}
                  ></NormalButton>
                </div>
                <div
                  className='goto-login-container'
                  style={{ marginTop: "10px" }}
                >
                  <p className='font-size-16'>
                    Already have an account?
                    <Link to='/login'>
                      <span style={{ fontWeight: "bold" }}> Login Now</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    </Content>
  );
}

export default withRouter(Register);
