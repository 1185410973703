import HeaderComponent from "../Common/Header/Header";
import reportActive from "../../assets/icons/activeIcons/reports.svg";
import { Content } from "antd/lib/layout/layout";
import { connect } from "react-redux";
import { NormalButton } from "../ComponentLibrary";
import { useEffect, useState } from "react";
import payoutEmpty from "../../assets/organisationConfiguration/AllowanceEmpty.svg";

import organisation from "../../redux/modules/organisation";
import AddOrganisation from "./CreateNewChildOrganistion";
import {
  authenticateChildOrganisation,
  updateBusinessDates,
} from "../../apis/organisation";
import { message, Modal, notification, Spin } from "antd";
import { isValidDate } from "../../utils/config";
import moment from "moment";
import { createLog } from "../../apis/user";
import DateComponent from "../InputLibrary/DateComponent/DateComponent";
import TrialPage from "../Common/TrialComponent";
import mappedOrganisations from "../../assets/dummyPages/mappedOrganisationBgImage.png"

const ChildOrganisation = ({
  user,
  organisationReducer,
  groups,
  location,
  isTrial,
  department,
  designation,
  status,
  childOrganisations,
}) => {
  const [modalProperties, setModalProperties] = useState({
    single: {
      visible: false,
      loading: false,
    },
    bulk: {
      visible: false,
      loading: false,
    },
  });

  const [loading, setLoading] = useState(false);

  const authenticateUser = async (organisationId) => {
    // authenticate the user
    // redirect it to the new organisation page
    // Store the data in session storage with basic information
    setLoading(true);
    let res = await authenticateChildOrganisation(organisationId);
    if (!res.data) {
      notification.error({
        message: "Sorry cannot able to authenticate the user",
      });
      return;
    }

    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: "User is signing into a child organisation",
      category: 0,
    });

    let inheritance = sessionStorage.getItem("inheritance");
    const { displayName, email, pointOfContact } = user;
    if (inheritance === null) {
      inheritance = {};
      inheritance[user.organisationId] = {
        displayName,
        email,
        pointOfContact,
        auth: localStorage.getItem("mool_auth"),
      };
    } else {
      inheritance = JSON.parse(inheritance);
      inheritance[user.organisationId] = {
        displayName,
        email,
        pointOfContact,
        auth: localStorage.getItem("mool_auth"),
      };
    }
    sessionStorage.setItem("inheritance", JSON.stringify(inheritance));
    localStorage.setItem("inheritance", JSON.stringify(inheritance));
    localStorage.setItem("mool_auth", res.data.auth);
    setLoading(false);
    window.location = "/";
  };

  const resetModals = () => {
    let modalProps = modalProperties;
    modalProps.single.visible = false;
    modalProps.single.loading = false;
    modalProps.bulk.visible = false;
    modalProps.bulk.loading = false;
    setModalProperties({ ...modalProps });
  };
  useEffect(() => {
    document.title = 'Mapped Organization';
  });
  return (
    isTrial ? <TrialPage user={user} trialMessage={" Manage different branches seamlessly. Easily switch between the branches."} bgImage={mappedOrganisations}
    /> :
      <div>
        <HeaderComponent
          show={false}
          showMoolCoins={user.role === "org"}
          icon={reportActive}
          name="Mapped Organisations"
        />
        <Content className="app-main-content-container">
          <Spin spinning={loading} size="large">
            <div className="flex justify-between align-center">
              <h3 className="font-size-global-24 font-weight-global-700">Mapped Organisations</h3>
              <NormalButton
                onClick={() => {
                  setModalProperties({
                    ...modalProperties,
                    single: {
                      visible: true,
                      loading: false,
                    },
                  });
                }}
                type={13}
                buttonText="Create Organisation"
              />
            </div>

            <AddOrganisation
              groups={groups}
              location={location}
              department={department}
              designation={designation}
              status={status}
              organisationReducer={organisationReducer}
              setModalProperties={setModalProperties}
              resetModals={resetModals}
              user={user}
              modalProperties={modalProperties}
            />

            {childOrganisations.map((val, index) => (
              <OrganisationObject
                key={`child-${index}`}
                {...val}
                organisationReducer={organisationReducer}
                authenticateUser={authenticateUser}
              />
            ))}
            {childOrganisations.length === 0 && (
              <div className="my-30 flex align-center justify-center flex-direction-column">
                <p className="font-weight-700 font-size-24 text-align-center mb-10">
                  Please add an organisation
                </p>
                <img
                  alt="img"
                  className="payout-state-0-image"
                  src={payoutEmpty}
                />
              </div>
            )}
          </Spin>
        </Content>
      </div>
  );
};

const OrganisationObject = ({
  authenticateUser,
  displayName,
  organisationId,
  phone,
  pointOfContact,
  businessStartData = "",
  businessEndDate = "",
  organisationReducer,
}) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bsd, setBSD] = useState("");
  const [bed, setBED] = useState("");

  const reset = () => {
    if (businessEndDate) {
      setBED(businessEndDate);
    }
    if (businessStartData) {
      setBSD(businessStartData);
    }
    setModal(false);
    setLoading(false);
  };

  useEffect(() => {
    if (businessEndDate) {
      setBED(businessEndDate);
    }
    if (businessStartData) {
      setBSD(businessStartData);
    }
  }, [businessEndDate, businessStartData]);

  const updateData = async () => {
    try {
      let postBody = {
        organisationId,
        businessEndDate: bed,
        businessStartData: bsd,
      };
      setLoading(true);
      let res = await updateBusinessDates(postBody);
      setLoading(false);
      if (res.data.success) {
        organisationReducer.updateChildOrganisationList();
        message.success("Successfully updated business dates");
      } else {
        message.error("Unable to update the request");
      }
    } catch (e) { }
    reset();
  };

  return (
    <div className="org-report-card flex justify-between align-center">
      <Modal
        centered
        footer={null}
        width={400}
        onCancel={reset}
        style={{ padding: 20 }}
        visible={modal}
      >
        <Spin spinning={loading} size="large">
          <h3 style={{ paddingTop: "1.5em", marginTop: "-1.5em", marginBottom: "1.5em", fontWeight: "700" }} className="app-heading-2">Update Business Details</h3>
          <div className="company-address-individual-data-container">
            <DateComponent
              className="profile-data-display single-employee-profile-datepicker"
              labelText={"Start Date"}
              value={
                isValidDate(new Date(bsd))
                  ? moment(new Date(bsd), "YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                setBSD(new Date(e).toLocaleDateString());
              }}
              style={{
                width: "100%",
              }}
              size="large"
            />
          </div>
          <div style={{ marginTop: "2em" }} className="company-address-individual-data-container">
            <DateComponent
              className="profile-data-display single-employee-profile-datepicker"
              labelText={"End Date"}
              value={
                isValidDate(new Date(bed))
                  ? moment(new Date(bed), "YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                if (!e) {
                  setBED("");
                } else {
                  setBED(new Date(e).toLocaleDateString());
                }
              }}
              style={{
                width: "100%",
              }}
              allowClear={true}
              size="large"
            />
          </div>
          <div style={{ marginTop: "2em" }}>
            <NormalButton
              onClick={updateData}
              buttonText="Update"
              type={13}
            />
          </div>
        </Spin>
      </Modal>
      <div className="">
        <p className="font-size-global-18 font-weight-global-700">{displayName}</p>
        <p className="org-report-subtitle">Point of Contact :{pointOfContact} </p>
        <p className="font-size-700 sub-info-text">Contact No. :{phone}</p>
      </div>
      <div className="flex flex-direction-column justify-between align-start">
        {bsd && (
          <p className="app-label-3 font-weight-500">
            Business Start Date: {bsd}
          </p>
        )}
        {bed && (
          <p className="app-label-3 font-weight-500">
            Business End Date: {bed}
          </p>
        )}
      </div>
      <div>
        <span>
          <NormalButton
            onClick={() => setModal(true)}
            buttonText="Update Date"
            type={11}
          />
        </span>
        <span>
          <NormalButton
            onClick={() => authenticateUser(organisationId)}
            buttonText="Explore"
            type={13}
          />
        </span>
      </div>
    </div>
  );
};

const ChildOrganisationContainer = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    groups: state.organisation.groups,
    location: state.organisation.location,
    department: state.organisation.department,
    designation: state.organisation.designation,
    status: state.organisation.status,
    onboarding: state.organisation.onboarding,
    childOrganisations: state.organisation.childOrganisations,
    isTrial: state.auth.user.isTrial,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(ChildOrganisation);

export default ChildOrganisationContainer;
