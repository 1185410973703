import React from "react";
const date = [
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "Jan",
  "Feb",
  "Mar"
];

function MonthlyIncomeData({ data, isFlat = false, name = "" }) {
  const RowElement = ({ val, name: mm }) =>
    <tr>
      <td>
        {mm.toUpperCase()}
      </td>
      <td>
        {val.values[3]}
      </td>
      <td>
        {val.values[4]}
      </td>
      <td>
        {val.values[5]}
      </td>
      <td>
        {val.values[6]}
      </td>
      <td>
        {val.values[7]}
      </td>
      <td>
        {val.values[8]}
      </td>
      <td>
        {val.values[9]}
      </td>
      <td>
        {val.values[10]}
      </td>
      <td>
        {val.values[11]}
      </td>
      <td>
        {val.values[0]}
      </td>
      <td>
        {val.values[1]}
      </td>
      <td>
        {val.values[2]}
      </td>
      <td>
        {val.total}
      </td>
    </tr>;
  return (
    <React.Fragment>
      <tr>
        <th>Pay Items</th>
        {date.map(i => {
          return (
            <th>
              {i}
            </th>
          );
        })}
        <th>Total</th>
      </tr>
      {Object.keys(data).length > 0 &&
        !isFlat &&
        Object.keys(data).map((val, i) =>
          <RowElement key={i} val={data[val]} name={val} />
        )}

      {isFlat && <RowElement val={data} name={name} />}
    </React.Fragment>
  );
}

export default MonthlyIncomeData;
