import { Select, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { checkFinancialYear } from '../../../utils/config.js';
import ForDisabled from "./deducation/forDisabled.js";
import General from "./deducation/general.js";
import Loans from './deducation/loans.js';
import MoreDeduction from "./deducation/moreDeducation.js"
import Summary from "./deducation/summary.js";

const { TabPane } = Tabs;
const { Option } = Select;

const deducationTabsLists = [
    {
        tabName: "General",
        key: "1",
        component: General,
        forceRender: false,
    },
    {
        tabName: "More Deductions",
        key: "2",
        component: MoreDeduction,
        forceRender: false,
    },
    {
        tabName: "For Disabled",
        key: "3",
        component: ForDisabled,
        forceRender: false,

    },
    {
        tabName: "Interest on Loans",
        key: "4",
        component: Loans,
        forceRender: false,

    },
    {
        tabName: "Summary",
        key: "5",
        component: Summary,
        forceRender: true,
    }
]

const Deduction = ({ the12BBApproved, organisationFreeze }) => {
    const [currFinancialYear, setCurrFinancialYear] = useState(
        checkFinancialYear().financialYear
    );
    const [financialYearList, setFinancialYearList] = useState([]);
    useEffect(() => {
        function calculateFinancialYear() {
            let startFYear = 2020;
            let currentDate = new Date();
            let currentYear = currentDate.getFullYear();
            let resFinancialYearList = [];
            for (let i = startFYear; i < currentYear + 1; i++) {
                let nextYear = i + 1;
                let tempFYear = `${i}-${nextYear}`;
                resFinancialYearList.push(tempFYear);
            }
            resFinancialYearList.reverse();
            setFinancialYearList([...resFinancialYearList]);
        }
        calculateFinancialYear();
    }, []);
    return (
        <div>
            <div className="blue-tabs-deduction-container">
                <div className='flex items-center justify-between'>
                    <h2>Chapter 6A Declarations</h2>
                    <div
                        className="flex align-center justify-end"
                        style={{ marginBottom: "2rem" }}
                    >
                        <h3 className="font-weight-700 text-size-21 mb-2 ml-5">Financial Year</h3>
                        <Select
                            defaultValue={currFinancialYear}
                            className="general-ant-field-select ml-2"
                            style={{
                                width: "8rem",
                                marginLeft: "1rem",
                                background: "none",
                                padding: 0
                            }}
                            size="large"
                            onChange={e => {
                                setCurrFinancialYear(e);
                            }}
                            bordered={false}
                        >
                            {financialYearList.map((val, index) =>
                                <Option key={`opt-${index}`} value={val}>
                                    <h3>{val}</h3>
                                </Option>
                            )}
                        </Select>
                    </div>
                </div>
                <Tabs tabPosition='left' style={{ marginTop: "-1rem" }}>
                    {
                        deducationTabsLists.map((data, index) => {
                            return (
                                <TabPane key={`deduction-tab-pane-${index}`}
                                    tab={
                                        <span className="left-side-tabs">
                                            {data.tabName}
                                        </span>
                                    }
                                    forceRender={data.forceRender}
                                >
                                    <data.component financialYear={currFinancialYear} organisationFreeze={organisationFreeze} key={`deduction-tab-pane-data-${index}`} the12BBApproved={the12BBApproved} />
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        </div >
    )
}

export default Deduction;