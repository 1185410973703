import { rootUrl } from "../../utils/config";
import moment from "moment";
import { NormalButton } from "../ComponentLibrary";
import { deleteLogo } from "../../assets/common/editAndDeleteLogos";
import CommonFunctionality from "./components/CommonFunctionality.js";
import { useState } from "react";
import { message, Select, Spin, notification, Popconfirm } from "antd";
import { monthList } from "../../utils/config";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
import DateComponent from "../InputLibrary/DateComponent/DateComponent";

let Option = Select;

const Fine = ({
  monthCloseFields,
  user,
  employeeList,
  organisation,
  deleteData,
  startRefresh,
  loading,
  financialYearList,
  name,
}) => {
  const [isloading, setIsLoading] = useState(false);
  const [singleModalShow, setSingleModalShow] = useState(false);
  const [bulkModalShow, setBulkModalShow] = useState(false);

  const [fieldDetails, setFieldDetails] = useState({
    id: "",
    type: "debit",
    details: "Fine",
    subDetails: "Fine",
    amount: null,
    othertext: "",
    taxable: true,
    month: new Date().getMonth(),
    financialYear: "",
    meta: {
      actForWhichFineImposed: "",
      hasEmployeeVerifiedProofs: true,
      dateOfOffense: "",
      wagesPeriod: null,
      wagesPayable: null,
      fineReleaseDate: new Date().toDateString(),
      nameOfPersonPresenceWhileExplaining: "",
    },
  });

  const submitForm = async () => {
    if (!fieldDetails.id) {
      message.error("Please select an employee");
      return;
    }
    if (!fieldDetails.amount) {
      message.error("Please enter fine amount");
      return;
    }
    if (!fieldDetails.financialYear) {
      message.error("Please enter Financial Year");
      return;
    }
    if (!fieldDetails.month && fieldDetails.month !== 0) {
      message.error("Please enter month");
      return;
    }
    if (!fieldDetails.meta.actForWhichFineImposed) {
      message.error("Please enter all values");
      return;
    }
    if (!fieldDetails.meta.nameOfPersonPresenceWhileExplaining) {
      message.error("Please enter all values");
      return;
    }
    if (!fieldDetails.meta.dateOfOffense) {
      message.error("Please enter all values");
      return;
    }
    if (!fieldDetails.meta.wagesPeriod) {
      message.error("Please enter all values");
      return;
    }
    if (!fieldDetails.meta.wagesPayable) {
      message.error("Please enter all values");
      return;
    }
    if (!fieldDetails.meta.fineReleaseDate) {
      message.error("Please enter all values");
      return;
    }

    setIsLoading(true);
    let response = await organisation.addMonthClose({
      monthClose: {
        ...fieldDetails,
        organisationId: user.organisationId,
      },
    });
    setIsLoading(false);
    if (response.data.success) {
      notification.success({ message: "Successfully added the fine details" });
    } else {
      notification.error({ message: "Unable to add the fine details" });
    }
    organisation.updateMonthClose({ organisationId: user.organisationId });
    reset();
  };

  const reset = () => {
    setFieldDetails({
      ...{
        id: "",
        type: "debit",
        details: "Fine",
        subDetails: "Fine",
        amount: null,
        othertext: "",
        taxable: true,
        month: new Date().getMonth(),
        financialYear: "",
        meta: {
          actForWhichFineImposed: "",
          hasEmployeeVerifiedProofs: true,
          dateOfOffense: "",
          wagesPeriod: null,
          wagesPayable: null,
          fineReleaseDate: new Date().toDateString(),
        },
      },
    });
    setSingleModalShow(false);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user", "displayName"],
    },
    {
      title: "Employee Id",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
    },
    {
      title: "Added On",
      dataIndex: "createdAt",
      defaultSortOrder: "aescend",
      render: (text) => <p>{moment(text).format("ll")}</p>,
    },
    {
      title: "Applicable On",
      dataIndex: "month",
      defaultSortOrder: "aescend",
      render: (text) => <p>{monthList[text]}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Reason",
      dataIndex: "details",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount, row) => (
        <p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
          {row.type === "debit" ? "-" : "+"}
          {amount}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (data, row) => (
        <Popconfirm title={"Are you sure ?"} onConfirm={() => deleteData(data)}>
          <NormalButton
            type={5}
            icon={deleteLogo}
          />
        </Popconfirm>
      ),
    }
  ];

  return (
    <CommonFunctionality
      columns={columns}
      user={user}
      employeeList={employeeList}
      monthCloseFields={monthCloseFields}
      organisation={organisation}
      deleteData={deleteData}
      loading={loading}
      singleModalShow={singleModalShow}
      bulkModalShow={bulkModalShow}
      setSingleModalShow={setSingleModalShow}
      setBulkModalShow={setBulkModalShow}
      name={name}
      startRefresh={startRefresh}
      reset={reset}
      downloadURL={`${rootUrl}/api/monthclose/generate/excel/fine/${user.organisationId}`}
      actionUrl={`${rootUrl}/api/organisation/validate/fine/${user.organisationId}`}
    >
      <div className="">
        <h2 className="font-size-global-24 font-weight-global-700">Add Fine</h2>
        <Spin spinning={isloading} size="large">
          <div className="org-anb-data-container p-top-15">
            <DropdownComponent
              labeltext={"Employee Name"}
              className="general-ant-field-select"
              value={fieldDetails.id}
              values={employeeList.map((item) => item._id)}
              options={employeeList.map((item) => item.displayName)}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  id: e,
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            ></DropdownComponent>
          </div>

          <div className="org-anb-data-container p-top-15">
            <DropdownComponent
              className="general-ant-field-select"
              labeltext={"Financial Year"}
              value={fieldDetails.financialYear}
              values={financialYearList}
              options={financialYearList}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  financialYear: e,
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            >
              {financialYearList.map((val, index) => {
                return (
                  <Option key={`fyear-${index}`} value={val}>
                    {val}
                  </Option>
                );
              })}
            </DropdownComponent>
          </div>

          <div className="org-anb-data-container p-top-15">
            <DropdownComponent
              className="general-ant-field-select"
              value={fieldDetails.month}
              labeltext="Month"
              values={monthList.map((_val, index) => index)}
              options={monthList}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  month: e,
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {monthList.map((val, index) => {
                return (
                  <Option key={`employees-${index}`} value={index}>
                    {val}
                  </Option>
                );
              })}
            </DropdownComponent>
          </div>

          <div className="org-anb-data-container p-top-15">
            <InputFieldComponent
              labeltext={"Reason for Fine"}
              className="general-ant-field-input"
              value={fieldDetails.meta.actForWhichFineImposed}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  meta: {
                    ...fieldDetailsData.meta,
                    actForWhichFineImposed: e.target.value,
                  },
                });
              }}
              width={"100%"}
            />
          </div>
          <div
            style={{ marginTop: "-2em" }}
            className="org-anb-data-container p-top-15"
          >
            <InputFieldComponent
              labeltext={"Name of Person Present"}
              className="general-ant-field-input"
              value={fieldDetails.meta.nameOfPersonPresenceWhileExplaining}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  meta: {
                    ...fieldDetailsData.meta,
                    nameOfPersonPresenceWhileExplaining: e.target.value,
                  },
                });
              }}
              width={"100%"}
            />
          </div>

          <div
            style={{ marginTop: "-2em" }}
            className="org-anb-data-container p-top-15"
          >
            <DropdownComponent
              className="general-ant-field-select"
              labeltext={"Has employer verified proof"}
              value={fieldDetails.meta.hasEmployeeVerifiedProofs}
              options={["Yes", "No"]}
              values={[true, false]}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  meta: {
                    ...fieldDetailsData.meta,
                    hasEmployeeVerifiedProofs: e,
                  },
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </DropdownComponent>
          </div>

          <div className="org-anb-data-container p-top-15">
            <DateComponent
              labelText={"Date of Offense"}
              width={"100%"}
              className="general-ant-field-input"
              value={
                fieldDetails.meta.dateOfOffense
                  ? moment(
                      new Date(fieldDetails.meta.dateOfOffense),
                      "YYYY-MM-DD"
                    )
                  : ""
              }
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  meta: {
                    ...fieldDetailsData.meta,
                    dateOfOffense: new Date(e).toDateString(),
                  },
                });
              }}
              size={"large"}
            />
          </div>

          <div className="org-anb-data-container p-top-15">
            <InputFieldComponent
              labeltext={"Wages Period"}
              className="general-ant-field-input"
              type={"number"}
              width={"100%"}
              value={parseInt(fieldDetails.meta.wagesPeriod)}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  meta: {
                    ...fieldDetailsData.meta,
                    wagesPeriod: e.target.value,
                  },
                });
              }}
              placeholder="Wages period"
              size={"large"}
            />
          </div>
          <div
            style={{ marginTop: "-2em" }}
            className="org-anb-data-container p-top-15"
          >
            <InputFieldComponent
              className="general-ant-field-input"
              labeltext={"Wages Payable"}
              type={"number"}
              width={"100%"}
              value={parseInt(fieldDetails.meta.wagesPayable)}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  meta: {
                    ...fieldDetailsData.meta,
                    wagesPayable: e.target.value,
                  },
                });
              }}
              placeholder="Wages payable"
              size={"large"}
            />
          </div>

          <div
            style={{ marginTop: "-2em" }}
            className="org-anb-data-container p-top-15"
          >
            <DateComponent
              width={"100%"}
              labelText={"Date of Fine"}
              className="general-ant-field-input"
              value={
                fieldDetails.meta.fineReleaseDate
                  ? moment(
                      new Date(fieldDetails.meta.fineReleaseDate),
                      "YYYY-MM-DD"
                    )
                  : ""
              }
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  meta: {
                    ...fieldDetailsData.meta,
                    fineReleaseDate: new Date(e).toDateString(),
                  },
                });
              }}
              size={"large"}
            />
          </div>

          <div className="org-anb-data-container p-top-15">
            <InputFieldComponent
              className="general-ant-field-input"
              type={"number"}
              labeltext={"Amount ( INR )"}
              width={"100%"}
              value={parseInt(fieldDetails.amount)}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  amount: isNaN(parseInt(e.target.value))
                    ? 0
                    : parseInt(e.target.value),
                });
              }}
              placeholder="Amount"
              size={"large"}
            />
          </div>

          <div className="select-button-container">
            <NormalButton
              type={13}
              buttonText={"Add to the month end data"}
              block={"100%"}
              onClick={submitForm}
              margin={[30, 0]}
            />
          </div>
        </Spin>
      </div>
    </CommonFunctionality>
  );
};

export default Fine;
