import { message, Modal, notification, Popconfirm, Spin } from "antd";
import moment from "moment";
import { useState } from "react";
import { getEmployeeOvertimeAmount } from "../../apis/employee";
import { updateOvertimeAmountByRefID } from "../../apis/organisation";
import { deleteLogo, editLogo } from "../../assets/common/editAndDeleteLogos";
import config, { fnfCalculationBasis, monthList, rootUrl } from "../../utils/config";
import { NormalButton } from "../ComponentLibrary";
import DateComponent from "../InputLibrary/DateComponent/DateComponent";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
import CommonFunctionality from "./components/CommonFunctionality.js";

const Overtime = ({
  monthCloseFields,
  user,
  organisationSettings,
  employeeList,
  organisation,
  startRefresh,
  deleteData,
  loading,
  financialYearList,
  name,
}) => {
  const [isloading, setIsLoading] = useState(false);
  const [singleModalShow, setSingleModalShow] = useState(false);
  const [bulkModalShow, setBulkModalShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  // const [overtimeAmount, setOvertimeAmount] = useState(0);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [updateData, setUpdateData] = useState({
    userId: "",
    month: 0,
    id: "",
    financialYear: 0,
    amount: 0,
  });

  const [fieldDetails, setFieldDetails] = useState({
    id: "",
    type: "credit",
    details: "Overtime",
    subDetails: "Overtime",
    amount: null,
    othertext: "",
    taxable: true,
    month: new Date().getMonth(),
    financialYear: "",
    meta: {
      dateOnOvertimeWorked: "",
      totalOvertimeWorked: null,
      component: "",
      factor: "",
      overtimeRateOfWages: null,
      dateOnOvertimePaid: new Date().toDateString(),
    },
  });

  const submitForm = async () => {
    if (!fieldDetails.id) {
      message.error("Please select an employee");
      return;
    }
    if (!fieldDetails.meta.dateOnOvertimeWorked) {
      message.error("Please enter overtime work date");
      return;
    }
    if (
      !fieldDetails.meta.totalOvertimeWorked ||
      parseInt(fieldDetails.meta.totalOvertimeWorked > 8)
    ) {
      message.error("Please enter valid overtime hours");
      return;
    }
    if (!fieldDetails.financialYear) {
      message.error("Please enter Financial Year");
      return;
    }
    if (!fieldDetails.month && fieldDetails.month !== 0) {
      message.error("Please enter month");
      return;
    }

    setIsLoading(true);
    let response = await organisation.addMonthClose({
      monthClose: {
        ...fieldDetails,
        organisationId: user.organisationId,
      },
    });
    setIsLoading(false);
    if (response.data.success) {
      notification.success({
        message: "Successfully added the overtime details",
      });
    } else {
      notification.error({ message: "Unable to add the overtime details" });
    }
    organisation.updateMonthClose({ organisationId: user.organisationId });
    reset();
  };

  function handleUpdate(data) {
    setUpdateData({
      userId: user._id,
      month: data.month,
      id: data._id,
      financialYear: data.financialYear,
      amount: data.amount,
    });
    setEditModal(true);
  }

  const onUpdate = async () => {
    setIsLoading(true);
    let updatedData = await updateOvertimeAmountByRefID(updateData);
    setIsLoading(false);
    setEditModal(false);
    if (updatedData.data.success) {
      notification.success({
        message: "Successfully Updated the Overtime Amount",
      });
      setSubmitSuccess(!submitSuccess);
    } else {
      notification.error({
        message: updatedData.data.message,
      });
    }
    startRefresh();
  };

  const employeeDetails = async (
    userId,
    component = organisationSettings.overtimeSetting.component,
    overtimeHoursCount = 1,
    month,
    financialYear
  ) => {
    setIsLoading(true);
    const { overtimeSetting } = organisationSettings;
    let overtimeDetails = await getEmployeeOvertimeAmount({
      userId: userId,
      month: month,
      financialYear: financialYear,
      component: component,
      overtimeHoursCount: overtimeHoursCount,
      overtimeSetting: overtimeSetting,
    });
    setIsLoading(false);
    return overtimeDetails;
  };
  const reset = () => {
    setFieldDetails({
      ...{
        id: "",
        type: "credit",
        details: "Overtime",
        subDetails: "Overtime",
        amount: null,
        othertext: "",
        taxable: true,
        month: new Date().getMonth(),
        financialYear: "",
        meta: {
          dateOnOvertimeWorked: "",
          totalOvertimeWorked: 0,
          component: "",
          overtimeRateOfWages: "",
          factor: "",
          dateOnOvertimePaid: new Date().toDateString(),
        },
      },
    });
    setSingleModalShow(false);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user", "displayName"],
    },
    {
      title: "Employee Id",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
    },
    {
      title: "Added On",
      dataIndex: "createdAt",
      defaultSortOrder: "aescend",
      render: (text) => <p>{moment(text).format("ll")}</p>,
    },
    {
      title: "Applicable On",
      dataIndex: "month",
      defaultSortOrder: "aescend",
      render: (text) => <p>{monthList[text]}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Reason",
      dataIndex: "details",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount, row) => (
        <p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
          {row.type === "debit" ? "-" : "+"}
          {amount}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (data, row) => (
        <>
          <Popconfirm
            title={"Are you sure ?"}
            onConfirm={() => deleteData(data)}
          >
            <NormalButton type={5} icon={deleteLogo} />
          </Popconfirm>
          <NormalButton
            type={5}
            icon={editLogo}
            onClick={() => handleUpdate(row)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <CommonFunctionality
        columns={columns}
        user={user}
        employeeList={employeeList}
        monthCloseFields={monthCloseFields}
        organisation={organisation}
        deleteData={deleteData}
        loading={loading}
        singleModalShow={singleModalShow}
        bulkModalShow={bulkModalShow}
        startRefresh={startRefresh}
        setSingleModalShow={setSingleModalShow}
        setBulkModalShow={setBulkModalShow}
        name={name}
        reset={reset}
        downloadURL={`${rootUrl}/api/monthclose/generate/csv/overtime/${user.organisationId}`}
        actionUrl={`${rootUrl}/api/organisation/validate/overtime/${user.organisationId}`}
      >
        <div className="">
          <h2 className="font-size-global-24 font-weight-global-700">
            Add Overtime
          </h2>
          <Spin spinning={isloading} size="large">
            <div className="org-anb-data-container p-top-15">
              <DropdownComponent
                labeltext={"Employee Name"}
                values={employeeList.map((item) => item._id)}
                options={employeeList.map((item) => item.displayName)}
                className="general-ant-field-select"
                value={fieldDetails.id}
                onChange={(e) => {
                  setFieldDetails({
                    ...fieldDetails,
                    id: e,
                  });
                }}
                width={"100%"}
                bordered={false}
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
              />
            </div>
            <div className="org-anb-data-container p-top-15">
              <DropdownComponent
                labeltext={"Financial Year"}
                values={financialYearList}
                options={financialYearList}
                className="general-ant-field-select"
                value={fieldDetails.financialYear}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    financialYear: e,
                  });
                }}
                width={"100%"}
                bordered={false}
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
              ></DropdownComponent>
            </div>
            <div className="org-anb-data-container p-top-15">
              <DropdownComponent
                labeltext={"Month"}
                className="general-ant-field-select"
                value={fieldDetails.month}
                values={monthList.map((_val, index) => index)}
                options={monthList}
                onChange={(e) => {
                  employeeDetails(fieldDetails.id,
                    fnfCalculationBasis[e],
                    parseInt(fieldDetails.meta.totalOvertimeWorked),
                    parseInt(e),
                    fieldDetails.financialYear
                  ).then((res) => {
                    let fieldDetailsData = fieldDetails;
                    setFieldDetails({
                      ...fieldDetailsData,
                      month: parseInt(e),
                      meta: {
                        ...fieldDetailsData.meta,
                        component:
                          organisationSettings.overtimeSetting.component,
                        overtimeRateOfWages: res.data.amountPerHour,
                        totalOvertimeWorked: 1,
                        factor: organisationSettings.overtimeSetting.factor,
                      },
                      amount: res.data.totalOvertimeAmount,
                    });
                  });
                }}
                width={"100%"}
                bordered={false}
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              ></DropdownComponent>
            </div>
            <div className="org-anb-data-container p-top-15">
              <DateComponent
                width={"100%"}
                className="general-ant-field-input"
                value={
                  fieldDetails.meta.dateOnOvertimeWorked
                    ? moment(
                      new Date(fieldDetails.meta.dateOnOvertimeWorked),
                      "YYYY-MM-DD"
                    )
                    : ""
                }
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      dateOnOvertimeWorked: new Date(e).toDateString(),
                    },
                  });
                }}
                size={"large"}
              />
            </div>
            <div className="org-anb-data-container p-top-15">
              <InputFieldComponent
                labeltext={"Overtime Hours worked"}
                min={1}
                max={6}
                className="general-ant-field-input"
                type={"number"}
                width={"100%"}
                value={fieldDetails.meta.totalOvertimeWorked}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      totalOvertimeWorked: parseInt(e.target.value),
                    },
                    amount: Math.round(
                      parseInt(
                        ((parseInt(e.target.value) *
                          fieldDetailsData.meta.overtimeRateOfWages) /
                          organisationSettings.overtimeSetting.factor) *
                        fieldDetailsData.meta.factor
                      )
                    ),
                  });
                }}
                placeholder="Overtime hours worked"
                size={"large"}
              />
            </div>
            <div
              style={{ marginTop: "-2em" }}
              className="org-anb-data-container p-top-15"
            >
              <DropdownComponent
                className="general-ant-field-input"
                width={"100%"}
                value={
                  fieldDetails.meta.component ||
                  organisationSettings.overtimeSetting.component
                }
                options={config.fnfCalculationBasis}
                labeltext="Select Component"
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  employeeDetails(
                    fieldDetailsData.id,
                    fnfCalculationBasis[e],
                    parseInt(fieldDetails.meta.totalOvertimeWorked),
                    fieldDetails.month,
                    fieldDetails.financialYear
                  ).then((res) => {
                    setFieldDetails({
                      ...fieldDetailsData,
                      meta: {
                        ...fieldDetailsData.meta,
                        component: fnfCalculationBasis[e],
                        overtimeRateOfWages: res.data.amountPerHour,
                      },
                      amount: res.data.totalOvertimeAmount,
                    });
                  });
                }}
              />
            </div>
            {/* <div className="org-anb-data-container p-top-15"
          >
            <InputFieldComponent
              className="general-ant-field-input"
              width={"100%"}
              type={"number"}
              labeltext={"Overtime Wage Rate"}
              value={parseInt(fieldDetails.meta.overtimeRateOfWages)}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  meta: {
                    ...fieldDetailsData.meta,
                    overtimeRateOfWages: e.target.value,
                  },
                  amount:e.target.value*fieldDetailsData.meta.totalOvertimeWorked
                });
              }}
              placeholder="Overtime wage rate"
              size={"large"}
            />
          </div> */}
            <div className="org-anb-data-container p-top-15">
              <InputFieldComponent
                className="general-ant-field-input"
                width={"100%"}
                min={1}
                type={"number"}
                labeltext={"Factor"}
                value={parseInt(fieldDetails.meta.factor)}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      factor: parseInt(e.target.value),
                    },
                    amount: Math.round(
                      ((fieldDetailsData.meta.overtimeRateOfWages *
                        fieldDetailsData.meta.totalOvertimeWorked) /
                        organisationSettings.overtimeSetting.factor) *
                      parseInt(e.target.value)
                    ),
                  });
                }}
                placeholder="Factor"
                size={"large"}
              />
            </div>
            <div
              style={{ marginTop: "-2em" }}
              className="org-anb-data-container p-top-15"
            >
              <DateComponent
                width={"100%"}
                labelText={"Date of Payment of Overtime"}
                className="general-ant-field-input"
                value={
                  fieldDetails.meta.dateOnOvertimePaid
                    ? moment(
                      new Date(fieldDetails.meta.dateOnOvertimePaid),
                      "YYYY-MM-DD"
                    )
                    : ""
                }
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      dateOnOvertimePaid: new Date(e).toDateString(),
                    },
                  });
                }}
                size={"large"}
              />
            </div>
            {/* <div

            className="org-anb-data-container p-top-15"
          >
            <InputFieldComponent
              className="general-ant-field-input"
              width={"100%"}
              labeltext={"Amount ( INR )"}
              type={"number"}
              value={parseInt(fieldDetails.amount)}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  amount: isNaN(parseInt(e.target.value))
                    ? 0
                    : parseInt(e.target.value),
                });
              }}
              placeholder="Amount"
              size={"large"}
            />
          </div> */}
            <div className="select-button-container">
              <NormalButton
                type={13}
                buttonText={"Add to the month end data"}
                block={"100%"}
                onClick={submitForm}
                margin={[30, 0]}
              />
            </div>
          </Spin>
        </div>
      </CommonFunctionality>

      <Modal
        visible={editModal}
        centered
        width={400}
        footer={null}
        onCancel={() => {
          setEditModal(false);
          reset();
        }}
      >
        <div className="">
          <h2 className="month-close-cards-data-heading">
            Edit Overtime amount
          </h2>
          <Spin spinning={isloading} size="large">
            <div className="org-anb-data-container p-top-15 ">
              <InputFieldComponent
                className="general-ant-field-input"
                width={"100%"}
                type={"number"}
                labeltext={"Overtime Amount"}
                value={parseInt(updateData.amount)}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    amount: isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value),
                  });
                }}
                placeholder="Overtime Amount"
                size={"large"}
              />
            </div>
            <NormalButton
              type={13}
              buttonText={"Update Amount"}
              block={"100%"}
              onClick={onUpdate}
              margin={[30, 0]}
            />
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default Overtime;
