import { useState, useEffect } from "react";
import messageBox from "../../assets/landingPage/messageBox.svg";
import { InputField, NormalButton } from "../../components/ComponentLibrary";
import { InfoCircleFilled } from "@ant-design/icons";
import huggingFace from "../../assets/landingPage/hugging-face.png";
import { withRouter } from "react-router";
import {
  resetPasswordOtpRequest,
  resetPasswordOtpCheck,
  resetPasswordWithOtp,
} from "../../apis/auth";
import { notification, Spin, Input } from "antd";
import profilePhotoEmployee from "../../assets/Login/emojipng.com-35999@2x.png";
import profilePhotoOrganisation from "../../assets/Login/Group 190.svg";

const PasswordReset = ({ history }) => {
  const [userCredDetails, setUserCredDetails] = useState({});
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [otp, setOpt] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  if (history.location.state === undefined) {
    history.replace("/login");
  }

  const resetPassword = async () => {
    setIsLoading(true);
    if (password.length < 8) {
      notification.error({
        message: "Password should contain aleast 8 characters",
      });
      setIsLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      notification.error({ message: "Passwords does not match" });
      setIsLoading(false);
      return;
    }
    let res = await resetPasswordWithOtp({
      verificationCode: otp,
      username: userCredDetails.username,
      password: password,
    });
    if (res.data.success) {
      setPageState(2);
    } else {
      notification.error({
        message: "Failed to reset password, Please try again.",
      });
    }
    setIsLoading(false);
  };

  const requestOtp = async (username) => {
    let res = await resetPasswordOtpRequest({ verificationCred: username });
    if (res.data.success) {
      setUserCredDetails(res.data.data.userDetails);
      setIsLoading(false);
    } else {
      notification.error({
        message: "Failed to reset password, Please try again.",
      });
      history.replace("/login");
    }
  };

  const verifyOTP = async () => {
    setIsLoading(true);
    let res = await resetPasswordOtpCheck({
      verificationCode: otp,
      username: userCredDetails.username,
    });
    if (!res.data.success) {
      notification.error({ message: "Incorrect code" });
    } else {
      setPageState(1);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (history.location.state) {
      requestOtp(history.location.state.username);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.state]);

  useEffect(() => {
    document.title = 'Reset Password';
  });

  return (
    <div>
      {pageState < 2 && (
        <div className="password-reset-container">
          <div className="flex jusify-center align-center">
            <div className="password-reset-main-container-container">
              <div className="password-reset-main-container">
                <Spin spinning={isLoading} size="large">
                  {pageState === 0 ? (
                    <div>
                      <div className="blue-header-box flex">
                        <div className="m-right-8">
                          <InfoCircleFilled
                            style={{
                              color: "#ffffff",
                            }}
                          />
                        </div>
                        <p className="blue-box-info-text">
                          <span className="blue-box-span">
                            Didn’t receive code?{" "}
                          </span>
                          Allow some time for the code to arrive
                        </p>
                      </div>
                      <div className="password-reset-main-content-conatiner">
                        <div className="message-box-image-container">
                          <img
                            src={messageBox}
                            className="message-box-image"
                            alt="message box"
                          ></img>
                        </div>
                        <p className="password-reset-main-content-header">
                          Update your password
                        </p>
                        <div className="mail-id-container">
                          <p className="mail-id-general-text">
                            Enter the code that was sent to
                          </p>
                          <p className="mail-id-of-user">
                            {userCredDetails.email
                              ? userCredDetails.email
                              : "."}
                          </p>
                        </div>
                        <div className="code-container">
                          <div className="code-input-container">
                            <InputField
                              type="number"
                              value={otp}
                              onChange={(e) => {
                                String(e.target.value).length <= 6 &&
                                e.target.value >= 0 &&
                                e.target.value <= 999999
                                  ? String(e.target.value).length <= 0
                                    ? setOpt("")
                                    : setOpt(String(e.target.value))
                                  : setOpt(otp);
                              }}
                              maxLength={6}
                            />
                          </div>
                          {/* <p className="resend-mail-text">Resend code</p> */}
                        </div>
                        <div className="password-reset-actions-button-container">
                          <NormalButton
                            type={5}
                            buttonText="Back"
                            onClick={() => history.replace("/login")}
                          />
                          <NormalButton
                            type={1}
                            buttonText="Verify"
                            margin={[0, 16]}
                            onClick={verifyOTP}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="password-reset-main-content-conatiner">
                      <p className="password-reset-main-content-header">
                        Update your password
                      </p>
                      <div className="user-info-holder">
                        <div
                          className="user-logo-container"
                          style={{ marginRight: 15 }}
                        >
                          {userCredDetails.role === "employee" ? (
                            <img
                              src={profilePhotoEmployee}
                              className="employee-avatar"
                              alt="employee avatar"
                            ></img>
                          ) : userCredDetails.role === "org" ? (
                            <img
                              src={profilePhotoOrganisation}
                              className="organisation-avatar"
                              alt="organisation avatar"
                            ></img>
                          ) : userCredDetails.role === "ca" ? (
                            <img
                              src={profilePhotoEmployee}
                              className="employee-avatar"
                              alt="chartered accountant avatar"
                            ></img>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="user-info">
                          <p className="userType">{userCredDetails.role}</p>
                          <p className="userName">{userCredDetails.name}</p>
                        </div>
                      </div>
                      <div className="password-fields-container">
                        <div className="password-field-container">
                          <p className="password-field-label">New Password</p>
                          <Input.Password
                            className="input-login"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="password-field-container">
                          <p className="password-field-label">
                            Repeat Password
                          </p>
                          <Input.Password
                            className="input-login"
                            onPressEnter={resetPassword}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="password-reset-actions-button-container-1">
                        <NormalButton
                          type={1}
                          buttonText="Update Password"
                          margin={[0, 0]}
                          onClick={resetPassword}
                        />
                      </div>
                    </div>
                  )}
                </Spin>
              </div>
            </div>
            <div className="hero-text-container">
              <p className="hero-text">
                With maximum amount of work it’s common to forget things at
                times
                <img src={huggingFace} className="hugging-face-image" alt=""></img>
              </p>
            </div>
          </div>
        </div>
      )}
      {pageState === 2 && (
        <div className="password-reset-successfull-container">
          <div className="password-reset-successfull-text-box">
            <p className="password-reset-successfull-heading">
              New password has been set.
            </p>
            <p className="password-reset-successfull-text">
              Log-in with your new credential and continue the good work.
            </p>
            <div className="password-reset-successfull-button-container">
              <NormalButton
                buttonText="Log in"
                onClick={() => history.replace("/login")}
                type={1}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(PasswordReset);
