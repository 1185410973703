import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import "./style.less";
import { Input } from "antd";

const Search = ({ data = [], setSearchLogs=()=>{}, styles }) => {
  const [searchString, setSearchString] = useState("");
  const searchInputRef = useRef();

  const style = styles?styles:{}

  useEffect(() => {
    let result = data?data.filter((d) => {
      return (
        new Date(d.dateTime).toDateString().includes(searchString) ||
        d.name.includes(searchString) ||
        d.message.includes(searchString) ||
        d.userId.includes(searchString) ||
        new Date(d)
          .toLocaleTimeString("en-US", {
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          })
          .includes(searchString)
      );
    }):[];
    setSearchLogs(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);


  return (
    <Input
      placeholder="Search for anything..."
      size="large"
      style={style}
      ref={searchInputRef}
      onPressEnter={(e) => setSearchString(e.target.value)}
      prefix={<SearchOutlined style={{ color: "#9ea0a8" }} />}
    />
  );
};

export default Search;
