import { Modal, Popconfirm } from "antd";
import CurrentMonthClosingFields from "./components/currentMonthClosingFields.js";
import config from "../../utils/config";
import moment from "moment";
import { NormalButton } from "../ComponentLibrary";
import { deleteLogo } from "../../assets/common/editAndDeleteLogos";
import { useState } from "react";
import axios from "../../apis";
import OvertimeLOPFields from "./components/OvertimeLOPFields.js";
import LopFiles from "./components/LOPOvertime.js";

const OvertimeLop = ({
  monthCloseFields,
  user,
  employeeList,
  organisation,
  deleteData,
  loading,
  startRefresh,
  organisationSettings,
}) => {
  const [singleModalShow, setSingleModalShow] = useState(false);
  const [bulkModalShow, setBulkModalShow] = useState(false);

  const handleAction = async (url, name) => {
    let res = await axios({
      url, //your url
      method: "GET",
      responseType: "blob",
    });
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = urlDownload;
    link.setAttribute("download", name); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user", "displayName"],
    },
    {
      title: "Employee Id",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
    },
    {
      title: "Added On",
      dataIndex: "createdAt",
      defaultSortOrder: "aescend",
      render: (text) => <p>{moment(text).format("ll")}</p>,
    },
    {
      title: "Applicable On",
      dataIndex: "month",
      defaultSortOrder: "aescend",
      render: (text) => <p>{config.monthList[text]}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Reason",
      dataIndex: "details",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount, row) => (
        <p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
          {row.type === "debit" ? "-" : "+"}
          {amount}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (data, row) => (
        <Popconfirm title={"Are you sure ?"} onConfirm={() => deleteData(data)}>
          <NormalButton type={5} icon={deleteLogo} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Modal
        visible={singleModalShow}
        centered
        width={400}
        footer={null}
        onCancel={() => setSingleModalShow(false)}
      >
        <OvertimeLOPFields
          employeeList={employeeList}
          organisationSettings={organisationSettings}
          user={user}
          organisation={organisation}
          setSingleModalShow={setSingleModalShow}
        />
      </Modal>
      <Modal
        visible={bulkModalShow}
        centered
        width={400}
        footer={null}
        onCancel={() => setBulkModalShow(false)}
      >
        <LopFiles
          organisationId={user.organisationId}
          employeeList={employeeList}
          user={user}
          organisation={organisation}
          setBulkModalShow={setBulkModalShow}
          handleAction={handleAction}
        />
      </Modal>
      <CurrentMonthClosingFields
        monthCloseFields={monthCloseFields}
        columns={columns}
        loading={loading}
        startRefresh={startRefresh}
        setSingleUploadModal={setSingleModalShow}
        setBulkUploadModal={setBulkModalShow}
        name={"General"}
      />
    </>
  );
};

export default OvertimeLop;
