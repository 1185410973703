import React from "react";
import { Tabs } from "antd";
import BankDetails from "./TabContents/bankDetails";
import Resignation from "./TabContents/resignation";
import PersonalInformation from "./TabContents/personalInformation";
import EmployeeDocument from "./TabContents/document";
import ContactInfo from "./TabContents/contactInfo";
import CredentialsContainer from "./TabContents/Credntials";
import { createLog } from "../../../apis/user";
const { TabPane } = Tabs;
const EmployeeDetails = ({
  user,
  address,
  familyDetail,
  authReducer,
  document,
  credentials,
  contactInfo,
  bankInformation,
  employeeReducer,
  separations,
  updateOnboarding
}) => {
  const updateInfo = async () => {
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Employee has updated its information`,
      category: 2,
    });
    authReducer.updateUser(user._id);
  };

  const updateFunction = (data) => {
    employeeReducer.employeeNewSettlement(data);
  }

  return (
    <div className="declaration-page-main-tabs-container">
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <div className="tabs">
              <span className="tab-name">Personal</span>
            </div>
          }
          key="1"
        >
          <div className="personal-info-container">
            <PersonalInformation
              user={user}
              addresses={address}
              familyDetail={familyDetail}
              updateInfo={updateInfo}
              updateOnboarding={updateOnboarding}
            />
            <ContactInfo
              contactInfo={contactInfo}
              user={user}
              updateInfo={updateInfo}
            />
            <CredentialsContainer
              credentials={credentials}
              user={user}
              updateInfo={updateInfo}
              updateOnboarding={updateOnboarding}
            />
            <BankDetails
              bankInformation={bankInformation}
              user={user}
              updateInfo={updateInfo}
              updateOnboarding={updateOnboarding}
            />
          </div>
        </TabPane>
        <TabPane
          tab={
            <div className="tabs">
              <span className="tab-name">Documents</span>
            </div>
          }
          key="2"
        >
          <div className="personal-info-container">

            <EmployeeDocument
              document={document}
              user={user}
              authReducer={authReducer}
              employeeReducer={employeeReducer}
            />
          </div>
        </TabPane>
        <TabPane
          tab={
            <div className="tabs">
              <span className="tab-name">Resignation</span>
            </div>
          }
          key="3"
        >
          <Resignation
            user={user}
            employeeReducer={employeeReducer}
            authReducer={authReducer}
            separations={separations}
            updateFunction={updateFunction}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default EmployeeDetails;
