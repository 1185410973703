import TDSIcon from "../../../assets/TDSIcon.svg";
import PFIcon from "../../../assets/PFIcon.svg";
import { useEffect, useState } from "react";
import config from "../../../utils/config";

const TaxSheetDownloadCard = ({ payslips }) => {
  const [totalTDS, setTotalTDS] = useState(0);
  const [totalPF, setTotalPF] = useState(0);

  useEffect(() => {
    let tds = 0;
    let pf = 0;
    payslips.forEach((p) => {
      tds +=
        p.user.regimeOptedStatus === 1
          ? p.finalPayslip.newRegime.incomeTax
          : p.finalPayslip.oldRegime.incomeTax;
      pf += p.finalPayslip.employee_PF;
    });
    setTotalPF(pf)
    setTotalTDS(tds)
  }, [payslips]);
  return (
    <div className="ctc-card-container">
      <div className="header-component">
        <h3 className="app-heading-3 font-weight-global-700">Deductions</h3>
      </div>
      <div className="info-container">
        <div className="flex align-center">
          <img alt="TDS" src={TDSIcon} height={45} width={45} />
          <div className="ctc-data-container">
            <h3 className="card-data-heading">TDS (Per year)</h3>
            <span className="amount">{totalTDS} INR</span>
          </div>
        </div>

        <div className="separator"></div>
        <div className="flex align-center">
          <img alt="TDS" src={PFIcon} height={45} width={45} />
          <div className="ctc-data-container">
            <h3 className="card-data-heading">Total PF</h3>
            <span className="amount">{config.numformatter(totalPF)} INR</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaxSheetDownloadCard;
