import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Layout, Tag, notification } from "antd";
import { updateMoolSettings } from "../../../../apis/organisation";
import moollogo from '../../../../assets/orgloginflow/Group 25.svg'
import discountlogo from '../../../../assets/orgloginflow/Badge Discount.svg'
import pielogo from '../../../../assets/orgloginflow/Pie 04.svg'
const { Content } = Layout;

const Structure = ({ changePageStateBack, changePageState, changeCustomStructure, setIsPercentageStructure }) => {
  const [loading, setLoading] = useState(false);
  const [backLoading, setBackLoading] = useState(false);

  const [active, setActive] = useState(0);

  const selectStructure = async (type) => {
    setLoading(true);
    changeCustomStructure(type);
    // !type because we are taking custom structure as true in the flow
    let resPercentStructure = await updateMoolSettings({
      moolSettingField: "isPercentageStructure",
      moolSettingValue: false
    })
    let res = await updateMoolSettings({ moolSettingField: "structureOfMool", moolSettingValue: !type })
    if (res.data.success && resPercentStructure.data.success) {
      changeCustomStructure(type);
      setIsPercentageStructure(false);
      setLoading(false)
      await changePageState();
    } else {
      setIsPercentageStructure(type);
      setLoading(false)
    }
    setLoading(false)
    await changePageState();
  };

  const selectPercentageStructure = async (type) => {
    setLoading(true);
    // !type because we are taking custom structure as true in the flow
    let res = await updateMoolSettings({ moolSettingField: "isPercentageStructure", moolSettingValue: true })
    if (res.data.success) {
      setIsPercentageStructure(true);
      setLoading(false)
      await changePageState();
    } else {
      setIsPercentageStructure(false);
      setLoading(false)
    }
  };
  const handleSubmit = () => {
    if (active === 0) {
      notification.error({ message: 'Select an option to proceed' });
    }
    else {
      active === 2 ? selectPercentageStructure(true) :
        (active === 1 ? selectStructure(false) : selectStructure(true));
    }
  }
  return (
      <Content className="stages">
        <div className="structure-container">
          <p className="structure-title">
            We'll streamline your setup experience accordingly
          </p>
          <div className="structure-types">
            <div id={active === 1 ? 'active' : ''} style={{ position: 'relative' }}
              className="structure-types-container" onClick={() => {
                setActive(1)
              }}>
              <div className="structure-logo">
                <img style={{ width: '20px' }} src={moollogo} alt="" />
              </div>
              <div className="structure-content">
                <p className="structure-types-heading">Mool System</p>
                <p className="structure-types-description">
                  The Mool System custom creates salary structures personalized for each employee in your organization allowing them to get the maximum tax benefits from their CTC.(Recommended)
                </p>
              </div>
              {/* <NormalButton
                onClick={() => selectStructure(false)}
                type={6}
                heightWidth={[35, 35]}
                buttonText={<RightOutlined />}
                padding={[2]}
                margin={[30, 0]}
              /> */}
              <Tag style={{ position: 'absolute', top: '10px', right: '-9px' }} color="#f05050">Recommended</Tag>
            </div>
            <div id={active === 2 ? 'active' : ''} className="structure-types-container" onClick={() => {
              setActive(2)
            }}>
              <div className="structure-logo3">
                <img style={{ width: '20px' }} src={pielogo} alt="" />
              </div>
              <div className="structure-content">
                <p className="structure-types-heading">Percentage Structure</p>
                <p className="structure-types-description">
                  In this system, you'd be defining the pay structure of all employees in your organization in the traditional manner. In this case, employees only get limited tax benefits.

                </p>
              </div>
              {/* <NormalButton
                onClick={() => selectStructure(true)}
                type={6}
                heightWidth={[35, 35]}
                buttonText={<RightOutlined />}
                padding={[2]}
                margin={[30, 0]}
              /> */}
            </div>
            <div id={active === 3 ? 'active' : ''} className="structure-types-container" onClick={() => {
              setActive(3)
            }}>
              <div className="structure-logo2">
                <img style={{ width: '20px' }} src={discountlogo} alt="" />
              </div>
              <div className="structure-content">
                <p className="structure-types-heading">Custom Structure</p>
                <p className="structure-types-description">
                  Define your own rules for allowances and sync them with Mool System to custom create salary structures. Tax benefits offered to employees will be limited.
                </p>
              </div>
              {/* <NormalButton
                onClick={selectPercentageStructure}
                type={6}
                heightWidth={[35, 35]}
                buttonText={<RightOutlined />}
                padding={[2]}
                margin={[30, 0]}
              /> */}
            </div>
          </div>
        </div>
        <div className="submit-container">
          <Button
            loading={loading}
            disabled={backLoading}
            className="submit-org"
            onClick={handleSubmit}
            style={{ background: '#405cd2', padding:'15px', height:'60px', fontSize:'1.125rem' }}
            htmlType="submit"
          >
            Save and Continue
          </Button>
          <Button
            loading={backLoading}
            disabled={loading}
            onClick={async () => {
              setBackLoading(true);
              await changePageStateBack()
              setBackLoading(false);
            }}
            style={{ marginRight: 10, padding:'15px', height:'60px', fontSize:'1.125rem' }}
            className="skip-button"
            type="dashhed"
            htmlType="submit"
          >
            Back
          </Button>
        </div>
      </Content>
  );
};

export default withRouter(Structure);
