import { DatePicker } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { checkFinancialYear, rootUrl } from "../../../utils/config";
import Spinner from "../../ComponentLibrary/Spinner/Spinner";
import { employeeCards, pdf } from "../../OrganisationReport/ComplianceReport/data";
import DisplayBlocks from "./../../../components/ComponentLibrary/DisplayBlocks/DisplayBlocks";

const HookURL = `generate/employee/reports/`;

const Documents = ({ id }) => {
  const [isAPILoading, setIsAPILoading] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth());
  const [financialYear, setFinancialYear] = useState(checkFinancialYear().financialYear);
  const [reportDate, setReportDate] = useState("");

  const handelDateChange = (date) => {
    let tempMonth = Number(moment(date).format("M"));
    let tempYear = Number(moment(date).format("yyyy"));
    let tempFinancialYear =
      tempMonth > 3
        ? `${tempYear}-${tempYear + 1}`
        : `${tempYear - 1}-${tempYear}`;
    setMonth(tempMonth - 1);
    setFinancialYear(tempFinancialYear);
    setReportDate(date);
  };
  return (
    <Spinner spinning={isAPILoading} size={"large"}>
      <div
        className="site-layout"
        style={{
          backgroundColor: "#fff",
          padding: "1.5rem 2rem",
          height: "80vh",
          borderRadius: "10px",
        }}
      >
        <div className="flex align-center justify-between">
          <h5 className="h5-bold-20 m-bottom-30">Employee Compliance Documents</h5>
          <div className="flex align-center">
            <p className="font-weight-700 text-size-14 mb-2 ml-5">Month:</p>
            <DatePicker
              format={"YYYY - MM"}
              className="app-date-picker month-picker"
              onChange={handelDateChange}
              picker={"month"}
              value={reportDate}
            />
          </div>
        </div>
        {
          employeeCards.map((val, index) => <DisplayBlocks
            key={`doc-value-${index}`}
            margin={[15, 0]}
            bgcolor={"#f4f4f6"}
            width={"95%"}
            padding={[10, 20]}
            borderRadius={"5px"}
            iconTextColor={"white"}
            iconFontSize={"0.8rem"}
            iconWidth={"2rem"}
            displayText={val.fileName}
            downloadLink={`${rootUrl}/api/${HookURL}${val.category}/${id}/${financialYear}/${month}`}
            downloadType={[pdf]}
            setIsAPILoading={setIsAPILoading}
          />)
        }
      </div>
    </Spinner>
  );
};

export default Documents;
