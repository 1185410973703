import { Checkbox, Col, Drawer, notification, Radio, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  updateAddress,
  updateEducationAndQualification,
  updateEmergencyContact,
  updateNominee,
  updatePersonalInfo
} from "../../../../apis/user";
import blueEditIcon from "../../../../assets/icons/blueEditIcon.png";
import { pincodeData } from "../../../../constants/common_functions";
import { isValidDate, states, statesCodes } from "../../../../utils/config";
import { NormalButton } from "../../../ComponentLibrary";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import DateComponent from "../../../InputLibrary/DateComponent/DateComponent";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";

const status = [
  { label: "Employed", value: true },
  { label: "UnEmployed", value: false },
];

const relationshipStatus = ["Married", "Unmarried", "Divorced"]; 

const PersonalInformation = ({
  user,
  updateInfo,
  familyDetail,
  updateOnboarding,
}) => {
  const [editablePI, setEditablePI] = useState(true);
  const [loading, setLoading] = useState(false);
  const [familyDetails, setfamilyDetails] = useState({
    phoneNumber: "",
    dateOfBirth: "",
    gender: "",
    maritalStatus: 0,
    spousEmployementStatus: 0,
    fathersName: "",
    mothersName: "",
    fathersDOB: "",
    mothersDOB: "",
    isFatherDisabled: false,
    isMotherDisabled: false,
  });

  const [nomineeList, setNomineeList] = useState({
    nomineeOne: {
      name: "",
      relationship: "",
      otherRelationship: "",
      dateOfBirth: "",
      aadhaar: "",
    },
    nomineeTwo: {
      name: "",
      relationship: "",
      otherRelationship: "",
      dateOfBirth: "",
      aadhaar: "",
    },
    nomineeThree: {
      name: "",
      relationship: "",
      otherRelationship: "",
      dateOfBirth: "",
      aadhaar: "",
    },
  });

  const [address, setAddress] = useState({
    present: {
      addressLineOne: "",
      addressLineTwo: "",
      state: "",
      pincode: "",
      landmark: "",
      city: "",
      country: "India",
    },
    permanent: {
      addressLineOne: "",
      addressLineTwo: "",
      state: "",
      pincode: "",
      landmark: "",
      city: "",
      country: "India",
    },
    presentSameAsPermanent: false,
  });

  const [education, setEducation] = useState({
    degree: "",
    collegeName: "",
    university: "",
    address: "",
    startDate: "",
    endDate: "",
  });

  const [emergencyContact, setEmergencyContact] = useState({
    name: "",
    relationship: "",
    address: "",
    contactNo: "",
    countryCode: "+91",
  });

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (user.familyDetails) {
      setfamilyDetails({
        gender: familyDetail.gender ? familyDetail.gender : "",
        maritalStatus: familyDetail.maritalStatus
          ? familyDetail.maritalStatus
          : 0,
        phoneNumber: familyDetail.phoneNumber
          ? familyDetail.phoneNumber
          : user.phone,
        spousEmployementStatus: familyDetail.spousEmployementStatus
          ? familyDetail.spousEmployementStatus
          : 0,
        fathersDOB: familyDetail.fathersDOB ? familyDetail.fathersDOB : "",
        mothersDOB: familyDetail.mothersDOB ? familyDetail.mothersDOB : "",
        fathersName: familyDetail.fathersName ? familyDetail.fathersName : "",
        mothersName: familyDetail.mothersName ? familyDetail.mothersName : "",
        isFatherDisabled: familyDetail.isFatherDisabled
          ? familyDetail.isFatherDisabled
          : false,
        isMotherDisabled: familyDetail.isMotherDisabled
          ? familyDetail.isMotherDisabled
          : false,
        noOfChild: familyDetail.noOfChild ? familyDetail.noOfChild : 0,
        dateOfBirth: familyDetail.dateOfBirth ? familyDetail.dateOfBirth : "",
      });
    }
    if (user.address) {
      const { present, permanent, presentSameAsPermanent } = user.address;
      let tempPresent = {};
      let tempPermanent = {};

      if (permanent) {
        tempPermanent = {
          addressLineOne: permanent.addressLineOne || "",
          addressLineTwo: permanent.addressLineTwo || "",
          state: permanent.state || "",
          pincode: permanent.pincode || "",
          landmark: permanent.landmark || "",
          city: permanent.city || "",
          country: "India",
        };
      }
      if (present) {
        tempPresent = {
          addressLineOne: present.addressLineOne || "",
          addressLineTwo: present.addressLineTwo || "",
          state: present.state || "",
          pincode: present.pincode || "",
          landmark: present.landmark || "",
          city: present.city || "",
          country: "India",
        };
      }

      setAddress({
        permanent: { ...tempPermanent },
        present: { ...tempPresent },
        presentSameAsPermanent: presentSameAsPermanent
          ? presentSameAsPermanent
          : false,
      });
    }
    if (user.educationAndQualification) {
      setEducation({
        degree: user.educationAndQualification.degree || "",
        collegeName: user.educationAndQualification.collegeName || "",
        university: user.educationAndQualification.university || "",
        address: user.educationAndQualification.address || "",
        startDate: user.educationAndQualification.startDate || "",
        endDate: user.educationAndQualification.endDate || "",
      });
    }

    if (user.emergencyContact) {
      setEmergencyContact({
        name: user.emergencyContact.name || "",
        relationship: user.emergencyContact.relationship || "",
        address: user.emergencyContact.address || "",
        contactNo: user.emergencyContact.contactNo || "",
        countryCode: "+91",
      });
    }

    if (user.nominee) {
      setNomineeList({
        nomineeOne: {
          name: user.nominee.nomineeOne.name || "",
          relationship: user.nominee.nomineeOne.relationship || "",
          otherRelationship: user.nominee.nomineeOne.otherRelationship || "",
          dateOfBirth: user.nominee.nomineeOne.dateOfBirth || "",
          aadhaar: user.nominee.nomineeOne.aadhaar || "",
        },
        nomineeTwo: {
          name: user.nominee.nomineeTwo ? user.nominee.nomineeTwo.name : "",
          relationship: user.nominee.nomineeTwo
            ? user.nominee.nomineeTwo.relationship
            : "",
          otherRelationship: user.nominee.nomineeTwo
            ? user.nominee.nomineeTwo.otherRelationship
            : "",
          dateOfBirth: user.nominee.nomineeTwo
            ? user.nominee.nomineeTwo.dateOfBirth
            : "",
          aadhaar: user.nominee.nomineeTwo
            ? user.nominee.nomineeTwo.aadhaar
            : "",
        },
        nomineeThree: {
          name: user.nominee.nomineeTwo ? user.nominee.nomineeThree.name : "",
          relationship: user.nominee.nomineeTwo
            ? user.nominee.nomineeThree.relationship
            : "",
          otherRelationship: user.nominee.nomineeTwo
            ? user.nominee.nomineeThree.otherRelationship
            : "",
          dateOfBirth: user.nominee.nomineeTwo
            ? user.nominee.nomineeThree.dateOfBirth
            : "",
          aadhaar: user.nominee.nomineeTwo
            ? user.nominee.nomineeThree.aadhaar
            : "",
        },
      });
    }

    setLoading(false);
  }, [
    familyDetail,
    user.address,
    user.educationAndQualification,
    user.emergencyContact,
    user.familyDetails,
    user.nominee,
    user.phone,
  ]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const submitFormPI = async () => {
    setLoading(true);
    setEditablePI(false);
    await updateAddress({ address, id: user._id });
    await updateEducationAndQualification({ ...education, id: user._id });
    await updateNominee({ ...nomineeList, id: user._id });
    await updateEmergencyContact({ ...emergencyContact, id: user._id });
    let res = await updatePersonalInfo({ ...familyDetails, id: user._id });
    if (res.data.success) {
      await updateOnboarding("personalInformation");
      await updateOnboarding("address");
      await updateInfo();
      notification.success({
        message: "Personal Information Has Been Updated Successfully",
      });
    } else {
      notification.error({
        message: "Sorry unable to update the personal information",
      });
    }
    setLoading(false);
    onClose();
  };

  const personalInfo = (
    <>
      <div className="header-container">
        <p className="font-size-global-24 font-weight-global-700">Personal Information</p>
      </div>
      <Row gutter={[55, 0]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <div className="phone-number-holder modal-margin-top-add-global-2">
              {/* <span className="phone-country-code">+91</span> */}
              <InputFieldComponent
                className="general-ant-field-input phone-real-number"
                type="number"
                labeltext={"Phone Number"}
                style={{ marginLeft: "5px", width: "70%" }}
                name="phoneNumber"
                value={familyDetails.phoneNumber}
                onChange={(e) => {
                  let newPhoneDetails = {
                    ...familyDetails,
                    phoneNumber: e.target.value,
                  };
                  setfamilyDetails({ ...newPhoneDetails });
                }}
              />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info modal-margin-top-add-global-2">
            <DateComponent
              className="profile-data-display"
              value={
                isValidDate(new Date(familyDetails.dateOfBirth))
                  ? moment(new Date(familyDetails.dateOfBirth), "YYYY-MM-DD")
                  : moment(new Date(), "YYYY-MM-DD")
              }
              onChange={(e) => {
                let newFamilyDetails = {
                  ...familyDetails,
                  dateOfBirth: moment(new Date(e)).format("MM/DD/YYYY"),
                };
                setfamilyDetails({ ...newFamilyDetails });
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <DropdownComponent
              className="general-ant-field-select "
              labeltext={"Gender"}
              style={{ width: "100%" }}
              size={"large"}
              value={familyDetails.gender}
              options={["Male", "Female", "Other"]}
              values={["M", "F", "O"]}
              onChange={(e) => {
                setfamilyDetails({
                  ...familyDetails,
                  gender: e,
                });
              }}
            ></DropdownComponent>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <DropdownComponent
              className="general-ant-field-select"
              labeltext={"Relationship Status"}
              style={{ width: "100%" }}
              size={"large"}
              value={familyDetails.maritalStatus}
              values={[0, 1, 2]}
              options={relationshipStatus}
              onChange={(e) => {
                let newFamilyDetails = {
                  ...familyDetails,
                  maritalStatus: e,
                };
                setfamilyDetails({ ...newFamilyDetails });
              }}
            ></DropdownComponent>
          </div>
        </Col>
      </Row>
    </>
  );

  const presentAddress = (
    <>
      <div className='header-container'>
        <p className='font-size-global-24 font-weight-global-700'>
          Present Address
        </p>
      </div>
      <Row gutter={[55, 0]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className='employee-info '>
            <InputFieldComponent
              className='general-ant-field-input phone-real-number'
              type='number'
              labeltext={"Pincode"}
              style={{ marginLeft: "5px" }}
              name='present'
              value={address.present.pincode}
              onChange={(e) => {
                if (e.target.value.length > 6) {
                  return;
                }
                let newAddress = {
                  ...address.present,
                  pincode: e.target.value,
                };
                setAddress({ ...address, present: newAddress });
                if (e.target.value.length === 6) {
                  let newAddress = {
                    ...address.present,
                    pincode: e.target.value,
                  };
                  setAddress({ ...address, present: newAddress });
                  const data = pincodeData(e.target.value)
                  Promise.resolve(data).then((res) => {
                    let newAddress = {
                      ...address.present,
                      pincode: e.target.value,
                      city: res.Block,
                      state: res.State,
                    };
                    setAddress({ ...address, present: newAddress });
                  });
                }
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className='employee-info'>
            <InputFieldComponent
              labeltext={"Address One"}
              className='general-ant-field-input phone-real-number'
              style={{ marginLeft: "5px" }}
              name='present'
              value={address.present.addressLineOne}
              onChange={(e) => {
                let newAddress = {
                  ...address.present,
                  addressLineOne: e.target.value,
                };
                setAddress({ ...address, present: newAddress });
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className='employee-info'>
            <InputFieldComponent
              className='general-ant-field-input phone-real-number'
              labeltext={"Address Two"}
              style={{ marginLeft: "5px" }}
              name='present'
              value={address.present.addressLineTwo}
              onChange={(e) => {
                let newAddress = {
                  ...address.present,
                  addressLineTwo: e.target.value,
                };
                setAddress({ ...address, present: newAddress });
              }}
            />
          </div>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12}>
          <div className='employee-info'>
            <InputFieldComponent
              className='general-ant-field-input phone-real-number'
              style={{ marginLeft: "5px" }}
              labeltext={"Landmark"}
              name='present'
              value={address.present.landmark}
              onChange={(e) => {
                let newAddress = {
                  ...address.present,
                  landmark: e.target.value,
                };
                setAddress({ ...address, present: newAddress });
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className='employee-info'>
            <InputFieldComponent
              className='general-ant-field-input phone-real-number'
              style={{ marginLeft: "5px" }}
              labeltext={"City"}
              name='present'
              value={address.present.city}
              onChange={(e) => {
                let newAddress = {
                  ...address.present,
                  city: e.target.value,
                };
                setAddress({ ...address, present: newAddress });
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className='employee-info'>
            <DropdownComponent
              style={{ width: "100%" }}
              labeltext={"State"}
              value={address.present.state}
              values={Object.keys(statesCodes)}
              className='single-employee-profile-select'
              onChange={(val) => {
                let newAddress = {
                  ...address.present,
                  state: val,
                };
                setAddress({ ...address, present: newAddress });
              }}
              options={Object.keys(states)}
            ></DropdownComponent>
          </div>
        </Col>
      </Row>

      <div></div>
    </>
  );


  const permanentAddress = (
    <>
      <div className='header-container'>
        <p className='font-size-global-24 font-weight-global-700'>
          Permanent Address
        </p>
      </div>
      <div
        style={{ marginBottom: "15px" }}
        className='modal-margin-top-add-global-2'
      >
        <Checkbox
          checked={address.presentSameAsPermanent}
          onChange={(e) => {
            if (e.target.checked) {
              setAddress({
                ...address,
                permanent: { ...address.present },
                presentSameAsPermanent: true,
              });
            } else {
              setAddress({
                ...address,
                permanent: {
                  addressLineOne: "",
                  addressLineTwo: "",
                  state: "",
                  pincode: "",
                  landmark: "",
                  city: "",
                  country: "INDIA",
                },
                presentSameAsPermanent: false,
              });
            }
          }}
        >
          <h3>My permanent address is same as the present address</h3>
        </Checkbox>
      </div>
      {!address.presentSameAsPermanent && (
        <>
          <Row gutter={[55, 0]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className='employee-info'>
                <InputFieldComponent
                  className='general-ant-field-input phone-real-number'
                  type='number'
                  labeltext={"Pincode"}
                  style={{ marginLeft: "5px" }}
                  name='permanent'
                  value={address.permanent.pincode}
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      return;
                    }
                    let newAddress = {
                      ...address.permanent,
                      pincode: e.target.value,
                    };
                    setAddress({ ...address, permanent: newAddress });
                    if (e.target.value.length === 6) {
                      let newAddress = {
                        ...address.permanent,
                        pincode: e.target.value,
                      };
                      setAddress({ ...address, permanent: newAddress });
                      const data = pincodeData(e.target.value);
                      Promise.resolve(data).then((res) => {
                        let newAddress = {
                          ...address.permanent,
                          pincode: e.target.value,
                          city: res.Block,
                          state: res.State,
                        };
                        setAddress({ ...address, permanent: newAddress });
                      });
                    }
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className='employee-info'>
                <InputFieldComponent
                  className='general-ant-field-input phone-real-number'
                  style={{ marginLeft: "5px" }}
                  labeltext={"Address One"}
                  name='permanent'
                  value={address.permanent.addressLineOne}
                  onChange={(e) => {
                    let newAddress = {
                      ...address.permanent,
                      addressLineOne: e.target.value,
                    };
                    setAddress({ ...address, permanent: newAddress });
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className='employee-info'>
                <InputFieldComponent
                  className='general-ant-field-input phone-real-number'
                  style={{ marginLeft: "5px" }}
                  labeltext={"Address Two"}
                  name='permanent'
                  value={address.permanent.addressLineTwo}
                  onChange={(e) => {
                    let newAddress = {
                      ...address.permanent,
                      addressLineTwo: e.target.value,
                    };
                    setAddress({ ...address, permanent: newAddress });
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className='employee-info'>
                <InputFieldComponent
                  className='general-ant-field-input phone-real-number'
                  style={{ marginLeft: "5px" }}
                  labeltext={"Landmark"}
                  name='present'
                  value={address.permanent.landmark}
                  onChange={(e) => {
                    let newAddress = {
                      ...address.permanent,
                      landmark: e.target.value,
                    };
                    setAddress({ ...address, permanent: newAddress });
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className='employee-info'>
                <InputFieldComponent
                  className='general-ant-field-input phone-real-number'
                  style={{ marginLeft: "5px" }}
                  labeltext={"City"}
                  name='present'
                  value={address.permanent.city}
                  onChange={(e) => {
                    let newAddress = {
                      ...address.permanent,
                      city: e.target.value,
                    };
                    setAddress({ ...address, permanent: newAddress });
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className='employee-info'>
                <DropdownComponent
                  style={{ width: "100%" }}
                  labeltext={"State"}
                  value={address.permanent.state}
                  values={Object.keys(statesCodes)}
                  className='single-employee-profile-select'
                  onChange={(val) => {
                    let newAddress = {
                      ...address.permanent,
                      state: val,
                    };
                    setAddress({ ...address, permanent: newAddress });
                  }}
                  options={Object.keys(states)}
                ></DropdownComponent>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );

  const educationInfo = (
    <>
      <div className="header-container">
        <p className="font-size-global-24 font-weight-global-700 modal-margin-top-add-global-2">
          Education and Qualification
        </p>
      </div>
      <Row gutter={[55, 0]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info modal-margin-top-add-global-2">
            <InputFieldComponent
              className="general-ant-field-input phone-real-number"
              labeltext={"Degree"}
              style={{ marginLeft: "5px", width: "70%" }}
              name="currentAddress"
              value={education.degree}
              onChange={(e) =>
                setEducation({ ...education, degree: e.target.value })
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info modal-margin-top-add-global-2">
            <InputFieldComponent
              className="general-ant-field-input phone-real-number"
              style={{ marginLeft: "5px", width: "70%" }}
              labeltext={"College Name"}
              name="collegeName"
              value={education.collegeName}
              onChange={(e) =>
                setEducation({
                  ...education,
                  collegeName: e.target.value,
                })
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <InputFieldComponent
              className="general-ant-field-input phone-real-number"
              style={{ marginLeft: "5px", width: "70%" }}
              name="university"
              labeltext={"University"}
              value={education.university}
              onChange={(e) =>
                setEducation({ ...education, university: e.target.value })
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <InputFieldComponent
              className="general-ant-field-input phone-real-number"
              style={{ marginLeft: "5px", width: "70%" }}
              labeltext={"Address"}
              name="address"
              value={education.address}
              onChange={(e) =>
                setEducation({ ...education, address: e.target.value })
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <DateComponent
              className="profile-data-display single-employee-profile-datepicker"
              value={
                isValidDate(new Date(education.startDate))
                  ? moment(new Date(education.startDate), "YYYY-MM-DD")
                  : ""
              }
              onChange={(e) =>
                setEducation({
                  ...education,
                  startDate: moment(new Date(e)).format("MM/DD/YYYY"),
                })
              }
              style={{
                width: "100%",
              }}
              size="large"
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <DateComponent
              className="profile-data-display single-employee-profile-datepicker"
              value={
                isValidDate(new Date(education.endDate))
                  ? moment(new Date(education.endDate), "YYYY-MM-DD")
                  : ""
              }
              onChange={(e) =>
                setEducation({
                  ...education,
                  endDate: moment(new Date(e)).format("MM/DD/YYYY"),
                })
              }
              style={{
                width: "100%",
              }}
              size="large"
            />
          </div>
        </Col>
      </Row>
    </>
  );

  const emergencyInfo = (
    <>
      <div className="header-container">
        <p className="font-size-global-24 font-weight-global-700">Emergency Contact</p>
      </div>
      <Row gutter={[55, 0]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info modal-margin-top-add-global-2">
            <InputFieldComponent
              className="general-ant-field-input phone-real-number"
              style={{ marginLeft: "5px", width: "70%" }}
              labeltext={"Full Name"}
              name="emergencyName"
              value={emergencyContact.name}
              onChange={(e) => {
                setEmergencyContact({
                  ...emergencyContact,
                  name: e.target.value,
                });
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info modal-margin-top-add-global-2">
            <InputFieldComponent
              className="general-ant-field-input phone-real-number"
              style={{ marginLeft: "5px", width: "70%" }}
              labeltext={"Relationship"}
              name="relationship"
              value={emergencyContact.relationship}
              onChange={(e) => {
                setEmergencyContact({
                  ...emergencyContact,
                  relationship: e.target.value,
                });
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <InputFieldComponent
              className="general-ant-field-input phone-real-number"
              style={{ marginLeft: "5px", width: "70%" }}
              labeltext={"Address"}
              name="Address"
              value={emergencyContact.address}
              onChange={(e) => {
                setEmergencyContact({
                  ...emergencyContact,
                  address: e.target.value,
                });
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <InputFieldComponent
              className="general-ant-field-input phone-real-number"
              labeltext={"Contact Number"}
              style={{ marginLeft: "5px", width: "70%" }}
              name="contactNumbmer"
              value={emergencyContact.contactNo}
              onChange={(e) => {
                setEmergencyContact({
                  ...emergencyContact,
                  contactNo: e.target.value,
                });
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );

  const nomineeInfo = (
    <>
      <div className="header-container">
        <p className="font-size-global-24 font-weight-global-700">Nominee</p>
      </div>

      <div style={{ width: "100%", padding: "5px" }} className="modal-margin-top-add-global-2">
        <h3>Nominee One</h3>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "2rem",
            flexWrap: "wrap",
            margin: "1rem 0 2rem 0",
          }}
        >
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeOne.name}
              labeltext={"Full Name"}
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeOne: {
                    ...nomineeList.nomineeOne,
                    name: e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeOne.relationship}
              labeltext={"Relationship"}
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeOne: {
                    ...nomineeList.nomineeOne,
                    relationship: e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%", marginTop: "-2em" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeOne.otherRelationship}
              labeltext={"Other Relationship"}
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeOne: {
                    ...nomineeList.nomineeOne,
                    otherRelationship: e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%", marginTop: "-2em" }}
          >
            <DateComponent
              className="profile-data-display single-employee-profile-datepicker"
              value={
                isValidDate(new Date(nomineeList.nomineeOne.dateOfBirth))
                  ? moment(
                    new Date(nomineeList.nomineeOne.dateOfBirth),
                    "YYYY-MM-DD"
                  )
                  : ""
              }
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeOne: {
                    ...nomineeList.nomineeOne,
                    dateOfBirth: moment(new Date(e)).format("MM/DD/YYYY"),
                  },
                });
              }}
              style={{
                width: "100%",
              }}
              size="large"
            />
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%", marginTop: "-2em" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeOne.aadhaar}
              labeltext={"Aadhar Number"}
              onChange={(e) => {
                if (e.target.value.length > 12) {
                  return;
                }
                setNomineeList({
                  ...nomineeList,
                  nomineeOne: {
                    ...nomineeList.nomineeOne,
                    aadhaar: Number.isNaN(parseInt(e.target.value))
                      ? ""
                      : e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
        </div>
        <h3>Nominee Two</h3>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "2rem",
            flexWrap: "wrap",
            margin: "1rem 0 2rem 0",
          }}
        >
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeTwo.name}
              labeltext={"Full Name"}
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeTwo: {
                    ...nomineeList.nomineeTwo,
                    name: e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeTwo.relationship}
              labeltext={"Relationship"}
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeTwo: {
                    ...nomineeList.nomineeTwo,
                    relationship: e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%", marginTop: "-2em" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeTwo.otherRelationship}
              labeltext={"Other Relationship"}
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeTwo: {
                    ...nomineeList.nomineeTwo,
                    otherRelationship: e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%", marginTop: "-2em" }}
          >
            <DateComponent
              className="profile-data-display single-employee-profile-datepicker"
              value={
                isValidDate(new Date(nomineeList.nomineeTwo.dateOfBirth))
                  ? moment(
                    new Date(nomineeList.nomineeTwo.dateOfBirth),
                    "YYYY-MM-DD"
                  )
                  : ""
              }
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeTwo: {
                    ...nomineeList.nomineeTwo,
                    dateOfBirth: moment(new Date(e)).format("MM/DD/YYYY"),
                  },
                });
              }}
              style={{
                width: "100%",
              }}
              size="large"
            />
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%", marginTop: "-2em" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeTwo.aadhaar}
              labeltext={"Aadhar Number"}
              onChange={(e) => {
                if (
                  e.target.value.length > 12
                ) {
                  return;
                }
                setNomineeList({
                  ...nomineeList,
                  nomineeTwo: {
                    ...nomineeList.nomineeTwo,
                    aadhaar: Number.isNaN(parseInt(e.target.value))
                      ? ""
                      : e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
        </div>
        <h3>Nominee Three</h3>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "2rem",
            flexWrap: "wrap",
            margin: "1rem 0 2rem 0",
          }}
        >
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeThree.name}
              labeltext={"Full Name"}
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeThree: {
                    ...nomineeList.nomineeThree,
                    name: e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeThree.relationship}
              labeltext={"Relationship"}
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeThree: {
                    ...nomineeList.nomineeThree,
                    relationship: e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%", marginTop: "-2em" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeThree.otherRelationship}
              labeltext={"Other Relationship"}
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeThree: {
                    ...nomineeList.nomineeThree,
                    otherRelationship: e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%", marginTop: "-2em" }}
          >
            <DateComponent
              className="profile-data-display single-employee-profile-datepicker"
              value={
                isValidDate(new Date(nomineeList.nomineeThree.dateOfBirth))
                  ? moment(
                    new Date(nomineeList.nomineeThree.dateOfBirth),
                    "YYYY-MM-DD"
                  )
                  : ""
              }
              onChange={(e) => {
                setNomineeList({
                  ...nomineeList,
                  nomineeThree: {
                    ...nomineeList.nomineeThree,
                    dateOfBirth: moment(new Date(e)).format("MM/DD/YYYY"),
                  },
                });
              }}
              style={{
                width: "100%",
              }}
              size="large"
            />
          </div>
          <div
            className="company-address-individual-data-container"
            style={{ width: "46%", marginTop: "-2em" }}
          >
            <InputFieldComponent
              value={nomineeList.nomineeThree.aadhaar}
              labeltext={"Aadhar Number"}
              onChange={(e) => {
                if (
                  e.target.value.length > 12
                ) {
                  return;
                }
                setNomineeList({
                  ...nomineeList,
                  nomineeThree: {
                    ...nomineeList.nomineeThree,
                    aadhaar: Number.isNaN(parseInt(e.target.value))
                      ? ""
                      : e.target.value,
                  },
                });
              }}
              block={true}
            ></InputFieldComponent>
          </div>
        </div>
      </div>
    </>
  );
  const parentInfo = (
    <>
      <div className="header-container">
        <p className="font-size-global-24 font-weight-global-700">Parent's Information</p>
      </div>
      <Row gutter={[55, 0]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info modal-margin-top-add-global-2">
            <div className="phone-number-holder">
              <InputFieldComponent
                className="general-ant-field-input phone-real-number"
                style={{ marginLeft: "5px", width: "70%" }}
                labeltext={"Father's Name"}
                name="fathername"
                value={familyDetails.fathersName}
                onChange={(e) =>
                  setfamilyDetails({
                    ...familyDetails,
                    fathersName: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info modal-margin-top-add-global-2">
            <DateComponent
              className="profile-data-display"
              labelText={"Father's Date of Birth"}
              value={
                isValidDate(new Date(familyDetails.fathersDOB))
                  ? moment(new Date(familyDetails.fathersDOB), "YYYY-MM-DD")
                  : moment(new Date(), "YYYY-MM-DD")
              }
              onChange={(e) => {
                let newFamilyDetails = {
                  ...familyDetails,
                  fathersDOB: moment(new Date(e)).format("MM/DD/YYYY"),
                };
                setfamilyDetails({ ...newFamilyDetails });
              }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <InputFieldComponent
              className="general-ant-field-input phone-real-number"
              style={{ marginLeft: "5px", width: "70%" }}
              labeltext={"Mother's Name"}
              name="motherName"
              value={familyDetails.mothersName}
              onChange={(e) =>
                setfamilyDetails({
                  ...familyDetails,
                  mothersName: e.target.value,
                })
              }
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="employee-info">
            <DateComponent
              className="profile-data-display"
              labelText={"Mother's Date of Birth"}
              value={
                isValidDate(new Date(familyDetails.mothersDOB))
                  ? moment(new Date(familyDetails.mothersDOB), "YYYY-MM-DD")
                  : moment(new Date(), "YYYY-MM-DD")
              }
              onChange={(e) => {
                let newFamilyDetails = {
                  ...familyDetails,
                  mothersDOB: moment(new Date(e)).format("MM/DD/YYYY"),
                };
                setfamilyDetails({ ...newFamilyDetails });
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
  return (
    <div className="employe-profileinfo-container">
      <div className="personal-information">
        <div className="header-container">
          <p className="font-size-global-24 font-weight-global-700">Personal Information</p>
          <div className="button-container">
            <NormalButton
              type={12}
              icon={blueEditIcon}
              buttonText="Edit"
              onClick={showDrawer}
            ></NormalButton>
          </div>
        </div>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Personal Phone number</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{familyDetails.phoneNumber}</p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Date of Birth</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{familyDetails.dateOfBirth}</p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Gender</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{familyDetails.gender}</p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Relationship Status</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">
              {relationshipStatus[familyDetails.maritalStatus]}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Present Address</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">
              {address.present.addressLineOne !== ""
                ? address.present.addressLineOne + ", "
                : ""}{" "}
              {address.present.addressLineTwo !== ""
                ? address.present.addressLineTwo + ","
                : ""}{" "}
              {address.present.landmark !== ""
                ? address.present.landmark + ", "
                : ""}{" "}
              {address.present.city !== "" ? address.present.city + ", " : ""}
              {address.present.state !== ""
                ? statesCodes[address.present.state]
                : ""}{" "}
              {address.present.pincode}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Permanent Address</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">
              {address.presentSameAsPermanent
                ? "Same as present address"
                : address.permanent ? `${address.permanent.addressLineOne ?? " "} ${" "}${address.permanent.addressLineTwo ?? " "
                  }${" "}
                ${address.permanent.landmark ?? " "}${" "} ${address.permanent.city ?? " "}${" "}
                ${address.permanent.state ? statesCodes[address.permanent.state] : " "} ${address.permanent.pincode ?? " "
                  }` : " "}
            </p>
          </Col>
        </Row>
        <div className="header-container">
          <p className="font-size-global-24 font-weight-global-700">
            Education and Qualification
          </p>
        </div>
        <Row className="education">
          <Col span={8}>
            Highest Qualification<p className="info">{education.degree}</p>
          </Col>
          <Col span={8}>
            College<p className="info">{education.collegeName}</p>
          </Col>
          <Col span={8}>
            University<p className="info">{education.university}</p>
          </Col>
          <Col span={8}>
            Address<p className="info">{education.address}</p>
          </Col>
          <Col span={8}>
            Start Date<p className="info">{education.startDate}</p>
          </Col>
          <Col span={8}>
            End Date<p className="info">{education.endDate}</p>
          </Col>
        </Row>
        <div className="header-container">
          <p className="font-size-global-24 font-weight-global-700">Emergency Contact</p>
        </div>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Full Name</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{emergencyContact.name}</p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Relationship</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{emergencyContact.relationship}</p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Address</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{emergencyContact.address}</p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Contact Number</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{emergencyContact.contactNo}</p>
          </Col>
        </Row>
        <div className="header-container">
          <p className="font-size-global-24 font-weight-global-700">Nominee</p>
        </div>
        <div style={{ marginBottom: "15px" }}>
          <h3>Nominee One</h3>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Full Name</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeOne.name}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Relationship</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeOne.relationship}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Other Relationship</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeOne.otherRelationship}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Date Of Birth</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeOne.dateOfBirth}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Aadhar Number</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeOne.aadhaar}</p>
            </Col>
          </Row>
        </div>
        <div style={{ marginBottom: "15px" }}>
          <h3>Nominee Two</h3>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Full Name</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeTwo.name}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Relationship</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeTwo.relationship}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Other Relationship</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeTwo.otherRelationship}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Date Of Birth</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeTwo.dateOfBirth}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Aadhar Number</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeTwo.aadhaar}</p>
            </Col>
          </Row>
        </div>
        <div style={{ marginBottom: "15px" }}>
          <h3>Nominee Three</h3>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Full Name</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeThree.name}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Relationship</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeThree.relationship}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Other Relationship</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">
                {nomineeList.nomineeThree.otherRelationship}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Date Of Birth</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeThree.dateOfBirth}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Aadhar Number</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{nomineeList.nomineeThree.aadhaar}</p>
            </Col>
          </Row>
        </div>

        <div className="header-container">
          <p className="profile-heading h5-bold-20">Parent's Information</p>
        </div>

        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Father's Name</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{familyDetails.fathersName}</p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Father's Date of Birth</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{familyDetails.fathersDOB}</p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Mother's Name</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{familyDetails.mothersName}</p>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <h3 className="fields-heading">Mother's Date of Birth</h3>
          </Col>
          <Col span={4}>:</Col>
          <Col span={10}>
            <p className="info">{familyDetails.mothersDOB}</p>
          </Col>
        </Row>

        <Row gutter={[0, 10]} className="details-container">
          {familyDetails.maritalStatus === "married" &&
            (editablePI ? (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                style={{ paddingLeft: "0px" }}
              >
                <div
                  className="employee-info"
                  style={{ marginTop: "1.4rem", width: "24rem" }}
                >
                  <h3 className="fields-heading">Spouse Employement Status</h3>
                  <Radio.Group
                    options={status}
                    style={{ padding: "12px" }}
                    value={familyDetails.spousEmployementStatus}
                    onChange={(val) =>
                      setfamilyDetails({
                        ...familyDetails,
                        spousEmployementStatus: val.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            ) : (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                style={{ paddingLeft: "6px" }}
              >
                <div
                  className="employee-info"
                  style={{ marginTop: "1.4rem", width: "24rem" }}
                >
                  <h3 className="fields-heading">Spouse Employement Status</h3>
                  <p>
                    {familyDetails.spousEmployementStatus && "Employed"}
                    {!familyDetails.spousEmployementStatus && "Unemployed"}
                  </p>
                </div>
              </Col>
            ))}
        </Row>
      </div>
      <Drawer
        title="Edit"
        placement="right"
        onClose={onClose}
        visible={visible}
        size={"large"}
      >
        <Spinner spinning={loading}>
          <div className="drawer">
            {personalInfo}
            {presentAddress}
            {permanentAddress}
            {educationInfo}
            {emergencyInfo}
            {nomineeInfo}
            {parentInfo}
            <div className="button-container">
              <NormalButton buttonText="Cancel" type={5} onClick={onClose} />
              <NormalButton
                buttonText="save changes"
                type={13}
                onClick={submitFormPI}
              />
            </div>
          </div>
        </Spinner>
      </Drawer>
    </div>
  );
};

export default PersonalInformation;
