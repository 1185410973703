import { Tabs } from "antd";
import SectionData from "./generalComponents/sectionData.js";
import SubmitFormContainer from "./generalComponents/SubmitForm";

const { TabPane } = Tabs;

const forDisabledData = [
  {
    key: "1",
    category: "80DD",
    sectionFullData: {
      sectionName: "Section 80DD",
      sectionType: "Disabled Dependent Deduction",
      sectionInfo: "You can claim tax benefits for the expenses incurred on the medical treatment, nursing, training or rehabilitation of a disabled dependent. A Disable Dependent can be a spouse, child, parents, siblings or self with Cognitive or severe mental disabilities, Low vision, Blindness, Leprosy-cured, Hearing impairment, Locomotor disability, Mental illness, Autism, Cerebral palsy, or multiple disabilities.",
      sectinDescription: "Income tax deductions for medical treatment of a disabled dependant",
      notes: "",
      limit: ""
    },
    documents: [
      {
        declaration: "medicalTreatmentOrInsuranceHandicappedDependant",
        label: "Medical Treatment / Insurance of handicapped Dependant",
      },
      {
        declaration: "medicalTreatmentOrInsuranceSevereHandicappedDependant",
        label: "Medical Treatment / Insurance of handicapped Dependant (Severe)",
      },
    ],
  },
  {
    key: "2",
    category: "80U",
    documents: [
      {
        declaration: "permanentPhysicalDisabilityBelow40Prcnt",
        label: "Permanent Physical disability (Below 40%)",
      },
      {
        declaration: "permanentPhysicalDisabilityAbove40Prcnt",
        label: "Permanent Physical disability (Above 40%)",
      },
    ],
    sectionFullData: {
      sectionName: "Section 80U",
      sectionType: "Disability",
      sectionInfo: "If you are an individual suffering from a disability, you can claim tax exemptions. For you to claim tax exemption you must be certified as a person with a disability by an appropriate medical authority. You will be considered a person with a disability if you have an ailment with a minimum of 40% impairment.",
      sectinDescription: "Tax benefits for individuals suffering from a disability",
      notes: "",
      limit: ""
    },
  },
  {
    key: "3",
    documents: [
      {
        declaration: "medicalTreatmentSpecifiedDisease",
        label: "Medical Treatment ( Specified Disease only )",
      },
      {
        declaration: "medicalTreatmentSpecifiedDiseaseSeniorCitizen",
        label: "Medical Treatment (Specified Disease only)- Senior Citizen",
      },
      {
        declaration: "medicalTreatmentSpecifiedDiseaseVerySeniorCitizen",
        label: "Medical Treatment (Specified Disease only)- Very Senior Citizen",
      },
    ],
    sectionFullData: {
      sectionName: "Section 80DDB",
      sectionType: "Medical Treatment ( Specified Disease only )",
      sectionInfo: "You can claim tax deduction on expenses incurred for medical treatment of specified diseases or ailments. This section should not be mistaken with premium paid for health insurance bought covering such diseases or ailments. The diseases and ailments for which the claim can be made include Neurological Diseases (like Dementia, Dystonia Musculorum Deformans, Chorea, Motor Neuron Disease, Ataxia, Aphasia, Parkinson’s Disease and Hemiballismus), Malignant Cancer, AIDS, Chronic Renal failure, Hematological disorders (like Hemophilia or Thalassaemia)",
      sectinDescription: "Income Tax exemption for specified diseases",
      notes: "",
      limit: ""
    },
    category: "80DDB",
  },
];

const ForDisabled = ({ the12BBApproved, financialYear }) => {
  return (
    <div>
      <div className="blue-tabs-deduction-info-tabs-container">
        <Tabs tabPosition="left">
          {forDisabledData.map((data) => {
            return (
              <TabPane
                className="main-content-tabs"
                tab={
                  <SectionData
                    sectionName={data.sectionFullData.sectionName}
                    sectionType={data.sectionFullData.sectionType}
                    sectionInfo={data.sectionFullData.sectionInfo}
                  />
                }
                key={data.key}
              >
                <SubmitFormContainer the12BBApproved={the12BBApproved} sectionDocuments={data.documents} sectionFullData={data.sectionFullData} Category={data.category} subCatagory={data.subCategory} financialYear={financialYear} />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default ForDisabled;
