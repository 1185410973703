import { notification } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  getUnapprovedProofsOfSingleEmployee,
  verifyDocument
} from "../../../apis/organisation";
import { createLog } from "../../../apis/user";
import verifiedFalse from "../../../assets/common/verifiedLogos/verify-no.svg";
import verifiedTrue from "../../../assets/common/verifiedLogos/verify-yes.svg";
import downwardArrow from "../../../assets/icons/arrow-ios-downward-outline.svg";
import rightArrow from "../../../assets/icons/arrow-ios-forward-outline.svg";
import { deducationList } from "../../../utils/config";
import { FileLogos } from "../../ComponentLibrary";

const EmployeeDocs = ({
  organisation,
  setViewFileDocumentVerifyProps,
  setViewFile,
  setViewFileDocumentProps,
  AllowanceOrDeduction,
  employeeData,
  id,
  financialYear,
  setIsModalVisible,
}) => {
  const [openSections, setOpenSections] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [employeeDocuments, setEmployeeDocument] = useState([]);
  const [deductionOrAllowancesList, setDeductionOrAllowancesList] = useState({});

  const getData = async () => {
    setIsDataLoading(true);
    let proofType = AllowanceOrDeduction ? 1 : 2;
    let res = await getUnapprovedProofsOfSingleEmployee(
      id,
      financialYear,
      proofType
    );
    let categoryData = {};
    res.data.forEach((data) => {
      if (categoryData[data.category]) {
        categoryData[data.category].objData.push(data);
      } else {
        let obj = {
          objData: [data],
          hidden: openSections[data.category] ? false : true,
        };
        categoryData[data.category] = obj;
      }
    });
    setEmployeeDocument(categoryData);
    setIsDataLoading(false);
  };
  useEffect(() => {
    setIsDataLoading(true);
    let proofType = AllowanceOrDeduction ? 1 : 2;
    getUnapprovedProofsOfSingleEmployee(
      id,
      financialYear,
      proofType
    ).then(res => {
      let categoryData = {};
      res.data.forEach((data) => {
        if (categoryData[data.category]) {
          categoryData[data.category].objData.push(data);
        } else {
          let obj = {
            objData: [data],
            hidden: openSections[data.category] ? false : true,
          };
          categoryData[data.category] = obj;
        }
      });
      setEmployeeDocument(categoryData);
      setIsDataLoading(false);
    });
    if (AllowanceOrDeduction) {
      let subSectionList = {};
      let allowancesObject = organisation.organisationSettings.allowances;
      let CustomAllowancesObject =
        organisation.organisationSettings.customAllowances;
      Object.keys(allowancesObject).forEach((key) => {
        subSectionList[key] = allowancesObject[key].label;
      });
      Object.keys(CustomAllowancesObject).forEach((key) => {
        subSectionList[key] = CustomAllowancesObject[key].label;
      });
      let allowancesList = {
        Allowances: {
          sectionName: "Allowances",
          sectionType: "Allowances",
          subSections: { ...subSectionList },
        },
        HRA: {
          sectionName: "HRA",
          sectionType: "HRA",
          subSections: {
            rentAgreement: "Rent Agreement",
            rentReceipt: "rentReceipt",
          },
        },
      };
      setDeductionOrAllowancesList({ ...allowancesList });
    } else {
      setDeductionOrAllowancesList({ ...deducationList });
    }
    console.log(financialYear)
  }, [AllowanceOrDeduction, financialYear, id, openSections, organisation])


  const handleViewDocument = (fileProps, index, proofId) => {
    setViewFile(true);
    let tempFileProps = {
      documentKey: fileProps.key,
      documentType: fileProps.key.split(".").pop(),
      documentName: fileProps.key.split("reimbursement-").pop(),
    };
    let tempViewFileDocumentVerifyProps = {
      showVerificationStatus: true,
      userCanVerify: true,
      fileIsVerified: fileProps.verified,
      verifyCallback: handleFileVerify,
      propsForVerifyCallback: {
        proofId: proofId,
        index: index,
        verified: fileProps.verified,
      },
    };
    setViewFileDocumentProps(tempFileProps);
    setViewFileDocumentVerifyProps(tempViewFileDocumentVerifyProps);
  };

  const handleFileVerify = async (propsForVerifyCallback) => {
    let tempObject = {
      index: propsForVerifyCallback.index,
      proofId: propsForVerifyCallback.proofId,
      verified: propsForVerifyCallback.verified,
    };
    let response = await verifyDocument(tempObject);
    if (response.data.success) {
      await createLog({
        name: organisation.displayName,
        userId: organisation._id,
        organisationId: organisation.organisationId,
        message: `User has verified the ${AllowanceOrDeduction ? "allowances" : "deduction"} proof`,
        category: 9,
      });
      setIsModalVisible(false)
      notification.success({ message: "Proof has been verified successfully." });
      getData();
      setViewFile(false);
    } else {
      notification.error({
        message: "Failed to verify proof, please try again",
      });
    }
  };

  const showFiles = (empDoc, status) => {
    let tempOpenList = openSections;
    if (status) {
      tempOpenList[empDoc] = true;
    } else {
      delete tempOpenList[empDoc];
    }
    setOpenSections(tempOpenList);
    let tempObj = employeeDocuments;
    tempObj[empDoc].hidden = !status;
    setEmployeeDocument({ ...tempObj });
  };


  return <div className="employee-upload-files-body">
    {!isDataLoading && (
      <div className="employee-upload-files-container">
        {employeeData && <h1 className="employee-upload-heading">
          {employeeData.employeeData.displayName}'s investment proof
        </h1>}
        {Object.keys(employeeDocuments).map((empDoc, i) => {
          return (
            <CategoryContainer
              empDoc={empDoc}
              key={`cat-${i}`}
              i={i}
              employeeDocuments={employeeDocuments}
              showFiles={showFiles}
              handelViewDocument={handleViewDocument}
              deductionOrAllowancesList={deductionOrAllowancesList}
              AllowanceOrDeduction={AllowanceOrDeduction}
            />
          );
        })}
      </div>
    )}
  </div>;
};

const CategoryContainer = ({
  empDoc,
  i,
  employeeDocuments,
  showFiles,
  handelViewDocument,
  AllowanceOrDeduction,
  deductionOrAllowancesList
}) => {
  return (
    <div key={`emp-doc-${i}`} className="employee-upload-files-category-holder">
      <div className="flex justify-between" onClick={() => {
        showFiles(empDoc, employeeDocuments[empDoc].hidden);
      }}>
        <p
          className="employee-upload-files-category-name"
          style={{
            marginBottom: employeeDocuments[empDoc].hidden ? 0 : 24,
          }}
        >
          {AllowanceOrDeduction
            ? deductionOrAllowancesList[empDoc].sectionType
            : deductionOrAllowancesList[empDoc].sectionType +
            " (" +
            empDoc +
            ")"}
        </p>
        <div

        >
          {employeeDocuments[empDoc].hidden ? (
            <img src={rightArrow} alt="right" style={{ width: "1rem" }} />
          ) : (
            <img src={downwardArrow} alt="downward" style={{ width: "1rem" }} />
          )}
        </div>
      </div>
      <div>
        {!employeeDocuments[empDoc].hidden &&
          employeeDocuments[empDoc].objData.map((docData, index) => {
            return (
              <SubCategoryContainer
                docData={docData}
                key={`sub-cat-${i}`}
                index={index}
                empDoc={empDoc}
                handelViewDocument={handelViewDocument}
                deductionOrAllowancesList={deductionOrAllowancesList}
              />
            );
          })}
      </div>
    </div>
  );
};

const SubCategoryContainer = ({
  docData,
  index,
  empDoc,
  handelViewDocument,
  deductionOrAllowancesList,
}) => {
  return (
    <div
      className="employee-upload-files-sub-category-container"
      key={`emp-doc-file${index}`}
    >
      <div className="flex justify-between">
        <p className="employee-upload-files-sub-category-heading">
          {deductionOrAllowancesList[empDoc].subSections[docData.subCatagory]
            ? deductionOrAllowancesList[empDoc].subSections[docData.subCatagory]
            : docData.subCatagory}
        </p>
      </div>
      {docData.documents.map((data, i) => {
        return (
          <DocumentContainer
            data={data}
            key={`doc-${i}`}
            i={i}
            handelViewDocument={handelViewDocument}
            proofId={docData.proofId}
          />
        );
      })}
    </div>
  );
};

const DocumentContainer = ({ data, i, handelViewDocument, proofId }) => {
  return (
    <div
      className="flex justify-between document-container align-center"
      onClick={() => {
        handelViewDocument(data, i, proofId);
      }}
    >
      <div className="flex document-details-container align-center">
        <div className="file-logo-container">
          <FileLogos type={data.key.split(".")[1]} />
        </div>
        <div className="file-details">
          <p className="file-name">{data.name}</p>
          <p className="file-created-at subtitle2-med-14">
            {moment(data.createdAt).format("ll")}
          </p>
        </div>
      </div>
      {data.verified ? (
        <span className="verifiection-text verified font-size-14 font-weight-500">
          <img
            alt="verification status logo"
            className="verifiection-logo"
            src={verifiedTrue}
          ></img>
          Verified
        </span>
      ) : (
        <span className="verifiection-text not-verified font-size-14 font-weight-500">
          <img
            alt="verification status logo"
            className="verifiection-logo"
            src={verifiedFalse}
          ></img>
          Not Verified
        </span>
      )}
    </div>
  );
};

export default EmployeeDocs;
