import HeaderComponent from "../../Common/Header/Header";
import reportActive from "../../../assets/icons/activeIcons/reports.svg";
import { Content } from "antd/lib/layout/layout";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { getAllOrgReportsList, newOrgReport, deleteOrgReportByReportId } from "../../../apis/organisation";
import { deleteLogo } from "../../../assets/common/editAndDeleteLogos";
import { NormalButton } from "../../ComponentLibrary";
import { Checkbox, Modal, notification, Spin } from "antd";
import { paths } from "../../../constants/routes";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";
import TrialPage from "../../Common/TrialComponent";
import customReportsBgImage from "../../../assets/dummyPages/customReportsBgImage.png"

const CustomReport = ({ user, history, isTrial }) => {

    const [customReportList, setCustomReportList] = useState([])
    // eslint-disable-next-line
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [showAddCustomReportModal, setShowAddCustomReportModal] = useState(false)
    const [showDeleteCustomReportModal, setShowDeleteCustomReportModal] = useState(false)
    const [deleteCustomReportObject, setDeleteCustomReportObject] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [newCustomReportData, setNewCustomReportData] = useState({
        name: "",
        fields: []
    })

    let fetchAllOrgReportsList = async () => {
        setIsLoading(true)
        let res = await getAllOrgReportsList(user.organisationId)
        if (res.status === 200) {
            setCustomReportList(res.data)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (user.role === "org") {
            fetchAllOrgReportsList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.role])

    const closeDeleteCustomReportModal = () => {
        setDeleteCustomReportObject({})
        setShowDeleteCustomReportModal(false)
    }

    const deleteCustomReportConfirm = async () => {
        setIsLoading(true)
        let res = await deleteOrgReportByReportId(deleteCustomReportObject.reportId)
        if (res.status === 200 && res.data.success) {
            notification.success({ message: "Successfully deleted the custom report." })
            fetchAllOrgReportsList()
            closeDeleteCustomReportModal()
        }
        else {
            notification.error({ message: "Failed to remove the custom report." })
            setIsLoading(false)
        }
    }

    const onAddCustomReportModalClose = () => {
        setNewCustomReportData({
            name: "",
            fields: []
        })
        setShowAddCustomReportModal(false)
    }

    function onCheckedReportFieldChange(checkedValues) {
        setNewCustomReportData({
            ...newCustomReportData,
            fields: checkedValues
        });
    }

    const createCustomReport = async () => {
        if (!newCustomReportData.name.length) {
            notification.error({ message: "Name of custom report cannot be empty." })
            return
        }
        if (!newCustomReportData.fields.length) {
            notification.error({ message: "Select atleast one field for this custom report." })
            return
        }
        setIsLoading(true)
        let fields = []
        for (let x in newCustomReportData.fields) {
            fields.push(customReportFields[newCustomReportData.fields[x]])
        }
        let newReportBody = {
            organisationId: user.organisationId,
            name: newCustomReportData.name,
            fields: fields
        }
        let res = await newOrgReport(newReportBody)
        if (res.status === 200 && res.data.success) {
            fetchAllOrgReportsList()
            notification.success({ message: "Successfully created new custom report." })
            onAddCustomReportModalClose()
        }
        else {
            notification.error({ message: "Failed to create new custom report." })
            setIsLoading(false)
        }
    }
    useEffect(() => {
        document.title = 'Reports | Custom Reports';
    });
    const customReportFields = {
        employeeId: {
            title: "Employee Id",
            dataIndex: [
                "user",
                "currentOrganisationMeta",
                "employeeId"
            ],
            key: "employeeId"
        },
        displayName: {
            title: "Name",
            dataIndex: [
                "user",
                "displayName"
            ],
            key: "displayName"
        },
        designation: {
            title: "Designation",
            dataIndex: [
                "user",
                "designation"
            ],
            key: "designation"
        },
        statutoryBonus: {
            title: "Statutory Bonus",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "statutoryBonus"
            ],
            key: "statutoryBonus"
        },
        basic: {
            title: "Basic",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "basic"
            ],
            key: "basic"
        },
        HRA: {
            title: "HRA",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "HRA"
            ],
            key: "HRA"
        },
        LTA: {
            title: "LTA",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "LTA"
            ],
            key: "LTA"
        },
        FOOD: {
            title: "FOOD",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "FOOD"
            ],
            key: "FOOD"
        },
        specialAllowance: {
            title: "Special Allowance",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "specialAllowance"
            ],
            key: "specialAllowance"
        },
        reimbursements: {
            title: "Reimbursements",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "reimbursements"
            ],
            key: "reimbursements"
        },
        employee_PF: {
            title: "Employee PF",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "employee_PF"
            ],
            key: "employee_PF"
        },
        employer_PF: {
            title: "Employer PF",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "employer_PF"
            ],
            key: "employer_pf"
        },
        employee_ESI: {
            title: "Employee ESI",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "employee_ESI"
            ],
            key: "employee_ESI"
        },
        employer_ESI: {
            title: "Employer ESI",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "employer_ESI"
            ],
            key: "employer_ESI"
        },
        employee_LWF: {
            title: "Employee LWF",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "employee_LWF"
            ],
            key: "employee_LWF"
        },
        employer_LWF: {
            title: "Employer LWF",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "employer_LWF"
            ],
            key: "employer_LWF"
        },
        incomeTax: {
            title: "Income Tax",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "newRegime",
                "incomeTax"
            ],
            key: "incomeTax"
        },
        proffTax: {
            title: "Profession tax",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "proffTax"
            ],
            key: "proffTax"
        },
        deduction: {
            title: "Deduction",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "newRegime",
                "deduction"
            ],
            key: "deduction"
        },
        totalEarnings: {
            title: "Total Earnings",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "totalEarnings"
            ],
            key: "totalEarnings"
        },
        totalFixedIncome: {
            title: "Total Fixed Income",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "totalFixedIncome"
            ],
            key: "totalFixedIncome"
        },
        totalVariableIncome: {
            title: "Variable Income",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "totalVariableIncome"
            ],
            key: "totalVariableIncome"
        },
        takeHome: {
            title: "TakeHome",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "newRegime",
                "takeHome"
            ],
            key: "takeHome"
        },
        otherAllowances: {
            title: "Other Allowances",
            dataIndex: [
                "payslip",
                "finalPayslip",
                "otherAllowances"
            ],
            key: "otherAllowances"
        },
    }

    const customReportCheckboxItems = {
        employeeId: "Employee Id",
        displayName: "Display Name",
        designation: "Designation",
        statutoryBonus: "Statutory Bonus",
        basic: "Basic",
        HRA: "HRA",
        LTA: "LTA",
        FOOD: "FOOD",
        specialAllowance: "Special Allowance",
        reimbursements: "Reimbursements",
        employee_PF: "Employee PF",
        employer_PF: "Employer PF",
        employee_ESI: "Employee ESI",
        employer_ESI: "Employer ESI",
        employee_LWF: "Employee LWF",
        employer_LWF: "Employer LWF",
        incomeTax: "Income Tax",
        proffTax: "Profession Tax",
        deduction: "Deduction",
        totalEarnings: "Total Earnings",
        totalFixedIncome: "Total Fixed Income",
        totalVariableIncome: "Total Variable Income",
        takeHome: "Take Home",
        otherAllowances: "Other Allowances",
    }

    const CustomReportsComponent = ({ customReport }) => <div className="org-custom-report-card flex justify-between align-center">
        <div
            className="org-custom-report-cart-left-side"
            onClick={() => {
                history.push({
                    pathname: paths.org.customReportTable,
                    state: {
                        data: customReport,
                        user: user
                    }
                })
            }}
        >
            <p className="org-report-type">{customReport.name}</p>
        </div>
        <div
            className="org-custom-report-cart-right-side"
        >
            <NormalButton
                type={5}
                margin={[3, 0]}
                icon={deleteLogo}
                onClick={() => {
                    setDeleteCustomReportObject({
                        ...customReport,
                        fileDownload: true,
                        fileDownloadLink: "",
                        showMonth: true
                    })
                    setShowDeleteCustomReportModal(true)
                }}
            />
        </div>
    </div>

    return (
        isTrial ? <TrialPage user={user} trialMessage={"Create customized report templates as per your need"} bgImage={customReportsBgImage}
        /> :
            <div>
                <HeaderComponent show={false} icon={reportActive} name="Custom Reports" />
                <Content className="app-main-content-container">
                    {user.role === "org" &&
                        <div className="flex justify-between align-center">
                            <h3 className="font-size-global-24 font-weight-global-700">Custom Reports</h3>
                            <NormalButton
                                onClick={() => setShowAddCustomReportModal(true)}
                                type={13}
                                buttonText="Add Custom Report"
                            />
                        </div>
                    }
                    <Spin spinning={isLoading}>
                        {user.role === "org" && customReportList.length !== 0
                            && customReportList.map((customReport, index) => <CustomReportsComponent customReport={customReport} key={`creport-${index}`} />)}
                        {user.role === "org" && customReportList.length === 0
                            && <div className="org-report-card">
                                <p className="org-report-type m-bottom-5 text-align-center">No custom report</p>
                            </div>
                        }
                    </Spin>

                </Content>
                <Modal
                    centered
                    closable={false}
                    visible={showAddCustomReportModal}
                    width={400}
                    className=""
                    footer={null}
                    onCancel={onAddCustomReportModalClose}
                >
                    <Spin spinning={isLoading} size="large">
                        <h1 className="font-weight-700 font-size-24 m-bottom-36">New Custom Report</h1>
                        <div>
                            <div style={{width:"10em"}} className="customer-report-fields-container">
                                <InputFieldComponent
                                    value={newCustomReportData.name}
                                    labeltext={"Name"}
                                    onChange={(e) => setNewCustomReportData({
                                        ...newCustomReportData,
                                        name: e.target.value
                                    })}
                                ></InputFieldComponent>
                                <label style={{ fontSize: "24px", fontWeight: "600" }} className="app-label-2">Fields</label>
                                <div className="customer-report-checkbox-container">
                                    <Checkbox className="mb-4" checked={Object.keys(customReportCheckboxItems).length === newCustomReportData.fields.length} onChange={(e) => {
                                        setIsAllChecked(e.target.checked);
                                        if (e.target.checked === true) {
                                            setNewCustomReportData({
                                                ...newCustomReportData,
                                                fields: Object.keys(customReportCheckboxItems)
                                            });
                                        } else {
                                            setNewCustomReportData({
                                                ...newCustomReportData,
                                                fields: []
                                            });
                                        }
                                    }} >Select All</Checkbox>
                                    <Checkbox.Group style={{ width: '100%' }} onChange={onCheckedReportFieldChange} value={newCustomReportData.fields}>
                                        {
                                            Object.keys(customReportCheckboxItems).map((reportFieldKey) => {
                                                return (
                                                    <div className="m-bottom-20">
                                                        <Checkbox value={reportFieldKey}>{customReportCheckboxItems[reportFieldKey]}</Checkbox>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Checkbox.Group>
                                </div>
                            </div>
                        </div>
                        <div className="text-align-left m-top-36">
                            <NormalButton
                                buttonText="Create"
                                onClick={() => createCustomReport()}
                                type={13}
                            />
                        </div>
                    </Spin>
                </Modal>
                <Modal
                    visible={showDeleteCustomReportModal}
                    onCancel={closeDeleteCustomReportModal}
                    closable={false}
                    footer={false}
                    centered={true}
                >
                    <Spin size="large" spinning={isLoading}>
                        <div>
                            <h1 className="app-heading-2 m-bottom-36">Delete Custom Report</h1>
                            <>
                                <div className="error-text p-bottom-36">
                                    Are you sure you want to delete the "{deleteCustomReportObject.name}" custom report?
                                </div>
                                <div className="m-top-36 text-align-right">
                                    <NormalButton
                                        buttonText="Cancel"
                                        type={5}
                                        onClick={closeDeleteCustomReportModal}
                                    />
                                    <NormalButton
                                        buttonText="Delete"
                                        type={3}
                                        onClick={deleteCustomReportConfirm}
                                    />
                                </div>
                            </>
                        </div>
                    </Spin>
                </Modal>
            </div>
    )
}

const CustomReportContainer = connect(
    (state) => ({
        user: state.auth.user,
        isTrial: state.auth.user.isTrial,

    })
)(CustomReport)

export default withRouter(CustomReportContainer);