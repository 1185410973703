import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { proofsByCategory } from "../../../apis/employee";
import { connect } from "react-redux";
import DocumentViewer from "../../Common/DeclarationDocuments/DeclarationDocuments.js";
import PerqsFileUploadContainer from "./PerqsDocumentsUpload.js";

const EmployeePerqs = ({ user }) => {
  const [perqsDocuments, setPerqsDocuments] = useState([]);
  useEffect(() => {
    getProofsByCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProofsByCategory = async () => {
    let data = await proofsByCategory({
      id: user._id,
      category: "Perquisites"
    });
    setPerqsDocuments([...data.data]);
  };

  return (
    <div className="other-allowances-container">
      <Row gutter={[48, 0]}>
        <Col span={12}>
            <PerqsFileUploadContainer
                updateDeclarationDocument={getProofsByCategory}
            />
            </Col>
        <Col span={12}>
          <DocumentViewer
            updateDeclarationDocument={getProofsByCategory}
            type="perquisites"
            items={perqsDocuments}
          />
        </Col>
      </Row>
    </div>
  );
};
const EmployeePerqsProofs = connect(state => ({
  user: state.auth.user,
  perquisites: state.employee.perquisites
}))(EmployeePerqs);
export default EmployeePerqsProofs;
