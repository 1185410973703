/* eslint-disable jsx-a11y/anchor-is-valid */
import { Select } from "antd";
import { useEffect, useState } from "react";
import { getTaxProjection } from "../../apis/organisation";
import { handleAction } from "../../constants/common_functions";
import {
  DEDUCTIONS,
  HRA,
  SALARY_COMPONENT
} from "../../constants/taxProjections";
import { checkFinancialYear, numformatter, rootUrl } from "../../utils/config";
import { NormalButton } from "../ComponentLibrary";
import ChapterVI from "./component/ChapterVI";
import IncomePreviousEmployer from "./component/IncomePreviousEmployer";
import ItemExemption from "./component/ItemExemption";
import MonthlyIncomeData from "./component/MonthlyIncomeData";
import MonthlyRentData from "./component/MonthlyRentData";
import Section16 from "./component/Section16";
import Total from "./component/Total";
const { Option } = Select;
const TaxProjection = ({
  user,
  declaration = { declarations: { deductions: [] } },
  payslips = [],
  previousEmployement,
}) => {
  const { month } = checkFinancialYear();
  const [salaryComponent, setSalaryComponent] = useState({
    ...SALARY_COMPONENT,
  });

  const [currFinancialYear, setCurrFinancialYear] = useState(
    checkFinancialYear().financialYear
  );
  const [financialYearList, setFinancialYearList] = useState([]);
  useEffect(() => {
    function calculateFinancialYear() {
      let startFYear = 2020;
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let resFinancialYearList = [];
      for (let i = startFYear; i < currentYear + 1; i++) {
        let nextYear = i + 1;
        let tempFYear = `${i}-${nextYear}`;
        resFinancialYearList.push(tempFYear);
      }
      resFinancialYearList.reverse();
      setFinancialYearList([...resFinancialYearList]);
    }
    calculateFinancialYear();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [perqs, SetPerqs] = useState({
    values: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
    },
    total: 0,
  });
  const [totalEarning, setTotalEarning] = useState({
    values: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
    },
    total: 0,
  });
  const [deductions, setDeductions] = useState({
    ...DEDUCTIONS,
  });
  const [totalDeductions, setTotalDeductions] = useState({
    values: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
    },
    total: 0,
  });
  const [Hra, setHRA] = useState([...HRA]);
  const [exemptionValues, setExemptionValues] = useState({
    HRA: 0,
    LTA: 0,
    FOOD: 0,
  });

  const [previousIncome, setPreviousIncome] = useState({
    totalGrossSalary: 0,
    totalExemption: 0,
    totalTaxDeducted: 0,
    totalPFPaid: 0,
    totalProffTaxDeducted: 0
  });

  const [totalExemption, setTotalExemption] = useState(0);
  // const [rebalancedTaxPaid, setRebalancedTaxPaid] = useState(0);
  const [totalProffTax, setTotalProffTax] = useState(0);

  const [declarationCategory, setDeclarationCategory] = useState(null);
  const [declarationSubCategory, setDeclarationSubCategory] = useState(null);
  const [totalDeclarationValue, setTotalDeclarationValue] = useState({
    declared: 0,
    exempted: 0,
  });

  const [headValues, setHeadValues] = useState({
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
    H: 0,
    I: 0,
    J: 0,
    K: 0,
    L: 0,
    M: 0,
    N: 0,
    O: 0,
    P: 0,
    Q: 0,
    R: 0,
    S: 0,
  });

  // eslint-disable-next-line no-unused-vars
  const [taxInfo, setTaxInfo] = useState({
    total: {
      tax: 0,
      cess: 0,
      surcharge: 0,
    },
    paid: {
      tax: 0,
      cess: 0,
      surcharge: 0,
    },
  });

  useEffect(
    () => {
      getTaxProjection(`${user._id}/${currFinancialYear}/${month}`).then((val) => {
        if (val.data) {
          const {
            rebalanceStructure,
            payslipCalculations,
            deductionCalculations,
            perqsCalculation,
            earningCalculation,
            deductionCalculation,
            proffTax,
            hraObject,
            exemptions,
            totalExemption: te,
            category,
            subCategory,
            totalDeductionValue,
            taxInfo: ti,
            A,
            B,
            C,
            D,
            E,
            F,
            G,
            H,
            I,
            J,
            K,
            L,
            M,
            N,
            O,
            P,
            Q,
            R,
            S,
          } = val.data;
          setHeadValues({
            A,
            B,
            C,
            D,
            E,
            F,
            G,
            H,
            I,
            J,
            K,
            L,
            M,
            N,
            O,
            P,
            Q,
            R,
            S,
          });
          setTotalExemption(te);
          setTotalDeclarationValue({ ...totalDeductionValue });
          setDeclarationCategory({ ...category });
          setDeclarationSubCategory({ ...subCategory });
          setTotalProffTax(proffTax);
          setPreviousIncome({ ...rebalanceStructure });
          const arrayOfHRA = restructureHraObject(hraObject);
          setHRA([...arrayOfHRA]);
          setSalaryComponent({ ...payslipCalculations });
          setDeductions({ ...deductionCalculations });
          setTotalEarning({ ...earningCalculation });
          setTotalDeductions({ ...deductionCalculation });
          setExemptionValues({ ...exemptions });
          setTaxInfo({ ...ti });
          SetPerqs({ ...perqsCalculation });
        }
      });
      const restructureHraObject = (hra) => {
        const newHRA = [];
        for (let i = 3; i < 12; i++) {
          newHRA.push(hra[i]);
        }
        for (let i = 0; i <= 2; i++) {
          newHRA.push(hra[i]);
        }
        return newHRA;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payslips, user, declaration, previousEmployement, currFinancialYear]
  );

  return (
    <div className="tax-projection-container">
      <div
        className="flex align-center justify-end"
        style={{ marginBottom: "2rem" }}
      >
        <h3 className="font-weight-700 text-size-21 mb-2 ml-5">Financial Year</h3>
        <Select
          defaultValue={currFinancialYear}
          className="general-ant-field-select ml-2"
          style={{
            width: "8rem",
            marginLeft: "1rem",
            background: "none",
            padding: 0
          }}
          size="large"
          onChange={e => {
            setCurrFinancialYear(e);
          }}
          bordered={false}
        >
          {financialYearList.map((val, index) =>
            <Option key={`opt-${index}`} value={val}>
              <h3>{val}</h3>
            </Option>
          )}
        </Select>
      </div>
      <div className="flex justify-between align-center" style={{ marginTop: "-2rem" }}>
        <h4 className="app-heading-2 font-weight-700">Tax Projection</h4>

        <NormalButton
          type={13}
          buttonText="Download Tax Projection"
          onClick={() =>
            handleAction(
              `${rootUrl}/api/get/document/tax/pdf/${user._id}/${month}/${currFinancialYear}`,
              `Tax_Projection-${currFinancialYear}.pdf`
            )
          }
          className="p-4 m-top-8"
        />
      </div>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700">A) Taxable Income</h4>
      </div>
      <table className="table_border">
        <MonthlyIncomeData data={salaryComponent} />
        <Total data={totalEarning} />
      </table>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700">B) Deduction</h4>
      </div>
      <table className="table_border">
        <MonthlyIncomeData data={deductions} />
        <Total data={totalDeductions} />
      </table>
      {/* <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700">C) Perquisites</h4>
      </div>
      <table className="table_border">
        <MonthlyIncomeData data={perqs} isFlat={true} name={"Perquisites"} />
      </table> */}
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            C{")"} Gross Salary
            ........................................................
          </span>

          <span style={{ marginLeft: "150px" }}>
            {numformatter(headValues.D)}
          </span>
        </h4>
      </div>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700">
          D{")"} Less Exemption Under Section 10
        </h4>
      </div>
      {Hra && <table className="table_border">
        <MonthlyRentData data={Hra} />
      </table>}
      <br />
      <table className="table_border">
        <ItemExemption
          data={exemptionValues}
          totalExemption={totalExemption}
          previousIncome={previousIncome}
        />
      </table>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700">
          E{")"} Income from Previous Employer
        </h4>
      </div>
      <table className="table_border">
        <IncomePreviousEmployer previousIncome={previousIncome} />
      </table>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            F{")"} Income after Exemption
            ........................................................
          </span>
          <span style={{ marginLeft: "130px" }}>
            {numformatter(headValues.G)}
          </span>
        </h4>
      </div>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            G{")"} Less Deduction Under Section 16
            ........................................................
          </span>

          <span>{numformatter(headValues.H)}</span>
        </h4>
      </div>
      <table className="table_border">
        <Section16 totalProffTax={totalProffTax} previousEmployment={previousIncome} />
      </table>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            H{")"} Income chargeable under head salaries
            ........................................................
          </span>

          <span>{numformatter(headValues.I)}</span>
        </h4>
      </div>

      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            I{")"} Income From Other Sources
            ........................................................
          </span>

          <span>{numformatter(headValues.J)}</span>
        </h4>
      </div>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            J{")"} Gross Total Income
            ........................................................
          </span>

          <span>{numformatter(headValues.K)}</span>
        </h4>
      </div>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            K{")"} Deduction Under Chapter VI A
            ........................................................
          </span>
          <span>{numformatter(headValues.L)}</span>
        </h4>
      </div>
      <table className="table_border">
        <ChapterVI
          category={declarationCategory}
          subCategory={declarationSubCategory}
          totalValue={totalDeclarationValue}
        />
      </table>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            L{")"} Taxable Income
            ........................................................
          </span>
          <span>{numformatter(headValues.M)}</span>
        </h4>
      </div>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            M{")"} Total Tax Liability
            ........................................................
          </span>
          <span>{numformatter(headValues.N)}</span>
        </h4>
      </div>
      {/* <table className="table_border">
        <Taxtobepaid taxInfo={taxInfo} />
      </table> */}
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            N{")"} Tax paid till date
            ........................................................
          </span>
          <span>{numformatter(headValues.O)}</span>
        </h4>
      </div>
      {/* <table className="table_border">
        <Taxpaid taxInfo={taxInfo} />
      </table> */}
      {/* <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            O{")"} Relief under Section 89
            ........................................................
          </span>
          <span>{numformatter(headValues.P)}</span>
        </h4>
      </div> */}
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            P{")"} Annual Tax Balance
            ........................................................
          </span>
          <span>{numformatter(headValues.Q)}</span>
        </h4>
      </div>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            Q{")"} TDS recovered in current month
            ........................................................
          </span>
          <span>{numformatter(headValues.R)}</span>
        </h4>
      </div>
      <div className="tableheading">
        <h4 className="app-heading-3 font-weight-700 flex justify-between align-center">
          <span>
            R{")"} Excess Tax deducted.Refund to be claimed through ITR
            ............................
          </span>
          <span>{numformatter(headValues.S)}</span>{" "}
        </h4>
      </div>
    </div>
  );
};

export default TaxProjection;
