import { Button, Drawer, Modal, notification, Table } from "antd";
import { useHistory } from "react-router-dom";
// import { HeaderComponent } from "../Common";
import { NormalButton } from "../ComponentLibrary";
// import DateComponent from "../InputLibrary/DateComponent/DateComponent";
import { connect } from "react-redux";
import plus from "../../assets/icons/whitePlus.svg";
import Form16 from "./component/Form16";
import { useEffect, useState } from "react";
import { deleteLogo, editLogo } from "../../assets/common/editAndDeleteLogos";
import {
  deletePreviousEmploymentData,
  getPreviousEmploymentData,
  savePreviousEmployment,
} from "../../apis/employee";
import { checkFinancialYear } from "../../utils/config";
import { createLog } from "../../apis/user";
import InputNumberComponent from "../InputLibrary/InputNumberComponent/InputNumberComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
import "../InputLibrary/InputNumberComponent/InputNumberComponent.less";
import { HeaderComponent } from "../Common";
import activeDeclaration from "../../assets/icons/activeIcons/activeDeclaration.svg";

const { financialYear } = checkFinancialYear();

const RebalanceStructure = (props) => {
  let history = useHistory();
  const pathArr = history.location.pathname.split("/");
  console.log(history.location.pathname);
  const id =
    pathArr[pathArr.length - 2] === "employee_details"
      ? pathArr[pathArr.length - 1]
      : props.user._id;
  const [open, setOpen] = useState(false);
  const [previousEmploymentData, setPreviousEmploymentData] = useState();
  const [
    isDeletePrevEmploymentModalVisible,
    setIsDeletePrevEmploymentModalVisible,
  ] = useState(false);
  const [
    currentUpdatePrevEmployementObj,
    setCurrentUpdatePrevEmployementObj,
  ] = useState({
    companyName: "",
    employerId: 0,
    hra: 0,
    basic: 0,
    totalGrossSalary: 0,
    totalExemption: 0,
    totalTaxDeducted: 0,
    totalPFPaid: 0,
    totalProffTaxDeducted: 0,
    exemptionReverse: 0,
    meta: [],
    monthWorked: 0,
  });
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [
    currentDeletePrevEmploymentObj,
    setCurrentDeletePrevEmploymentObj,
  ] = useState({});
  const openDeletePrevEmploymentModal = (reimbursementData) => {
    setIsDeletePrevEmploymentModalVisible(true);
    setCurrentDeletePrevEmploymentObj(reimbursementData);
  };
  const closeDeletePrevEmploymentModal = () => {
    setIsDeletePrevEmploymentModalVisible(false);
    setCurrentDeletePrevEmploymentObj({});
  };

  const deletePrevEmploymentRequest = async () => {
    let response = await deletePreviousEmploymentData(
      `${id}/${financialYear}/${currentDeletePrevEmploymentObj.employerId}`
    );
    if (response.data.success) {
      await createLog({
        name: props.user.displayName,
        userId: props.user._id,
        organisationId: props.user.organisationId,
        message: `User has deleted a Previous Employment`,
        category: 11,
      });
      await renderPrevEmploymentData();
      closeDeletePrevEmploymentModal();
      notification.success({
        message: "Previous Employment has been deleted",
      });
    } else {
      notification.error({ message: "Failed to delete request, Try again" });
    }
  };
  useEffect(() => {
    getPreviousEmploymentData(`${id}/${financialYear}`).then((res) => {
      const myData = Object.keys(res.data.previousEmployments).map((key) => {
        return res.data.previousEmployments[key];
      });
      setPreviousEmploymentData(myData);
    });
  }, [id]);
  const renderPrevEmploymentData = () => {
    getPreviousEmploymentData(`${id}/${financialYear}`).then((res) => {
      const myData = Object.keys(res.data.previousEmployments).map((key) => {
        return res.data.previousEmployments[key];
      });
      setPreviousEmploymentData(myData);
    });
  };
  const openUpdateModal = (data) => {
    setCurrentUpdatePrevEmployementObj({
      id: id,
      financialYear: financialYear,
      employerId: data.employerId,
      hra: data.hra,
      basic: data.basic,
      totalGrossSalary: data.totalGrossSalary,
      totalExemption: data.totalExemption,
      totalTaxDeducted: data.totalTaxDeducted,
      totalPFPaid: data.totalPFPaid,
      totalProffTaxDeducted: data.totalProffTaxDeducted,
      exemptionReverse: data.exemptionReverse,
      meta: data.meta,
      monthWorked: data.monthWorked,
      companyName: data.companyName !== undefined ? data.companyName : "",
    });
    setIsUpdateModalOpen(true);
  };
  const closeUpdateModal = () => {
    setCurrentUpdatePrevEmployementObj({
      id: id,
      financialYear: financialYear,
      employerId: 0,
      hra: 0,
      basic: 0,
      totalGrossSalary: 0,
      totalExemption: 0,
      totalTaxDeducted: 0,
      totalPFPaid: 0,
      totalProffTaxDeducted: 0,
      exemptionReverse: 0,
      meta: [],
      monthWorked: 0,
      companyName: "",
    });
    setIsUpdateModalOpen(false);
  };
  const updatePrevEmploymentData = async () => {
    let response = await savePreviousEmployment(
      currentUpdatePrevEmployementObj
    );
    if (response.data.success) {
      await renderPrevEmploymentData();
      setCurrentUpdatePrevEmployementObj({});
      setIsUpdateModalOpen(false);
      notification.success({
        message: "Previous Employment details has been updated",
      });
    } else {
      notification.error({
        message:
          "Failed to update details of Previous Employment, Please try again",
      });
    }
  };
  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      ellipsis: true,
    },
    {
      title: "Total Gross Salary",
      dataIndex: "totalGrossSalary",
      key: "totalGrossSalary",
      ellipsis: true,
    },
    {
      title: "Total Basic Salary",
      dataIndex: "basic",
      key: "basic",
      ellipsis: true,
    },
    {
      title: "Total HRA Exemption",
      dataIndex: "hra",
      key: "hra",
      ellipsis: true,
    },
    {
      title: "Total Exemption",
      dataIndex: "totalExemption",
      key: "totalExemption",
      ellipsis: true,
    },
    {
      title: "Total PF Paid",
      dataIndex: "totalPFPaid",
      key: "totalPFPaid",
      ellipsis: true,
    },
    {
      title: "Total Professional Tax Paid",
      dataIndex: "totalProffTaxDeducted",
      key: "totalProffTaxDeducted",
      ellipsis: true,
    },
    {
      title: "Total Tax Deducted",
      dataIndex: "totalTaxDeducted",
      key: "totalTaxDeducted",
      ellipsis: true,
    },
    {
      title: "Total Month Worked",
      dataIndex: "monthWorked",
      key: "monthWorked",
      ellipsis: true,
    },

    {
      title: "",
      dataIndex: "state",
      key: "state",
      width: "18%",
      render: (state, data) => (
        <div>
          <NormalButton
            type={5}
            icon={editLogo}
            onClick={() => openUpdateModal(data)}
          />
          <NormalButton
            type={5}
            icon={deleteLogo}
            onClick={() => openDeletePrevEmploymentModal(data)}
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <Modal
        className='update-reimbursement-modal'
        visible={isUpdateModalOpen}
        onCancel={closeUpdateModal}
        centered
        footer={null}
        closable={false}
        width={400}
      >
        <div className='update-reimbursement-modal-body'>
          <h3 className='update-reimbursement-header'>
            Update Previous Employment data
          </h3>
          <div className='inputnumberdiv'>
            <InputFieldComponent
              width={"300px"}
              labeltext={"Company Name"}
              className=''
              onChange={(e) => {
                setCurrentUpdatePrevEmployementObj({
                  ...currentUpdatePrevEmployementObj,
                  companyName: e.target.value,
                });
              }}
              value={currentUpdatePrevEmployementObj.companyName}
              bordered={false}
            />
          </div>
          <div className='claim-reimbursement-data-container'>
            <InputNumberComponent
              labeltext={"Total Gross Salary"}
              className='general-ant-field-input'
              style={{ width: "100%", marginTop: "-30px" }}
              onChange={(val) => {
                setCurrentUpdatePrevEmployementObj({
                  ...currentUpdatePrevEmployementObj,
                  totalGrossSalary: val,
                });
              }}
              value={currentUpdatePrevEmployementObj.totalGrossSalary}
              bordered={false}
            />
          </div>
          <div className='claim-reimbursement-data-container'>
            <InputNumberComponent
              labeltext={"Total Basic Salary"}
              className='general-ant-field-input'
              style={{ width: "100%", marginTop: "-30px" }}
              onChange={(val) => {
                setCurrentUpdatePrevEmployementObj({
                  ...currentUpdatePrevEmployementObj,
                  basic: val,
                });
              }}
              value={currentUpdatePrevEmployementObj.basic}
              bordered={false}
            />
          </div>
          <div className='claim-reimbursement-data-container'>
            <InputNumberComponent
              labeltext={"Total HRA Exemption"}
              className='general-ant-field-input'
              style={{ width: "100%", marginTop: "-30px" }}
              onChange={(val) => {
                setCurrentUpdatePrevEmployementObj({
                  ...currentUpdatePrevEmployementObj,
                  hra: val,
                });
              }}
              value={currentUpdatePrevEmployementObj.hra}
              bordered={false}
            />
          </div>
          <div className='claim-reimbursement-data-container'>
            <InputNumberComponent
              labeltext={"Total Exemption"}
              className='general-ant-field-input'
              style={{ width: "100%", marginTop: "-30px" }}
              onChange={(val) => {
                setCurrentUpdatePrevEmployementObj({
                  ...currentUpdatePrevEmployementObj,
                  totalExemption: val,
                });
              }}
              value={currentUpdatePrevEmployementObj.totalExemption}
              bordered={false}
            />
          </div>
          <div className='claim-reimbursement-data-container'>
            <InputNumberComponent
              labeltext={"Total PF Paid"}
              className='general-ant-field-input'
              style={{ width: "100%", marginTop: "-30px" }}
              onChange={(val) => {
                setCurrentUpdatePrevEmployementObj({
                  ...currentUpdatePrevEmployementObj,
                  totalPFPaid: val,
                });
              }}
              value={currentUpdatePrevEmployementObj.totalPFPaid}
              bordered={false}
            />
          </div>
          <div className='claim-reimbursement-data-container'>
            <InputNumberComponent
              labeltext={"Total Professional Tax Paid"}
              className='general-ant-field-input'
              style={{ width: "100%", marginTop: "-30px" }}
              onChange={(val) => {
                setCurrentUpdatePrevEmployementObj({
                  ...currentUpdatePrevEmployementObj,
                  totalProffTaxDeducted: val,
                });
              }}
              value={currentUpdatePrevEmployementObj.totalProffTaxDeducted}
              bordered={false}
            />
          </div>
          <div className='claim-reimbursement-data-container'>
            <InputNumberComponent
              labeltext={"Total Tax Deducted"}
              className='general-ant-field-input'
              style={{ width: "100%", marginTop: "-30px" }}
              onChange={(val) => {
                setCurrentUpdatePrevEmployementObj({
                  ...currentUpdatePrevEmployementObj,
                  totalTaxDeducted: val,
                });
              }}
              value={currentUpdatePrevEmployementObj.totalTaxDeducted}
              bordered={false}
            />
          </div>
          <div className='claim-reimbursement-data-container'>
            <InputNumberComponent
              labeltext={"Total Month Worked"}
              className='general-ant-field-input'
              style={{ width: "100%", marginTop: "-30px" }}
              onChange={(val) => {
                setCurrentUpdatePrevEmployementObj({
                  ...currentUpdatePrevEmployementObj,
                  monthWorked: val,
                });
              }}
              value={currentUpdatePrevEmployementObj.monthWorked}
              bordered={false}
            />
          </div>

          <div className='update-reimbursement-modal-button'>
            <NormalButton
              type={5}
              buttonText={"Close"}
              onClick={closeUpdateModal}
              margin={[0, 8]}
            ></NormalButton>
            <NormalButton
              type={13}
              buttonText='Update'
              onClick={updatePrevEmploymentData}
              margin={[0, 8]}
            />
          </div>
        </div>
      </Modal>
      <Modal
        className='rejection-modal'
        visible={isDeletePrevEmploymentModalVisible}
        onCancel={closeDeletePrevEmploymentModal}
        centered
        footer={null}
        closable={false}
      >
        <h3 className='rejection-modal-header'>
          Are you sure you want to delete the Previous Employment record
        </h3>
        <div className='rejection-button'>
          <NormalButton
            type={5}
            buttonText={"Cancel"}
            onClick={closeDeletePrevEmploymentModal}
          ></NormalButton>
          <NormalButton
            type={13}
            buttonText='Delete'
            onClick={deletePrevEmploymentRequest}
            margin={[0, 8]}
          />
        </div>
      </Modal>
      <div className='site-layout'>
        {history.location.pathname ===
        "/employee/declaration/previous_employment" ? (
          <HeaderComponent icon={activeDeclaration} name='Declaration' />
        ) : null}

        {/* <HeaderComponent icon={activeDeclaration} name="Declaration" /> */}
        <div className='app-main-content-container reimbursement-container'>
          <div className='flex justify-between align-cener'>
            <h1 className='page-heading'>Previous Employment Information</h1>

            <div className='flex align-center'>
              <NormalButton
                buttonText='Add'
                onClick={showDrawer}
                type={13}
                icon={plus}
              />
              {/* <DateComponent
                format={"YYYY - MM"}
                className='app-date-picker month-picker'
                // onChange={handelDateChange}
                picker={"month"}
                // value={reportDate}
                labelText={"Financial year"}
                type={"month"}
              /> */}
            </div>
          </div>
          <Table
            pagination={false}
            className='reimbursement-table'
            columns={columns}
            dataSource={previousEmploymentData}
            scroll={{ x: 900, y: 700 }}
          />
        </div>
      </div>
      <Drawer
        title='Add New Previous Employment Data'
        placement='right'
        onClose={onClose}
        visible={open}
        size={"large"}
      >
        <div className='drawer'>
          <Form16
            type='declaration'
            id={id}
            // user={props.user}
            updateList={renderPrevEmploymentData}
            closeDrawer={() => {
              setOpen(false);
            }}
          />
        </div>
      </Drawer>
      {props.type === "login" ? (
        <>
          <Button
            // loading={loading}
            onClick={() => {
              props.changePageState();
            }}
            className='submit-button'
            style={{
              margin: "10px 0 10px 0",
              background: "#405cd2",
              padding: "15px",
              height: "60px",
              fontSize: "1.125rem",
            }}
            htmlType='submit'
          >
            Save and Continue
          </Button>
        </>
      ) : null}
    </>
  );
};

const PrevEmploymetContainer = connect((state) => ({
  user: state.auth.user,
  // employeeList: state.organisation.employeeList,
}))(RebalanceStructure);

export default PrevEmploymetContainer;
