import { NormalButton } from "../../ComponentLibrary";
import blueEditIcon from "../../../assets/icons/blueEditIcon.png";
import payoutEmpty from "../../../assets/organisationConfiguration/AllowanceEmpty.svg";
import { isValidDate, monthList, numformatter } from "../../../utils/config";
import { useState, useEffect } from "react";
import { Select, Modal, notification, Spin, Divider, } from "antd";
import { updateCTC } from "../../../apis/organisation";
import { createLog } from "../../../apis/user";
import DropdownComponent from "../../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";
import DateComponent from "../../InputLibrary/DateComponent/DateComponent";
import moment from "moment";
import { dateDestruct, timeDiffCalc } from "../../../utils/dateCheckUtil";
const { Option } = Select;

const SalaryDetails = ({ user, ctc, getEmployeeData, orgInfo, payslips }) => {
  const [loading, setLoading] = useState(false);
  const [financialYearList, setFinancialYearList] = useState([]);
  const [payslip, setPayslip] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(moment(new Date(new Date().setDate(1)), "YYYY-MM-DD"));
  const currentMonth = new Date().getMonth();
  const [ctcDetails, setCTCDetails] = useState({
    fyYear: "",
    id: "",
    ctc: "",
    month: new Date().getMonth(),
    date: 1,
    payoutMonth: new Date().getMonth(),
    payoutYear: new Date().getFullYear(),
    variableAmount: 0,
    isPercentage: true,
    variablePercentage: 0,
    organisationId: "",
    isEmployerContributionIncludedInCTC: true
  });
  const [showCTC, setShowCTC] = useState(false);
  useEffect(
    () => {
      if (ctc) {
        setCTCDetails({
          fyYear: ctc.financialYear || "",
          id: user._id,
          ctc: parseInt(ctc.costToCompany[currentMonth].ctc) || 0,
          date: 1,
          month: new Date().getMonth(),
          payoutMonth: ctc.payoutMonth ?? new Date().getMonth(),
          payoutYear: ctc.payoutYear ?? new Date().getFullYear(),
          variablePercentage:
            parseFloat(ctc.costToCompany[currentMonth].variablePercentage) || 0,
          organisationId: ctc.organisationId || orgInfo.organisationId,
          variableAmount: 0,
          isPercentage: true,
          isEmployerContributionIncludedInCTC:
            typeof ctc.isEmployerContributionIncludedInCTC === "boolean"
              ? ctc.isEmployerContributionIncludedInCTC
              : true
        });
      }

      let startFYear = 2015;
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let resFinancialYearList = [];
      for (let i = startFYear; i < currentYear + 2; i++) {
        let nextYear = i + 1;
        let tempFYear = `${i}-${nextYear}`;
        resFinancialYearList.push(tempFYear);
      }
      setFinancialYearList([...resFinancialYearList]);
    },
    [ctc, currentMonth, orgInfo.organisationId, payslips, user._id]
  );

  useEffect(
    () => {
      // Sort payslip by month
      let newPayslips = [];
      for (let i = 3; i < 12; i++) {
        const payslip = payslips.filter((a) => a.month === i);
        if (payslip.length) {
          newPayslips.push(...payslip)
        }
      }
      for (let i = 0; i < 3; i++) {
        const payslip = payslips.filter((a) => a.month === i);
        if (payslip.length) {
          newPayslips.push(...payslip)
        }
      }
      // if payslips is not empty then fetch the current payslip to show the details
      if (newPayslips.length > 0) {
        let currentPayslip = newPayslips.pop();
        setPayslip(currentPayslip);
      }
    },
    [payslips]
  );

  useEffect(() => {
    if (effectiveDate && ctcDetails.payoutMonth && ctcDetails.payoutYear) {
      const futureDate = new Date(new Date().setFullYear(ctcDetails.payoutYear)).setMonth(ctcDetails.payoutMonth)
      const { monthDiff } = timeDiffCalc(effectiveDate, futureDate);
      if (monthDiff < 0) {
        notification.error({ message: "Effective date can not be lesser than the payout month and year" })
      }
    }
  }, [effectiveDate, ctcDetails.payoutMonth, ctcDetails.payoutYear,])


  const yearList = []
  for (let year = new Date().getFullYear() - 10; year <= new Date().getFullYear() + 20; year++) {
    yearList.push(year)
  }

  // eslint-disable-next-line no-unused-vars
  const saveUpdateCTC = async () => {
    if (!ctcDetails.fyYear) {
      notification.error({ message: "Please Enter Financial Year" });
      return;
    }
    if (!parseFloat(ctcDetails.ctc)) {
      notification.error({ message: "Please Enter CTC" });
      return;
    }
    setLoading(true);
    const { day: date, month, year } = dateDestruct(effectiveDate);
    let data = await updateCTC({
      ...ctcDetails,
      date,
      month: month - 1,
      year,
    });
    await createLog({
      name: orgInfo.displayName,
      userId: orgInfo._id,
      organisationId: user.organisationId,
      message: `Organization User has updated the ${user.displayName}(${user.currentOrganisationMeta.employeeId})'s salary`,
      category: 16,
    });
    if (data.data.success) {
      notification.success({ message: "Successfully updated ctc" });
    } else {
      notification.error({ message: "Sorry error adding bonus" });
    }
    setShowCTC(false);
    getEmployeeData(user._id);
    setLoading(false);
  };

  const DataItem = ({ itemName, amount, className }) => {
    return (
      <tr className={className ? "salary-breakdown-table-body " + className : "salary-breakdown-table-body"}>
        <td className="salary-breakdown-table-td-1">
          {itemName}
        </td>
        <td className="salary-breakdown-table-td-2">
          {numformatter(amount)} INR
        </td>
        <td className="salary-breakdown-table-td-3">
          {numformatter(amount * 12)} INR
        </td>
      </tr>
    );
  };

  return (
    <div className="personal-info-container">
      <div className="employee-details-container salary-details-container">
        <div className="flex align-center justify-between">
          <h1 className="h5-bold-20">Salary Details</h1>
          <NormalButton
            icon={blueEditIcon}
            type={12}
            buttonText="Update Salary"
            margin={[0, 16]}
            onClick={() => {
              setShowCTC(true);
            }}
          />
        </div>
        <div className="flex">
          <div className="flex align-center my-24">
            <div className="m-right-36">
              <h1 className="ctc-label subtitle1-med-16">Annual CTC</h1>
              <p className="h5-bold-20">
                {numformatter(ctc.costToCompany[currentMonth].ctc)} INR
              </p>
            </div>
            <div className="m-left-20">
              <h3 className="ctc-label subtitle1-med-16">Monthly Income</h3>
              <p className="h5-bold-20">
                {numformatter(ctc.costToCompany[currentMonth].ctc / 12)} INR
              </p>
            </div>
          </div>
          {
            ctc.updatedStructure &&
            <>
              <Divider className="divider" type="vertical" style={{ height: "80px", margin: "2rem", width: "1.5px", background: "#ccc" }} />
              <div className="flex align-center my-24">
                <div className="m-right-36">
                  <h1 className="ctc-label subtitle1-med-16">Updated Annual CTC</h1>
                  <p className="h5-bold-20">
                    {numformatter(ctc.updatedStructure.ctc)} INR
                  </p>
                </div>
                <div className="m-left-20">
                  <h3 className="ctc-label subtitle1-med-16">Updated Monthly Income</h3>
                  <p className="h5-bold-20">
                    {numformatter(ctc.updatedStructure.ctc / 12)} INR
                  </p>
                </div>
              </div>
              <Divider className="divider" type="vertical" style={{ height: "80px", margin: "2rem", width: "1.5px", background: "#ccc" }} />
              <div className="flex flex-direction-column justify-between align-center my-24 py-10">
                <div >
                  <h1 className="ctc-label h8-regular-12">Salary Effective Date</h1>
                  <p className="h8-bold-12">
                    {new Date(ctc.updatedStructure.effectiveDate).toDateString()}
                    {/* <span>{timeDiffCalc(new Date(ctc.updatedStructure.effectiveDate), new Date()).dayDiff > 0 ? "In-active" : "Active"}</span> */}
                  </p>
                </div>
                <div className="m-top-10">
                  <h3 className="ctc-label h8-regular-12">Salary Payout Date</h3>
                  <p className="h8-bold-12">
                    {new Date(ctc.payoutDate).toDateString()}
                  </p>
                </div>
              </div>
            </>

          }
        </div>
        <div className="salary-breakdown-table-container">
          {payslip
            ? <table className="salary-breakdown-table" >
              <tr className="salary-breakdown-table-head">
                <th className="salary-breakdown-table-th-1">
                  Salary Component
                </th>
                <th className="salary-breakdown-table-th-2">Monthly Amount</th>
                <th className="salary-breakdown-table-th-3">Annual Amount</th>
              </tr>
              <DataItem
                itemName={"Basic"}
                amount={payslip.finalPayslip.basic}
              />

              {payslip.finalPayslip.HRA !== 0 &&
                <DataItem
                  itemName={"House Rent Allowance"}
                  amount={payslip.finalPayslip.HRA}
                />}
              {payslip.finalPayslip.DA !== 0 &&
                <DataItem
                  itemName={"Dearness Allowance"}
                  amount={payslip.finalPayslip.DA}
                />}
              {payslip.finalPayslip.LTA !== 0 &&
                <DataItem
                  itemName={"Leave Travel Allowance"}
                  amount={payslip.finalPayslip.LTA}
                />}
              {payslip.finalPayslip.FOOD !== 0 &&
                <DataItem
                  itemName={"Food Allowances"}
                  amount={payslip.finalPayslip.FOOD}
                />}
              {payslip.finalPayslip.specialAllowance !== 0 &&
                <DataItem
                  itemName={"Special Allowances"}
                  amount={payslip.finalPayslip.specialAllowance}
                />}
              {Object.keys(payslip.finalPayslip.otherAllowances).map(val =>
                <DataItem
                  itemName={orgInfo.organisationSettings.allowances[val] && orgInfo.organisationSettings.allowances[val]["displayName"] ? orgInfo.organisationSettings.allowances[val]["displayName"] : orgInfo.organisationSettings.allowances[val]["label"]}
                  amount={payslip.finalPayslip.otherAllowances[val]}
                />
              )}
              {Object.keys(payslip.finalPayslip.customAllowances).map(val =>
                <DataItem
                  itemName={orgInfo.organisationSettings.customAllowances[val] && orgInfo.organisationSettings.customAllowances[val]["displayName"] ? orgInfo.organisationSettings.customAllowances[val]["displayName"] : orgInfo.organisationSettings.customAllowances[val]["label"]}
                  amount={payslip.finalPayslip.customAllowances[val]}
                />
              )}
              {payslip.finalPayslip.reimbursements &&
                Object.keys(payslip.finalPayslip.reimbursements).map(val =>
                  <DataItem
                    itemName={val}
                    amount={payslip.finalPayslip.reimbursements[val]}
                  />
                )}

              {Object.keys(payslip.finalPayslip.bonuses).map(val =>
                <DataItem
                  itemName={val}
                  amount={payslip.finalPayslip.bonuses[val]}
                />
              )}
              {payslip.finalPayslip.statutoryBonuse !== 0 &&
                !isNaN(payslip.finalPayslip.statutoryBonuse) &&
                <DataItem
                  itemName={"Statutory Bonus"}
                  amount={payslip.finalPayslip.statutoryBonuse}
                />}
              <DataItem
                itemName={"Total Monthly Income "}
                className="h7-bold-19"
                amount={payslip.finalPayslip.totalEarnings}
              />

              {payslip.finalPayslip.employee_PF !== 0 &&
                <DataItem
                  itemName={"Provident Fund"}
                  amount={payslip.finalPayslip.employer_PF}
                />}
              {payslip.finalPayslip.employee_PF !== 0 &&
                payslip.finalPayslip.employee_PF >
                payslip.finalPayslip.employer_PF &&
                <DataItem
                  itemName={"Voluntary PF"}
                  amount={
                    payslip.finalPayslip.employee_PF -
                    payslip.finalPayslip.employer_PF
                  }
                />}
              {(user.regimeOptedStatus === 1
                ? payslip.finalPayslip.newRegime.incomeTax !== 0
                : payslip.finalPayslip.oldRegime.incomeTax !== 0) &&
                <DataItem
                  itemName={"Income Tax"}
                  amount={
                    (user.regimeOptedStatus === 1
                      ? payslip.finalPayslip.newRegime.incomeTax
                      : payslip.finalPayslip.oldRegime.incomeTax) +
                    (isNaN(parseInt(payslip.finalPayslip.advanceTax))
                      ? 0
                      : payslip.finalPayslip.advanceTax)
                  }
                />}
              {payslip.finalPayslip.proffTax !== 0 &&
                <DataItem
                  itemName={"Professional Tax"}
                  amount={payslip.finalPayslip.proffTax}
                />}
              {payslip.finalPayslip.monthClose &&
                Object.keys(payslip.finalPayslip.monthClose).map(val =>
                  <DataItem
                    itemName={val}
                    amount={payslip.finalPayslip.monthClose[val]}
                  />
                )}
              {payslip.finalPayslip.FOOD !== 0 && (orgInfo?.organisationSettings?.isFoodRecovery ?? true) &&
                <DataItem
                  itemName={"Food Allowances"}
                  amount={payslip.finalPayslip.FOOD}
                />}
              {payslip.finalPayslip.employee_LWF !== 0 &&
                !isNaN(payslip.finalPayslip.employee_LWF) &&
                <DataItem
                  itemName={"Labour Walfare Fund"}
                  amount={payslip.finalPayslip.employee_LWF}
                />}

              {payslip.finalPayslip.employee_ESI !== 0 &&
                <DataItem
                  itemName={"ESIC"}
                  amount={payslip.finalPayslip.employee_ESI}
                />}
              <DataItem
                itemName={"Total Deduction"}
                className="h7-bold-19"
                amount={
                  payslip.user.regimeOptedStatus === 1
                    ? payslip.finalPayslip.newRegime.deductions
                    : payslip.finalPayslip.oldRegime.deductions
                }
              />

              <DataItem
                itemName={"Net Pay"}
                className="h7-bold-20"
                amount={
                  payslip.user.regimeOptedStatus === 1
                    ? payslip.finalPayslip.newRegime.takeHome
                    : payslip.finalPayslip.oldRegime.takeHome
                }
              />
            </table>
            : <div className="my-30">
              <img
                alt="img"
                className="payout-state-0-image"
                src={payoutEmpty}
              />{" "}
            </div>}
        </div>

        <Modal
          footer={null}
          centered
          visible={showCTC}
          width={400}
          onCancel={() => {
            setShowCTC(false);
          }}
          style={{ margin: "0px 15px" }}
        >
          <Spin spinning={loading} size="large">
            <div className="font-size-global-24 font-weight-global-700 ">
              Update Salary
            </div>
            <div style={{ marginTop: "1em", width: "100%" }} >
              <DropdownComponent
                labeltext={"Financial Year"}
                className="general-ant-field-select"
                style={{ width: "100%", marginTop: 10 }}
                size="large"
                width={"100%"}
                value={ctcDetails.fyYear}
                values={financialYearList}
                options={financialYearList}
                onChange={e => {
                  setCTCDetails({ ...ctcDetails, fyYear: e });
                }}
                bordered={false}
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0}
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())}
                showSearch
              >
                <Option value={""}>Financial Year...</Option>
                {financialYearList.map(val =>
                  <Option value={val}>
                    {val}
                  </Option>
                )}
              </DropdownComponent>
            </div>
            <div className="p-top-10 ">
              <DateComponent
                className="profile-data-display single-employee-profile-datepicker"
                labelText={"Effective Date (YYYY-MM-DD)"}
                value={
                  isValidDate(new Date(effectiveDate))
                    ? moment(new Date(effectiveDate), "YYYY-MM-DD")
                    : ""
                }
                onChange={(e) =>
                  setEffectiveDate(moment(new Date(e)).format("MM/DD/YYYY"))
                }
                width="100%"
                style={{
                  width: "100%",
                }}
                size="large"
              />
            </div>
            <div
              className="p-top-10 flex justify-between"
            >
              <DropdownComponent
                labeltext={"Payout Month"}
                className="general-ant-field-input"
                value={monthList[ctcDetails.payoutMonth ?? new Date().getMonth()]}
                options={monthList}
                values={monthList}
                width={"200px"}
                onChange={e => {
                  setCTCDetails({ ...ctcDetails, payoutMonth: monthList.indexOf(e) });
                }}
                size={"large"}
              />
              <div style={{ marginLeft: "10px" }} />
              <DropdownComponent
                labeltext={"Payout Year"}
                width={"140px"}
                className="general-ant-field-input"
                value={ctcDetails.payoutYear ?? new Date().getFullYear()}
                values={yearList.map((_val, index) => _val)}
                options={yearList}
                onChange={(e) => {
                  setCTCDetails({
                    ...ctcDetails,
                    payoutYear: e,
                  });
                }}
                size={"large"}
              />
            </div>
            <div style={{ margin: "1em 0 8px", }}>
              <InputFieldComponent
                labeltext={"CTC"}
                type={"number"}
                width={"100%"}
                value={ctcDetails.ctc}
                onChange={e => {
                  setCTCDetails({
                    ...ctcDetails,
                    ctc: e.target.value
                  });
                }}

                placeholder="Cost to Company"
                className="general-ant-field-input"
                size={"large"}
                style={{ width: "100%" }}
              />
            </div>

            <div  >
              <InputFieldComponent
                type={"number"}
                width={"100%"}
                labeltext={"Variable Percentage"}
                className="general-ant-field-input"
                value={ctcDetails.variablePercentage}
                min={0}
                onChange={e => {
                  setCTCDetails({
                    ...ctcDetails,
                    variablePercentage: e.target.value
                  });
                }}
              />

            </div>
            <div  >
              <DropdownComponent
                labeltext={"Is Employer Contribution Included In CTC?"}
                className="general-ant-field-select"
                style={{ width: "100%" }}
                size="large"
                width={"100%"}
                value={ctcDetails.isEmployerContributionIncludedInCTC}
                options={["Yes", "No"]}
                values={[true, false]}
                onChange={e => {
                  setCTCDetails({
                    ...ctcDetails,
                    isEmployerContributionIncludedInCTC: e
                  });
                }}
                bordered={false}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </DropdownComponent>
            </div>

            <div style={{ marginTop: "1em" }} >
              <NormalButton
                buttonText="Update CTC"
                type={13}
                block={"100%"}
                margin={[10, 0]}
                onClick={saveUpdateCTC}
              />
            </div>
          </Spin>
        </Modal>
      </div>
    </div>
  );
};

export default SalaryDetails;
