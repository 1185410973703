import { Spin, Row, Col, Upload, notification } from "antd";
import { useState, useEffect } from "react";
import { NormalButton } from "../../../ComponentLibrary";
import payoutEmpty from "../../../../assets/organisationConfiguration/AllowanceEmpty.svg";
import config, { validateEmail } from "../../../../utils/config";
import uploadLogo from "../../../../assets/icons/savefile.svg";
import UploadComplete from "../../../../assets/icons/UploadComplete.svg";
import netpayIcon from "../../../../assets/OrganisationDashboardd/netpay.svg";

import {
  getCashfreeBalance,
  newOrgPayout,
  orgPayoutDetails,
} from "../../../../apis/organisation";
import sitBackAndEnjoy from "../../../../assets/organisationSettings/sitBackAndEnjoy.svg";
import { connect } from "react-redux";
import { removeS3Object } from "../../../../apis/employee";
import { HeaderComponent } from "../../../Common";
import { createLog } from "../../../../apis/user";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"

const { Dragger } = Upload;

const Payout = ({ user, isTrial }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [pageState, setPageState] = useState(0);
  const [newOrgDetails, setNewOrgDetails] = useState({
    businessEmail: "",
    businessNumber: null,
    address: "",
    city: "",
    state: "",
    pinCode: null,
    bankName: "",
    IFSC: "",
    bankAccountNo: "",
    PAN: user.PAN,
    organisationId: user.organisationId,
    accountProof: {},
    Cert_incorporation_business: {},
    uploads: {},
    GSTCertificate: {},
    directorPAN: {},
  });
  const [cashfreeBalance, setCashfreeBalance] = useState({
    availableBalance: 0,
    balance: 0,
  });
  const [cashfreeDetails, setCashfreeDetails] = useState({});

  const getOrgPayoutInfo = async () => {
    setIsLoading(true);
    let res = await orgPayoutDetails(user.organisationId);
    if (res.status === 200) {
      if (res.data) {
        setNewOrgDetails(res.data)
        setPageState(res.data.instanceStatus);
        if (res.data.instanceStatus === 3) {
          await getOrgBalance();
          setCashfreeDetails(res.data);
        }
      } else {
        setPageState(0);
      }
    }
    setIsLoading(false);
  };

  const getOrgBalance = async () => {
    setIsLoading(true);
    let res = await getCashfreeBalance(user.organisationId);
    setCashfreeBalance({
      availableBalance: isNaN(parseFloat(res.data.availableBalance))
        ? 0
        : parseFloat(res.data.availableBalance),
      balance: isNaN(parseFloat(res.data.balance))
        ? 0
        : parseFloat(res.data.balance),
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getOrgPayoutInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    document.title = 'Settings | Payouts';
  });
  useEffect(() => {
    return () => {
      Object.keys(newOrgDetails).forEach((key) => {
        if (
          newOrgDetails[key] === {} &&
          Object.keys(newOrgDetails[key]).length !== 0
        ) {
          removeS3FileObject(newOrgDetails[key]);
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOrgDetails])

  const removeS3FileObject = async (item) => {
    await removeS3Object({ Key: item.key });
  };

  const draggerProps = {
    maxCount: 1,
    name: "file",
    multiple: false,
    action: `${config.rootUrl}/api/document/orgInstance`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    showUploadList: false,
    beforeUpload: config.beforeFileUpload,
  };

  const accountProofDraggerProps = {
    async onChange(info) {
      setIsFileLoading(false);
      let { response } = info.file;
      if (response) {
        if (Object.keys(newOrgDetails.accountProof).length !== 0) {
          removeS3FileObject(newOrgDetails.accountProof);
        }
        setIsFileLoading(false);
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        documentDetails.name = info.file.name.split(".")[0];
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        setNewOrgDetails({
          ...newOrgDetails,
          accountProof: documentDetails,
        });
      }
    },
  };

  const Cert_incorporation_businessDraggerProps = {
    async onChange(info) {
      setIsFileLoading(false);
      let { response } = info.file;
      if (response) {
        if (
          Object.keys(newOrgDetails.Cert_incorporation_business).length !== 0
        ) {
          removeS3FileObject(newOrgDetails.Cert_incorporation_business);
        }
        setIsFileLoading(false);
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        documentDetails.name = info.file.name.split(".")[0];
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        setNewOrgDetails({
          ...newOrgDetails,
          Cert_incorporation_business: documentDetails,
        });
      }
    },
  };

  const uploadsDraggerProps = {
    async onChange(info) {
      setIsFileLoading(false);
      let { response } = info.file;
      if (response) {
        if (Object.keys(newOrgDetails.uploads).length !== 0) {
          removeS3FileObject(newOrgDetails.uploads);
        }
        setIsFileLoading(false);
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        documentDetails.name = info.file.name.split(".")[0];
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        setNewOrgDetails({
          ...newOrgDetails,
          uploads: documentDetails,
        });
      }
    },
  };

  const GSTCertificateDraggerProps = {
    async onChange(info) {
      setIsFileLoading(false);
      let { response } = info.file;
      if (response) {
        if (Object.keys(newOrgDetails.GSTCertificate).length !== 0) {
          removeS3FileObject(newOrgDetails.GSTCertificate);
        }
        setIsFileLoading(false);
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        documentDetails.name = info.file.name.split(".")[0];
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        setNewOrgDetails({
          ...newOrgDetails,
          GSTCertificate: documentDetails,
        });
      }
    },
  };

  const directorPANDraggerProps = {
    async onChange(info) {
      setIsFileLoading(false);
      let { response } = info.file;
      if (response) {
        if (Object.keys(newOrgDetails.directorPAN).length !== 0) {
          removeS3FileObject(newOrgDetails.directorPAN);
        }
        setIsFileLoading(false);
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        documentDetails.name = info.file.name.split(".")[0];
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        setNewOrgDetails({
          ...newOrgDetails,
          directorPAN: documentDetails,
        });
      }
    },
  };

  const onSave = async () => {
    setIsLoading(true);
    if (!validateEmail(newOrgDetails.businessEmail)) {
      notification.error({ message: "Email not valid." });
      setIsLoading(false);
      return;
    }
    if (
      newOrgDetails.businessNumber === null ||
      String(newOrgDetails.businessNumber).length !== 10
    ) {
      notification.error({ message: "Phone number is not valid." });
      setIsLoading(false);
      return;
    }
    if (newOrgDetails.address === "") {
      notification.error({ message: "Address cannot be empty." });
      setIsLoading(false);
      return;
    }
    if (newOrgDetails.city === "") {
      notification.error({ message: "City cannot be empty." });
      setIsLoading(false);
      return;
    }
    if (newOrgDetails.state === "") {
      notification.error({ message: "State cannot be empty." });
      setIsLoading(false);
      return;
    }
    if (
      newOrgDetails.pinCode === null ||
      String(newOrgDetails.pinCode).length !== 6
    ) {
      notification.error({ message: "Pincode is not valid." });
      setIsLoading(false);
      return;
    }
    if (newOrgDetails.bankAccountNo === "") {
      notification.error({ message: "Bank account number cannot be empty." });
      setIsLoading(false);
      return;
    }
    if (newOrgDetails.IFSC === "") {
      notification.error({ message: "IFSC cannot be empty." });
      setIsLoading(false);
      return;
    }
    if (newOrgDetails.bankName === "")
      if (
        Object.keys(newOrgDetails.directorPAN).length === 0 ||
        Object.keys(newOrgDetails.GSTCertificate).length === 0 ||
        Object.keys(newOrgDetails.uploads).length === 0 ||
        Object.keys(newOrgDetails.Cert_incorporation_business).length === 0 ||
        Object.keys(newOrgDetails.accountProof).length === 0
      ) {
        notification.error({
          message: "Upload all supporting document files.",
        });
        setIsLoading(false);
        return;
      }
    let res = await newOrgPayout(newOrgDetails);
    if (res.status === 200 && res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Successfully updated the payouts`,
        category: 14,
      });
      setPageState(2);
    } else {
      notification.error({
        message: "Failed to request for payout, please try again.",
      });
    }
    setIsLoading(false);
  };

  const UnderReview = () => {
    return (
      <div className="flex flex-direction-column align-center">
        <img alt="img" src={sitBackAndEnjoy} className="block my-36" />
        <p className="title-gray-3 my-36">
          Just sit back and enjoy while our team is setting up your payout
        </p>
      </div>
    );
  };

  const PayoutAlreadySetup = () => {
    return (

      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="paper-wrapper flex justify-between h-full">
            <div className="flex align-center">
              <img
                src={netpayIcon}
                height="60"
                width="60"
                alt="employees"
              />
              <div className="m-left-24">
                <h1 className="font-size-14 color-grey font-weight-500">
                  Available Balance
                </h1>
                <p className="h5-bold-20">
                  INR {cashfreeBalance.availableBalance}
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="paper-wrapper">
            <h1 className="h5-bold-20">Account Details</h1>
            <div className="">
              <div className="w-full flex justify-between align-center">
                <span className="app-label-3 font-weight-700">A/C</span>
                <span className="app-label-value-4 font-weight-700">
                  {cashfreeDetails.cashfreeAccount}
                </span>
              </div>
              <div className="w-full flex justify-between align-center">
                <span className="app-label-3 font-weight-700">IFSC</span>
                <span className="app-label-value-4 font-weight-700">
                  {cashfreeDetails.cashfreeIFSC}
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    isTrial ? <TrialPage user={user} trialMessage={"Configure Mool as per your company policy"} bgImage={configurationsBgImage}
    /> :
      <div className="site-layout">
        <HeaderComponent
          show={false}
          name="Payouts"
        />
        <div className="app-main-content-container paper-wrapper">
          {pageState !== 1 && <h1 className="h1-bold-24">Payout</h1>}
          {pageState === 0 && (
            <ZeroState isLoading={isLoading} setPageState={setPageState} />
          )}
          {pageState === 1 && (
            <PayoutAccount
              pageState={pageState}
              isLoading={isLoading}
              setPageState={setPageState}
              user={user}
              onSave={onSave}
              newOrgDetails={newOrgDetails}
              setNewOrgDetails={setNewOrgDetails}
              isFileLoading={isFileLoading}
              directorPANDraggerProps={directorPANDraggerProps}
              draggerProps={draggerProps}
              accountProofDraggerProps={accountProofDraggerProps}
              uploadsDraggerProps={uploadsDraggerProps}
              GSTCertificateDraggerProps={GSTCertificateDraggerProps}
              Cert_incorporation_businessDraggerProps={
                Cert_incorporation_businessDraggerProps
              }
            />
          )}
          {pageState === 2 && <UnderReview />}
          {pageState === 3 && <PayoutAlreadySetup />}
        </div>
      </div>
  );
};

const PayoutAccount = ({
  pageState,
  setPageState,
  user,
  onSave,
  newOrgDetails,
  setNewOrgDetails,
  isFileLoading,
  directorPANDraggerProps,
  draggerProps,
  accountProofDraggerProps,
  uploadsDraggerProps,
  GSTCertificateDraggerProps,
  Cert_incorporation_businessDraggerProps,
}) => {
  return (
    <div>
      <div className="heading-style-2-container flex justify-between align-center">
        <div className="flex align-center">
          <h1 className="heading-name">
            Payout Account for "{user.displayName}"
          </h1>
        </div>
        <div>
          {
            pageState === 1 ? (<NormalButton type={13} buttonText="Update" onClick={onSave} />) : <><NormalButton
              type={5}
              buttonText="Cancel"
              margin={[0, 16]}
              onClick={() => setPageState(0)}
            />
              <NormalButton type={1} buttonText="Save" onClick={onSave} /></>
          }
        </div>
      </div>
      <div className="payout-details-container">
        <div className="payout-details-sub-section-container">
          <Row gutter={[100, 20]}>
            <Col sm={24} md={12} lg={9} xl={9}>
              <InputFieldComponent
                labeltext={"Email"}
                value={newOrgDetails.businessEmail}
                onChange={(e) => {
                  setNewOrgDetails({
                    ...newOrgDetails,
                    businessEmail: e.target.value,
                  });
                }}
              />
            </Col>
            <Col sm={24} md={12} lg={9} xl={9}>
              <label className="app-label-5">Phone number</label>
              <InputFieldComponent
                type={"number"}
                labeltext={"Phone Number"}
                value={newOrgDetails.businessNumber}
                onChange={(e) => {
                  setNewOrgDetails({
                    ...newOrgDetails,
                    businessNumber:
                      e.target.value > -1 && e.target.value < 10000000000
                        ? e.target.value
                        : newOrgDetails.businessNumber,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="payout-details-sub-section-container">
          <p className="sub-section-heading">ADDRESS</p>
          <Row gutter={[100, 20]}>
            <Col sm={24} md={12} lg={9} xl={9}>
              <InputFieldComponent
                value={newOrgDetails.address}
                labeltext={"Address"}
                onChange={(e) => {
                  setNewOrgDetails({
                    ...newOrgDetails,
                    address: e.target.value,
                  });
                }}
              />
            </Col>
            <Col sm={24} md={12} lg={8} xl={9}>
              <InputFieldComponent
                value={newOrgDetails.city}
                labeltext={"City"}
                onChange={(e) => {
                  setNewOrgDetails({
                    ...newOrgDetails,
                    city: e.target.value,
                  });
                }}
              />
            </Col>
            <Col sm={24} md={12} lg={8} xl={9}>
              <InputFieldComponent
                value={newOrgDetails.state}
                labeltext={"State"}
                onChange={(e) => {
                  setNewOrgDetails({
                    ...newOrgDetails,
                    state: e.target.value,
                  });
                }}
              />
            </Col>
            <Col sm={24} md={12} lg={8} xl={9}>
              <InputFieldComponent
                type={"number"}
                value={newOrgDetails.pinCode}
                labeltext={"Pin Code"}
                onChange={(e) => {
                  setNewOrgDetails({
                    ...newOrgDetails,
                    pinCode:
                      e.target.value > -1 && e.target.value < 1000000
                        ? e.target.value
                        : newOrgDetails.pinCode,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="payout-details-sub-section-container">
          <p className="sub-section-heading">BANK DETAILS</p>
          <Row gutter={[100, 20]}>
            <Col sm={24} md={12} lg={9} xl={9}>
              <InputFieldComponent
                value={newOrgDetails.bankAccountNo}
                labeltext={"Account Number"}
                onChange={(e) => {
                  setNewOrgDetails({
                    ...newOrgDetails,
                    bankAccountNo: e.target.value,
                  });
                }}
              />
            </Col>
            <Col sm={24} md={12} lg={9} xl={9}>
              <InputFieldComponent
                value={newOrgDetails.IFSC}
                labeltext={"IFSC Code"}
                onChange={(e) => {
                  setNewOrgDetails({
                    ...newOrgDetails,
                    IFSC: e.target.value,
                  });
                }}
              />
            </Col>
            <Col sm={24} md={12} lg={9} xl={9}>
              <InputFieldComponent
                value={newOrgDetails.bankName}
                labeltext={"Bank Name"}
                onChange={(e) => {
                  setNewOrgDetails({
                    ...newOrgDetails,
                    bankName: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="payout-details-sub-section-container">
          <p className="sub-section-heading">DOCUMENTS</p>
          <Row gutter={[100, 20]}>
            <Col sm={24} md={12} lg={9} xl={9}>
              <label className="app-label-5">
                Canceled cheque or Account Statement{" "}
              </label>
              <Dragger
                className="payout-details-document-dragger"
                {...draggerProps}
                {...accountProofDraggerProps}
                style={
                  Object.keys(newOrgDetails.accountProof).length === 0
                    ? {}
                    : { backgroundColor: "#e4fff8" }
                }
              >
                <Spin spinning={isFileLoading}>
                  {Object.keys(newOrgDetails.accountProof).length === 0 ? (
                    <div className="payout-details-document-container flex align-center justify-center">
                      <div>
                        <img
                          alt="img"
                          src={uploadLogo}
                          className="sub-section-dragger-image"
                        />
                      </div>
                      <p className="sub-section-dragger-text">
                        Drag and drop your files or click over the space to
                        upload
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <img
                          alt="img"
                          src={UploadComplete}
                          className="sub-section-dragger-image"
                        />
                      </div>
                      <p className="sub-section-dragger-text">
                        {newOrgDetails.accountProof.name} has been uploaded.
                      </p>
                    </div>
                  )}
                </Spin>
              </Dragger>
            </Col>
            <Col sm={24} md={12} lg={9} xl={9}>
              <label className="app-label-5">Business Certificate</label>
              <Dragger
                className="payout-details-document-dragger"
                {...draggerProps}
                {...Cert_incorporation_businessDraggerProps}
                style={
                  Object.keys(newOrgDetails.Cert_incorporation_business)
                    .length === 0
                    ? {}
                    : { backgroundColor: "#e4fff8" }
                }
              >
                <Spin spinning={isFileLoading}>
                  {Object.keys(newOrgDetails.Cert_incorporation_business)
                    .length === 0 ? (
                    <div className="payout-details-document-container flex align-center justify-center">
                      <div>
                        <img
                          alt="img"
                          src={uploadLogo}
                          className="sub-section-dragger-image"
                        />
                      </div>
                      <p className="sub-section-dragger-text">
                        Drag and drop your files or click over the space to
                        upload
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <img
                          alt="img"
                          src={UploadComplete}
                          className="sub-section-dragger-image"
                        />
                      </div>
                      <p className="sub-section-dragger-text">
                        {newOrgDetails.Cert_incorporation_business.name} has
                        been uploaded.
                      </p>
                    </div>
                  )}
                </Spin>
              </Dragger>
            </Col>
            <Col sm={24} md={12} lg={9} xl={9}>
              <label className="app-label-5">PAN</label>
              <Dragger
                className="payout-details-document-dragger"
                {...draggerProps}
                {...uploadsDraggerProps}
                style={
                  Object.keys(newOrgDetails.uploads).length === 0
                    ? {}
                    : { backgroundColor: "#e4fff8" }
                }
              >
                <Spin spinning={isFileLoading}>
                  {Object.keys(newOrgDetails.uploads).length === 0 ? (
                    <div className="payout-details-document-container flex align-center justify-center">
                      <div>
                        <img
                          alt="img"
                          src={uploadLogo}
                          className="sub-section-dragger-image"
                        />
                      </div>
                      <p className="sub-section-dragger-text">
                        Drag and drop your files or click over the space to
                        upload
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <img
                          alt="img"
                          src={UploadComplete}
                          className="sub-section-dragger-image"
                        />
                      </div>
                      <p className="sub-section-dragger-text">
                        {newOrgDetails.uploads.name} has been uploaded.
                      </p>
                    </div>
                  )}
                </Spin>
              </Dragger>
            </Col>
            <Col sm={24} md={12} lg={9} xl={9}>
              <label className="app-label-5">GST Certificate</label>
              <Dragger
                className="payout-details-document-dragger"
                {...draggerProps}
                {...GSTCertificateDraggerProps}
                style={
                  Object.keys(newOrgDetails.GSTCertificate).length === 0
                    ? {}
                    : { backgroundColor: "#e4fff8" }
                }
              >
                <Spin spinning={isFileLoading}>
                  {Object.keys(newOrgDetails.GSTCertificate).length === 0 ? (
                    <div className="payout-details-document-container flex align-center justify-center">
                      <div>
                        <img
                          alt="img"
                          src={uploadLogo}
                          className="sub-section-dragger-image"
                        />
                      </div>
                      <p className="sub-section-dragger-text">
                        Drag and drop your files or click over the space to
                        upload
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <img
                          alt="img"
                          src={UploadComplete}
                          className="sub-section-dragger-image"
                        />
                      </div>
                      <p className="sub-section-dragger-text">
                        {newOrgDetails.GSTCertificate.name} has been uploaded.
                      </p>
                    </div>
                  )}
                </Spin>
              </Dragger>
            </Col>
            <Col sm={24} md={12} lg={9} xl={9}>
              <label className="app-label-5">Director’s PAN</label>
              <Dragger
                className="payout-details-document-dragger"
                {...draggerProps}
                {...directorPANDraggerProps}
                style={
                  Object.keys(newOrgDetails.directorPAN).length === 0
                    ? {}
                    : { backgroundColor: "#e4fff8" }
                }
              >
                <Spin spinning={isFileLoading}>
                  {Object.keys(newOrgDetails.directorPAN).length === 0 ? (
                    <div className="payout-details-document-container flex align-center justify-center">
                      <div>
                        <img
                          alt="img"
                          src={uploadLogo}
                          className="sub-section-dragger-image"
                        />
                      </div>
                      <p className="sub-section-dragger-text">
                        Drag and drop your files or click over the space to
                        upload
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div>
                        <img
                          alt="img"
                          src={UploadComplete}
                          className="sub-section-dragger-image"
                        />
                      </div>
                      <p className="sub-section-dragger-text">
                        {newOrgDetails.directorPAN.name} has been uploaded.
                      </p>
                    </div>
                  )}
                </Spin>
              </Dragger>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const ZeroState = ({ isLoading, setPageState }) => {
  return (
    <Spin spinning={isLoading}>
      <div className="payout-state-0-container flex flex-direction-column align-center">
        <div className="my-30">
          <img alt="img" className="payout-state-0-image" src={payoutEmpty} />
        </div>
        <p className="title-gray-2 my-30">
          Auto-payouts has not been set up for your organization
        </p>
        <NormalButton
          margin={[24, 0]}
          buttonText="Enable Payout"
          type={13}
          onClick={() => setPageState(1)}
        />
      </div>
    </Spin>
  );
};

const PayoutContainer = connect((state) => ({
  user: state.auth.user,
  isTrial: state.auth.user.isTrial,
}))(Payout);

export default PayoutContainer;