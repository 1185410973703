import { Radio, Row, Skeleton, Slider } from "antd";
import InputNumberComponent from "../../InputLibrary/InputNumberComponent/InputNumberComponent";

import "../../../styles/ctccalcstyle.less"

const BasicInfo = ({ basicInfo, setBasicInfo, organisationSettings }) => {
  const options = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];
  // const term = [
  //   { label: "CTC Calculator", value: "true" },
  //   { label: "Optimal Pay Structure", value: "false" },
  // ];
  const type = [
    { label: "Maximum Takehome", value: "false" },
    { label: "Maximum Savings", value: "true" },
  ];
  return (
    <div className="h-full w-full">
      <p className="font-size-global-24 font-weight-700" style={{ marginBottom: "3rem" }}>Basic Details {basicInfo.isMonthly === "true" ? " For CTC Calculator" :
        "For Optimal Pay Structure"}</p>
      <Skeleton
        loading={Object.keys(organisationSettings).length === 0}
        active
        paragraph={{ rows: 15 }}
      >
        {/* <div className="org-data-container">
          <h3 className="general-ant-field-label">Type</h3>
          <Radio.Group
            options={term}
            style={{ marginTop: "7px" }}
            value={basicInfo.isMonthly}
            onChange={(val) =>
              setBasicInfo({ ...basicInfo, isMonthly: val.target.value })
            }
          />
        </div> */}
        <div className="org-data-container">
          <h3 className="general-ant-field-label">
            How much is the candidate expecting per {`${basicInfo.isMonthly === "true" ? "month ?" : "annum ?"}`}{" "}
          </h3>
          <InputNumberComponent
            value={basicInfo.ctc}
            onChange={(val) => setBasicInfo({ ...basicInfo, ctc: val })}
            labeltext="Amount"
            margin={[20, 0, 20, 0]}
          />
        </div>
        <Row justify="space-between">
          <div className="org-data-container">
            <h3 className="general-ant-field-label">
              Is the candidate staying in a metro?
            </h3>
            <Radio.Group
              options={options}
              style={{ marginTop: "7px" }}
              value={basicInfo.isMetro}
              onChange={(val) =>
                setBasicInfo({ ...basicInfo, isMetro: val.target.value })
              }
            />
            <p className="font-size-global-16" style={{ color: "red", margin: "10px 0" }}>
              Note: Only Mumbai, Delhi, Chennai, Kolkata are considered as metro
            </p>
          </div>
          <div className="org-data-container">
            <h3 className="general-ant-field-label">
              What is the amount that the candidate is paying for the home?
            </h3>
            <InputNumberComponent
              value={basicInfo.rent}
              labeltext="Amount"
              onChange={(val) => setBasicInfo({ ...basicInfo, rent: val })}
              margin={[20, 0, 20, 0]}
            />
            <p className="font-size-global-16" style={{ color: "red", margin: "10px 0" }}>
              Note: you can choose to let the field be empty if the candidate is
              not paying any rent for the house.
            </p>
          </div>
        </Row>
        <div className="org-data-container">
          <h3 className="general-ant-field-label">
            Enter the variable percentage you are giving
          </h3>
          <InputNumberComponent
            min={0}
            max={100}
            labeltext="Percentage"
            value={basicInfo.variable}
            onChange={(val) => setBasicInfo({ ...basicInfo, variable: val })}
            margin={[20, 0, 20, 0]}
          />
        </div>
        <Row justify="space-between">
          {organisationSettings &&
            organisationSettings.pfEnabled &&
            basicInfo.isMonthly === "false" && (
              <div className="org-data-container">
                <h3 className="general-ant-field-label">
                  Select the type of optimization that you want to do
                </h3>
                <Radio.Group
                  options={type}
                  style={{ marginTop: "7px" }}
                  value={basicInfo.isSavings}
                  onChange={(val) =>
                    setBasicInfo({ ...basicInfo, isSavings: val.target.value })
                  }
                />
              </div>
            )}
          {basicInfo.isSavings === "true" && (basicInfo.isMonthly === "false") && (
            <div className="org-data-container" style={{ paddingRight: "5.5rem" }}>
              <h3 className="font-size-global-18 font-weight-700">
                Select the percentage of savings that the employee want to do
              </h3>
              <Slider
                tooltipVisible={false}
                style={{ margin: "10px 0" }}
                disabled={!basicInfo.isSavings}
                value={basicInfo.slider}
                onChange={(val) => {
                  setBasicInfo({ ...basicInfo, slider: val });
                }}
                max={1000}
                min={0}
                handleStyle={{ height: "24px", width: "24px" }}
                trackStyle={{
                  height: "18px",
                  borderRadius: "18px",
                  backgroundColor: "#405cd2",
                }}

              />
            </div>
          )}
        </Row>
      </Skeleton>
    </div>
  );
};

export default BasicInfo;