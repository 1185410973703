import { useState,useEffect } from "react";
import { Tabs } from "antd";
import Allowance from "./components/allowance.js";
import Deduction from "./components/deduction.js";
import TaxProjection from "../PreviousEmployment/TaxProjection.js"
import allowanceActiveLogo from "../../assets/manageDeclaration/allowanceActiveLogo.svg";
import allowanceInactiveLogo from "../../assets/manageDeclaration/allowanceInactiveLogo.svg";
import deductionActiveLogo from "../../assets/manageDeclaration/deductionActiveLogo.svg";
import deductionInactiveLogo from "../../assets/manageDeclaration/deductionInactiveLogo.svg";
import { HeaderComponent } from "../Common";
import activeDeclaration from "../../assets/icons/activeIcons/activeDeclaration.svg";
import taxProjectionActiveLogo from "../../assets/manageDeclaration/taxProjection.svg";
import { connect } from "react-redux";
import { PreviousEmployment } from "../index.js";

const { TabPane } = Tabs;



const ManageDeclaration = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [the12BBApproved, setThe12BBApproved] = useState(false)


  useEffect(() => {
    document.title = 'Manage | Declarations';
  });


  return (
    <div className="site-layout">
      <HeaderComponent icon={activeDeclaration} name="Declaration" />
      <div className="manage-declaration-container declaration-page-main-tabs-container">
        <Tabs
          //   tabBarExtraContent={operations}
          defaultActiveKey="1"
          onChange={(e) => {
            setActiveTab(parseInt(e));
          }}
        >
          <TabPane
            tab={
              <div className="tabs">
                <img
                  alt=""
                  className="tab-logo"
                  src={
                    activeTab === 1
                      ? deductionActiveLogo
                      : deductionInactiveLogo
                  }
                ></img>
                <span className="tab-name">Deductions</span>
              </div>
            }
            key="1"
          >
            <Deduction organisationFreeze={props.organisationFreeze} the12BBApproved={the12BBApproved} />
          </TabPane>
          <TabPane
            tab={
              <div className="tabs">
                <img
                  alt=""
                  className="tab-logo"
                  src={
                    activeTab === 2
                      ? allowanceActiveLogo
                      : allowanceInactiveLogo
                  }
                ></img>
                <span className="tab-name">Allowance</span>
              </div>
            }
            key="2"
          >
            <Allowance organisationFreeze={props.organisationFreeze} the12BBApproved={the12BBApproved} />
          </TabPane>
          <TabPane
            tab={
              <div className="tabs">
                <img
                  alt=""
                  className="tab-logo"
                  src={
                    taxProjectionActiveLogo
                  }
                ></img>
                <span className="tab-name">Tax Projection</span>
              </div>
            }
            key="3"
          >
            <TaxProjection user={props.user} declaration={props.declaration} payslips={props.payslips} the12BBApproved={the12BBApproved} setThe12BBApproved={setThe12BBApproved} />
          </TabPane>
          {
            props.isCurrentFinancialYearJoinee && <TabPane
              tab={
                <div className="tabs">
                  <img
                    alt=""
                    className="tab-logo"
                    src={
                      taxProjectionActiveLogo
                    }
                  ></img>
                  <span className="tab-name">Previous Employment</span>
                </div>
              }
              key="4"
            >
              <PreviousEmployment />
            </TabPane>
          }

        </Tabs>
      </div>
    </div>
  );
};

const ManageDeclarationContainer = connect((state) => ({
  user: state.auth.user,
  declaration: state.employee.declaration,
  payslips: state.employee.payslips,
  organisationFreeze: state.employee.organisationFreeze,
  isCurrentFinancialYearJoinee: state.auth.isCurrentFinancialYearJoinee
}))(ManageDeclaration);

export default ManageDeclarationContainer;
