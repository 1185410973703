/* eslint-disable jsx-a11y/anchor-is-valid */
import { Content } from "antd/lib/layout/layout";
import HeaderComponent from "../../Common/Header/Header";
import { Link, withRouter } from "react-router-dom";
import { useEffect } from "react";
import {
  Spin,
  Upload,
  Breadcrumb,
  Progress,
  notification,
  DatePicker,
  Row,
  Col,
  Select,
} from "antd";

import {
  createNewEmployee,
  updateEmployerOnBoarding,
} from "../../../apis/organisation";
import uploadLogo from "../../../assets/icons/savefile.svg";
import UploadComplete from "../../../assets/icons/UploadComplete.svg";
import { useState } from "react";
import config from "../../../utils/config";
import { InputField, NormalButton, Status } from "../../ComponentLibrary";
import moment from "moment";
import { connect } from "react-redux";
import organisation from "../../../redux/modules/organisation";
import axios from "../../../apis";
import { paths } from "../../../constants/routes";
import { createLog } from "../../../apis/user";
const { Dragger } = Upload;
const { Option } = Select;

const AddMultipleEmployeePage = (props) => {
  const user = props.history.location.state;
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedEmployeeList, setUploadedEmployeeList] = useState([]);
  const [
    uploadedEmployeeListExistsWithOtherOrg,
    setUploadedEmployeeListExistsWithOtherOrg,
  ] = useState([]);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [percentageOfAddedRows, setPercentageOfAddedRows] = useState(0);
  const [fileUpload, setFileUpload] = useState(false);

  useEffect(() => {
    document.title = 'Add Multiple Employees';
  });

  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/employee/excel/bulk/parser`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        try {
          let tempUploadedEmployeeList = response["employeesList"];
          setTotalNumberOfRows(tempUploadedEmployeeList.length);
          setUploadedEmployeeList([...tempUploadedEmployeeList]);
          setUploadedEmployeeListExistsWithOtherOrg(
            response["existingWithOneOrg"]
          );
          setFileUpload(true);
        } catch (e) {
          notification.error({
            message: `Failed to upload Excel file, Please try again`,
          });
        }
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  const updateOnboarding = async (type) => {
    const { user: usr, onboarding, organisationReducer } = props;
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployerOnBoarding({
        fields,
        organisationId: usr.organisationId,
        id: usr._id,
      });
      organisationReducer.updateEmployerOnBoarding({
        organisationId: usr.organisationId,
        id: usr._id,
      });
    }
  };

  /**
   * Update the fields
   * @param {*} e the field object
   * @param {*} i key of the updation field
   */
  const updateFields = (e, i) => {
    setPageState(1);
    let tempUploadedEmployeeList = uploadedEmployeeList;
    tempUploadedEmployeeList[i][e.target.name] = e.target.value;
    setUploadedEmployeeList([...tempUploadedEmployeeList]);
  };

  /**
   * Upate the slect fields
   * @param {*} value
   * @param {*} index
   * @param {*} name
   */
  const updateSelectField = (value, index, name) => {
    let tempUploadedEmployeeList = uploadedEmployeeList;
    tempUploadedEmployeeList[index][name] = value;
    setUploadedEmployeeList([...tempUploadedEmployeeList]);
  };

  let dummy = () => {
    return;
  };

  const validateEachRow = async () => {
    let allPass = true;
    let tempUploadedEmployeeList = uploadedEmployeeList;
    for (let x in tempUploadedEmployeeList) {
      let status = await CheckRowData(tempUploadedEmployeeList[x]);
      if (status.hasError) {
        allPass = false;
        tempUploadedEmployeeList[x] = status.currentEmployeeDetails;
        tempUploadedEmployeeList[x].error = true;
      } else {
        tempUploadedEmployeeList[x].error = false;
      }
    }
    setUploadedEmployeeList([...tempUploadedEmployeeList]);
    if (allPass === true) {
      setPageState(2);
    } else {
      notification.error({
        message: `Please enter vaild data in the empty fields`,
      });
      setPageState(1);
    }
  };

  const CheckRowData = async (currentEmployeeDetails) => {
    let hasError = false;
    if (!/^[A-Za-z\s]+$/.test(currentEmployeeDetails.name)) {
      currentEmployeeDetails.name = "";
      hasError = true;
    }
    if (!currentEmployeeDetails.dateOfJoining) {
      currentEmployeeDetails.dateOfJoining = "";
      hasError = true;
    }
    if (!/^[A-Za-z0-9\-\s]+$/.test(currentEmployeeDetails.employeeId)) {
      currentEmployeeDetails.employeeId = "";
      hasError = true;
    }
    if (!currentEmployeeDetails.ctc) {
      currentEmployeeDetails.ctc = "";
      hasError = true;
    }
    if (!currentEmployeeDetails.designationId) {
      currentEmployeeDetails.designationId = "";
      hasError = true;
    }
    if (!currentEmployeeDetails.departmentId) {
      currentEmployeeDetails.departmentId = "";
      hasError = true;
    }
    if (!currentEmployeeDetails.locationId) {
      currentEmployeeDetails.locationId = "";
      hasError = true;
    }
    // Check one of PAN, Email, Phone or Aadhar number is available
    if (
      !currentEmployeeDetails.PAN &&
      !currentEmployeeDetails.email &&
      !currentEmployeeDetails.aadhaarNumber
    ) {
      hasError = true;
    }
    return { hasError, currentEmployeeDetails };
  };

  const uploadEachRowData = async () => {
    setPageState(3);
    let tempUploadedEmployeeList = uploadedEmployeeList;
    for (let x in tempUploadedEmployeeList) {
      let data = await createNewEmployee({
        ...tempUploadedEmployeeList[x],
        organisationId: user.organisationId,
      });
      let percentage = Math.round(((Number(x) + 1) / totalNumberOfRows) * 100);
      setPercentageOfAddedRows(percentage);
      if (data.data.success) {
        tempUploadedEmployeeList[x].uploaded = true;
      } else if (data.data.message === "USER_EXIST_WITH_SAME_CREDENTIALS") {
        tempUploadedEmployeeList[x].uploaded = false;
        tempUploadedEmployeeList[
          x
        ].uploadFailMessage = `Another user exists with the same PAN and/or phone number.`;
      } else {
        tempUploadedEmployeeList[x].uploaded = false;
        tempUploadedEmployeeList[x].uploadFailMessage = `Network issue`;
      }
    }
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organiation User created ${uploadedEmployeeList.length} number of employees`,
      category: 16,
    });
    await updateOnboarding("employeeAddition");
    setUploadedEmployeeList([...tempUploadedEmployeeList]);
    props.organisationReducer.updateEmployeeList({
      organisationId: user.organisationId,
    });
    setPageState(4);
  };

  const handleAction = async (url, _data) => {
    let res = await axios({
      url, //your url
      method: "GET",
      responseType: "blob",
    });
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = urlDownload;
    link.setAttribute("download", `${user.displayName}-bulk-employee.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  return (
    <>
      <div className="site-layout">
        <HeaderComponent name="Bulk Upload" />

        <div className="app-breadcrum-container">
          <Breadcrumb className="app-breadcrum">
            <Breadcrumb.Item>
              <Link to={paths.org.employee}>Employee Management</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="app-breadcrum-active">
              Add multiple employee
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {pageState >= 1 ? (
          <Content className="app-main-content-container paper-wrapper">
            <div className="paper-header-container ">
              <div className="">
                <h1 className="paper-heading">Uploaded data </h1>
                <p className="title-gray-4">
                  Verify and edit the uploaded fields by clicking on them
                </p>
              </div>
              <div>
                {pageState === 1 ? (
                  uploadedEmployeeList.length ? (
                    <NormalButton
                      buttonText="Validate"
                      type={1}
                      onClick={validateEachRow}
                    ></NormalButton>
                  ) : (
                    <NormalButton
                      buttonText="Upload xlsx again"
                      type={1}
                      onClick={() => {
                        setPageState(0);
                      }}
                    ></NormalButton>
                  )
                ) : pageState === 2 ? (
                  <NormalButton
                    buttonText="Upload Employee"
                    type={1}
                    onClick={uploadEachRowData}
                  ></NormalButton>
                ) : pageState === 3 || pageState === 4 ? (
                  <div className="flex align-center">
                    <p className="upload-status-text">Upload Status</p>
                    <Progress
                      type="circle"
                      percent={percentageOfAddedRows}
                      width={60}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div>
              {(pageState === 1 || pageState === 2 || pageState === 3) &&
                uploadedEmployeeList.length ? (
                <div className="employee-add-list-container">
                  <div className="list-row-container">
                    <table className="uploaded-data-validate-table">
                      <tr className="">
                        <th className="list-row-item-padding list-row-error">
                          Status
                        </th>
                        <th className="list-row-item-padding list-row-name">
                          Name
                        </th>
                        <th className="list-row-item-padding list-row-pan">
                          PAN
                        </th>
                        <th className="list-row-item-padding list-row-employee-id">
                          Employee Id
                        </th>
                        <th className="list-row-item-padding list-row-email">
                          Email
                        </th>
                        <th className="list-row-item-padding list-row-phone">
                          Phone
                        </th>
                        <th className="list-row-item-padding list-row-employee-id">
                          Aadhaar Number
                        </th>
                        <th className="list-row-item-padding list-row-doj">
                          Date of joining
                        </th>
                        <th className="list-row-item-padding list-row-doj">
                          Date of Birth
                        </th>
                        <th className="list-row-item-padding list-row-ctc">
                          CTC
                        </th>
                        <th className="list-row-item-padding list-row-ctc">
                          Is Employer Contribution Included in CTC?
                        </th>
                        <th className="list-row-item-padding list-row-ctc">
                          Opting for PF?
                        </th>
                        <th className="list-row-item-padding list-row-vp">
                          Variable Amount
                        </th>
                        <th className="list-row-item-padding list-row-designation">
                          Group
                        </th>
                        <th className="list-row-item-padding list-row-designation">
                          Designation
                        </th>
                        <th className="list-row-item-padding list-row-department">
                          Department
                        </th>
                        <th className="list-row-item-padding list-row-location">
                          Location
                        </th>
                      </tr>

                      {uploadedEmployeeList.map((data, i) => {
                        return (
                          <tr className="">
                            <td className=" list-row-error">
                              {data.error ? (
                                <Status text="Error" type={3}></Status>
                              ) : (
                                <Status text="Valid" type={2}></Status>
                              )}
                            </td>
                            <td className="list-row-name">
                              <InputField
                                fieldType={data.name === "" ? 3 : ""}
                                name="name"
                                value={data.name}
                                onChange={(e) => updateFields(e, i)}
                              />
                            </td>
                            <td className="list-row-pan">
                              <InputField
                                fieldType={data.aadhaarNumber && data.email === "" && data.PAN === "" ? 3 : ""}
                                maxLength={10}
                                name="PAN"
                                value={data.PAN.toUpperCase()}
                                onChange={(e) => updateFields(e, i)}
                              />
                            </td>
                            <td className="list-row-employee-id">
                              <InputField
                                fieldType={data.employeeId === "" ? 3 : ""}
                                name="employeeId"
                                value={data.employeeId}
                                onChange={(e) => updateFields(e, i)}
                              />
                            </td>
                            <td className="list-row-email">
                              <InputField
                                fieldType={data.PAN === "" && data.aadhaarNumber && data.email === "" ? 3 : ""}
                                name="email"
                                value={data.email}
                                onChange={(e) => updateFields(e, i)}
                              />
                            </td>
                            <td className="list-row-phone">
                              <InputField
                                min={0}
                                max={9999999999}
                                type="Number"
                                name="phone"
                                value={data.phone}
                                onChange={(e) => {
                                  e.target.value <= 9999999999 &&
                                    e.target.value >= 0
                                    ? updateFields(e, i)
                                    : dummy();
                                }}
                              />
                            </td>
                            <td className="list-row-phone">
                              <InputField
                                fieldType={data.PAN === "" && data.email === "" && data.aadhaarNumber === "" ? 3 : ""}
                                name="aadhaarNumber"
                                value={data.aadhaarNumber}
                                onChange={(e) => updateFields(e, i)}
                              />
                            </td>
                            <td className="list-row-doj">
                              <DatePicker
                                fieldType={data.dateOfJoining === "" ? 3 : ""}
                                value={
                                  moment(new Date(data.dateOfJoining).toDateString()).isValid()
                                    ? moment(data.dateOfJoining)
                                    : null
                                }
                                format="MM DD YYYY"
                                onChange={(e) => {
                                  let tempUploadedEmployeeList = uploadedEmployeeList;
                                  tempUploadedEmployeeList[i].dateOfJoining =
                                    e === null ? "" : e._d;
                                  setUploadedEmployeeList([
                                    ...tempUploadedEmployeeList,
                                  ]);
                                }}
                              />
                            </td>
                            <td className="list-row-doj">
                              <DatePicker
                                fieldType={data.dateOfBirth === "" ? 3 : ""}
                                value={
                                  moment(new Date(data.dateOfBirth).toDateString()).isValid()
                                    ? moment(data.dateOfBirth)
                                    : null
                                }
                                format="MM DD YYYY"
                                onChange={(e) => {
                                  let tempUploadedEmployeeList = uploadedEmployeeList;
                                  tempUploadedEmployeeList[i].dateOfBirth =
                                    e === null ? "" : e._d;
                                  setUploadedEmployeeList([
                                    ...tempUploadedEmployeeList,
                                  ]);
                                }}
                              />
                            </td>
                            <td className="list-row-ctc">
                              <InputField
                                fieldType={data.ctc === "" ? 3 : ""}
                                type="Number"
                                name="ctc"
                                value={data.ctc}
                                onChange={(e) => {
                                  updateFields(e, i);
                                }}
                              />
                            </td>
                            <td className="list-row-ctc">
                              <Select
                                className="general-input-field"
                                onChange={(e) =>
                                  updateSelectField(
                                    e,
                                    i,
                                    "isEmployerContributionIncludedInCTC"
                                  )
                                }
                                style={{ width: "100%", padding: "2px" }}
                                value={data.isEmployerContributionIncludedInCTC}
                                bordered={false}
                                size={"large"}
                              >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                              </Select>
                            </td>
                            <td className="list-row-ctc">
                              <Select
                                className="general-input-field"
                                onChange={(e) =>
                                  updateSelectField(e, i, "pfEnabled")
                                }
                                style={{ width: "100%", padding: "2px" }}
                                value={data.pfEnabled}
                                bordered={false}
                                size={"large"}
                              >
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                              </Select>
                            </td>
                            <td className="list-row-vp">
                              <InputField
                                fieldType={
                                  data.variablePercentage === "" ? 3 : ""
                                }
                                type="Number"
                                name="variablePercentage"
                                value={data.variablePercentage}
                                onChange={(e) => {
                                  updateFields(e, i);
                                }}
                              />
                            </td>
                            <td className="list-row-designation">
                              <Select
                                className="general-input-field"
                                onChange={(e) =>
                                  updateSelectField(e, i, "groupId")
                                }
                                style={{ width: "100%", padding: "2px" }}
                                bordered={false}
                                value={data.groupId}
                                size={"large"}
                                filterOption={(input, option) =>
                                  option.children &&
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                showSearch
                              >
                                <Option value={""}>General</Option>
                                <Option value="intern">
                                  Intern / Contractor
                                </Option>

                                {props.groups.map((val) => {
                                  return (
                                    <Option value={val.groupId}>
                                      {val.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </td>
                            <td className="list-row-designation">
                              <Select
                                className="general-input-field"
                                onChange={(e) =>
                                  updateSelectField(e, i, "designationId")
                                }
                                style={{ width: "100%", padding: "2px" }}
                                bordered={false}
                                value={data.designationId}
                                size={"large"}
                                filterOption={(input, option) =>
                                  option.children &&
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                showSearch
                              >
                                {props.designation.map((val, index) => {
                                  return (
                                    <Option
                                      key={`designation-select-${index}`}
                                      value={val.designationId}
                                    >
                                      {val.designationName}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </td>
                            <td className="list-row-department">
                              <Select
                                className="general-input-field"
                                onChange={(e) =>
                                  updateSelectField(e, i, "departmentId")
                                }
                                style={{ width: "100%", padding: "2px" }}
                                bordered={false}
                                value={data.departmentId}
                                size={"large"}
                                filterOption={(input, option) =>
                                  option.children &&
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                showSearch
                              >
                                {props.department.map((val, index) => {
                                  return (
                                    <Option
                                      key={`department-select-${index}`}
                                      value={val.departmentId}
                                    >
                                      {val.departmentName}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </td>
                            <td className="list-row-location">
                              <Select
                                className="general-input-field"
                                onChange={(e) =>
                                  updateSelectField(e, i, "locationId")
                                }
                                style={{ width: "100%", padding: "2px" }}
                                bordered={false}
                                value={data.locationId}
                                size={"large"}
                                filterOption={(input, option) =>
                                  option.children &&
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                showSearch
                              >
                                {props.location.map((val, index) => {
                                  return (
                                    <Option
                                      key={`location-select-${index}`}
                                      value={val.locationId}
                                    >
                                      {val.officeName}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              ) : (
                <>
                  {pageState === 1 ? (
                    <p className="empty-array-error">
                      None of the employees from the list can be added
                    </p>
                  ) : (
                    ""
                  )}
                </>
              )}
              {pageState === 4 ? (
                <div className="list-table">
                  <table>
                    <tr className="list-row">
                      <th className="list-row-item ">Employee Id</th>
                      <th className="list-row-item ">Name</th>
                      <th className="list-row-item ">PAN</th>
                      <th className="list-row-item ">Phone</th>
                      <th className="list-row-item ">Status</th>
                    </tr>
                    {uploadedEmployeeList
                      .sort(function (a, _b) {
                        return a.uploaded ? 0 : -1;
                      })
                      .map((data, _i) => {
                        return (
                          <tr className="list-row">
                            <td className="list-row-item ">
                              {data.employeeId}
                            </td>
                            <td className="list-row-item ">{data.name}</td>
                            <td className="list-row-item ">{data.PAN}</td>
                            <td className="list-row-item ">{data.phone}</td>
                            <td className="list-row-item ">
                              {data.uploaded ? (
                                <Status text="Success" type={2}></Status>
                              ) : (
                                <Status text="Fail" type={3}></Status>
                              )}
                            </td>
                            <td className="list-row-item list-row-error-msg">
                              {data.uploaded === false ? (
                                <p className="fail-reason">
                                  {data.uploadFailMessage}
                                </p>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                  <div className="flex justify-end">
                    <NormalButton
                      buttonText="Finish"
                      type={1}
                      onClick={() => {
                        props.history.goBack();
                      }}
                      margin={[8, 0]}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Content>
        ) : (
          <></>
        )}

        {pageState === 0.5 ? (
          <div>
            {uploadedEmployeeListExistsWithOtherOrg.length ? (
              <Content className="app-main-content-container paper-wrapper employee-exists-list-container">
                <div className="paper-header-container ">
                  <div className="">
                    <h1 className="paper-heading">Employees already Exists</h1>
                    <p className="title-gray-4">
                      These employees are linked with other organisation
                    </p>
                  </div>
                  <div>
                    <NormalButton
                      buttonText={`Add the remaining ${uploadedEmployeeList.length} employees`}
                      type={13}
                      onClick={() => {
                        setPageState(1);
                      }}
                    ></NormalButton>
                  </div>
                </div>
                <table className="overflow-table">
                  <tr className="table-row">
                    <th className="column-status">Status</th>
                    <th className="column-name">Name</th>
                    <th className="column-pan">PAN</th>
                    <th className="column-id">Employee ID</th>
                    <th className="column-phone">Phone</th>
                    <th className="column-email">Email</th>
                  </tr>
                  {uploadedEmployeeListExistsWithOtherOrg.map((data, _i) => {
                    return (
                      <tr className="table-row">
                        <td className="column-status">
                          <Status text="Cannot add" type={1}></Status>
                        </td>
                        <td className="column-name">{data.name}</td>
                        <td className="column-pan">{data.PAN}</td>
                        <td className="column-id">{data.employeeId}</td>
                        <td className="column-phone">{data.phone}</td>
                        <td className="column-email">{data.email}</td>
                      </tr>
                    );
                  })}
                </table>
              </Content>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {pageState === 0 ? (
          <Row className="app-main-content-container bulk-wrapper">
            <Col xs={24} sm={24} md={14} lg={14}>
              <Content className="bulk-wrapper-left">
                <div className="paper-header-container">
                  <div>
                    <p className="paper-title">
                      Download Sample in{" "}
                      <a
                        className="download-employee-list"
                        onClick={() =>
                          handleAction(
                            `${config.rootUrl}/api/employee_bulk/${user.organisationId}`,
                            "Add Multiple Employee.xlsx"
                          )
                        }
                      >
                        .xlxs format
                      </a>{" "}
                      file to ensure that you have the correct file ready to
                      import.
                    </p>
                  </div>
                </div>
                <Spin spinning={isLoading} size="large">
                  <div>
                    <div>
                      <Dragger
                        {...uploadProps}
                        showUploadList={false}
                        height={300}
                        className="bulk-wrapper-dragger"
                        disabled={fileUpload}
                        style={fileUpload ? { backgroundColor: "#e4fff8" } : {}}
                      >
                        <p className="ant-upload-drag-icon">
                          <img
                            alt=""
                            src={fileUpload ? UploadComplete : uploadLogo}
                            height="80"
                            width="80"
                          />
                        </p>
                        {fileUpload ? (
                          <p className="ant-upload-text">Excel file Uploaded</p>
                        ) : (
                          <>
                            <p className="ant-upload-text">
                              Drag and drop Excel file
                            </p>
                            <p className="ant-upload-hint">
                              or <u>Click Here</u> to upload
                            </p>
                          </>
                        )}
                      </Dragger>
                    </div>
                    <div className="button-container">
                      <NormalButton
                        type={13}
                        buttonText="Continue"
                        margin={[5]}
                        onClick={() => {
                          if (!fileUpload) {
                            notification.error({
                              message: "Please upload a file to continue",
                            });
                            return;
                          }
                          let tempUploadedEmployeeList = uploadedEmployeeList;
                          setUploadedEmployeeList([
                            ...tempUploadedEmployeeList,
                          ]);
                          if (uploadedEmployeeListExistsWithOtherOrg.length) {
                            setPageState(0.5);
                          } else {
                            setPageState(1);
                          }
                        }}
                      ></NormalButton>
                      <NormalButton
                        type={5}
                        buttonText="Cancel"
                        margin={[5]}
                        onClick={() => {
                          props.history.goBack();
                        }}
                      ></NormalButton>
                    </div>
                  </div>
                </Spin>
              </Content>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10}>
              <Content className="bulk-wrapper-right">
                <div className="tips-container">
                  <p className="tips-header">Tips</p>
                  <p className="tips-title">
                    Verify and edit the uploaded fields by clicking on them
                  </p>
                  <div className="tips-items">
                    <li className="tips-title">
                      Download the .xlsx file and check for the required field
                    </li>
                    <li className="tips-title">
                      Make sure all the field that you have entered aligns with
                      the file that you have downloaded
                    </li>
                    <li className="tips-title">
                      Select the employees which falls under some specific group
                      and add them under that Excel file which you want to
                      upload
                    </li>
                    <li className="tips-title">
                      Upload the file and select all the employee data which are
                      falling in a specific category
                    </li>
                  </div>
                </div>
              </Content>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const AddMultipleEmployeePageContainer = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    groups: state.organisation.groups,
    location: state.organisation.location,
    department: state.organisation.department,
    designation: state.organisation.designation,
    status: state.organisation.status,
    onboarding: state.organisation.onboarding,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(AddMultipleEmployeePage);

export default withRouter(AddMultipleEmployeePageContainer);
