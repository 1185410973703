import { useState, useEffect } from "react";
import { Table, Spin, Modal, notification } from "antd";
import { NormalButton } from "../../../ComponentLibrary";
import plusSymbol from "../../../../assets/icons/whitePlus.svg";
import upload from "../../../../assets/icons/cloud-upload-outline.svg";
import {
  createOrUpdateDesignation,
  deleteDesignation,
  updateEmployerOnBoarding,
} from "../../../../apis/organisation";
import {
  deleteLogo,
  editLogo,
} from "../../../../assets/common/editAndDeleteLogos";
import { connect } from "react-redux";
import organisation from "../../../../redux/modules/organisation";
import BulkUpload from "../BulkUpload";
import { HeaderComponent } from "../../../Common";
import { createLog } from "../../../../apis/user";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"

const Designation = ({
  organisationReducer,
  deviceWidth,
  designationList,
  user,
  handleAction,
  onboarding,
  isTrial
}) => {
  const [showDesignationModal, setShowDesignationModal] = useState(false);
  const [isUpdateFunction, setIsUpdateFunction] = useState("");
  const [designationObject, setDesignationObject] = useState({
    designationName: "",
    color: "#fff",
    description: "",
  });
  const [updateDesignationObject, setUpdateDesignationObject] = useState({});
  const [showDesignationDeleteModal, setShowDesignationDeleteModal] = useState(
    false
  );
  const [deleteDesignationObject, setDeleteDesignationObject] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  const updateOnboarding = async (type) => {
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployerOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id,
      });
      organisationReducer.updateEmployerOnBoarding({
        organisationId: user.organisationId,
        id: user._id,
      });
    }
  };

  const reset = () => {
    setShowDesignationModal(false);
    setIsUpdateFunction("");
    setUpdateDesignationObject({});
    setDesignationObject({
      designationName: "",
      color: "#fff",
      description: "",
    });
  };
  const uploadFunction = async () => {
    await updateOnboarding("designation");
    await organisationReducer.getDesignation(user.organisationId);
  };

  const onDelete = (data) => {
    if (data.totalNoOfEmployees > 0) {
      notification.error({
        message:
          "Employees are assigned this designation, please dissociate this designation before deleting this designation.",
      });
      return;
    }
    setDeleteDesignationObject(data);
    setShowDesignationDeleteModal(true);
  };
  useEffect(() => {
    document.title = 'Settings | Designations';
  });
  const onDeleteConfirm = async () => {
    setIsLoading(true);
    let res = await deleteDesignation(deleteDesignationObject.designationId);
    setIsLoading(false);
    if (res.status === 200 && res.data.success) {
      notification.success({
        message: "Successfully deleted the designation.",
      });
      organisationReducer.getDesignation(user.organisationId);
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has deleted a designation`,
        category: 14,
      });
      setDeleteDesignationObject({});
      setShowDesignationDeleteModal(false);
    } else {
      notification.error({
        message: "Failed to delete the designation, please try again.",
      });
      organisationReducer.loading(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "designationName",
      width: "30%",
    },
    {
      title: "",
      dataIndex: "isDefault",
      width: deviceWidth > 1500 ? "10%" : "20%",
      render: (isDefault, rowData) => (
        <div className="flex justify-end align-center">
          {!isDefault ? (
            <>
              <NormalButton
                type={5}
                icon={editLogo}
                margin={[3, 0]}
                onClick={() => {
                  setIsUpdateFunction(rowData.designationId);
                  setShowDesignationModal(true);
                  setDesignationObject(rowData);
                  setUpdateDesignationObject(rowData);
                }}
              />
              <NormalButton
                type={5}
                margin={[3, 0]}
                icon={deleteLogo}
                onClick={() => {
                  onDelete(rowData);
                }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  const onSave = async () => {
    if (designationObject.designationName === "") {
      notification.error({ message: "Designation name cannot be empty" });
      return;
    }

    if (isUpdateFunction === "") {
      setIsLoading(true);
      let res = await createOrUpdateDesignation({
        ...designationObject,
        organisationId: user.organisationId,
      });
      setIsLoading(false);
      if (res.status === 200 && res.data.success) {
        await createLog({
          name: user.displayName,
          userId: user._id,
          organisationId: user.organisationId,
          message: `User has created a designation`,
          category: 14,
        });
        organisationReducer.getDesignation(user.organisationId);
        await updateOnboarding("designation");
        notification.success({
          message: "New designation has been successfully created",
        });
      } else {
        notification.error({
          message: "Failed to create new designation, please try again.",
        });
        organisationReducer.loading(false);
        return;
      }
    } else {
      if (
        designationObject.designationName !==
        updateDesignationObject.designationName
      ) {
        setIsLoading(true);
        let res = await createOrUpdateDesignation({
          ...designationObject,
          organisationId: user.organisationId,
        });
        setIsLoading(false);
        if (res.status === 200 && res.data.success) {
          notification.success({
            message: "Designation has been successfully updated",
          });
          await createLog({
            name: user.displayName,
            userId: user._id,
            organisationId: user.organisationId,
            message: `User has updated a designation`,
            category: 14,
          });
          organisationReducer.getDesignation(user.organisationId);
        } else {
          notification.error({
            message: "Failed to update designation details, please try again.",
          });
          organisationReducer.loading(false);
          return;
        }
      } else {
        notification.success({
          message: "Designation has been successfully updated",
        });
        organisationReducer.loading(false);
      }
    }
    setShowDesignationModal(false);
    setIsUpdateFunction("");
    setUpdateDesignationObject({});
    setDesignationObject({
      designationName: "",
      color: "#fff",
      description: "",
    });
  };

  return (
    isTrial ? <TrialPage user={user} trialMessage={"Configure Mool as per your company policy"} bgImage={configurationsBgImage}
    /> :
    <div className="site-layout">
      <HeaderComponent
        show={false}
        name="Designations"
      />
      <div className="app-main-content-container paper-wrapper">
        <div>
          <h1 className="h1-bold-24">Designation</h1>
          <p className="tab-subtitle-1">
            Add designations for employees in your organization
          </p>
          <Spin spinning={isLoading} size="large">
            <div className="flex justify-between align-center">
              <p className="h5-bold-20">List ({designationList.length})</p>{" "}
              <div>
                <NormalButton
                  icon={upload}
                  buttonText="Bulk Upload"
                  type={16}
                  onClick={() => {
                    setModalStatus(true);
                  }}
                />
                <NormalButton
                  icon={plusSymbol}
                  buttonText="Create New"
                  type={13}
                  onClick={() => {
                    setShowDesignationModal(true);
                  }}
                />
              </div>
            </div>
            <Table
              style={{ fontWeight: 500, marginTop: 16 }}
              columns={columns}
              dataSource={designationList.reverse()}
              pagination={false}
              scroll={{ y: 520 }}
            />
          </Spin>
        </div>
        <BulkUpload
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          type="des"
          updateFunction={uploadFunction}
          handleAction={handleAction}
        />
        <Modal
          centered
          closable={false}
          visible={showDesignationModal}
          className="modal-container"
          footer={null}
          width={400}
          onCancel={reset}
        >
          <Spin spinning={isLoading} size="default">
            <div className="">
              <div className="flex justify-between">
                <h1 className="font-weight-global-700 font-size-global-24 m-bottom-20 modal-margin-left-global-button">
                  {isUpdateFunction === ""
                    ? "Add Designation"
                    : "Update Designation"}
                </h1>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }} className="m-top-4 ">
                <InputFieldComponent
                  value={designationObject.designationName}
                  labeltext={"Designation Name"}
                  className={`input-field-container`}
                  onPressEnter={onSave}
                  autoFocus={true}
                  onChange={(e) => {
                    setDesignationObject({
                      ...designationObject,
                      designationName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="m-top-8 modal-margin-left-global-1">
                <NormalButton
                  type={13}
                  buttonText={isUpdateFunction === "" ? "Save" : "Update"}
                  onClick={() => {
                    onSave();
                  }}
                />
                <NormalButton
                  type={5}
                  buttonText={"Cancel"}
                  margin={[0, 12]}
                  onClick={reset}
                />
              </div>
            </div>
          </Spin>
        </Modal>
        <Modal
          spinning={isLoading}
          centered
          closable={false}
          visible={showDesignationDeleteModal}
          className="modal-container"
          footer={null}
          onCancel={() => {
            setShowDesignationDeleteModal(false);
            setDeleteDesignationObject({});
          }}
        >
          <Spin spinning={isLoading} size="default">
            <div className="">
              <div className="flex justify-between">
                <h1 className="app-heading-2">Delete Designation</h1>
              </div>
              <div className="">
                <p className="font-size-16 font-weight-500">
                  Are you sure you want to delete the Designation "
                  {deleteDesignationObject.designationName}"
                </p>
              </div>
              <div className="m-top-36  text-align-right">
                <NormalButton
                  type={5}
                  buttonText={"cancel"}
                  margin={[0, 12]}
                  onClick={() => {
                    setDeleteDesignationObject({});
                    setShowDesignationDeleteModal(false);
                  }}
                />
                <NormalButton
                  type={5}
                  buttonText="Delete"
                  onClick={() => {
                    onDeleteConfirm();
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    </div>
  );
};

const DesignationContainer = connect(
  (state) => ({
    deviceWidth: state.responsive.deviceWidth,
    designationList: state.organisation.designation,
    loading: state.organisation.loading,
    user: state.auth.user,
    onboarding: state.organisation.onboarding,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(Designation);

export default DesignationContainer;
