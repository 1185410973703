import { useState,useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { getAdsByTargeting } from "../../../apis/organisation";
import SwiperCore, { Autoplay } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.less";
import { paths } from "../../../constants/routes";

SwiperCore.use([Autoplay]);
const BroadCast = ({ broadcastMessage }) => {
  const [adDetails, setAdDetails] = useState([]);
  useEffect(() => {
    getCampaign();
  }, []);
  const getCampaign = async () => {
    let response = await getAdsByTargeting(2);
    setAdDetails(response.data);

  };
  let photos = [];
  adDetails.forEach((element) => {
    photos.push(element.ad.graphicsUrl);
  });
  return (
    <>
      <div className="paper-wrapper">
        <div className="header-component">
          <h3 className="app-heading-3">Broadcast Message</h3>
          <Link to={paths.employee.broadcast} className="header-link">
            View All
          </Link>
        </div>
        {broadcastMessage
          .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
          .slice(0, 4)
          .map((val, index) => (
            <BroadCastList key={`broadcast-${index}`} {...val} />
          ))}
      </div>
      <div className="ads-container">
        <Swiper autoplay={{ delay: 3000 }}>
          {
            photos.map((item) =>
              (
              <SwiperSlide>
                <div className="photo-holder">
                    <img src={item} alt="Ads not available" />
                </div>
              </SwiperSlide>
              )
            )
          }
        </Swiper>
      </div>
    </>
  );
};

const BroadCastList = ({ updatedAt, title, message }) => (
  <div
    style={{ marginBottom: 20 }}
    className="flex justify-between align-center"
  >
    <div style={{ marginLeft: 10, width: "80%" }}>
      <p style={{ fontWeight: 600, fontSize: 15 }}>{title}</p>
      <p className="app-label-3 text-align-justify">{message}</p>
    </div>
    <p className="app-label-4 text-align-right">{moment(updatedAt).format("ll")}</p>
  </div>
);

export default BroadCast;
