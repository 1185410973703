import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs } from "antd";
import organisation from "../../../../redux/modules/organisation";
import CommonFreeze from "./CommonFreeze";
import { HeaderComponent } from "../../../Common";
import { useEffect } from "react";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"

const { TabPane } = Tabs;

const Freeze = ({
    user,
    employeeList,
    organisationReducer,
    organisationFreeze,
    isTrial
}) => {
    useEffect(() => {
        document.title = 'Settings | Declaration Freeze';
    });
    return (
        isTrial ? <TrialPage user={user} trialMessage={"Configure Mool as per your company policy"} bgImage={configurationsBgImage}
        /> :
            <div className="rebalance">
                <HeaderComponent
                    show={false}
                    name="Common Freeze" />
                <div className="card-container">
                    <Tabs type="card">
                        <TabPane tab="Declaration" key="1">
                            <CommonFreeze
                                user={user}
                                employeeList={employeeList}
                                organisationReducer={organisationReducer}
                                organisationFreeze={organisationFreeze}
                                type={1}
                            />
                        </TabPane>
                        <TabPane tab="Allowances" key="2">
                            <CommonFreeze
                                user={user}
                                employeeList={employeeList}
                                organisationReducer={organisationReducer}
                                organisationFreeze={organisationFreeze}
                                type={0}
                            />
                        </TabPane>
                        <TabPane tab="Perquisites" key="3">
                            <CommonFreeze
                                user={user}
                                employeeList={employeeList}
                                organisationReducer={organisationReducer}
                                organisationFreeze={organisationFreeze}
                                type={2}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
    );
};

const FreezeContainer = connect(
    state => ({
        user: state.auth.user,
        taxSettlementData: state.organisation.taxSettlementData,
        organisationFreeze: state.organisation.organisationFreeze,
        employeeList: state.organisation.employeeList,
        isTrial: state.auth.user.isTrial,
    }),
    dispatch => ({
        organisationReducer: organisation.getActions(dispatch)
    })
)(Freeze);

export default withRouter(FreezeContainer);