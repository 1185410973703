import { Modal } from "antd";
import CurrentMonthClosingFields from "./currentMonthClosingFields.js";
import axios from "../../../apis"

import UploadMonthClose from "./uploadMonthClose";

const CommonFunctionality = ({
    monthCloseFields,
    user,
    employeeList,
    organisation,
    columns,
    loading,
    children,
    downloadURL,
    actionUrl,
    startRefresh,
    singleModalShow,
    setSingleModalShow,
    bulkModalShow,
    setBulkModalShow,
    name,
    reset
}) => {

    const handleAction = async (url) => {
      let res = await axios({
        url, //your url
        method: "GET",
        responseType: "blob",
      });
      const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = urlDownload;
      link.setAttribute("download", `${name}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    };

    return (
        <>

            <Modal
                visible={singleModalShow}
                centered
                width={400}
                footer={null}
                onCancel={() => {
                    reset()
                    setSingleModalShow(false)
                }}
            >
                {children}
            </Modal>
            <Modal
                visible={bulkModalShow}
                centered
                width={500}
                footer={null}
                onCancel={() => setBulkModalShow(false)}
            >
                <UploadMonthClose
                    organisationId={user.organisationId}
                    employeeList={employeeList}
                    user={user}
                    organisation={organisation}
                    downloadURL={downloadURL}
                    actionUrl={actionUrl}
                    setBulkModalShow={setBulkModalShow}
                    handleAction={handleAction}
                />
            </Modal>
            <CurrentMonthClosingFields
                monthCloseFields={monthCloseFields}
                columns={columns}
                loading={loading}
                startRefresh={startRefresh}
                setSingleUploadModal={setSingleModalShow}
                setBulkUploadModal={setBulkModalShow}
                name={name}
            />
        </>
    );
};

export default CommonFunctionality;
