const { Allowance } = require("./keys");
const { partiallyTaxable, govtAllowancesLimit } = require("./taxrules");

const calculateHRAExemption = (payslip, rent_config) => {
  let basicPlusDA = payslip.basic + payslip.DA;
  let HRA = payslip.HRA;
  let isMetro = rent_config.isMetro;
  let rentPaid = rent_config.rent;
  /**
     * HRA Rules
     * MINIMUM OF:
     * Condition 1. Actual HRA offered by Employer
     * Condition 2. Actual rent paid minus 10% of basic salary
     * Condition 3. 50% of basic salary for Metro, 40% of basic for Non Metro
     */
  let basicFourtyOrFifty = basicPlusDA * (isMetro ? 0.5 : 0.4);
  let hraTenBasic = rentPaid - basicPlusDA * 0.1;
  let hraExemption = Math.min(hraTenBasic, basicFourtyOrFifty, HRA);
  if (hraExemption < 0) {
    hraExemption = 0;
  }

  let hraData = {
    isMetro,
    rent: rentPaid,
    hra: HRA,
    basicDa: basicPlusDA,
    basicFourtyOrFifty,
    rent_minus_basic: hraTenBasic,
    hraExemption: hraExemption
  };
  return { hraExemption, hraData };
};

/**
 * This function checks if an allowance comes under taxable bracket or not;
 * If an allowance comes under taxable bracket then it will return true or else it will return false
 */
const checkIsNonTaxable = allowanceName => {
  let result = false;
  if (partiallyTaxable[allowanceName]) {
    result = true;
  }
  return result;
};

/**
 * 
 * This function will provide if an allowance has any limit or not.
 * If it has any limit then it will return true or else it will return false
 */
const isUnderLimitBracket = allowanceName => {
  return !!(govtAllowancesLimit[allowanceName]);
};

const calculateAllowanceExemption = (allowanceName, allowanceAmount, user) => {
  let totalExemption = 0;
  if (!checkIsNonTaxable(allowanceName)) {
    return totalExemption;
  }

  if (isUnderLimitBracket(allowanceName)) {
    let limit = govtAllowancesLimit[allowanceName];
    if (
      allowanceName === Allowance.EXT_CHILD_EDU ||
      allowanceName === Allowance.EXT_HOSTEL_EDU
    ) {
      if (user.familyDetails.noOfChild) {
        limit = limit * user.familyDetails.noOfChild;
      } else {
        limit = 0;
      }
    }

    if (allowanceAmount >= limit) {
      totalExemption = parseInt(limit) ? limit : 0;
    } else {
      totalExemption = parseInt(allowanceAmount) ? allowanceAmount : 0;
    }
  } else {
    totalExemption = parseInt(allowanceAmount) ? allowanceAmount : 0;
  }
  return totalExemption;
};

module.exports = {
  calculateHRAExemption,
  calculateAllowanceExemption
};
