import { useEffect, useState } from "react";
import {
  Table,
  Modal,
  Row,
  Col,
  Select,
  Checkbox,
  message,
  Progress,
  notification,
} from "antd";
import plusSymbol from "../../../../assets/common/plusSymbol.svg"
import { NormalButton } from "../../../ComponentLibrary";
import { connect } from "react-redux";
import {
  creditEmployeeLeave,
  leaveBalanceReport,
} from "../../../../apis/organisation";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";
import InputfieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import TrialPage from "../../../Common/TrialComponent";
import attendanceBgImage from "../../../../assets/dummyPages/attendanceBgImage.png"

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

const Report = ({ employeeList, user, leaveList, isTrial }) => {
  const [creditLeaveModal, setCreditLeaveModal] = useState({
    modal: false,
    loading: false,
  });
  const [creditLeave, setCreditLeave] = useState({
    leaveId: "",
    amount: null,
  });
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [employeeNames, setEmployeeNames] = useState([]);
  const [employeeUserIds, setEmployeeUserIds] = useState([]);
  const [leaveCreditCount, setLeaveCreditCount] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [employeeLeaves, setEmployeeLeaves] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);


  const onChange = (list) => {
    setCheckedList(list);
    let ids = [];
    list.forEach((val_i) => {
      for (let index = 0; index < employeeNames.length; index++) {
        if (employeeNames[index] === val_i) {
          ids.push(employeeUserIds[index]);
        }
      }
    });
    setSelectedEmployee([...ids]);
    setIndeterminate(!!list.length && list.length < employeeNames.length);
    setCheckAll(list.length === employeeNames.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? employeeNames : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const getEmployeeLeaves = async () => {
    let leaves = await leaveBalanceReport(user.organisationId);
    let EmployeeLeaves = [];
    for (let val of leaves.data) {
      let employeeLeave = val;
      let total = 0;
      for (let v in val.leaveTypes) {
        total += parseFloat(val.leaveTypes[v].balance);
      }
      employeeLeave.balance = Math.round(total);
      EmployeeLeaves.push(employeeLeave);
    }
    setEmployeeLeaves([...EmployeeLeaves]);
  };

  useEffect(() => {
    getEmployeeLeaves();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isRefresh]);

  useEffect(() => {
    let EmployeeList = employeeList.map(
      (val) =>
        `${val.displayName} (${val.currentOrganisationMeta.employeeId}-${val.username})`
    );
    let ids = employeeList.map((val) => val._id);
    setEmployeeNames([...EmployeeList]);
    setEmployeeUserIds([...ids]);
  }, [employeeList, isRefresh]);

  useEffect(() => {
    let initialData = [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "name",
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "name",
      },
    ];
    let tableData = leaveList.map((val) => {
      return {
        title: val.name,
        dataIndex: ["leaveTypes", val.leaveId, "balance"],
        key: "balance",
        render: (data) => <p>{Math.round(data * 100) / 100}</p>,
      };
    });
    let finalData = [
      {
        title: "Balance",
        dataIndex: "balance",
        key: "name",
      },
    ];
    setTableRows([...initialData, ...tableData, ...finalData]);
  }, [leaveList, isRefresh]);

  const creditLeaves = async () => {
    if (checkedList.length <= 0) {
      message.warning("Please Select the respective employees");
      return;
    }
    if (!creditLeave.leaveId) {
      message.warning("Please Select a Leave");
      return;
    }
    if (creditLeave.amount === 0) {
      message.warning("Please Enter the Number of Leaves");
      return;
    }
    setCreditLeaveModal({ ...creditLeaveModal, loading: true });
    let creditedLeaves = 0;
    if (checkAll) {
      for (const element of employeeUserIds) {
        let postObject = {
          organisationId: user.organisationId,
          leaveId: creditLeave.leaveId,
          id: element,
          numberOfLeaves: creditLeave.amount,
        };
        await creditEmployeeLeave(postObject);
        creditedLeaves++;
        setLeaveCreditCount(creditedLeaves);
      }
    } else {
      for (const element of selectedEmployee) {
        let postObject = {
          organisationId: user.organisationId,
          leaveId: creditLeave.leaveId,
          id: element,
          numberOfLeaves: creditLeave.amount,
        };
        await creditEmployeeLeave(postObject);
        creditedLeaves++;
        setLeaveCreditCount(creditedLeaves);
      }
    }
    notification.success({ message: "Successfully credited the leaves" });
    await getEmployeeLeaves();
    resetCreditLeaveModal();
  };

  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }

  const resetCreditLeaveModal = () => {
    setCreditLeave({
      leaveId: "",
      amount: 0,
    });
    setCreditLeaveModal({
      modal: false,
      loading: false,
    });
    setLeaveCreditCount(0);
    setCheckedList([]);
    setIndeterminate(true);
    setCheckAll(false);
  };
  return (isTrial ? <TrialPage trialMessage={" Get access to India's first tax optimizing and salary personalization tool powering your payroll"} bgImage={attendanceBgImage}
  /> :
    <div>
      <div className="report-container">
        <div className="report-header-container m-bottom-20">
          <p className="font-size-global-24 font-weight-global-700">Reports</p>
          <div>
            <span style={{ float: "left", marginRight: "0.5em" }}>
              <NormalButton
                buttonText={"Refresh"}
                type={13}
                onClick={startRefresh}
                className={`button-lib button-type-13 p-10`}
                style={{ height: "2.5rem" }}
              />

            </span>
            <NormalButton
              type={13}
              icon={plusSymbol}
              buttonText={"Credit Leave"}
              className="button-container"
              onClick={() =>
                setCreditLeaveModal({ ...creditLeaveModal, modal: true })
              }
            />
          </div>
        </div>
        <div>
        </div>
        <Table
          pagination={employeeLeaves.length <= 8 ? false : { pageSize: 8 }}
          columns={tableRows}
          dataSource={employeeLeaves.reverse()}
        />
      </div>
      <Modal
        className="leave-modal"
        visible={creditLeaveModal.modal}
        onCancel={resetCreditLeaveModal}
        centered
        footer={null}
        closable={true}
        width={1000}
      >
        {creditLeaveModal.loading && (
          <Progress
            percent={Math.round(
              (leaveCreditCount /
                (checkAll ? employeeList.length : selectedEmployee.length)) *
              100
            )}
            status="active"
          />
        )}
        <Spinner spinning={creditLeaveModal.loading}>
          <div className="leave-modal-container">
            <p className="font-size-global-24 font-weight-global-700 modal-margin-left-global-button">Credit leave</p>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={10} lg={10}>
                <div className="modal-left-container">
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    className="modal-data-container"
                  >
                    <DropdownComponent
                      className="general-ant-field-select leave-select"
                      bordered={false}
                      value={creditLeave.leaveId}
                      labeltext={"Type"}
                      values={leaveList.map(item => item.leaveId)}
                      options={leaveList.map((item) => item.name)}
                      onChange={(e) => {
                        setCreditLeave({
                          ...creditLeave,
                          leaveId: e,
                        });
                      }}
                    >
                      {leaveList.map((val, index) => (
                        <Option key={`leave-list-${index}`} value={val.leaveId}>
                          {val.name}
                        </Option>
                      ))}
                    </DropdownComponent>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "center" }}
                    className="modal-data-container"
                  >
                    <InputfieldComponent
                      className="general-ant-field-input leave-input"
                      bordered={false}
                      labeltext={"Count"}
                      type={"number"}
                      value={creditLeave.amount}
                      onChange={(e) => {
                        setCreditLeave({
                          ...creditLeave,
                          amount: isNaN(parseInt(e.target.value))
                            ? 0
                            : parseInt(e.target.value),
                        });
                      }}
                    ></InputfieldComponent>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={14} className="columnContainer">
                <div className="modal-right-container">
                  <div className="employees-holder">
                    <CheckboxGroup
                      options={employeeNames}
                      value={checkedList}
                      onChange={onChange}
                    />
                  </div>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    className="checkbox"
                  >
                    Select all
                  </Checkbox>
                </div>
                <div className="flex justify-end align-center w-full">
                  <NormalButton
                    type={13}
                    buttonText="Credit Leave"
                    onClick={creditLeaves}
                    margin={[10, 0]}
                    block={true}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Spinner>
      </Modal>
    </div>
  );
};

const ReportContainer = connect((state) => ({
  user: state.auth.user,
  employeeList: state.organisation.employeeList,
  leaveData: state.organisation.leaveData,
  isTrial: state.auth.user.isTrial,
  leaveList: state.organisation.leaveList,

}))(Report);

export default ReportContainer;
