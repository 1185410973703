import { Col, Row } from "antd";
import HraInfo from "./hra/hraInfo.js";
import HraSupportingDocuments from "./hra/hraSupportingDocuments.js";
import HraFilesUpload from "./hra/hraFIlesUpload.js"
import { proofsByCategoryAndFY } from "../../../../apis/employee.js";
import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { checkFinancialYear } from "../../../../utils/dateCheckUtil.js";

const Hra = ({ user, declaration, organisationFreeze }) => {
    const [hraDocuments, setHraDocuments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [financialYear, setFinancialYear] = useState(
        checkFinancialYear().financialYear
    );
    useEffect(() => {
        setLoading(true)
        getProofsByCategory().then((_data) => setLoading(false))
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])
    const getProofsByCategory = async () => {
        setLoading(true)
        let data = await proofsByCategoryAndFY({
            id: user._id,
            category: 'HRA',
            financialYear

        })
        setHraDocuments([...data.data])
        setLoading(false)
    }
    return (
        <div className="hra-container">
            <Row gutter={{
                xs: 8,
                lg: 32,
                sm: 16,
                md: 24,
            }}>
                <Col span={12}>
                    <HraInfo />
                </Col>
                <Col span={12} className="m-top-30">
                    <HraFilesUpload setFinancialYear={setFinancialYear} financialYear={financialYear} organisationFreeze={organisationFreeze} hraDocuments={hraDocuments} updateDeclarationDocument={getProofsByCategory} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <HraSupportingDocuments user={user} updateDeclarationDocument={getProofsByCategory} loadingMain={loading} hraDocuments={hraDocuments} declaration={declaration} />
                </Col>
            </Row>
        </div>
    )
}


const HRAContainer = connect(
    state => ({
        user: state.auth.user,
        declaration: state.employee.declaration,
        organisationFreeze: state.employee.organisationFreeze
    }),
)(Hra)
export default HRAContainer;