import React, { useEffect, useState } from 'react'
import config, { checkFinancialYear } from '../../utils/config'
import { Divider, Radio, Skeleton, notification } from 'antd'
import InfoBtn from '../ComponentLibrary/InfoButtonComponent/InfoBtn';
import { NormalButton } from '../ComponentLibrary';
import { getOneTimeEmployeeRegimeSelectionStatus, getRegimeComparison, updateRegimeOptedStatus } from '../../apis/employee';
const { financialYear, month } = checkFinancialYear();
const EmployeeRegimeSelection = ({ user, organisationSettings }) => {
	const [finalPayslip, setFinalPayslip] = useState({});
	const [showTable, setShowTable] = useState(true);
	const [currentRegime, setCurrentRegime] = useState(1);
	const [regimeSelectionStatus, setRegimeSelectionStatus] = useState(false);
	const [isEditable, setIsEditable] = useState(false);
	useEffect(() => {
		getOneTimeEmployeeRegimeSelectionStatus(user._id).then(res => {
			if (res.data.success) {
				setRegimeSelectionStatus(res.data.data.regimeSelectionStatus)
			}
		})
		getRegimeComparison({ id: user._id, financialYear, month }).then(res => {
			if (res.data.success) {
				setFinalPayslip(res.data.data)
			} else {
				setFinalPayslip({})
			}
		}).catch(err => {
			setFinalPayslip({})
		})
	}, [user._id]);
	useEffect(() => {
		const getRegime = (status) => {
			if (status && status === 1) {
				return status;
			} else {
				return 0;
			}
		}
		setCurrentRegime(getRegime(user.regimeOptedStatus));
		setRegimeSelectionStatus(user.regimeSelectionStatus);
	}, [user]);

	const handleSaveRegime = () => {
		updateRegimeOptedStatus({ id: user._id, regimeOptedStatus: currentRegime, regimeSelectionStatus: true }).then(res => {
			if (res.data.success) {
				notification.success({
					message: "Regime Status Saved Successfully!"
				})
			} else {
				notification.error({
					message: "Error while saving regime status!"
				})
			}
		}).catch(err => {
			notification.error({
				message: "Error while saving regime status!"
			})
		})
	}
	return (
		<div className="tax-projection-container" style={{ padding: "3rem" }}>
			<div>
				<h1 className="font-weight-700 " style={{ fontSize: "1.6rem" }}>Select Your Regime</h1>
				<p>Select your regime on the basis of the calculation difference.</p>
			</div>
			<div>
				<div className='flex justify-between align-center m-top-16'>
					<h1 style={{ fontSize: "1.2rem" }}>Comparison table between Old and New Regime</h1>
					<NormalButton buttonText={showTable ? "Collapse Table" : "Show Table"} onClick={() => { setShowTable(!showTable) }} />
				</div>
				{showTable && <div>
					<Skeleton loading={!finalPayslip} paragraph={{ rows: 20 }}>
						{finalPayslip != null && (
							<div
								style={{
									padding: "21px 15.5px 11px 21px",
								}}
								id="ctc-breaking"
							>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-around",
										alignItems: "center",
									}}
								>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
											height: 50,
											padding: "10px 10px",
											// border: "solid #dcdfe8 1px",
										}}
									>
										{" "}
										<p style={{ width: 120 }}></p>
										<div className="flex align-center justify-start">
											<div className='flex justify-between align-center'>
												<p className="font-weight-global-24 font-weight-700" style={{ marginTop: "30px" }}>
													Old Tax Regime
												</p>
												<InfoBtn infoText={"Old Tax Regime"} />
											</div>
										</div>
										<div className="flex align-center justify-start">
											<p className="font-weight-global-24 font-weight-700" style={{ marginTop: "30px" }}>
												New Tax Regime
											</p>
											<InfoBtn infoText={<p>New Tax Regime<br /> (2023-2024)</p>} />
										</div>
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
											height: 50,
											padding: "10px 10px",
											margin: "10px 0"
											// border: "solid #dcdfe8 1px",
										}}
									>
										<p
											style={{
												fontSize: 18,
												fontWeight: 700,

												width: 135,
											}}
										>
											Earnings

										</p>
										<p
											style={{
												fontSize: 18,
												fontWeight: 700,
											}}
										> Amount ( In INR )
										</p>
										<p
											style={{
												fontSize: 18,
												fontWeight: 700,
											}}
										> Amount ( In INR )
										</p>
									</div>

									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
											padding: "10px 10px",
											border: "solid #dcdfe8 1px",
										}}
									>
										<p
											style={{
												fontSize: 15,
												fontWeight: 500,
												width: 120,
											}}
										>
											Basic
										</p>
										<p
											style={{
												fontSize: 15,
												fontWeight: 500,
											}}
										>
											{config.numformatter(finalPayslip?.basic ?? 0)}
										</p>
										<p
											style={{
												fontSize: 15,
												fontWeight: 500,
											}}
										>
											{config.numformatter(finalPayslip?.basic ?? 0)}
										</p>
									</div>

									{finalPayslip?.specialAllowance !== 0 && (
										<>
											{" "}
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													Special Allowances
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.specialAllowance ?? 0)}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.specialAllowance ?? 0)}
												</p>
											</div>

										</>
									)}
									{finalPayslip?.HRA !== 0 && (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													HRA
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.HRA ?? 0)}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.HRA ?? 0)}
												</p>
											</div>

										</>
									)}
									{finalPayslip?.DA !== 0 && (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													Dearness
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.DA ?? 0)}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.DA ?? 0)}
												</p>
											</div>

										</>
									)}
									{finalPayslip?.LTA !== 0 && (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													Leave Travel Allowances
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.LTA ?? 0)}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.LTA ?? 0)}
												</p>
											</div>

										</>
									)}
									{finalPayslip?.statutoryBonuse !== 0 ? (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													Statutory Bonus
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.statutoryBonuse ?? 0)}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.statutoryBonuse ?? 0)}
												</p>
											</div>

										</>
									) : <></>}
									{finalPayslip.FOOD ? (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													Food Allowances
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.FOOD ?? 0)}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.FOOD ?? 0)}
												</p>
											</div>

										</>
									) : <></>}
									{Object.keys(finalPayslip?.otherAllowances ?? {}).map((val) => (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
													lineHeight: "14px"
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													{organisationSettings.allowances[val].label}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.otherAllowances[val])}{" "}

												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.otherAllowances[val])}{" "}

												</p>
											</div>

										</>
									))}
									{Object.keys(finalPayslip?.customAllowances ?? {}).map((val) => (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													{val}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.customAllowances[val])}{" "}

												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.customAllowances[val])}{" "}

												</p>
											</div>

										</>
									))}
									{Object.keys(finalPayslip?.bonuses ?? {}).map((val) => (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													{val}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.bonuses[val])}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.bonuses[val])}
												</p>
											</div>

										</>
									))}
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
											height: 50,
											padding: "10px 10px",
											border: "solid #dcdfe8 1px",
										}}
									>
										<p
											style={{
												fontSize: 15,
												fontWeight: 700,
												width: 120,
											}}
										>
											Total Earnings
										</p>
										<p
											style={{
												fontSize: 15,
												fontWeight: 700,
											}}
										>
											{config.numformatter(finalPayslip?.totalFixedIncome ?? 0)}
										</p>
										<p
											style={{
												fontSize: 15,
												fontWeight: 700,
											}}
										>
											{config.numformatter(finalPayslip?.totalFixedIncome ?? 0)}
										</p>
									</div>



									{finalPayslip?.employee_ESI !== 0 && (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													Employee ESI
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.employee_ESI ?? 0)}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.employee_ESI ?? 0)}
												</p>
											</div>

										</>
									)}

									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
											height: 50,
											padding: "10px 10px",
											margin: "10px 0"
											// border: "solid #dcdfe8 1px",
										}}
									>
										<p
											style={{
												fontSize: 18,
												fontWeight: 700,

												width: 135,
											}}
										>
											Deductions

										</p>
										<p
											style={{
												fontSize: 18,
												fontWeight: 700,
											}}
										> Amount ( In INR )
										</p>
										<p
											style={{
												fontSize: 18,
												fontWeight: 700,
											}}
										> Amount ( In INR )
										</p>
									</div>
									{finalPayslip?.employee_PF !== 0 && (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													PF
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.employer_PF ?? 0)}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.employer_PF ?? 0)}
												</p>
											</div>

										</>
									)}
									{finalPayslip?.employee_PF - finalPayslip?.employer_PF > 0 && (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													VPF
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(
														finalPayslip?.employee_PF ?? 0 - finalPayslip?.employer_PF ?? 0
													)}{" "}

												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(
														finalPayslip?.employee_PF ?? 0 - finalPayslip?.employer_PF ?? 0
													)}{" "}

												</p>
											</div>

										</>)}
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
											height: 50,
											padding: "10px 10px",
											border: "solid #dcdfe8 1px",
										}}
									>
										<p
											style={{
												fontSize: 15,
												fontWeight: 500,
												width: 120,
											}}
										>
											Income Tax
										</p>
										<p
											style={{
												fontSize: 15,
												fontWeight: 500,
											}}
										>
											{config.numformatter(finalPayslip?.oldRegime?.incomeTax ?? 0)}
										</p>
										<p
											style={{
												fontSize: 15,
												fontWeight: 500,
											}}
										>
											{config.numformatter(finalPayslip?.newRegime?.incomeTax ?? 0)}
										</p>
									</div>

									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
											height: 50,
											padding: "10px 10px",
											border: "solid #dcdfe8 1px",
										}}
									>
										<p
											style={{
												fontSize: 15,
												fontWeight: 500,
												width: 120,
											}}
										>
											Professional Tax
										</p>
										<p
											style={{
												fontSize: 15,
												fontWeight: 500,
											}}
										>
											{config.numformatter(finalPayslip?.proffTax ?? 0)}
										</p>
										<p
											style={{
												fontSize: 15,
												fontWeight: 500,
											}}
										>
											{config.numformatter(finalPayslip?.proffTax ?? 0)}
										</p>
									</div>
									{finalPayslip?.FOOD !== 0 && (
										<>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													width: "100%",
													height: 50,
													padding: "10px 10px",
													border: "solid #dcdfe8 1px",
												}}
											>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
														width: 120,
													}}
												>
													Food Allowances
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.FOOD ?? 0)}
												</p>
												<p
													style={{
														fontSize: 15,
														fontWeight: 500,
													}}
												>
													{config.numformatter(finalPayslip?.FOOD ?? 0)}
												</p>
											</div>

										</>
									)}


									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
											height: 50,
											padding: "10px 10px",
											border: "solid #dcdfe8 1px",
										}}
									>
										<p
											style={{
												fontSize: 15,
												fontWeight: 700,
												width: 120,
											}}
										>
											Total Deductions
										</p>
										<p
											style={{
												fontSize: 15,
												fontWeight: 700,
											}}
										>
											{config.numformatter(finalPayslip?.oldRegime?.deductions ?? 0)}
										</p>
										<p
											style={{
												fontSize: 15,
												fontWeight: 700,
											}}
										>
											{config.numformatter(finalPayslip?.newRegime?.deductions ?? 0)}
										</p>
									</div>


									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
											height: 50,
											padding: "10px 10px",
											margin: "10px 0"
											// border: "solid #dcdfe8 1px",
										}}
									>
										<p
											style={{
												fontSize: 15,
												fontWeight: 700,
												width: 120,
											}}
										>
											Takehome
										</p>
										<p
											style={{
												fontSize: 18,
												fontWeight: 700,
											}}
										>
											{config.numformatter(finalPayslip?.oldRegime?.takeHome ?? 0)}
										</p>
										<p
											style={{
												fontSize: 18,
												fontWeight: 700,
											}}
										>
											{config.numformatter(finalPayslip?.newRegime?.takeHome ?? 0)}
										</p>
									</div>

								</div>
							</div>
						)}
					</Skeleton>
				</div>}
				<Divider type='horizontal' style={{ borderWidth: "1px", borderColor: "gray", borderStyle: "dashed" }} />
				<div className='flex justify-between align-center'>
					<div>
						<h1 className="font-weight-700 m-bottom-10" style={{ fontSize: "1.6rem" }}>Opted Regime</h1>
						<Radio.Group
							style={{ display: "flex", justifyContent: "start", marginTop: "-2em" }}
							options={[
								{ label: "New Regime", value: 1 },
								{ label: "Old Regime", value: 0 },
							]}
							onChange={(e) => {
								setCurrentRegime(
									e.target.value,
								)
								setIsEditable(true);
							}
							}
							value={currentRegime}
						/>
					</div>
					<div>
						{isEditable && (
							<div>
								<NormalButton type={13} buttonText={"Save selected regime"} disabled={regimeSelectionStatus} onClick={handleSaveRegime} />
								{regimeSelectionStatus && <p>Already selected regime for current financial year!</p>}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default EmployeeRegimeSelection