import { InputNumber, notification, Select, Upload, Spin } from "antd";
import { useState } from "react";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import config from "../../../utils/config";
import { removeS3Object, addProofs } from "../../../apis/employee";
import { NormalButton } from "../../ComponentLibrary";
const { Option } = Select;
const { Dragger } = Upload;

const PerqsFileUpload = ({
  updateDeclarationDocument,
  user,
  organisationSettings,
  perquisites
}) => {
  const [nonTaxablePerqs, setNonTaxableperqs] = useState({});

  useState(
    () => {
      let { perquisites: perqs } = organisationSettings;
      if(!perqs) perqs = {}
      let resultantPerqs = {};
      Object.keys(perqs).forEach(prq => {
        if (perqs[prq].applicable) {
          let amount = !isNaN(parseFloat(perqs[prq].amount))
            ? perqs[prq].amount
            : 0;
          let actualExpense =
            perquisites[prq] &&
            !isNaN(parseFloat(perquisites[prq].actualExpense))
              ? parseFloat(perquisites[prq].actualExpense)
              : amount;
          resultantPerqs[prq] = {
            ...perqs[prq],
            amount,
            limit: amount,
            actualExpense
          };
        }
      });
      setNonTaxableperqs({ ...resultantPerqs });
    },
    [perquisites, organisationSettings]
  );
  const [documentUploadList, setDocumentUploadList] = useState({
    subCatagory: "",
    amount: 0,
    documents: []
  });
  const [loading, setLoading] = useState(false);
  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/document/proofs`,
    headers: {
      authorization: localStorage.getItem("mool_auth")
    },
    multiple: false,
    async onChange(info) {
      setLoading(true);
      let { response } = info.file;
      if (response) {
        setLoading(false);
        let tempDocumentList = documentUploadList;
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        documentDetails.name = info.file.name.substring(
          0,
          info.file.name.lastIndexOf(".")
        );
        delete documentDetails.Bucket;
        delete documentDetails.ETag;
        tempDocumentList.documents.length !== 3 &&
          tempDocumentList.documents.push(documentDetails);
        setDocumentUploadList({ ...tempDocumentList });
      }
    }
  };

  const removeDocument = async (doc, index) => {
    setLoading(true);
    let tempDocumentList = documentUploadList;
    let tempDocument = tempDocumentList.documents.filter(
      (_val, i) => i !== index
    );
    await removeS3Object({ Key: doc.key });
    tempDocumentList.documents = [...tempDocument];
    setDocumentUploadList({ ...tempDocumentList });
    setLoading(false);
  };

  const uploadProofs = async () => {
    if (
      !documentUploadList.subCatagory ||
      !documentUploadList.amount ||
      !documentUploadList.documents.length
    ) {
      notification.error({ message: "Please enter every details" });
      return;
    }
    setLoading(true);
    let tempUploadData = { proofObj: documentUploadList };
    tempUploadData.proofObj.id = user._id;
    tempUploadData.proofObj.organisationId = user.currentOrganisationId;
    tempUploadData.proofObj.category = "Perquisites";
    tempUploadData.proofObj.proofType = 1;
    await addProofs(tempUploadData);
    await updateDeclarationDocument();
    setDocumentUploadList({
      subCatagory: "",
      amount: 0,
      documents: []
    });
    setLoading(false);
  };

  let styles = {
    border:  "2px #5E5F62 solid",
    width:  "288px",
    maxWidth:  "100%",
    height: "50px",
    borderRadius: "8px",
  };

  return (
    <div className="hostel-info-container">
      <div className="hostel-info-header-container">
        <h1 className="hostel-info-heading">Non Taxable Perquisites</h1>
      </div>
      {/* <label className="general-ant-field-label">Type of perqs</label> */}
      <Spin spinning={loading} size="large">
        <div className="dropdowndiv">
          <Select
            size="large"
            onChange={val => {
              let tempDocumentList = documentUploadList;
              tempDocumentList.subCatagory = val;
              setDocumentUploadList({ ...tempDocumentList });
            }}
            bordered={false}
            style={styles}
            value={documentUploadList.subCatagory}
            className="general-ant-field-select"
            filterOption={(input, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())}
            showSearch
          >
            {Object.keys(nonTaxablePerqs).map((val, _index) => {
              return (
                <Option key={_index} className="option" value={val}>
                  {nonTaxablePerqs[val].label}
                </Option>
              );
            })}
          </Select>
          <label
            className="labelup" style={{marginTop:"0px"}} 
          >
            {"Type of perqs"}
          </label>
        </div>
        

        {/* <p className="note">Note: you can claim upto 5000 INR under this allowance</p> */}

        <div>
          
          <br />
          <InputNumber
            style={styles}
            bordered={false}
            value={documentUploadList.amount}
            onChange={val => {
              let tempDocumentList = documentUploadList;
              tempDocumentList.amount = val;

              setDocumentUploadList({ ...tempDocumentList });
            }}
          />
          <label
            className="labelup" style={{marginTop:"80px"}} 
          >
            {"Amount in INR"}
          </label>
        </div>
        <div className="document-list">
          {documentUploadList.documents.map((val, index) => {
            return (
              <div
                className="document-container"
                key={`doc-container-${index}`}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <DeleteOutlined
                    key={`doc-container-delete-${index}`}
                    onClick={() => removeDocument(val, index)}
                  />
                  <div
                    key={`doc-container-filetype-${index}`}
                    className="file-typeHeader"
                  >
                    {val.Key.split(".").pop()}
                  </div>
                  <span key={`doc-container-name-${index}`}>
                    {val.Key}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        {documentUploadList.documents.length < 3 &&
          <Dragger
            {...uploadProps}
            multiple={true}
            beforeUpload={config.beforeFileUpload}
            showUploadList={false}
            className="dragger-container"
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">upload max of 3 files</p>
          </Dragger>}
        <div className="">
          <NormalButton
            type={5}
            buttonText="Reset"
            disabled={loading}
            onClick={async () => {
              let documents = documentUploadList.documents;
              for (let i of documents) {
                await removeS3Object({ Key: i.key });
              }
              setDocumentUploadList({
                subCatagory: "",
                amount: 0,
                documents: []
              });
            }}
          />
          <NormalButton
            type={13}
            margin={[0, 20]}
            buttonText="Submit"
            disabled={loading}
            onClick={uploadProofs}
          />
        </div>
      </Spin>
    </div>
  );
};

const PerqsFileUploadContainer = connect(state => ({
  user: state.auth.user,
  organisationSettings: state.employee.organisationSettings,
  perquisites: state.employee.perquisites,
  organisationFreeze: state.employee.organisationFreeze
}))(PerqsFileUpload);

export default PerqsFileUploadContainer;