import {
  createOrUpdateStatus,
  deleteStatus,
  updateEmployerOnBoarding,
} from "../../../../apis/organisation";
import { useState, useEffect } from "react";
import { Table, Spin, Modal, notification } from "antd";
import { NormalButton } from "../../../ComponentLibrary";
import plusSymbol from "../../../../assets/icons/whitePlus.svg";
import upload from "../../../../assets/icons/cloud-upload-outline.svg";
import {
  deleteLogo,
  editLogo,
} from "../../../../assets/common/editAndDeleteLogos";
import { connect } from "react-redux";
import organisation from "../../../../redux/modules/organisation";
import BulkUpload from "../BulkUpload";
import { HeaderComponent } from "../../../Common";
import { createLog } from "../../../../apis/user";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"

const Status = ({
  organisationReducer,
  deviceWidth,
  statusList,
  user,
  onboarding,
  isTrial
}) => {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [isUpdateFunction, setIsUpdateFunction] = useState("");
  const [statusObject, setStatusObject] = useState({
    statusName: "",
    color: "#fff",
    description: "",
  });
  const [updateStatusObject, setUpdateStatusObject] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteStatusObject, setDeleteStatusObject] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  const updateOnboarding = async (type) => {
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployerOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id,
      });
      organisationReducer.updateEmployerOnBoarding({
        organisationId: user.organisationId,
        id: user._id,
      });
    }
  };
  const uploadFunction = async () => {
    await updateOnboarding("status");
    await organisationReducer.getStatus(user.organisationId);
  };

  const onDelete = (data) => {
    if (data.totalNoOfEmployees > 0) {
      notification.error({
        message:
          "Employees are currently in this status, please change the status before deleting the status.",
      });
      return;
    }
    setDeleteStatusObject(data);
    setShowDeleteModal(true);
  };

  const onDeleteConfirm = async () => {
    setIsLoading(true);
    let res = await deleteStatus(deleteStatusObject.statusId);
    setIsLoading(false);
    if (res.status !== 200 || res.data.success) {
      notification.error({
        message: "Failed to delete the status, please try again.",
      });
    }

    organisationReducer.loading(false);
    notification.success({ message: "Successfully deleted the status." });
    organisationReducer.getStatus(user.organisationId);
    setDeleteStatusObject({});
    setShowDeleteModal(false);
  };
  const reset = () => {
    setShowStatusModal(false);
    setIsUpdateFunction("");
    setUpdateStatusObject({});
    setStatusObject({
      statusName: "",
      color: "#fff",
      description: "",
    });
  };
  const onSave = async () => {
    if (statusObject.statusName === "") {
      notification.error({ message: "Status name cannot be empty" });
      return;
    }

    if (isUpdateFunction === "") {
      setIsLoading(true);
      let res = await createOrUpdateStatus({
        ...statusObject,
        organisationId: user.organisationId,
      });
      setIsLoading(false);
      if (res.status === 200 && res.data.success) {
        organisationReducer.getStatus(user.organisationId);
        await updateOnboarding("status");
        await createLog({
          name: user.displayName,
          userId: user._id,
          organisationId: user.organisationId,
          message: `User has created the status`,
          category: 14,
        });
        notification.success({
          message: "New status has been successfully created",
        });
      } else {
        notification.error({
          message: res.data.message,
        });
        organisationReducer.loading(false);
        return;
      }
    } else {
      if (statusObject.statusName !== updateStatusObject.statusName) {
        statusObject["statusId"] = updateStatusObject.statusId;
        setIsLoading(true);

        let res = await createOrUpdateStatus({
          ...statusObject,
          organisationId: user.organisationId,
        });
        setIsLoading(false);
        if (res.status === 200 && res.data.success) {
          await createLog({
            name: user.displayName,
            userId: user._id,
            organisationId: user.organisationId,
            message: `User has updaed the status`,
            category: 14,
          });
          notification.success({
            message: "Status has been successfully updated",
          });
          organisationReducer.getStatus(user.organisationId);
        } else {
          notification.error({
            message: res.data.message,
          });
          organisationReducer.loading(false);
          return;
        }
      } else {
        notification.success({
          message: "Status has been successfully updated",
        });
      }
    }
    reset()
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "statusName",
      width: "30%",
    },
    {
      title: "",
      dataIndex: "isDefault",
      width: deviceWidth > 1500 ? "10%" : "20%",
      render: (_isDefault, rowData) => (
        <div className="flex justify-end align-center">
          <NormalButton
            type={5}
            icon={editLogo}
            margin={[3, 0]}
            onClick={() => {
              setIsUpdateFunction(rowData.statusId);
              setShowStatusModal(true);
              setStatusObject(rowData);
              setUpdateStatusObject(rowData);
            }}
          />
          <NormalButton
            type={5}
            margin={[3, 0]}
            icon={deleteLogo}
            onClick={() => {
              onDelete(rowData);
            }}
          />
        </div>
      ),
    },
  ];
  useEffect(() => {
    document.title = 'Settings | Status';
  });
  return (
    isTrial ? <TrialPage user={user} trialMessage={" Configure Mool as per your company policy"} bgImage={configurationsBgImage}
    /> :
    <div className="site-layout">
      <HeaderComponent
        show={false}
        name="Status"
      />
      <div className="app-main-content-container paper-wrapper">
        <div>
          <h1 className="h1-bold-24">Status</h1>
          <p className="tab-subtitle-1">
            Define employee statuses in your organization
          </p>
          <Spin spinning={isLoading} size="large">
            <div className="flex justify-between align-center">
              <p className="h5-bold-20">List ({statusList.length})</p>
              <div>
                <NormalButton
                  icon={upload}
                  buttonText="Bulk Upload"
                  type={16}
                  onClick={() => {
                    setModalStatus(true);
                  }}
                />
                <NormalButton
                  icon={plusSymbol}
                  buttonText="Create New"
                  type={13}
                  onClick={() => {
                    setShowStatusModal(true);
                  }}
                />
              </div>
            </div>
            <Table
              style={{ fontWeight: 500, marginTop: 16 }}
              columns={columns}
              dataSource={statusList.reverse()}
              pagination={false}
              scroll={{ y: 520 }}
            />
          </Spin>
        </div>
        <BulkUpload
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
          type="status"
          updateFunction={uploadFunction}
        />
        <Modal
          loading={isLoading}
          centered
          closable={false}
          visible={showStatusModal}
          className="modal-container"
          footer={null}
          width={400}
          onCancel={reset}
        >
          <Spin spinning={isLoading}>
            <div className="">
              <div className="flex justify-between">
                <h1 className="h5-bold-20 m-bottom-20 modal-margin-left-global-button">
                  {isUpdateFunction === "" ? "Add Status" : "Update Status"}
                </h1>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }} className="m-top-4 ">
                <InputFieldComponent
                  value={statusObject.statusName}
                  autoFocus={true}
                  labeltext={"Status Name"}
                  className={`input-field-container`}
                  onPressEnter={onSave}
                  onChange={(e) => {
                    setStatusObject({
                      ...statusObject,
                      statusName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="m-top-8 modal-margin-left-global-1">
                <NormalButton
                  type={13}
                  buttonText={isUpdateFunction === "" ? "Save" : "Update"}
                  onClick={() => {
                    onSave();
                  }}
                />
                <NormalButton
                  type={5}
                  buttonText={"Cancel"}
                  margin={[0, 12]}
                  onClick={reset}
                />
              </div>
            </div>
          </Spin>
        </Modal>
        <Modal
          loading={isLoading}
          centered
          closable={false}
          visible={showDeleteModal}
          className="modal-container"
          footer={null}
          onCancel={() => {
            setShowDeleteModal(false);
            setDeleteStatusObject({});
          }}
        >
          <Spin spinning={isLoading}>
            <div className="">
              <div className="flex justify-between">
                <h1 className="app-heading-2">Delete Status</h1>
              </div>
              <div className="">
                <p className="font-size-16 font-weight-500">
                  Are you sure you want to delete the status "
                  {deleteStatusObject.statusName}"
                </p>
              </div>
              <div className="m-top-36  text-align-right">
                <NormalButton
                  type={5}
                  buttonText={"cancel"}
                  margin={[0, 12]}
                  onClick={() => {
                    setDeleteStatusObject({});
                    setShowDeleteModal(false);
                  }}
                />
                <NormalButton
                  type={5}
                  buttonText="Delete"
                  onClick={() => {
                    onDeleteConfirm();
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    </div>
  );
};

const StatusContainer = connect(
  (state) => ({
    deviceWidth: state.responsive.deviceWidth,
    statusList: state.organisation.status,
    loading: state.organisation.loading,
    user: state.auth.user,
    onboarding: state.organisation.onboarding,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(Status);

export default StatusContainer;
