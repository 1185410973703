import {
  Col,
  Row,
  Radio,
  Checkbox,
  notification,
  Drawer
} from "antd";
import moment from "moment";
import { useState } from "react";
import { createSettlement, updateStatusData } from "../../../apis/organisation";
import { NormalButton } from "../../ComponentLibrary";
import editLogo from "../../../assets/common/yellowEditIcon.svg"
import { createLog } from "../../../apis/user";
import { Redirect } from "react-router-dom";
import { dateDestruct } from "../../../utils/dateCheckUtil";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";
import DateComponent from "../../InputLibrary/DateComponent/DateComponent";
import Spinner from "../../ComponentLibrary/Spinner/Spinner";
const options = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const SettlementForm = ({
  settlementData,
  updateSettlementData,
  id,
  user,
  parentComponentSetLoading
}) => {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false)
  const [visible, setVisible] = useState(false);
  const [newSettlementData, setNewSettlementData] = useState({});

  const changeSettlement = (key, value) => {
    let settlement = newSettlementData;
    if (key === "lastDayOfWorking")
      settlement[key] = new Date(value).toISOString();
    else settlement[key] = value;
    setNewSettlementData({ ...settlement });
  };
  const submitSettlement = async () => {
    setLoading(true);
    if (!newSettlementData.lastDayOfWorking) {
      setLoading(false);
      notification.error({ message: "Please enter Last Day Of Working" });
      return <Redirect to={`/org/employee/employee_details/${id}`} />
    }
    const { day: lastDay, month: lastMonthOfWorking, year } = dateDestruct(newSettlementData.lastDayOfWorking)
    let res = await createSettlement({
      settlement: {
        ...newSettlementData,
        id,
        organisationId: user.organisationId,
        lastDay,
        lastMonthOfWorking: lastMonthOfWorking - 1,
        year
      },
    });
    let status = true;
    await updateStatusData(id + "/" + status);

    if (res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has updated full and final settlement.`,
        category: 18,
      });
      await updateSettlementData(res.data.data.fnf);
      notification.success({
        message: "Successfully updated employee settlement",
      });
    }
    else {
      notification.error({ message: "Unable to update the settlement" });
    }
    setLoading(false);
    setVisible(false);
    setEdit(false)
  };
  const showDrawer = () => {
    setNewSettlementData({ ...settlementData })
    setVisible(true);
    setEdit(true)
  };
  const onClose = () => {
    parentComponentSetLoading(true);
    setVisible(false);
    setEdit(false);
  };
  const viewData = (
    <>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Leave Encashment</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{settlementData.leaveEncashment}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Loss of Pay</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{settlementData.lossOfPay}</p>
        </Col>
      </Row><Row>
        <Col span={10}>
          <h3 className="fields-heading">Eligible for Gratuity</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{(settlementData.gratuityEligible && "Yes") || "No"}</p>
        </Col>
      </Row><Row>
        <Col span={10}>
          <h3 className="fields-heading">Gratuity amount to be paid</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{(settlementData.gratuityEligible && settlementData.gratuityAmount) || 0}</p>
        </Col>
      </Row><Row>
        <Col span={10}>
          <h3 className="fields-heading">Other credit amount</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{settlementData.otherCreditAmount}</p>
        </Col>
      </Row><Row>
        <Col span={10}>
          <h3 className="fields-heading">Other debit amount</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{settlementData.otherDebitAmount}</p>
        </Col>
      </Row><Row>
        <Col span={10}>
          <h3 className="fields-heading">PF withdrawn</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{(settlementData.withdrawPF && "Yes") || "No"}</p>
        </Col>
      </Row><Row>
        <Col span={10}>
          <h3 className="fields-heading">PF amount to be cashed</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{settlementData.pfAmount}</p>
        </Col>
      </Row><Row>
        <Col span={10}>
          <h3 className="fields-heading">Last date of working</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{settlementData.lastDayOfWorking.slice(0, 10)}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Income tax deducted</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{settlementData.taxDeducted}</p>
        </Col>
      </Row>
    </>
  )
  return (
    <>
      <section className="settlement-form">
        <div className="flex justify-between m-bottom-16">
          <p className="font-size-global-24 font-weight-global-700">Final Settlement</p>

          <div className="">
            <NormalButton
              type={13}
              icon={editLogo}
              buttonText="Edit"
              onClick={showDrawer}
            ></NormalButton>
            {/* {
              edit
                ? <>
                  <NormalButton
                    onClick={() => setEdit(false)}
                    type={5}
                    buttonText="Cancel"
                    margin={[0, 16]}
                  />
                  <NormalButton
                    onClick={submitSettlement}
                    type={13}
                    buttonText="Make Settlement"
                  />
                </>
                : <>
                  <NormalButton
                    icon={editLogo}
                    onClick={() => setEdit(true)}
                    type={13}
                    buttonText="Edit"
                  />
                </>
            } */}
          </div>
        </div>
        {viewData}
        <Drawer
          title="Edit Final Settlement"
          placement="right"
          onClose={onClose}
          visible={visible}
          size={"small"}
        >
          <Spinner spinning={loading}>
            <div className="drawer" style={{ margin: "0", paddingLeft: "1.9em" }}>
              <p style={{ marginLeft: "1em", marginBottom: "2 em", marginTop: "0.7em" }} className="font-size-global-24 font-weight-global-700">Edit Final Settlement</p>
              <Col marginLeft={0}>
                <Col xs={24} sm={24} md={24}>
                  <div className="org-data-container">
                    <InputFieldComponent
                      labeltext={"Leave Encashment"}
                      disabled={!edit}
                      type={"number"}
                      className="general-ant-field-input"
                      style={{ width: "100%" }}
                      value={newSettlementData.leaveEncashment ?? 0}
                      onChange={(e) => changeSettlement("leaveEncashment", parseInt(e.target.value || 0))}
                      placeholder="Leave Encashment"
                      size={"large"}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} marginLeft={0}>
                  <div className="org-data-container">
                    <InputFieldComponent
                      disabled={!edit}
                      type={"number"}
                      labeltext={"Loss Of Pay"}
                      className="general-ant-field-input"
                      style={{ width: "70%" }}
                      value={newSettlementData.lossOfPay ?? 0}
                      onChange={(e) => changeSettlement("lossOfPay", parseInt(e.target.value || 0))}
                      placeholder="Loss Of Pay"
                      size={"large"}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <div className="org-data-container">
                    <h3 className="general-ant-field-label" style={{ marginBottom: "0" }}>Eligible For Gratuity</h3>
                    <Radio.Group
                      disabled={!edit}
                      style={{ marginTop: 10 }}
                      options={options}
                      onChange={(e) => {
                        changeSettlement("gratuityEligible", !newSettlementData.gratuityEligible)
                        if ((!newSettlementData.gratuityEligible)) {
                          changeSettlement("gratuityAmount", parseInt(0))
                        } else {
                          changeSettlement("gratuityAmount", parseInt(newSettlementData.gratuityAmount))
                        }
                      }}
                      value={newSettlementData.gratuityEligible ?? 0}
                    />
                  </div>
                </Col>
                {newSettlementData.gratuityEligible &&
                  <Col xs={24} sm={24} md={24}>
                    <div className="org-data-container">
                      <InputFieldComponent
                        disabled={!edit || !newSettlementData.gratuityEligible}
                        type={"number"}
                        className="general-ant-field-input"
                        labeltext={" Gratuity amount to be paid"}
                        style={{ width: "70%" }}
                        value={(newSettlementData.gratuityEligible && newSettlementData.gratuityAmount) || 0}
                        onChange={(e) => {
                          changeSettlement("gratuityAmount", parseInt(e.target.value));
                        }}
                        placeholder="Gratuity Amount"
                        size={"large"}
                      />
                    </div>
                  </Col>}
                <Col xs={24} sm={24} md={24}>
                  <div className="org-data-container">
                    <InputFieldComponent
                      disabled={!edit}
                      labeltext={"Other Credit Amount"}
                      type={"number"}
                      className="general-ant-field-input"
                      style={{ width: "70%" }}
                      value={newSettlementData.otherCreditAmount ?? 0}
                      onChange={(e) => changeSettlement("otherCreditAmount", parseInt(e.target.value || 0))}
                      placeholder="Other Credit Amount"
                      size={"large"}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <div className="org-data-container">
                    <InputFieldComponent
                      disabled={!edit}
                      labeltext={"Other Debit Amount"}
                      type={"number"}
                      className="general-ant-field-input"
                      style={{ width: "70%" }}
                      value={newSettlementData.otherDebitAmount ?? 0}
                      placeholder="Other Debit Amount"
                      onChange={(e) => changeSettlement("otherDebitAmount", parseInt(e.target.value || 0))}
                      size={"large"}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} marginLeft={0}>
                  <div style={{ marginTop: "-2em" }} className="org-data-container">
                    <Checkbox
                      disabled={!edit}
                      checked={newSettlementData.withdrawPF ?? 0}
                      style={{ marginTop: 15, marginBottom: 10 }}
                      onClick={(_data) => {
                        changeSettlement("withdrawPF", !newSettlementData.withdrawPF)
                        if ((!newSettlementData.withdrawPF)) {
                          changeSettlement("pfAmount", parseInt(0))
                        } else {
                          changeSettlement("pfAmount", parseInt(newSettlementData.pfAmount))
                        }
                      }}
                      className="withdraw-pf"
                    >
                      <span style={{ color: "black", fontWeight: "700" }}> Withdraw PF</span>
                    </Checkbox>
                    {newSettlementData.withdrawPF &&
                      <div style={{ marginTop: '0em' }} className="org-data-container">
                        <InputFieldComponent
                          disabled={!newSettlementData.withdrawPF || !edit}
                          labeltext={"PF amount to be encashed"}
                          type={"number"}
                          className="general-ant-field-input"
                          style={{ width: "70%" }}
                          value={newSettlementData.pfAmount ?? 0}
                          onChange={(e) => changeSettlement("pfAmount", parseInt(e.target.value || 0))}
                          placeholder="PF Amount"
                          size={"large"}
                        />
                      </div>}
                  </div>
                  <div style={{ marginTop: "1rem" }} className="org-data-container">
                    <DateComponent
                      disabled={!edit}
                      labelText={"Last date of working"}
                      className="general-ant-field-input"
                      style={{ width: "70%" }}
                      value={newSettlementData.lastDayOfWorking ? moment(new Date(newSettlementData.lastDayOfWorking), 'YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD')}
                      onChange={(e) => changeSettlement("lastDayOfWorking", e)}
                      placeholder="Last Day Of Working"
                      size={"large"}
                    />
                  </div>
                  <div style={{ marginTop: "2em" }} className="org-data-container">
                    <InputFieldComponent
                      disabled={!edit}
                      labeltext={"Income Tax Deducted"}
                      type={"number"}
                      className="general-ant-field-input"
                      style={{ width: "70%" }}
                      value={newSettlementData.taxDeducted ?? 0}
                      onChange={(e) => changeSettlement("taxDeducted", parseInt(e.target.value || 0))}
                      placeholder="Tax Deducted"
                      size={"large"}
                    />
                  </div>
                </Col>
              </Col>
              <div className="button-container" style={{ marginLeft: "1.5em" }}>
                <NormalButton
                  buttonText="Save"
                  type={13}
                  onClick={submitSettlement}
                />
                <NormalButton buttonText="Cancel" type={5} onClick={onClose} />

              </div>
            </div>
          </Spinner>
        </Drawer>
      </section>
    </>
  );
};

export default SettlementForm;