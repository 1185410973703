import { ArrowLeftOutlined } from '@ant-design/icons';
import { Table } from "antd";
import { useEffect, useState } from "react";
import { getUnapprovedProofs } from "../../../apis/organisation";
import { NormalButton } from "../../ComponentLibrary";


const UploadList = ({ history, setPageState, pageData, user, setEmployeeData, financialYear }) => {
    const [unApprovedProofsList, setUnApprovedProofsList] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const getData = async () => {
        setIsLoading(true)
        let fyYear = financialYear;
        let proofType = pageData.name === "Allowance" ? 1 : 2;
        let res = await getUnapprovedProofs(user.organisationId, fyYear, proofType)
        let unApprovedList = {}

        res.data.forEach((unApprovedData) => {
            if (unApprovedList[unApprovedData.id]) {
                let tempData = unApprovedList[unApprovedData.id];
                tempData.totalDocumentsLength += unApprovedData.totalDocumentsLength
                tempData.totalUnVerifiedDocuments += unApprovedData.totalUnVerifiedDocuments
                unApprovedList[tempData.id] = tempData
            }
            else {
                unApprovedList[unApprovedData.id] = unApprovedData;
            }
        })
        setUnApprovedProofsList(unApprovedList)
        setIsLoading(false)
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financialYear])

    const columns = [
        {
            title: "Employee Name",
            dataIndex: "displayName",
            width: "20%",
        },
        {
            title: "Employee Id",
            dataIndex: "employeeId",
            width: "20%",

        },
        {
            title: "PAN",
            dataIndex: "PAN",
            width: "20%",

        },
        {
            title: "Files Status",
            dataIndex: "totalDocumentsLength",
            render: (_totalDocumentsLength, rowData) => (
                <div className="files-status-container"><span className="files-status-pending">{rowData.totalUnVerifiedDocuments} Pending </span> </div>
            ),
            width: "20%"
        },
        {
            title: "",
            dataIndex: "PAN",
            render: (_PAN, rowData) => (
                <NormalButton
                    type={2}
                    buttonText="View"
                    onClick={() => {
                        setEmployeeData({
                            id: rowData.id,
                            employeeData: rowData,
                            fyYear: "2021-2022"
                        });
                        setPageState(1);
                    }}
                ></NormalButton>
            ),
            width: "20%"
        }
    ];

    return (
        <div className="app-main-content-container upload-files-container">
            <div className="upload-files-header">
                <div className="header-left-side flex align-center">
                    <ArrowLeftOutlined
                        onClick={() => {
                            history.goBack()
                        }}
                        className="arrow-left-button"
                    />
                    <h1 className="display-name">All Uploads</h1>
                </div>
            </div>
            <div className="upload-files-body ">
                <Table
                    className="upload-files-table"
                    style={{
                        fontWeight: 500,
                        fontSize: "16px"
                    }}
                    pagination={Object.keys(unApprovedProofsList) > 8 ? {
                        pageSize: 10,
                    } : false}
                    columns={columns}
                    loading={isLoading}
                    dataSource={Object.keys(unApprovedProofsList).map((key) => unApprovedProofsList[key]).reverse()}
                />
            </div>
        </div>
    )
}

export default UploadList;