/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import imageUpload from "../../../assets/common/imageUpload.svg";
import { NormalButton } from "../../ComponentLibrary";
import { Select, Upload, message, notification, Checkbox } from "antd";
import config, {
  numberOfDaysExceptSundayAndSaturDay,
} from "../../../utils/config";
import { DeleteOutlined } from "@ant-design/icons";
import { removeS3Object, requestLeave } from "../../../apis/employee";
import { pdfjs } from "react-pdf";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import { withRouter } from "react-router";
import Spinner from "../../ComponentLibrary/Spinner/Spinner";
import DropdownComponent from "../../InputLibrary/DropdownComponent/DropdownComponent"
import DateComponent from "../../InputLibrary/DateComponent/DateComponent"
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent"

const { Dragger } = Upload;
const { Option } = Select;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ApplyLeave = ({
  leaveList,
  leaveBalanceReport,
  user,
  employeeReducer,
  modal,
  setModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [leaveApplication, setLeaveApplication] = useState({
    leaveId: "",
    startDate: "",
    endDate: "",
    reason: "",
    isHalfDay: false,
    duration: 0,
    document: {},
    status: 0,
  });

  const draggerProps = {
    maxCount: 1,
    name: "file",
    multiple: true,
    action: `${config.rootUrl}/api/document/leaves`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    showUploadList: false,
    beforeUpload: config.beforeFileUpload,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        setLeaveApplication({
          ...leaveApplication,
          document: documentDetails,
        });
      }
    },
  };

  const deleteDoc = async () => {
    setIsLoading(true);
    await removeS3Object({ Key: leaveApplication.document.Key });
    setIsLoading(false);
    setLeaveApplication({
      ...leaveApplication,
      document: {},
    });
  };

  const DocumentDisplay = ({ data, deleteFunction }) => {
    return (
      <div className="document-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <DeleteOutlined onClick={deleteFunction} />
          <span className="file-typeHeader">{data.Key.split(".").pop()}</span>
          <div
            style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}
          >
            <span>Leave Document</span>
            <span className="date-contianer">
              {new Date(data.createdAt).toDateString()}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const leaveRequest = async () => {
    if (!leaveApplication.leaveId) {
      message.error("Please select a leave type");
      return;
    }
    if (!leaveApplication.startDate) {
      message.error("Please select the start date");
      return;
    }
    if (!leaveApplication.isHalfDay && !leaveApplication.endDate) {
      message.error("Please select the end date");
      return;
    }
    if (!leaveApplication.reason) {
      message.error("Please select the reason");
      return;
    }
    setIsLoading(true);
    let res = await requestLeave({
      ...leaveApplication,
      organisationId: user.organisationId,
      id: user._id,
    });
    if (res.data.success) {
      notification.success({
        message: "A request has been raised successfully",
      });
      employeeReducer.updateLeaveLogs({
        id: user._id,
        organisationId: user.organisationId,
      });
    } else {
      notification.error({ message: "Unable to raise the leave request" });
    }
    reset();
  };

  const reset = () => {
    setModal(false);
    setLeaveApplication({
      leaveId: "",
      startDate: "",
      endDate: "",
      reason: "",
      isHalfDay: false,
      duration: 0,
      document: {},
      status: 0,
    });
    setIsLoading(false);
  };

  return (
    <Modal
      visible={modal}
      onCancel={reset}
      centered
      footer={null}
      closable={true}
      width={400}
    >
      <Spinner spinning={isLoading} size="large">
        <div className="leave-main-container">
          <p className="modal-margin-left-global-heading font-size-global-24 font-weight-global-700">Apply for leave</p>
          <div style={{ display: "flex", justifyContent: "center" }} className="modal-margin-top-add-global-1">
            <DropdownComponent
              bordered={false}
              labeltext={"Leave Type"}
              className="general-ant-field-input"
              value={leaveApplication.leaveId}
              values={leaveList.map(item => item.leaveId)}
              options={leaveList.map(item => item.name)}
              onChange={(e) => {
                if (leaveBalanceReport.leaveTypes[e].balance > 0) {
                  setLeaveApplication({
                    ...leaveApplication,
                    leaveId: e,
                    startDate: "",
                    endDate: "",
                  });
                } else {
                  message.error(
                    "Sorry You do not have any remaining leaves in the selected section"
                  );
                }
              }}
            >
              {leaveList.map((val, index) => (
                <Option key={`leave-list-${index}`} value={val.leaveId}>
                  {val.name} (
                  {Math.round(
                    leaveBalanceReport.leaveTypes[val.leaveId].balance * 100
                  ) / 100}
                  )
                </Option>
              ))}
            </DropdownComponent>
          </div>
          <div className="m-bottom-6 m-top-6 modal-margin-left-global-2 ">
            <Checkbox
              checked={leaveApplication.isHalfDay}
              onChange={(value) => {
                setLeaveApplication({
                  ...leaveApplication,
                  isHalfDay: value.target.checked,
                  duration: value.target.checked ? 0.5 : 0,
                });
              }}
            >
              Opting For Half Day?
            </Checkbox>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }} >
            <DateComponent
              className="general-ant-field-input employee-leave-select"
              labelText={"Start Date"}
              format="MM/DD/YYYY"
              value={leaveApplication.startDate}
              disabled={leaveApplication.leaveId === ""}
              onChange={(e) => {
                setLeaveApplication({
                  ...leaveApplication,
                  startDate: e,
                  endDate: leaveApplication.isHalfDay ? e : ""
                });
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }} className="modal-margin-top-add-global-1">
            <DateComponent
              className="general-ant-field-input employee-leave-select"
              labelText={"End Date"}
              format="MM/DD/YYYY"
              disabled={
                leaveApplication.startDate === "" ||
                leaveApplication.leaveId === "" ||
                leaveApplication.isHalfDay
              }
              value={leaveApplication.isHalfDay ? leaveApplication.startDate : leaveApplication.endDate}
              onChange={(e) => {
                if (
                  leaveApplication.startDate === "" ||
                  leaveApplication.leaveId === "" ||
                  leaveApplication.isHalfDay
                ) {
                  return;
                }
                let a = moment(leaveApplication.startDate);
                let b = moment(e);
                let c = b.diff(a, "days");
                let dayDiffs = numberOfDaysExceptSundayAndSaturDay(
                  a.format("MM/D/YYYY"),
                  b.format("MM/D/YYYY")
                );
                if (
                  dayDiffs > 0 &&
                  dayDiffs <
                  leaveBalanceReport.leaveTypes[leaveApplication.leaveId]
                    .balance
                ) {
                  setLeaveApplication({
                    ...leaveApplication,
                    endDate: e,
                    duration: c,
                  });
                } else {
                  if (c <= 0) {
                    message.error("End Date should be after the start date");
                  } else {
                    message.error("Sorry Not enough leaves");
                  }
                }
              }}
            />
          </div>
          <div className="modal-margin-top-add-global-1 modal-alignment-center-global" >
            <InputFieldComponent
              className="general-ant-field-input employee-leave-select"
              value={leaveApplication.reason}
              labeltext={"Reason"}
              border={false}
              onChange={(e) => {
                setLeaveApplication({
                  ...leaveApplication,
                  reason: e.target.value,
                });
              }}
            />
          </div>

          {Object.keys(leaveApplication.document).length > 0 ? (
            <DocumentDisplay
              deleteFunction={deleteDoc}
              data={leaveApplication.document}
            />
          ) : (
            <Dragger
              showUploadList={false}
              className="dragger"
              {...draggerProps}
            >
              {
                <div className="dragger-info-container">
                  <img src={imageUpload} alt=" " />
                  <p className="ant-upload-text">
                    Drag and drop your file or click here to upload
                  </p>
                </div>
              }
            </Dragger>
          )}

          <NormalButton
            buttonText="Save"
            onClick={leaveRequest}
            type={13}
            margin={[20, 0]}
            padding={[10, 30]}
            block={true}
          />
        </div>
      </Spinner>
    </Modal>
  );
};

export default withRouter(ApplyLeave);
