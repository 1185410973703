import { HeaderComponent } from "../Common";
import home from "../../assets/icons/Home1.svg";
import { connect } from "react-redux";
import auth from "../../redux/modules/auth";
import { Col, Row, Select } from "antd";
import PayrollAndDeductionCards from "./Components/PayrollAndDeductionCards";
import Tasks from "./Components/Tasks";
import PayrollSummary from "./Components/PayrollSummary";
import PayrollProcessingHistory from "./Components/PayrollProcessingHistory";
import BroadcastMessageAndAdvertisement from "./Components/BroadcastMessageAndAdvertisement";
import { useEffect, useState } from "react";
import ZeroState from "../ZeroState/ZeroState";
import organisation from "../../redux/modules/organisation";
import { withRouter } from "react-router-dom";
import TrialPage from "../Common/TrialComponent";
import dashboardBgImage from "../../assets/dummyPages/dashboardBgImage.png"
import { checkFinancialYear } from "../../utils/config";
import MailNotification from "./Components/MailNotification";

const { Option } = Select;
const OrganisationDashboard = ({
  freezeResult,
  employeeList,
  broadcastMessage,
  user,
  loading,
  onboarding,
  reimbursements,
  authReducer,
  history,
  isTrial,
  organisationReducer
}) => {
  const [netPay, setNetPay] = useState(0);
  const [pf, setPF] = useState(0);
  const [taxs, setTaxs] = useState(0);
  const [totalPay, setTotalPay] = useState(0);
  const [employees, setEmployees] = useState(0);
  const [createdAt, setCreatedAt] = useState("Not Available");
  const [currFinancialYear, setCurrFinancialYear] = useState(
    checkFinancialYear().financialYear
  );
  const isEndFYMailNotification = true;
  const [financialYearList, setFinancialYearList] = useState([]);
  useEffect(() => {
    function calculateFinancialYear() {
      let startFYear = 2020;
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let resFinancialYearList = [];
      for (let i = startFYear; i < currentYear + 1; i++) {
        let nextYear = i + 1;
        let tempFYear = `${i}-${nextYear}`;
        resFinancialYearList.push(tempFYear);
      }
      resFinancialYearList.reverse();
      setFinancialYearList([...resFinancialYearList]);
    }
    calculateFinancialYear();
  }, []);

  useEffect(() => {
    if (freezeResult.length > 0) {
      let latestFreezeResult = freezeResult[0];
      setTotalPay(latestFreezeResult.totalEarnings);
      setEmployees(latestFreezeResult.totalPayslipsGenerated);
      setCreatedAt(new Date(latestFreezeResult.createdAt).toDateString());
      let totalPF = 0;
      let totalTax = 0;
      let pay = 0;
      freezeResult.forEach((obj) => {
        totalPF += obj.totalPF ? parseInt(obj.totalPF) : 0;
        totalTax += obj.totalTax ? parseInt(obj.totalTax) : 0;
        pay += obj.totalEarnings ? parseInt(obj.totalEarnings) : 0;
      });
      setNetPay(pay);
      setPF(totalPF);
      setTaxs(totalTax);
    }
  }, [freezeResult]);
  useEffect(() => {
    document.title = 'Organization | Dashboard';
  });
  return (
    isTrial ? <TrialPage user={user} trialMessage={"View all relevant insights at one place!"} bgImage={dashboardBgImage}
    /> :
      !loading && (
        <div className="site-layout">
          <HeaderComponent
            icon={home}
            name={"Dashboard"}
            showMoolCoins={true}
          />
          {
            isEndFYMailNotification &&
            <MailNotification />
          }
          {onboarding && (
            <ZeroState authReducer={authReducer} history={history} />
          )}
          {!onboarding && (
            <div className="app-main-content-container">
              <Row gutter={[40, 40]}>
                <Col xs={24} sm={24} md={24} lg={16}>
                  <Row gutter={[40, 40]}>
                    <Col xs={24} sm={24}>
                      <PayrollAndDeductionCards
                        user={user}
                        totalPay={totalPay}
                        authReducer={authReducer}
                        pf={pf}
                        taxs={taxs}
                        employees={employees}
                        createdAt={createdAt}
                        numberOfEmployee={employeeList.length}
                      />
                    </Col>
                    <Col xs={24} sm={24}>
                      <PayrollSummary
                        pf={pf}
                        taxs={taxs}
                        netPay={netPay}
                        freezeResult={freezeResult}
                      />
                    </Col>
                    <Col xs={24} sm={24}>
                      <PayrollProcessingHistory freezeResult={freezeResult} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Row gutter={[40, 40]}>
                    <Col xs={24} sm={24}>
                      <div
                        className="flex align-center justify-end"
                        style={{ marginBottom: "2rem" }}
                      >
                        <h3 className="font-weight-700 text-size-21 mb-2 ml-5">Financial Year</h3>
                        <Select
                          defaultValue={currFinancialYear}
                          className="general-ant-field-select ml-2"
                          style={{
                            width: "8rem",
                            marginLeft: "1rem",
                            background: "none",
                            padding: 0
                          }}
                          size="large"
                          onChange={e => {
                            setCurrFinancialYear(e);
                            organisationReducer.freezeResult(`${user.organisationId}/${e}`);
                          }}
                          bordered={false}
                        >
                          {financialYearList.map((val, index) =>
                            <Option key={`opt-${index}`} value={val}>
                              <h3>{val}</h3>
                            </Option>
                          )}
                        </Select>
                      </div>
                      <Tasks reimbursements={reimbursements} user={user} authReducer={authReducer} financialYear={currFinancialYear} />
                    </Col>
                    <Col xs={24} sm={24}>
                      <BroadcastMessageAndAdvertisement
                        broadcastMessage={broadcastMessage}
                        user={user}
                        authReducer={authReducer}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </div>
      )
  );
};

const OrganisationDashboardContainer = connect(
  (state) => ({
    user: state.auth.user,
    organisationSettings: state.organisation.organisationSettings,
    employeeList: state.organisation.employeeList,
    freezeResult: state.organisation.freezeResult,
    broadcastMessage: state.organisation.broadcastMessage,
    loading: state.organisation.loading,
    onboarding: state.organisation.onboarding,
    reimbursements: state.organisation.reimbursements,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    organisationReducer: organisation.getActions(dispatch),
  })
)(OrganisationDashboard);

export default withRouter(OrganisationDashboardContainer);
