import { Layout } from "antd";
import React from "react";
import { useEffect } from "react";
import { useHistory, withRouter } from "react-router-dom";
import moolLogo from "../../assets/moolLogo.svg";
import orderSuccess from "../../assets/order_success.svg";
import { PHONENUMBER } from "../../utils/config";
import { NormalButton } from "../ComponentLibrary";
import "./index.less";
const { Content } = Layout;

function OrderedSuccess() {
  const history = useHistory()
  const signMeOut = () => {
    localStorage.setItem("mool_auth","")
    localStorage.removeItem("mool_auth")
    localStorage.removeItem("organisationId")
    window.location = "/";
  }
  useEffect(() => {
    if (!localStorage.getItem("organisationId")) {
      history.push("/")
    }
  }, [history]);

  return (
    <Content className="register-container">
      <div className="halfNavbar">
        <div className="logoDiv">
          <img src={moolLogo} alt={"mool logo"} />
        </div>
        <div className="sign-outDiv">
          <p className="h6-bold-18" style={{ cursor: "pointer" }}></p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          height: "82vh",
          width: "100vw",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div className="successDiv">
            <img src={orderSuccess} className="orderSuccess" alt=""></img>
          </div>
          <h1 className="h1-bold-30 bold" style={{ lineHeight: "34px" }}>
            Wohoo!
          </h1>
          <h1 className="h1-bold-30 bold" style={{ lineHeight: "34px" }}>
            You're a part of the mool family
          </h1>
          <p className="font-size-14 para">
            Stay tuned. You'll receive your credential post which our team will
            reach out to you for smooth onboarding
          </p>
          <NormalButton
            type={13}
            buttonText="Back to login screen"
            margin={[33, 0]}
            heightWidth={["50px", "210px"]}
            onClick={signMeOut}
          />
        </div>
      </div>
      <div className="footerDiv">
        <div style={{ width: "50vw" }}>
          <p className="font-size-16">
            <b>Questions? Call {PHONENUMBER}</b>
          </p>
          <p className="font-size-12">
            <a href="https://www.moolfinance.com/termsAndConditions">Terms of service</a> | <a href="https://www.moolfinance.com/privacyPolicy">Privacy Policy</a> |
            <a href="https://www.moolfinance.com/contact"> Contact Us</a>
          </p>
        </div>
        <div>
          <div style={{ width: "50vw", textAlign: "right" }}>
            <div className="font-size-16" style={{ fontWeight: "bold" }}>
              <p>Mool Innovation Labs Pvt. Ltd.</p>
              <p className="font-size-12" style={{ color: "grey", fontWeight: "500" }}>All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
}

export default withRouter(OrderedSuccess);
