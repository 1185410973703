import { connect } from "react-redux";
import auth from "../../redux/modules/auth";
import { Result, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router";
import { checkAuthToken } from "../../apis/auth";
import React from "react";

const Authorize = ({ authReducer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { authToken } = useParams();
  useEffect(() => {
    setIsLoading(true);
    localStorage.setItem("mool_auth", "");
    localStorage.removeItem("mool_auth");
    if (!authToken) {
      setIsSuccess(false);
      setIsLoading(false);
      setTimeout(() => {
        window.location = "/";
      }, 5000);
    } else {
      const postData = {
        token: authToken,
      };
      checkAuthToken(postData).then((data) => {
        if (data.data) {
          setIsSuccess(true);
          setIsLoading(false);
          setTimeout(() => {
            localStorage.setItem("mool_auth", authToken);
            window.location = "/";
          }, 5000);

        } else {
          setIsSuccess(false);
          setIsLoading(false);
          setTimeout(() => {
            window.location = "/";
          }, 5000);
        }
      }).catch(err => {
        setIsLoading(false);
        setIsSuccess(false);
      });
    }
    setIsLoading(false)
  }, [authToken, isLoading]);

  return (
    <Spin spinning={isLoading} size="large">
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!isLoading &&
          (isSuccess && (
            <Result
              status="success"
              title="User has been successfully authenticated"
              subTitle="You will be redirected to the dashboard in 5 seconds"
            />
          ))}
      </div>
    </Spin>
  );
};

const AuthorizeContainer = connect((dispatch) => ({
  authReducer: auth.getReducer(),
}))(Authorize);

export default withRouter(AuthorizeContainer);
