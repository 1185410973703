import { Skeleton } from "antd";
import Modal from "antd/lib/modal/Modal";
import config from "../../../utils/config";
import InfoBtn from "../../ComponentLibrary/InfoButtonComponent/InfoBtn";
import "../../../styles/salarystructure.less";

const Chapter6A = ({
  organisationSettings,
  finalPayslip,
  modal,
  ctc,
  reset,
  monthly,
  setModal,
  setFinalPayslip,
}) => {
  return (
    <Modal
      centered
      className="salary-struct"
      visible={modal}
      closable={true}
      width={700}
      footer={null}
      okButtonProps={{ style: { display: "none" } }}
      onCancel={() => {
        reset();
        setModal(false);
        setFinalPayslip(null);
      }}
    >
      <Skeleton loading={!finalPayslip} paragraph={{ rows: 20 }}>
        {finalPayslip != null && (
          <div
            style={{
              padding: "21px 15.5px 11px 21px",
              width: 650,
            }}
            id="ctc-breaking"
          >
            <h3
              style={{
                fontSize: 24,
                fontWeight: 700,
                textAlign: "left",
                color: "#2d2e30",
                margin: "20px 0",
              }}
            >
              Salary Breakdown
            </h3>
            {/* <Row justify="space-between">
                <div>Some</div>
                <p>New Tax Regime</p>
                <p>Old Tax Regime</p>
    
              </Row> */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 600,
                  height: 50,
                  padding: "10px 10px",
                  // border: "solid #dcdfe8 1px",
                }}
              >
                {" "}
                <p style={{ width: 120 }}></p>
                <div className="flex align-center justify-start">
                  <p className="font-weight-global-24 font-weight-700">
                    Old Tax Regime
                  </p>
                  <InfoBtn infoText={"Old Tax Regime"} />
                </div>
                <div className="flex align-center justify-start">
                  <p className="font-weight-global-24 font-weight-700">
                    New Tax Regime
                  </p>
                  <InfoBtn infoText={"New Tax Regime"} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 600,
                  height: 50,
                  padding: "10px 10px",
                  margin: "10px 0"
                  // border: "solid #dcdfe8 1px",
                }}
              >
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 700,

                    width: 135,
                  }}
                >
                  Earnings

                </p>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                > Amount ( In INR )
                </p>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                > Amount ( In INR )
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 600,
                  padding: "10px 10px",
                  border: "solid #dcdfe8 1px",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                    width: 120,
                  }}
                >
                  Basic
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  {config.numformatter(finalPayslip.basic)}
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  {config.numformatter(finalPayslip.basic)}
                </p>
              </div>

              {finalPayslip.specialAllowance !== 0 && (
                <>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      Special Allowances
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.specialAllowance)}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.specialAllowance)}
                    </p>
                  </div>

                </>
              )}
              {finalPayslip.HRA !== 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      HRA
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.HRA)}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.HRA)}
                    </p>
                  </div>

                </>
              )}
              {finalPayslip.DA !== 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      Dearness
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.DA)}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.DA)}
                    </p>
                  </div>

                </>
              )}
              {finalPayslip.LTA !== 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      Leave Travel Allowances
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.LTA)}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.LTA)}
                    </p>
                  </div>

                </>
              )}
              {finalPayslip.statutoryBonuse !== 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      Statutory Bonus
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.statutoryBonuse)}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.statutoryBonuse)}
                    </p>
                  </div>

                </>
              )}
              {finalPayslip.FOOD !== 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      Food Allowances
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.FOOD)}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.FOOD)}
                    </p>
                  </div>

                </>
              )}
              {Object.keys(finalPayslip.otherAllowances).map((val) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                      lineHeight:"14px"
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      {organisationSettings.allowances[val].label}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.otherAllowances[val])}{" "}

                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.otherAllowances[val])}{" "}

                    </p>
                  </div>

                </>
              ))}
              {Object.keys(finalPayslip.customAllowances).map((val) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      {val}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.customAllowances[val])}{" "}

                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.customAllowances[val])}{" "}

                    </p>
                  </div>

                </>
              ))}
              {Object.keys(finalPayslip.bonuses).map((val) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      {val}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.bonuses[val])}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.bonuses[val])}
                    </p>
                  </div>

                </>
              ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 600,
                  height: 50,
                  padding: "10px 10px",
                  border: "solid #dcdfe8 1px",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                    width: 120,
                  }}
                >
                  Total Earnings
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  {config.numformatter(finalPayslip.totalFixedIncome)}
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  {config.numformatter(finalPayslip.totalFixedIncome)}
                </p>
              </div>



              {finalPayslip.employee_ESI !== 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      Employee ESI
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.employee_ESI)}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.employee_ESI)}
                    </p>
                  </div>

                </>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 600,
                  height: 50,
                  padding: "10px 10px",
                  margin: "10px 0"
                  // border: "solid #dcdfe8 1px",
                }}
              >
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 700,

                    width: 135,
                  }}
                >
                  Deductions

                </p>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                > Amount ( In INR )
                </p>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                > Amount ( In INR )
                </p>
              </div>
              {finalPayslip.employee_PF !== 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      PF
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.employer_PF)}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.employer_PF)}
                    </p>
                  </div>

                </>
              )}
              {finalPayslip.employee_PF - finalPayslip.employer_PF > 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      VPF
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(
                        finalPayslip.employee_PF - finalPayslip.employer_PF
                      )}{" "}

                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(
                        finalPayslip.employee_PF - finalPayslip.employer_PF
                      )}{" "}

                    </p>
                  </div>

                </>)}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 600,
                  height: 50,
                  padding: "10px 10px",
                  border: "solid #dcdfe8 1px",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                    width: 120,
                  }}
                >
                  Income Tax
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  {config.numformatter(finalPayslip.oldRegime.incomeTax)}
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  {config.numformatter(finalPayslip.newRegime.incomeTax)}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 600,
                  height: 50,
                  padding: "10px 10px",
                  border: "solid #dcdfe8 1px",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                    width: 120,
                  }}
                >
                  Professional Tax
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  {config.numformatter(finalPayslip.proffTax)}
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  {config.numformatter(finalPayslip.proffTax)}
                </p>
              </div>
              {finalPayslip.FOOD !== 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        width: 120,
                      }}
                    >
                      Food Allowances
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.FOOD)}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                      }}
                    >
                      {config.numformatter(finalPayslip.FOOD)}
                    </p>
                  </div>

                </>
              )}


              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 600,
                  height: 50,
                  padding: "10px 10px",
                  border: "solid #dcdfe8 1px",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                    width: 120,
                  }}
                >
                  Total Deductions
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  {config.numformatter(finalPayslip.oldRegime.deductions)}
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                  }}
                >
                  {config.numformatter(finalPayslip.newRegime.deductions)}
                </p>
              </div>


              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: 600,
                  height: 50,
                  padding: "10px 10px",
                  margin: "10px 0"
                  // border: "solid #dcdfe8 1px",
                }}
              >
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 700,
                    width: 120,
                  }}
                >
                  Takehome
                </p>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                >
                  {config.numformatter(finalPayslip.oldRegime.takeHome)}
                </p>
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                  }}
                >
                  {config.numformatter(finalPayslip.newRegime.takeHome)}
                </p>
              </div>
              {monthly === "true" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 600,
                      height: 50,
                      padding: "10px 10px",
                      // border: "solid #dcdfe8 1px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 24,
                        fontWeight: 700,

                        width: 135,
                      }}
                    >
                      CTC
                    </p>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      {config.numformatter(ctc)}
                    </p>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      {config.numformatter(ctc)}
                    </p>
                  </div>
                </>
              )}

            </div>
          </div>
        )}
      </Skeleton>
    </Modal>
  );
};

export default Chapter6A;
