import { Checkbox, Drawer, notification, Radio, Table, Tabs } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import InputNumberComponent from "../../../InputLibrary/InputNumberComponent/InputNumberComponent";
import organisation from "../../../../redux/modules/organisation";
import { getFullToolTip } from "../../../../utils/common";
import { checkFinancialYear } from "../../../../utils/config";
import { HeaderComponent } from "../../../Common";
import { NormalButton } from "../../../ComponentLibrary";
import { getFreeConcessionalFood, postFreeConcessionalFood } from '../../../../apis/perqs'
import ".././perqs.css";

const { TabPane } = Tabs
const { month, financialYear } = checkFinancialYear()

const FreeConcessionalFood = ({ user, employeeList }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [type, setType] = useState(0);
  const [open, setOpen] = useState(false);
  const [requestBody, setRequestBody] = useState({
    id: "",
    bifurcatedAmount: 0,
    recoveryAmount: 0,
    isFullRecovery: false,
    isRecovery: true,
  });


  useEffect(() => {
    getFreeConcessionalFood(user.organisationId)
      .then((res) => {
        let data = res.data.data;
        setData(data);
      });
  }, [user, refresh]);


  const onSave = () => {
    setLoading(true)
    postFreeConcessionalFood({ ...requestBody, financialYear, month, type }).then(res => {
      if (res.data.success) {
        notification.success({ message: "Data Saved Successfully!" })
        setOpen(false)
      } else {
        notification.error({ message: "Error while Saving The Data!" })
      }
    }).catch(err => {
      notification.error({ message: "Error while Saving The Data!" })
    })
    setRefresh(!refresh)
    setLoading(false)
  }
  const configureEmployeePerq = (record) => {
    showDrawer();
    setRequestBody({ ...requestBody, ...record, isRecovery: record["perquisites"] && record["perquisites"][month] ? record["perquisites"][month]['isRecovery'] : true });
  }
  const columns = [
    {
      title: "",
      dataIndex: "flag",
      render: (flag) => {
        return <Checkbox checked={flag} disabled={true} />
      }
    },
    {
      title: "ID",
      dataIndex: "employeeId",
    },
    {
      title: "Name",
      dataIndex: "displayName",
    },
    {
      title: "Cost/ month (in INR)",
      dataIndex: "id",
      render: (id, data) => {
        return <p>{data.flag ? data.bifurcatedAmount ? data.bifurcatedAmount : 0 : "Not Defined"}</p>
      }
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record, index) => {
        return (
          <NormalButton
            buttonText={"Configure"}
            type={15}
            onClick={() => configureEmployeePerq(record)}
          ></NormalButton>
        );
      },
    },
  ];


  const employeeListDiv = (
    <>
      <h1 className="h5-bold-20">Employee List</h1>
      <div className="section">
        <Table
          columns={columns}
          dataSource={data[type] ?? []}
        />
      </div>
    </>
  );



  const showDrawer = () => {
    setOpen(true);
  };


  const onClose = () => {
    setRequestBody({
      id: "",
      bifurcatedAmount: 0,
      recoveryAmount: 0,
      isFullRecovery: false,
      isRecovery: true,
    });
    setOpen(false);
  };

  const info = (
    <div className="section">
      {employeeListDiv}
    </div>
  );



  const fieldSection = (
    <div className="section">
      <div style={{ marginTop: "2rem" }}>
        <p className="subtitle3-med-16">
          {
            "Amount paid by employer (per month) ?"
          }
        </p>
        <InputNumberComponent labeltext={"Amount"} value={requestBody.bifurcatedAmount} onChange={(e) => {
          setRequestBody({
            ...requestBody,
            bifurcatedAmount: e
          })
        }} width={"100%"} />
      </div>
      {
        type === 1 &&
        <>
          <div style={{ marginTop: "1.5rem" }}>
            <p className="subtitle3-med-16">How do you want to recover the amount from your employee (per month) ?</p>
            <Radio.Group onChange={(e) => {
              setRequestBody({
                ...requestBody,
                isFullRecovery: e.target.value,
                recoveryAmount: e.target.value ? requestBody.bifurcatedAmount : 0
              });
            }} value={requestBody.isFullRecovery} className="section-top">
              <Radio value={true}>Fully
                {getFullToolTip("Expenses incurred by employer (per month)")}
              </Radio>
              <Radio value={false}>Custom</Radio>
            </Radio.Group>
          </div>
          {
            !requestBody.isFullRecovery && <div style={{ marginTop: "2rem" }}>
              <InputNumberComponent labeltext={"Amount"} value={requestBody.recoveryAmount}
                disabled={requestBody.isFullRecovery}
                onChange={(value) => {
                  setRequestBody({
                    ...requestBody,
                    recoveryAmount: value
                  })
                }} width={"100%"} />
            </div>
          }
          <div style={{ marginTop: "2rem" }}>
            <p className="subtitle3-med-16">Do you want recover the amount from employee’s salary</p>
            <Radio.Group onChange={(e) => {
              setRequestBody({
                ...requestBody,
                isRecovery: e.target.value
              });
            }} value={requestBody.isRecovery} className="section-top">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
        </>
      }
      <div className="flex" style={{ marginTop: "5rem" }} >
        <NormalButton
          type={13}
          onClick={onSave}
          buttonText="Save Changes"
        />
        <NormalButton type={5} onClick={() => { setOpen(false) }} buttonText="Cancel" />
      </div>
    </div>
  );



  return (

    <section className="site-layout">
      <HeaderComponent name="Free and concessional food and non alcoholic beverages"/>
      <div className="app-main-content-container paper-wrapper">
        <Spinner spinning={loading}>
          <div>
            <h2 className="app-heading-2 font-weight-700">
              Free and concessional food and non alcoholic beverages
            </h2>
          </div>
        </Spinner>
        <div className="domestic-servants-tabs">
          <Tabs onChange={(e) => setType(parseInt(e))} defaultActiveKey="0" style={{ marginLeft: "10px" }}>
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">Food provided during office hours</span>
                </div>
              }
              key="0"
            >
              {info}
            </TabPane>
            <TabPane
              tab={
                <div className="tabs">
                  <span className="tab-name">Other</span>
                </div>
              }
              key="1"
            >
              {info}
            </TabPane>
          </Tabs>
        </div>
      </div>
      <Drawer
        width={500}
        title={<><p className="subtitle3-med-16">{requestBody.displayName}</p><p className="subtitle4">{requestBody.employeeId}</p></>}
        placement="right"
        onClose={onClose}
        visible={open}
      >
        <div className="section">{fieldSection}</div>
      </Drawer>
    </section>
  );
};

const FreeConcessionalFoodPerqs = connect(
  (state) => ({
    user: state.auth.user,
    organisationSettings: state.organisation.organisationSettings,
    orgLoading: state.organisation.loading,
    employeeList: state.organisation.employeeList,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(FreeConcessionalFood);

export default FreeConcessionalFoodPerqs;