import { InfoCircleOutlined } from "@ant-design/icons";

const InfoBtn = ({ infoText, link, className }) => {

    return <div className="info-container" >
        <button className="info-btn " style={{ display: "flex", background: "none", alignItems: "center", justifyContent: "center" }}><InfoCircleOutlined /> </button>
        <div className={className ? "info-card " + className : "info-card"} style={{ zIndex: 99 }}>{infoText}{link && <a rel={"noreferrer"} target={"_blank"} href={link}>{" Know more"}</a>}</div>
    </div>;
};

export default InfoBtn;