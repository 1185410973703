const { calculateHRAExemption, calculateAllowanceExemption } = require("./exemptions_calculation")

const rebalance = (payslips, user, houseRent, previousEmployement, currentMonth) => {
    let totalExemption = 0;
    let totalGrossIncome = 0;
    let totalTaxPaid = 0;
    let totalEmployeePF = 0;
    let totalEmployerPF = 0;
    let totalEmployeeESI = 0;
    let totalEmployerESI = 0;
    let totalEmployeeLWF = 0;
    let totalEmployerLWF = 0;
    let totalGratuity = 0;
    let totalMonthlyCTC = 0;
    let totalEarnings = 0;
    let totalHRA = 0;
    let totalLTA = 0;
    let totalFOODAllowances = 0;
    let totalAllowances = 0;
    let regimeOptedStatus = user.regimeOptedStatus;
    let hraObject = {}
    let exemptions = {
        HRA: 0,
        LTA: 0,
        FOOD: 0
    }
    payslips.forEach(v => {
        if (parseInt(v.month) < currentMonth) {
            let val = v.finalPayslip;
            totalEarnings += val.totalEarnings;
            totalMonthlyCTC += val.monthlyCTC;
            totalEmployeePF += parseInt(val.employee_PF) ? val.employee_PF : 0
            totalEmployerPF += parseInt(val.employer_PF) ? val.employer_PF : 0
            totalEmployeeESI += parseInt(val.employee_ESI) ? val.employee_ESI : 0
            totalEmployerESI += parseInt(val.employer_ESI) ? val.employer_PF : 0
            totalEmployeeLWF += parseInt(val.employee_LWF) ? val.employee_LWF : 0
            totalEmployerLWF += parseInt(val.employer_LWF) ? val.employer_LWF : 0
            totalGratuity += val.gratuity
            totalHRA += val.HRA
            totalLTA += val.LTA
            totalGrossIncome += parseInt(val.totalEarnings) ? val.totalEarnings : 0
            totalFOODAllowances += parseInt(val.FOOD) ? val.FOOD : 0
            totalTaxPaid += (regimeOptedStatus === 1 ? val.newRegime.incomeTax : val.oldRegime.incomeTax)
            let exemption = 0;
            let allowances = 0;
            Object.keys(val.otherAllowances).forEach(all => {
                allowances += val.otherAllowances[all];
                let calculatedExemption = calculateAllowanceExemption(all, val.otherAllowances[all], user);
                if(exemptions[all] === undefined && calculatedExemption > 0) {
                    exemptions[all] = 0
                }
                if(calculatedExemption > 0) {
                    exemptions[all] += calculatedExemption
                }

                exemption += calculatedExemption
            })
            let hra = calculateHRAExemption(val, houseRent)
            if(hra.hraData) {
                hraObject[parseInt(v.month)] = hra.hraData;
            }
            exemption += hra.hraExemption
            exemption += val.LTA;
            exemption += parseInt(val.FOOD) ? val.FOOD : 0;

            exemptions.HRA += hra.hraExemption;
            exemptions.LTA += val.LTA;
            exemptions.FOOD += parseInt(val.FOOD) ? val.FOOD : 0;

            totalExemption += exemption;
            totalAllowances += allowances;
        }
    })

    exemptions.Total = totalExemption;

    // exeption rebalance structure should be a positive number
    totalExemption += previousEmployement.totalExemption + previousEmployement.exemptionReverse
    totalGrossIncome += previousEmployement.totalGrossSalary
    totalTaxPaid += previousEmployement.totalTaxDeducted
    totalEmployeePF += previousEmployement.totalPFPaid

    return {
        totalExemption,
        totalHRA,
        totalFOODAllowances,
        totalGrossIncome,
        totalEarnings,
        totalEmployeePF,
        totalEmployerPF,
        totalTaxPaid,
        totalEmployeeESI,
        totalEmployerESI,
        totalEmployeeLWF,
        totalEmployerLWF,
        totalMonthlyCTC,
        totalAllowances,
        totalGratuity,
        totalLTA,
        hraObject,
        exemptions
    }
}

module.exports = {
    rebalance
}