import axios from "./index";

export async function getPricePlans() {
  return axios.get("org/chargebee/planprice");
}

export async function addPlanTemporarily(planData) {
  return axios.post("org/chargebee/addplan", { planData: planData });
}

export async function getPlanDetails(organisationId) {
  return axios.get(`org/chargebee/getplan/${organisationId}`);
}

export async function addBillingInfo(
  organisationID,
  billingAddress,
  employeeCount,
  billingInterval
) {
  return axios.post("org/chargebee/update/billingdetails", {
    organisationID,
    billingAddress,
    employeeCount,
    billingInterval
  });
}

export async function createSubscription(organisationID) {
  return axios.post("org/chargebee/create", { organisationID });
}

export async function createOrderInRazorpay(organisationID, customer) {
  return axios.post("razorpayorder/create", { organisationID, customer });
}

export async function makePayment(organisationID, customer) {
  return axios.post("razorpay/payment", { organisationID, customer });
}

export async function sendpaymentResponse(response, organisationID) {
  return axios.post("razorpay/payment/response", { response, organisationID });
}
