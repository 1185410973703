import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CATEGORIES, LOG_CATEGORY_LIST } from "../../constants/logs";
import { HeaderComponent } from "../Common";
import IndividualLogs from "./IndividualLogs";
import userActivityBgImage from "../../assets/dummyPages/dashboardActivityBgImage.png"
import TrialPage from "../Common/TrialComponent";

const { TabPane } = Tabs;

const Log = ({ category, user, isTrial }) => {
  const [selectedLogs, setSelectedLogs] = useState(null);
  useEffect(() => {
    if (
      (user.role === "org" || user.role === "employee") &&
      LOG_CATEGORY_LIST[user.role][category]
    ) {
      setSelectedLogs([...LOG_CATEGORY_LIST[user.role][category]]);
    }
  }, [category, user]);
  useEffect(() => {
    if (category)
      document.title = `${category.substring(0, 1).toUpperCase() + category.substring(1, category.length)} | Activities`;
  }, [category]);
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Keep track of all your actions."} bgImage={userActivityBgImage}
    /> :
      <div className="rebalance">
        <HeaderComponent show={false} name={`User Activity`} />
        <div className="card-container font-size-global-24">
          {selectedLogs && (
            <Tabs type="card">
              {selectedLogs.map((val, index) => (
                <TabPane tab={CATEGORIES[val]} key={index}>
                  <IndividualLogs category={val} user={user} />
                </TabPane>
              ))}
            </Tabs>
          )}
        </div>
      </div>
  );
};

const Logs = connect(
  state => ({
    user: state.auth.user,
    category: state.auth.selectedCategory,
    isTrial: state.auth.user.isTrial,

  }),
  _dispatch => ({})
)(Log);

export default Logs;
