import React from "react";
import { DECLARATION_CONSTANT } from "../../../constants/taxProjections";

function ChapterVI({ category, subCategory, totalValue }) {
  const RowData = ({ name = "", section = "", declared = 0, exempted = 0 }) => {
    if (name === "totalPFPaidForPreviousEmployment") {
      return (
        <tr>
          <th>{"Contribution To PF In Previous Employer"}</th>
          <th>{section}</th>
          <td>{declared}</td>
          <td>{exempted}</td>
        </tr>
      )
    } else {
      return (
        <tr>
          <th>{DECLARATION_CONSTANT[name]}</th>
          <th>{section}</th>
          <td>{declared}</td>
          <td>{exempted}</td>
        </tr>
      )
    }
  };
  return (
    <React.Fragment>
      <tr>
        <th>Investment</th>
        <th>Section</th>
        <th>Declared</th>
        <th>Exempted</th>
      </tr>
      {category &&
        Object.keys(category).map((v, i) => (
          <React.Fragment>
            <RowData
              key={i}
              name={""}
              section={v}
              declared={category[v].declared}
              exempted={category[v].exempted}
            />

            {subCategory && subCategory[v] &&
              Object.keys(subCategory[v]).length > 0 &&
              Object.keys(subCategory[v]).map((v_i, j) => (
                <RowData
                  key={j}
                  name={v_i}
                  section={v}
                  declared={subCategory[v][v_i].declared}
                  exempted={subCategory[v][v_i].exempted}
                />
              )
              )}
          </React.Fragment>
        ))}
      <tr>
        <th>Total</th>
        <th></th>
        <th>{totalValue.declared}</th>
        <th>{totalValue.exempted}</th>
      </tr>
    </React.Fragment>
  );
}

export default ChapterVI;
