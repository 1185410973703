import { useState, useEffect } from "react";
import { Row, Col, notification, Drawer } from "antd";
import { updateCredentials } from "../../../../apis/user";
import { NormalButton } from "../../../ComponentLibrary";
import blueEditIcon from "../../../../assets/icons/blueEditIcon.png";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import PasswordComponent from "../../../InputLibrary/PasswordComponent/PasswordComponent";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";

const Credentials = ({ user, credentials, updateInfo, updateOnboarding }) => {
  const [loading, setLoading] = useState(false);
  const [credentialInfo, setCredentialInfo] = useState({
    aadhaar: "",
    passportNumber: "",
    UAN: "",
    UANPassword: "",
    drivingLicenseNumber: "",
    SSN: "",
    PFNo: "",
    voterID: "",
    esicNumber: "",
  });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    credentailUpdate();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const credentailUpdate = () => {
    if (credentials) {
      setCredentialInfo({
        aadhaar: credentials.aadhaar || "",
        passportNumber: credentials.passportNumber || "",
        UAN: credentials.UAN || "",
        UANPassword: credentials.UANPassword || "",
        drivingLicenseNumber: credentials.drivingLicenseNumber || "",
        PFNo: credentials.PFNo || "",
        voterID: credentials.voterID || "",
        esicNumber: credentials.esicNumber || "",
      });
    }
  };

  const submitForm = async () => {
    setLoading(true);
    let res = await updateCredentials({ ...credentialInfo, id: user._id });
    if (res.data.success) {
      await updateInfo();
      await updateOnboarding("personalCredentials");
      notification.success({
        message: "Credentials Information Has Been Updated Successfully",
      });
    } else {
      credentailUpdate();
      notification.error({
        message: "Sorry unable to update the credential information",
      });
    }
    setLoading(false);
    onClose()
  };

  const updateField = (field, value) => {
    setCredentialInfo({
      ...credentialInfo,
      [field]: value,
    });
  };

  const cred = (
    <>
      <div className="media-header">
        <h1 className="font-weight-global-700 font-size-global-24">Personal Credentials</h1>
      </div>
      <Row gutter={[55, 0]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="company-address-individual-data-container modal-margin-top-add-global-2">
            <InputFieldComponent
              name="aadhaar"
              labeltext={"Aadhar Number"}
              onChange={(e) => {
                if (
                  (parseInt(e.target.value) && e.target.value.length <= 12) ||
                  e.target.value === ""
                ) {
                  setCredentialInfo({
                    ...credentialInfo,
                    aadhaar: isNaN(parseInt(e.target.value))
                      ? ""
                      : parseInt(e.target.value),
                  });
                }
              }}
              value={credentialInfo.aadhaar}
              block={true}
            ></InputFieldComponent>
          </div>
          <div className="company-address-individual-data-container">
            <InputFieldComponent
              value={credentialInfo.drivingLicenseNumber}
              labeltext={"Driving License Number"}
              onChange={(e) => {
                updateField(
                  "drivingLicenseNumber",
                  e.target.value.toUpperCase()
                );
              }}
              name="drivingLicenseNumber"
              block={true}
            ></InputFieldComponent>
          </div>
          <div className="company-address-individual-data-container">
            <InputFieldComponent
              value={credentialInfo.PFNo}
              labeltext={"PF Number"}
              onChange={(e) => {
                updateField("PFNo", e.target.value.toUpperCase());
              }}
              name="PFNo"
              block={true}
            ></InputFieldComponent>
          </div>
          <div className="company-address-individual-data-container">
            <InputFieldComponent
              value={credentialInfo.UAN}
              labeltext={"UAN"}
              onChange={(e) => {
                updateField("UAN", e.target.value.toUpperCase());
              }}
              name="UAN"
              block={true}
            ></InputFieldComponent>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="company-address-individual-data-container modal-margin-top-add-global-2">
            <InputFieldComponent
              value={credentialInfo.passportNumber}
              labeltext={"Passport Number"}
              onChange={(e) => {
                updateField("passportNumber", e.target.value.toUpperCase());
              }}
              name="passportNumber"
              block={true}
            ></InputFieldComponent>
          </div>
          <div className="company-address-individual-data-container">
            <InputFieldComponent
              value={credentialInfo.voterID}
              labeltext={"Voter ID"}
              onChange={(e) => {
                updateField("voterID", e.target.value.toUpperCase());
              }}
              name="voterID"
              block={true}
            ></InputFieldComponent>
          </div>
          <div className="company-address-individual-data-container">
            <InputFieldComponent
              value={credentialInfo.esicNumber}
              labeltext={"ESIC Number"}
              onChange={(e) => {
                updateField("esicNumber", e.target.value.toUpperCase());
              }}
              name="esicNumber"
              block={true}
            ></InputFieldComponent>
          </div>
          <div className="company-address-individual-data-container">
            <PasswordComponent
              value={credentialInfo.UANPassword}
              labeltext={"UAN Password"}
              onChange={(e) => {
                updateField("UANPassword", e.target.value);
              }}
              name="UANPassword"
              className="single-employee-profile-password"
              block={true}
              visibilityToggle={true}
            ></PasswordComponent>
          </div>
        </Col>
      </Row>
    </>
  );

  return (
    <div
      className="company-address-container app-white-card"
      id="credentials"
      style={{ marginTop: "20px", height: "auto" }}
    >
      <div className="media-header">
        <h1 className="font-size-global-24 font-weight-global-700">Personal Credentials</h1>

        <NormalButton
          type={12}
          icon={blueEditIcon}
          buttonText="Edit"
          onClick={showDrawer}
        ></NormalButton>
      </div>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Aadhaar Number</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{credentialInfo.aadhaar}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Driving License Number</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{credentialInfo.drivingLicenseNumber}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">PF Number</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{credentialInfo.PFNo}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">UAN</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{credentialInfo.UAN}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Passport Number</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{credentialInfo.passportNumber}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Voter ID</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{credentialInfo.voterID}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">ESIC Number</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{credentialInfo.esicNumber}</p>
        </Col>
      </Row>
      {/* <Row>
        <Col span={10}>
          <h3 className="fields-heading">UAN Password</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{credentialInfo.UANPassword}</p>
        </Col>
      </Row> */}
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">UAN Password</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">*******</p>
        </Col>
      </Row>
      <Drawer
        title="Edit"
        placement="right"
        onClose={onClose}
        visible={visible}
        size={"large"}
      >
        <Spinner spinning={loading}>
          <div className="drawer">
            {cred}
            <div style={{ marginLeft: "-0.5em" }}>
              <NormalButton
                buttonText="save changes"
                type={13}
                onClick={submitForm}
              />
              <NormalButton buttonText="Cancel" type={5} onClick={onClose} />
            </div>
          </div>
        </Spinner>
      </Drawer>
    </div>
  );
};

export default Credentials;
