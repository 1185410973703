import { Table, Spin } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { leaveLogForManager } from "../../../../apis/employee";
import { NormalButton } from "../../../ComponentLibrary";

const History = ({ user }) => {
  const [history, setHistory] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [loading, setLoading] = useState(true);


  const getLeaveRecords = async () => {
    //loader is turned on here
    setLoading(true)
    let approved = await leaveLogForManager(`${user.PAN}/1`);
    let rejected = await leaveLogForManager(`${user.PAN}/2`);
    setHistory([...approved.data, ...rejected.data]);
    //loader is turned off here
    setLoading(false)
  };
  useEffect(() => {
    getLeaveRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isRefresh]);
  const status = ["", "Approved", "Rejected"]
  const columns = [
    {
      title: "Name",
      dataIndex: ["user", "displayName"],
      key: "name",
    },
    {
      title: "Type",
      dataIndex: ["leaveType", "name"],
      key: "type",
    },
    {
      title: "Leave",
      dataIndex: "startDate",
      key: "leave",
      render: (data, row) => <p>{`${new Date(data).toDateString()} - ${new Date(row.endDate).toDateString()}`}</p>
    },
    {
      title: "Requested",
      dataIndex: "createdAt",
      key: "requested",
      render: (data) => <p>{new Date(data).toDateString()}</p>
    },
    {
      title: "Approved",
      dataIndex: "status",
      key: "approved",
      render: (data) => <p className={`approved-${data}`}>{status[data]}</p>
    },

    {
      title: "Notes",
      dataIndex: "commets",
      key: "notes",
      render: (data) => <p>{data ? data : "-"}</p>

    },

    {
      title: "Days",
      dataIndex: "duration",
      key: "days",
    },
  ];
  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }

  return (
    <div className="report-container">
      <div className="report-header-container">
        <p className="report-heading">History</p>
        <NormalButton
          buttonText={"Refresh"}
          type={13}
          onClick={startRefresh}
        />
      </div>
      <Spin spinning={loading} delay={500} size={"large"}>

        <Table pagination={history.length <= 8 ? false : { pageSize: 8 }} columns={columns} dataSource={history} />
      </Spin>

    </div>
  );
};

const LeaveHistory = connect((state) => ({
  user: state.auth.user,
}))(History);

export default LeaveHistory;
