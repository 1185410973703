const { message } = require("antd");
const { endpoint, attendance } = require("./env");
const moment = require("moment");
const { rebalance } = require("../rebalancing/rebalancing");

const reimbursementTypes = {
  fuelReimbursement: "Fuel Reimbursement",
  travelReimbursement: "Travel Reimbursement",
  businessExpenseReimbursement: "Business Expense Reimbursement",
  medicalExpenseReimbursement: "Medical Expense Reimbursement",
  foodReimbursement: "Food Reimbursement",
  otherReimbursement: "Other Reimbursement"
};
const getFinancialYearFromDate = (providedDate) => {
  let currentDate = new Date(providedDate);
  let month = currentDate.getMonth();
  let year = currentDate.getFullYear();
  let date = currentDate.getDate();
  let financialYear = "";
  if (month < 3) {
    financialYear = `${year - 1}-${year}`;
  } else {
    financialYear = `${year}-${year + 1}`;
  }
  return { month, year, financialYear, date };
};
const totalMonthCalculation = (
  month,
  payslips,
  previousEmployement,
  isCurrentYearJoinee,
  monthOfJoining
) => {
  let currentMonth = month - 1;
  let monthRemaining = 0;
  let totalMonth = 0;

  // Configuration variables
  let monthInAYear = 12;
  let financialYearEndMonth = 3;
  // let totalPreviousMonths = 12;

  if (currentMonth < financialYearEndMonth) {
    monthRemaining = financialYearEndMonth - currentMonth;
  } else {
    monthRemaining = financialYearEndMonth + (monthInAYear - currentMonth);
  }

  // Calculate depresiation months
  totalMonth = monthRemaining;
  // totalPreviousMonths -= monthRemaining;

  // if (
  //   previousEmployement.isPreviouslyEmployed &&
  //   previousEmployement.totalGrossSalary
  // ) {
  //   totalPreviousMonths -= previousEmployement.monthWorked;
  // }

  // payslips.forEach(val => {
  //   if (val.month < currentMonth) {
  //     totalPreviousMonths--;
  //   }
  // });

  monthRemaining = totalMonth;
  // totalMonth += totalPreviousMonths;

  return { totalMonth, monthRemaining };
};

const checkFinancialYear = () => {
  let currentDate = new Date();
  let month = currentDate.getMonth();
  let year = currentDate.getFullYear();
  let date = currentDate.getDate();
  let financialYear = "";
  if (month < 3) {
    financialYear = `${year - 1}-${year}`;
  } else {
    financialYear = `${year}-${year + 1}`;
  }
  return { month, year, financialYear, date };
};
const isLeapYear = (year) => {
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    return true;
  }
  return false;
};

// eslint-disable-next-line
const newallowances = {
  tiffinLunchDinner: {
    applicable: false,
    amount: 0,
    label: "Food",
  },
  mobile: {
    applicable: false,
    amount: 0,
    label: "Mobile",
  },
  telephone: {
    applicable: false,
    amount: 0,
    label: "Telephone",
  },
  dearness: {
    applicable: false,
    amount: 0,
    label: "Dearness",
  },
  overtime: {
    applicable: false,
    amount: 0,
    label: "Overtime",
  },
  fixedMedical: {
    applicable: false,
    amount: 0,
    label: "Fixed Medical Allowance",
  },
  project: {
    applicable: false,
    amount: 0,
    label: "Project Allowance",
  },
  LTA: {
    applicable: false,
    amount: 0,
    label: "LTA",
  },
  childrenEducation: {
    applicable: false,
    amount: 0,
    label: "Children's Education",
    meta: {
      childCount: 0,
    },
  },
  hostelExpenditure: {
    applicable: false,
    amount: 0,
    label: "Hostel Expenditure",
    meta: {
      childCount: 0,
    },
  },
  transportDivyangPerson: {
    applicable: false,
    amount: 0,
    label: "Transport for Divyang",
    meta: {
      isBlindOrDeafAndDumbOrHasDisabilityInLowerExtremeties: true,
    },
  },
  transportBusinesss: {
    applicable: false,
    amount: 0,
    label: "Transport Business",
  },
  conveyance: {
    applicable: false,
    amount: 0,
    label: "Conveyance",
  },
  tourTravel_transfer: {
    applicable: false,
    amount: 0,
    label: "Tour Travel",
  },
  daily: {
    applicable: false,
    amount: 0,
    label: "Daily Allowance",
  },
  helper: {
    applicable: false,
    amount: 0,
    label: "Helper Assistance",
  },
  research: {
    applicable: false,
    amount: 0,
    label: "Research",
  },
  uniform: {
    applicable: false,
    amount: 0,
    label: "Uniform",
  },
  transportRegular: {
    applicable: false,
    amount: 0,
    label: "Regular Transport",
  },
};

const getWeekList = (month, year) => {
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  let totalDays = daysInMonth[month];
  if (month === 1 && isLeapYear(year)) {
    totalDays = 29;
  }
  let dateDay = new Date(`${month + 1}/1/${year}`);
  let weekStart = 1;
  let weeks = [];
  let dayPointer = 1;
  for (dayPointer; dayPointer <= totalDays; dayPointer++) {
    dateDay = dateDay % 7;

    if (dateDay === 6 || dayPointer === totalDays) {
      weeks.push({
        weekStart,
        weekEnd: dayPointer
      });
    }
    if (dateDay === 0) {
      weekStart = dayPointer;
    }
    dateDay++;
  }
  return weeks;
};

const numberOfDaysExceptSundayAndSaturDay = (startDate, endDate) => {
  let start = new Date(startDate);
  let end = new Date(endDate);
  if (start === "invalid date" || end === "invalid date") {
    return -1;
  }
  let startDay = start.getDate();
  let startMonth = start.getMonth();
  let startYear = start.getFullYear();
  let endDay = end.getDate();
  let endMonth = end.getMonth();
  let endYear = end.getFullYear();
  let numberOfDays = 0;
  if (startYear === endYear) {
    if (startMonth === endMonth) {
      //simple calculation of for loop will be happening here
      for (let i = startDay; i <= endDay; i++) {
        let tempDate = `${startMonth + 1}/${i}/${startYear}`;
        let tempDay = new Date(tempDate);
        let temDayOfWeek = tempDay % 7;
        if (!(temDayOfWeek === 6 || temDayOfWeek === 0)) {
          numberOfDays++;
        }
      }
    } else {
      // Two loops will be runned here
      // One is from start date to end of the month
      // check next month should be the end month ro else get calculate the days till the end of the month repeat untill the end month
      // Use While loop here
      let tempMonth = startMonth;
      let tempDate = startDay;
      const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      let totalDays = daysInMonth[tempMonth];
      if (tempMonth === 1 && isLeapYear(startYear)) {
        totalDays = 29;
      }
      while (!(endMonth === tempMonth && tempDate === endDay + 1)) {
        let tempPartialDay = `${tempMonth + 1}/${tempDate}/${startYear}`;
        let tempDay = new Date(tempPartialDay);
        let temDayOfWeek = tempDay % 7;
        if (!(temDayOfWeek === 6 || temDayOfWeek === 0)) {
          numberOfDays++;
        }

        if (tempDate === endDate && tempMonth === endMonth) {
          break;
        }
        if (tempDate === totalDays) {
          tempDate = 1;
          tempMonth++;
          totalDays = daysInMonth[tempMonth];
          if (tempMonth === 1 && isLeapYear(startYear)) {
            totalDays = 29;
          }
        } else {
          tempDate++;
        }
      }
    }
  } else {
    // lets code it extensively we will apply leave in december to february
    // Here three cases will be handled
    let tempMonth = startMonth;
    let tempDate = startDay;
    let tempYear = startYear;
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let totalDays = daysInMonth[tempMonth];
    if (tempMonth === 1 && isLeapYear(tempYear)) {
      totalDays = 29;
    }
    while (
      !(
        endMonth === tempMonth &&
        tempDate === endDay + 1 &&
        tempYear === endYear
      )
    ) {
      let tempPartialDay = `${tempMonth + 1}/${tempDate}/${tempYear}`;
      let tempDay = new Date(tempPartialDay);
      let temDayOfWeek = tempDay % 7;
      if (!(temDayOfWeek === 6 || temDayOfWeek === 0)) {
        numberOfDays++;
      }

      if (
        tempDate === endDate &&
        tempMonth === endMonth &&
        tempYear === endYear
      ) {
        break;
      }
      if (tempDate === totalDays) {
        tempDate = 1;
        tempMonth++;
        if (tempMonth === 12) {
          tempMonth = 0;
          tempYear++;
        }
        totalDays = daysInMonth[tempMonth];
        if (tempMonth === 1 && isLeapYear(tempYear)) {
          totalDays = 29;
        }
      } else {
        tempDate++;
      }
    }
  }
  return numberOfDays;
};

module.exports = {
  checkCurrMonth: new Date().getMonth(),
  getWeekList,
  isLeapYear,
  numberOfDaysExceptSundayAndSaturDay,
  statusList: {
    0: "pending",
    1: "approved",
    2: "rejected",
  },
  leaveCreditFrequency: {
    0: "Yearly",
    1: "Half Yearly",
    2: "Quarterly",
    3: "Monthly",
  },
  newallowances: {
    tiffinLunchDinner: {
      applicable: false,
      amount: 0,
      label: "Food",
    },
    mobile: {
      applicable: false,
      amount: 0,
      label: "Mobile",
    },
    telephone: {
      applicable: false,
      amount: 0,
      label: "Telephone",
    },
    dearness: {
      applicable: false,
      amount: 0,
      label: "Dearness",
    },
    overtime: {
      applicable: false,
      amount: 0,
      label: "Overtime",
    },
    fixedMedical: {
      applicable: false,
      amount: 0,
      label: "Fixed Medical Allowance",
    },
    project: {
      applicable: false,
      amount: 0,
      label: "Project Allowance",
    },
    LTA: {
      applicable: false,
      amount: 0,
      label: "LTA",
    },
    childrenEducation: {
      applicable: false,
      amount: 0,
      label: "Children's Education",
      meta: {
        childCount: 0,
      },
    },
    hostelExpenditure: {
      applicable: false,
      amount: 0,
      label: "Hostel Expenditure",
      meta: {
        childCount: 0,
      },
    },
    transportDivyangPerson: {
      applicable: false,
      amount: 0,
      label: "Transport for Divyang",
      meta: {
        isBlindOrDeafAndDumbOrHasDisabilityInLowerExtremeties: true,
      },
    },
    transportBusinesss: {
      applicable: false,
      amount: 0,
      label: "Transport Business",
    },
    conveyance: {
      applicable: false,
      amount: 0,
      label: "Conveyance",
    },
    tourTravel_transfer: {
      applicable: false,
      amount: 0,
      label: "Tour Travel",
    },
    daily: {
      applicable: false,
      amount: 0,
      label: "Daily Allowance",
    },
    helper: {
      applicable: false,
      amount: 0,
      label: "Helper Assistance",
    },
    research: {
      applicable: false,
      amount: 0,
      label: "Research",
    },
    uniform: {
      applicable: false,
      amount: 0,
      label: "Uniform",
    },
    transportRegular: {
      applicable: false,
      amount: 0,
      label: "Regular Transport",
    },
  },


  daylist: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  dayListObject: {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
  },
  shortMonthList: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  monthDays: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
  attendance,
  findOneInArrayOfObjects: (array, field, value) => {
    return array.find((al) => {
      return al[field] === value;
    });
  },
  isValidDate: (d) => {
    return !(Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d));
  },
  checkFinancialYear,
  getFinancialYear: (providedDate) => {
    let month = Number(providedDate.format("M") - 1);
    let year = Number(providedDate.format("YYYY"));
    let date = Number(providedDate.format("D"));
    let financialYear = "";
    if (month < 3) {
      financialYear = `${year - 1}-${year}`;
    } else {
      financialYear = `${year}-${year + 1}`;
    }
    return { month, year, financialYear, date };
  },
  getFinancialYearFromDate,
  moolStructureLimit: {
    tiffinLunchDinner: 2000,
    childrenEducation: 200,
    hostelExpenditure: 600,
    transportRegular: 3200,
    transportBusiness: 1600,
  },
  daysLeft: (tillDate) => {
    var eventdate = moment(tillDate);
    var todaysdate = moment();
    return eventdate.diff(todaysdate, "days");
  },
  monthList: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  reimbursementTypes,
  accessLabels: {
    reimbursement: "Reimbursement Access",
    leave: "Leave Access",
    reports: "Reports Access",
    payroll: "Payroll Access",
    salaryRegister: "Salary Register Report Access",
    taxDeduction: "Tax Deduction Report Access",
    pfRegister: "PF Report Report Access",
    professionalTax: "Professional Tax Report Access",
    bankAdvice: "Back Advice Report Access",
    salary: "Salary Registery Access",
    monthlyCTC: "Monthly CTC Regisery Access",
    yearlyCTC: "Yearly CTC Registery Access",
    YTDEarning: "Year Till Date Registery Access",
    statutoryBonus: "Bonus Report Access",
    reim: "Reimburesement Report Access",
    monthClose: "Month Close Report Access",
    compliances: "Complaince Report Access",
  },
  defaultUserAccess: {
    reimbursement: {
      access: false,
      subAccess: {},
    },
    leave: {
      access: false,
      subAccess: {},
    },
    compliances: {
      access: false,
      subAccess: {},
    },
    reports: {
      access: false,
      subAccess: {
        salaryRegister: {
          access: false,
          subAccess: {},
        },
        taxDeduction: {
          access: false,
          subAccess: {},
        },
        pfRegister: {
          access: false,
          subAccess: {},
        },
        professionalTax: {
          access: false,
          subAccess: {},
        },
        bankAdvice: {
          access: false,
          subAccess: {},
        },
        salary: {
          access: false,
          subAccess: {},
        },
        monthlyCTC: {
          access: false,
          subAccess: {},
        },
        yearlyCTC: {
          access: false,
          subAccess: {},
        },
        YTDEarning: {
          access: false,
          subAccess: {},
        },
        statutoryBonus: {
          access: false,
          subAccess: {},
        },
        reim: {
          access: false,
          subAccess: {},
        },
        monthClose: {
          access: false,
          subAccess: {},
        },
      },
    },
    payroll: {
      access: false,
      subAccess: {},
    },
  },
  validateAccess: (access) => {
    const { reimbursement, reports, payroll, leave } = access;
    if (
      !reimbursement ||
      !reports ||
      !leave ||
      !payroll ||
      !reports.subAccess
    ) {
      return false;
    }
    const {
      salaryRegister,
      taxDeduction,
      pfRegister,
      professionalTax,
      bankAdvice,
      salary,
      monthlyCTC,
      yearlyCTC,
      YTDEarning,
      statutoryBonus,
      reim,
      monthClose,
    } = reports.subAccess;
    return !(
      typeof salaryRegister !== "object" ||
      typeof taxDeduction !== "object" ||
      typeof pfRegister !== "object" ||
      typeof professionalTax !== "object" ||
      typeof bankAdvice !== "object" ||
      typeof salary !== "object" ||
      typeof monthlyCTC !== "object" ||
      typeof yearlyCTC !== "object" ||
      typeof YTDEarning !== "object" ||
      typeof statutoryBonus !== "object" ||
      typeof reim !== "object" ||
      typeof monthClose !== "object"
    );
  },
  finMonthList: [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ],

  singlePriceWithComma: (price) => {
    return price.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  },

  finMonthNumbers: [
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    0,
    1,
    2,
  ],
  rootUrl: endpoint,
  salaryPayslipFreezeStartDate: 5,
  searchEmployee: (employeeList, PAN) => {
    try {
      let res = employeeList.filter((val) => val.PAN === PAN);
      if (res.length === 1) {
        return res[0];
      }
      return null;
    } catch (e) {
      return null;
    }
  },
  numformatter: function numFormatter(num) {
    num = Math.ceil(num);
    num = num + "" || "";
    let n1 = num.split(".");
    let n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    return num;
  },

  sumOfAll: (obj) => {
    return Object.keys(obj).reduce(
      (sum, key) => sum + parseFloat(obj[key] || 0),
      0
    );
  },
  validateEmail: function (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  deductionData: [
    {
      section: "80C",
      declaration: "fiveYearFixedDepositScheduledBank",
      label: "5 Years of Fixed Deposit in Scheduled Bank",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "childrenTuitionFees",
      label: "Children Tuition Fees",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "depositInNSC",
      label: "Deposit in National Savings Certificate",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "depositInNSS",
      label: "Deposit in National Savings Schemes",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "depositInPostOfficeSavingsSchemes",
      label: "Deposit in Post Office Savings Schemes",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "equityLinkedSavingsScheme",
      label: "Equity Linked Savings Scheme ( ELSS )",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "infrastructureBonds",
      label: "Infrastructure Bonds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "interestReinvestedNSC",
      label: "Interest on NSC Reinvested",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "kisanVikasPatra",
      label: "Kisan Vikas Patra (KVP)",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "lifeInsurancePremium",
      label: "Life Insurance Premium",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "longTermInfrastructureBonds",

      label: "Long term Infrastructure Bonds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "mutualFunds",

      label: "Mutual Funds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "ruralBondsNABARD",
      label: "NABARD Rural Bonds",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "nationalPensionScheme",

      label: "National Pension Scheme",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "schemeNHB",

      label: "NHB Scheme",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "postOfficeTimeDepositForFiveYears",

      label: "Post office time deposit for 5 years",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "pradhanMantriSurakshaBimaYojana",

      label: "Pradhan Mantri Suraksha Bima Yojana",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "publicProvidentFund",

      label: "Public Provident Fund",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "repaymentOfHousingLoanPrincipal",

      label: "Repayment of Housing loan(Principal amount)",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "stampDutyAndRegistrationCharges",

      label: "Stamp duty and Registration charges",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "sukanyaSamriddhiYojana",

      label: "Sukanya Samriddhi Yojana",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80C",
      declaration: "unitLinkedInsurancePremium",

      label: "Unit Linked Insurance Premium (ULIP)",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80C - Investments",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80CCC",
      declaration: "contributionPensionFund",

      label: "Contribution to Pension Fund",
      bucket: "sec_80C_OtherThanPF",
      bucketLabel: "Section 80CCC - Insurance Premium",
      deductionPresentationGroup: "Section 80C, 80CCC, 80CCD",
    },
    {
      section: "80D",
      declaration: "preventiveHealthCheckupDependantParents",

      label: "Preventive Health Checkup - Dependant Parents",
      limit: 5000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalBillsVerySeniorCitizen",

      label: "Medical Bills - Very Senior Citizen",
      limit: 50000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremiumBelow60",

      label: "Medical Insurance Premium For Person Below 60",
      limit: 25000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremium60YearsAndAbove",

      label: "Medical Insurance Premium For Person 60 Years And Above",
      limit: 50000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremiumDependantParentsBelow60",
      label: "Medical Insurance Premium - Dependant Parents Below 60",
      limit: 25000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "medicalInsurancePremiumDependantParents60YearsAbove",
      label: "Medical Insurance Premium - Dependant Parents 60 Years and Above",
      limit: 50000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80D",
      declaration: "preventiveHealthCheckUp",
      label: "Preventive Health Check-up",
      limit: 5000,
      bucket: "sec_80D",
      bucketLabel: "Section 80D - Medical Insurance",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DD",
      declaration: "medicalTreatmentOrInsuranceHandicappedDependant",
      label: "Medical Treatment / Insurance of handicapped Dependant",
      limit: 75000,
      bucket: "sec_80DD_generalDisability",
      bucketLabel: "Section 80DD - Disabled Dependent",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DD",
      declaration: "medicalTreatmentOrInsuranceSevereHandicappedDependant",

      label: "Medical Treatment / Insurance of handicapped Dependant (Severe)",
      limit: 125000,
      bucket: "sec_80DD_severeDisability",
      bucketLabel: "Section 80DD - Disabled Dependent",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DDB",
      declaration: "medicalTreatmentSpecifiedDisease",

      label: "Medical Treatment ( Specified Disease only )",
      limit: 40000,
      bucket: "sec_80DDB_Others",
      bucketLabel: "Section 80DDB - Medical Expenditure",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DDB",
      declaration: "medicalTreatmentSpecifiedDiseaseSeniorCitizen",

      label: "Medical Treatment (Specified Disease only)- Senior Citizen",
      limit: 100000,
      bucket: "sec_80DDB_Senior",
      bucketLabel: "Section 80DDB - Medical Expenditure",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80DDB",
      declaration: "medicalTreatmentSpecifiedDiseaseVerySeniorCitizen",

      label: "Medical Treatment (Specified Disease only)- Very Senior Citizen",
      limit: 100000,
      bucket: "sec_80DDB_Senior",
      bucketLabel: "Section 80DDB - Medical Expenditure",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80U",
      declaration: "permanentPhysicalDisabilityBelow40Prcnt",

      label: "Permanent Physical disability (Below 40%)",
      limit: 75000,
      bucket: "sec_80U_generalDisability",
      bucketLabel: "Section 80U - Physical Disability",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80U",
      declaration: "permanentPhysicalDisabilityAbove40Prcnt",

      label: "Permanent Physical disability (Above 40%)",
      limit: 125000,
      bucket: "sec_80U_criticalDisability",
      bucketLabel: "Section 80U - Physical Disability",
      deductionPresentationGroup: "Section 80D, 80DD, 80DDB, 80U",
    },
    {
      section: "80CCD1(B)",
      declaration: "contributionNPS2015_CCD1B",

      label: "80CCD1(B) - Contribution to NPS 2015",
      limit: 50000,
      bucket: "sec_80CCD_1B",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80CCG",
      declaration: "rajivGandhiEquityScheme",

      label: "80CCG - Rajiv Gandhi Equity Scheme",
      limit: 25000,
      bucket: "sec_80CCG",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80E",
      declaration: "interestLoanHigherSelfEducation",

      label: "80E - Interest on Loan of higher Self education",
      bucket: "sec_80E",
      deductionPresentationGroup: "Other Sections",
    },

    {
      section: "80EE",
      declaration: "additionalInterestOnHousingLoanBorrowed1stApr2016",

      label:
        "80EE - Additional Interest on housing loan borrowed as on 1st Apr 2016",
      limit: 50000,
      bucket: "sec_80EE",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80EEA",
      declaration: "additionalInterestOnHousingLoanBorrowed1stApr2019",

      label:
        "80EEA - Additional Interest on Housing loan borrowed as on 1st Apr 2019",
      limit: 150000,
      bucket: "sec_80EE",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80EEB",
      declaration: "interestOnElectricVehicleBorrowed1stApr2019",

      label: "80EEB - Interest on Electric Vehicle borrowed as on 1st Apr 2019",
      limit: 150000,
      bucket: "sec_80EE",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80TTA",
      declaration:
        "interestSavingsAccountDepositFDPostOfficeCooperativeSociety",

      label:
        "80TTA - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society",
      limit: 10000,
      bucket: "sec_80TTA",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80TTB",
      declaration:
        "interestSavingsAccountDepositFDPostOfficeCooperativeSocietySeniorCitizen",

      label:
        "80TTB - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society for Senior Citizen",
      limit: 50000,
      bucket: "sec_80TTB",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donation100PrcntExemption",

      label: "80G - Donation - 100% Exemption",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donation50PrcntExemption",

      label: "80G - Donation - 50% Exemption",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donationChildrenEducation",

      label: "80G - Donation - Children Education",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "80G",
      declaration: "donationPoliticalParties",

      label: "80G - Donation - Political Parties",
      bucket: "sec_80G",
      deductionPresentationGroup: "Other Sections",
    },
    {
      section: "24B",
      declaration: "preConstructionHomeLoanInterest",
      amount: 0,
      documents: [],
      label: "Home Loan Interest - Pre Construction Period",
      bucket: "sec_24B",
      bucketLabel: "Section 24B - Loan",
      deductionPresentationGroup: "Section 24B",
    },
    {
      section: "24B",
      declaration: "currentYearHomeLoanInterest",
      amount: 0,
      documents: [],
      label: "Home Loan Interest - Current Year ",
      bucket: "sec_24B",
      bucketLabel: "Section 24B - Loan",
      deductionPresentationGroup: "Section 24B",
    },
  ],
  deducationList: {
    "80CCG": {
      declaration: "rajivGandhiEquityScheme",
      sectionType: "Savings",
      sectionName: "Section 80CCG ",
      subSections: {
        rajivGandhiEquityScheme: "80CCG - Rajiv Gandhi Equity Scheme",
      },
    },
    "80EEA": {
      declaration: "additionalInterestOnHousingLoanBorrowed1stApr2019",
      sectionName: "Section 80EEA ",
      sectionType: "Savings",
      subSections: {
        additionalInterestOnHousingLoanBorrowed1stApr2019:
          "80EEA - Additional Interest on Housing loan borrowed as on 1st Apr 2019",
      },
    },
    "80EEB": {
      declaration: "additionalInterestOnHousingLoanBorrowed1stApr2019",
      sectionName: "Section 80EEB ",
      sectionType: "Savings",
      subSections: {
        additionalInterestOnHousingLoanBorrowed1stApr2019:
          "80EEA - Additional Interest on Housing loan borrowed as on 1st Apr 2019",
      },
    },
    "24B": {
      sectionName: "Section 24B",
      sectionType: "Home Loan Interest",
      subSections: {
        preConstructionHomeLoanInterest:
          "Home Loan Interest - Pre Construction Period",
        currentYearHomeLoanInterest: "Home Loan Interest - Current Year ",
      },
    },
    "80TTB": {
      declaration: "rajivGandhiEquityScheme",
      sectionName: "Section 80TTB ",
      sectionType: "Savings",
      subSections: {
        interestSavingsAccountDepositFDPostOfficeCooperativeSocietySeniorCitizen:
          "80TTB - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society for Senior Citizen",
      },
    },
    "80C": {
      sectionName: "Section 80C ",
      sectionType: "Investment",
      subSections: {
        employeeContributionToPF: "Employee Contribution to Provident Fund",
        fiveYearFixedDepositScheduledBank:
          "5 Years of Fixed Deposit in Scheduled Bank",
        childrenTuitionFees: "Children Tuition Fees",
        depositInNSC: "Deposit in National Savings Certificate",
        depositInNSS: "Deposit in National Savings Schemes",
        depositInPostOfficeSavingsSchemes:
          "Deposit in Post Office Savings Schemes",
        equityLinkedSavingsScheme: "Equity Linked Savings Scheme ( ELSS )",
        infrastructureBonds: "Infrastructure Bonds",
        interestReinvestedNSC: "Interest on NSC Reinvested",
        kisanVikasPatra: "Kisan Vikas Patra (KVP)",
        lifeInsurancePremium: "Life Insurance Premium",
        longTermInfrastructureBonds: "Long term Infrastructure Bonds",
        mutualFunds: "Mutual Funds",
        ruralBondsNABARD: "NABARD Rural Bonds",
        nationalPensionScheme: "National Pension Scheme",
        schemeNHB: "NHB Scheme",
        postOfficeTimeDepositForFiveYears:
          "Post office time deposit for 5 years",
        pradhanMantriSurakshaBimaYojana: "Pradhan Mantri Suraksha Bima Yojana",
        publicProvidentFund: "Public Provident Fund",
        repaymentOfHousingLoanPrincipal:
          "Repayment of Housing loan(Principal amount)",
        stampDutyAndRegistrationCharges: "Stamp duty and Registration charges",
        sukanyaSamriddhiYojana: "Sukanya Samriddhi Yojana",
        unitLinkedInsurancePremium: "Unit Linked Insurance Premium (ULIP)",
      },
    },
    "80D": {
      sectionName: "Section 80D",
      sectionType: "Medical Insurance",
      subSections: {
        preventiveHealthCheckupDependantParents:
          "Preventive Health Checkup - Dependant Parents",
        medicalBillsVerySeniorCitizen: "Medical Bills - Very Senior Citizen",
        medicalInsurancePremiumBelow60:
          "Medical Insurance Premium For Person Below 60",
        medicalInsurancePremium60YearsAndAbove:
          "Medical Insurance Premium For Person 60 Years And Above",
        medicalInsurancePremiumDependantParentsBelow60:
          "Medical Insurance Premium - Dependant Parents Below 60",
        medicalInsurancePremiumDependantParents60YearsAbove:
          "Medical Insurance Premium - Dependant Parents 60 Years and Above",
        preventiveHealthCheckUp: "Preventive Health Check-up",
      },
    },
    "80E": {
      sectionName: "Section 80E",
      sectionType: "Education Loan",
      subSections: {
        interestLoanHigherSelfEducation:
          "80E - Interest on Loan of higher Self education",
      },
    },
    "80EE": {
      sectionName: "Section 80EE",
      sectionType: "Home Loan Interest",
      subSections: {
        additionalInterestOnHousingLoanBorrowed1stApr2016:
          "80EE - Additional Interest on housing loan borrowed as on 1st Apr 2016",
      },
    },
    "80TTA": {
      sectionName: "Section 80TTA",
      sectionType: "Interest on savings account",
      subSections: {
        interestSavingsAccountDepositFDPostOfficeCooperativeSociety:
          "80TTA - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society",
      },
    },
    "80G": {
      sectionName: "Section 80G ",
      sectionType: "Donation to charitable Organisation",
      subSections: {
        donation100PrcntExemption: "80G - Donation - 100% Exemption",
        donation50PrcntExemption: "80G - Donation - 50% Exemption",
        donationChildrenEducation: "80G - Donation - Children Education",
        donationPoliticalParties: "80G - Donation - Political Parties",
      },
    },
    "80CCC": {
      sectionName: "Section 80CCC",
      sectionType: "Contribution to pension plan/ Annuity fund ",
      subSections: {
        contributionPensionFund: "Contribution to Pension Fund",
      },
    },
    "80CCD(2)": {
      sectionName: "Section 80CCD(2)",
      sectionType: "Employer contribution in NPS",
      subSections: {},
    },
    "80CCD1(B)": {
      sectionName: "Section 80CCD(2)",
      sectionType: "Employer contribution in NPS",
      subSections: {},
    },
    "80DD": {
      sectionName: "Section 80DD",
      sectionType: "Disabled Dependent Deduction",
      subSections: {
        medicalTreatmentOrInsuranceHandicappedDependant:
          "Medical Treatment / Insurance of handicapped Dependant",
        medicalTreatmentOrInsuranceSevereHandicappedDependant:
          "Medical Treatment / Insurance of handicapped Dependant (Severe)",
      },
    },
    "80U": {
      sectionName: "Section 80U",
      sectionType: "Disability",
      subSections: {
        permanentPhysicalDisabilityBelow40Prcnt:
          "Permanent Physical disability (Below 40%)",
        permanentPhysicalDisabilityAbove40Prcnt:
          "Permanent Physical disability (Above 40%)",
      },
    },
    "80DDB": {
      sectionName: "Section 80DDB",
      sectionType: "Disability",
      subSections: {
        medicalTreatmentSpecifiedDisease:
          "Medical Treatment ( Specified Disease only )",
        medicalTreatmentSpecifiedDiseaseSeniorCitizen:
          "Medical Treatment (Specified Disease only)- Senior Citizen",
        medicalTreatmentSpecifiedDiseaseVerySeniorCitizen:
          "Medical Treatment (Specified Disease only)- Very Senior Citizen",
      },
    },
  },
  deducationTabsLists: [
    {
      key: "1",
      sectionName: "Section 80C ",
      sectionType: "Investment (80C)",
      sectionInfo:
        "Section 80C includes contributions to LIC / Insurance premium, PPF, EPF, NSC, ELSS Mutual Funds, Children school fees, Payment of Principal in Housing Loan & other eligible items.",
      Category: "80C",
      infoBtnText: "Tax deduction upto Rs.1.5 Lakhs / Financial Year.",
      status: "NA",
    },
    {
      key: "2",
      sectionName: "Section 80D",
      sectionType: "Medical Insurance (80D)",
      sectionInfo:
        "Deductions for Medical Insurance or Preventive Health Check-Up fees or Medical Expenditure incurred by you.",
      Category: "80D",
      infoBtnText:
        "Tax deduction upto Rs. 25000 / financial year (or) Rs. 75000 / financial year (If parent’s age is above 60 years).",
      status: "NA",
    },
    {
      key: "3",
      sectionName: "Section 80E",
      sectionType: "Education Loan (80E)",
      sectionInfo:
        "You can claim tax deduction on the interest paid on an education loan for higher studies Interest paid by you on loan taken out by you. Loan can be for yourself or your spouse or your children",
      Category: "80E",
      infoBtnText: "Tax deduction upto the extent of interest paid.",
      status: "NA",
    },
    {
      key: "4",
      sectionName: "Section 80EE",
      sectionType: "Home Loan Interest (80EE)",
      sectionInfo:
        "Read about the eligibility criteria of Section 80EE: Deduction under section 80EE is available on interest on loan taken from a financial institution on your first house purchased. This loan should be sanctioned between April 1st, 2016 to March 31st 2017. The value of the house can be upto Rs. 50 Lakhs and the total home loan can be upto Rs. 35 Lakhs.",
      Category: "80EE",
      infoBtnText: "Tax deduction upto Rs. 50,000 / financial year.",
      status: "NA",
    },
    {
      key: "5",
      sectionName: "Section 80TTA",
      sectionType: "Interest on savings account (80TTA)",
      sectionInfo:
        "Declared interest income from Saving Bank account and Post office deposits.",
      Category: "80TTA",
      infoBtnText:
        "Tax deduction upto Rs. 10,000 / Financial year on savings accounts deposits held in a post office, bank, or cooperative society.",
      status: "NA",
    },
    {
      key: "6",
      sectionName: "Section 80G ",
      sectionType: "Donation to charitable Organisation (80G)",
      sectionInfo:
        "The government requires itemized details of donations for Section 80G.",
      Category: "80G",
      infoBtnText: "Tax deduction on donations made to only prescribed funds.",
      status: "NA",
    },
    {
      key: "7",
      sectionName: "Section 80CCC",
      sectionType: "Contribution to pension plan/ Annuity fund (80CCC) ",
      sectionInfo:
        "You can claim tax deduction on the interest paid on an education loan for higher studies Interest paid by you on loan taken out by you. Loan can be for yourself or your spouse or your children",
      Category: "80CCC",
      infoBtnText:
        "Tax deduction as per the employee's contribution to a pension plan bought from a life insurance company which will give annuities to the employee.",
      status: "NA",
    },
    {
      key: "8",
      sectionName: "Section 80CCD1(B)",
      sectionType: "Employee contribution in NPS (80CCD1(B))",
      sectionInfo: "Employee contribution towards NPS (upto 10% of Salary).",
      Category: "80CCD1(B)",
      infoBtnText:
        "Tax deduction upto Rs. 50,000 / financial year on upon investing in National Pension Scheme.",
      status: "NA",
    },
    {
      key: "9",
      sectionName: "Section 80U",
      sectionType: "Disability",
      label: "Disability (80U)",
      sectionInfo:
        "Deductions for Medical Insurance or Preventive Health Check-Up fees or Medical Expenditure incurred by you.",
      Category: "80U",
      infoBtnText:
        "A deduction of Rs. 75,000 is allowed for people with disabilities, and Rs. 1,25,000 deduction for people with severe disability.",
      status: "NA",
    },
    {
      key: "10",
      sectionName: "Section 80DD",
      sectionType: "Disabled Dependent Deduction (80DD)",
      label: "Disabled Dependent Deduction (80DD)",
      sectionInfo:
        "Section 80C includes contributions to LIC / Insurance premium, PPF, EPF, NSC, ELSS Mutual Funds, Children school fees, Payment of Principal in Housing Loan & other eligible items.",
      Category: "80DD",
      infoBtnText:
        "Tax deduction for the expenses incurred on the medical treatment, nursing, training or rehabilitation of a disabled dependent.",
      status: "NA",
    },

    {
      key: "11",
      sectionName: "Section 80DDB",
      sectionType: "Disability",
      label: "Medical Treatment (80DDB)",
      sectionInfo:
        "Deductions for Medical Insurance or Preventive Health Check-Up fees or Medical Expenditure incurred by you.",
      Category: "80DDB",
      infoBtnText:
        "Tax deduction on expenses incurred for medical treatment of specified diseases or ailments.",
      status: "NA",
    },
    {
      key: "12",
      sectionName: "Section 80EEA",
      sectionType: "Home Loan Interest (80EEA)",
      sectionInfo:
        "Income tax deduction on home loan interest paid.",
      Category: "80EEA",
      infoBtnText:
        "Income tax deduction on home loan interest paid.",
      status: "NA",
    },
    {
      key: "13",
      sectionName: "Section 80EEB",
      sectionType: " Interest on electric vehicle (80EEB)",
      sectionInfo:
        "Income tax deduction on electric vehicle loan interest paid.",
      Category: "80EEB",
      infoBtnText:
        "Income tax deduction on electric vehicle loan interest paid.",
      status: "NA",
    },
    {
      key: "14",
      sectionName: "Section 24B",
      sectionType: " Home Loan Interest (24B)",
      sectionInfo:
        "Income tax deduction on home loan interest paid for multiple houses.",
      Category: "24B",
      infoBtnText:
        "Income tax deduction on home loan interest paid for multiple houses.",
      status: "NA",
    },
  ],


  createPayslipJSON: (data) => {
    const {
      deductions,
      allowances,
      user,
      customAllowances,
      organisationSettings,
      hra,
      ctc,
      monthClose,
      group,
      getGraphDataByKey,
      sendGraphData,
      currentMonth,
      state,
      isPercentageStructure,
      previousEmployement,
      payslips,
      percentage,
      advanceTaxConfig,
      perquisites,
    } = data;
    let rebalanceStructure = {
      hra:
        previousEmployement && previousEmployement.hra
          ? previousEmployement.hra
          : 0,
      basic:
        previousEmployement && previousEmployement.basic
          ? previousEmployement.basic
          : 0,
      totalGrossSalary:
        previousEmployement && previousEmployement.totalGrossSalary
          ? previousEmployement.totalGrossSalary
          : 0,
      totalExemption:
        previousEmployement && previousEmployement.totalExemption
          ? previousEmployement.totalExemption
          : 0,
      totalTaxDeducted:
        previousEmployement && previousEmployement.totalTaxDeducted
          ? previousEmployement.totalTaxDeducted
          : 0,
      totalPFPaid:
        previousEmployement && previousEmployement.totalPFPaid
          ? previousEmployement.totalPFPaid
          : 0,
      exemptionReverse:
        previousEmployement && previousEmployement.exemptionReverse
          ? previousEmployement.exemptionReverse
          : 0,
      meta:
        previousEmployement && previousEmployement.meta
          ? previousEmployement.meta
          : [],
      monthWorked:
        previousEmployement && previousEmployement.monthWorked
          ? previousEmployement.monthWorked
          : 0,
      isPreviouslyEmployed:
        previousEmployement && previousEmployement.isPreviouslyEmployed
          ? previousEmployement.isPreviouslyEmployed
          : false,
    };

    let rebalancing = rebalance(
      payslips,
      user,
      hra,
      rebalanceStructure,
      currentMonth - 1
    );
    // TODO: Need to look into this process.

    let salaryAdjustDays = 0;

    const { dateOfJoining } = user.currentOrganisationMeta;
    let joiningDate = new Date(dateOfJoining);
    let {
      financialYear: employeeJoiningFinancialYear,
      month: joiningMonth,
      date: joiningDay,
    } = getFinancialYearFromDate(joiningDate);
    let { financialYear } = checkFinancialYear();
    let isCurrentYearJoinee = false;

    if (employeeJoiningFinancialYear === financialYear) {
      if (joiningMonth === currentMonth - 1) {
        salaryAdjustDays = joiningDay - 1;
      }
      isCurrentYearJoinee = true;
    }

    let { totalMonth, monthRemaining } = totalMonthCalculation(
      currentMonth,
      payslips,
      rebalanceStructure,
      isCurrentYearJoinee
    );

    totalMonth = totalMonth <= 0 ? 1 : totalMonth;
    monthRemaining = monthRemaining <= 0 ? 1 : monthRemaining;
    monthRemaining = organisationSettings.autoRebalance ? monthRemaining : 12;

    let previousData = {
      pfPaid:
        organisationSettings.autoRebalance && previousEmployement
          ? rebalancing.totalEmployeePF
          : 0,
      taxPaid: organisationSettings.autoRebalance
        ? rebalancing.totalTaxPaid
        : 0,
      monthRemaining,
      previousExemption: organisationSettings.autoRebalance
        ? rebalancing.totalExemption
        : 0,
      previousEmployeePFPaid: organisationSettings.autoRebalance
        ? rebalancing.totalEmployeePF -
        (previousEmployement ? previousEmployement.totalPFPaid : 0)
        : 0,
      previousGrossIncome: rebalancing.totalGrossIncome,
      totalMonth: totalMonth,
    };

    let declarations = deductions;
    let declarationBucketNames = {};
    let chapter6A = [];
    let lopDebits = 0;
    let monthCloseData = [];
    let monthCloseDetails = {};

    // Month close configuration
    monthClose.forEach((mclose) => {
      if (mclose.details === "LOP_Debit") {
        lopDebits += parseFloat(mclose.amount);
      } else {
        let name = mclose.subDetails ? mclose.subDetails : mclose.details;
        monthCloseDetails[name] =
          (monthCloseDetails[name] ? monthCloseDetails[name] : 0) +
          parseFloat(mclose.amount);

        monthCloseData.push({
          type: mclose.type,
          taxable: mclose.taxable,
          name,
          amount: monthCloseDetails[name],
        });
      }
    });

    // Declaration Configuration
    declarations.forEach((d) => {
      let newDecl = { name: d.declaration, amount: parseInt(d.amount) };
      chapter6A.push(newDecl);
      declarationBucketNames[d.bucket] = {};
    });

    // Allowances configuration and data classification
    let allowance = Object.keys(allowances)
      .filter((k) => {
        return allowances[k].applicable;
      })
      .map((all) => {
        return {
          name: all,
          amount: allowances[all].amount
            ? parseInt(allowances[all].amount)
            : parseInt(allowances[all].actualExpense),
          actualExpense: parseInt(allowances[all].actualExpense)
            ? parseInt(allowances[all].actualExpense)
            : 0,
          meta: allowances[all].meta ? allowances[all].meta : {},
        };
      });

    let perks = Object.keys(perquisites)
      .filter((k) => {
        return perquisites[k].applicable;
      })
      .map((all) => {
        return {
          name: all,
          amount: perquisites[all].amount
            ? parseInt(perquisites[all].amount)
            : parseInt(perquisites[all].actualExpense),
          actualExpense: parseInt(perquisites[all].actualExpense)
            ? parseInt(perquisites[all].actualExpense)
            : 0,
        };
      });

    // Custom allowances configuration and data classification
    let customAllowance = Object.keys(customAllowances)
      .filter((k) => {
        return customAllowances[k].applicable;
      })
      .map((call) => {
        return {
          name: allowance,
          amount: parseInt(customAllowances[call].actualExpense)
            ? parseInt(customAllowances[call].actualExpense)
            : 0,
          actualExpense: parseInt(customAllowances[call].actualExpense)
            ? parseInt(customAllowances[call].actualExpense)
            : 0,
          meta: customAllowances[call].meta ? customAllowances[call].meta : {},
        };
      });

    // House rent configuration
    let houseRent = {
      status: hra.rent ? hra.applicable : false,
      isMetro: hra.rent ? hra.isMetro : false,
      rent: hra.rent ? hra.rent : 0,
    };
    let dataCustomPoint = 0;
    dataCustomPoint = parseFloat(dataCustomPoint);

    let pfEnabled =
      organisationSettings.pfEnabled !== undefined
        ? organisationSettings.pfEnabled
        : true;

    let lwfEligible =
      organisationSettings.lwfEligible !== undefined
        ? organisationSettings.lwfEligible
        : true;

    let esicEnabled =
      organisationSettings.esicEnabled !== undefined
        ? organisationSettings.esicEnabled
        : true;
    let gratuity =
      organisationSettings.gratuity !== undefined
        ? organisationSettings.gratuity
        : false;
    let isStatutoryBonusIncludedInSalary =
      organisationSettings.isStatutoryBonusIncludedInSalary !== undefined
        ? organisationSettings.isStatutoryBonusIncludedInSalary
        : true;
    let isStatutoryBonusIncludeHRA =
      organisationSettings.isStatutoryBonusIncludeHRA !== undefined
        ? organisationSettings.isStatutoryBonusIncludeHRA
        : false;
    let isStatutoryBonusIncludedInPF =
      organisationSettings.isStatutoryBonusIncludedInPF !== undefined
        ? organisationSettings.isStatutoryBonusIncludedInPF
        : true;

    if (group !== null && Object.keys(group).length > 0) {
      gratuity =
        group.groupSettings.gratuity !== undefined
          ? group.groupSettings.gratuity
          : organisationSettings.gratuity;
      pfEnabled =
        group.groupSettings.pfEnabled !== undefined
          ? group.groupSettings.pfEnabled
          : organisationSettings.pfEnabled;
    }

    if (user.pfEnabled !== undefined) {
      pfEnabled = user.pfEnabled;
    }

    // Organisation Settings Data Configuration
    let organisationPFContribution =
      organisationSettings.organisationPFContribution !== undefined
        ? organisationSettings.organisationPFContribution
        : false;
    let employeePFContribution =
      organisationSettings.employeePFContribution !== undefined
        ? organisationSettings.employeePFContribution
        : false;

    let pfContributionType =
      organisationSettings.pfContributionType !== undefined
        ? organisationSettings.pfContributionType
        : true;
    let orgPFContribution =
      organisationSettings.orgPFContribution !== undefined
        ? organisationSettings.orgPFContribution
        : false;

    if (user.groupId === "intern") {
      customAllowance = [];
      allowance = [];
      pfEnabled = false;
      houseRent = {
        status: false,
        isMetro: false,
        rent: 0,
      };
      organisationPFContribution = false;
      employeePFContribution = false;
    }

    /**
     * Percentage based calculation to select percentage structure accordingly
     */
    let percentStructure = {};
    let percentStructureEnabled = false;
    if (isPercentageStructure) {
      percentStructureEnabled = true;
      if (percentage && Object.keys(percentage).length > 0) {
        percentStructure = percentage;
      } else if (Object.keys(group).length > 0) {
        if (Object.keys(group.percentage).length > 0) {
          percentStructure = group.percentage;
        } else if (organisationSettings.percentageStructure) {
          percentStructure = organisationSettings.percentageStructure;
        } else {
          percentStructure = {};
          percentStructureEnabled = false;
        }
      } else if (organisationSettings.percentageStructure) {
        percentStructure = organisationSettings.percentageStructure;
      } else {
        percentStructure = {};
        percentStructureEnabled = false;
      }
    }

    let advanceTax = 0;
    let isAdvanceTaxAboveIncomeTax = true;

    if (advanceTaxConfig && Object.keys(advanceTaxConfig).length > 0) {
      advanceTax = advanceTaxConfig.advanceTax;
      isAdvanceTaxAboveIncomeTax = advanceTaxConfig.isAdvanceTaxAboveIncomeTax;
    }

    return {
      advanceTax,
      isAdvanceTaxAboveIncomeTax,

      regimeOptedStatus: user.regimeOptedStatus === 1 ? 1 : 0,
      customPoint: dataCustomPoint,
      organisationPFContribution,
      employeePFContribution,
      pfContributionType,
      orgPFContribution,
      salaryAdjustDays,
      getGraphDataByKey:
        getGraphDataByKey !== undefined ? getGraphDataByKey : false,
      graphDataKey: getGraphDataByKey
        ? ctc.selectedIndex
          ? ctc.selectedIndex
          : 0
        : 0,
      sendGraphData,
      sendDebugInfo: false,
      lwfEligible,
      gender: "M",
      stateAbbrev: state ? state : "MH",
      proffTaxEnabled: user.groupId === "intern" ? false : true,
      percentStructure,
      percentStructureEnabled,
      pfEnabled,
      esicEnabled,
      previousData,
      annualCTC: parseInt(ctc.costToCompany[currentMonth - 1].ctc),
      feesOrCommissions: [],
      lopDebits,
      isStatutoryBonusIncludedInSalary,
      isStatutoryBonusIncludeHRA,
      isStatutoryBonusIncludedInPF,
      isEmployerContributionIncludedInCTC:
        typeof ctc.isEmployerContributionIncludedInCTC === "boolean"
          ? ctc.isEmployerContributionIncludedInCTC
          : true,
      ctcVariableComponent: {
        status: true,
        percentage: ctc.costToCompany[currentMonth - 1].variablePercentage
          ? parseFloat(ctc.costToCompany[currentMonth - 1].variablePercentage)
          : 0,
        variableCompensationReceived: 0,
        isOverAndAboveCTC:
          group !== null && Object.keys(group).length > 0
            ? group.groupSettings.variableAboveCTC
            : organisationSettings.variableAboveCTC,
      },
      gratuity,
      houseRent,
      monthClose: monthCloseData,
      currentMonth,
      allowances: isPercentageStructure ? [] : allowance,
      employerContributionToNPS_80CCD_2: 0,
      bonuses: [],
      reimbursements: [],
      customAllowances: isPercentageStructure ? [] : customAllowance,
      perks,
      chapter6A,
    };
  },
  uploadType: {
    proofs: "mooldocuments",
    support: "moolsupport",
    dhan: "mooldhan",
    reim: "moolreim",
    payslip: "moolpayslip",
    userDocs: "userDocs",
  },
  beforeFileUpload: function (file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  },
  states: {
    "Andhra Pradesh": "AP",
    "Arunachal Pradesh": "AR",
    Assam: "AS",
    Bihar: "BR",
    Chattisgarh: "CG",
    Delhi: "DL",
    Goa: "GA",
    Gujarat: "GJ",
    Haryana: "HR",
    "Himachal Pradesh": "HP",
    "Jammu And Kashmir": "JK",
    Jharkhand: "JH",
    Karnataka: "KA",
    Kerala: "KL",
    "Lakshadweep Islands": "LD",
    "Madhya Pradesh": "MP",
    Maharashtra: "MH",
    Manipur: "MN",
    Meghalaya: "ML",
    Mizoram: "MZ",
    Nagaland: "NL",
    Odisha: "OD",
    Pondicherry: "PY",
    Punjab: "PB",
    Rajasthan: "RJ",
    Sikkim: "SK",
    "Tamil Nadu": "TN",
    Telangana: "TS",
    Tripura: "TR",
    "Uttar Pradesh": "UP",
    Uttarakhand: "UK",
    WestBengal: "WB",
    Chandigarh: "CH",
    Ladakh: "LA",
    "Other Territory": "OT",
    "Dadra Nagar Haveli": "DN",
    DamanDiu: "DD",
    "Andaman NicobarIslands": "AN",
  },
  statesCodes: {
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CG: "Chattisgarh",
    DL: "Delhi",
    GA: "Goa",
    GJ: "Gujarat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JK: "Jammu & Kashmir",
    JH: "Jharkhand",
    KA: "Karnataka",
    KL: "Kerala",
    LD: "Lakshadweep",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OD: "Odisha",
    PY: "Pondicherry",
    PB: "Punjab",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "Tamil Nadu",
    TS: "Telangana",
    TR: "Tripura",
    UP: "Uttar Pradesh",
    UK: "Uttarakhand",
    WB: "WestBengal",
    CH: "Chandigarh",
    LA: "Ladakh",
    OT: "Other Territory",
    DN: "Dadra & Nagar Haveli",
    DD: "Daman & Diu",
    AN: "Andaman & Nicobar"
  },
  allowances: {
    tiffinLunchDinner: {
      applicable: false,
      amount: 0,
      label: "Food",
      infoBtnText:
        "A limit of Rs.100 per working day which comes to an average of Rs.2200 per month for 22 working days per month.",
      status: "Non-taxable",
    },
    mobile: {
      applicable: false,
      amount: 0,
      label: "Mobile",
      infoBtnText:
        "No specific upper limit on mobile allowance as per the law.",
      status: "Non-taxable",
    },
    telephone: {
      applicable: false,
      amount: 0,
      label: "Telephone",
      infoBtnText:
        "No specific upper limit on mobile allowance as per the law.",
      status: "Non-taxable",
    },
    dearness: {
      applicable: false,
      amount: 0,
      label: "Dearness",
      infoBtnText:
        "Dearness Allowance is the cost-of-living adjustment allowance which the government pays to the employees of the public sector as well as pensioners of the same.",
      status: "Taxable",
    },
    overtime: {
      applicable: false,
      amount: 0,
      label: "Overtime",
      infoBtnText: "",
      status: "",
    },
    fixedMedical: {
      applicable: false,
      amount: 0,
      label: "Fixed Medical Allowance",
      infoBtnText:
        "Dearness Allowance is the cost-of-living adjustment allowance which the government pays to the employees of the public sector as well as pensioners of the same.",
      status: "Taxable",
    },
    project: {
      applicable: false,
      amount: 0,
      label: "Project Allowance",
      infoBtnText: "",
      status: "",
    },
    LTA: {
      applicable: false,
      amount: 0,
      label: "LTA",
      infoBtnText:
        "No specific upper limit on LTA as per the law, but can be availed twice in  4 calendar year and one in block of 2 calendar years.",
      status: "Non-taxable",
    },
    childrenEducation: {
      applicable: false,
      amount: 0,
      label: "Children's Education",
      meta: {
        childCount: 0,
      },
      infoBtnText:
        "Up to Rs. 100 per month per child up to a maximum of 2 children",
      status: "Non-taxable",
    },
    hostelExpenditure: {
      applicable: false,
      amount: 0,
      label: "Hostel Expenditure",
      meta: {
        childCount: 0,
      },
      infoBtnText:
        "Up to Rs. 300 per month per child up to a maximum of 2 children",
      status: "Non-taxable",
    },
    transportDivyangPerson: {
      applicable: false,
      amount: 0,
      label: "Transport for Divyang",
      meta: {
        isBlindOrDeafAndDumbOrHasDisabilityInLowerExtremeties: true,
      },
      infoBtnText: "",
      status: "",
    },
    transportBusiness: {
      applicable: false,
      amount: 0,
      label: "Transport Business",
      infoBtnText: "",
      status: "",
    },
    conveyance: {
      applicable: false,
      amount: 0,
      label: "Conveyance",
      infoBtnText: "This amounts to the extent of actual expenditure",
      status: "",
    },
    tourTravel_transfer: {
      applicable: false,
      amount: 0,
      label: "Tour Travel",
      infoBtnText:
        "This amounts to the extent of actual travel costs incurred i.e. rail/bus/air fare or This amounts to 70% of daily allowance or Rs. 10, 000 p.m.",
      status: "",
    },
    daily: {
      applicable: false,
      amount: 0,
      label: "Daily Allowance",
      infoBtnText: "This amounts to the extent of actual expenditure",
      status: "",
    },
    helper: {
      applicable: false,
      amount: 0,
      label: "Helper Assistance",
      infoBtnText: "This amounts to the extent of actual expenditure",
      status: "",
    },
    research: {
      applicable: false,
      amount: 0,
      label: "Research",
      infoBtnText: "This amounts to the extent of actual expenditure",
      status: "",
    },
    uniform: {
      applicable: false,
      amount: 0,
      label: "Uniform",
      infoBtnText: "This amounts to the extent of actual expenditure",
      status: "Non-taxable",
    },
    transportRegular: {
      applicable: false,
      amount: 0,
      label: "Regular Transport",
      infoBtnText: "",
      status: "",
    },
    fuelAndVehicleMaintenance: {
      applicable: false,
      amount: 0,
      label: "Fuel and Vehicle Maintenance Reimbursements",
      infoBtnText: "",
      status: "",
    },
    laptopReimbursement: {
      applicable: false,
      amount: 0,
      label: "Laptop Reimbursement",
      infoBtnText: "",
      status: "",
    },
    booksOfPeriodicals: {
      applicable: false,
      amount: 0,
      label: "Books and Periodicals",
      infoBtnText: "",
      status: "",
    },
  },
  perquisites: {
    refreshmentsDuringWorkingHours: {
      applicable: true,
      amount: 0,
      label: "Refreshment",
      isFixed: false,
    },
    subsidizedLunchDuringWorkingHours: {
      applicable: true,
      amount: 0,
      label: "Subsidized Lunch",
      isFixed: false,
    },
    employerContributionToStaffGroupInsurance: {
      applicable: false,
      amount: 0,
      label: "Employer's contribution to staff group insurance scheme",
      isFixed: false,
    },
    annualPremiumByEmployerOnPersonalAccidentPolicy: {
      applicable: false,
      amount: 0,
      label: "Annual premium by employer on personal accident policy",
      isFixed: false,
    },
    recreationalFacilities: {
      applicable: false,
      amount: 0,
      label: "Recreational Facilities",
      isFixed: false,
    },
    employeeTraining: {
      applicable: false,
      amount: 0,
      label: "Amount spent on training of employees",
      isFixed: false,
    },
    sumPayableByEmployerToA_RPF_OrAnApprovedSuperannuationFund: {
      applicable: false,
      amount: 0,
      label:
        "Sum payable by employer to a RPF or an approved superannuation fund",
      isFixed: false,
    },
    medicalFacilities: {
      applicable: false,
      amount: 0,
      label: "Medical Facilities",
      isFixed: false,
    },
  },
  infoTextForGeneral: {
    gratuity:
      "The amount that an employer pays his employee, in return for services offered by him to the company.",
    gratuityTenure:
      "As per the law gratuity benefit is applicable only those employees who have been employed by the company for five years or more. However this tenure can be lowered subject to company policy.",
    enablePF:
      "A scheme in which retirement benefits are accumulated where an employee has to pay a certain contribution towards the scheme and an equal contribution is paid by the employer.",
    variableAboveCTC:
      "Select Yes if the performance pay and variable pays are calculated over and above the CTC committed.",
    varablePayoutMonth:
      "Select a month where you will payout the variable pay amounts to your employees",
  },
  PHONENUMBER: "+91-8088072672",
  fnfCalculationBasis: ["Basic", "Gross"],
};