import { useState, useEffect } from "react";
import NotificationUpdate from "../../../assets/update/NotificationUpdate.svg";
import Payslip from "../../../assets/update/Payslip.svg";
import ReimUpdate from "../../../assets/update/ReimUpdate.svg";
import TaxUpdate from "../../../assets/update/TaxUpdate.svg";
import { newUpdates } from "../../../apis/employee";
// import moment from "moment";

const Updates = ({ user }) => {
  const [update, setUpdate] = useState([]);

  useEffect(() => {
    getNewUpdates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNewUpdates = async () => {
    let response = await newUpdates({ PAN: user.PAN });
    setUpdate(response.data);
  };
  update.map((val) => {
    if (val.type === 1) {
      val.heading = "Reimbursement"
      val.image = ReimUpdate
      return val
    } else if (val.type === 2) {
      val.heading = "Deduction"
      val.image = TaxUpdate
      return val
    } else if (val.type === 3) {
      val.heading = "Payslip"
      val.image = Payslip
      return val;
    } else {
      val.heading = "Notification"
      val.image = NotificationUpdate
      return val;
    }
  });

  return (
    <div className="paper-wrapper">
      <div className="header-component">
        <h3 className="app-heading-3 font-size-global-24 font-weight-global-700">Updates</h3>
        {/* <Link to="/employee/salary_manage" className="header-link">
          View All
        </Link> */}
      </div>
      <div className="w-full update-scroll">
        {/* {update.map((val, index) => (
          <UpdatesList key={`update-${index}`} {...val} />
        ))} */}
        {/* {update.length === 0 && (
          <div className="text-align-center py-36">
            <img alt="img" src={blueFolder}></img>
          </div>
        )} */}
      </div>
    </div>
  );
};

// const UpdatesList = ({ heading, message, image, createdAt }) => (
//   <div
//     style={{ marginBottom: 20 }}
//     className="flex justify-between align-center"
//   >
//     <div className="info-section flex align-center">
//       <img alt="Not" src={image} />
//       <div style={{ marginLeft: 10 }}>
//         <p style={{ fontWeight: 600, fontSize: 15 }}>{heading}</p>
//         <p className="app-label-3">{message}</p>
//       </div>
//     </div>
//     {createdAt && (
//       <p className="update-time">{moment(createdAt).format("ll")}</p>
//     )}
//   </div>
// );

export default Updates;
