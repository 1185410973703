import { Col, message, notification, Row, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { newSettlement } from "../../../../apis/employee";
import payoutEmpty from "../../../../assets/organisationConfiguration/AllowanceEmpty.svg";
import { NormalButton } from "../../../ComponentLibrary";
import DateComponent from "../../../InputLibrary/DateComponent/DateComponent";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import SeparationInfo from "./SeparationInfo";

const Resignation = ({ user, employeeReducer, separations, isEmployerPage, updateFunction }) => {
  const [loading, setLoading] = useState(false);
  const [settlement, setSettlement] = useState({
    name: "",
    employeeId: "",
    reason: "",
    id: "",
    organisationId: "",
    initiationDate: "",
    lastDayOfWorking: "",
  });
  const [pageState, setPageState] = useState(0);
  useEffect(() => {
    if (separations && separations.active) {
      setPageState(1)
    }
    else {
      setPageState(0);
    }
  }, [separations]);
  useEffect(() => {
    const currentDate = new moment();
    let lastDate = new moment(currentDate);
    lastDate = lastDate.add(60, "days").calendar();
    setSettlement({
      name: user.displayName,
      employeeId: user.currentOrganisationMeta.employeeId,
      reason: "",
      id: user._id,
      organisationId: user.currentOrganisationId,
      initiationDate: currentDate,
      lastDayOfWorking: lastDate,
    });
  }, [user]);

  const startSettlement = async () => {
    if (!settlement.reason) {
      message.error("Please write the reason for separation");
      return;
    }
    setLoading(true);
    let res = await newSettlement({ settlement });
    if (res.status === 200 && res.data.success) {
      if (isEmployerPage) {
        notification.error({
          message: "Successfully created employee separation",
        });
        await updateFunction(user._id)
      }
      else {
        updateFunction(user._id);
      }
    } else {
      notification.error({
        message: "Failed to create separation request.",
      });
    }
    setLoading(false);
  };

  const CancelSeparation = () => {
    employeeReducer.employeeNewSettlement({});
  };

  return (
    <div className="paper-wrapper m-top-16">
      {
        pageState === 0 ? <div className="flex justify-center align-center flex-direction-column">
          <div className="my-30">
            <img alt="img" className="payout-state-0-image" src={payoutEmpty} />
          </div>
          <NormalButton
            margin={[24, 0]}
            buttonText="Initiate Separation"
            type={13}
            onClick={() => setPageState(1)}
          />
        </div> :
          <>
            {separations && separations.active && (
              <SeparationInfo CancelSeparation={CancelSeparation} user={user} separations={separations} />
            )}
            {(!separations || !separations.active) && (
              <Spin spinning={loading} size={"large"}>
                <div className="separation-container">
                  <div className="separation-header">Employee Details</div>
                  <Row>
                    <Col md={12} xl={12}>
                      <div className="app-input-container-1">
                        <p className="general-ant-field-label font-weight-700">Name</p>
                        <b className="app-input font-weight-700">{settlement.name}</b>
                      </div>
                    </Col>
                    <Col md={12} xl={12}>
                      <div className=" app-input-container-1">
                        <p className="general-ant-field-label font-weight-700">Employee ID</p>
                        <b className="app-input font-weight-700">{settlement.employeeId}</b>
                      </div>
                    </Col>

                    <Col md={12} xl={12}>
                      <div className="emp-data-container">
                        <DateComponent
                          bordered={false}
                          labelText={"Initiation Date"}
                          value={new moment(
                            new Date(settlement.initiationDate),
                            "YYYY-MM-DD"
                          )}
                          size={"large"}
                          onChange={(e) => {
                            let last = new moment(e);
                            last = last.add(60, "days").calendar();
                            setSettlement({
                              ...settlement,
                              initiationDate: new Date(e).toDateString(),
                              lastDayOfWorking: last,
                            });
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={12} xl={12}>
                      <div className="app-input-container-1">
                        <DateComponent
                          bordered={false}
                          labelText={"Last Day of Working"}
                          value={new moment(
                            new Date(settlement.lastDayOfWorking),
                            "YYYY-MM-DD"
                          )}
                          size={"large"}
                          onChange={(e) => {
                            setSettlement({
                              ...settlement,
                              lastDayOfWorking: new Date(e).toDateString(),
                            });
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={24} xl={24}>
                      <div >
                        <InputFieldComponent
                          className="input-register"
                          labeltext={"Reason"}
                          value={settlement.reason}
                          width={"100%"}
                          onChange={(e) => {
                            setSettlement({
                              ...settlement,
                              reason: e.target.value,
                            });
                          }}
                        />
                        
                      </div>
                    </Col>
                  </Row>
                  <div>

                    <div className="button-container">
                      <NormalButton
                        onClick={startSettlement}
                        buttonText="save"
                        type={13}
                      />
                    </div>
                  </div>
                </div>
              </Spin>
            )}{" "}
          </>
      }

    </div>
  );
};

export default Resignation;
