import { Col, InputNumber, Row, Switch } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";

const AllowancesSelect = ({
  percentageStructure,
  newGroupsDetails,
  setNewGroupsDetails,
  isPercentageStructure,
  updatePercentageStructure,
  updateIsPercentage,
  setPercentageStructure,
  calculatePercentage,
}) => {
  return (
    <div>
      <div
        style={{
          width: "93.4%",
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <h1 className="tab-subtitle-1" style={{ color: "black" }}>
          {isPercentageStructure ? "Percentage Defining" : "Select Allowances"}
        </h1>
        {/* <Link style={{ color: "#fea101" }}>View Breakup</Link> */}
      </div>
      <div className="note">
        <p>
          Note: The allowance that you’ll select needs to be defined as some %
          of the Actual CTC, once you have selected and defined all the % you
          cannot change in the later stage as this might be a permanent
          configuration
        </p>
      </div>
      {isPercentageStructure && (
        <Row gutter={[20, 20]}>
          <Col className="app-label-5">
            <p>Remaining %</p>
            <p style={{ color: "#000", fontWeight: "600" }}>
              {Math.round((1 - calculatePercentage(percentageStructure)) * 100)}{" "}
              %
            </p>
          </Col>
          <Col className="app-label-5">
            <p>Buffer %</p>
            <p style={{ color: "#000", fontWeight: "600" }}>
              {Math.round(percentageStructure.buffer * 100)} %
            </p>
          </Col>
        </Row>
      )}
      <div className="flex justify-between align-center  m-top-10">
        <p className="app-label-1">Name</p>
        <p className="app-label-1">
          {" "}
          Allowance {isPercentageStructure ? "%" : ""}
        </p>
      </div>
      <div className="line"></div>
      {isPercentageStructure && (
        <div className="flex justify-between m-bottom-20">
          <p className="app-label-1">Basic Percentage</p>
          <div>
            <Checkbox
              checked={!percentageStructure.basicHRAPercentage.basic}
              onChange={(value) => {
                setPercentageStructure({
                  ...percentageStructure,
                  basicHRAPercentage: {
                    ...percentageStructure.basicHRAPercentage,
                    basic: !value.target.checked,
                  },
                  basic: value.target.checked ? 0 : percentageStructure.basic,
                });
              }}
            >
              Amount
            </Checkbox>
            <InputNumber
              placeholder={0}
              className="m-right-16"
              onChange={(value) => {
                // write the algorithm somewhere else that someone can understand it
                updatePercentageStructure(value, "basic", 0);
              }}
              value={
                percentageStructure.basicHRAPercentage.basic
                  ? percentageStructure.basic * 100
                  : percentageStructure.basic
              }
            />
            <span className="app-label-5 heading">
              {percentageStructure.basicHRAPercentage.basic
                ? `% of CTC`
                : `Per Month`}
            </span>{" "}
          </div>
        </div>
      )}

      {isPercentageStructure && (
        <div className="flex justify-between m-bottom-20">
          <p className="app-label-1">HRA Percentage</p>
          <div>
            <Checkbox
              checked={!percentageStructure.basicHRAPercentage.hra}
              onChange={(value) => {
                setPercentageStructure({
                  ...percentageStructure,
                  basicHRAPercentage: {
                    ...percentageStructure.basicHRAPercentage,
                    hra: !value.target.checked,
                  },
                  hra: value.target.checked ? 0 : percentageStructure.hra,
                });
              }}
            >
              Amount
            </Checkbox>
            <InputNumber
              placeholder={0}
              className="m-right-16"
              onChange={(value) => {
                // write the algorithm somewhere else that someone can understand it
                updatePercentageStructure(value, "hra", 0);
              }}
              value={
                percentageStructure.basicHRAPercentage.hra
                  ? percentageStructure.hra * 100
                  : percentageStructure.hra
              }
            />
            <span className="app-label-5 heading">
              {percentageStructure.basicHRAPercentage.hra
                ? `% of CTC`
                : `Per Month`}
            </span>{" "}
          </div>
        </div>
      )}

      {Object.keys(newGroupsDetails.customAllowances).map((val, i) => {
        return (
          <div
            className="flex justify-between m-bottom-36"
            key={`allowance-item-${i}`}
          >
            <div className="flex">
              {isPercentageStructure && (
                <Switch
                  checked={newGroupsDetails.customAllowances[val].checked}
                  onChange={(e) => {
                    let customAllowances = newGroupsDetails.customAllowances;
                    customAllowances[val].checked = e;
                    setNewGroupsDetails({
                      ...newGroupsDetails,
                      customAllowances: {
                        ...customAllowances,
                      },
                    });
                    setPercentageStructure({
                      ...percentageStructure,
                      customAllowances: {
                        ...percentageStructure.customAllowances,
                        [val]: {
                          isPercent: true,
                          value: 0,
                        },
                      },
                    });
                  }}
                  className="m-right-16"
                />
              )}
              <p className="app-label-1">
                {newGroupsDetails.customAllowances[val].label}
              </p>{" "}
            </div>

            <div>
              {!isPercentageStructure && (
                <Switch
                  checked={newGroupsDetails.customAllowances[val].checked}
                  onChange={(e) => {
                    let customAllowances = newGroupsDetails.customAllowances;
                    customAllowances[val].checked = e;
                    setNewGroupsDetails({
                      ...newGroupsDetails,
                      customAllowances: {
                        ...customAllowances,
                      },
                    });
                  }}
                  className="m-right-16"
                />
              )}
              {isPercentageStructure &&
                newGroupsDetails.customAllowances[val].checked && (
                  <>
                    <Checkbox
                      checked={
                        !percentageStructure.customAllowances[val]["isPercent"]
                      }
                      onChange={(value) =>
                        updateIsPercentage(val, !value.target.checked, 1)
                      }
                    >
                      Amount
                    </Checkbox>
                    <InputNumber
                      min={0}
                      placeholder={0}
                      width={400}
                      className="m-right-16"
                      onChange={(value) => {
                        updatePercentageStructure(value, val, 2);
                      }}
                      value={
                        Math.round(
                          percentageStructure["customAllowances"][val].value *
                          (percentageStructure["customAllowances"][val]
                            .isPercent
                            ? 100
                            : 1) *
                          1000
                        ) / 1000
                      }
                    />

                    {isPercentageStructure && (
                      <span className="app-label-5 heading">
                        {percentageStructure.customAllowances[val].isPercent
                          ? " % of CTC"
                          : " Per Month"}
                      </span>
                    )}
                  </>
                )}
            </div>
          </div>
        );
      })}
      {Object.keys(newGroupsDetails.allowances).map((val, i) => {
        return (
          <div
            className="flex justify-between m-bottom-36"
            key={`allowance-item-${i}`}
          >
            <div className="flex">
              {percentageStructure && (
                <Switch
                  checked={newGroupsDetails.allowances[val].checked}
                  onChange={(e) => {
                    let allowances = newGroupsDetails.allowances;
                    allowances[val].checked = e;
                    setNewGroupsDetails({
                      ...newGroupsDetails,
                      allowances: {
                        ...allowances,
                      },
                    });
                    setPercentageStructure({
                      ...percentageStructure,
                      allowances: {
                        ...percentageStructure.allowances,
                        [val]: {
                          isPercent: true,
                          value: 0,
                        },
                      },
                    });
                  }}
                  className="m-right-16"
                />
              )}
              <p className="app-label-1">
                {newGroupsDetails.allowances[val].label}
              </p>
            </div>

            <div>
              {!percentageStructure && (
                <Switch
                  checked={newGroupsDetails.allowances[val].checked}
                  onChange={(e) => {
                    let allowances = newGroupsDetails.allowances;
                    allowances[val].checked = e;
                    setNewGroupsDetails({
                      ...newGroupsDetails,
                      allowances: {
                        ...allowances,
                      },
                    });
                  }}
                  className="m-right-16"
                />
              )}
              {isPercentageStructure &&
                newGroupsDetails.allowances[val].checked && (
                  <>
                    <Checkbox
                      checked={
                        !percentageStructure.allowances[val]["isPercent"]
                      }
                      onChange={(value) =>
                        updateIsPercentage(val, !value.target.checked, 0)
                      }
                    >
                      Amount
                    </Checkbox>
                    <InputNumber
                      min={0}
                      placeholder={0}
                      width={400}
                      className="m-right-16"
                      onChange={(value) => {
                        updatePercentageStructure(value, val, 1);
                      }}
                      value={
                        Math.round(
                          percentageStructure["allowances"][val].value *
                          (percentageStructure["allowances"][val].isPercent
                            ? 100
                            : 1) *
                          1000
                        ) / 1000
                      }
                    />

                    {isPercentageStructure && (
                      <span className="app-label-5 heading">
                        {percentageStructure.allowances[val].isPercent
                          ? " % of CTC"
                          : " Per Month"}
                      </span>
                    )}
                  </>
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AllowancesSelect;
