import { HeaderComponent } from "../../Common";
import { connect } from "react-redux";
import { Tabs } from 'antd';
import Request from "./Component/Request";
import Report from "./Component/Report";
import History from "./Component/History";
import { useEffect } from "react";
import TrialPage from "../../Common/TrialComponent";
import attendanceBgImage from "../../../assets/dummyPages/attendanceBgImage.png"
import organisation from "../../../redux/modules/organisation";


const { TabPane } = Tabs;

const OrganisationLeaveContainer = (isTrial, organisation, user) => {
    useEffect(() => {
        document.title = 'Attendance | Leave';
    });
    return (
        !isTrial ? <TrialPage user={user} trialMessage={"Configure your leave and attendance regularization flow"} bgImage={attendanceBgImage}
        /> :
            <div className="site-layout">
                <HeaderComponent show={false} name="Leave" />
                <div className="app-main-content-container paper-wrapper ">
                    <div className="blue-tabs-container ">
                        <Tabs
                            tabPosition={"left"}
                        >
                            <TabPane tab="Requests" key="1">
                                <Request />
                            </TabPane>
                            <TabPane tab="Report" key="2">
                                <Report />
                            </TabPane>
                            <TabPane tab="History" key="3">
                                <History />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
    )
}
const OrganisationLeave = connect(
    (state) => ({
        user: state.auth.user,
        groups: state.organisation.groups,
        leaveData: state.organisation.leaveData,
        leaveList: state.organisation.leaveList,
        isTrial: state.auth.user.isTrial,

    }),
    (dispatch) => ({
        organisationReducer: organisation.getActions(dispatch),
    })
)(OrganisationLeaveContainer);

export default OrganisationLeave