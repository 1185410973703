import { Avatar, Card, Drawer, message, Table } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { downloadJannForm12Document, getJannForm12BBDocument, updateForm12BBVerifiedStatus } from "../../../apis/jann";
import { checkFinancialYear, deducationList as deductionList } from "../../../utils/config";
import { HeaderComponent } from "../../Common";
import { NormalButton } from "../../ComponentLibrary";
import organisation from "../../../redux/modules/organisation"
import { Buffer } from 'buffer';


const getStatus = (key) => {
  if (key === 1) {
    return 'Paid';
  } else if (key === 2) {
    return 'Signed';
  }
  return 'Unsigned';
};

const getEmployerStatus = (key) => {
  if (key === 1) {
    return 'Acknowledged By HR';
  } else if (key === 2) {
    return 'Accepted By HR';
  } else if (key === 3) {
    return 'Rejected By HR';
  }
  return 'Pending At HR';
};

const Form12BB = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [currDetails, setCurrDetails] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Documents",
      dataIndex: "_id",
      render: (key, record) => {
        return (
          <span
            style={{ color: "#4285F4", cursor: "pointer" }}
            onClick={() => {
              setOpen(true);
              setCurrDetails(record);
            }}>
            ...show documents
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (user) {
      getJannForm12BBDocument(user.organisationId).then(res => {
        if (res.data && res.data.success) {
          setDataSource(res.data.data)
        } else {
          setDataSource([])
        }
      }).catch(err => {
        setDataSource([])
      })
    }
  }, [user, refresh])

  useEffect(() => {
    document.title = "Form 12B";
  });

  function onClose() {
    setOpen(false);
  }

  const GetIcon = ({ documentKey }) => {
    const colors = { pdf: "#EB455F", jpeg: "#BAD7E9", png: "#0081B4" };
    const splitter = typeof documentKey === "string" ? documentKey.split(".")[1] : "pdf";
    return (
      <Avatar
        shape='square'
        style={{
          backgroundColor: colors[splitter],
          verticalAlign: "middle",
          borderRadius: "5px",
        }}
        size={"large"}>
        {splitter}
      </Avatar>
    );
  };
  const DocumentSection = ({ sectionName, documentKey, status, verified, id }) => {
    const updateAcceptStatus = (verified) => {
      const { financialYear } = checkFinancialYear()
      updateForm12BBVerifiedStatus({
        id, verified, documentKey, financialYear
      }).then(res => {
        if (res.data && res.data.success) {
          message.success("Updated Status For: " + sectionName)
          setRefresh(!refresh)
          setOpen(false)
        } else {
          message.error("Error While Updating The Status!")
        }
      }).catch(err => {
        message.error("Error While Updating The Status!")
      })
    }
    return (
      <Card key={documentKey} style={verified === 3 ? { opacity: 0.5 } : {}}>
        <div className='flex justify-between align-center'>
          <div className='flex justify-between align-center'>
            <GetIcon documentKey={documentKey} />
            <div className='flex justify-between flex-direction-column'>
              <h3 style={verified === 3 ? { textDecoration: "line-through" } : {}} className='m-left-10'>{sectionName}</h3>
              <p className='m-left-10'>Status: {getStatus(status)} | {getEmployerStatus(verified)}</p>
            </div>
          </div>
          {
            verified < 2 ? <div className='flex justify-center align-center'>
              <NormalButton type={19} buttonText='Reject' onClick={() => { updateAcceptStatus(3) }} margin={[5, 8]} />
              <NormalButton type={13} buttonText='Accept' onClick={() => { updateAcceptStatus(2) }} margin={[5, 0]} />
            </div> : ""
          }
        </div>
      </Card >
    );
  };

  const downloadAllDocuments = async () => {
    const id = currDetails.id
    const financialYear = checkFinancialYear().financialYear;
    const content = await downloadJannForm12Document(id, financialYear);
    if (content.data !== '') {
      const buffer = Buffer.from(content.data, 'base64');

      const blob = new Blob([buffer], { type: 'application/zip' });
      const urlDownload = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', `Form12BB.zip`);
      document.body.appendChild(link);
      link.click();
    }
  }

  return (
    <section className='site-layout'>
      <Drawer
        title='Show & Verify Documents'
        placement='right'
        onClose={onClose}
        visible={open}
        size={"large"}>
        <div style={{ padding: "10px 20px" }}>
          <div className="flex justify-between align-center">
            <h1>Documents</h1>
            <NormalButton type={13} onClick={() => { downloadAllDocuments() }} buttonText={"Download Documents"} />
          </div>
          <div className="">
            <h2 className='m-10'>Form12BB Document</h2>
            {
              currDetails.form12BB ? <DocumentSection
                sectionName={"Form12BB Document"}
                documentKey={currDetails.form12BB.key}
                status={currDetails.form12BB.status}
                verified={currDetails.form12BB.verified}
                id={currDetails.id}
              /> : ""
            }
          </div>
          <div>
            <h2 className='m-10'>Leave Travel Concession </h2>
            {
              currDetails.leaveTravelObject && currDetails.leaveTravelObject.documents ? currDetails.leaveTravelObject.documents.map(item => {
                return (<DocumentSection
                  sectionName={item.name}
                  documentKey={item.key}
                  status={item.status}
                  verified={item.verified}
                  id={currDetails.id}
                />)
              }) : ""
            }
          </div>
          <div>
            <h2 className='m-10'>Deduction Of Interest</h2>
            {
              currDetails.deductionsObject && currDetails.deductionsObject.documents ? currDetails.deductionsObject.documents.map(item => {
                return (<DocumentSection
                  sectionName={item.name}
                  documentKey={item.key}
                  status={item.status}
                  verified={item.verified}
                  id={currDetails.id}
                />)
              }) : ""
            }
          </div>
          <div>
            <h2 className='m-10'>Declarations </h2>
            {
              Object.keys(deductionList).map(key => {
                return (
                  Object.keys(deductionList[key].subSections).map(item => {
                    if (currDetails.declarations && currDetails.declarations[item] && currDetails.declarations[item].documents?.length) {
                      return (<div key={item}>
                        <h3 className="m-left-10 m-top-8" style={{ fontSize: "18px" }}>{deductionList[key]?.sectionName}</h3>
                        <div className="m-left-20 m-top-10">
                          <h3>{deductionList[key].subSections[item]}</h3>
                          <div className="m-top-10">
                            {
                              currDetails.declarations[item] ? currDetails.declarations[item].documents.map(doc => {
                                return (<DocumentSection
                                  sectionName={doc.name}
                                  documentKey={doc.key}
                                  status={doc.status}
                                  verified={doc.verified}
                                  id={currDetails.id}
                                />)
                              }) : ""
                            }
                          </div>
                        </div>
                      </div>)
                    } else {
                      return ""
                    }
                  })
                )
              })
            }

          </div>
        </div>
      </Drawer>
      <HeaderComponent name={"Form 12BB"} />
      <div className='app-main-content-container paper-wrapper'>
        <Table
          style={{ fontWeight: 500 }}
          columns={columns}
          dataSource={dataSource}
        />
      </div>
    </section>
  );
};

const Form12BBContainer = connect(
  state => ({
    user: state.auth.user,
  }),
  dispatch => ({
    organisationReducer: organisation.getActions(dispatch)
  })
)(Form12BB);
export default Form12BBContainer;
