import { Skeleton, Collapse, Divider, InputNumber } from "antd";
import { deducationTabsLists } from "../../../utils/config";
import { useEffect, useState } from "react";
import "../../../styles/ctccalcstyle.less";
import { FileTextOutlined } from "@ant-design/icons";
import InfoBtn from "../../ComponentLibrary/InfoButtonComponent/InfoBtn";
const { Panel } = Collapse;

const Chapter6A = ({ organisationSettings, dec, setDec }) => {
  const [array80c, setArray80c] = useState([]);
  const [array80d, setArray80d] = useState([]);
  const [arrayDisabled, setArrayDisabled] = useState([]);
  const [arrayOtherSection, setArrayOtherSection] = useState([]);
  useEffect(() => {
    // to prevent value getting appended in each re-render
    setArray80c([]);
    setArray80d([]);
    setArrayDisabled([]);
    setArrayOtherSection([]);
    deducationTabsLists.forEach((deduction) => {
      if (
        deduction.Category === "80C" ||
        deduction.Category === "80CCC" ||
        deduction.Category === "80CCD"
      ) {
        setArray80c((array80c) => [...array80c, deduction]);
      } else if (deduction.Category === "80D") {
        setArray80d((array80d) => [...array80d, deduction]);
      } else if (
        deduction.Category === "80DD" ||
        deduction.Category === " 80DDB" ||
        deduction.Category === " 80U" ||
        deduction.sectionType === "Disability"
      ) {
        setArrayDisabled((arrayDisabled) => [...arrayDisabled, deduction]);
      } else {
        setArrayOtherSection((arrayOtherSection) => [
          ...arrayOtherSection,
          deduction,
        ]);
      }
    });
  }, []);
  return (
    <Collapse ghost={true} bordered={false}>
      <Panel header="Declaration">
        <div className="h-full w-full declaration-class">
          {/* <p className="font-weight-700 font-size-global-18 mb-2">
            Can't decide how much allowance to add ?
          </p>
          <Checkbox
            className="font-weight-500 font-size-global-18 mb-2"
            defaultChecked={true}
          >
            Allow mool engine to enter the numbers automatically
          </Checkbox> */}
          <Skeleton
            loading={Object.keys(organisationSettings).length === 0}
            active
            paragraph={{ rows: 15 }}
          >
            <div>
              <Collapse expandIconPosition="end">
                <Panel
                  header={
                    <div className="flex align-items">
                      <FileTextOutlined style={{ padding: "5px" }} />
                      <p>Section 80C, 80CCC</p>
                    </div>
                  }
                  extra={`Total declared: ${dec["80C"] + dec["80CCC"]}`}
                >
                  <div className="flex justify-between align-center font-weight-500">
                    <p>Item</p>
                    <div
                      className="flex justify-between"
                      style={{ width: "400px", padding: "0 4rem" }}
                    >
                      <p style={{ marginLeft: "16em" }}>Amount</p>
                    </div>
                  </div>
                  <Divider />
                  {array80c.map((deduction, index) => {
                    return (
                      <div
                        className="flex justify-between align-center mb-5"
                        key={`all-${index}`}
                      >
                        <div className="flex align-center justify-start">
                          <h3 className="general-ant-field-label">{`${deduction.sectionType}`}</h3>
                          <InfoBtn infoText={`${deduction.infoBtnText}`} />
                        </div>
                        <InputNumber
                          className="ant-input-num"
                          placeholder="Amount"
                          value={dec[deduction.Category]}
                          onChange={(value) => {
                            let newChapter6A = dec;
                            newChapter6A[deduction.Category] = value;
                            setDec({
                              ...newChapter6A,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </Panel>
              </Collapse>
              <Collapse expandIconPosition="end">
                <Panel
                  header={
                    <div className="flex align-items">
                      <FileTextOutlined style={{ padding: "5px" }} />
                      <p>Section 80D</p>
                    </div>
                  }
                  extra={`Total declared: ${dec["80D"]}`}
                >
                  <div className="flex justify-between align-center font-weight-500">
                    <p>Item</p>
                    <div
                      className="flex justify-between"
                      style={{ width: "400px", padding: "0 4rem" }}
                    >
                      <p style={{ marginLeft: "16em" }}>Amount</p>
                    </div>
                  </div>
                  <Divider />
                  {array80d.map((deduction, index) => {
                    return (
                      <div
                        className="flex justify-between align-center mb-5"
                        key={`all-${index}`}
                      >
                        <div className="flex align-center justify-start">
                          <h3 className="general-ant-field-label">{`${deduction.sectionType}`}</h3>
                          <InfoBtn infoText={`${deduction.infoBtnText}`} />
                        </div>
                        <InputNumber
                          className="ant-input-num"
                          placeholder="INR"
                          value={dec[deduction.Category]}
                          width="120px"
                          onChange={(value) => {
                            let newChapter6A = dec;
                            newChapter6A[deduction.Category] = value;
                            setDec({
                              ...newChapter6A,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </Panel>
              </Collapse>
              <Collapse expandIconPosition="end">
                <Panel
                  header={
                    <div className="flex align-items">
                      <FileTextOutlined style={{ padding: "5px" }} />
                      <p>For Disabled</p>
                    </div>
                  }
                  extra={`Total declared:  ${dec["80DD"] + dec["80U"] + dec["80DDB"]}`}
                >
                  <div className="flex justify-between align-center font-weight-500">
                    <p>Item</p>
                    <div
                      className="flex justify-between"
                      style={{ width: "400px", padding: "0 4rem" }}
                    >
                      <p style={{ marginLeft: "16em" }}>Amount</p>
                    </div>
                  </div>
                  <Divider />

                  {arrayDisabled.map((deduction, index) => {
                    return (
                      <div
                        className="flex justify-between align-center mb-5"
                        key={`all-${index}`}
                      >

                        <div className="flex align-center justify-start">
                          <h3 className="general-ant-field-label">{`${deduction.label}`}</h3>
                          <InfoBtn infoText={`${deduction.infoBtnText}`} />
                        </div>
                        <InputNumber
                          className="ant-input-num"
                          placeholder="INR"
                          value={dec[deduction.Category]}
                          width="120px"
                          onChange={(value) => {
                            let newChapter6A = dec;
                            newChapter6A[deduction.Category] = value;
                            setDec({
                              ...newChapter6A,
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </Panel>
              </Collapse>
              <Collapse expandIconPosition="end">
                <Panel
                  header={
                    <div className="flex align-items">
                      <FileTextOutlined style={{ padding: "5px" }} />
                      <p>Other Sections</p>
                    </div>
                  }
                  extra={`Total declared: ${!isNaN ? 0 : dec["80E"] + dec["80EE"] + dec["80G"] + dec["80TTA"] + dec["80CCD1(B)"] + dec["80EEA"] + dec["80EEB"] + dec["24B"]}`}
                >
                  <div className="flex justify-between align-center font-weight-500">
                    <p>Item</p>
                    <div
                      className="flex justify-between"
                      style={{ width: "400px", padding: "0 4rem" }}
                    >
                      <p style={{ marginLeft: "16em" }}>Amount</p>
                    </div>
                  </div>
                  <Divider />
                  {arrayOtherSection.map((deduction, index) => {
                    return (
                      <div
                        className="flex justify-between align-center mb-5"
                        key={`all-${index}`}
                      >
                        <div className="flex align-center justify-start">
                          <h3 className="general-ant-field-label">{`${deduction.sectionType}`}</h3>
                          <InfoBtn infoText={`${deduction.infoBtnText}`} />
                        </div>
                        <InputNumber
                          className="ant-input-num"
                          value={dec[deduction.Category]}
                          width="120px"
                          onChange={(value) => {
                            let newChapter6A = dec;
                            newChapter6A[deduction.Category] = value;
                            setDec({
                              ...newChapter6A,
                            });
                          }}
                          placeholder="INR"
                        />
                      </div>
                    );
                  })}
                </Panel>
              </Collapse>

              {/* {deducationTabsLists.map((deduction, index) => {
            return (
              <>                
                
              </>
            );
          })} */}
            </div>
          </Skeleton>
        </div>
      </Panel>
    </Collapse>
  );
};

export default Chapter6A;