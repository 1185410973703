import { Button, message, Modal, notification, Popconfirm, Radio, Spin, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { deleteLogo } from "../../assets/common/editAndDeleteLogos";
import plus from "../../assets/icons/whitePlus.svg";
import InfoBtn from "../../components/ComponentLibrary/InfoButtonComponent/InfoBtn";
import { monthList } from "../../utils/config";
import { timeDiffCalc } from "../../utils/dateCheckUtil";
import { NormalButton } from "../ComponentLibrary";
import RefreshButton from "../ComponentLibrary/RefreshButtonComponent";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
import { createArrears } from "../../apis/organisation"




const options = [
    { label: "Salary Arrear", value: 0 },
    { label: "Other Allowance Arrear", value: 1 },
];

const options2 = [
    { label: "One-time", value: true },
    { label: "Multiphase", value: false },
];

const Arrears = ({
    monthCloseFields,
    user,
    employeeList,
    deleteData,
    loading,
    organisationSettings,
    startRefresh
}) => {
    const [isloading, setIsLoading] = useState(false);
    const [singleModalShow, setSingleModalShow] = useState(false);
    const [monthCloseCount, setMonthCloseCount] = useState(0);
    const [tenure, setTenure] = useState(0);
    const [allowances, setAllowances] = useState({});

    const [fieldDetails, setFieldDetails] = useState({
        ID: "",
        type: "0",
        isOneTime: true,
        subCategoryCode: 0,
        subCategory: "Salary Arrear",
        allowanceKey: "",
        totalAmount: 0,
        organisationId: "",
        month: new Date().getMonth(),
        startMonth: "",
        endMonth: "",
        startYear: "",
        endYear: "",
        fromMonth: "",
        toMonth: "",
        fromYear: "",
        toYear: "",
    });

    const yearList = []
    for (let year = new Date().getFullYear() - 10; year <= new Date().getFullYear() + 20; year++) {
        yearList.push(year)
    }
    const currentYearList = []
    for (let year = new Date().getFullYear(); year <= new Date().getFullYear() + 20; year++) {
        currentYearList.push(year)
    }
    useEffect(() => {
        const { allowances: dAllowances } = organisationSettings;
        setAllowances({
            ...dAllowances
        })
    }, [organisationSettings])

    const reset = () => {
        setFieldDetails({
            ...{
                ID: "",
                type: "0",
                isOneTime: true,
                totalAmount: 0,
                subCategoryCode: 0,
                subCategory: "Salary Arrear",
                allowanceKey: "",
                organisationId: "",
                month: new Date().getMonth(),
                startMonth: "",
                endMonth: "",
                startYear: "",
                endYear: "",
                fromMonth: "",
                toMonth: "",
                fromYear: "",
                toYear: "",
            },
        });
        setTenure(0)
        setSingleModalShow(false);
    };

    const onSave = () => {
        if (!fieldDetails.ID) {
            message.error("Please select an employee");
            return;
        }
        if (fieldDetails.subCategoryCode === 1 && !fieldDetails.allowanceKey) {
            message.error("Please select one allowance");
            return;
        }
        if (!fieldDetails.startMonth && fieldDetails.startMonth !== 0) {
            message.error("Please enter Start Month");
            return;
        }
        if (!fieldDetails.startYear) {
            message.error("Please enter Start Year");
            return;
        }
        if (!fieldDetails.endMonth && fieldDetails.endMonth !== 0) {
            message.error("Please enter End Month");
            return;
        }
        if (!fieldDetails.endYear) {
            message.error("Please enter End Year");
            return;
        }
        if (!fieldDetails.isOneTime && !(fieldDetails.toMonth || fieldDetails.toYear || fieldDetails.fromMonth || fieldDetails.fromYear)) {
            message.error("Please enter the mandatory fields of multi-phase payment");
            return;
        }
        if (!fieldDetails.totalAmount) {
            message.error("Please enter Total Amount");
            return;
        }


        setIsLoading(true);
        createArrears({
            ...fieldDetails,
            organisationId: user.organisationId,
            tenure,
            subCategory: fieldDetails.subCategoryCode === 0 ? "Salary Arrear" : "Other Allowance Arrear",
            year: new Date().getFullYear(),
            type: parseInt(fieldDetails.type ?? 0),
        }).then(res => {
            setSingleModalShow(false);
            if (res.data.success) {
                notification.success({
                    message: "Arrear Added Successfully!"
                })
            } else {
                notification.error({
                    message: res.data.message
                })
            }
            reset()
        }).catch(err => {
            return this.error("Error While Creating Data!");
        })

        setIsLoading(false);

    }




    useEffect(() => {
        if (fieldDetails.startMonth !== "" && fieldDetails.startYear !== "" && fieldDetails.endMonth !== "" && fieldDetails.endYear !== "") {
            const pastDate = new Date(new Date().setFullYear(fieldDetails.startYear)).setMonth(fieldDetails.startMonth)
            const futureDate = new Date(new Date().setFullYear(fieldDetails.endYear)).setMonth(fieldDetails.endMonth)
            const { monthDiff } = timeDiffCalc(pastDate, futureDate);
            if (monthDiff < 0) {
                notification.error({ message: "Start Month & Year Should Be Lesser than End Month & Year" })
            }
        }
    }, [fieldDetails.startMonth, fieldDetails.startYear, fieldDetails.endMonth, fieldDetails.endYear,])

    useEffect(() => {
        if (fieldDetails.fromMonth !== "" && fieldDetails.fromYear !== "") {
            const pastDate = new Date(new Date().setFullYear(fieldDetails.fromYear)).setMonth(fieldDetails.fromMonth)
            const currDate = new Date()
            const { monthDiff } = timeDiffCalc(currDate, pastDate);
            if (monthDiff < 0) {
                notification.error({ message: "Can not select previous month and year combination." })
            }
        }
    }, [fieldDetails.fromMonth, fieldDetails.fromYear])

    useEffect(() => {
        if (fieldDetails.fromMonth !== "" && fieldDetails.fromYear !== "" && fieldDetails.toMonth !== "" && fieldDetails.toYear !== "") {
            const pastDate = new Date(new Date().setFullYear(fieldDetails.fromYear)).setMonth(fieldDetails.fromMonth)
            const futureDate = new Date(new Date().setFullYear(fieldDetails.toYear)).setMonth(fieldDetails.toMonth)
            const { monthDiff } = timeDiffCalc(pastDate, futureDate);
            setTenure(monthDiff < 0 ? 0 : monthDiff + 1);
            if (monthDiff < 0) {
                notification.error({ message: "From Month & Year Should Be Lesser than To Month & Year" })
            }
        }
    }, [fieldDetails.fromMonth, fieldDetails.fromYear, fieldDetails.toMonth, fieldDetails.toYear,])



    const columns = [
        {
            title: "Employee Name",
            dataIndex: ["user", "displayName"],
        },
        {
            title: "Employee Id",
            dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        },
        {
            title: "Added On",
            dataIndex: "createdAt",
            defaultSortOrder: "ascend",
            render: (text) => <p>{moment(text).format("ll")}</p>,
        },
        {
            title: "Applicable On",
            dataIndex: "month",
            defaultSortOrder: "ascend",
            render: (text) => <p>{monthList[parseInt(text)]}</p>,
            filters: [
                ...monthList.map((_val, index) => { return { text: _val, value: index } })
            ],
            onFilter: (value, record) => { return record.month === value; },
            filterSearch: true,
        },
        {
            title: "Type",
            dataIndex: "type",
        },
        {
            title: "Reason",
            dataIndex: "details",
        },
        {
            title: "Description",
            dataIndex: "subDetails",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (amount, row) => (
                <p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
                    {row.type === "debit" ? "-" : "+"}
                    {amount}
                </p>
            ),
        },
        {
            title: "Action",
            dataIndex: "_id",
            align: "center",
            render: (data, record) => (
                <>
                    <Popconfirm title={"Are you sure ?"} onConfirm={() => deleteData(data)}>
                        <NormalButton
                            type={5}
                            icon={deleteLogo}
                        />
                    </Popconfirm>
                </>
            ),
        }
    ];


    return (
        <div className="paper-wrapper-month-closing">

            <Modal
                visible={singleModalShow}
                centered
                width={400}
                footer={null}
                onCancel={() => {
                    setSingleModalShow(false)
                    reset()
                }}
            >
                <div className="p-6 p-top-10">
                    <div className="m-bottom-10">
                        <h2 className="font-weight-global-700 font-size-global-24">Add Arrear</h2>
                        <h3 className="general-ant-field-label font-weight-global-500">Credit Type</h3>
                    </div>
                    <Spin spinning={isloading} size="large">
                        <div

                            className="org-anb-data-container p-top-15"
                        >
                            <DropdownComponent
                                className="general-ant-field-select"
                                labeltext={"Employee Name"}
                                options={employeeList.map((item) => item.displayName)}
                                values={employeeList.map(item => item._id)}
                                value={fieldDetails.ID}
                                onChange={(e) => {
                                    let fieldDetailsData = fieldDetails;
                                    setFieldDetails({
                                        ...fieldDetailsData,
                                        ID: e,
                                    });
                                }}
                                width={"100%"}
                                bordered={false}
                                filterOption={(input, option) =>
                                    option.children &&
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children
                                        .toLowerCase()
                                        .localeCompare(optionB.children.toLowerCase())
                                }
                                showSearch
                            />
                        </div>
                        <div className="org-anb-data-container p-top-10">
                            <h3

                                className="flex  align-center general-ant-field-label font-size-global-18 font-weight-global-700" style={{ color: "black" }}
                            >
                                Type {"  "} <InfoBtn className={"font-size-12"} infoText={"Is it a salary arrear or any other allowance arrear [ Dearness, LTA, HRA ...etc ] ?"} />
                            </h3>
                            <Radio.Group
                                options={options}
                                onChange={(e) => {
                                    setFieldDetails({
                                        ...fieldDetails,
                                        subCategory: options[parseInt(e.target.value)],
                                        subCategoryCode: parseInt(e.target.value)
                                    })
                                }
                                }
                                value={parseInt(fieldDetails.subCategoryCode)}
                                className="p-top-8 p-bottom-8"
                            />
                        </div>
                        {
                            fieldDetails.subCategoryCode === 1 && <div className="org-anb-data-container p-top-10">
                                <DropdownComponent
                                    labeltext={"Select Allowance"}
                                    className="general-ant-field-select"
                                    value={fieldDetails.allowanceKey}
                                    values={Object.keys(allowances).map((_val, index) => _val)}
                                    options={Object.values(allowances).map((_val, index) => _val.displayName ? _val.displayName : _val.label)}
                                    width={"100%"}
                                    onChange={(e) => {

                                        let fieldDetailsData = fieldDetails;
                                        setFieldDetails({
                                            ...fieldDetailsData,
                                            allowanceKey: e,
                                        });
                                    }}
                                    size={"large"}
                                />
                            </div>
                        }

                        <div
                            className=" flex justify-between"
                        >
                            <DropdownComponent
                                labeltext={"Start Month"}
                                width={"200px"}
                                className="general-ant-field-input"
                                value={fieldDetails.startMonth}
                                values={monthList.map((_val, index) => index)}
                                options={monthList}
                                showSearch
                                onChange={(e) => {
                                    let fieldDetailsData = fieldDetails;
                                    setFieldDetails({
                                        ...fieldDetailsData,
                                        startMonth: e,
                                    });
                                }}
                                size={"large"}
                            />
                            <div style={{ marginLeft: "8px" }} />
                            <DropdownComponent
                                labeltext={"Start Year"}
                                width={"140px"}
                                className="general-ant-field-input"
                                value={fieldDetails.startYear}
                                values={yearList.map((_val, index) => _val)}
                                options={yearList}
                                showSearch
                                onChange={(e) => {
                                    let fieldDetailsData = fieldDetails;
                                    setFieldDetails({
                                        ...fieldDetailsData,
                                        startYear: e,
                                    });
                                }}
                                size={"large"}
                            />
                        </div>

                        <div
                            className="flex justify-between "
                        >
                            <DropdownComponent
                                labeltext={"End Month"}
                                width={"200px"}
                                className="general-ant-field-input"
                                value={fieldDetails.endMonth}
                                values={monthList.map((_val, index) => index)}
                                options={monthList}
                                showSearch
                                onChange={(e) => {
                                    let fieldDetailsData = fieldDetails;
                                    setFieldDetails({
                                        ...fieldDetailsData,
                                        endMonth: e,
                                    });
                                }}
                                size={"large"}
                            />
                            <div style={{ marginLeft: "8px" }} />
                            <DropdownComponent
                                labeltext={"End Year"}
                                width={"140px"}
                                className="general-ant-field-input"
                                value={fieldDetails.endYear}
                                values={yearList.map((_val, index) => _val)}
                                options={yearList}
                                showSearch={true}
                                onChange={(e) => {
                                    let fieldDetailsData = fieldDetails;
                                    setFieldDetails({
                                        ...fieldDetailsData,
                                        endYear: e,
                                    });
                                }}
                                size={"large"}
                            />
                        </div>
                        <div className="org-anb-data-container p-top-10">
                            <h3

                                className="flex  align-center general-ant-field-label font-size-global-18 font-weight-global-700" style={{ color: "black" }}
                            >
                                Payment Options{"  "} <InfoBtn className={"font-size-12"} infoText={"How would you like to pay the amount of arrear to the employee ?"} />
                            </h3>
                            <Radio.Group
                                options={options2}
                                onChange={(e) => {
                                    setFieldDetails({
                                        ...fieldDetails,
                                        isOneTime: e.target.value,
                                    })
                                }
                                }
                                value={fieldDetails.isOneTime}
                                className="p-top-8 p-bottom-8"
                            />
                        </div>
                        {
                            !fieldDetails.isOneTime &&
                            <div className="org-anb-data-container p-top-8"
                            >
                                <div
                                    className=" flex justify-between"
                                >
                                    <DropdownComponent
                                        labeltext={"From Month"}
                                        width={"200px"}
                                        className="general-ant-field-input"
                                        value={fieldDetails.fromMonth}
                                        values={monthList.map((_val, index) => index)}
                                        options={monthList}
                                        onChange={(e) => {
                                            let fieldDetailsData = fieldDetails;
                                            setFieldDetails({
                                                ...fieldDetailsData,
                                                fromMonth: e,
                                            });
                                        }}
                                        size={"large"}
                                    />
                                    <div style={{ marginLeft: "8px" }} />
                                    <DropdownComponent
                                        labeltext={"From Year"}
                                        showSearch={true}
                                        width={"140px"}
                                        className="general-ant-field-input"
                                        value={fieldDetails.fromYear}
                                        values={currentYearList.map((_val, index) => _val)}
                                        options={currentYearList}
                                        onChange={(e) => {
                                            let fieldDetailsData = fieldDetails;
                                            setFieldDetails({
                                                ...fieldDetailsData,
                                                fromYear: e,
                                            });
                                        }}
                                        size={"large"}
                                    />
                                </div>

                                <div
                                    className="flex justify-between "
                                >
                                    <DropdownComponent
                                        labeltext={"To Month"}
                                        width={"200px"}
                                        className="general-ant-field-input"
                                        value={fieldDetails.toMonth}
                                        values={monthList.map((_val, index) => index)}
                                        options={monthList}
                                        onChange={(e) => {
                                            let fieldDetailsData = fieldDetails;
                                            setFieldDetails({
                                                ...fieldDetailsData,
                                                toMonth: e,
                                            });
                                        }}
                                        size={"large"}
                                    />
                                    <div style={{ marginLeft: "8px" }} />
                                    <DropdownComponent
                                        labeltext={"To Year"}
                                        width={"140px"}
                                        className="general-ant-field-input"
                                        value={fieldDetails.toYear}
                                        values={currentYearList.map((_val, index) => _val)}
                                        options={currentYearList}
                                        showSearch={true}
                                        onChange={(e) => {
                                            let fieldDetailsData = fieldDetails;
                                            setFieldDetails({
                                                ...fieldDetailsData,
                                                toYear: e,
                                            });
                                        }}
                                        size={"large"}
                                    />
                                </div>

                            </div>
                        }
                        <div className="org-anb-data-container p-top-8"
                        >
                            <InputFieldComponent
                                className="general-ant-field-input"
                                width={"100%"}
                                type={"number"}
                                labeltext={"Total Amount"}
                                value={parseInt(fieldDetails.totalAmount)}
                                onChange={(e) => {
                                    let fieldDetailsData = fieldDetails;
                                    setFieldDetails({
                                        ...fieldDetailsData,
                                        totalAmount: isNaN(parseInt(e.target.value))
                                            ? 0
                                            : parseInt(e.target.value),
                                    });
                                }}
                                placeholder="Amount"
                                size={"large"}
                                description={!fieldDetails.isOneTime && tenure ? `Distributed Per-month Amount: ${fieldDetails.totalAmount} / ${tenure} = ${Math.round(fieldDetails.totalAmount / tenure)} (excl. LOP)` : ""}
                            />
                        </div>

                        <NormalButton
                            type={13}
                            buttonText={"Add to the month end data"}
                            block={"100%"}
                            onClick={onSave}
                            margin={[30, 0]}
                        />
                    </Spin>
                </div>

            </Modal>
            <div className="flex justify-between align-center">
                <h2 className="app-heading-2 font-weight-global-700 font-size-global-24 ">Arrears Month Closing Data ( {monthCloseCount === 0 ? monthCloseFields.length : monthCloseCount} )</h2>
                <div style={{ display: "flex" }}>
                    <RefreshButton method={true} refreshMethod={startRefresh} />
                    <Button
                        onClick={() => setSingleModalShow(true)}
                        type={13}
                        className={`button-lib button-type-13 p-10`}
                        style={{ height: "2.5rem", paddingRight: "1.5em" }}
                    >
                        <div className="flex justify-between align-center">
                            <img style={{ height: 18, width: 18 }} alt="pri-icon" src={plus} className="button-lib-icon"></img>
                            Add
                        </div>
                    </Button>
                </div>
                {/* <NormalButton buttonText={"Add"} type={13} icon={plus} secondaryIcon={downoutined} /> */}
            </div>
            <Spin spinning={loading} size="large">
                <Table
                    pagination={false}
                    scroll={{ y: 400, x: 500 }}
                    columns={columns}
                    dataSource={monthCloseFields.reverse()}
                    onChange={(pagination, filters, sorter, extra) => { setMonthCloseCount(extra.currentDataSource.length ?? monthCloseCount.length) }}
                />
            </Spin>
        </div>
    );
};

export default Arrears