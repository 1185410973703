
import Deduction from "./components/deduction.js";
import { HeaderComponent } from "../Common";
import activeDeclaration from "../../assets/icons/activeIcons/activeDeclaration.svg";
import { connect } from "react-redux";
import { useEffect } from "react";


const Deductions = (props) => {
  useEffect(() => {
    document.title = 'Declarations | Deductions';
  });
  return (
    <div className="site-layout">
      <HeaderComponent icon={activeDeclaration} name="Declaration" />
      <div className="paper-wrapper app-main-content-container">
        <Deduction organisationFreeze={props.organisationFreeze} />
      </div>
    </div>
  );
};

const DeductionsConainer = connect((state) => ({
  user: state.auth.user,
  declaration: state.employee.declaration,
  payslips: state.employee.payslips,
  organisationFreeze: state.employee.organisationFreeze,
  isCurrentFinancialYearJoinee: state.auth.isCurrentFinancialYearJoinee
}))(Deductions);

export default DeductionsConainer;
