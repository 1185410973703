import { useEffect, useState } from "react";
import config from "../../../utils/config";

const SettlementList = ({ settlementData, payslip }) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  console.log(payslip)
  useEffect(() => {
    let totalIncome =
      parseInt(payslip?.finalPayslip?.newRegime?.takeHome ?? 0) +
      parseInt(settlementData.leaveEncashment ?? 0) +
      parseInt(settlementData.gratuityAmount ?? 0) +
      parseInt(settlementData.otherCreditAmount ?? 0) +
      parseInt(settlementData.pfAmount ?? 0);
    let totalDeduction =
      parseInt(settlementData.lossOfPay ?? 0) +
      parseInt(settlementData.otherDebitAmount ?? 0) +
      parseInt(settlementData.taxDeducted ?? 0);
    setTotalDeduction(totalDeduction);
    setTotalIncome(totalIncome);
    setTotalAmount(totalIncome - totalDeduction);
  }, [settlementData, payslip]);

  return (
    <section className="settlement-form">
      <h2 className="font-size-global-24 font-weight-global-700">Breakage</h2>
      <div className="individual-structure">
        <p className="break-label">Leave Encashment</p>
        <p className="break-value">
          {config.numformatter(settlementData.leaveEncashment)} INR
        </p>
      </div>
      {settlementData.gratuityEligible && (
        <div className="individual-structure">
          <p className="break-label">Gratuity</p>
          <p className="break-value">
            {config.numformatter(settlementData.gratuityAmount)} INR
          </p>
        </div>
      )}
      {settlementData.withdrawPF && (
        <div className="individual-structure">
          <p className="break-label">PF</p>
          <p className="break-value">
            {config.numformatter(settlementData.pfAmount)} INR
          </p>
        </div>
      )}
      {settlementData.otherCreditAmount !== 0 && (
        <div className="individual-structure">
          <p className="break-label">Other Credits</p>
          <p className="break-value">
            {config.numformatter(settlementData.otherCreditAmount)} INR
          </p>
        </div>
      )}
      <div className="individual-structure">
        <p className="break-label-earning">Earning</p>
        <p className="break-value-earning">
          {config.numformatter(totalIncome)} INR
        </p>
      </div>
      <div className="individual-structure">
        <p className="break-label">Tax Deducted</p>
        <p className="break-value">
          {config.numformatter(settlementData.taxDeducted)} INR
        </p>
      </div>
      <div className="individual-structure">
        <p className="break-label">Loss Of Pay</p>
        <p className="break-value">
          {config.numformatter(settlementData.lossOfPay)} INR
        </p>
      </div>
      {settlementData.otherDebitAmount !== 0 && (
        <div className="individual-structure">
          <p className="break-label">Other Debits</p>
          <p className="break-value">
            {config.numformatter(settlementData.otherDebitAmount)} INR
          </p>
        </div>
      )}
      <div className="individual-structure">
        <p className="break-label-deduction">Deduction</p>
        <p className="break-value-deduction">
          {config.numformatter(totalDeduction)} INR
        </p>
      </div>

      <div className="individual-structure-takehome">
        <p className="break-label">Final Amount</p>
        <p className="break-value">{config.numformatter(totalAmount)} INR</p>
      </div>
    </section>
  );
};

export default SettlementList;
