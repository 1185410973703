import { Modal, Button, Layout } from "antd";
import { connect } from "react-redux";
import config from "../../utils/config";
import PayslipFreeze from "./component/PayslipFreeze";
import { useEffect } from "react";
const { Content } = Layout;

const MonthCloseProcess = ({
  modal,
  setModal,
  user,
  updatePayrollData,
  month,
  financialYear,
  orgPayslipInfo,
  getPayslipFreeze
}) => {

  const closeRonPayroll = () => {
    setModal(false);
  };
  useEffect(() => {
    document.title = 'Month Closing Process';
  });
  return (
    <Modal footer={null} width={1000} closable={false} centered visible={modal}>
      <Content className="paper-wrapper w-full">
        <div className="paper-header-container">
          <h3 className="paper-heading" style={{fontWeight:"700"}}>
            Run Payroll for {config.monthList[month]}
          </h3>
          <div className="paper-heading-action">
            <Button onClick={closeRonPayroll} size={"large"} type="default">
              X
            </Button>
          </div>
        </div>
        <div className="paper-body-container">
          <PayslipFreeze
            user={user}
            organisationId={user.organisationId}
            closeRonPayroll={closeRonPayroll}
            updatePayrollData={updatePayrollData}
            month={month}
            financialYear={financialYear}
            orgPayslipInfo={orgPayslipInfo}
            getPayslipFreeze={getPayslipFreeze}
          />
        </div>
      </Content>
    </Modal>
  );
};

const MonthCloseProcessContainer = connect((state) => ({
  user: state.auth.user,
  employeeList: state.organisation.employeeList,
}))(MonthCloseProcess);

export default MonthCloseProcessContainer;
