import React, { useState, useEffect } from "react";
import { Table, Modal, Upload, notification, message, Drawer } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import {
  addReimbursement,
  deleteReimbursement,
  removeS3Object,
  updateReimbursement,
  employeeReimbursementByMonth,
} from "../../apis/employee";
import {
  NormalButton,
  FileViewer,
  FileLogos,
  Status,
} from "../ComponentLibrary";
import config from "../../utils/config";
import activeReimbursement from "../../assets/icons/activeIcons/activeReimbursement.svg";
import { HeaderComponent } from "../Common";
import { deleteLogo, editLogo } from "../../assets/common/editAndDeleteLogos";
import employee from "../../redux/modules/employee";
import { createLog, getDocumentURL } from "../../apis/user";
import plus from "../../assets/icons/whitePlus.svg";
import DateComponent from "../InputLibrary/DateComponent/DateComponent";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import InputNumberComponent from "../InputLibrary/InputNumberComponent/InputNumberComponent";
import TextAreaComponent from "../InputLibrary/TextAreaComponent/TextAreaComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";

import packageJson from "../../../package.json";

// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";

// Plugins
import {
  defaultLayoutPlugin,
  // ToolbarProps,
  // ToolbarSlot,
} from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// const { Option } = Select;
const { Dragger } = Upload;

const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];

const EmployeeReimbursement = ({ user, employeeReducer }) => {
  const [reimbursementsData, setReimbursementData] = useState([]);
  const [isDocViewModalVisible, setIsDocViewModalVisible] = useState(false);
  // const [modalIndex, setModalIndex] = useState(0);
  const [newReimbursementData, setnewReimbursementData] = useState({
    type: null,
    reimbursementBillDate: new moment(),
    details: null,
    amount: null,
    documents: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [docType, setDocType] = useState("");
  // const [isOpen, setIsOpen] = useState(false);
  const [currentReimbursements, setCurrentReimbursements] = useState({});

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [
    isDeleteReimbursementModalVisible,
    setIsDeleteReimbursementModalVisible,
  ] = useState(false);
  const [
    currentDeleteReimbursementObj,
    setCurrentDeleteReimbursementObj,
  ] = useState({});
  const [viewFile, setViewFile] = useState(false);
  const [viewFileDocumentProps, setViewFileDocumentProps] = useState({});
  const [
    viewFileDocumentVerifyProps,
    setViewFileDocumentVerifyProps,
  ] = useState({});
  const [isReasonModalVisible, setisReasonModalVisible] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [reasonForRejection, setreasonForRejection] = useState("");
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [
    currentUpdateReimbursementObj,
    setCurrentUpdateReimbursementObj,
  ] = useState({
    reimbursementId: "",
    type: null,
    reimbursementBillDate: new moment(),
    details: "",
    amount: "",
  });
  const [month, setMonth] = useState(Number(moment().format("M")) - 1);
  const [financialYear, setFinancialYear] = useState(
    config.checkFinancialYear().financialYear
  );
  useEffect(() => {
    document.title = "Salary | Reimbursement";
  });
  const [reportDate, setReportDate] = useState(moment());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    updateReimbursements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeReducer, financialYear, month, user]);
  useEffect(() => {
    updateReimbursements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  const updateReimbursements = async () => {
    employeeReducer.employeeReimbursement(user._id);
    employeeReimbursementByMonth(`${user._id}/${month}/${financialYear}`).then(
      (res) => {
        setReimbursementData(res.data);
      }
    );
  };
  const handelNewReimbursementDataChange = (event) => {
    setnewReimbursementData({
      ...newReimbursementData,
      details: event.target.value,
    });
  };
  console.log(reimbursementsData);
  const requestReimbursement = async () => {
    if (
      !newReimbursementData.type ||
      !newReimbursementData.reimbursementBillDate ||
      !newReimbursementData.details ||
      !newReimbursementData.amount ||
      !newReimbursementData.documents.length
    ) {
      notification.error({
        message: "Please enter every details",
      });
      return;
    }
    let tempUploadData = {
      reimbursement: newReimbursementData,
    };
    tempUploadData.reimbursement.organisationId = user.currentOrganisationId;
    tempUploadData.reimbursement.userId = user._id;
    addReimbursement(tempUploadData).then(async (response) => {
      if (response.data.success) {
        await createLog({
          name: user.displayName,
          userId: user._id,
          organisationId: user.organisationId,
          message: `User has raised a reimbursement request`,
          category: 11,
        });
        await updateReimbursements();
        notification.success({
          message: "Reimbursement request placed succesfully",
        });
        setOpen(false);
        setnewReimbursementData({
          type: null,
          reimbursementBillDate: new moment(),
          details: "",
          amount: "",
          documents: [],
        });
      } else {
        notification.error({
          message: "Failed to place request, Try again",
        });
      }
    });
  };

  const openDeleteReimbursementModal = (reimbursementData) => {
    setIsDeleteReimbursementModalVisible(true);
    setCurrentDeleteReimbursementObj(reimbursementData);
  };

  const closeDeleteReimbursementModal = () => {
    setIsDeleteReimbursementModalVisible(false);
    setCurrentDeleteReimbursementObj({});
  };

  const deleteReimbursementRequest = async () => {
    let response = await deleteReimbursement(
      currentDeleteReimbursementObj.reimbursementId
    );
    if (response.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has deleted a reimbursement`,
        category: 11,
      });
      await updateReimbursements();
      closeDeleteReimbursementModal();
      notification.success({
        message: "Reimbursement request has been deleted",
      });
    } else {
      notification.error({
        message: "Failed to delete request, Try again",
      });
    }
  };
  function beforeFileUpload(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  }
  const draggerProps = {
    maxCount: 3,
    name: "file",
    multiple: true,
    action: `${config.rootUrl}/api/document/reim`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    showUploadList: false,
    beforeUpload: beforeFileUpload,
    async onChange(info) {
      let { response } = info.file;
      if (response) {
        let documentDetails = {
          ...response.data.stored,
        };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        let allDocumentsDetails = newReimbursementData.documents;
        allDocumentsDetails.length !== 3 &&
          allDocumentsDetails.push(documentDetails);
        setnewReimbursementData({
          ...newReimbursementData,
          documents: allDocumentsDetails,
        });
      }
    },
  };

  const handelViewDocument = async (fileProps) => {
    setViewFile(true);
    let tempFileProps = {
      documentKey: fileProps.key,
      documentType: fileProps.key.split(".").pop(),
      documentName: fileProps.key.split("reimbursement-").pop(),
    };
    let tempViewFileDocumentVerifyProps = {
      showVerificationStatus: false,
    };
    setViewFileDocumentProps(tempFileProps);
    setViewFileDocumentVerifyProps(tempViewFileDocumentVerifyProps);
    setIsDocViewModalVisible(false);
    const doc = await getDocumentURL({
      Key: tempFileProps.documentKey,
    });
    setUrl(doc.data);
    if (tempFileProps.documentType === "pdf") {
      setDocType("pdf");
      showModal();
    } else {
      setDocType("image");
    }
  };

  const showReasonModal = (data) => {
    setisReasonModalVisible(true);
    setreasonForRejection(data.statusMessage);
  };

  const closeReasonModal = () => {
    setisReasonModalVisible(false);
    setreasonForRejection("");
  };

  const removeFileFromUploadList = async (index) => {
    let tempReimbursementDocuments = newReimbursementData.documents;
    let reim = tempReimbursementDocuments.splice(index, 1);
    await removeS3Object({
      Key: reim[0].Key,
    });
    setnewReimbursementData({
      ...newReimbursementData,
      documents: tempReimbursementDocuments,
    });
  };

  const openUpdateModal = (data) => {
    setCurrentUpdateReimbursementObj({
      reimbursementId: data.reimbursementId,
      type: data.type,
      reimbursementBillDate: moment(data.reimbursementBillDate),
      details: data.details,
      amount: data.amount,
    });
    setIsUpdateModalOpen(true);
  };

  const closeUpdateModal = () => {
    setCurrentUpdateReimbursementObj({
      reimbursementId: "",
      type: null,
      reimbursementBillDate: new moment(),
      details: "",
      amount: "",
    });
    setIsUpdateModalOpen(false);
  };

  const updateReimbursementData = async () => {
    let response = await updateReimbursement(currentUpdateReimbursementObj);
    if (response.data.success) {
      await updateReimbursements();
      setCurrentUpdateReimbursementObj({});
      setIsUpdateModalOpen(false);
      notification.success({
        message: "Reimbursement details has been updated",
      });
    } else {
      notification.error({
        message: "Failed to update details of reimbursement, Please try again",
      });
    }
  };
  const updateReimbursementFields = (e) => {
    setCurrentUpdateReimbursementObj({
      ...currentUpdateReimbursementObj,
      [e.target.name]: e.target.value,
    });
  };

  const handelDateChange = (date) => {
    let tempMonth = Number(moment(date).format("M"));
    let tempYear = Number(moment(date).format("yyyy"));
    let tempFinancialYear =
      tempMonth > 3
        ? `${tempYear}-${tempYear + 1}`
        : `${tempYear - 1}-${tempYear}`;
    setMonth(tempMonth - 1);
    setFinancialYear(tempFinancialYear);
    setReportDate(date);
    employeeReimbursementByMonth(
      `${user._id}/${tempMonth - 1}/${tempFinancialYear}`
    ).then((res) => {
      setReimbursementData(res.data);
    });
  };

  const onClose = () => {
    setOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const showDocViewModal = () => {
    setIsDocViewModalVisible(true);
  };
  const closeDocViewModal = () => {
    setIsDocViewModalVisible(false);
  };

  const renderToolbar = (Toolbar) =>
    React.createElement(Toolbar, null, (slots) => {
      const {
        CurrentPageInput,
        Download,
        EnterFullScreen,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        // Print,
        // ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
      } = slots;
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            <ZoomOut />
          </div>
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            <Zoom />
          </div>
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            <ZoomIn />
          </div>
          <div
            style={{
              padding: "0px 2px",
              marginLeft: "auto",
            }}
          >
            <GoToPreviousPage />
          </div>
          <div
            style={{
              padding: "0px 2px",
              width: "4rem",
            }}
          >
            <CurrentPageInput />
          </div>
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            / <NumberOfPages />
          </div>
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            <GoToNextPage />
          </div>
          <div
            style={{
              padding: "0px 2px",
              marginLeft: "auto",
            }}
          >
            <EnterFullScreen />
          </div>
          <div
            style={{
              padding: "0px 2px",
            }}
          >
            <Download />
          </div>
        </div>
      );
    });

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "reimbursementBillDate",
      key: "reimbursementBillDate",
      render: (reimbursementBillDate) =>
        moment(reimbursementBillDate).format("ll"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
      render: (text) => <p>{config.reimbursementTypes[text]}</p>,
    },
    {
      title: "Reason",
      dataIndex: "details",
      key: "details",
      ellipsis: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      ellipsis: true,
    },
    {
      title: "Attachment",
      dataIndex: "documents",
      key: "documents",
      ellipsis: true,
      render: (item, record, index) => {
        return (
          <div>
            <NormalButton
              buttonText={"View"}
              onClick={() => {
                setCurrentReimbursements(record);
                showDocViewModal();
              }}
              type={13}
            />
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      width: "29%",
      render: (state, data) => (
        <div>
          {state === 0 ? (
            <>
              <Status type={1} text="Pending" margin={[0, 8]} />
              <NormalButton
                type={5}
                icon={editLogo}
                onClick={() => openUpdateModal(data)}
              />
              <NormalButton
                type={5}
                icon={deleteLogo}
                onClick={() => openDeleteReimbursementModal(data)}
              />
            </>
          ) : state === 1 ? (
            <Status type={2} text="Accepted" />
          ) : (
            <>
              <Status type={3} text="Rejected" />
              <NormalButton
                type={5}
                buttonText="Reason?"
                margin={[0, 24]}
                onClick={() => showReasonModal(data)}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  const startRefresh = () => {
    setIsRefresh(!isRefresh);
  };
  return (
    <>
      {/* <FileViewer
        visible={viewFile}
        onclose={setViewFile}
        fileProps={viewFileDocumentProps}
        verifyProps={viewFileDocumentVerifyProps}
      /> */}
      <div className="site-layout">
        <HeaderComponent icon={activeReimbursement} name="Reimbursement" />
        <div className="app-main-content-container reimbursement-container">
          <div className="flex justify-between align-cener">
            <h1 className="page-heading">All Claims</h1>

            <div className="flex align-center">
              <NormalButton
                buttonText={"Refresh"}
                onClick={startRefresh}
                type={13}
              />
              <NormalButton
                buttonText="Add"
                onClick={showDrawer}
                type={13}
                icon={plus}
              />
              <DateComponent
                format={"YYYY - MM"}
                className="app-date-picker month-picker"
                onChange={handelDateChange}
                picker={"month"}
                value={reportDate}
                labelText={"Financial year"}
                type={"month"}
              />
            </div>
          </div>
          <Table
            pagination={false}
            className="reimbursement-table"
            columns={columns}
            dataSource={reimbursementsData.reverse()}
            scroll={{
              x: 900,
              y: 700,
            }}
          />
        </div>
      </div>
      {docType === "image" ? (
        <FileViewer
          visible={viewFile}
          onclose={setViewFile}
          fileProps={viewFileDocumentProps}
          verifyProps={viewFileDocumentVerifyProps}
        />
      ) : (
        <></>
      )}

      <Modal
        title={
          <>
            <FileLogos type={1} /> {viewFileDocumentProps.documentName}
          </>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <div>
          {url ? (
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={url}
                plugins={[defaultLayoutPluginInstance]}
                renderError={console.log}
              />
            </Worker>
          ) : (
            "Document URL not found"
          )}
        </div>
      </Modal>
      <Drawer
        title="Edit"
        placement="right"
        onClose={onClose}
        visible={open}
        size={"large"}
      >
        <div className="drawer">
          <div className="claim-reimbursement-container">
            <h2 className="claim-reimbursement-heading">New Reimbursement</h2>
            <div
              className="claim-reimbursement-data-container"
              style={{
                display: "flex",
              }}
            >
              <DropdownComponent
                className="general-ant-field-select "
                labeltext={"Type of reimbursement"}
                style={{
                  width: "50%",
                }}
                size={"large"}
                value={newReimbursementData.type}
                options={Object.values(config.reimbursementTypes)}
                values={Object.keys(config.reimbursementTypes)}
                onChange={(e) =>
                  setnewReimbursementData({
                    ...newReimbursementData,
                    type: e,
                  })
                }
              ></DropdownComponent>
              <InputNumberComponent
                style={{
                  width: "50%",
                }}
                min={0}
                onChange={(e) =>
                  setnewReimbursementData({
                    ...newReimbursementData,
                    amount: e,
                  })
                }
                labeltext={"Amount"}
                value={newReimbursementData.amount}
              />
            </div>
            <DateComponent
              format="DD-MM-YYYY"
              className="app-date-picker month-picker"
              onChange={(e) => {
                let date = e === null ? "" : e;
                setnewReimbursementData({
                  ...newReimbursementData,
                  reimbursementBillDate: date,
                });
              }}
              width="100%"
              value={newReimbursementData.reimbursementBillDate}
              labelText={"Expense Date"}
            />
            <div className="claim-reimbursement-data-container">
              <label className="general-ant-field-label">Reason</label>
              <TextAreaComponent
                onChange={handelNewReimbursementDataChange}
                value={newReimbursementData.details}
                row={10}
              />
            </div>

            <div>
              {newReimbursementData.documents.map((value, index) => {
                return (
                  <div
                    key={`upload-doc-main-${index}`}
                    className="uploaded-docs-card"
                  >
                    <div className="uploaded-docs-left">
                      <FileLogos
                        type={
                          value.key.split(".").pop() === "pdf"
                            ? 1
                            : value.key.split(".").pop() === "jpg"
                            ? 2
                            : value.key.split(".").pop() === "svg"
                            ? 3
                            : 4
                        }
                        margin={[0, 5]}
                      />
                      <span className="uploaded-docs-card-name">
                        {`reimbursement-${index}.${value.key.split(".").pop()}`}
                      </span>
                    </div>
                    <NormalButton
                      type={5}
                      margin={[0, 0]}
                      icon={deleteLogo}
                      onClick={() => removeFileFromUploadList(index)}
                    />
                  </div>
                );
              })}
            </div>
            <div>
              {newReimbursementData.documents.length < 3 ? (
                <Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">Upload max of 3 files</p>
                </Dragger>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                marginTop: "20%",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <NormalButton
                  buttonText="Request Now"
                  onClick={requestReimbursement}
                  type={13}
                  heightWidth={["auto", "25%"]}
                />
                <NormalButton
                  buttonText="Cancel"
                  onClick={requestReimbursement}
                  type={5}
                  heightWidth={["auto", "25%"]}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>

      <Modal
        className="rejection-modal"
        visible={isReasonModalVisible}
        onCancel={closeReasonModal}
        centered
        footer={null}
        closable={false}
      >
        <h3 className="rejection-modal-header">Reason for rejection</h3>
        <div className="rejection-text-box">{reasonForRejection}</div>
        <div className="rejection-button">
          <NormalButton
            type={2}
            buttonText={"close"}
            onClick={closeReasonModal}
          ></NormalButton>
        </div>
      </Modal>

      <Modal
        className="rejection-modal"
        visible={isDeleteReimbursementModalVisible}
        onCancel={closeDeleteReimbursementModal}
        centered
        footer={null}
        closable={false}
      >
        <h3 className="rejection-modal-header">
          Are you sure you want to delete the reimbursement record
        </h3>
        <div className="rejection-button">
          <NormalButton
            type={5}
            buttonText={"Cancel"}
            onClick={closeDeleteReimbursementModal}
          ></NormalButton>
          <NormalButton
            type={13}
            buttonText="Delete"
            onClick={deleteReimbursementRequest}
            margin={[0, 8]}
          />
        </div>
      </Modal>

      <Modal
        className="update-reimbursement-modal"
        visible={isUpdateModalOpen}
        onCancel={closeUpdateModal}
        centered
        footer={null}
        closable={false}
        width={400}
      >
        <div className="update-reimbursement-modal-body">
          <h3 className="update-reimbursement-header">
            Update reimbursement data
          </h3>
          <div className="claim-reimbursement-data-container ">
            <DropdownComponent
              className="general-ant-field-select "
              labeltext={"Type of reimbursement"}
              style={{
                width: "100%",
              }}
              size={"large"}
              value={currentUpdateReimbursementObj.type}
              options={Object.values(config.reimbursementTypes)}
              values={Object.keys(config.reimbursementTypes)}
              onChange={(e) =>
                setCurrentUpdateReimbursementObj({
                  ...currentUpdateReimbursementObj,
                  type: e,
                })
              }
            ></DropdownComponent>
          </div>
          <div className="claim-reimbursement-data-container">
            <DateComponent
              format="DD-MM-YYYY"
              className="app-date-picker month-picker"
              onChange={(e) => {
                let date = e === null ? "" : e;
                setCurrentUpdateReimbursementObj({
                  ...currentUpdateReimbursementObj,
                  reimbursementBillDate: date,
                });
              }}
              width="90%"
              value={currentUpdateReimbursementObj.reimbursementBillDate}
              labelText={"Expense Date"}
            />
            {/* <DatePicker
              name="reimbursementBillDate"
              onChange={(e) => {
                let date = e === null ? "" : e;
                setCurrentUpdateReimbursementObj({
                  ...currentUpdateReimbursementObj,
                  reimbursementBillDate: date,
                });
              }}
              value={currentUpdateReimbursementObj.reimbursementBillDate}
              className="general-ant-field-input"
              style={{ width: "100%" }}
              size="large"
              format="DD-MM-YYYY"
            /> */}
          </div>
          <div className="claim-reimbursement-data-container">
            <InputFieldComponent
              labeltext={"Reason"}
              className="general-ant-field-input"
              bordered={false}
              style={{
                width: "100%",
              }}
              name="details"
              onChange={updateReimbursementFields}
              value={currentUpdateReimbursementObj.details}
            />
          </div>
          <div className="claim-reimbursement-data-container">
            <InputFieldComponent
              labeltext={"Amount"}
              className="general-ant-field-input"
              style={{
                width: "100%",
                marginTop: "-30px",
              }}
              name="amount"
              type="number"
              onChange={updateReimbursementFields}
              value={currentUpdateReimbursementObj.amount}
              bordered={false}
            />
          </div>
          <div className="update-reimbursement-modal-button">
            <NormalButton
              type={5}
              buttonText={"Close"}
              onClick={closeUpdateModal}
              margin={[0, 8]}
            ></NormalButton>
            <NormalButton
              type={1}
              buttonText="Update"
              onClick={updateReimbursementData}
              margin={[0, 8]}
            />
          </div>
        </div>
      </Modal>
      {isDocViewModalVisible && (
        <Modal
          className="update-reimbursement-modal"
          visible={isDocViewModalVisible}
          onCancel={closeDocViewModal}
          centered
          footer={null}
          closable={false}
          width={400}
        >
          <div
            style={{
              marginBottom: "1em",
            }}
            className="font-size-global-24 font-weight-global-700"
          >
            View Documents
          </div>
          {currentReimbursements.documents.map((document, e) => {
            return (
              <div
                style={{
                  display: "inline",
                }}
              >
                <NormalButton
                  type={7}
                  heightWidth={[40, 40]}
                  padding={[0, 0]}
                  margin={[0, 8]}
                  buttonText={`#${e + 1}`}
                  onClick={() => handelViewDocument(document)}
                  className="attachment-buttons"
                />
              </div>
            );
          })}
        </Modal>
      )}
    </>
  );
};
const EmployeeReimbursementContainer = connect(
  (state) => ({
    user: state.auth.user,
    reimbursements: state.employee.reimbursements,
  }),
  (dispatch) => ({
    employeeReducer: employee.getActions(dispatch),
  })
)(EmployeeReimbursement);

export default EmployeeReimbursementContainer;
