import { useState } from "react";
import { Layout, Button, Spin, Checkbox, notification, message } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import eulaTemplate from "../../../../utils/eula";
import { updateEula } from "../../../../apis/employee";

const { Content } = Layout;

const RegimeSelection = ({ changePageState, user }) => {
  const [loading, setLoading] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);

  const updateEULA = async () => {
    if (!termsAndCondition) {
      message.error("Please accept the Terms and Conditions")
      return;
    }
    let body = {
      id: user._id,
      eula: {
        appLevel: true,
        regimeOptedStatus: false,
        payslipCustomization: true,
      },
    };
    if (termsAndCondition) {
      setLoading(true);
      await updateEula(body);
      setLoading(false);
      changePageState();
    } else {
      notification.error({
        message: "Please verify and accept the terms and condition",
      });
      return;
    }
  };

  return (
    <Spin spinning={loading} delay={500} size="large">
      <Content className="stages">
        <div className="main-container">
          <div className="allowances-container employee">
            <div
              style={{ background: "#ffffff", paddingRight: "1rem", textAlign: "justify" }}
              id="clickWrap"
              className="eulabox"
              dangerouslySetInnerHTML={{ __html: eulaTemplate.appLevel }}
            />
          </div>
        </div>
        <div className="terms-and-conditions-container">
          <Checkbox checked={termsAndCondition}
            onClick={(_data) => setTermsAndCondition(!termsAndCondition)}>I accept the terms and condition</Checkbox>
        </div>
        <div className="submit-container">
        <Button
          loading={loading}
          onClick={updateEULA}
          className="submit-button"
          style={{ background: '#405cd2', padding: '15px', height: '60px', fontSize: '1.125rem' }}
          htmlType="submit"
        >
          Save and Submit
        </Button>
      </div>
      </Content>
    </Spin>
  );
};

const RegimeSelectionContainer = connect(
  (state) => ({
    user: state.auth.user
  }),
)(RegimeSelection);

export default withRouter(RegimeSelectionContainer);
