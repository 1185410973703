import React, { useState } from "react";
import EmployeeDocs from "../../../AllInvestementAllowance/Components/EmployeeDocs";
import { FileViewer } from "../../../ComponentLibrary";

const Allowance = ({ user, orgInfo, financialYear }) => {
  const [viewFile, setViewFile] = useState(false);
  const [viewFileDocumentProps, setviewFileDocumentProps] = useState({});
  const [
    viewFileDocumentVerifyProps,
    setviewFileDocumentVerifyProps,
  ] = useState({});

  return (
    <>
      <FileViewer
        visible={viewFile}
        onclose={setViewFile}
        fileProps={viewFileDocumentProps}
        verifyProps={viewFileDocumentVerifyProps}
      />
      <div className="employee-deduction-file-container">
        <div className="employee-upload-files-container">
          <div className="deduction-header">
            <h1 className="app-heading-3">Allowance List</h1>
          </div>
          <EmployeeDocs
            employeeDetails={user}
            organisation={orgInfo}
            setViewFile={setViewFile}
            id={user._id}
            setViewFileDocumentVerifyProps={setviewFileDocumentVerifyProps}
            setViewFileDocumentProps={setviewFileDocumentProps}
            AllowanceOrDeduction={true}
            financialYear={financialYear}
          />
        </div>
      </div>
    </>
  );
};

export default Allowance;
