import { HeaderComponent } from "../Common";
import { Menu, Row, Col } from "antd";
import Payslip from "./PayslipSync";
import Declaration from "./DeclarationSync";
import Allowances from "./AllowancesSync";
import PersonalInformationContainer from "./PersonalInfomationsSync";
import AttendanceContainer from "./Attendance";
import { useState, useEffect } from "react";
import { handleAction } from "../../constants/common_functions";
import { DATA_SYNC } from "../../constants/components";
import organisation from "../../redux/modules/organisation";
import { connect } from "react-redux";
import PercentageStructure from "./PercentageStrucutre";
import HRAAllowances from "./HRASync";
import "../DataSync/DataSync.less"
import TotalWorkingDays from "./TotalWorkingDays";
import OvertimeDays from "./OvertimeConfiguration";
import TrialPage from "../Common/TrialComponent";
import syncEmployeeDataBgImage from "../../assets/dummyPages/syncEmployeeDataBgImage.png"
import AdvanceTax from "./AdvanceTax";
import FormCBulk from "./FormCBulk";

const DataSync = ({
  user,
  isTrial,
  organisationReducer,
  onboarding,
  groups,
  employeeList
}) => {
  const [currentSelectedTab, setCurrentSelectedTab] = useState("0");

  const componentArray = [
    <Payslip
      user={user}
      onboarding={onboarding}
      organisationReducer={organisationReducer}
      handleAction={handleAction}
    />,
    <Declaration
      user={user}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <Allowances
      groups={groups}
      user={user}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <AttendanceContainer
      user={user}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <HRAAllowances
      user={user}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"contactInfo"}
      name={"Contact Info"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"credentials"}
      name={"Credentials"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"bankInfo"}
      name={"Bank Informations"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"address"}
      name={"Address"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"familyDetails"}
      name={"Family Details"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"nominee"}
      name={"Nominee"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"emergencyContact"}
      name={"Emergency Contact"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"ipAddress"}
      name={"Employee IP Address"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"regime"}
      name={"Regime Status"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"ctc"}
      name={"Cost To Company"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"doj"}
      name={"Update Date Of Joining"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"leave"}
      name={"Leave Balance"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PersonalInformationContainer
      user={user}
      employeeList={employeeList}
      type={"employeeId"}
      name={"Employee ID"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,

    <AdvanceTax
      user={user}
      employeeList={employeeList}
      type={"employeeId"}
      name={" Advance Tax"}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <TotalWorkingDays
      user={user}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <OvertimeDays
      user={user}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <FormCBulk
      user={user}
      handleAction={handleAction}
      name={" Form C Bulk"}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
    <PercentageStructure
      groups={groups}
      user={user}
      handleAction={handleAction}
      commonColumn={DATA_SYNC.UPLOAD_TABLE_COLUMN}
    />,
  ];
  useEffect(() => {
    document.title = 'Employee | Data Sync';
  });
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Migrate seamlessly from your existing setup."} bgImage={syncEmployeeDataBgImage}
    /> :
      <div className="site-layout">
        <HeaderComponent
          backButton={false}
          name={"Sync Employee Data"}
          showMoolCoins={true}
        />
        <div className="app-main-content-container paper-wrapper">
          <Row className="blue-menu-container">
            <Col xs={4} md={4} lg={4} xl={4}>
              <Menu
                style={{
                  marginRight: 16,
                  height: "100%"
                }}
                defaultSelectedKeys={[currentSelectedTab]}
                defaultOpenKeys={[currentSelectedTab]}
                selectedKeys={currentSelectedTab}
                mode="inline"
                onClick={e => {
                  setCurrentSelectedTab(e.key);
                }}
                className="sync-data-tabs"
              >
                {DATA_SYNC.TAB_PANES.map(_data =>
                  <Menu.Item key={_data.key}>
                    {_data.name}
                  </Menu.Item>
                )}
                {user.moolSettings.isPercentageStructure &&
                  <Menu.Item key={22}>Percentage Structure Update</Menu.Item>}
              </Menu>
            </Col>
            <Col xs={20} md={20} lg={20} xl={20}>
              <div
                style={{
                  marginLeft: 30
                }}
              >
                {componentArray[currentSelectedTab]}
              </div>
            </Col>
          </Row>
        </div>
      </div>
  );
};

const DataSyncContainer = connect(
  state => ({
    user: state.auth.user,
    onboarding: state.organisation.onboarding,
    groups: state.organisation.groups,
    employeeList: state.organisation.employeeList,
    isTrial: state.auth.user.isTrial,
  }),
  dispatch => ({
    organisationReducer: organisation.getActions(dispatch)
  })
)(DataSync);

export default DataSyncContainer;
