import { Modal, notification, Select, Table } from "antd";
import {
  getHolidayListData,
  createNewHoliday,
  deleteHoliday,
} from "../../../../apis/organisation";
import { useState, useEffect } from "react";
import { NormalButton } from "../../../ComponentLibrary";
import moment from "moment";
import {
  editLogo,
  deleteLogo,
} from "../../../../assets/common/editAndDeleteLogos";
import plusSymbol from "../../../../assets/common/plusSymbol.svg"
import { monthList } from "../../../../utils/config";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import { createLog } from "../../../../apis/user";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import DateComponent from "../../../InputLibrary/DateComponent/DateComponent";

const { Option } = Select;
const holidayType = ["National", "Regional", "Company"];

const Holiday = ({ user, groups }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAddHolidayModal, setShowAddHolidayModal] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [holidayData, setHolidayData] = useState({
    organisationId: user.organisationId,
    name: "",
    description: "---",
    date: null,
    holidayType: null,
  });
  const [editFunction, setEditFunction] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [editOrDeleteHolidayData, setEditOrDeleteHolidayData] = useState({});
  const [showDeleteHolidayModal, setShowDeleteHolidayModal] = useState(false);
  const [holidayYear, setHolidayYear] = useState(new Date().getFullYear());
  const [yearList, setYearList] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);


  const getHolidayList = async () => {
    setIsLoading(true);
    let res = await getHolidayListData(
      { year: Number(holidayYear), groupId: selectedGroup },
      user.organisationId
    );
    if (res.status === 200) {
      setHolidayList(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getHolidayList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, holidayYear, isRefresh]);

  useEffect(() => {
    let currentYear = new Date().getFullYear();
    let list = [];
    for (let i = currentYear - 5; i < currentYear + 5; i++) {
      list.push(i);
    }
    setYearList([...list]);
  }, [user, isRefresh]);

  const closeModal = () => {
    setShowAddHolidayModal(false);
    setHolidayData({
      organisationId: user.organisationId,
      name: "",
      description: "---",
      date: null,
      holidayType: null,
    });
    setEditFunction(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "holidayType",
      key: "Type",
      render: (type) => <div>{holidayType[type]}</div>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => (
        <p>{`${monthList[new Date(date).getMonth()]} ${new Date(
          date
        ).getDate()} ${new Date(date).getFullYear()}`}</p>
      ),
    },
    {
      title: "",
      dataIndex: "holidayId",
      key: "holidayId",
      render: (_holidayId, rowData) => (
        <div className="flex justify-end align-center">
          <NormalButton
            icon={editLogo}
            type={5}
            onClick={() => {
              setEditFunction(true);
              setHolidayData({
                ...rowData,
                date: moment(rowData.date),
              });
              setEditOrDeleteHolidayData(rowData);
              setShowAddHolidayModal(true);
            }}
          />
          <NormalButton
            icon={deleteLogo}
            type={5}
            margin={[0, 10]}
            onClick={() => {
              setShowDeleteHolidayModal(true);
              setHolidayData({
                ...rowData,
                date: moment(rowData.date),
              });
              setEditOrDeleteHolidayData(rowData);
            }}
          />
        </div>
      ),
    },
  ];

  const createHoliday = async () => {
    if (holidayData.name === "") {
      notification.error({ message: "Name of the holiday cannot be empty." });
      return;
    }
    if (holidayData.data === null) {
      notification.error({ message: "Date of holiday cannot be empty." });
      return;
    }
    if (holidayData.holidayType === null) {
      notification.error({ message: "Please select a holiday type." });
      return;
    }
    setIsLoading(true);
    let tempData = { ...holidayData };
    tempData.date = holidayData.date.format("MM/DD/YYYY");
    let res = await createNewHoliday({ ...tempData, groupId: selectedGroup });
    if (res.status === 200 && res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Organisaiton user has created a holiday`,
        category: 6,
      });
      await getHolidayList();
      notification.success({ message: "Successfully added the new holiday." });
      closeModal();
      return;
    } else {
      notification.error({
        message: "Failed to add the holiday, please try again.",
      });
    }
    setIsLoading(false);
  };

  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }

  const onDeleteConfirm = async () => {
    setIsLoading(true);
    let res = await deleteHoliday(editOrDeleteHolidayData.holidayId);
    if (res.status !== 200 && !res.data.success) {
      notification.error({
        message: "Failed to delete the holiday, please try again.",
      });
      setIsLoading(false);
      return;
    }
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organisaiton user has deleted a ${editOrDeleteHolidayData.name} holiday`,
      category: 6,
    });
    await getHolidayList();
    notification.success({ message: "Successfully deleted the holiday." });
    setShowDeleteHolidayModal(false);
  };

  return (
    <Spinner spinning={isLoading}>
      <div className="flex justify-between align-center">
        <div>
          <h1 className="font-size-global-24 font-weight-global-700">Holiday Settings</h1>
          <p className="title-gray-5 font-weight-global-500">
            Create a list of holidays for your organization
          </p>
        </div>
        <div className="flex align-center">
          Year
          <Select
            style={{ width: 100, marginLeft: 10 }}
            value={holidayYear}
            onChange={(e) => {
              if (parseInt(e)) {
                setHolidayYear(parseInt(e));
              }
            }}
          >
            {yearList.map((val, index) => (
              <Option key={`group-year-${index}`} value={val}>
                {val}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="flex justify-between align-center">
        <div className="flex justify-between align-center">
          <h1 className="app-heading-4 my-4 font-size-global-18 font-weight-global-700">Records ({holidayList.length})</h1>
        </div>
        <div className="flex align-center">
          <span style={{ marginRight: "1em" }}>
            <NormalButton
              onClick={startRefresh}
              buttonText={"Refresh"}
              type={13}
              className={`button-lib button-type-13 p-10`}
              style={{ height: "2.5rem" }}
            />
          </span>
          <p className="app-label-3"> Groups</p>
          <Select
            style={{ width: 200, marginLeft: 10 }}
            value={selectedGroup}
            onChange={(e) => {
              setSelectedGroup(e);
            }}
          >
            <Option value={""}>General</Option>
            {groups.map((val, index) => (
              <Option key={`group-dropdown-${index}`} value={val.groupId}>
                {val.name}
              </Option>
            ))}
          </Select>
          <NormalButton
            icon={plusSymbol}
            buttonText="Add New"
            type={13}
            onClick={() => setShowAddHolidayModal(true)}
          />
        </div>
      </div>
      <Table
        dataSource={holidayList.reverse()}
        columns={columns}
        pagination={holidayList.length < 10 ? false : { pageSize: 10 }}
        className="app-table"
      />
      <Modal
        visible={showAddHolidayModal}
        onCancel={closeModal}
        closable={false}
        centered={true}
        width={390}
        footer={false}
      >
        <Spinner spinning={isLoading}>
          <div className="add-new-leave-modal-heading">
            <h1 className=" m-top-8 font-size-global-24 font-weight-global-700 modal-margin-left-global-heading">Add New Holiday</h1>
          </div>
          <div className="">
            <div>
              <div className="app-input-field-container-1 modal-alignment-center-global ">
                <InputFieldComponent
                  labeltext={"Name"}
                  value={holidayData.name}
                  onChange={(e) => {
                    setHolidayData({
                      ...holidayData,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="app-input-field-container-1 modal-alignment-center-global modal-margin-top-reduce-global-3">
                <DateComponent
                  className="app-date-picker block"
                  format="MM/DD/YYYY"
                  value={holidayData.date}
                  onChange={(e) => {
                    setHolidayData({
                      ...holidayData,
                      date: e,
                    });
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }} className="app-input-field-container-1 modal-alignment-center-global modal-margin-top-reduce-global-1 ">
                <DropdownComponent
                  className="block app-select m-top-4"
                  value={holidayData.holidayType}
                  values={[0, 1, 2]}
                  options={holidayType}
                  labeltext={"Type"}
                  onChange={(e) => {
                    setHolidayData({
                      ...holidayData,
                      holidayType: e,
                    });
                  }}
                >
                </DropdownComponent>
              </div>
            </div>
          </div>
          <div className="text-align-left m-top-36 add-new-leave-modal-body modal-margin-left-global-button">
            <NormalButton
              buttonText={editFunction ? "Update" : "Submit"}
              type={13}
              onClick={createHoliday}
            />
            <NormalButton
              buttonText="Cancel"
              margin={[0, 10]}
              type={5}
              onClick={closeModal}
            />
          </div>
        </Spinner>
      </Modal>
      <Modal
        spinning={isLoading}
        centered
        closable={false}
        visible={showDeleteHolidayModal}
        className="modal-container"
        footer={null}
        onCancel={() => {
          showDeleteHolidayModal(false);
          editOrDeleteHolidayData({});
        }}
      >
        <Spinner spinning={isLoading}>
          <div className="">
            <div className="flex justify-between">
              <h1 className="app-heading-2">Delete Holiday</h1>
            </div>
            <div className="">
              <p className="danger-text font-size-16 font-weight-500">
                Are you sure you want to delete the holiday "
                {editOrDeleteHolidayData.name}"
              </p>
            </div>
            <div className="m-top-36  text-align-right">
              <NormalButton
                type={8}
                buttonText={"cancel"}
                margin={[0, 12]}
                onClick={() => {
                  setShowDeleteHolidayModal(false);
                  editOrDeleteHolidayData({});
                }}
              />
              <NormalButton
                type={5}
                buttonText="Delete"
                onClick={() => {
                  onDeleteConfirm();
                }}
              />
            </div>
          </div>
        </Spinner>
      </Modal>
    </Spinner>
  );
};

export default Holiday;
