import { Skeleton } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { getProofs } from "../../../../apis/employee";
import config from "../../../../utils/config";

const Summary = ({ user, financialYear }) => {
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalNotVerified, setTotalNotVerified] = useState(0);
  const [totalVerified, setTotalVerified] = useState(0);

  useState(() => {
    setLoading(true);
    getProofs(user._id)
      .then((data) => {
        let proofs = data.data;
        let tempSummaryData = {};
        let totalNotVerifiedValue = 0;
        let totalVerifiedValue = 0;
        proofs.forEach((value) => {
          if (value.category !== "Perquisites") {
            if (isNotVerified(value.documents)) {
              if (!tempSummaryData[value.category]) {
                let tempData = {
                  notVerified: value.amount,
                  verified: 0,
                };
                tempSummaryData[value.category] = tempData;
              } else
                tempSummaryData[value.category].notVerified += value.amount;
            } else {
              if (!tempSummaryData[value.category]) {
                let tempData = {
                  verified: value.amount,
                  notVerified: 0,
                };
                tempSummaryData[value.category] = tempData;
              } else tempSummaryData[value.category].verified += value.amount;
            }

            if (value.category !== "Allowances") {
              if (isNotVerified(value.documents)) {
                totalNotVerifiedValue += value.amount;
              } else {
                totalVerifiedValue += value.amount;
              }
            }
          }
        });
        delete tempSummaryData.Allowances;
        delete tempSummaryData.HRA;
        setSummary({ ...tempSummaryData });
        setTotalNotVerified(totalNotVerifiedValue);
        setTotalVerified(totalVerifiedValue);
        setLoading(false);
      })
      .catch((_err) => {
        setLoading(false);
      });
  }, [loading]);

  const isNotVerified = (docs) => {
    return docs.some((val) => val.verified === false);
  };

  return (
    <div className="summary-container">
      <h2 className="summary-heading">Total Investment</h2>
      <h3 className="summary-subheading">
        Summary of all the Investment in the FY{" "}
        {financialYear}
      </h3>
      <Skeleton loading={!summary} active>
        {summary &&
          Object.keys(summary).map((section) => (
            <div key={section}>
              <div className="investment-list-item-conatiner">
                <div>
                  <h4 className="investment-list-item-name">
                    {config.deducationList[section].sectionType}
                  </h4>
                  <span className="investment-list-item-type">
                    {config.deducationList[section].sectionName}
                  </span>
                </div>
                <div className="amount-container">
                  <div className="amount-details">
                    <span className="investment-list-item-header">
                      Verified
                    </span>
                    <span className="investment-list-item-price verified">
                      INR {config.numformatter(summary[section].verified)}
                    </span>
                  </div>
                  <div className="amount-details">
                    <span className="investment-list-item-header">
                      Not Verified
                    </span>
                    <span className="investment-list-item-price not-verified">
                      INR {config.numformatter(summary[section].notVerified)}
                    </span>
                  </div>
                </div>
              </div>
              <hr></hr>
            </div>
          ))}
      </Skeleton>
      <div className="total-price">
        <span className="total-price-heading">Total</span>
        <div className="amount-container-total">
          <div className="amount-details">
            <span className="investment-list-item-header">Not Verified</span>
            <span className="investment-list-item-price not-verified">
              INR {config.numformatter(totalNotVerified)}
            </span>
          </div>
          <div className="amount-details">
            <span className="investment-list-item-header">Verified</span>
            <span className="investment-list-item-price verified">
              INR {config.numformatter(totalVerified)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SummaryContainer = connect((state) => ({
  user: state.auth.user,
}))(Summary);

export default SummaryContainer;
