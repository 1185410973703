import OrganisationData from "./component/organisationData.js"
import { Row, Col } from 'antd';
import { HeaderComponent } from '../Common';
import activeSetting from "../../assets/icons/activeIcons/eac.svg"
import CompanyData from './component/CompanyData';
import { connect } from "react-redux";
import auth from "../../redux/modules/auth";
import GenInfo from "./component/GenInfo.js";
import { useEffect } from "react";
import TrialPage from "../Common/TrialComponent/index.js";
import perqsBgImage from "../../assets/dummyPages/perqsBgImage.png"




const OrganisationSetting = (props, isTrial, user) => {
    useEffect(() => {
        document.title = 'Account & Activity | Profile';
    });
    return (
        !isTrial ? <TrialPage user={user} trialMessage={"Designed for your needs, Mool is always at your service"} bgImage={perqsBgImage}
        /> :
            <div className="site-layout">
                <HeaderComponent show={false} showMoolCoins={true} icon={activeSetting} name="Profile" />
                <div className="app-main-content-container">
                    <h1 className="profile-heading font-size-global-24 font-weight-global-700">{`${props.user.displayName}'s profile`}</h1>
                    <Row gutter={[20, 20]}>
                        <Col xs={24} sm={24} md={7} lg={7}>
                            <OrganisationData />
                            <CompanyData />
                        </Col>
                        <Col xs={24} sm={24} md={17} lg={17}>
                            <GenInfo />
                        </Col>
                    </Row>
                </div>
            </div>

    )
}

const OrganisationSettingContainer = connect(
    (state) => ({
        user: state.auth.user,
        isTrial: state.auth.user.isTrial,

    }),
    (dispatch) => ({
        authReducer: auth.getActions(dispatch)
    })
)(OrganisationSetting)

export default OrganisationSettingContainer;