import { useState, useEffect } from "react";
import { message, Select, Spin, Radio, notification } from "antd";
import config, { fnfCalculationBasis, monthList } from "../../../utils/config";
import { NormalButton } from "../../ComponentLibrary";
import { createSingleLOPOverday } from "../../../apis/organisation";
import DropdownComponent from "../../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";
import InputNumberComponent from "../../InputLibrary/InputNumberComponent/InputNumberComponent";
let Option = Select;
const options = [
  { label: "Loss Of Pay", value: "lop" },
  { label: "Overday", value: "overtime" },
];

const OvertimeLOPFields = ({
  user,
  organisation,
  organisationSettings,
  employeeList,
  setSingleModalShow,
}) => {
  const [loading, setLoading] = useState(false);
  const [financialYearList, setFinancialYearList] = useState([]);

  const [fieldDetails, setFieldDetails] = useState({
    id: "",
    type: "",
    month: new Date().getMonth(),
    financialYear: "",
    days: null,
    component: organisationSettings.overtimeSetting.component,
    factor: organisationSettings.overtimeSetting.factor,
  });

  useEffect(() => {
    let startFYear = 2015;
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let resFinancialYearList = [];
    for (let i = startFYear; i < currentYear + 1; i++) {
      let nextYear = i + 1;
      let tempFYear = `${i}-${nextYear}`;
      resFinancialYearList.push(tempFYear);
    }
    setFinancialYearList([...resFinancialYearList]);
  }, [user]);

  const submitForm = async () => {
    if (!fieldDetails.id) {
      message.error("Please select an employee");
      return;
    }
    if (!fieldDetails.financialYear) {
      message.error("Please enter Financial Year");
      return;
    }
    if (!fieldDetails.month && fieldDetails.month !== 0) {
      message.error("Please enter month");
      return;
    }
    if (!fieldDetails.type) {
      message.error("Please enter the month close type");
      return;
    }
    if (!fieldDetails.factor) {
      message.error("Please enter factor");
      return;
    }
    if (!fieldDetails.days) {
      message.error("Please enter the number of days");
      return;
    }

    setLoading(true);
    const monthClose = {
      ...fieldDetails,
      organisationId: user.organisationId,
    };

    let response = await createSingleLOPOverday({
      ...monthClose,
    });
    setLoading(false);
    if (response.data.success) {
      notification.success({ message: "Successfully added the month close" });
    } else {
      notification.error({ message: "Unable to add the month close" });
    }
    organisation.updateMonthClose({ organisationId: user.organisationId });
    setFieldDetails({
      ...{
        id: "",
        type: "",
        month: new Date().getMonth(),
        financialYear: "",
        days: null,
        component: organisationSettings.overtimeSetting.component,
        factor: organisationSettings.overtimeSetting.factor,
      },
    });
    setSingleModalShow(false);
  };
  return (
    <div className="">
      <h2 className="font-size-global-24 font-weight-global-700 modal-margin-left-global-heading">
        Add Loss of Pay & Overday
      </h2>
      <Spin spinning={loading} size="large">
        <div className="org-anb-data-container p-top-15 modal-alignment-center-global">
          <DropdownComponent
            labeltext={"Employee Name"}
            className="general-ant-field-select"
            value={fieldDetails.id}
            values={employeeList.map((item) => item._id)}
            options={employeeList.map((item) => item.displayName)}
            onChange={(e) => {
              let fieldDetailsData = fieldDetails;
              setFieldDetails({
                ...fieldDetailsData,
                id: e,
              });
            }}
            style={{ width: "100%" }}
            bordered={false}
            filterOption={(input, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            showSearch
          >
            {employeeList.map((val, index) => {
              return (
                <Option key={`employees-${index}`} value={val._id}>
                  {val.displayName}
                </Option>
              );
            })}
          </DropdownComponent>
        </div>
        <div className="org-anb-data-container p-top-15 modal-alignment-center-global">
          <DropdownComponent
            className="general-ant-field-select"
            labeltext={"Financial Year"}
            value={fieldDetails.financialYear}
            values={financialYearList}
            options={financialYearList}
            onChange={(e) => {
              let fieldDetailsData = fieldDetails;
              setFieldDetails({
                ...fieldDetailsData,
                financialYear: e,
              });
            }}
            style={{ width: "100%" }}
            bordered={false}
            filterOption={(input, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            showSearch
          >
            {financialYearList.map((val, index) => {
              return (
                <Option key={`fyear-${index}`} value={val}>
                  {val}
                </Option>
              );
            })}
          </DropdownComponent>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="org-anb-data-container p-top-15"
        >
          <DropdownComponent
            labeltext={"Month"}
            className="general-ant-field-select"
            value={fieldDetails.month}
            values={monthList.map((_val, index) => index)}
            options={monthList}
            onChange={(e) => {
              let fieldDetailsData = fieldDetails;
              setFieldDetails({
                ...fieldDetailsData,
                month: e,
              });
            }}
            style={{ width: "100%" }}
            bordered={false}
            filterOption={(input, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {monthList.map((val, index) => {
              return (
                <Option key={`employees-${index}`} value={index}>
                  {val}
                </Option>
              );
            })}
          </DropdownComponent>
        </div>
        <div className="org-anb-data-container p-top-15">
          <h3
            className="general-ant-field-label "
            style={{ marginLeft: "1.7em" }}
          >
            Type
          </h3>
          <Radio.Group
            options={options}
            style={{
              display: "flex",
              justifyContent: "start",
              marginLeft: "2rem",
            }}
            onChange={(e) =>
              setFieldDetails({
                ...fieldDetails,
                type: e.target.value,
              })
            }
            value={fieldDetails.type}
            className="p-top-8 p-bottom-8 modal-margin-left-global-2"
          />
        </div>
        {fieldDetails.type === "overtime" && (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="org-anb-data-container p-top-15"
          >
            <DropdownComponent
              labeltext={"Component"}
              className="general-ant-field-select"
              value={
                fieldDetails.component ||
                organisationSettings.overtimeSetting.component
              }
              options={config.fnfCalculationBasis}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  component: fnfCalculationBasis[e],
                });
              }}
              style={{ width: "100%" }}
              bordered={false}
            />
          </div>
        )}
        {fieldDetails.type === "overtime" && (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="org-anb-data-container p-top-15"
          >
            <InputNumberComponent
              min={1}
              max={10}
              value={fieldDetails.factor}
              labeltext="Factor"
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  factor: e,
                });
              }}
              style={{ width: "100%" }}
              bordered={false}
            />
          </div>
        )}
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="org-anb-data-container p-top-15"
        >
          <InputFieldComponent
            labeltext={"No of Days"}
            type={"number"}
            className="general-ant-field-input"
            style={{ width: "100%" }}
            value={parseFloat(fieldDetails.days)}
            onChange={(e) => {
              setFieldDetails({
                ...fieldDetails,
                days: e.target.value,
              });
            }}
            placeholder="No Of Days"
            size={"large"}
          />
        </div>
        <div
          style={{ paddingLeft: "2em", paddingRight: "2em" }}
          className="select-button-container "
        >
          <NormalButton
            type={13}
            buttonText={"Add to the month end data"}
            block={"100%"}
            onClick={submitForm}
            margin={[30, 0]}
          />
        </div>
      </Spin>
    </div>
  );
};

export default OvertimeLOPFields;
