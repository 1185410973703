import { Input } from 'antd';
import React from 'react';
import  './TextAreaComponent.less';

const { TextArea } = Input;

function TextAreaComponent({ row ,onChange, value, width, maxWidth, height }) {
    let styles = {
        borderWidth: `${value?'2px':'1px'}`,
        width: width ? `${width}` : "`100%`",
        maxWidth: maxWidth ? `${maxWidth}` : "100%",
        borderRadius:'8px',
    }
    return (
        <>
            <TextArea rows={row || 4} onChange={onChange} value={value} style={styles} />
        </>
    );
};

export default TextAreaComponent;