const SectionData = ({ sectionName, sectionType, sectionInfo }) => {
    return (
        <div className="section-data-container">
            <h3 className="section-name">{sectionType}</h3>
            <h4 className="section-type">{sectionName}</h4>
            <p className="section-info">{sectionInfo}</p>
        </div>
    )
}

export default SectionData;