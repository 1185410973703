import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
export function makePercentageWhole(structure) {
    let newStructure = { ...structure };
    Object.keys(structure).forEach((val) => {
        if (val === "allowances" || val === "customAllowances") {
            if (Object.keys(structure[val]).length > 0) {
                Object.keys(structure[val]).forEach((val_nest) => {
                    newStructure[val][val_nest]["value"] = Math.round(structure[val][val_nest]["value"] * 1000) / 1000;
                });
            }
        } else {
            newStructure[val] = Math.round(structure[val] * 1000) / 1000;
        }
    });
    return newStructure;
}
export function getFullToolTip(rules) {
    return <Tooltip title={rules} >
        <InfoCircleOutlined style={{ marginLeft: "0.5rem", color: 'blue' }} />
    </Tooltip>
};
export function calculatePercentage(structure) {
    let sumPercent = 0;
    Object.keys(structure).forEach((val) => {
        if (val === "allowances" || val === "customAllowances") {
            if (Object.keys(structure[val]).length > 0) {
                Object.keys(structure[val]).forEach((val_nest) => {
                    if (structure[val][val_nest]["isPercent"]) {
                        sumPercent += structure[val][val_nest]["value"];
                    }
                });
            }
        } else if (val === "basic" || val === "hra") {
            if ((!structure.basicHRAPercentage || structure.basicHRAPercentage[val]) && !isNaN(parseFloat(structure[val]))) {
                sumPercent += structure[val];
            }
        }
        else if (!isNaN(parseFloat(structure[val]))) {
            sumPercent += structure[val];
        }
    });
    return sumPercent;
}