import { Col, notification, Radio, Row } from "antd";
import { useEffect, useState } from "react";
import { NormalButton } from "../ComponentLibrary";
import Spinner from "../ComponentLibrary/Spinner/Spinner";
import employee from "../../redux/modules/employee";
import { connect } from "react-redux";
import { options } from "../../constants/radioOptions";
import { checkFinancialYear } from "../../utils/config";
import { updateAdvanceTax } from "../../apis/employee";
import { createLog } from "../../apis/user";
import InputNumberComponent from "../InputLibrary/InputNumberComponent/InputNumberComponent";

const AdvanceTax = ({ user, declaration }) => {
  const [loading, setLoading] = useState(false);
  const [advanceTaxConfig, setAdvanceTaxConfig] = useState({
    advanceTax: 0,
    isAdvanceTaxAboveIncomeTax: true,
  });

  useEffect(() => {
    if (
      declaration.advanceTaxConfig &&
      Object.keys(declaration.advanceTaxConfig).length > 0
    ) {
      const { advanceTaxConfig: atc } = declaration;
      setAdvanceTaxConfig({
        advanceTax: isNaN(parseInt(atc.advanceTax))
          ? 0
          : parseInt(atc.advanceTax),
        isAdvanceTaxAboveIncomeTax:
          typeof atc.isAdvanceTaxAboveIncomeTax !== "boolean"
            ? true
            : atc.isAdvanceTaxAboveIncomeTax,
      });
    }
  }, [declaration]);

  const submitAdvanceTax = async () => {
    const { financialYear } = checkFinancialYear();
    const { advanceTax, isAdvanceTaxAboveIncomeTax } = advanceTaxConfig;
    const postBody = {
      id: user._id,
      financialYear,
      advanceTax,
      isAdvanceTaxAboveIncomeTax,
    };

    setLoading(true);
    let res = await updateAdvanceTax(postBody);
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `User has updaed the advance tax`,
      category: 5,
    });
    setLoading(false);
    if (res.data.success) {
      notification.success({ message: "Successfully Updated" });
    } else {
      notification.error({ message: "Sorry Unable To Update" });
    }
  };

  return (
    <div className="p-30">
      <div className="container">
        <div>
          <h1 className="tab-heading-1">Advance Tax</h1>
          <span className="tab-tagline">Update Advance Tax</span>
        </div>
        <div className="w-half">
          <Spinner spinning={loading}>
            <Row gutter={[50, 35]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <div className="flex align-center justify-start">
                  <p className="app-label-5">Is advance tax above income tax</p>
                </div>
                <div className="app-radio-container">
                  <Radio.Group
                    className="app-checkbox"
                    options={options}
                    onChange={(e) =>
                      setAdvanceTaxConfig({
                        ...advanceTaxConfig,
                        isAdvanceTaxAboveIncomeTax: e.target.value,
                      })
                    }
                    value={advanceTaxConfig.isAdvanceTaxAboveIncomeTax}
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} xl={24}>
                
                <div style={{marginLeft: "-10px"}}>
                  <InputNumberComponent
                    labeltext={"Advance Tax"}
                    className="general-ant-field-input"
                    bordered={false}
                    value={advanceTaxConfig.advanceTax}
                    onChange={(val) => {
                      setAdvanceTaxConfig({
                        ...advanceTaxConfig,
                        advanceTax: val,
                      });
                    }}
                    style={{
                      paddingLeft: "0.1rem",
                      fontSize: "0.9rem",
                    }}
                  />
                </div>
              </Col>
            </Row>

            <NormalButton
              type={13}
              buttonText="Save"
              onClick={submitAdvanceTax}
            />
          </Spinner>
        </div>
      </div>
    </div>
  );
};

const AdvanceTaxContainer = connect(
  (state) => ({
    user: state.auth.user,
    declaration: state.employee.declaration,
  }),
  (dispatch) => ({
    employeeReducer: employee.getActions(dispatch),
  })
)(AdvanceTax);

export default AdvanceTaxContainer;
