import { connect } from "react-redux";
import { useState, useEffect } from "react";
import organisation from "../../../redux/modules/organisation";
import { HeaderComponent } from "../../Common";
import Spinner from "../../ComponentLibrary/Spinner/Spinner";
import { NormalButton } from "../../ComponentLibrary";
import { Drawer, Table, Modal, Spin, Radio, message, notification } from "antd";
import { getEquitySharesApis, postEquitySharesApis } from "../../../apis/perqs";
import DateComponent from "../../InputLibrary/DateComponent/DateComponent";
import "../../../styles/equityShares.less"
import DropdownComponent from "../../InputLibrary/DropdownComponent/DropdownComponent";
import { checkFinancialYear } from "../../../utils/config";
import moment from "moment";
import InputNumberComponent from "../../InputLibrary/InputNumberComponent/InputNumberComponent";
import { getFullToolTip } from "../../../utils/common";
import TrialPage from "../../Common/TrialComponent";
import perqsBgImage from "../../../assets/dummyPages/perqsBgImage.png"

const options2 = [
    {
        label: "Yes", value: true
    },
    {
        label: "No", value: false
    }
]

const EquityShares = ({
    user, employeeList, isTrial
}) => {

    const [loading] = useState(false);
    const [data, setData] = useState([]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [entryModalVisible, setEntryModalVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});
    const [showData, setShowData] = useState(false);

    const [fieldDetails, setFieldDetails] = useState({
        id: "",
        equityId: "",
        exerciseDate: "",
        annualBifurcatedAmount: 0,
        isFullRecovery: false,
        recoveryAmount: 0,
        isRecovery: true,
    })

    const showDrawer = (data) => {
        setCurrentRecord(data)
        setDrawerVisible(true)
    };

    const drawerClose = () => {
        setDrawerVisible(false)
    };

    const modalClose = () => {
        setModalVisible(false)
    };

    const showModal = () => {
        setModalVisible(true)
    };
    const showEntryModal = () => {
        setEntryModalVisible(true);
    }
    const reset = () => {
        setFieldDetails({
            ...{
                id: "",
                equityId: "",
                exerciseDate: "",
                annualBifurcatedAmount: 0,
                isFullRecovery: false,
                recoveryAmount: 0,
                isRecovery: true,
            },
        })
    };
    const entryModalClose = () => {
        setEntryModalVisible(false);
    }

    useEffect(() => {
        getEquitySharesApis(user.organisationId)
            .then((res) => {
                setData(res.data.data)
            })
            .catch((err) => {
                "No Data"
            })
        //the below comment is to remove unwanted warning _/\_
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showData])

    const onModalSave = () => {
        let id = fieldDetails.id ? fieldDetails.id : currentRecord.id
        if (!id) {
            message.error("Please add an employee");
            return;
        }
        const { month, financialYear } = checkFinancialYear();
        let postAPIData = {
            ...fieldDetails,
            id,
            month,
            financialYear,
        }
        postEquitySharesApis(postAPIData)
            .then((res) => {
                if (res.data.success) {
                    notification.success({
                        message: "Successfully Saved Data",
                    });
                    setShowData(!showData)
                    setModalVisible(false)
                    setDrawerVisible(false)
                    setEntryModalVisible(false)
                    reset();
                } else {
                    notification.warn({
                        message: "User Already Exists"
                    })
                }
            }
            )

    }


    const columns = [
        {
            title: "ID",
            dataIndex: ["user", "currentOrganisationMeta", "employeeId"]
        },
        {
            title: "Employee Name",
            dataIndex: ["user", "displayName"],
        },
        {
            title: "Exercise Entries",
            dataIndex: "entries",
            render: (text, record, index) => {
                return (<button className="table-view-bttn" onClick={() => showDrawer(record)}> {record.equityHistory ? Object.keys(record.equityHistory).length : "0"} view</button>);
            },
        },
    ];

    const drawerContent = (
        <>
            <div>
                <h1 style={{ fontSize: "18px", fontWeight: "700", marginBottom: "2em", marginLeft: "-1em" }}> Exercise List</h1>
            </div>

            {
                currentRecord && currentRecord.equityHistory ? Object.values(currentRecord.equityHistory).map((item, index) => {
                    return (
                        <div style={{ marginLeft: "-1.5em", borderBottom: "solid #dcdfe8 1px", paddingBottom: "14px", marginTop: "1rem" }} className="flex justify-between">
                            <div>
                                <p style={{ color: " #2d2e30", opacity: "0.6", fontWeight: "500" }}>
                                    Entry # {index + 1}
                                </p>
                                <p style={{ color: " #2d2e30", fontWeight: "700", marginTop: "5px" }}>
                                    {item.exerciseDate}
                                </p>
                            </div>
                            <div style={{ textAlign: "right" }}>

                                <p style={{ color: " #2d2e30", fontWeight: "700" }}>
                                    {item.annualBifurcatedAmount} INR
                                </p>
                                <p style={{ color: " #2d2e30", opacity: "0.6", fontWeight: "500", marginTop: "5px" }}>
                                    {item.recoveryAmount} INR
                                </p>
                            </div>
                        </div>
                    )

                }) : <h1>No Entries</h1>

            }

        </>
    )

    return (
        isTrial ? <TrialPage user={user} trialMessage={"Explore the tax heaven for salaried employees without getting stressed about compliances"} bgImage={perqsBgImage}
        /> :
        <section className="site-layout">
            <HeaderComponent name={"Equity Shares"} />
            <div className="app-main-content-container paper-wrapper">
                <Modal
                    visible={modalVisible}
                    onCancel={modalClose}
                    footer={false}
                    closable={false}
                    centered={true}
                    width={400}
                >
                    <Spin spinning={loading}>
                        <div>
                            <h4 className="font-size-global-24 font-weight-global-700">Add new employee</h4>
                        </div>
                        <div className="">
                            <DropdownComponent
                                className="general-ant-field-select"
                                labeltext={"Employee Name"}
                                options={employeeList.map((item) => item.displayName)}
                                values={employeeList.map(item => item._id)}
                                value={fieldDetails.id}
                                onChange={(e) => {
                                    setFieldDetails({
                                        ...fieldDetails,
                                        id: e,
                                    });
                                }}
                                width={"100%"}
                                bordered={false}
                                filterOption={(input, option) =>
                                    option.children &&
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children
                                        .toLowerCase()
                                        .localeCompare(optionB.children.toLowerCase())
                                }
                                showSearch
                            />
                        </div>
                        <div>
                            <NormalButton
                                buttonText={"Save"}
                                type={13}
                                onClick={onModalSave}
                            />
                            <NormalButton
                                buttonText={"Cancel"}
                                type={5}
                                onClick={modalClose}
                            />
                        </div>
                    </Spin>
                </Modal>
                <Modal
                    visible={entryModalVisible}
                    onCancel={entryModalClose}
                    footer={false}
                    closable={false}
                    centered={true}
                    width={400}
                >
                    <Spin spinning={loading}>
                        <div>
                            <h4 className="font-size-global-24 font-weight-global-700">Add new entry for - <i>{currentRecord && currentRecord.user ? currentRecord.user.displayName : ""}</i></h4>
                        </div>
                        <div className="m-10">
                            <DateComponent
                                labelText={"Exercise Date"}
                                labelBackground={"white"}
                                value={
                                    fieldDetails.exerciseDate
                                        ? moment(
                                            new Date(fieldDetails.exerciseDate),
                                            "YYYY-MM-DD"
                                        )
                                        : ""
                                }
                                onChange={e => {
                                    let fieldDetailsData = fieldDetails;
                                    setFieldDetails({
                                        ...fieldDetailsData,
                                        exerciseDate: new Date(e).toDateString()

                                    });
                                }}
                                width={"100%"}
                            />
                        </div>
                        <div className=" m-top-36">
                            <InputNumberComponent
                                labeltext={"Value of share on date of exercise in INR"}
                                type={"number"}
                                value={fieldDetails.annualBifurcatedAmount}
                                width={"100%"}
                                onChange={(e) => {
                                    let fieldDetailsData = fieldDetails;
                                    setFieldDetails({
                                        ...fieldDetailsData,
                                        annualBifurcatedAmount: parseInt(e)
                                    });
                                }}
                            />
                        </div>
                        <div>
                            <div className="font-size-global-14 font-weight-global-700 m-10">
                                How much do you want to Recover from the employee?
                            </div>
                            <div className="m-10">
                                <Radio.Group
                                    value={fieldDetails.isFullRecovery}
                                    onChange={(e) =>
                                        setFieldDetails({
                                            ...fieldDetails,
                                            isFullRecovery: e.target.value,
                                            recoveryAmount: e.target.value ? Math.round(fieldDetails.annualBifurcatedAmount / 12) : 0,
                                        })
                                    }
                                >
                                    <Radio value={true}>Fully
                                        {getFullToolTip("On the date of exercising, value of the share in the company as determined by a merchant banker on the specified date.")}
                                    </Radio>
                                    <Radio value={false}>Custom</Radio>
                                </Radio.Group>

                            </div>
                        </div>
                        <div className="m-top-16">
                            <InputNumberComponent
                                labeltext={"Amount to Recover"}
                                type={"number"}
                                disabled={fieldDetails.isFullRecovery}
                                value={fieldDetails.recoveryAmount}
                                width={"100%"}
                                onChange={(e) => {
                                    let fieldDetailsData = fieldDetails;
                                    setFieldDetails({
                                        ...fieldDetailsData,
                                        recoveryAmount: parseInt(e)
                                    });
                                }}
                            />
                        </div>
                        <div className="font-size-global-14 font-weight-global-700 m-10">
                            Do you want recover the amount from employee's salary
                        </div>
                        <div className="m-10">
                            <Radio.Group
                                options={options2}
                                value={fieldDetails.isRecovery}
                                onChange={(e) =>
                                    setFieldDetails({
                                        ...fieldDetails,
                                        isRecovery: e.target.value,
                                    })
                                }
                            />

                        </div>
                        <div className="flex m-10 p-top-10">
                            <NormalButton
                                buttonText={"Cancel"}
                                onClick={entryModalClose}
                                width={"30%"}
                            />
                            <NormalButton
                                buttonText={"Add"}
                                type={13}
                                onClick={onModalSave}
                                width={"30%"}
                            />
                        </div>
                    </Spin>
                </Modal>
                <Spinner spinning={loading}>
                    <div className="">
                        <div>
                            <h2 className="app-heading-2 font-weight-700">Specified security not being an equity share in a company</h2>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span style={{ fontSize: "24px", fontWeight: "700" }}>Entries</span>
                                <span>
                                    <NormalButton
                                        buttonText={"Add"}
                                        onClick={showModal}
                                        type={13}
                                    />
                                </span>
                            </div>
                        </div>
                        <div>
                            <Table
                                columns={columns}
                                dataSource={data.reverse()}
                            />
                        </div>

                    </div>
                    <Drawer
                        placement="right"
                        title={<div><b>{currentRecord && currentRecord.user ? currentRecord.user.displayName : ""}</b><br /><span>{currentRecord && currentRecord.user ? currentRecord.user.currentOrganisationMeta.employeeId : ""} </span></div>} //page breaks while hardcoding
                        visible={drawerVisible}
                        onClose={drawerClose}
                    >
                        <Spinner spinning={loading}>
                            <div className="drawer">
                                {drawerContent}
                                <div style={{ marginLeft: "-2em" }} className="button-container m-top-36">
                                    <NormalButton
                                        width={"100%"}
                                        buttonText={"Add New Entry"}
                                        type={13}
                                        onClick={showEntryModal}
                                    />
                                </div>
                            </div>
                        </Spinner>
                    </Drawer>
                </Spinner>
            </div>
        </section>
    )
}
const EquitySharesPerqs = connect(
    state => ({
        user: state.auth.user,
        organisationSettings: state.organisation.organisationSettings,
        orgLoading: state.organisation.loading,
        employeeList: state.organisation.employeeList,
        isTrial: state.auth.user.isTrial,

    }),
    dispatch => ({
        organisationReducer: organisation.getActions(dispatch)
    })
)(EquityShares);

export default EquitySharesPerqs;