import { Spin, Modal, notification } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { checkUserExist } from "../../../apis/auth";
import { updateEmployeeDetails } from "../../../apis/organisation";
import { createLog } from "../../../apis/user";
import { validateEmail } from "../../../utils/config";
import { NormalButton } from "../../ComponentLibrary";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";

const errorDataState = {
  phone: false,
  PAN: false,
  email: false,
};

const errorMessages = {
  id: "",
  phone: "",
  PAN: "",
  email: "",
};

const UpdateEmployeeDetails = ({
  modalProperties,
  user,
  setModalProperties,
  resetModals,
  organisationReducer,
  setUpdateSelectedEmployee,
}) => {
  const [employeeData, setEmployeeData] = useState({
    PAN: "",
    email: "",
    phone: "",
    id: "",
  });
  const [error, setError] = useState({
    ...errorDataState,
  });

  const [errorMessage, setErrorMessage] = useState({
    ...errorMessages,
  });

  useEffect(() => {
    if (user) {
      setEmployeeData({
        PAN: user.PAN,
        email: user.primaryEmail,
        phone: user.phone,
        id: user._id,
      });
    }
  }, [user]);
  const resetDetails = () => {
    setEmployeeData({
      PAN: "",
      email: "",
      phone: "",
      id: "",
      name: ""
    });
    setError({ ...errorDataState });
    setErrorMessage({ ...errorMessages });
    resetModals();
    setUpdateSelectedEmployee(null);
  };

  const saveDetails = async () => {
    let errors = {
      ...errorDataState,
    };
    let errorMessagesDemo = {
      ...errorMessage,
    };
    let localError = false;
    if (!/^[0-9A-Z]{10}$/.test(employeeData.PAN)) {
      localError = true;
      errors.PAN = true;
      errorMessagesDemo.PAN = "Please enter a valid PAN number";
    }
    if (!/^\d{10}$/.test(employeeData.phone)) {
      localError = true;
      errors.phone = true;
      errorMessagesDemo.phone = "Please enter a valid phone number";
    }

    if (!validateEmail(employeeData.email)) {
      localError = true;
      errors.email = true;
      errorMessagesDemo.email = "Please enter a valid email";
    }
    if (localError) {
      setErrorMessage({ ...errorMessagesDemo });
      setError({ ...errors });
      return;
    }
    let modalProps = modalProperties;
    modalProps.employeeDetails.loading = true;
    setModalProperties({ ...modalProps });
    let res = await updateEmployeeDetails({ ...employeeData });
    if (res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Organiation User has updated ${user.displayName}'s details`,
        category: 16,
      });
      notification.success({
        message: `Successfully updated the employee`,
      });
    } else {
      notification.error({
        message: `Sorry Unable to update the employee`,
      });
    }
    organisationReducer.updateEmployeeList({
      organisationId: user.organisationId,
    });
    resetDetails();
  };

  const checkUserAvailability = async (details, type) => {
    // Check if it is same as current user;
    if (type === 1 && user.primaryEmail === details) {
      return;
    }

    if (type === 3 && user.PAN === details) {
      return;
    }

    // type : 1 email
    let res = await checkUserExist({ details, type });
    if (res.data) {
      if (type === 1) {
        setError({
          ...errorDataState,
          email: true,
        });
        setErrorMessage({
          ...errorMessages,
          email: `User with the email: ${employeeData.email} already is in a organisation`,
        });
        setEmployeeData({ ...employeeData, email: user.primaryEmail });
      } else if (type === 3) {
        setError({
          ...errorDataState,
          PAN: true,
        });
        setErrorMessage({
          ...errorMessage,
          PAN: `User with the PAN: ${employeeData.PAN} already is in a organisation`,
        });
        setEmployeeData({ ...employeeData, PAN: user.PAN });
      } else {
        setError({
          ...errorDataState,
        });
        setErrorMessage({
          ...errorMessages,
        });
      }
    }
  };

  const setSingleEmployeeData = (key, value) => {
    let val = value;
    if (key === "PAN") val = val.toUpperCase();
    let tempEmployeeData = employeeData;
    tempEmployeeData[key] = val;
    setEmployeeData({ ...tempEmployeeData });
  };

  return (
    <Modal
      centered
      closable={false}
      width={400}
      visible={modalProperties.employeeDetails.visible}
      className="modal-container"
      footer={null}
    >
      <Spin spinning={modalProperties.employeeDetails.loading} size="large">
        <Content className={"modal-body"}>
          <h1 className="font-weight-global-700 font-size-global-24 modal-margin-left-global-heading">Edit Employee</h1>
          <div style={{ marginTop: '2em' }} className="org-data-container modal-alignment-center-global ">
            <InputFieldComponent
              value={employeeData.PAN}
              labeltext={"PAN Number"}
              className="general-input-field"
              placeholder="Employee PAN Number"
              bordered={false}
              onBlur={() => checkUserAvailability(employeeData.PAN, 3)}
              onChange={(e) => {
                if (e.target.value.length < 11)
                  setSingleEmployeeData("PAN", e.target.value);
              }}
              size={"large"}
            />
            {error.PAN && (
              <p className="error m-top-minus-1">{errorMessage.PAN}</p>
            )}
          </div>
          <div className="org-data-container modal-alignment-center-global modal-margin-top-reduce-global-1">
            <InputFieldComponent
              value={employeeData.phone}
              type={"number"}
              labeltext={"Phone Number"}
              placeholder="Employee 10 Digit Phone Number"
              onChange={(e) => {
                if (e.target.value.length < 11)
                  setSingleEmployeeData("phone", e.target.value);
              }}
              bordered={false}
              size={"large"}
              className="general-input-field"
            />
            {error.phone && (
              <p className="error m-top-minus-1">{errorMessage.phone}</p>
            )}
          </div>
          <div className="org-data-container modal-alignment-center-global modal-margin-top-reduce-global-1">
            <InputFieldComponent
              value={employeeData.email}
              labeltext={"Email"}
              className="general-input-field"
              placeholder="Employee Email"
              bordered={false}
              onBlur={() => checkUserAvailability(employeeData.email, 1)}
              onChange={(e) =>
                setSingleEmployeeData("email", e.target.value)
              }
              size={"large"}
            />
            {error.email && (
              <p className="error m-top-minus-1">{errorMessage.email}</p>
            )}
          </div>
          <div className="modal-margin-left-global-1 modal-margin-top-add-global-2">
            <NormalButton
              onClick={saveDetails}
              id="UEDBttnId"
              type={13}
              buttonText={"Update"}
              className="submit-button"
              style={{
                marginRight: "0px",
                padding: "0px 24px",
              }}
            />
            <NormalButton
              onClick={resetDetails}
              type={5}
              buttonText={"Cancel"}
              className="cancel-button"
              style={{
                border: "none",
                boxShadow: "none",
                marginRight: "0px",
              }}
            />
          </div>
        </Content>
      </Spin>
    </Modal>
  );
};

export default UpdateEmployeeDetails;
