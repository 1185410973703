import { HeaderComponent } from "../Common";
import activeSetting from "../../assets/icons/activeIcons/activeSettings.svg";
import { connect } from "react-redux";
import organisation from "../../redux/modules/organisation";
import { NormalButton } from "../ComponentLibrary";
import { withRouter } from "react-router-dom";
import { Col, notification, Row, Spin } from "antd";
import { useState } from "react";
import { addNewApplicationToApiAccess } from "../../apis/organisation";
import { LoadingOutlined } from "@ant-design/icons";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";

const OrganisationAddNewApplicationToAPIAccess = ({ history, user }) => {

    const [newAppDetails, setNewAppDetails] = useState({
        appName: "",
        organisationID: user.organisationId
    })
    const [accessKeyPair, setAccessKeyPair] = useState({})
    const [pageState, setPageState] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const onSave = async () => {
        setIsLoading(true);
        if (newAppDetails.appName.length < 1) {
            notification.error({ message: "App name should not empty." });
            setIsLoading(false);
            return;
        }
        let res = await addNewApplicationToApiAccess(newAppDetails);
        if (res.status === 200 && res.data.success) {
            setAccessKeyPair(res.data.data)
            setPageState(2);
        } else {
            notification.error({
                message: "Failed to add new application, please try again.",
            });
        }
        setIsLoading(false);
    };

    return (
        <div className="site-layout">
            <HeaderComponent
                show={false}
                showMoolCoins={true}
                icon={activeSetting}
                name="Configuration"
            />
            <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                <div className="app-main-content-container paper-wrapper">
                    {
                        pageState === 1 && (
                            <>
                                <div className="heading-style-2-container flex justify-between align-center">
                                    <div className="flex align-center">
                                        <h1 className="font-size-global-24 font-weight-global-700">
                                            Generate a new pair of API access keys
                                        </h1>
                                    </div>
                                    <div>
                                        <NormalButton type={13} buttonText="Save" onClick={onSave} />
                                        <NormalButton
                                            type={5}
                                            buttonText="Cancel"
                                            margin={[0, 16]}
                                            onClick={() => history.goBack()}
                                        />
                                    </div>
                                </div>
                                <Row gutter={[100, 20]}>
                                    <Col sm={24} md={12} lg={9} xl={9}>
                                        <InputFieldComponent
                                            value={newAppDetails.appName}
                                            labeltext={"Application Name"}
                                            onChange={(e) => {
                                                setNewAppDetails({
                                                    ...newAppDetails,
                                                    appName: e.target.value,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                    {
                        pageState === 2 && (
                            <>
                                <div className="heading-style-2-container flex justify-between align-center">
                                    <div className="flex align-center">
                                        <h1 className="heading-name">
                                            Here is your new pair of access keys
                                        </h1>
                                    </div>
                                    <div>
                                        <NormalButton type={1} buttonText="Back to application list" onClick={() => history.push("/org/config/apiAccess")} />
                                    </div>
                                </div>
                                <div>
                                    <p className={"app-label-1 mb-4"}>Please store your access key ID and access key secret, this will be the last time this credentials will be available.</p>
                                    <p className={"app-label-1 mb-4"}>Access key ID:  <code style={{ fontWeight: "bold", background: "#ED864C", padding: "5px", borderRadius: "4px" }} className={"subtitle1-med-16"}>{accessKeyPair.accessKeyID}</code></p>
                                    <p className={"app-label-1 mb-4"}>Access key secret:  <code style={{ fontWeight: "bold", background: "#ED864C", padding: "5px", borderRadius: "4px" }} className={"subtitle1-med-16"}>{accessKeyPair.accessKeySecret}</code></p>
                                </div>
                            </>
                        )
                    }
                </div>
            </Spin>
        </div>
    )
}


const OrganisationAddNewApplicationToAPIAccessContainer = connect(
    (state) => ({
        user: state.auth.user,
    }),
    (dispatch) => ({
        organisationReducer: organisation.getActions(dispatch),
    })
)(OrganisationAddNewApplicationToAPIAccess);

export default withRouter(OrganisationAddNewApplicationToAPIAccessContainer)