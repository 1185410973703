import {
  Table,
  Menu,
  Row,
  Drawer,
  Radio,
  Divider,
  Space,
  Dropdown,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { HeaderComponent } from "../../Common";
import { NormalButton } from "../../ComponentLibrary";
import { connect } from "react-redux";
import organisation from "../../../redux/modules/organisation";
import actionIcon from "../../../assets/icons/action.svg";
import InputNumberComponent from "../../InputLibrary/InputNumberComponent/InputNumberComponent";
import { getResidentialAccommodation, postResidentialAccommodation } from "../../../apis/perqs";
import DropdownComponent from "../../InputLibrary/DropdownComponent/DropdownComponent";
import { getFullToolTip } from "../../../utils/common";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";
import TrialPage from "../../Common/TrialComponent";
import perqsBgImage from "../../../assets/dummyPages/perqsBgImage.png"

const Residential_accommodation = ({
  employeeList,
  user,
  financialYear,
  month,
  isTrial
}) => {
  const [open, setOpen] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);

  //inputfield state

  const [DataSource, setDataSource] = useState([]);

  const [requestData, setRequestData] = useState({
    id: "",
    residentId: "",
    residentRecoveryAmount: 0,
    furnitureRecoveryAmount: 0,
    bifurcatedAmount: 0,
    annualBifurcatedAmountOfFurniture: 0,
    isFullRecovery: false,
    isRecovery: true,
    isProvidedByPrivateOrganisation: true,
    isOwnedByOrganisation: true,
    isFurnitureOwnedByOrganisation: true,
    isHouseFurnished: false,
    netPopulationOfCityType: 2,
    isFurnitureFullRecovery: false,
    isRecoveryOfFurniture: true,
    costOfFurniture: 0
  });


  useEffect(() => {
    getResidentialAccommodation(user.organisationId).then((data) => {
      setDataSource(data.data.data);
    });
  }, [user.organisationId]);

  const onSave = () => {
    postResidentialAccommodation({ ...requestData, financialYear, month }).then(res => {
      if (res.data.success) {
        notification.success({
          message: "Residential Accommodation Saved Successfully!"
        })
      } else {
        notification.error({
          message: "Error while saving the residential perquisites!"
        })
      }
    }).catch(err => {
      notification.error({
        message: "Error while saving the residential perquisites!"
      })
    })
    setOpen(false);
    reset()
  }

  const reset = () => {
    setRequestData({
      id: "",
      residentId: "",
      residentRecoveryAmount: 0,
      furnitureRecoveryAmount: 0,
      bifurcatedAmount: 0,
      annualBifurcatedAmountOfFurniture: 0,
      isFullRecovery: false,
      isRecovery: true,
      isProvidedByPrivateOrganisation: true,
      isOwnedByOrganisation: true,
      isFurnitureOwnedByOrganisation: true,
      isHouseFurnished: false,
      netPopulationOfCityType: 2,
      isFurnitureFullRecovery: false,
      isRecoveryOfFurniture: true,
      costOfFurniture: 0
    });
  }

  const setEditDrawerData = (data) => {
    setIsEditClicked(true)
    setRequestData({
      ...requestData,
      ...data,
      isRecovery: data.perquisites && data.perquisites[month] ? data.perquisites[month]["isRecovery"] : true,
      isRecoveryOfFurniture: data.perquisites && data.perquisites[month] ? data.perquisites[month]["isRecoveryOfFurniture"] : true
    })
    openDrawer();
  };
  const getFurnitureFullMessage = () => {
    if (requestData.isHouseFurnished) {
      if (requestData.isProvidedByPrivateOrganisation) {
        if (requestData.isFurnitureOwnedByOrganisation) {
          return "10% per annum of the cost of furniture"
        } else {
          return "Actual Hiring Charges"
        }
      }
    }
  }
  const getFullyMessage = () => {
    if (requestData.isProvidedByPrivateOrganisation) {
      if (requestData.isOwnedByOrganisation) {
        if (requestData.netPopulationOfCityType === 0) {
          return "15% of salary"
        }
        else if (requestData.netPopulationOfCityType === 1) {
          return "10% of salary"
        }
        else {
          return "7.5% of salary"
        }
      }
      else {
        return "15% of salary"
      }
    } else {
      return <p>Min of
        <br />1. 24% of salary paid or payable for the previous year.
        <br />2. The actual charges paid or payable to such hotel</p>
    }
  }
  const getActionMenu = (data) => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            setEditDrawerData(data);
          }}
        >
          Edit
        </Menu.Item>
        {/* <Menu.Item key="2">Delete</Menu.Item> */}
      </Menu>
    );
  };
  const columns = [
    {
      title: "ID",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
    },
    {
      title: "Employee Name",
      dataIndex: ["user", "displayName"],
    },
    {
      title: "Category",
      dataIndex: [
        "perquisites",
        `${month}`,
        "isProvidedIsByPrivateOrganisation",
      ],
      render: (val) =>
        val ? (
          <p>Provided by Private Organisation</p>
        ) : (
          <p>Accommodation in Hotel</p>
        ),
    },
    {
      title: "State",
      dataIndex: ["perquisites", `${month}`, "isOwnedByOrganisation"],
      render: (val) => (val ? <p>Owned by Organisation</p> : <p>On Lease</p>),
    },
    {
      title: "Type",
      dataIndex: ["perquisites", `${month}`, "isHouseFurnished"],
      render: (val) => (val ? <p>Furnished</p> : <p>Unfurnished</p>),
    },
    {
      title: "Rent Paid(in INR)",
      dataIndex: ["perquisites", `${month}`, "residentRecoveryAmount"],
    },
  ];


  // eslint-disable-next-line no-unused-vars
  const editCol = [
    {
      title: "",
      dataIndex: ["perquisites", `${month}`],
      fixed: "right",
      render: (data) => {
        const actionMenu = getActionMenu(data);
        return (
          <Space
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown
              trigger={["click"]}
              overlay={actionMenu}
              placement="bottomLeft"
            >
              <div onClick={(e) => e.stopPropagation()}>
                <img src={actionIcon} alt="actionIcon" />
              </div>
            </Dropdown>
          </Space>
        );
      },
    },
  ]

  const openDrawer = () => {
    setOpen(true);
  };


  const closeDrawer = () => {
    setOpen(false);
  };

  const addNewAccomodation = (
    <div className="section">
      <div className="section-top">
        <h3 className="font-size-16" style={{ fontWeight: "bold" }}>
          Name of the employee whom this house is assigned
        </h3>

        {
          !isEditClicked ?
            <DropdownComponent
              className="general-ant-field-select"
              labeltext={"Employee Name"}
              options={employeeList.map((item) => item.displayName)}
              values={employeeList.map(item => item._id)}
              margin={[18, 0, 18, 0]}
              value={requestData.id}
              onChange={(e) => { setRequestData({ ...requestData, id: e }) }}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            />
            :
            <InputFieldComponent
              labeltext={"Employee Name"}
              width={"500px"}
              disabled={true}
              margin={[18, 0, 5, 0]}
              labelColor="white"
              value={requestData && requestData.user && requestData.user.displayName ? requestData.user.displayName : ""}
            />
        }
        <p className="subtitle3-med-16"> Select a category</p>
        <Radio.Group
          className="section-top"
          style={{ display: "flex" }}
          value={requestData.isProvidedByPrivateOrganisation}
          name="accomodation"
          onChange={(radioProps) => {
            setRequestData({
              ...requestData,
              isProvidedByPrivateOrganisation: radioProps.target.value
            })
          }}
        >
          <Radio value={true}>Provided by private organisation</Radio>
          <Radio value={false}>Accomodation in hotel</Radio>
        </Radio.Group>

        {
          requestData.isProvidedByPrivateOrganisation &&
          <>
            <div>
              <p className="subtitle3-med-16 m-top-16">
                What kind of state is the accommodation in?
              </p>
              <Radio.Group
                className="section-top"
                style={{ display: "flex" }}
                value={requestData.isOwnedByOrganisation}
                name="accomodation_state"
                onChange={(radioProps) => setRequestData({
                  ...requestData,
                  isOwnedByOrganisation: radioProps.target.value
                })}
              >
                <Radio value={true}>Owned by organisation</Radio>
                <Radio value={false}>On lease</Radio>
              </Radio.Group>
            </div>
          </>
        }

        <>
          <h3
            className="font-size-16 m-top-16"
            style={{ fontWeight: "bold" }}
          >
            {requestData.isProvidedByPrivateOrganisation ? requestData.isOwnedByOrganisation ? "Cost to the organisation for owning the property ( per annum )" : "Lease amount paid by organisation" : "Charges paid by the organisation to the hotel"}
          </h3>
          <InputNumberComponent
            labeltext={"Amount"}
            width={"500px"}
            margin={[18, 0, 18, 0]}
            value={requestData.bifurcatedAmount}
            onChange={(e) => setRequestData({
              ...requestData,
              bifurcatedAmount: e
            })}
          />
          {requestData.isProvidedByPrivateOrganisation &&
            <div>
              <p className="subtitle3-med-16 m-top-16">Accommodation type</p>
              <Radio.Group
                className="section-top"
                style={{ display: "flex" }}
                value={requestData.isHouseFurnished}
                name="accommodation_type"
                onChange={(radioProps) => setRequestData({ ...requestData, isHouseFurnished: radioProps.target.value })}
              >
                <Radio value={false}>Unfurnished</Radio>
                <Radio value={true}>Furnished</Radio>
              </Radio.Group>
            </div>
          }
          {
            requestData.isProvidedByPrivateOrganisation
            && requestData.isOwnedByOrganisation &&
            <>
              <div>
                <p className="subtitle3-med-16 m-top-16">
                  What do the net population of this city fall under
                </p>
                <Radio.Group
                  className="section-top"
                  style={{ display: "flex" }}
                  value={requestData.netPopulationOfCityType}
                  name="population"
                  onChange={(radioProps) => setRequestData({
                    ...requestData,
                    netPopulationOfCityType: radioProps.target.value
                  })}
                >
                  <Radio value={0}> {">25 Lakh"} </Radio>
                  <Radio value={1}> {">10 Lakh - <25 Lakh"} </Radio>
                  <Radio value={2}> {"<10 Lakh"} </Radio>
                </Radio.Group>
              </div>
            </>
          }
          <>
            <div>
              <p className="subtitle3-med-16 m-top-16">
                How do you want to recover the amount from your employee
              </p>
              <Radio.Group
                className="section-top"
                style={{ display: "flex" }}
                value={requestData.isFullRecovery}
                name={"full_recover"}
                onChange={(radioProps) => setRequestData({
                  ...requestData,
                  isFullRecovery: radioProps.target.value,
                  residentRecoveryAmount: radioProps.target.value ? requestData.bifurcatedAmount : 0
                })}
              >
                <Radio value={true}> Fully
                  {getFullToolTip(getFullyMessage())}
                </Radio>
                <Radio value={false}> Custom </Radio>
              </Radio.Group>
            </div>
          </>
          {
            !requestData.isProvidedByPrivateOrganisation &&
            <div>
              <h3
                className="font-size-16 m-top-16"
                style={{ fontWeight: "bold" }}
              >
                How much do you want to recover the amount
              </h3>
            </div>
          }
          {
            !requestData.isFullRecovery ?
              <InputNumberComponent
                labeltext={"Amount"}
                width={"500px"}
                disabled={requestData.isFullRecovery}
                margin={[18, 0, 18, 0]}
                value={requestData.residentRecoveryAmount}
                onChange={(e) => setRequestData({ ...requestData, residentRecoveryAmount: e })}
              /> : ""
          }
          <div>
            <p className="subtitle3-med-16 m-top-16">
              Do you want to recover the amount from employee's salary
            </p>
            <Radio.Group
              className="section-top"
              style={{ display: "flex" }}
              name="recover_from_salary"
              value={requestData.isRecovery}
              onChange={(radioProps) => setRequestData({ ...requestData, isRecovery: radioProps.target.value })}
            >
              <Radio value={true}> Yes </Radio>
              <Radio value={false}> No </Radio>
            </Radio.Group>
          </div>
        </>

        {requestData.isProvidedByPrivateOrganisation &&
          <>
            {
              requestData.isHouseFurnished &&
              <>
                <div>
                  <Divider></Divider>
                  <p className="subtitle3-med-16 m-top-16">
                    Who owns the furniture
                  </p>
                  <Radio.Group
                    className="section-top"
                    style={{ display: "flex" }}
                    value={requestData.isFurnitureOwnedByOrganisation}
                    name="owns_furniture"
                    onChange={(radioProps) => setRequestData({ ...requestData, isFurnitureOwnedByOrganisation: radioProps.target.value })}
                  >
                    <Radio value={true}> By organisation </Radio>
                    <Radio value={false}> 3rd party vendor </Radio>
                  </Radio.Group>
                </div>
                {
                  requestData.isHouseFurnished &&
                  <>
                    {
                      requestData.isFurnitureOwnedByOrganisation &&
                      <div>
                        <p className="subtitle3-med-16 m-top-16">
                          Total Cost of the furniture
                        </p>
                        <InputNumberComponent
                          labeltext={"Amount"}
                          width={"500px"}
                          margin={[18, 0, 18, 0]}
                          value={requestData.costOfFurniture}
                          onChange={(val) => setRequestData({ ...requestData, costOfFurniture: val })}
                        />
                      </div>

                    }
                    <div>
                      <p className="subtitle3-med-16 m-top-16">
                        {requestData.isHouseFurnished && requestData.isFurnitureOwnedByOrganisation ? "What is the cost to the organisation (annually)" : "Rental charges paid by the organisation"}
                      </p>
                      <InputNumberComponent
                        labeltext={"Amount"}
                        width={"500px"}
                        margin={[18, 0, 18, 0]}
                        value={requestData.annualBifurcatedAmountOfFurniture}
                        onChange={(val) => setRequestData({ ...requestData, annualBifurcatedAmountOfFurniture: val })}
                      />
                    </div>
                    <div>
                      <p className="subtitle3-med-16 m-top-16">
                        How do you want to recover the amount from your employee?
                      </p>
                      <Radio.Group
                        className="section-top"
                        style={{ display: "flex" }}
                        value={requestData.isFurnitureFullRecovery}
                        name={"full_recover"}
                        onChange={(radioProps) => setRequestData({
                          ...requestData,
                          isFurnitureFullRecovery: radioProps.target.value,
                          furnitureRecoveryAmount: radioProps.target.value ? Math.round(requestData.annualBifurcatedAmountOfFurniture / 12) : 0
                        })}
                      >
                        <Radio value={true}> Fully {getFullToolTip(getFurnitureFullMessage())} </Radio>
                        <Radio value={false}> Custom </Radio>
                      </Radio.Group>
                    </div>
                    {
                      !requestData.isFurnitureFullRecovery &&
                      <div>
                        <InputNumberComponent
                          labeltext={"Amount"}
                          width={"500px"}
                          margin={[18, 0, 18, 0]}
                          value={requestData.furnitureRecoveryAmount}
                          onChange={(val) => setRequestData({ ...requestData, furnitureRecoveryAmount: val })}
                        />
                      </div>
                    }
                    <div>
                      <p className="subtitle3-med-16 m-top-16">
                        How do you want to recover the amount from your employee ?
                      </p>
                      <Radio.Group
                        className="section-top"
                        style={{ display: "flex" }}
                        name="recover_from_salary"
                        value={requestData.isRecoveryOfFurniture}
                        onChange={(radioProps) => setRequestData({ ...requestData, isRecoveryOfFurniture: radioProps.target.value })}
                      >
                        <Radio value={true}> Yes </Radio>
                        <Radio value={false}> No </Radio>
                      </Radio.Group>
                    </div>
                  </>
                }
              </>
            }
          </>
        }
      </div >
      <div className="flex" style={{ marginTop: "2rem" }}>
        <NormalButton
          type={13}
          onClick={onSave}
          buttonText="Save Changes"
        />
        <NormalButton type={5} onClick={() => { }} buttonText="Cancel" />
      </div>
    </div>
  );

  return (
    isTrial ? <TrialPage user={user} trialMessage={"Explore the tax heaven for salaried employees without getting stressed about compliances"} bgImage={perqsBgImage}
    /> :
    <>
      <section className="site-layout">
        <HeaderComponent name="Residential Accomodation" />
        <div className="app-main-content-container paper-wrapper">
          <h1 style={{ fontSize: "24px", fontWeight: "700", margin: "2rem 0" }}>
            Residential Accommodation
          </h1>

          <Row justify="space-between">
            <h2
              style={{
                fontSize: "1.125rem",
                fontWeight: "700",
                margin: "2rem 0 ",
              }}
            >
              {DataSource.length} Entries
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NormalButton
                type={13}
                buttonText={"Add"}
                heightWidth={["50px", "150px"]}
                margin={[0, 32]}
                onClick={openDrawer}
              ></NormalButton>
            </div>
          </Row>
          <Table columns={columns} dataSource={DataSource.reverse()} />
        </div>
        <Drawer
          title={<p className="subtitle3-med-16">Add New Accomodation</p>}
          placement="right"
          onClose={closeDrawer}
          visible={open}
          width={"495px"}
        >
          <div className="section">{addNewAccomodation}</div>
        </Drawer>
      </section>
    </>
  );
};

const ResidentialAccomodation = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    month: state.organisation.month,
    financialYear: state.organisation.financialYear,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(Residential_accommodation);

export default ResidentialAccomodation;
