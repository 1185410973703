import { DeleteOutlined, InboxOutlined, FileTextOutlined } from "@ant-design/icons";
import { Avatar, Card, Modal, Select, Spin, Table } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useState } from 'react';
import { addProofs, removeS3Object } from "../../../../../apis/employee";
import { generateAllowanceProofs } from '../../../../../apis/organisation';
import { createLog } from "../../../../../apis/user";
import config, { checkFinancialYear, singlePriceWithComma } from '../../../../../utils/config';
import { NormalButton } from "../../../../ComponentLibrary";
import InputNumberComponent from '../../../../InputLibrary/InputNumberComponent/InputNumberComponent';
const { Option } = Select;
const AllowanceFileUploader = ({ user, organisationFreeze }) => {
    const [loading, setLoading] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});
    const [viewModal, setViewModal] = useState(false);
    const [currentDocuments, setCurrentDocuments] = useState([]);
    const [activeModal, setActiveModal] = useState(false);
    const [proofDetails, setProofDetails] = useState({});
    const [financialYear, setFinancialYear] = useState(checkFinancialYear().financialYear);
    const [financialYearList, setFinancialYearList] = useState([]);

    useEffect(() => {
        function calculateFinancialYear() {
            let startFYear = 2020;
            let currentDate = new Date();
            let currentYear = currentDate.getFullYear();
            let resFinancialYearList = [];
            for (let i = startFYear; i < currentYear + 1; i++) {
                let nextYear = i + 1;
                let tempFYear = `${i}-${nextYear}`;
                resFinancialYearList.push(tempFYear);
            }
            resFinancialYearList.reverse();
            setFinancialYearList([...resFinancialYearList]);
        }
        calculateFinancialYear();
    }, []);

    useEffect(() => {
        const { month } = checkFinancialYear();
        if (user._id) {
            generateAllowanceProofs({
                id: user._id,
                month,
                financialYear
            }).then(res => {
                if (res.data.success) {
                    setProofDetails(res.data.data)
                } else {
                    setProofDetails({})
                }
            }).catch(err => {
                setProofDetails({})
            })
        }
    }, [financialYear, user])


    const uploadProps = {
        name: "file",
        action: `${config.rootUrl}/api/document/proofs`,
        headers: {
            authorization: localStorage.getItem("mool_auth"),
        },
        multiple: false,
        async onChange(info) {
            setLoading(true);
            let { response } = info.file;
            if (response) {
                setLoading(false);
                let tempDocumentList = currentRecord.documents ?? [];
                let documentDetails = { ...response.data.stored };
                documentDetails.verified = false;
                documentDetails.createdAt = new Date();
                documentDetails.name = info.file.name.substring(0, info.file.name.lastIndexOf('.'));
                delete documentDetails.Bucket;
                delete documentDetails.ETag;
                tempDocumentList.push(documentDetails);
                setCurrentRecord({ ...currentRecord, documents: tempDocumentList });
            }
        },
    };




    const columns = [
        {
            title: "Allowance name",
            dataIndex: ["meta", "label"],
        },
        {
            title: "Annual declared amount",
            dataIndex: ["meta", "annualDeclaredAmount"],
            render: (key) => {
                return <span>{singlePriceWithComma(key ? key : 0)}</span>
            }
        },
        {
            title: "Proof upload amount",
            dataIndex: "amount",
        },
        {
            title: "Uploaded Documents",
            dataIndex: "documents",
            render: (key) => {
                return (
                    <span style={{ color: "#5d74da", cursor: "pointer" }} onClick={() => { setCurrentDocuments(key); setViewModal(true) }}><FileTextOutlined /> {key.length ?? 0} Attachments</span>
                )
            }
        },
        {
            title: "",
            dataIndex: "subCatagory",
            render: (key, record) => {
                return (
                    <NormalButton type={7}
                        buttonText="Edit / Upload"
                        onClick={() => { setActiveModal(true); setCurrentRecord(record) }}
                    />
                )
            }
        },
    ];

    const removeDocumentFromDocuments = async (key, index) => {
        setLoading(true);
        let tempDocumentList = currentRecord;
        await removeS3Object({ Key: key });
        let tempDocument = tempDocumentList.documents.filter(
            (_val, i) => i !== index
        );
        tempDocumentList.documents = [...tempDocument];
        setCurrentRecord({ ...tempDocumentList });
        setLoading(false);
    };



    const handleSaveRecord = async () => {
        setProofDetails({
            ...proofDetails,
            [currentRecord.subCatagory]: {
                ...currentRecord
            }
        })
        setCurrentRecord({});
        await addProofs({ proofObj: { ...currentRecord, id: user._id } });
        await createLog({
            name: user.displayName,
            userId: user._id,
            organisationId: user.organisationId,
            message: `User has uploaded the proofs`,
            category: 13,
        });
        setActiveModal(false);
    }
    return (
        <div >
            <Modal visible={viewModal} width="400px" footer={[]} onCancel={() => { setViewModal(false) }} title="View Proof Details!">
                <div className='m-top-10'>
                    {
                        currentDocuments.length > 0 ? currentDocuments.map((item, index) => {
                            return (
                                <Card key={item.Key} className="m-bottom-10">
                                    <div className="flex">
                                        <Avatar shape='square' style={{ background: "#F55050" }}>PDF</Avatar>
                                        <h3 className="m-left-20">{item.name}</h3>
                                    </div>
                                </Card>
                            )
                        }) : <h3 className="document-viewer">No Attachments</h3>
                    }
                </div>
            </Modal>
            <Modal visible={activeModal} width="400px" okText="Save" onCancel={() => { setActiveModal(false); }} onOk={() => {
                handleSaveRecord()
            }} title="Upload & Edit Proof Details!">
                <InputNumberComponent margin={[0, 0, 0, 0]} max={currentRecord?.meta?.annualDeclaredAmount ?? 0} width="100%" value={currentRecord.amount ?? 0} labeltext={"Proof Declared Amount"} onChange={(e) => {
                    setCurrentRecord({
                        ...currentRecord,
                        amount: e
                    })
                }} />
                <p style={{ marginTop: "5px", fontStyle: "italic" }}>Maximum Limit is: {currentRecord?.meta?.annualDeclaredAmount ?? 0} INR</p>
                <Dragger
                    {...uploadProps}
                    multiple={true}
                    beforeUpload={config.beforeFileUpload}
                    showUploadList={false}
                    className="dragger-container"
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                        Click or drag file to this area to upload
                    </p>
                </Dragger>
                <div className='m-top-10'>
                    {
                        currentRecord.documents ? currentRecord.documents.map((item, index) => {
                            return (
                                <Card key={item.Key} className="m-bottom-10">
                                    <div className='app-flex-container-3'>
                                        <Avatar shape='square' style={{ background: "#F55050" }}>PDF</Avatar>
                                        <h3 className="m-left-20">{item.name}</h3>
                                        <DeleteOutlined style={{ cursor: "pointer" }} onClick={() => { removeDocumentFromDocuments(item.Key, index) }} />
                                    </div>
                                </Card>
                            )
                        }) : ""
                    }
                </div>
            </Modal>
            <div className="m-bottom-10">
                <h1 className='app-heading-2'>Allowance Proof Upload</h1>
                <div
                    className="flex align-center justify-end"
                    style={{ marginLeft: "2rem" }}
                >
                    <h3 className="font-weight-700 text-size-21 mb-2 ml-5">Financial Year</h3>
                    <Select
                        defaultValue={financialYear}
                        className="general-ant-field-select ml-2"
                        style={{
                            width: "8rem",
                            marginLeft: "1rem",
                            background: "none",
                            padding: 0
                        }}
                        size="large"
                        onChange={e => {
                            setFinancialYear(e);
                        }}
                        bordered={false}
                    >
                        {financialYearList.map((val, index) =>
                            <Option key={`opt-${index}`} value={val}>
                                <h3>{val}</h3>
                            </Option>
                        )}
                    </Select>
                </div>
            </div>
            <>
                {
                    organisationFreeze.allowancesFreeze && organisationFreeze.allowances[user._id] ? <h1>Can not perform any changes, currently allowances are freezed by your employer.</h1> :
                        <Spin spinning={loading} size="large">
                            <Table columns={columns} dataSource={Object.values(proofDetails)} />
                        </Spin>
                }</>

        </div>
    )
}

export default AllowanceFileUploader