import {
  ArrowRightOutlined, CloseOutlined, DeleteOutlined, InboxOutlined
} from "@ant-design/icons";
import {
  Button, Card, Col, Drawer, InputNumber, message, Modal, notification, Row, Select, Skeleton, Spin, Typography, Upload
} from "antd";
import React, { useEffect, useState } from "react";
import PasswordResponses from "react-pdf/dist/esm/PasswordResponses";
import packageJson from "../../../../../../package.json";
import { FileLogos } from "../../../../ComponentLibrary";
import NormalButton from "../../../../ComponentLibrary/ButtonComponents/NormalButton";
// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { connect } from "react-redux";
import {
  addProofs, deleteCurrentPayslip, proofsByCategoryAndFY, removeProof, removeS3Object, updateDeclarations, updateEmployeeOnBoarding
} from "../../../../../apis/employee";
import { createLog, getDocumentURL } from "../../../../../apis/user";
import verifiedFalse from "../../../../../assets/common/verifiedLogos/verify-no.svg";
import verifiedTrue from "../../../../../assets/common/verifiedLogos/verify-yes.svg";
import employee from "../../../../../redux/modules/employee";
import config, {
  checkFinancialYear,
  numformatter
} from "../../../../../utils/config";
import SectionHeading from "./SectionHeading";
const { Text } = Typography;
const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const { Dragger } = Upload;
const { Option } = Select;

const SubmitForm = ({
  sectionFullData,
  Category,
  subCatagory,
  user,
  declaration,
  sectionDocuments,
  employeeReducer,
  financialYear,
  ctc,
  organisationFreeze,
  onboarding,
}) => {
  const [displayAddNewDocument, setDisplayAddNewDocument] = useState(false);
  const [displayAddMoreAmount, setDisplayAddMoreAmount] = useState(false);
  const [documentUploadList, setDocumentUploadList] = useState({
    subCatagory: "",
    amount: 0,
    documents: [],
  });
  const [allowanceDocuments, setAllowanceDocuments] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState("");
  const [modalstatus, setModalStatus] = useState(false);
  const [format, setFormat] = useState("");
  const [totalDeductionAmount, setTotalDeductionAmount] = useState(0);
  const [declarationDrawer, setDeclarationDrawer] = useState(false);
  const [viewFileDocumentProps, setViewFileDocumentProps] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderToolbar = (Toolbar) => (React.createElement(Toolbar, null, (slots) => {
    const { CurrentPageInput, Download, EnterFullScreen, GoToNextPage, GoToPreviousPage, NumberOfPages, Zoom, ZoomIn, ZoomOut } = slots;
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <div style={{ padding: '0px 2px' }}>
          <ZoomOut />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <Zoom />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <ZoomIn />
        </div>
        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
          <GoToPreviousPage />
        </div>
        <div style={{ padding: '0px 2px', width: '4rem' }}>
          <CurrentPageInput />
        </div>
        <div style={{ padding: '0px 2px' }}>
          / <NumberOfPages />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <GoToNextPage />
        </div>
        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
          <EnterFullScreen />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <Download />
        </div>
      </div>
    );
  }));

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
  });
  const calculateTotalDeduction = () => {
    let total = 0;
    declaration &&
      declaration.declarations.deductions.forEach((data) => {
        if (data.section === Category) {
          total += data.amount;
        }
      });
    setTotalDeductionAmount(total);
  };

  useEffect(() => {
    employeeReducer.updateDeclarations({ id: user._id, financialYear })
  }, [employeeReducer, financialYear, user._id])

  useEffect(() => {
    calculateTotalDeduction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [declaration]);

  const calculateAmountOfFileUploads = (subCategory) => {
    let total = 0;
    allowanceDocuments.forEach((data) => {
      if (data.subCatagory === subCategory && data.varified) {
        total += data.amount;
      }
    });
    return total;
  };


  const recalculatePayslip = async () => {
    await deleteCurrentPayslip(user._id);
    const { month } = checkFinancialYear();
    await employeeReducer.askMool({
      financialYear,
      month,
      id: user._id,
      user,
      ctc,
    });
  };

  const getDocumentURLFromKey = async (data) => {
    let fileProperty = {
      documentKey: data.Key,
      documentType: data.Key.split(".").pop(),
      documentName: data.name,
    }
    setViewFileDocumentProps(fileProperty);
    let documents = await getDocumentURL({ Key: data.Key });
    setDocument(documents.data);
    if (fileProperty.documentType === "pdf") {
      setFormat("pdf");
      setModalStatus(false);
      showModal();
    } else {
      setFormat("image");
      setModalStatus(true);
    }
  };

  useEffect(() => {
    getProofsByCategory();
    if (subCatagory) {
      setDocumentUploadList({
        ...documentUploadList,
        subCatagory: subCatagory,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCatagory, financialYear]);

  const onPasswordHandler = function (callback, reason) {
    function callbackProxy(password) {
      // Cancel button handler
      if (!password) {
        setModalStatus(false)
        return
      }
      callback(password);
    }

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(password);
        break;
      }
      default:
    }
  }

  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/document/proofs`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    multiple: false,
    async onChange(info) {
      setLoading(true);
      let { response } = info.file;
      if (response) {
        let tempDocumentList = documentUploadList;
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        documentDetails.name = info.file.name.substring(
          0,
          info.file.name.lastIndexOf(".")
        );
        delete documentDetails.Bucket;
        delete documentDetails.ETag;
        tempDocumentList.documents.length !== 3 &&
          tempDocumentList.documents.push(documentDetails);
        setDocumentUploadList({ ...tempDocumentList });
      }
      setLoading(false);
    },
  };

  const removeDocument = async (doc, index) => {
    let tempDocumentList = documentUploadList;
    let tempDocument = tempDocumentList.documents.filter(
      (_val, i) => i !== index
    );
    await removeS3Object({ Key: doc.key });
    tempDocumentList.documents = [...tempDocument];
    setDocumentUploadList({ ...tempDocumentList });
  };

  const getDeductionName = (category) => {
    let tempDeduction = config.deductionData;
    return tempDeduction.filter((val) => val.declaration === category)[0].label;
  };

  const addMoreAmount = async () => {
    if (
      organisationFreeze.declarationFreeze &&
      organisationFreeze.declaration[user._id]
    ) {
      notification.warn({
        message: `Organisation has freezed the declarations for the current financial year`,
      });
      return;
    }
    if (
      !documentUploadList.subCatagory ||
      isNaN(parseInt(documentUploadList.amount))
    ) {
      notification.error({ message: "Please enter every details" });
      return;
    }
    setLoading(true);
    let tempDeduction = declaration.declarations.deductions;
    let valueOfFilesUploaded = calculateAmountOfFileUploads(
      documentUploadList.subCatagory
    );
    if (valueOfFilesUploaded > parseInt(documentUploadList.amount)) {
      message.warning(
        `The document uploaded for the selected exemptions is more then the amount.`
      );
      setDocumentUploadList({
        ...documentUploadList,
        amount: 0,
        subCatagory: subCatagory ? subCatagory : "",
        documents: [],
      });
      setDisplayAddMoreAmount(false);
      setLoading(false);

      return;
    }

    for (const element of tempDeduction) {
      if (element.declaration === documentUploadList.subCatagory) {
        element.amount = documentUploadList.amount;
        break;
      }
    }
    let tempDeclaration = declaration.declarations;
    tempDeclaration.deductions = tempDeduction;
    const { month } = checkFinancialYear();

    let response = await updateDeclarations({
      id: user._id,
      declarations: tempDeclaration,
      financialYear,
      month
    });
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `User has updaed the declarations`,
      category: 3
    });
    if (response.data.success) {
      setDisplayAddMoreAmount(false);
      if (onboarding && !onboarding.fields.declarations) {
        await updateEmployeeOnBoarding({
          fields: { declarations: true },
          organisationId: user.organisationId,
          id: user._id,
        });
        employeeReducer.updateEmployeeOnBoarding({
          organisationId: user.organisationId,
          id: user._id,
        });
      }
      notification.success({
        message: "Successfully updated deduction amount",
      });
      declaration.declarations.deductions = [...tempDeduction];
      await recalculatePayslip();

      calculateTotalDeduction();

      setDocumentUploadList({
        ...documentUploadList,
        amount: 0,
        subCatagory: subCatagory ? subCatagory : "",
        documents: [],
      });
    } else {
      notification.error({
        message: "Failed to update deduction amount, please try again",
      });
    }
    setLoading(false);
  };

  const uploadProofs = async () => {
    if (
      !documentUploadList.subCatagory ||
      !documentUploadList.amount ||
      !documentUploadList.documents.length
    ) {
      notification.error({ message: "Please enter every details" });
      return;
    }
    setLoading(true);
    let tempUploadData = { proofObj: documentUploadList };
    tempUploadData.proofObj.id = user._id;
    tempUploadData.proofObj.organisationId = user.currentOrganisationId;
    tempUploadData.proofObj.category = Category;
    tempUploadData.proofObj.proofType = 2;
    tempUploadData.proofObj.financialYear = financialYear;

    await addProofs(tempUploadData);
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `User has uploaded the deduction proofs`,
      category: 9,
    });
    if (onboarding && !onboarding.fields.declarations) {
      await updateEmployeeOnBoarding({
        fields: { declarations: true },
        organisationId: user.organisationId,
        id: user._id,
      });
      employeeReducer.updateEmployeeOnBoarding({
        organisationId: user.organisationId,
        id: user._id,
      });
    }
    setDisplayAddNewDocument(false);
    setDocumentUploadList({
      ...documentUploadList,
      amount: 0,
      documents: [],
    });
    await getProofsByCategory();
    calculateTotalDeduction();
    setLoading(false);
  };

  const DocumentAllowances = ({ data }) => {
    return (
      <div className="document-container">
        <div
          style={{ display: "flex" }}
          onClick={() => {
            setModalStatus(true);
            getDocumentURLFromKey(data);
          }}
        >
          <span className="file-typeHeader">{data.Key.split(".").pop()}</span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ width: 300 }} ellipsis={{ tooltip: data.name }}>
              {data.name}
            </Text>
            <span className="date-contianer">
              {new Date(data.createdAt).toDateString()}
            </span>
          </div>
        </div>
        {data.verified ? (
          <span className="verifiection-text verified">
            <img
              alt="verification status logo"
              className="verifiection-logo"
              src={verifiedTrue}
            ></img>
            Verified
          </span>
        ) : (
          <span className="verifiection-text not-verified">
            <img
              alt="verification status logo"
              className="verifiection-logo"
              src={verifiedFalse}
            ></img>
            Not Verified
          </span>
        )}
      </div>
    );
  };

  const getProofsByCategory = async () => {
    setLoading(true);
    let data = await proofsByCategoryAndFY({
      id: user._id,
      category: Category,
      financialYear,
    });
    setAllowanceDocuments([...data.data]);
    setLoading(false);
  };

  const removeProofs = async (data) => {
    setLoading(true);
    await removeProof(data.proofId);
    await getProofsByCategory();
    setLoading(false);
  };

  let styles = {
    border: "2px #5E5F62 solid",
    width: "688px",
    maxWidth: "100%",
    height: "50px",
    borderRadius: "8px",
    marginRight: "2px",
    marginTop: "30px"
  };

  return (
    <div className="section-full-data-container">
      <SectionHeading {...sectionFullData} />
      {totalDeductionAmount > 0 && (
        <p>
          <span className="app-label-1">Total declaration provided : </span>
          <span className="app-label-value-1">
            {config.numformatter(totalDeductionAmount)} INR
          </span>
        </p>
      )}
      {allowanceDocuments.length !== 0 && (
        <Button
          style={{
            border: "none",
            padding: 0,
          }}
          onClick={async () => {
            setDrawerVisible(true);
          }}
          className="section-full-data-all-documents-anchor"
        >
          <span className="section-full-data-all-documents-anchor-data">
            View {allowanceDocuments.length} document
          </span>
          <ArrowRightOutlined />
        </Button>
      )}
      {totalDeductionAmount > 0 && (
        <Button
          style={{
            border: "none",
            padding: 0,
            marginLeft: 10,
          }}
          onClick={async () => {
            setDeclarationDrawer(true);
          }}
          className="section-full-data-all-documents-anchor"
        >
          <span className="section-full-data-all-documents-anchor-data">
            View {Category} Declarations
          </span>
          <ArrowRightOutlined />
        </Button>
      )}
      {
        <>
          {displayAddMoreAmount ? (
            <div>
              <Row gutter={[10, 10]} className="document-data-container">
                {!subCatagory && (
                  <Col xs={24} sm={24} md={18} lg={18}>
                    <div style={{ marginTop: "0px" }} className="dropdowndiv">
                      {/* <h5 className="app-heading-7">Document Type</h5> */}
                      <Select
                        className="general-ant-field-select"
                        style={styles}
                        onChange={(val) => {
                          let tempDocumentList = documentUploadList;
                          tempDocumentList.subCatagory = val;
                          setDocumentUploadList({ ...tempDocumentList });
                        }}
                        disabled={
                          organisationFreeze.declarationFreeze &&
                          organisationFreeze.declaration[user._id]
                        }
                        filterOption={(input, option) =>
                          option.children &&
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        showSearch
                        value={documentUploadList.subCatagory}
                        size="large"
                        bordered={false}
                      >
                        {sectionDocuments.map((doc, index) => {
                          return (
                            <Option
                              key={`80d-option-key-${index}`}
                              value={doc.declaration}
                            >
                              {doc.label}
                            </Option>
                          );
                        })}
                      </Select>
                      <label
                        style={{ backgroundColor: "white", marginTop: "30px" }}
                        className=
                        "labelup"

                      >
                        {"Document Type"}
                      </label>
                    </div>
                  </Col>
                )}
                <Col xs={24} sm={24} md={6} lg={6}>
                  {/* <h5 className="app-heading-7" >Amount in INR</h5> */}
                  <InputNumber
                    value={documentUploadList.amount}
                    onChange={(val) =>
                      setDocumentUploadList({ ...documentUploadList, amount: val })
                    }
                    style={styles}
                    size="large"
                    type="number"
                  />
                  <label
                    className={"labelup"} style={{ marginTop: "30px" }}
                  >
                    {"Amount in INR"}
                  </label>
                </Col>
              </Row>
              <div className="document-data-buttons-container">
                <NormalButton
                  className="skip-button"
                  buttonText={"Close"}
                  disabled={
                    organisationFreeze.declarationFreeze &&
                    organisationFreeze.declaration[user._id]
                  }
                  onClick={async () => {
                    setDisplayAddMoreAmount(false);
                    setDocumentUploadList({
                      subCatagory: "",
                      amount: 0,
                      documents: [],
                    });
                  }}
                />
                <NormalButton
                  className="skip-button"
                  buttonText={"Reset"}
                  type={3}
                  disabled={
                    organisationFreeze.declarationFreeze &&
                    !organisationFreeze.declaration[user._id]
                  }
                  onClick={async () => {
                    setDocumentUploadList({
                      subCatagory: "",
                      amount: 0,
                      documents: [],
                    });
                  }}
                />
                <NormalButton buttonText={"Add"} type={13} onClick={addMoreAmount} className="submit-button" />
              </div>
            </div>
          ) : (
            !(
              organisationFreeze.declarationFreeze &&
              organisationFreeze.declaration[user._id]
            ) && (
              <Button
                onClick={() => setDisplayAddMoreAmount(true)}
                className="add-new-document-button"
              >
                Update Declaration Amount
              </Button>
            )
          )}
        </>
      }
      {/* TODO: Make a separate component */}
      {displayAddNewDocument ? (
        <Spin spinning={loading} size="large">
          <Row gutter={[10, 10]} className="document-data-container">
            {!subCatagory && (
              <Col xs={24} sm={24} md={18} lg={18}>
                {/* <h5 className="app-heading-7">Document Type</h5> */}
                <div className="dropdowndiv">
                  <Select

                    className="general-ant-field-select"
                    onChange={(val) =>
                      setDocumentUploadList({
                        ...documentUploadList,
                        subCatagory: val,
                      })
                    }
                    style={styles}
                    value={documentUploadList.subCatagory}
                    size="large"
                    bordered={false}
                    filterOption={(input, option) =>
                      option.children &&
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    showSearch
                  >
                    {sectionDocuments.map((doc, index) => {
                      return (
                        <Option
                          key={`80d-option-key-${index}`}
                          value={doc.declaration}
                        >
                          {doc.label}
                        </Option>
                      );
                    })}
                  </Select>
                  <label
                    style={{ backgroundColor: "white", marginTop: "30px" }}
                    className=
                    "labelup"

                  >
                    {"Document Type"}
                  </label>
                </div>
              </Col>
            )}
            <Col xs={24} sm={24} md={6} lg={6}>
              {/* <h5 className="app-heading-7" style = {{ marginLeft: "-70px",}}>Amount INR</h5> */}
              {/* <InputNumberComponent
                value={documentUploadList.amount}
                style = {{borderRadius: "8px", marginLeft: -70, width: 200}}
                size="large"
                onChange={(val) =>
                  setDocumentUploadList({ ...documentUploadList, amount: val })
                }
              /> */}

              <InputNumber
                value={documentUploadList.amount}
                size="large"
                style={styles}
                type="number"
                onChange={(val) =>
                  setDocumentUploadList({ ...documentUploadList, amount: val })
                }
              />
              <label
                className={"labelup"}
                style={{ marginTop: "30px" }}
              >
                {"Amount in INR"}
              </label>
            </Col>
          </Row>
          <div className="document-list">
            {documentUploadList.documents.map((val, index) => {
              return (
                <div
                  className="document-container"
                  key={`doc-container-${index}`}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DeleteOutlined
                      key={`doc-container-delete-${index}`}
                      onClick={() => removeDocument(val, index)}
                    />
                    <div
                      key={`doc-container-filetype-${index}`}
                      className="file-typeHeader"
                    >
                      {val.Key.split(".").pop()}
                    </div>
                    <span key={`doc-container-name-${index}`}>{val.Key}</span>
                  </div>
                </div>
              );
            })}
          </div>
          {documentUploadList.documents.length < 3 && (
            <Dragger
              {...uploadProps}
              multiple={true}
              showUploadList={false}
              beforeUpload={config.beforeFileUpload}
              className="dragger-container"
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">upload max of 3 files</p>
            </Dragger>
          )}
          <div className="document-data-buttons-container">
            <NormalButton
              className="skip-button"
              buttonText={"Close"}
              onClick={async () => {
                let documents = documentUploadList.documents;
                for (let i of documents) {
                  await removeS3Object({ Key: i.key });
                }
                setDisplayAddNewDocument(false);
                setDocumentUploadList({
                  subCatagory: "",
                  amount: 0,
                  documents: [],
                });
              }}
            />
            <NormalButton
              className="skip-button"
              buttonText={"Reset"}
              type={3}
              onClick={async () => {
                let documents = documentUploadList.documents;
                for (let i of documents) {
                  await removeS3Object({ Key: i.key });
                }
                setDocumentUploadList({
                  subCatagory: "",
                  amount: 0,
                  documents: [],
                });
              }}
            />
            <NormalButton onClick={uploadProofs} buttonText={"Add"} type={13} className="submit-button" />

          </div>
          <hr></hr>
        </Spin>
      ) : (
        !displayAddMoreAmount && (
          <Button
            onClick={() => setDisplayAddNewDocument(true)}
            className="add-new-document-button"
          >
            Add Proofs
          </Button>
        )
      )}
      {/* Separate Component */}
      <Drawer
        width={640}
        placement="right"
        closable={false}
        onClose={() => {
          setDrawerVisible(false);
        }}
        visible={drawerVisible}
      >
        <div className="otherAllowance-files-container">
          <div className="otherAllowance-files-header flex align-center justify-between">
            <h1 className="app-heading-3">
              {sectionFullData.sectionType} Documents
            </h1>
            <CloseOutlined
              onClick={() => {
                setDrawerVisible(false);
              }}
            />
          </div>
          <div className="document-list-container">
            <Skeleton loading={loading} active paragraph={{ rows: 3 }}>
              {allowanceDocuments &&
                allowanceDocuments.map((value, index) => {
                  return (
                    <div key={value.subCatagory}>
                      <Card
                        className="document-group"
                        bordered={false}
                        style={{
                          borderBottom: 16,
                        }}
                      >
                        <div className="document-header">
                          <div className="document-header-button-and-name-container">
                            <div className="document-action-container">
                              <Button
                                danger
                                shape="circle"
                                type=""
                                onClick={() => removeProofs(value)}
                              >
                                <DeleteOutlined
                                  key={`doc-container-delete-${index}`}
                                />
                              </Button>
                            </div>
                            <p className="document-header-labele">
                              {getDeductionName(value.subCatagory)}
                            </p>
                          </div>
                          <p className="allowance-amount">
                            Amount: INR {config.numformatter(value.amount)}
                          </p>
                        </div>
                        <div className="document-list">
                          {value.documents.map((val, i) => (
                            <DocumentAllowances data={val} key={`doc-${i}`} />
                          ))}
                        </div>
                      </Card>
                      <div className="seperator"></div>
                    </div>
                  );
                })}
            </Skeleton>
          </div>
        </div>
      </Drawer>
      {/* Separate Component */}
      <Drawer
        width={640}
        placement="right"
        closable={false}
        onClose={() => {
          setDeclarationDrawer(false);
        }}
        visible={declarationDrawer}
      >
        <div className="flex align-center justify-between p-10">
          <h1 className="app-heading-3">
            {sectionFullData.sectionType} Documents
          </h1>
          <CloseOutlined
            onClick={() => {
              setDeclarationDrawer(false);
            }}
          />
        </div>
        {// eslint-disable-next-line array-callback-return
          declaration.declarations.deductions.map((data) => {
            if (data.section === Category && data.amount > 0) {
              return (
                <div className="flex justify-between align-center p-10">
                  <p className="app-label-3">{data.label}</p>
                  <p className="app-label-value-4 font-weight-700">
                    {numformatter(data.amount)} INR
                  </p>
                </div>
              );
            }
          })}
        {/* <div className="flex justify-between align-center">
          <p className="app-label-3">{sub}</p>
          <p className="app-label-value-4 font-weight-700">
            {numformatter(deductionsObject[section].subsection[sub])} INR
          </p>
        </div> */}
      </Drawer>
      {/* Separate Component */}
      {format === "image" && <Modal
        onCancel={() => {
          setDocument("");
          setModalStatus(false);
          setFormat("");
        }}
        visible={modalstatus}
        footer={null}
        centered
        width={900}
      >
        <Skeleton loading={!document} active avatar paragraph={{ rows: 5 }}>
          <a download href={document}>
            Download Document
          </a>
          <img alt="" src={document} className="document-display" />
        </Skeleton>
      </Modal>}
      {format === "pdf" &&
        <Modal
          title={
            <>
              <FileLogos type={1} /> {viewFileDocumentProps.documentName}
            </>
          }
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          footer={null}
        >
          <div>
            {document ? (
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={document}
                  plugins={[defaultLayoutPluginInstance]}
                  renderError={console.log}
                  onDocumentAskPassword={onPasswordHandler}
                  initialPage={1}
                />
              </Worker>
            ) : (
              "Document URL not found"
            )}
          </div>
        </Modal>
      }
    </div>
  );
};

const SubmitFormContainer = connect(
  (state) => ({
    user: state.auth.user,
    declaration: state.employee.declaration,
    ctc: state.employee.ctc,
    hra: state.employee.hra,
    deductions: state.employee.deductions,
    reimbursements: state.employee.reimbursements,
    allowances: state.employee.allowances,
    customAllowances: state.employee.customAllowances,
    organisationSettings: state.employee.organisationSettings,
    monthClose: state.employee.monthClose,
    group: state.employee.group,
    organisationFreeze: state.employee.organisationFreeze,
    onboarding: state.employee.onboarding,
    location: state.employee.location,
    isPercentageStructure: state.employee.isPercentageStructure,
    previousEmployement: state.employee.previousEmployement,
    payslips: state.employee.payslips,
    percentage: state.employee.percentage,
    advanceTaxConfig: state.employee.advanceTaxConfig,
  }),
  (dispatch) => ({
    employeeReducer: employee.getActions(dispatch),
  })
)(SubmitForm);

export default SubmitFormContainer;