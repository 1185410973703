const { Allowance, Perks } = require("./keys") 

// ALLOWANCES
let govtAllowancesLimit = {};
govtAllowancesLimit[Allowance.EXT_CHILD_EDU] = 100
govtAllowancesLimit[Allowance.EXT_HOSTEL_EDU] = 300
govtAllowancesLimit[Allowance.EXT_TRANSPORT_DIVYANG] = 3200
govtAllowancesLimit[Allowance.EXT_TRANSPORT_BUSINESS] = 10000
govtAllowancesLimit[Allowance.EXT_TIFFIN_LUNCH_DINNER] = 3000

// PERKS
let perksLimitByLaw = {}
perksLimitByLaw[Perks.EXT_GIFT_VOUCHER] = 5000
perksLimitByLaw[Perks.EXT_CONCESSIONAL_LOAN] = 20000

//Parially Taxable
let partiallyTaxable =  {}
partiallyTaxable[Allowance.EXT_LTA] = true; // No Limit
partiallyTaxable[Allowance.EXT_TIFFIN_LUNCH_DINNER] = true; // 3000
partiallyTaxable[Allowance.EXT_DAILY] = true; // Limit need to be found No Limit
partiallyTaxable[Allowance.EXT_HELPER] = true; // No Limit
partiallyTaxable[Allowance.EXT_RESEARCH] = true; // No Limit
partiallyTaxable[Allowance.EXT_UNIFORM] = true; // No Limit
partiallyTaxable[Allowance.EXT_CHILD_EDU] = true; // 100 for one child and 200 for two
partiallyTaxable[Allowance.EXT_HOSTEL_EDU] = true; // 300 for one child and 600 for two
partiallyTaxable[Allowance.EXT_TRANSPORT_DIVYANG] = true; // 3200
partiallyTaxable[Allowance.EXT_TRANSPORT_BUSINESS] = true; // 10000
partiallyTaxable[Allowance.EXT_TEL] = true; // No Limit
partiallyTaxable[Allowance.EXT_MOB] = true; // No Limit
partiallyTaxable[Allowance.EXT_LAPTOP_REIMBURSEMENT] = true; // No Limit
partiallyTaxable[Allowance.EXT_BOOKS_OF_PERIODICALS] = true; // No Limit
partiallyTaxable[Allowance.EXT_FUEL_AND_VEHICLE_MAINTENANCE] = true; // No Limit

// No Limit means till the value they are putting and providing the proof

// Fully taxable
let fullyTaxable =  {}
fullyTaxable[Allowance.EXT_DEARNESS] = true;
fullyTaxable[Allowance.EXT_OVERTIME] = true;
fullyTaxable[Allowance.EXT_FIXED_MEDICAL] = true;
fullyTaxable[Allowance.EXT_PROJECT] = true;
fullyTaxable[Allowance.EXT_TRANSPORT_REGULAR] = true;
fullyTaxable[Allowance.SPECIAL] = true;
fullyTaxable[Allowance.EXT_CONVEYANCE] = true;

// section_10_14_1
let section_10_14_1 = {}
section_10_14_1[Allowance.EXT_DAILY] = true;
section_10_14_1[Allowance.EXT_HELPER] = true;
section_10_14_1[Allowance.EXT_RESEARCH] = true;
section_10_14_1[Allowance.EXT_UNIFORM] = true;
// section_10_14_2
let section_10_14_2 = {}
section_10_14_2[Allowance.EXT_CHILD_EDU] = true;
section_10_14_2[Allowance.EXT_HOSTEL_EDU] = true;
section_10_14_2[Allowance.EXT_TRANSPORT_DIVYANG] = true;
section_10_14_2[Allowance.EXT_TRANSPORT_BUSINESS] = true;

// old_regime_excluded 
let old_regime_excluded = {}
old_regime_excluded[Allowance.EXT_TOUR_TRAVEL_TRANSFER] = true
old_regime_excluded[Allowance.EXT_TRANSPORT_REGULAR] = true;

// new_regime_included
let new_regime_included = {}
new_regime_included[Allowance.EXT_TOUR_TRAVEL_TRANSFER] = true;
new_regime_included[Allowance.EXT_TRANSPORT_DIVYANG] = true;
new_regime_included[Allowance.EXT_DAILY] = true;

module.exports = {
    govtAllowancesLimit,
    perksLimitByLaw,
    partiallyTaxable,
    fullyTaxable,
    section_10_14_1,
    section_10_14_2,
    old_regime_excluded,
    new_regime_included
}