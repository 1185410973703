import { Avatar, Card, Drawer, notification, Spin, Table } from "antd";
import { Buffer } from 'buffer';
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { downloadJannRentReceiptDocument, getJannRentReceiptDocument } from "../../../apis/jann";
import { updateRentReceiptDocumentStatus } from "../../../apis/organisation";
import organisation from "../../../redux/modules/organisation";
import { HeaderComponent } from "../../Common";
import { NormalButton } from "../../ComponentLibrary";


const possibleStatus = {
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
};
const buttonTypeValue = {
  ACCEPTED: 15,
  REJECTED: 9,
};
const getStatus = (key) => {
  if (key === 1) {
    return "Paid";
  } else if (key === 2) {
    return "Signed";
  }
  return "Unsigned";
};

const getEmployerStatus = (key) => {
  if (key === 1) {
    return "Acknowledged By HR";
  } else if (key === 2) {
    return "Accepted By HR";
  } else if (key === 3) {
    return "Rejected By HR";
  }
  return "Pending At HR";
};
const RentReceipt = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [currDetails, setCurrDetails] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const updateStatusHandler = async (rentReceiptID, status) => {
    setLoading(true);
    let res = await updateRentReceiptDocumentStatus(rentReceiptID, status);
    setLoading(false);
    if (res.data.success) {
      notification.success({ message: "Updated status successfully" });
    } else {
      notification.error({ message: "Unable to update status" });
    }
    setRefresh(!refresh);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Documents",
      width: "30%",
      dataIndex: "_id",
      render: (key, record) => {
        setCurrentRecord(record);
        return (
          <div style={{ display: "flex" }}>
            <div
              style={{
                color: "#4285F4",
                cursor: "pointer",
                marginTop: "0.7rem",
                maxWidth: "30%",
              }}
              onClick={() => {
                handleAction();
                setCurrDetails(record);
              }}
            >
              Download Document
            </div>
            {!Object.keys(possibleStatus).includes(
              record.digitalSigning.HR.status
            ) && (
                <div
                  style={{
                    marginTop: "0.6rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <NormalButton
                    type={19}
                    buttonText="Reject"
                    onClick={() => {
                      updateStatusHandler(record._id, "REJECTED");
                    }}
                    margin={[0, 8]}
                  />
                  <NormalButton
                    type={13}
                    buttonText="Accept"
                    margin={[0, 8]}
                    onClick={() => {
                      updateStatusHandler(record._id, "ACCEPTED");
                    }}
                  />
                </div>
              )}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "_id",
      render: (key, record) => {
        return (
          <div style={{ display: "flex" }}>
            {Object.keys(possibleStatus).includes(
              record.digitalSigning.HR.status
            ) && (
                <>
                  <NormalButton
                    type={buttonTypeValue[record.digitalSigning.HR.status]}
                    disabled={true}
                    buttonText={possibleStatus[record.digitalSigning.HR.status]}
                    margin={[0, 8]}
                  />
                </>
              )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (user) {
      getJannRentReceiptDocument(user.organisationId)
        .then((res) => {
          if (res.data && res.data.success) {
            setDataSource(res.data.data);
          } else {
            setDataSource([]);
          }
        })
        .catch((err) => {
          setDataSource([]);
        });
    }
  }, [user, refresh]);
  useEffect(() => {
    document.title = "Rent Receipt";
  });
  function onClose() {
    setOpen(false);
  }
  const handleAction = async () => {
    const fileName = `${currentRecord.name}'s Rent Receipt.pdf`
    const content = await downloadJannRentReceiptDocument(currentRecord.baseDocument.key);
    if (content.data !== '') {
      const buffer = Buffer.from(content.data, 'base64');

      const blob = new Blob([buffer], { type: 'application/pdf' });
      const urlDownload = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
  }
  const GetIcon = ({ documentKey }) => {
    const colors = { pdf: "#EB455F", jpeg: "#BAD7E9", png: "#0081B4" };
    const splitter =
      typeof documentKey === "string" ? documentKey.split(".")[1] : "pdf";
    return (
      <Avatar
        shape="square"
        style={{
          backgroundColor: colors[splitter],
          verticalAlign: "middle",
          borderRadius: "5px",
        }}
        size={"large"}
      >
        {splitter}
      </Avatar>
    );
  };
  const DocumentSection = ({ sectionName, documentKey, status, verified }) => {
    return (
      <Card key={documentKey} style={verified === 3 ? { opacity: 0.5 } : {}}>
        <div className="flex justify-between align-center">
          <div className="flex justify-between align-center">
            <GetIcon documentKey={documentKey} />
            <div className="flex justify-between flex-direction-column">
              <h3
                style={verified === 3 ? { textDecoration: "line-through" } : {}}
                className="m-left-10"
              >
                {sectionName}
              </h3>
              <p className="m-left-10">
                Status: {getStatus(status)} | {getEmployerStatus(verified)}
              </p>
            </div>
          </div>
        </div>
      </Card>
    );
  };
  return (
    <section className="site-layout">
      <Drawer
        title="Show & Verify Documents"
        placement="right"
        onClose={onClose}
        visible={open}
        size={"large"}
      >
        <div style={{ padding: "10px 20px" }}>
          <div>
            <h2 className="m-10">Rent Receipt Documents</h2>
            {currDetails.form12BB ? (
              <DocumentSection
                sectionName={"Rent Receipt Document"}
                documentKey={currDetails.form12BB.key}
                status={currDetails.form12BB.status}
                verified={currDetails.form12BB.verified}
                id={currDetails.id}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </Drawer>
      <HeaderComponent name={"Rent Receipt"} />
      <Spin spinning={loading} size="large">
        <div className="app-main-content-container paper-wrapper">
          <Table
            style={{ fontWeight: 500 }}
            columns={columns}
            dataSource={dataSource}
          />
        </div>
      </Spin>
    </section>
  );
};

const RentReceiptContainer = connect(
  (state) => ({
    user: state.auth.user,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(RentReceipt);
export default RentReceiptContainer;
