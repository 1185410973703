const COMMON_JSON = { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 }

const SALARY_COMPONENT = {
    basic: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    HRA: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    LTA: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    DA: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    special_Allowance: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    statutory_Bonuse: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    bonuses: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 }
}

const DEDUCTIONS = {
    employee_PF: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    employee_ESI: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    income_Tax: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    proffessional_Tax: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    FOOD: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
    other_Deductions: { values: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }, total: 0 },
}


const HRA = [
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    },
    {
        isMetro: false,
        rent: 0,
        hra: 0,
        basicDa: 0,
        basicFourtyOrFifty: 0,
        rent_minus_basic: 0,
        hraExemption: 0
    }
];

const EXEMPTIONS = {
    HRA: 0,
    LTA: 0,
    booksOfPeriodicals: 0,
    laptopReimbursement: 0,
    fuelAndVehicleMaintenance: 0,
    FOOD: 0,
    uniform: 0,
    mobile: 0,

}

const DECLARATIONS = {
    "80C": 0,
    "80D": 0,
    "80E": 0,
    "80EE": 0,
    "80TTA": 0,
    "80G": 0,
    "80CCC": 0,
    "80CCD(2)": 0,
    "80CCD1(B)": 0,
    "80CCG": 0,
    "80EEA": 0,
    "80TTB": 0,
    "80EEB": 0,
    "80DD": 0,
    "80U": 0,
    "80DDB": 0,
    "24B": 0
}

const LIMITS = {
    "80C": 150000,

}

const DECLARATION_CONSTANT = {
    "fiveYearFixedDepositScheduledBank": "5 Years of Fixed Deposit in Scheduled Bank",
    "childrenTuitionFees": "Children Tuition Fees",
    "depositInNSC": "Deposit in National Savings Certificate",
    "depositInNSS": "Deposit in National Savings Schemes",
    "depositInPostOfficeSavingsSchemes": "Deposit in Post Office Savings Schemes",
    "equityLinkedSavingsScheme": "Equity Linked Savings Scheme ( ELSS )",
    "infrastructureBonds": "Infrastructure Bonds",
    "interestReinvestedNSC": "Interest on NSC Reinvested",
    "kisanVikasPatra": "Kisan Vikas Patra (KVP)",
    "lifeInsurancePremium": "Life Insurance Premium",
    "longTermInfrastructureBonds": "Long term Infrastructure Bonds",
    "mutualFunds": "Mutual Funds",
    "ruralBondsNABARD": "NABARD Rural Bonds",
    "nationalPensionScheme": "National Pension Scheme",
    "schemeNHB": "NHB Scheme",
    "postOfficeTimeDepositForFiveYears": "Post office time deposit for 5 years",
    "pradhanMantriSurakshaBimaYojana": "Pradhan Mantri Suraksha Bima Yojana",
    "publicProvidentFund": "Public Provident Fund",
    "repaymentOfHousingLoanPrincipal": "Repayment of Housing loan(Principal amount)",
    "stampDutyAndRegistrationCharges": "Stamp duty and Registration charges",
    "sukanyaSamriddhiYojana": "Sukanya Samriddhi Yojana",
    "unitLinkedInsurancePremium": "Unit Linked Insurance Premium (ULIP)",
    "contributionPensionFund": "Contribution to Pension Fund",
    "preventiveHealthCheckupDependantParents": "Preventive Health Checkup - Dependant Parents",
    "medicalBillsVerySeniorCitizen": "Medical Bills - Very Senior Citizen",
    "medicalInsurancePremiumBelow60": "Medical Insurance Premium For Person Below 60",
    "medicalInsurancePremium60YearsAndAbove": "Medical Insurance Premium For Person 60 Years And Above",
    "medicalInsurancePremiumDependantParentsBelow60": "Medical Insurance Premium - Dependant Parents Below 60",
    "medicalInsurancePremiumDependantParents60YearsAbove": "Medical Insurance Premium - Dependant Parents 60 Years and Above",
    "preventiveHealthCheckUp": "Preventive Health Check-up",
    "medicalTreatmentOrInsuranceHandicappedDependant": "Medical Treatment / Insurance of handicapped Dependant",
    "medicalTreatmentOrInsuranceSevereHandicappedDependant": "Medical Treatment / Insurance of handicapped Dependant (Severe)",
    "medicalTreatmentSpecifiedDisease": "Medical Treatment ( Specified Disease only )",
    "medicalTreatmentSpecifiedDiseaseSeniorCitizen": "Medical Treatment (Specified Disease only)- Senior Citizen",
    "medicalTreatmentSpecifiedDiseaseVerySeniorCitizen": "Medical Treatment (Specified Disease only)- Very Senior Citizen",
    "permanentPhysicalDisabilityBelow40Prcnt": "Permanent Physical disability (Below 40%)",
    "permanentPhysicalDisabilityAbove40Prcnt": "Permanent Physical disability (Above 40%)",
    "contributionNPS2015_CCD1B": "80CCD1(B) - Contribution to NPS 2015",
    "rajivGandhiEquityScheme": "80CCG - Rajiv Gandhi Equity Scheme",
    "interestLoanHigherSelfEducation": "80E - Interest on Loan of higher Self education",
    "additionalInterestOnHousingLoanBorrowed1stApr2016": "80EE - Additional Interest on housing loan borrowed as on 1st Apr 2016",
    "additionalInterestOnHousingLoanBorrowed1stApr2019": "80EEA - Additional Interest on Housing loan borrowed as on 1st Apr 2019",
    "interestOnElectricVehicleBorrowed1stApr2019": "80EEB - Interest on Electric Vehicle borrowed as on 1st Apr 2019",
    "interestSavingsAccountDepositFDPostOfficeCooperativeSociety": "80TTA - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society",
    "interestSavingsAccountDepositFDPostOfficeCooperativeSocietySeniorCitizen": "80TTB - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society for Senior Citizen",
    "donation100PrcntExemption": "80G - Donation - 100% Exemption",
    "donation50PrcntExemption": "80G - Donation - 50% Exemption",
    "donationChildrenEducation": "80G - Donation - Children Education",
    "donationPoliticalParties": "80G - Donation - Political Parties",
    "preConstructionHomeLoanInterest": "Home Loan Interest - Pre Construction Period",
    "currentYearHomeLoanInterest": "Home Loan Interest - Current Year "
}

export {
    SALARY_COMPONENT,
    DECLARATIONS,
    HRA,
    EXEMPTIONS,
    DEDUCTIONS,
    LIMITS,
    COMMON_JSON,
    DECLARATION_CONSTANT
}