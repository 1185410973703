import React, { useState, useEffect } from "react";
import { Col, Layout, Row, Collapse, message } from "antd";
import { useHistory, withRouter } from "react-router-dom";
// import auth from "../../redux/modules/auth";
// import { connect } from "react-redux";
import sidebar from "../../assets/sidebarlogo.svg";
import "./priceModel.less";
import { NormalButton } from "../ComponentLibrary";
import * as chargebee from "../../apis/chargebee";
import { PHONENUMBER } from "../../utils/config";
import registerArrow from "../../assets/icons/regArrow.svg";
import registerArrowBlue from "../../assets/icons/regArrowBlue.svg";
import comingSoonImg from "../../assets/Comingsoonlabel.svg";
import { changeRegisterState } from "../../apis/auth";
import GreenCheck from "../../assets/icons/greenCheck.png"
import MoolLogo from "../../assets/icons/moolLogoWithName.png"
// import rightArrow from "../../assets/icons/rightArrow.png"
import blueTick from "../../assets/icons/bluetick.svg"
import blueTickSingle from "../../assets/icons/bluetick-single.svg"
import redCross from "../../assets/icons/redcross.svg"

const { Content } = Layout;
const { Panel } = Collapse;

const frequencyValues = {
  Monthly: "Monthly",
  Quaterly: "Every-3-months",
  Yearly: "Yearly",
};
function PricingModel() {
  const [fetchPlansData, setfetchPlansData] = useState({});
  const [isFeaterHiddenStr, setIsFeatureHiddenStr] = useState(false);
  const [isFeaterHiddenStd, setIsFeatureHiddenStd] = useState(false);
  const [planOpted, setPlanOpted] = useState({
    plan: "Starter",
    frequency: frequencyValues.Monthly,
  });
  const history = useHistory();
  const getPlans = async () => {
    const plans = await chargebee.getPricePlans();
    setfetchPlansData({
      standard: { ...plans.data.data.standard },
      starter: { ...plans.data.data.starter },
    });
  };
  useEffect(() => {
    if (!localStorage.getItem("organisationId")) {
      history.push("/");
    }
  }, [history]);

  useEffect(() => {
    getPlans();
  }, []);

  const changeIsFeatureHiddenStr = () => {
    setIsFeatureHiddenStr(!isFeaterHiddenStr)

  }
  const changeIsFeatureHiddenStd = () => {
    setIsFeatureHiddenStd(!isFeaterHiddenStd)
  }

  const clickHandler = async (planName) => {
    const plan = planName.toLowerCase();
    const price = fetchPlansData[plan][planOpted.frequency];
    let multiplier = 1;
    if (planOpted["frequency"].includes("3-months")) {
      multiplier = 3;
    } else if (planOpted["frequency"].includes("Yearly")) {
      multiplier = 12;
    }
    const storePlan = {
      organisationID: localStorage.getItem("organisationId"),
      name: `${planName}-INR-${planOpted["frequency"]}`,
      price: price,
      multiplier: multiplier,
    };
    await chargebee.addPlanTemporarily(storePlan);
    const registeredOrganisationId = localStorage.getItem("organisationId");
    await changeRegisterState({
      organisationId: registeredOrganisationId,
      registerState: 1,
    });
    message.success("Plan selected successfully");
    history.push("/ordersummary");
  };
  const signMeOut = () => {
    localStorage.setItem("mool_auth", "");
    localStorage.removeItem("mool_auth");
    localStorage.removeItem("organisationId");
    window.location = "/";
  };
  return (
    <Content>
      <div className="main price-model-largeScreen">
        <div
          className="moolLogoDiv"
        // style={{
        //   minHeight: "100vh",
        //   width: "26vw",
        //   padding: "2rem 3rem 3rem 3rem",
        //   backgroundImage:
        //     "linear-gradient(155deg, #e7ebfd -6%, #a3b5ff 114%)"
        // }}
        >
          <a href="https://moolfinance.com">
            <img
              src={sidebar}
              className="sidebar"
              alt=""
              style={{ position: "sticky", top: "2rem" }}
            />
          </a>
          {/* <img style={{position:"absolute",left:"150px",top:"674px "}}src={arrowRight}/> */}
        </div>
        <div className="attachDiv">
          <div className="navbar">
            <div className="sign-outDiv">
              <p
                className="h6-bold-18"
                style={{ cursor: "pointer" }}
                onKeyDown={signMeOut}
                onClick={signMeOut}
              >
                Sign out
              </p>
            </div>
          </div>
          <div className="contentDiv">
            <p className="font-size-18">
              Step <b>1</b> of <b>2</b>
            </p>
            <h1 className="h1-bold-32 price-model-header">Choose a plan that suits you</h1>
            <p className="font-size-18" style={{ color: "#5e5f62" }}>
              Try mool free for 14 days | no commitments cancel anytime
            </p>
            <div className="top-view">
              <div
                className="top-col1"
                style={{
                  // width: "35vw",
                  // height: "44vh",
                  display: "flex",
                  flexDirection: "column",
                  padding: "1vw",
                }}
              >
                <Row>
                  <div
                    style={{
                      backgroundColor: "#f4f4f6",
                      borderRadius: "5px",
                      margin: "0",
                    }}
                    className="flex"
                  >
                    <NormalButton
                      type={
                        planOpted.frequency === frequencyValues.Monthly
                          ? 13
                          : 17
                      }
                      buttonText="Monthly"
                      fontSize="14px"
                      block={true}
                      margin={[0, 10]}
                      onClick={() => {
                        setPlanOpted({
                          ...planOpted,
                          frequency: frequencyValues.Monthly,
                        });
                      }}
                    />

                    <NormalButton
                      type={
                        planOpted.frequency === frequencyValues.Quaterly
                          ? 13
                          : 17
                      }
                      buttonText="Quarterly"
                      fontSize="14px"
                      block={true}
                      margin={[0, 10]}
                      onClick={() => {
                        setPlanOpted({
                          ...planOpted,
                          frequency: frequencyValues.Quaterly,
                        });
                      }}
                    />

                    <NormalButton
                      style={{ paddingLeft: "5em" }}
                      type={
                        planOpted.frequency === frequencyValues.Yearly ? 13 : 17
                      }
                      buttonText="Yearly"
                      fontSize="14px"
                      block={true}
                      margin={[0, 10]}
                      onClick={() => {
                        setPlanOpted({
                          ...planOpted,
                          frequency: frequencyValues.Yearly,
                        });
                      }}
                    />
                  </div>
                </Row>
                <Row justify="space-between">
                  {/* <Col style={{}}>
                    <p className="font-size-14 mb-1">
                      <img src={checkCircle} className="checkCircle" alt="" />
                      14 days free trial
                    </p>
                    <p className="font-size-14 mb-1">
                      <img src={checkCircle} className="checkCircle" alt="" />
                      No commitments, cancel anytime
                    </p>
                    <p className="font-size-14 mb-1">
                      <img src={checkCircle} className="checkCircle" alt="" />
                      No extra charges
                    </p>
                  </Col>
                  {planOpted.plan === "Starter" && ( */}
                  <Col>
                    <p className="font-size-16" style={{ color: "#118122" }}>
                      <img src={GreenCheck} style={{ marginRight: "0.5em" }} alt="" />
                      Save{" "}
                      {(
                        ((99 - fetchPlansData?.starter?.["Every-3-months"]) /
                          99) *
                        100
                      ).toFixed(2)}{" "}
                      % on Quarterly plan
                    </p>
                    <p
                      className="font-size-16"
                      style={{ color: "#118122", margin: "0.5rem 0" }}
                    ><img src={GreenCheck} alt="" style={{ marginRight: "0.5em" }} />
                      Save{" "}
                      {(
                        ((99 - fetchPlansData?.starter?.["Yearly"]) / 99) *
                        100
                      ).toFixed(2)}{" "}
                      % on Annual plan
                    </p>
                  </Col>
                  {/* )} */}
                  {planOpted.plan === "Standard" && (
                    <Col>
                      <p className="font-size-14" style={{ color: "#118122" }}>
                        Save{" "}
                        {(
                          ((150 - fetchPlansData.standard["Every-3-months"]) /
                            150) *
                          100
                        ).toFixed(2)}{" "}
                        % on Quarterly plan
                      </p>
                      <p
                        className="font-size-14"
                        style={{ color: "#118122", margin: "0.5rem 0" }}
                      >
                        Save{" "}
                        {(
                          ((150 - fetchPlansData.standard["Yearly"]) / 150) *
                          100
                        ).toFixed(2)}{" "}
                        % on Annual plan
                      </p>
                    </Col>
                  )}
                </Row>
              </div>
              <div
                className="top-col2"
                style={{
                  // width: "18vw",
                  // height: "44.3vh",
                  border: "solid #d2d8e6 1px",
                  borderTopLeftRadius: "2rem",
                  padding: "1.8vw",
                }}
              >
                <Col>
                  <h3 className="font-size-24 bold">Starter</h3>

                  <Row style={{ margin: "0" }}>
                    <Col>
                      <p className="font-size-64 bold">
                        <sup className="lighter font-size-14">INR</sup>
                        {fetchPlansData?.starter?.[planOpted.frequency] || 99}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ padding: "0 0 0 0" }}>
                      <p className="font-size-14">per month per employee</p>
                      {/* <p className="font-size-14">per employee </p> */}
                    </Col>
                  </Row>
                  <NormalButton
                    type={18}
                    buttonText="Select this plan"
                    block={true}
                    margin={[16, 0]}
                    // heightWidth={["52px", "500px"]}
                    minHeight="50px"
                    secondaryIcon={registerArrowBlue}
                    onClick={(e) => {
                      clickHandler("Starter");
                    }}
                    // fontSize="1vw"
                    fontSize="100%"
                  />
                  {planOpted.frequency !== "Monthly" && (
                    <div
                      style={{
                        background: "#e2ffe6",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "3rem",
                        color: "#118122",
                        textAlign: "center",
                        borderRadius: "0.2rem",
                        fontSize: "12px",
                      }}
                    >
                      You save{" "}
                      {99 - fetchPlansData?.starter?.[planOpted.frequency]} INR/
                      month in your {Object.values(planOpted.frequency)} plan
                    </div>
                  )}
                </Col>
              </div>
              <div
                className="top-col3"
                style={{
                  // width: "18vw",
                  // height: "44.3vh",
                  border: "solid #d2d8e6 1px",
                  padding: "1.8vw",
                  backgroundColor: "#f8fbff",
                }}
              >
                <div
                  className="most-popular"
                // style={{
                //   position: "relative",
                //   top: "-1.5em",
                //   width: "18vw",
                //   height: "5.9rem",
                //   margin: "-2.8rem -3rem -3rem -1.9rem",
                //   textAlign: "center",
                //   paddingTop: "0.5rem",
                //   backgroundColor: "#feba01",
                //   zIndex: "-1",
                //   borderRadius: "4rem",
                //   fontSize: "1.125rem",
                //   fontWeight: "bold"
                // }}
                >
                  Most Popular
                </div>

                <Col>
                  <h3 className="font-size-24 bold">Standard</h3>

                  <Row style={{ margin: "0" }}>
                    <Col>
                      <p className="font-size-64 bold">
                        <sup className="lighter font-size-14">INR</sup>
                        {fetchPlansData?.standard?.[planOpted.frequency] || 150}
                      </p>
                    </Col>
                  </Row>
                  <Row >
                    <Col style={{ padding: "0 0 0 0" }}>
                      <p className="font-size-14">per month per employee</p>
                      {/* <p className="font-size-14">per employee </p> */}
                    </Col>
                  </Row>
                  <NormalButton
                    type={13}
                    buttonText="Select this plan"
                    block={true}
                    margin={[16, 0]}
                    secondaryIcon={registerArrow}
                    // heightWidth={["50px", "500px"]}
                    minHeight="50px"
                    onClick={(e) => {
                      clickHandler("Standard");
                    }}
                    // fontSize="1vw"
                    fontSize="100%"
                  />
                  {planOpted.frequency !== "Monthly" && (
                    <div
                      style={{
                        background: "#e2ffe6",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "3rem",
                        color: "#118122",
                        textAlign: "center",
                        borderRadius: "0.2rem",
                        fontSize: "12px",
                      }}
                    >
                      You save{" "}
                      {150 - fetchPlansData?.standard?.[planOpted.frequency]}{" "}
                      INR/ month in your {Object.values(planOpted.frequency)} plan
                    </div>
                  )}
                </Col>
              </div>
            </div>
            <div className="collapsible">
              <Col className="collapse-col">
                <Collapse>
                  {/* Core HR section */}
                  <Panel header="Core HR" className="mainPanel">
                    <Row justify="space-between">
                      <Collapse ghost={true} className="no-border">
                        <Panel
                          header="Employee Info Management"
                          className="subPanel"
                        >
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Employee Directory
                            </div>

                            <div className="circle orange-tick"></div>
                            <div className="circle orange-tick m-r-6"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Manage a record of employee database in the system
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle  m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle"></div>
                      <div className="circle m-r-6"></div>
                    </Row>

                    <Row justify="space-between">
                      <Collapse ghost={true} className="no-border">
                        <Panel header="Know Your Employee">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              One stop repository for all employee related
                              informations
                            </div>

                            <div className="circle orange-tick"></div>
                            <div className="circle orange-tick m-r-6"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Easy to collect identity data via online
                            </div>

                            <div className="circle red-cross"></div>
                            <div className="circle orange-tick m-r-6"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6"></div>
                    </Row>

                    <Row justify="space-between">
                      <Collapse ghost={true} className="no-border">
                        <Panel header="Employee Documents Management">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Maintaining 360 degree view of the employee with
                              all documents from onboarding to off boarding
                            </div>

                            <div className="circle red-cross"></div>
                            <div className="circle orange-tick m-r-6"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Go paperless with storing and accessing all
                              documents digitally
                            </div>

                            <div className="circle red-cross"></div>
                            <div className="circle orange-tick m-r-6"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold inner-row"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Upload documents in bulk
                            </div>

                            <div className="circle red-cross"></div>
                            <div className="circle orange-tick m-r-6"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse ghost={true} className="no-border">
                        <Panel header="Employee Communication">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Automatically generated task and email reminders
                              to keep employees on track
                            </div>

                            <div className="circle red-cross"></div>
                            <div className="circle red-cross m-r-6"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Social HR - employee messaging system
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>

                            <div className="circle red-cross"></div>
                            <div className="circle red-cross m-r-6"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Group-wise targeting of communication
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>

                            <div className="circle red-cross"></div>
                            <div className="circle red-cross m-r-6"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle red-cross m-r-6"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse ghost={true} className="no-border">
                        <Panel header="Reminders and Alerts">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Late mark notification to HR and Managers
                            </div>

                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Leave application mail reminder
                            </div>

                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Track and manage employees/teams work hours and
                              leaves.From calendar integration to automatic
                              notifications for leaves and late marks
                            </div>

                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>

                    <Row justify="space-between">
                      <Collapse ghost={true} className="no-border">
                        <Panel header="HR Reports">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              System generated HR MIS reports
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              User defined and ad-hoc reports
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Other reports essential for HR function like
                              Exhaustive Labour Law reports
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse ghost={true} className="no-border">
                        <Panel header="Company Policies and Forms">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Get policies regarding all functions within the
                              company with detailed SOP's and also employees
                              handbook at click away
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Go paperless with all frequently required forms
                              and templates in fillable format online
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 red-cross"></div>
                    </Row>
                  </Panel>

                  {/* borders  */}
                  <div className="vl-1"></div>
                  <div className="vl-2"></div>

                  {/* Payroll Section */}
                  <Panel header="Payroll" className="mainPanel">
                    <Row justify="space-between">
                      <Collapse ghost={true} className="no-border">
                        <Panel header="Payroll Inputs">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Automatic leave inputs from other Mool modules.
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Automatic attendance inputs from other Mool
                              modules.
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Appraisals
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              One time payment and deductions
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Full and final calculations
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Calculations of deduction for LOP/leave without
                              pay and reimbursement of LOP reversals
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Calculation of arrears processing
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Stop payment with release feature
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Integrated with other HR core solutions to
                              simplify daily operations
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle"></div>
                      <div className="circle m-r-6"></div>
                    </Row>

                    <Row justify="space-between">
                      <Collapse ghost={true} className="no-border">
                        <Panel header="Statuory Compliance">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Calculations of PF with built in and customised
                              inputs
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Monthly PF returns submission and ECR generation
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              ESI computations
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Profession Tax with all state specific rules built
                              in
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Labour Welfare fund calculation and deductions
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Calculations of Income tax and TDS deductions
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Digitally signed Form 16 generation{" "}
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Maintain up to date HR Statutory Compliance with
                              all necessary documentation
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Payroll processing">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              2 click payroll process
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Guided payroll processing with checklist
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Lock feature to close payroll processing
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <img src={blueTick} alt="" />
                      <div className="circle m-r-6"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Verification and Reconciliations">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Get all information regarding salary in excel
                              without any manual work
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Get customised salary register and payroll
                              statements
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Reconciliation of workings/calculation with
                              details from different inputs
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Get detailed analytical reports for variance
                              checking and performance tracking
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6 "></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Payslip Generation and Distribution">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              One click payslip distribution
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Download payslips into a single or multiple PDF
                              files and distribute by email
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Distribute payslips via employee portal or mail
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Separate payslip for reimbursements
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              System generated payslip and distribution with
                              ease of access to employees
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Payroll Reports">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Profession Tax reports
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Reconciliation reports
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              MIS Reports
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Customizable payroll statement / salary register /
                              wage register
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Salary Analytics
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Ad-hoc report builder
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Reports under Shops and Establishment Acts of
                              various states
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Reports under CLRA Act of various states{" "}
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Digitally signed Form 16 and Form 24Q files with
                              FVU validation
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Configurable Salary Structure/ Flexi Pay option">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Highly customizable salary structure for any
                              industry
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              User defined/Flexible Salary structure
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Unlimited/Exhaustive salary component
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Get customized/optimal salary structure format
                              based on industry type
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle"></div>
                      <div className="circle m-r-6"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Payroll Reimbusements">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Extensive reimbursement configurations
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Monthly / annual entitlements
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Claim process with limit checking
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Excess claim tracking and set-off feature
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Expenses/Reimbursements management. Online claim
                              processing with work flows for maintaining trail
                              and inbuilt limit checking and variance tracking
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Loans and Salary Advances">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Salary advance with auto deduction in next payroll
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Manage company loans to employees
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Fixed rate interest, EMI, no interest, reducing
                              balance options
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Auto calculation and deductions in payroll
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Pause loan deductions for a specified period
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Automatic closure on completion of repayment
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Ability to report on principal and interest
                              portions
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Loan prepayment and balloon payment features
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Automatic calculation of loan perquisite
                              {/* <mark>Coming Soon</mark> */}
                              <span>
                                <img
                                  style={{
                                    height: "24px",
                                    paddingLeft: "10px",
                                  }}
                                  src={comingSoonImg}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Access Management">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Reports Access Management
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Payroll Access Management
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Payout and Disbursements">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Flexible payment options like cash, bank transfer
                              etc
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              All major bank transfer electronic formats
                              built-in
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              All Pay frequency- pay different employees in
                              different batches.Preschedule pay roll cycles and
                              facility to release in batches
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                  </Panel>

                  {/* Leave Management */}
                  <Panel header="Leave Management" className="mainPanel">
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Fully Customizable Leave Policies">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Unlimited leave types (annual, privilege,
                              maternity, etc.)
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Manage different types of leaves
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Customize leave types and holiday list
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Restricted (optional) holidays support
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Different and multiple leave policy depending on
                              company and group of employees
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Integration with team calendars and real time
                              reports
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Manage Balance and Transactions">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Integration with timesheet for automatic tracking
                              of leave and leave balance
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Calculation for carry forward and leave lapsing
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Online application with approvals
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6"></div>
                    </Row>
                  </Panel>

                  {/* Employee Self Onboarding */}
                  <Panel
                    header="Employee Self Onboarding"
                    className="mainPanel"
                  >
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Manage Balance and Transactions">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Personalized & Paperless onboarding of employees
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Workflows for Admin review
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Alerts and Reminders for employees and admin
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6"></div>
                    </Row>
                  </Panel>

                  {/* Employee Portal */}
                  <Panel
                    header="Employee Portal (Web and Mobile app coming soon)"
                    className="mainPanel"
                  >
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Employee Portal - Core HR">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Social HR
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Employee directory
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Access to own documents and letters
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Access to company policies,handbook, forms,etc.
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Access to own employee information
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Mobile app for employees and managers{" "}
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Employee Portal - Leave">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Leave application and review
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Leave cancellation work-flow
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Online leave balances and details
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Apply for leave online and also get detailed
                              reports regarding leave balance, carry forward etc
                              for planning leaves
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Employee Portal - Payroll">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Online payslips
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              IT calculation statement
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              IT proof of investments
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              IT savings and declarations
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Reimbursements claims
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Get payslips for current as well as previous
                              months saved at one place.Provide declaration
                              online with proof of investments and get
                              projections and current tax calculations.Get
                              claims settled with submission and processing done
                              online
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Employee Portal - Attendance">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Attendance regularization work-flow
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Team attendance information for managers
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Detailed attendance information
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Real time report integrated with other details
                              with online attendance regularization's option
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Dashboards ">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Analytical Dashboards
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Operational Dashboards
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6"></div>
                    </Row>
                  </Panel>

                  {/* Attendance Management */}
                  <Panel header="Attendance Management" className="mainPanel">
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Swipe Capture from Varied Sources">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Online attendance marking
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Mobile attendance marking
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              GPS based mobile attendance marking
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Biometric based Attendance Marking
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Integration with attendance recording devices
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="High Configurable Policies">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Multiple attendance policies for different groups
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Customizable weekends
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        <Panel header="Attendance Processing">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Automatic daily attendance processing
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Attendance regularization work-flow
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Manual override facility
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Continuos absence alerts
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Month end HR review and finalization facility
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Attendance muster generation
                            </div>
                            <div className="circle orange-tick"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </Row>
                  </Panel>

                  {/* Onboarding Support & Support Plans */}
                  <Panel
                    header="Onboarding Support & Support Plans"
                    className="mainPanel"
                  >
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Product Training
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </div>
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Onboard Support
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 red-cross"></div>
                    </div>
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Ticket Support
                      </div>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </div>
                  </Panel>

                  {/* General features */}
                  <Panel header="General features" className="mainPanel">
                    <div className="endCollapse noPanelDiv">
                      <div
                        className="font-size-14"
                        style={{ width: "24vw", paddingLeft: "1rem" }}
                      >
                        Mobile app for employees and managers
                      </div>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </div>
                    <div className="endCollapse noPanelDiv">
                      <div
                        className="font-size-14"
                        style={{ width: "24vw", paddingLeft: "1rem" }}
                      >
                        Excessive Excel import and export facility
                      </div>
                      <div className="circle blue-tick"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </div>
                    <Row justify="space-between">
                      <Collapse className="no-border" ghost={true}>
                        {/**TODO: Fix styling of User mangement */}
                        <Panel header="User Management">
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Support for multiple users
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Fine grained role based access control (RBAC)
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 orange-tick"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Password policy configuration
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              Detailed audit logging & reporting of all
                              activities
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                          <div className="endCollapse">
                            <div
                              className="font-size-14 bold"
                              style={{
                                color: "#5e5f62",
                                fontWeight: 600,
                              }}
                            >
                              IP restriction for access control to application
                            </div>
                            <div className="circle red-cross"></div>
                            <div className="circle m-r-6 red-cross"></div>
                          </div>
                        </Panel>
                      </Collapse>
                      <div
                        className="circle red-cross"
                        style={{ marginRight: "2vw" }}
                      ></div>
                      <div
                        className="circle blue-tick"
                        style={{ marginRight: "6.4vw" }}
                      ></div>
                    </Row>
                  </Panel>

                  {/* Database Limits and Backup */}
                  <Panel
                    header="Database Limits and Backup"
                    className="mainPanel"
                  >
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Database Storage Limits
                      </div>
                      <img src={blueTick} alt="" />
                      <div className="circle m-r-6"></div>
                    </div>
                  </Panel>

                  {/* paynow */}
                  <Panel header="PayNow" className="mainPanel">
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Direct salary transfers to employees
                      </div>
                      <div className="circle  red-cross"></div>
                      <div className="circle  m-r-6 blue-tick"></div>
                    </div>
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Secured OTP based Authentication
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </div>
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Real time transaction & account balance status
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </div>
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Reports & Audit Trail for due diligence
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </div>
                  </Panel>

                  {/* Enterprise Features */}
                  <Panel header="Enterprise Features" className="mainPanel">
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Single sign-on
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 orange-tick"></div>
                    </div>
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        REST API access
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 red-cross"></div>
                    </div>
                  </Panel>

                  {/* Expense Claims Management */}
                  <Panel
                    header="Expense Claims Management"
                    className="mainPanel"
                  >
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Multi-company Support
                      </div>
                      <img src={blueTick} alt="" />
                      <div className="circle m-r-6 "></div>
                    </div>
                  </Panel>

                  {/* Geo Mark+ (Coming Soon) */}
                  <Panel header="Geo Mark+" className="mainPanel">
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        GPS based attendance marking for distributed workforce
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </div>
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Workflows for Manager Reviews
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 blue-tick"></div>
                    </div>
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Attendance scheme level customizations
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 red-cross"></div>
                    </div>
                    <div className="endCollapse noPanelDiv">
                      <div className="font-size-14" style={{ width: "24vw" }}>
                        Geo Swipe reports for due diligence
                      </div>
                      <div className="circle red-cross"></div>
                      <div className="circle m-r-6 red-cross"></div>
                    </div>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          </div>
          <div className="footer">
            <div style={{ padding: "10px 10px 5px 40px", width: "50vw" }}>
              <p className="font-size-16">
                <b>Questions? Call {PHONENUMBER}</b>
              </p>
              <p className="font-size-12">
                <a
                  href="https://www.moolfinance.com/termsAndConditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of service
                </a>{" "}
                |{" "}
                <a
                  href="https://www.moolfinance.com/privacyPolicy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                |
                <a
                  href="https://www.moolfinance.com/contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Contact Us
                </a>
              </p>
            </div>
            <div>
              <div
                style={{
                  width: "50vw",
                  textAlign: "right",
                  padding: "20px 10px 5px 40px",
                }}
              >
                <div className="font-size-16" style={{ fontWeight: "bold" }}>
                  <p>Mool Innovation Labs Pvt. Ltd.</p>
                  <p
                    className="font-size-12"
                    style={{ color: "grey", fontWeight: "500" }}
                  >
                    All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="price-model-PhoneScreen">
        <div>
          <div className="navbar flex justify-between align-center">
            <div>
              <img src={MoolLogo} alt="" />
            </div>
            <div className="sign-outDiv">
              <p className="h6-bold-18" style={{ cursor: "pointer", marginRight: "0.5em" }} onKeyDown={signMeOut} onClick={signMeOut}>Log out</p>
            </div>
          </div>
          <div className="contentDiv">
            <p className="font-size-16" style={{ marginBottom: "1em" }}>
              Step <b>2</b> of <b>3</b>
            </p>
            <h1 style={{ fontSize: "28px" }} className="font-weight-global-700">Choose a plan that suits you 🚀</h1>
            <p className="font-size-18" style={{ color: "#5e5f62", marginRight: "2em" }}>
              Try mool free for 14 days | no commitments cancel anytime
            </p>
            <Row style={{ marginTop: "2em", marginLeft: "-0.75em" }}>
              <div
                style={{
                  backgroundColor: "#f4f4f6",
                  borderRadius: "5px",
                  margin: "0",
                }}
                className="flex"
              >
                <NormalButton
                  type={
                    planOpted.frequency === frequencyValues.Monthly
                      ? 13
                      : 17
                  }
                  buttonText="Monthly"
                  fontSize="14px"
                  block={true}
                  margin={[0, 40]}
                  onClick={() => {
                    setPlanOpted({
                      ...planOpted,
                      frequency: frequencyValues.Monthly,
                    });
                  }}
                />

                <NormalButton
                  type={
                    planOpted.frequency === frequencyValues.Quaterly
                      ? 13
                      : 17
                  }
                  buttonText="Quarterly"
                  fontSize="14px"
                  block={true}
                  margin={[0, 10]}
                  onClick={() => {
                    setPlanOpted({
                      ...planOpted,
                      frequency: frequencyValues.Quaterly,
                    });
                  }}
                />

                <NormalButton
                  type={
                    planOpted.frequency === frequencyValues.Yearly ? 13 : 17
                  }
                  buttonText="Yearly"
                  fontSize="14px"
                  block={true}
                  margin={[0, 10]}
                  onClick={() => {
                    setPlanOpted({
                      ...planOpted,
                      frequency: frequencyValues.Yearly,
                    });
                  }}
                />
              </div>
            </Row>
            <div style={{ color: "#0F8248", fontWeight: '600' }}>
              <p style={{ marginBottom: "1em" }}>
                <img style={{ marginRight: "1em" }} src={GreenCheck} alt=""></img>
                Save 14.14 % on Quarterly plan
              </p>
              <p>
                <img style={{ marginRight: "1em" }} src={GreenCheck} alt=""></img>
                Save 32.32% % on Annual plan
              </p>
            </div>
            <div className="starter-div-mobile">
              <p style={{ marginLeft: "1em", marginTop: "10px" }} className="starter-heading font-weight-global-700 font-size-global-24">
                Starter
              </p>
              {/* <div className="starter-subDiv">
                <p><div className="INR-span">INR </div><span className="price-span">89 </span><span className="month-employee-span"><span>per month </span><span>per employee</span></span></p>
              </div> */}
              <Row style={{ marginTop: "20px" }}>
                <Col style={{ marginLeft: "25px" }}>
                  <p className="bold" style={{ fontSize: "80px" }}>
                    <sup className="lighter font-size-14">INR</sup>
                    {fetchPlansData?.starter?.[planOpted.frequency] || 99}
                  </p>
                </Col>
                <Col style={{ padding: "0.9rem 0 0 0.5rem", marginTop: "20px", marginLeft: "20px" }}>
                  <p className="font-size-14">per month </p>
                  <p style={{ marginTop: "15px" }} className="font-size-14">per employee</p>
                </Col>
              </Row>
              <div style={{ width: "90%", marginLeft: "5%" }}>
                <NormalButton
                  type={18}
                  buttonText="Select this plan"
                  block={true}
                  margin={[16, 0]}
                  // heightWidth={["52px", "500px"]}
                  minHeight="50px"
                  secondaryIcon={registerArrowBlue}
                  onClick={(e) => {
                    clickHandler("Starter");
                  }}
                  // fontSize="1vw"
                  fontSize="100%"

                />
              </div>
              <div>
                {planOpted.frequency !== "Monthly" && (
                  <div
                    style={{
                      width: "90%",
                      marginLeft: "5%",
                      background: "#e2ffe6",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "3rem",
                      color: "#118122",
                      textAlign: "center",
                      borderRadius: "0.2rem",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    You save{" "}
                    {99 - fetchPlansData?.starter?.[planOpted.frequency]} INR/
                    month in your {Object.values(planOpted.frequency)} plan
                  </div>
                )}
              </div>
              <div style={{ width: "90%", marginLeft: "5%" }}>
                {/* <NormalButton
                  type={5}
                  buttonText={!isFeaterHiddenStr ? " Show Features" : "Hide Features"}
                  block={true}
                  margin={[16, 0]}
                  secondaryIcon={!isFeaterHiddenStr ? rightArrow : blueTickSingle}
                  minHeight="50px"
                  fontSize="100%"
                  onClick={changeIsFeatureHiddenStr}
                /> */}
                <div style={{ marginBottom: "20px", width: "100%" }}>
                  <Collapse onChange={changeIsFeatureHiddenStr} accordion={true}>
                    <Panel className="feature-collapsable-rows" header={!isFeaterHiddenStr ? " Show Features" : "Hide Features"}>
                      <Collapse accordion={true}>
                        <Panel header="Core Hr" className="main-panel-phone-screen">
                          <Collapse accordion={true}>
                            <Panel header="Employee Info Management" className="sub-panel">
                              <div className="flex">
                                <img src={blueTickSingle} alt="" />
                                <p className="sub-sub-panel collapse-p-l-margin ">
                                  Employee Directory
                                </p>
                              </div>
                              <div className="flex ">
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="sub-sub-panel collapse-p-l-margin ">
                                    Manage a record of employee database in the system
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Know your employee" className="sub-panel">
                              <div className="flex">
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    One stop repository for all employee related problems
                                  </p>
                                </div>

                              </div>
                              <div className="flex ">
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin">
                                    Easy to collect identity data via online
                                  </p>
                                </div>

                              </div>

                            </Panel>
                            <Panel header="Employee Document Management" className="sub-panel">
                              <div className="flex" style={{ marginBottom: "1em" }}>
                                <img src={redCross} alt="" />
                                <p className="collapse-p-l-margin ">
                                  Maintaining 360 degree view of the employee with all documents from onboarding to off boarding
                                </p>
                              </div>
                              <div className="flex" style={{ marginBottom: "1em" }}>
                                <img src={redCross} alt="" />
                                <p className="collapse-p-l-margin ">
                                  Go paperless with storing and accessing all documents digitally
                                </p>
                              </div>
                              <div className="flex" style={{ marginBottom: "1em" }}>
                                <img src={redCross} alt="" />
                                <p className="collapse-p-l-margin ">
                                  Upload documents in bulk
                                </p>

                              </div>
                            </Panel>
                            <Panel header="Employee Communication" className="sub-panel">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />

                                <p className="collapse-p-l-margin ">
                                  Automatically generated task and email reminders to keep employees on track
                                </p>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />

                                <p className="collapse-p-l-margin ">
                                  Social HR - employee messaging system
                                </p>


                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <p className="collapse-p-l-margin ">
                                  Group-wise targeting of communication
                                </p>
                              </div>
                            </Panel>
                            <Panel header="Reminders and Alerts">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Late mark notification to HR and Managers
                                  </p>
                                </div>

                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Leave application mail reminder
                                  </p>
                                </div>

                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <p className="collapse-p-l-margin ">
                                  Track and manage employees/teams work hours and leaves.From calendar integration to automatic notifications for leaves and late marks
                                </p>

                              </div>
                            </Panel>
                            <Panel header="HR Reports">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    System generated HR MIS reports
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Customized and ad-hoc reports
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Other reports essential for HR function like Exhaustive Labour Law reports                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Company Policies and Forms">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Get policies regarding all functions within the company with detailed SOP's and also employees handbook at click away                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Go paperless with all frequently required forms and templates in fillable format online
                                  </p>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        </Panel>
                        <Panel header="Payroll" className="main-panel-phone-screen">
                          <Collapse accordion={true}>
                            <Panel header="Payroll Inputs">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Automatic leave inputs from other Mool modules.                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Automatic attendance inputs from other Mool modules.                                 </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Appraisals                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    One time payment and deductions                                 </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Full and final calculations                                </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Calculations of deduction for LOP/leave without pay and reimbursement of LOP reversals
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Calculation of arrears processing
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Stop payment with release feature
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Integrated with other HR core solutions to simplify daily operations
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Statutory Compliance">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Calculations of PF with built in and customized inputs
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Monthly PF returns submission and ECR generation
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    ESI computations
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Profession Tax with all state specific rules built in
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Labour Welfare fund calculation and deductions
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Calculations of Income tax and TDS deductions                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Digitally signed Form 16 generation                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Maintain up to date HR Statutory Compliance with all necessary documentation
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Payroll Processing">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Single click payroll process                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Guided payroll processing with checklist                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Real Time payroll Calculations and processing with single click and system indicative processing with checkllist
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Verification and Reconciliations">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Get all information regarding salary in excel without any manual work
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Get customized salary register and payroll statements
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Reconciliation of workings/calculation with details from different inputs
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Get detailed analytical reports for variance checking and performance tracking
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Payslip Generation and Distribution">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    One click payslip distribution
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Download payslips into a single or multiple PDF files and distribute by email
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Distribute payslips via employee portal or mail
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Separate payslip for reimbursements
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    System generated payslip and distribution with ease of access to employees
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Payroll Reports">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Profession Tax reports
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Reconciliation reports
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    MIS Reports
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Customizable payroll statement / salary register / wage register
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Salary Analytics
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Ad-hoc report builder
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Reports under Shops and Establishment Acts of various states
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Reports under CLRA Act of various states
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Digitally signed Form 16 and Form 24Q files with FVU validation
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Configurable Salary Structure/ Flexi Pay option">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Highly customizable salary structure for any industry
                                  </p>
                                </div>
                              </div> <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    User defined/Flexible Salary structure
                                  </p>
                                </div>
                              </div> <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Unlimited/Exhaustive salary component
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Get customised/optimal salary structure format based on industry type
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Payroll Reimbursements">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Extensive reimbursement configurations
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Monthly / annual entitlements
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Claim process with limit checking
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Excess claim tracking and set-off feature
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Expenses/Reimbursements management. Online claim processing with work flows for maintaining trail and inbuilt limit checking and variance tracking
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Loans and Salary Advances">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Automatically captures salary advances from online data and auto deduction in next payroll
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Manage company loans to employees
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Number of options and methods configured for calculation of interest and EMI
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Automatically schedule deductions based on preferred options
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Manage deductions with pausing deductions for specified period
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Captures and automates closure of loan on completion of repayment
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Get Bifurcation for Principal and interest components
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Loan prepayment and balloon payment features
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Inbuilt parameters for loan calculation and deductions based on different available options
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Access Management">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Reports Access Management
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Reports Access Management
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Payout and Disbursements">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Flexible payment options like cash, bank transfer etc
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    All major bank transfer electronic formats built-in
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    All Pay frequency- pay different employees in different batches. Pre schedule pay roll cycles and facility to release in batches
                                  </p>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        </Panel>
                        <Panel header="Leave Management" className="main-panel-phone-screen">
                          <Collapse accordion={true}>
                            <Panel header="Fully Customizable Leave Policies">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Unlimited leave types (annual, privilege, maternity, etc.)
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Manage different types of leaves
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Customize leave types and holiday list
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Restricted (optional) holidays support
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Different and multiple leave policy depending on company and group of employees
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Integration with team calendars and real time reports
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Manage Balance and Transactions">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Integration with timesheet for automatic tracking of leave and leave balance
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Calculation for carry forward and leave lapsing
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Online application with approvals
                                  </p>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        </Panel>
                        <Panel header="Employee Self Onboarding" className="main-panel-phone-screen">
                          <Collapse accordion={true}>
                            <Panel header="Manage Balance and Transactions">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Personalised & Paperless onboarding of employees
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Workflows for Admin review
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Alerts and Reminders for employees and admin
                                  </p>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        </Panel>
                        <Panel header="Employee Portal (Web and Mobile app coming soon)" className="main-panel-phone-screen">
                          <Collapse accordion={true}>
                            <Panel header="Employee Portal - Core HR">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Get notifications regarding birthdays, news, upcoming events
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    List of all employees with their contact details at one click
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Access to own documents and letters
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Access to company policies,handbook, forms,etc.
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Access to own employee information
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Mobile app for employees and managers
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Employee Portal - Leave">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Leave application and review
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Leave cancellation work-flow
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Online leave balances and details
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Apply for leave online and also get detailed reports regarding leave balance, carry forward etc for planning leaves
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Employee Portal - Payroll">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Online payslips
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    IT calculation statement
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    IT proof of investments
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={blueTickSingle} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    IT savings and declarations
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p style={{ marginLeft: "0.20em" }}>
                                    Reimbursements claims
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Get payslips for current as well as previous months saved at one place.Provide declaration online with proof of investments and get projections and current tax calculations.Get claims settled with submission and processing done online
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Employee Portal - Attendance">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Attendance regularization work-flow
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Team attendance information for managers
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Detailed attendance information
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Real time report integrated with other details with online attendance regularisations option
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Dashboards">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Analytical Dashboards
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Operational Dashboards
                                  </p>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        </Panel>
                        <Panel header="Attendance Management" className="main-panel-phone-screen">
                          <Collapse accordion={true}>
                            <Panel header="Swipe Capture from Varied Sources">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Get real time attendance integration
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Mobile attendance marking                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    GPS based mobile attendance marking                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Facial Recognition based Attendance Marking                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Mark attendance with smart mobile app from anywhere and it will get processed automatically with preset geolocation address.HR/Managers can track and get reports based on tagged locations
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="High Configurable Policies">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Multiple attendance policies for different groups
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Customisable weekends
                                  </p>
                                </div>
                              </div>
                            </Panel>
                            <Panel header="Attendance Processing">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Automatic daily attendance processing
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Attendance regularization work-flow
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Manual override facility
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Continous absence alerts
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Month end HR review and finalization facility
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Attendance muster generation
                                  </p>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        </Panel>
                        <Panel header="Onboarding Support & Support Plans" className="main-panel-phone-screen">
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Product Training
                              </p>
                            </div>
                          </div>
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Onboard Support
                              </p>
                            </div>
                          </div>
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={blueTick} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Ticket Support
                              </p>
                            </div>
                          </div>
                        </Panel>
                        <Panel header="General features" className="main-panel-phone-screen">
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={blueTick} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Mobile app for employees and managers
                              </p>
                            </div>
                          </div>
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={blueTick} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Excessive Excel import and export facility
                              </p>
                            </div>
                          </div>
                          <Collapse accordion={true}>
                            <Panel header="User Management">
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Support for multiple users
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Fine grained role based access control (RBAC)
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Password policy configuration
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    Detailed audit logging & reporting of all activities
                                  </p>
                                </div>
                              </div>
                              <div className="flex" style={{ bottomMargin: "1em" }}>
                                <img src={redCross} alt="" />
                                <div>
                                  <p className="collapse-p-l-margin ">
                                    IP restriction for access control to application
                                  </p>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        </Panel>
                        <Panel header="Database Limits and Backup" className="main-panel-phone-screen">
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={blueTick} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Database Storage Limits
                              </p>
                            </div>
                          </div>
                        </Panel>
                        <Panel header="PayNow" className="main-panel-phone-screen">
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Direct salary transfers to employees
                              </p>
                            </div>
                          </div>
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Secured OTP based Authentication
                              </p>
                            </div>
                          </div>
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Real time transaction & account balance status
                              </p>
                            </div>
                          </div>
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Reports & Audit Trail for due diligence
                              </p>
                            </div>
                          </div>
                        </Panel>
                        <Panel header="Enterprise Features" className="main-panel-phone-screen">
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Single sign-on
                              </p>
                            </div>
                          </div><div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                REST API access
                              </p>
                            </div>
                          </div>
                        </Panel>
                        <Panel header="Expense Claims Management" className="main-panel-phone-screen">
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={blueTick} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Multi-company Support
                              </p>
                            </div>
                          </div>
                        </Panel>
                        <Panel header="Geo Mark+ (Coming Soon)" className="main-panel-phone-screen">
                          <div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                GPS based attendance marking for distributed workforce
                              </p>
                            </div>
                          </div><div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Workflows for Manager Reviews
                              </p>
                            </div>
                          </div><div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Attendance scheme level customizations
                              </p>
                            </div>
                          </div><div className="flex" style={{ bottomMargin: "1em" }}>
                            <img src={redCross} alt="" />
                            <div>
                              <p className="collapse-p-l-margin ">
                                Geo Swipe reports for due diligence
                              </p>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className="standard-div-mobile">
              <p className="starter-heading font-weight-global-700 font-size-global-24">
                Standard <span className="popular-span">Most Popular</span>
              </p>
              <Row style={{ marginTop: "20px" }}>
                <Col style={{ marginLeft: "25px" }}>
                  <p className="bold" style={{ fontSize: "80px" }}>
                    <sup className="lighter font-size-14">INR</sup>
                    {fetchPlansData?.standard?.[planOpted.frequency] || 99}
                  </p>
                </Col>
                <Col style={{ padding: "0.9rem 0 0 0.5rem", marginTop: "20px", marginLeft: "20px" }}>
                  <p className="font-size-14">per month </p>
                  <p style={{ marginTop: "15px" }} className="font-size-14">per employee </p>
                </Col>
              </Row>
              <div style={{ width: "90%", marginLeft: "5%" }}>
                <NormalButton
                  type={13}
                  buttonText="Select this plan"
                  block={true}
                  margin={[16, 0]}
                  secondaryIcon={registerArrow}
                  // heightWidth={["50px", "500px"]}
                  minHeight="50px"
                  onClick={(e) => {
                    clickHandler("Standard");
                  }}
                  // fontSize="1vw"
                  fontSize="100%"
                />
              </div>
              <div>
                {planOpted.frequency !== "Monthly" && (
                  <div
                    style={{
                      width: "90%",
                      marginLeft: "5%",
                      background: "#e2ffe6",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "3rem",
                      color: "#118122",
                      textAlign: "center",
                      borderRadius: "0.2rem",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    You save{" "}
                    {150 - fetchPlansData?.standard?.[planOpted.frequency]}{" "}
                    INR/ month in your {Object.values(planOpted.frequency)} plan
                  </div>
                )}
              </div>
              <div style={{ width: "90%", marginLeft: "5%" }}>
                {/* <NormalButton
                  type={5}
                  buttonText={!isFeaterHiddenStd ? " Show Features >" : "Hide Features <"}
                  block={true}
                  margin={[16, 0]}
                  minHeight="50px"
                  fontSize="100%"
                  onClick={changeIsFeatureHiddenStd}
                /> */}
              </div>
              <div style={{ marginBottom: "20px", width: "100%" }}>
                <Collapse onChange={changeIsFeatureHiddenStd} accordion={true}>
                  <Panel className="feature-collapsable-rows" header={!isFeaterHiddenStd ? " Show Features" : "Hide Features"}>
                    <Collapse accordion={true}>
                      <Panel header="Core Hr" className="main-panel-phone-screen">
                        <Collapse accordion={true}>
                          <Panel header="Employee Info Management" className="sub-panel">
                            <div className="flex justify-between">
                              <img src={blueTickSingle} alt="" />
                              <p className="sub-sub-panel">
                                Employee Directory
                              </p>
                            </div>
                            <div className="flex  align-center">
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ textAlign: "right" }} className="sub-sub-panel">
                                  Manage a record of employee database in the system
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Know your employee" className="sub-panel">
                            <div className="flex">
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ textAlign: "right" }}>
                                  One stop repository for all employee related problems
                                </p>
                              </div>

                            </div>
                            <div className="flex justify-between">
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p>
                                  Easy to collect identity data via online
                                </p>
                              </div>

                            </div>

                          </Panel>
                          <Panel header="Employee Document Management" className="sub-panel">
                            <div className="flex" style={{ marginBottom: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p>
                                  Maintaining 360 degree view of the
                                </p>
                                <p>
                                  employee with all documents from onboarding to off boarding
                                </p>
                              </div>

                            </div>
                            <div className="flex" style={{ marginBottom: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p>
                                  Go paperless with storing and accessing all documents digitally                                  </p>
                              </div>

                            </div>
                            <div className="flex" style={{ marginBottom: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Upload documents in bulk
                                </p>
                              </div>

                            </div>
                          </Panel>
                          <Panel header="Employee Communication" className="sub-panel">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p>
                                  Automatically generated task and email reminders to keep employees on track
                                </p>
                              </div>

                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Social HR - employee messaging system
                                </p>
                              </div>

                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Group-wise targeting of communication
                                </p>
                              </div>

                            </div>
                          </Panel>
                          <Panel header="Reminders and Alerts">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Late mark notification to HR and Managers
                                </p>
                              </div>

                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Leave application mail reminder
                                </p>
                              </div>

                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <div style={{ marginRight: "0.75em" }} className="circle orange-tick"></div>
                              <div style={{ marginLeft: "1em" }}>
                                <p >
                                  Track and manage employees/teams work hours and leaves.From calendar integration to automatic notifications for leaves and late marks
                                </p>
                              </div>

                            </div>
                          </Panel>
                          <Panel header="HR Reports">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  System generated HR MIS reports
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Customised and ad-hoc reports
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Other reports essential for HR function like Exhaustive Labour Law reports                                  </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Company Policies and Forms">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Get policies regarding all functions within the company with detailed SOP's and also employees handbook at click away                                  </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Go paperless with all frequently required forms and templates in fillable format online
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </Panel>
                      <Panel header="Payroll" className="main-panel-phone-screen">
                        <Collapse accordion={true}>
                          <Panel header="Payroll Inputs">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Automatic leave inputs from other Mool modules.                                  </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Automatic attendance inputs from other Mool modules.                                 </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Appraisals                                  </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  One time payment and deductions                                 </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Full and final calculations                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Calculations of deduction for LOP/leave without pay and reimbursement of LOP reversals
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Calculation of arrears processing
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Stop payment with release feature
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Integrated with other HR core solutions to simplify daily operations
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Statuory Compliance">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Calculations of PF with built in and customised inputs
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Monthly PF returns submission and ECR generation
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  ESI computations
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Profession Tax with all state specific rules built in
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Labour Welfare fund calculation and deductions
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Calculations of Income tax and TDS deductions                                  </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Digitally signed Form 16 generation                                  </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Maintain up to date HR Statutory Compliance with all necessary documentation
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Payroll Processing">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Single click payroll process                                  </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Guided payroll processing with checklist                                  </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Real Time payroll Calculations and processing with single click and system indicative processing with checkllist
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Verification and Reconcilations">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Get all information regarding salary in excel without any manual work
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Get customised salary register and payroll statements
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Reconciliation of workings/calculation with details from different inputs
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Get detailed analytical reports for variance checking and performance tracking
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Payslip Generation and Distribution">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  One click payslip distribution
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Download payslips into a single or multiple PDF files and distribute by email
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Distribute payslips via employee portal or mail
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Separate payslip for reimbursements
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  System generated payslip and distribution with ease of access to employees
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Payroll Reports">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Profession Tax reports
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Reconciliation reports
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  MIS Reports
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Customizable payroll statement / salary register / wage register
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Salary Analytics
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Ad-hoc report builder
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Reports under Shops and Establishment Acts of various states
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Reports under CLRA Act of various states
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Digitally signed Form 16 and Form 24Q files with FVU validation
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Configurable Salary Structure/ Flexi Pay option">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Highly customizable salary structure for any industry
                                </p>
                              </div>
                            </div> <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  User defined/Flexible Salary structure
                                </p>
                              </div>
                            </div> <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Unlimited/Exhaustive salary component
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Get customised/optimal salary structure format based on industry type
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Payroll Reimbusements">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Extensive reimbursement configurations
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Monthly / annual entitlements
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Claim process with limit checking
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Excess claim tracking and set-off feature
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Expenses/Reimbursements management. Online claim processing with work flows for maintaining trail and inbuilt limit checking and variance tracking
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Loans and Salary Advances">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Automatically captures salary advances from online data and auto deduction in next payroll
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Manage company loans to employees
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Number of options and methods configured for calculation of interest and EMI
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Automatically schedule deductions based on preferred options
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Manage deductions with pausing deductions for specified period
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Captures and automates closure of loan on completion of repayment
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Get Bifurcation for Principal and interest components
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Loan prepayment and balloon payment features
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Inbuilt parameters for loan calculation and deductions based on different available options
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Access Management">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Reports Access Management
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Reports Access Management
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Payout and Disbursements">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Flexible payment options like cash, bank transfer etc
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  All major bank transfer electronic formats built-in
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  All Pay frequency- pay different employees in different batches. Pre schedule pay roll cycles and facility to release in batches
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </Panel>
                      <Panel header="Leave Management" className="main-panel-phone-screen">
                        <Collapse accordion={true}>
                          <Panel header="Fully Customizable Leave Policies">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Unlimited leave types (annual, privilege, maternity, etc.)
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Manage different types of leaves
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Customize leave types and holiday list
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Restricted (optional) holidays support
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Different and multiple leave policy depending on company and group of employees
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Integration with team calendars and real time reports
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Manage Balance and Transactions">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Integration with timesheet for automatic tracking of leave and leave balance
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Calculation for carry forward and leave lapsing
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Online application with approvals
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </Panel>
                      <Panel header="Employee Self Onboarding" className="main-panel-phone-screen">
                        <Collapse accordion={true}>
                          <Panel header="Manage Balance and Transactions">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Personalised & Paperless onboarding of employees
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Workflows for Admin review
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Alerts and Reminders for employees and admin
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </Panel>
                      <Panel header="Employee Portal (Web and Mobile app coming soon)" className="main-panel-phone-screen">
                        <Collapse accordion={true}>
                          <Panel header="Employee Portal - Core HR">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Get notifications regarding birthdays, news, upcoming events
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  List of all employees with their contact details at one click
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Access to own documents and letters
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Access to company policies,handbook, forms,etc.
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Access to own employee information
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Mobile app for employees and managers
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Employee Portal - Leave">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Leave application and review
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Leave cancellation work-flow
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Online leave balances and details
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Apply for leave online and also get detailed reports regarding leave balance, carry forward etc for planning leaves
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Employee Portal - Payroll">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Online payslips
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  IT calculation statement
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  IT proof of investments
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  IT savings and declarations
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p style={{ marginLeft: "0.20em" }}>
                                  Reimbursements claims
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Get payslips for current as well as previous months saved at one place.Provide declaration online with proof of investments and get projections and current tax calculations.Get claims settled with submission and processing done online
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Employee Portal - Attendance">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Attendance regularization work-flow
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Team attendance information for managers
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Detailed attendance information
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Real time report integrated with other details with online attendance regularisations option
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Dashboards">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Analytical Dashboards
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Operational Dashboards
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </Panel>
                      <Panel header="Attendance Management" className="main-panel-phone-screen">
                        <Collapse accordion={true}>
                          <Panel header="Swipe Capture from Varied Sources">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Get real time attendance integration
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Mobile attendance marking                                  </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  GPS based mobile attendance marking                                  </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Facial Recognition based Attendance Marking                                  </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Mark attendance with smart mobile app from anywhere and it will get processed automatically with preset geolocation address.HR/Managers can track and get reports based on tagged locations
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="High Configurable Policies">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Multiple attendance policies for different groups
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Customisable weekends
                                </p>
                              </div>
                            </div>
                          </Panel>
                          <Panel header="Attendance Processing">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Automatic daily attendance processing
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Attendance regularization work-flow
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Manual override facility
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Continous absence alerts
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Month end HR review and finalization facility
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={blueTickSingle} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Attendance muster generation
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </Panel>
                      <Panel header="Onboarding Support & Support Plans" className="main-panel-phone-screen">
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <div className="circle"></div>
                          <div>
                            <p className="collapse-p-l-margin ">
                              Product Training
                            </p>
                          </div>
                        </div>
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={redCross} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Onboard Support
                            </p>
                          </div>
                        </div>
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={blueTick} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Ticket Support
                            </p>
                          </div>
                        </div>
                      </Panel>
                      <Panel header="General features" className="main-panel-phone-screen">
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={blueTick} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Mobile app for employees and managers
                            </p>
                          </div>
                        </div>
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={blueTick} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Excessive Excel import and export facility
                            </p>
                          </div>
                        </div>
                        <Collapse accordion={true}>
                          <Panel header="User Management">
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Support for multiple users
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Fine grained role based access control (RBAC)
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Password policy configuration
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  Detailed audit logging & reporting of all activities
                                </p>
                              </div>
                            </div>
                            <div className="flex" style={{ bottomMargin: "1em" }}>
                              <img src={redCross} alt="" />
                              <div>
                                <p className="collapse-p-l-margin ">
                                  IP restriction for access control to application
                                </p>
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </Panel>
                      <Panel header="Database Limits and Backup" className="main-panel-phone-screen">
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <div className="circle"></div>
                          <div>
                            <p className="collapse-p-l-margin ">
                              Database Storage Limits
                            </p>
                          </div>
                        </div>
                      </Panel>
                      <Panel header="PayNow" className="main-panel-phone-screen">
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={redCross} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Direct salary transfers to employees
                            </p>
                          </div>
                        </div>
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={redCross} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Secured OTP based Authentication
                            </p>
                          </div>
                        </div>
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={redCross} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Real time transaction & account balance status
                            </p>
                          </div>
                        </div>
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={redCross} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Reports & Audit Trail for due diligence
                            </p>
                          </div>
                        </div>
                      </Panel>
                      <Panel header="Enterprise Features" className="main-panel-phone-screen">
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={redCross} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Single sign-on
                            </p>
                          </div>
                        </div><div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={redCross} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              REST API access
                            </p>
                          </div>
                        </div>
                      </Panel>
                      <Panel header="Expense Claims Management" className="main-panel-phone-screen">
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={blueTick} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Multi-company Support
                            </p>
                          </div>
                        </div>
                      </Panel>
                      <Panel header="Geo Mark+ (Coming Soon)" className="main-panel-phone-screen">
                        <div className="flex" style={{ bottomMargin: "1em" }}>
                          <div className="circle"></div>
                          <div>
                            <p className="collapse-p-l-margin ">
                              GPS based attendance marking for distributed workforce
                            </p>
                          </div>
                        </div><div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={blueTick} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Workflows for Manager Reviews
                            </p>
                          </div>
                        </div><div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={redCross} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Attendance scheme level customizations
                            </p>
                          </div>
                        </div><div className="flex" style={{ bottomMargin: "1em" }}>
                          <img src={redCross} alt="" />
                          <div>
                            <p className="collapse-p-l-margin ">
                              Geo Swipe reports for due diligence
                            </p>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </Panel>
                </Collapse>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <NormalButton
          type={13}
          buttonText="Next Page"
          block={true}
          margin={[16, 0]}
          secondaryIcon={registerArrow}
          heightWidth={["50px", "500px"]}
          onClick={clickHandler}
        />
      </div> */}
    </Content>
  );
}

export default withRouter(PricingModel);
