/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Upload,
    message,
    Spin,
    Table,
    Input,
    Progress,
    Select,
    Modal
} from "antd";
import { DownloadOutlined, FileAddOutlined } from "@ant-design/icons";
import config, { monthList } from "../../../utils/config";
import { useEffect, useState } from "react";
import { NormalButton } from "../../ComponentLibrary";
import { addMonthClose } from "../../../apis/organisation";
import DropdownComponent from "../../InputLibrary/DropdownComponent/DropdownComponent";

const { Dragger } = Upload;
const { Option } = Select;

const UploadMonthClose = ({
    organisationId,
    organisation,
    user,
    actionUrl,
    downloadURL,
    setBulkModalShow,
    handleAction
}) => {
    const [loading, setLoading] = useState(false);
    const [monthCloseValidate, setMonthCloseValidate] = useState({
        display: false,
        loading: false
    });
    const [monthCloseSubmitData, setMonthCloseSubmitData] = useState([]);
    const [displayList, setDisplayList] = useState([]);
    const [successCounter, setSuccessCounter] = useState(0);
    const [month, setMonth] = useState(0);
    const [financialYearList, setFinancialYearList] = useState([]);
    const [financialYear, setFinancialYear] = useState("");

    useEffect(
        () => {
            let startFYear = 2015;
            let currentDate = new Date();
            let currentYear = currentDate.getFullYear();
            let resFinancialYearList = [];
            for (let i = startFYear; i < currentYear + 1; i++) {
                let nextYear = i + 1;
                let tempFYear = `${i}-${nextYear}`;
                resFinancialYearList.push(tempFYear);
            }
            setFinancialYearList([...resFinancialYearList]);
        },
        [user]
    );
    function beforeUpload(_file) {
        let res = true;
        if (!financialYear) {
            message.error("Please Select Financial Year");
            res = false;
        }
        if (month < 0 || month > 11) {
            message.error("Please Select correct month");
            res = false;
        }

        return res;
    }

    const props = {
        name: "file",
        multiple: true,
        headers: {
            authorization: localStorage.getItem("mool_auth")
        },
        beforeUpload: beforeUpload,
        async onChange(info) {
            setLoading(true);
            const { response } = info.file;
            if (response) {
                setLoading(false);
                let successResult = [];
                if (response.mcloseData) {
                    for (const element of response.mcloseData) {
                        successResult.push({ ...element });
                    }
                    setMonthCloseSubmitData([...successResult]);
                    setDisplayList([...successResult]);
                    setBulkModalShow(false);

                    setMonthCloseValidate({
                        display: true,
                        loading: false
                    });
                }
                // organisation.updateMonthClose({organisationId})
            }
        }
    };

    const submitMonthCLose = async () => {
        setMonthCloseValidate({ ...monthCloseValidate, loading: true });
        let final = 1;
        for (const element of monthCloseSubmitData) {
            let monthClose = {
                ...element
            };
            await addMonthClose({
                monthClose
            });
            setSuccessCounter(final);
            final++;
        }
        organisation.updateMonthClose({ organisationId });
        message.success(`Month Close has been added for the empoyees`);
        resetModal();
    };

    const resetModal = () => {
        setMonthCloseSubmitData([]);
        setDisplayList([]);
        setSuccessCounter(0);
        setFinancialYear("");
        setMonth(0);
        setMonthCloseValidate({
            display: false,
            loading: false
        });
        setBulkModalShow(false);
    };

    const searchMonthList = e => {
        let ml = [];
        let SerachParams = e.target.value.toLowerCase();
        try {
            ml = monthCloseSubmitData.filter(val => {
                return Object.keys(val).some(keys =>
                    val[keys].toString().toLowerCase().includes(SerachParams)
                );
            });
        } catch (err) {
            setDisplayList([...monthCloseSubmitData]);
        }
        setDisplayList(ml);
    };

    return (
        <div className="m-top-16">
            <div className="month-closing-files">
                <div className="header-monthclose-bulk">
                    <h2 className="month-close-cards-data-heading">Add Multiple</h2>
                    <a onClick={() => handleAction(downloadURL)}>
                        <DownloadOutlined /> Download Sample
                    </a>
                </div>
                <Spin spinning={loading} size="large">
                    <div className="radio-group">
                        <DropdownComponent
                            className="general-ant-field-select"
                            labeltext={"Financial Year"}
                            style={{ width: "100%", marginTop: 10 }}
                            size="large"
                            value={financialYear}
                            values={financialYearList}
                            options={financialYearList}
                            onChange={e => {
                                setFinancialYear(e);
                            }}
                            bordered={false}
                            filterOption={(input, option) =>
                                option.children &&
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())}
                            showSearch
                        >
                            <Option value={""}>Financial Year...</Option>
                            {financialYearList.map((val, index) =>
                                <Option key={`year-${index}`} value={val}>
                                    {val}
                                </Option>
                            )}
                        </DropdownComponent>
                    </div>
                    <div style={{marginTop:"2em"}} className="radio-group">
                        <DropdownComponent
                            className="general-ant-field-select"
                            labeltext={"Select Month"}
                            size="large"
                            value={month}
                            values={monthList.map((_val, index) => index)}
                            options={monthList} 
                            style={{ width: "100%", marginTop: 10 }}
                            onChange={e => setMonth(e)}
                            placeholder="month"
                            bordered={false}
                            filterOption={(input, option) =>
                                option.children &&
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            showSearch
                        >
                            {monthList.map((val, index) =>
                                <Option value={index} key={`month-${index}`}>
                                    {val}
                                </Option>
                            )}
                        </DropdownComponent>
                    </div>
                    {financialYear !== "" &&
                        <Dragger
                            {...props}
                            action={`${actionUrl}/${financialYear}/${month}`}
                            showUploadList={false}
                            className="dragger"
                        >
                            <div className="dragger-info-container">
                                <FileAddOutlined className="uploadLogo" />
                                <p className="ant-upload-text">
                                    Click or drag file to this area to upload
                                </p>
                            </div>
                        </Dragger>}
                </Spin>
            </div>

            <Modal
                footer={null}
                width={1000}
                closable={false}
                visible={monthCloseValidate.display}
            >
                <div className="w-full h-full flex flex-direction-column justify-between align-start">
                    <div className="w-full">
                        <div className="flex align-center justify-between w-full">
                            <h3 className="app-heading-2 font-weight-700">Month Close</h3>
                            <Input
                                onChange={searchMonthList}
                                style={{
                                    width: 200,
                                    borderBottom: "none",
                                    background: "#eeeeee"
                                }}
                                size={"large"}
                                placeholder="search"
                                bordered={false}
                            />
                        </div>
                        {monthCloseValidate.loading &&
                            <Progress
                                percent={Math.round(
                                    successCounter / monthCloseSubmitData.length * 100
                                )}
                                size="large"
                                status="active"
                            />}
                        <Spin spinning={monthCloseValidate.loading} size="large">
                            <Table
                                columns={columns}
                                dataSource={displayList.reverse()}
                                scroll={{ x: 600 }}
                            />
                        </Spin>
                    </div>
                    <div className="w-full flex justify-end">
                        <NormalButton
                            type={5}
                            buttonText="Cancel"
                            onClick={resetModal}
                            margin={[0, 8]}
                        />
                        <NormalButton
                            type={13}
                            buttonText="Submit"
                            onClick={submitMonthCLose}
                            margin={[0, 8]}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const columns = [
    {
        title: "Applicable On",
        dataIndex: "month",
        defaultSortOrder: "aescend",
        render: text =>
            <p>
                {config.monthList[text]}
            </p>
    },
    {
        title: "PAN Number",
        dataIndex: ["user", "PAN"]
    },
    {
        title: "Type",
        dataIndex: "type"
    },
    {
        title: "Reason",
        dataIndex: "details"
    },
    {
        title: "Amount",
        dataIndex: "amount",
        render: (amount, row) =>
            <p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
                {row.type === "debit" ? "-" : "+"}
                {amount}
            </p>
    }
];

export default UploadMonthClose;
