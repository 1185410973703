import { Row, Col } from "antd";
import { Content } from "antd/lib/layout/layout";
import { numformatter } from "../../../utils/config";

const ProcessPayroll = ({
  payroll,
  processedPayrolls
}) => {
  return (
    <Content className="app-white-card">
      {payroll &&
        <div className="app-flex-container-2">
          <div className="payroll-process">
            <Row gutter={[16, 16]}>
              <Col> Payroll Cost </Col>
              <Col>
                {" "}:{" "}
                <b>
                  {payroll.totalPayslipsGenerated != null
                    ? numformatter(payroll.totalEarnings)
                    : "0.00"}{" "}
                  INR
                </b>{" "}
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-top-20">
              <Col> Net Pay </Col>
              <Col>
                {" "}: <b>{processedPayrolls.grossSalary} INR</b>{" "}
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-top-20">
              <Col> Income Tax </Col>
              <Col>
                {" "}: <b>{processedPayrolls.incomeTax} INR</b>{" "}
              </Col>
            </Row>
          </div>
          <div className="flex payroll-process">
            <div className="separator" />
            <div className="m-left-36">
              <Row gutter={[16, 16]}>
                <Col> Professional Tax </Col>
                <Col>
                  {" "}: <b>{processedPayrolls.professionalTax} INR</b>{" "}
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="m-top-20">
                <Col> Employee PF </Col>
                <Col>
                  {" "}: <b>{processedPayrolls.employeePF} INR</b>{" "}
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="m-top-20">
                <Col> Employer PF </Col>
                <Col>
                  {" "}: <b>{processedPayrolls.employerPF} INR</b>{" "}
                </Col>
              </Row>
            </div>
          </div>
          <div className="flex payroll-process">
            <div className="separator" />
            <div className="m-left-36 ">
              <Row gutter={[16, 16]}>
                <Col> Reimbursement </Col>
                <Col>
                  {" "}: <b>{processedPayrolls.reimbursement} INR</b>{" "}
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="m-top-20">
                <Col> Bonus </Col>
                <Col>
                  {" "}: <b>{processedPayrolls.bonus} INR</b>{" "}
                </Col>
              </Row>
            </div>
          </div>
        </div>}
    </Content>
  );
};

export default ProcessPayroll;
