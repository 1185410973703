import { Layout, Modal, notification, Progress, Table } from "antd";
import { useState } from "react";
import '../../../styles/sendMailModal.less'
import {
  singlePayslipSendMail,
  updatePayslipMail
} from "../../../apis/organisation";
import { NormalButton } from "../../ComponentLibrary";
const { Content } = Layout;


    const SendNewMailModal = ({
      modalVisible,
      allEmp,
      setModalVisible,
      month,
      financialYear,
      setButtonDisabled,
      updatePayrollData,
      organisationId,
    }) => {
      const [modalData, setModalData] = useState({
        success: 0,
        fail: 0,
        total: allEmp.length,
      });
      const [responseCount, setResponseCount] = useState(0);
      const [state, setState] = useState(0);

      const resetModal = () => {
        setModalData({ ...modalData, success: 0, fail: 0 });
        setResponseCount(0);
        setModalVisible(false);
        setButtonDisabled(false);
        setState(0);
      };
      
      const [rowSelected, setRowSelected] = useState(allEmp)
      
      const rowSelection = {
          onChange: (selectedRowKeys, selectedRows) => {
              setRowSelected(selectedRows)
          },
          getCheckboxProps: (record) => ({
              disabled: record.name === 'Disabled User',
              name: record.name,
          }),
      };
        const columns = [
            {
                title: 'Employee name',
                dataIndex: 'displayName',
                align: 'left',
                render: (text, record) => `${text} ( ID: ${record.currentOrganisationMeta.employeeId} )`
            },
            {
                title: 'Mail ID',
                dataIndex: 'primaryEmail',
                align: 'right'
            },
        ];
        const submitModal = async() => {
            setState(1);
            let success = 0;
            let fail = 0;
            let final = 1;
            for (const element of rowSelected) {
              try {
                  let response = await singlePayslipSendMail(
                    `${element._id}/${month}/${financialYear}`
                  );
                  setResponseCount(final);
                  final++;
                  if (response.data.success) {
                    success++;
                  } else {
                    fail++;
                  }
                } catch (e) { }
            }
        setModalData({ ...modalData, success: success, fail: fail });
        await updatePayslipMail({
          organisationId,
          financialYear,
          month,
        });
        await updatePayrollData();
        if (success > 0) {
          notification.success({
            message: `Successfully send ${success} Payslip Mails`,
          });
        } else {
          notification.error({ message: "Sorry! Try Again" });
        }
        resetModal();
        }
        const selectedRowKeys = allEmp.map((item) => item['_id']);
      return (
        <Modal
          centered
          footer={null}
          width={state === 0 ? 700 : 400}
          onCancel={resetModal}
          style={{ padding: state === 0 ? 0 : 0 }}
          visible={modalVisible}
          title= {state === 0 ? 'Mail Payslips' : null }
          className= {state === 1 ? "modal-class state-one" : "modal-class"}
          closable= {state === 0 ? true : false}
          forceRender={true}
        >
          <Content>
            {state === 0 && (
              <>
                <Table
                    pagination={false} 
                    className= 'modal-table'
                    rowSelection={{
                    type: 'checkbox',
                    defaultSelectedRowKeys: selectedRowKeys,
                    ...rowSelection,
                    }}
                    scroll={{ y: 300 }}
                    rowKey={"_id"}
                    columns={columns}
                    dataSource={allEmp}
                />
                <br/>
                <div className="flex">
                    <NormalButton onClick={resetModal} buttonText={"Cancel"}
                    type={5} />
                   <NormalButton onClick={submitModal} buttonText={"Confirm and mail"}
                    type={13} />
                </div>
              </>
            )}
            {state === 1 && (
              <div style={{ padding: 20 }}>
                <h3 style={{fontWeight: "bold", fontSize: 16}}>
                  Mailing employees ({Math.round((responseCount / rowSelected.length) * 100)}%)
                </h3>
                <Progress
                  strokeColor="#405cd2"
                  trailColor="#f0f1f4"
                  strokeWidth={10}
                  percent={Math.round((responseCount / rowSelected.length) * 100)}
                  size="small"
                  status="active"
                />{" "}
              </div>
            )}
          </Content>
        </Modal>
      );
    };

export default SendNewMailModal;
