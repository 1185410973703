import { Button, Col, Layout, notification, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { punchIn, punchOut } from "../../../../apis/employee";
import timerIcon from "../../../../assets/timer.svg";
import timerWhite from "../../../../assets/timer_white.svg";
import { categoryMappring, paths } from "../../../../constants/routes";
import { shortMonthList } from "../../../../utils/config";

const { Content } = Layout;

const Attendance = ({
  leaveBalanceReport,
  todayAttendance,
  user,
  employeeReducer,
  authReducer,
  history,
}) => {
  const [logoff, setLogoff] = useState(false);
  const [isCompleted, setIscompleted] = useState(false);
  const [hourDiff, setHourDiff] = useState(0);
  const [minDiff, setMinDiff] = useState(0);
  const [secDiff, setSecDiff] = useState(0);
  const [timer, setTimer] = useState(null);

  const renderComponent = () => {
    authReducer.updateCurrentTab("leave_report");
    authReducer.updateSelectedCategory(categoryMappring.employee.leave_report);
    history.push(paths.employee.leave_report);
  };
  useEffect(() => {
    document.title = 'Attendance | Attendance Home';
  });
  useEffect(() => {
    if (Object.keys(todayAttendance).length > 0) {
      if (todayAttendance.end === null) {
        setLogoff(true);
        setIscompleted(false);
        setTimer(
          setInterval(() => {
            let miliDiff =
              new Date(Date.now()) - new Date(todayAttendance.start);
            let tempHourDiff = Math.floor(miliDiff / 3600000);
            let remainMili = miliDiff - tempHourDiff * 3600000;
            let tempMinDiff = Math.floor(remainMili / 60000);
            remainMili = remainMili - tempMinDiff * 60000;
            let tempSecDiff = Math.ceil(remainMili / 1000);
            setHourDiff(tempHourDiff);
            setMinDiff(tempMinDiff);
            setSecDiff(tempSecDiff);
          }, 1000)
        );
      } else {
        let miliDiff =
          new Date(todayAttendance.end) - new Date(todayAttendance.start);
        let tempHourDiff = Math.floor(miliDiff / 3600000);
        let remainMili = miliDiff - tempHourDiff * 3600000;
        let tempMinDiff = Math.floor(remainMili / 60000);
        remainMili = remainMili - tempMinDiff * 60000;
        let tempSecDiff = Math.ceil(remainMili / 1000);
        setHourDiff(tempHourDiff);
        setMinDiff(tempMinDiff);
        setSecDiff(tempSecDiff);
        setIscompleted(true);
        setLogoff(true);
      }
    }
  }, [todayAttendance]);

  const punchInPunchOut = async () => {
    let postData = {
      id: user._id,
      date: Date.now(),
      type: 0,
    };
    let res = {};
    if (logoff) {
      clearInterval(timer);
      setTimer(null);
      res = await punchOut(postData);
    } else {
      res = await punchIn(postData);
    }
    employeeReducer.updateTodayAttendance({
      id: user._id,
    });
    if (res.data.success) {
      notification.success({ message: "Successfully Logged The Attendance" });
    } else {
      notification.error({ message: "Sorry Unable to Log The Attendance" });
    }
  };

  return (
    <Content>
      <div className="paper-wrapper">
        <div className="timer">
          <p className={`countdown ${isCompleted && "countdown-gray"}`}>
            {hourDiff}h {minDiff}m {secDiff}s{" "}
            {!isCompleted && (
              <img alt="timer" src={timerIcon} height="40" width="40" />
            )}
          </p>
          <p className="app-label-4 subtitle">
            {Object.keys(todayAttendance).length > 0 &&
              todayAttendance.end === null
              ? ` Logged In: ${shortMonthList[new Date().getMonth()]
              } ${new Date(todayAttendance.start).getDate()} at ${new Date(
                todayAttendance.start
              ).toLocaleTimeString()}`
              : `Please Punch In To Log Your Attendance`}
          </p>
          <Button
            type="primary"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 45,
              fontSize: 16,
              fontWeight: 700,
              borderRadius: "5px"
            }}
            disabled={isCompleted}
            onClick={punchInPunchOut}
          >
            <img
              style={{ marginRight: 5 }}
              alt="timer"
              src={timerWhite}
              height="12"
              width="12"
            />{" "}
            {isCompleted && "Completed"} {(!isCompleted && !logoff) ? "Punch In" : "Punch Out"}
          </Button>
        </div>
        {/* <div className="footer-separator flex justify-between">
          <div className="left-container">location address......</div>
          <div className="right-container">Image.jpg</div>
        </div> */}
      </div>
      <div className="paper-wrapper p-bottom-6">
        <div className="paper-header-container">
          <h1 className="paper-heading">Leave</h1>
        </div>
        {leaveBalanceReport && (
          <Skeleton
            active={true}
            loading={Object.keys(leaveBalanceReport).length === 0}
            paragraph={{ rows: 3 }}
          >
            {Object.keys(leaveBalanceReport).length > 0 && (
              <Row className="m-top-16">
                {Object.keys(leaveBalanceReport.leaveTypes).length > 0 &&
                  Object.keys(leaveBalanceReport.leaveTypes).map(
                    (val, index) => {
                      return (
                        <Col
                          sm={12}
                          xs={12}
                          lg={8}
                          md={8}
                          key={`info-${index}`}
                        >
                          <LabelValueDisplay
                            label={leaveBalanceReport.leaveTypes[val].name}
                            value={parseFloat(
                              leaveBalanceReport.leaveTypes[val].balance
                            ).toFixed(2)}
                            key={`container-${index}`}
                          />
                        </Col>
                      );
                    }
                  )}
              </Row>
            )}
          </Skeleton>
        )}

        <div className="footer-separator flex justify-center">
          <Link
            onClick={renderComponent}
            style={{ color: "#9898a5", marginTop: 8 }}
            to={paths.employee.leave_report}
          >
            View Leave History
          </Link>
        </div>
      </div>
    </Content>
  );
};

const LabelValueDisplay = ({ label, value }) => {
  return (
    <div>
      <p className="info-header">{label}</p>
      <p className="information">{value}</p>
    </div>
  );
};

export default Attendance;
