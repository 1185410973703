import { DeleteOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Row, Skeleton, Spin, Upload } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PasswordResponses from "react-pdf/dist/esm/PasswordResponses";
import { removeS3Object, updateUserDocument } from "../../../../apis/employee";
import { getDocumentURL } from "../../../../apis/user";
import uploadLogo from "../../../../assets/icons/savefile.svg";
import config from "../../../../utils/config";

const { Dragger } = Upload;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const EmployeeDocument = ({
  user,
  document,
  authReducer,
  employeeReducer,
  onboarding,
}) => {
  const [documents, setDocuments] = useState("");
  const [modalstatus, setModalStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [format, setFormat] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const deletePANCard = async () => {
    setIsLoading(true);
    await updateUserDocument({
      id: user._id,
      document: {
        ...document,
        PAN: {},
      },
    });
    await removeS3Object({ Key: document.PAN.Key });
    authReducer.updateDocument({
      ...document,
      PAN: {},
    });
    setIsLoading(false);
  };

  const deleteaadharCard = async () => {
    setIsLoading(true);
    await updateUserDocument({
      id: user._id,
      document: {
        ...document,
        aadhaar: {},
      },
    });
    await removeS3Object({ Key: document.aadhaar.Key });
    authReducer.updateDocument({
      ...document,
      aadhaar: {},
    });
    setIsLoading(false);
  };

  const panUpload = {
    maxCount: 1,
    name: "file",
    multiple: false,
    action: `${config.rootUrl}/api/document/userDocs`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    showUploadList: false,
    beforeUpload: config.beforeFileUpload,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        documentDetails.name = info.file.name.split(".")[0];
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        let resp = await updateUserDocument({
          id: user._id,
          document: {
            ...document,
            PAN: documentDetails,
          },
        });
        authReducer.updateDocument(
          resp.data.data.user.document || {
            PAN: {},
            aadhaar: {},
          }
        );
        setIsLoading(false);
      }
    },
  };

  const aadhaarUpload = {
    maxCount: 1,
    name: "file",
    multiple: false,
    action: `${config.rootUrl}/api/document/userDocs`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    showUploadList: false,
    beforeUpload: config.beforeFileUpload,
    async onChange(info) {
      setIsLoading(true);

      let { response } = info.file;
      if (response) {
        let documentDetails = { ...response.data.stored };
        documentDetails.verified = false;
        documentDetails.createdAt = new Date();
        documentDetails.name = info.file.name.split(".")[0];
        delete documentDetails.Bucket;
        delete documentDetails.Etag;
        let resp = await updateUserDocument({
          id: user._id,
          document: {
            ...document,
            aadhaar: documentDetails,
          },
        });
        authReducer.updateDocument(
          resp.data.data.user.document || {
            PAN: {},
            aadhaar: {},
          }
        );
        setIsLoading(false);
      }
    },
  };

  const increaseCurrentPage = () => {
    let currPage = currentPage;
    currPage = currPage < pageNumber ? currPage + 1 : currPage;
    setCurrentPage(currPage);
  };

  const decreaseCurrentPage = () => {
    let currPage = currentPage;
    currPage = currPage > 1 ? currPage - 1 : currPage;
    setCurrentPage(currPage);
  };

  const getDocumentURLFromKey = async (key) => {
    setFormat(key.split(".").pop());
    let document = await getDocumentURL({ Key: key });
    setDocuments(document.data);
  };

  const onPasswordHandler = function (callback, reason) {
    function callbackProxy(password) {
      // Cancel button handler
      if (!password) {
        setModalStatus(false)
        return
      }
      callback(password);
    }

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(password);
        break;
      }
      default:
    }
  }

  const DocumentAllowances = ({ data, deleteFunction, type }) => {
    return (
      <div className="document-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <DeleteOutlined onClick={deleteFunction} />
          <span

            onClick={() => {
              setModalStatus(true);
              getDocumentURLFromKey(data.Key);
            }}
            className="file-typeHeader"
          >
            {data.Key.split(".").pop()}
          </span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{type}</span>
            <span className="date-contianer">
              {new Date(data.createdAt).toDateString()}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="employee-card-container">
      <Spin spinning={isLoading} size={"large"}>
        <h2 className="card-heading">Personal Cards</h2>
        <Row gutter={20} className="dragger-container">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            className="dragger-items-container"
          >
            {Object.keys(document.PAN).length === 0 && (
              <>
                <h1>Pan Card</h1>
                <Dragger
                  {...panUpload}
                  className="dragger-container"
                  maxCount={1}
                  style={document.PAN.Key ? { backgroundColor: "#e4fff8" } : {}}
                >
                  <p className="ant-upload-drag-icon">
                    <img alt="img" src={uploadLogo} height="70" width="70" />
                  </p>
                  <p className="ant-upload-text">Drag And Drop PAN Card Here</p>
                  <p className="ant-upload-hint">
                    or <u>Click Here</u> to upload
                  </p>
                </Dragger>
              </>
            )}
            {Object.keys(document.PAN).length > 0 && (
              <DocumentAllowances
                deleteFunction={deletePANCard}
                data={document.PAN}
                type="PAN Card"
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {Object.keys(document.aadhaar).length === 0 && (
              <>
                <h1>Aadhaar Card</h1>
                <Dragger
                  {...aadhaarUpload}
                  className="dragger-container"
                  maxCount={1}
                  style={
                    document.aadhaar.Key ? { backgroundColor: "#e4fff8" } : {}
                  }
                >
                  <p className="ant-upload-drag-icon">
                    <img alt="img" src={uploadLogo} height="70" width="70" />
                  </p>
                  <p className="ant-upload-text">
                    Drag And Drop Aadhaar Card Here
                  </p>
                  <p className="ant-upload-hint">
                    or <u>Click Here</u> to upload
                  </p>
                </Dragger>
              </>
            )}
            {Object.keys(document.aadhaar).length > 0 && (
              <DocumentAllowances
                deleteFunction={deleteaadharCard}
                data={document.aadhaar}
                type="Aadhaar Card"
              />
            )}
          </Col>
        </Row>
        <Modal
          onCancel={() => {
            setDocuments("");
            setModalStatus(false);
            setFormat("");
            setCurrentPage(1);
            setPageNumber(1);
          }}
          visible={modalstatus}
          footer={null}
          centered
          width={900}
        >
          <Skeleton loading={!documents} active avatar paragraph={{ rows: 5 }}>
            <a download href={documents}>
              Download Document
            </a>
            {format === "pdf" ? (
              <>
                <Document
                  className="document-viewer"
                  file={documents}
                  onPassword={onPasswordHandler}
                  onLoadSuccess={(pn) => setPageNumber(pn.numPages)}
                >
                  <Page pageNumber={currentPage} />
                </Document>
                <div className="pdf-document-action">
                  <Button onClick={decreaseCurrentPage} type="default">
                    <LeftOutlined />
                  </Button>
                  <p>
                    Page {currentPage} of {pageNumber}
                  </p>
                  <Button onClick={increaseCurrentPage} type="default">
                    <RightOutlined />
                  </Button>
                </div>
              </>
            ) : (
              <img alt="" src={documents} className="document-display" />
            )}
          </Skeleton>
        </Modal>
      </Spin>
    </div>
  );
};

export default EmployeeDocument;
