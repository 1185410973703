import { Modal, notification, Row, Col, Spin, message, Checkbox, Popconfirm } from "antd";
import { useState, useEffect } from "react";
import { NormalButton } from "../../../ComponentLibrary/index";
import { UserDeleteOutlined, UserAddOutlined } from "@ant-design/icons";

import {
  assignEmployeeToGroup,
  createNewGroup,
  deleteGroup,
  getEmployeesByGroupId,
  updateEmployerOnBoarding,
  updateGroupInfo,
} from "../../../../apis/organisation";
import plusSymbol from "../../../../assets/icons/whitePlus.svg";
import blueEdiIcon from "../../../../assets/common/blueEditButton.svg";
import userIcon from "../../../../assets/icons/user-add.svg";
import blueEditIcons from "../../../../assets/common/blueEditIcon.svg";
import blueRightArrowIcon from "../../../../assets/common/blueRightArrow.svg";
import blueDownArrowIcon from "../../../../assets/common/blueDownArrow.svg";
import blueDeleteIcon from "../../../../assets/common/blueDeleteButton.svg";
import { connect } from "react-redux";
import organisation from "../../../../redux/modules/organisation";
import CreateGroup from "./createGroup";
import { calculatePercentage } from "../../../../utils/common";
import { HeaderComponent } from "../../../Common";
import { createLog } from "../../../../apis/user";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";

const Groups = ({
  organisationSettings,
  organisationReducer,
  groups,
  user,
  employeeList,
  onboarding,
}) => {
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupsList, setGroupsList] = useState([]);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [deleteGroupDetails, setDeleteGroupDetails] = useState({});
  const [allowanceObject, setAllowanceObject] = useState({});
  const [customAllowanceObject, setCustomAllowanceObject] = useState({});
  const [currentEmployee, setCurrentEmployee] = useState("");
  const [defaultEmployeeData, setDefaultEmployeeData] = useState([]);
  const [showUserData, setShowUserData] = useState(false);
  const [assignClicked, setAssignClicked] = useState(false);
  const [newGroupsDetails, setNewGroupsDetails] = useState({
    name: "",
    allowances: {},
    customAllowances: {},
    groupSettings: {},
    overtimeSetting: {},
  });
  const [editGroupsDetails, setEditGroupsDetails] = useState({
    groupId: "",
    name: "",
    allowances: {},
    customAllowances: {},
    groupSettings: {},
    overtimeSetting: {
      factor: 1,
      component: "Basic"
    },
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const defaultGroupSettings = {
    pfEnabled: true,
    variableAboveCTC: false,
    gratuity: false,
    gratuityTenure: 0,
  };
  const [showDefaultGroup, setShowDefaultGroup] = useState(false);
  const [showGeneralGroupDetails, setShowGeneralGroupDetails] = useState(false);

  const [isPercentageStructure, setIsPercentageStructure] = useState(false);
  const [percentageStructure, setPercentageStructure] = useState({
    basic: 0,
    hra: 0,
    buffer: 0.15,
    specialAllowances: 0,
    allowances: {},
    customAllowances: {},
    basicHRAPercentage: {
      basic: true,
      hra: true,
    },
  });

  const assignEmployeeInGroup = (groupId) => {
    setIsLoading(true)
    if (!currentEmployee) {
      message.error("Please select one employee");
      setIsLoading(false)
      return;
    }
    assignEmployeeToGroup({
      groupId,
      id: currentEmployee
    }).then(res => {
      if (res.data.success) {
        notification.success({ message: "Employee Group Changed Successfully!" });
        getEmployeesByGroupId(editGroupsDetails.groupId).then(res => {
          setDefaultEmployeeData(res.data);
        });
      } else {
        notification.error({ message: "Error while assigning" });
      }
    })
    setIsLoading(false)
    setCurrentEmployee("")
    setAssignClicked(false);
  }

  const calculateIsApplicable = (percentage) => {
    let percentTotal = calculatePercentage(percentage);
    let remaing = 1 - percentTotal;
    let isApplicable = remaing >= 0;
    return isApplicable;
  };

  const validatePercentageStructure = (structure) => {
    Object.keys(structure).forEach((val) => {
      if (val === "allowances" || val === "customAllowances") {
        if (Object.keys(structure[val]).length > 0) {
          Object.keys(structure[val]).forEach((val_nest) => {
            if (!structure[val][val_nest]["value"]) {
              return false;
            }
          });
        }
      } else if (val !== "specialAllowances") {
        if (!structure[val]) {
          return false;
        }
      }
    });
    return true;
  };

  /**
   *
   * @param {*} value
   * @param {*} field
   * @param {*} type
   * type - 0 basic hra
   * type - 1 for allowances
   * type - 2 for custom allowances
   */
  const updatePercentageStructure = (value, field, type) => {
    let percentValue = value / 100;
    let tempPercent = { ...percentageStructure };
    if (type === 0) {
      tempPercent[field] = tempPercent.basicHRAPercentage[field]
        ? percentValue
        : value;
    } else if (type === 1) {
      let allowance = {
        ...tempPercent.allowances,
      };
      if (allowance[field]) {
        allowance[field] = {
          ...allowance[field],
          value: allowance[field]["isPercent"] ? percentValue : value,
        };
      } else {
        allowance[field] = {
          value: percentValue,
          isPercent: true,
        };
      }
      tempPercent["allowances"] = { ...allowance };
    } else if (type === 2) {
      let callowance = {
        ...tempPercent.customAllowances,
      };
      if (callowance[field]) {
        callowance[field] = {
          ...callowance[field],
          value: callowance[field]["isPercent"] ? percentValue : value,
        };
      } else {
        callowance[field] = {
          value: percentValue,
          isPercent: true,
        };
      }
      tempPercent["customAllowances"] = { ...callowance };
    } else {
      return;
    }
    let isApplicable = calculateIsApplicable(tempPercent);
    if (!isApplicable) {
      message.warning("The value exceeded the remaining percentage");
      return;
    }
    setPercentageStructure({
      ...tempPercent,
    });
  };

  const updateIsPercentage = (field, value, type) => {
    if (isPercentageStructure) {
      let tempPercent = { ...percentageStructure };
      if (type === 0) {
        let allowance = {
          ...tempPercent.allowances,
        };
        allowance[field]["isPercent"] = value;
        allowance[field]["value"] = value ? 0 : allowance[field]["value"];
        tempPercent["allowances"] = { ...allowance };
      } else if (type === 1) {
        let callowance = {
          ...tempPercent.customAllowances,
        };
        callowance[field]["isPercent"] = value;
        callowance[field]["value"] = value ? 0 : callowance[field]["value"];
        tempPercent["customAllowances"] = { ...callowance };
      } else {
        return;
      }

      setPercentageStructure({
        ...tempPercent,
      });
    }
  };
  useEffect(() => {
    document.title = 'Settings | Groups Management';
  });
  useEffect(() => {
    groups.forEach((data) => {
      data["showAllowance"] = false;
    });
    setGroupsList(groups);
  }, [groups]);

  useEffect(() => {
    if (user.moolSettings) {
      setIsPercentageStructure(user.moolSettings.isPercentageStructure);
    }
    let tempAllowanceObject = {};
    let tempcustomAllowanceObject = {};
    let allowancesObject = organisationSettings.allowances
      ? organisationSettings.allowances
      : {};
    let _customAllowanceObject = organisationSettings.customAllowances
      ? organisationSettings.customAllowances
      : {};
    Object.keys(allowancesObject).forEach((data) => {
      if (allowancesObject[data].applicable) {
        let tempObj = allowancesObject[data];
        tempObj["checked"] = false;
        tempAllowanceObject[data] = tempObj;
      }
    });
    Object.keys(_customAllowanceObject).forEach((data) => {
      if (_customAllowanceObject[data].applicable) {
        let tempObj = _customAllowanceObject[data];
        tempObj["checked"] = false;
        tempcustomAllowanceObject[data] = tempObj;
      }
    });
    setAllowanceObject(tempAllowanceObject);
    setCustomAllowanceObject(tempcustomAllowanceObject);
    let tempSetttingObj = { ...organisationSettings };
    delete tempSetttingObj.allowances;
    delete tempSetttingObj.customAllowances;
  }, [organisationSettings, user]);

  const showGroupEditModal = (groupData) => {
    setDefaultEditData(groupData);
    setShowEditModal(true);
  };

  const showUserGroupModal = (groupData) => {
    const { groupId } = groupData;
    setIsLoading(true)
    getEmployeesByGroupId(groupId).then(res => {
      setDefaultEmployeeData(res.data);
    });
    setEditGroupsDetails(groupData)
    setIsLoading(false)
    setShowUserData(true);
  };

  const closeShowGroupEditModal = () => {
    setEditGroupsDetails({
      groupId: "",
      name: "",
      allowances: {},
      customAllowances: {},
      groupSettings: {},
      overtimeSetting: {
        component: "Basic",
        factor: 1
      }
    });
    setPercentageStructure({
      basic: 0,
      hra: 0,
      buffer: 0.15,
      specialAllowances: 0,
      allowances: {},
      customAllowances: {},
      basicHRAPercentage: {
        basic: true,
        hra: true,
      },
    });
    setShowEditModal(false);
  };
  const closeAssignModal = () => {
    setEditGroupsDetails({
      groupId: "",
      name: "",
      allowances: {},
      customAllowances: {},
      groupSettings: {},
      overtimeSetting: {
        component: "Basic",
        factor: 1
      }
    });
    setCurrentEmployee("");
    setAssignClicked(false);
    setShowUserData(false)
  };

  const setDefaultEditData = (groupData) => {
    let tempAllowanceObject = JSON.parse(JSON.stringify(allowanceObject));
    let tempcustomAllowanceObject = JSON.parse(
      JSON.stringify(customAllowanceObject)
    );
    let allowancesKeys = Object.keys(groupData.allowances);
    let customAllowancesKeys = Object.keys(groupData.customAllowances);
    for (let x in allowancesKeys) {
      if (tempAllowanceObject[allowancesKeys[x]]) {
        tempAllowanceObject[allowancesKeys[x]].checked = true;
      }
    }
    for (let x in customAllowancesKeys) {
      if (tempcustomAllowanceObject[customAllowancesKeys[x]]) {
        tempcustomAllowanceObject[customAllowancesKeys[x]].checked = true;
      }
    }
    setEditGroupsDetails({
      groupId: groupData.groupId,
      name: groupData.name,
      allowances: JSON.parse(JSON.stringify(tempAllowanceObject)),
      customAllowances: JSON.parse(JSON.stringify(tempcustomAllowanceObject)),
      groupSettings: JSON.parse(JSON.stringify(groupData.groupSettings)),
      overtimeSetting: JSON.parse(JSON.stringify(groupData.overtimeSetting ?? {
        component: "Basic",
        factor: 1
      })),
    });
    if (isPercentageStructure) {
      setPercentageStructure({
        ...groupData.percentage,
        basicHRAPercentage: {},
      });
    }
  };

  const openAddNewGroupModal = () => {
    setNewGroupsDetails({
      ...newGroupsDetails,
      name: "",
      allowances: JSON.parse(JSON.stringify(allowanceObject)),
      customAllowances: JSON.parse(JSON.stringify(customAllowanceObject)),
      groupSettings: JSON.parse(JSON.stringify(defaultGroupSettings)),
      overtimeSetting: JSON.parse(JSON.stringify({
        component: "Basic",
        factor: 1
      })),
    });
    setActive(true);
  };

  const generateGroupObject = (groupDetails) => {
    return new Promise((resolve, _reject) => {
      let data = groupDetails;
      let tempObj = {};
      tempObj["name"] = data.name;
      tempObj["employeeList"] = {};
      tempObj["allowances"] = {};
      for (let x in data.allowances) {
        if (data.allowances[x].checked) {
          delete data.allowances[x].checked;
          tempObj["allowances"][x] = data.allowances[x];
        }
      }
      tempObj["customAllowances"] = {};
      for (let x in data.customAllowances) {
        if (data.customAllowances[x].checked) {
          delete data.customAllowances[x].checked;
          tempObj["customAllowances"][x] = data.customAllowances[x];
        }
      }
      tempObj["groupSettings"] = data.groupSettings;
      tempObj["overtimeSetting"] = data.overtimeSetting;
      resolve(tempObj);
    });
  };

  const updateOnboarding = async (type) => {
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployerOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id,
      });
      organisationReducer.updateEmployerOnBoarding({
        organisationId: user.organisationId,
        id: user._id,
      });
    }
  };

  const onSaveGroup = async () => {
    let tempObj = await generateGroupObject(newGroupsDetails);
    if (!tempObj.name) {
      notification.error({ message: "Group name cannot be empty" });
      return;
    }
    tempObj.groupSettings.payrollFreezeDate =
      organisationSettings.payrollFreezeDate;
    tempObj.groupSettings.variablePayoutMonth =
      organisationSettings.variablePayoutMonth;
    let tempPercent = {};
    if (isPercentageStructure) {
      if (!validatePercentageStructure(percentageStructure)) {
        notification.error({
          message: "Selected section should have some value",
        });
        return;
      }
      tempPercent = { ...percentageStructure };
      // Make remaing percentage to whole numbers;
      // tempPercent = makePercentageWhole(tempPercent);
      let percentTotal = calculatePercentage(percentageStructure);
      let remaing = Math.round((1 - percentTotal) * 1000) / 1000;
      tempPercent.specialAllowances = remaing > 0 ? remaing : 0;
    }
    tempObj.percentageStructure = tempPercent;
    setIsLoading(true);
    let response = await createNewGroup(tempObj);
    if (response.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has created a group`,
        category: 14,
      });
      organisationReducer.updateGroupsList();
      await updateOnboarding("groups");
      notification.success({ message: "Successfully created new group" });
    } else {
      notification.error({
        message: "Failed to create new group, Please try again",
      });
    }
    resetPercentage();
    setIsLoading(false);
  };

  const onDeleteGroup = async () => {
    setIsLoading(true);
    let response = await deleteGroup(deleteGroupDetails.groupId);
    if (response.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has deleted a group`,
        category: 14,
      });
      organisationReducer.updateGroupsList();
      notification.success({ message: "Successfully deleted the group" });
      closeDeleteGroupModal();
    } else {
      notification.error({
        message: "Failed to delete the group , please try again",
      });
    }
    setIsLoading(false);
  };

  const closeDeleteGroupModal = () => {
    setDeleteGroupDetails({});
    setShowDeleteGroupModal(false);
  };

  const openDeleteGroupModal = (groupDetails) => {
    setDeleteGroupDetails(groupDetails);
    setShowDeleteGroupModal(true);
  };

  const handleGroupUpdate = () => {
    const { groupId, groupSettings, overtimeSetting } = editGroupsDetails;
    updateGroupInfo({ groupId, groupSettings, overtimeSetting }).then(res => {
      if (res.data.success) {
        notification.success({ message: "Successfully updated group details!" })
        organisationReducer.updateGroupsList();
      }
      else {
        notification.error({ message: "Sorry! Unable to update the group" })
      }
    }).catch((err) => {
      notification.error({ message: "Sorry! Unable to update the group" })
    })
    setEditClicked(false)
    setShowEditModal(false)
  }

  const resetPercentage = () => {
    setPercentageStructure({
      basic: 0,
      hra: 0,
      buffer: 0.15,
      specialAllowances: 0,
      allowances: {},
      customAllowances: {},
      basicHRAPercentage: {
        basic: true,
        hra: true,
      },
    });
    setActive(false);
  };

  return (
    <div className="site-layout">
      <HeaderComponent
        show={false}
        name="Groups Management"
      />
      <div className="app-main-content-container paper-wrapper">
        {active && (
          <Spin spinning={isLoading} size="large">
            <CreateGroup
              setActive={setActive}
              setPercentageStructure={setPercentageStructure}
              isPercentageStructure={isPercentageStructure}
              percentageStructure={percentageStructure}
              onSaveGroup={onSaveGroup}
              allowance={allowanceObject}
              customAllowances={customAllowanceObject}
              newGroupsDetails={newGroupsDetails}
              setNewGroupsDetails={setNewGroupsDetails}
              updateIsPercentage={updateIsPercentage}
              updatePercentageStructure={updatePercentageStructure}
              calculatePercentage={calculatePercentage}
            />
          </Spin>
        )}
        {!active && (
          <div>
            <div className="flex justify-between">
              <div>
                <h1 className="h1-bold-24">Groups</h1>
                <p className="tab-subtitle-1">
                  Segregate your employees into different groups based on allowances
                </p>
              </div>
              <div>.</div>
            </div>
            <div>
              <div className="flex justify-between align-center m-bottom-40">
                <p className="h5-bold-20">Type</p>
                <NormalButton
                  type={13}
                  icon={plusSymbol}
                  buttonText="Create new"
                  onClick={() => {
                    openAddNewGroupModal();
                  }}
                />
              </div>
              <div className=" groups-card my-16">
                <div className="flex justify-between align-center">
                  <p className="group-name">General</p>
                  <NormalButton
                    type={4}
                    icon={blueRightArrowIcon}
                    padding={[8, 8]}
                    onClick={() =>
                      setShowGeneralGroupDetails(!showGeneralGroupDetails)
                    }
                  />
                </div>
                {showGeneralGroupDetails && (
                  <div>
                    <div className="group-data-section">
                      <p className="group-data-section-heading">
                        Group Settings
                      </p>
                      <div>
                        <Row gutter={[100, 2]}>
                          <Col xs={24} sm={24} md={12} xl={12}>
                            <div className="flex justify-between">
                              <p className="group-setting-name">PF Enabled</p>
                              <p className="group-setting-value">
                                {organisationSettings.pfEnabled ? "Yes" : "No"}
                              </p>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={12} xl={12}>
                            <div className="flex justify-between">
                              <p className="group-setting-name">
                                Variable above CTC
                              </p>
                              <p className="group-setting-value">
                                {organisationSettings.variableAboveCTC
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className=" groups-card my-16">
                <div className="flex justify-between align-center">
                  <p className="group-name">Intern / Contractor</p>
                  <NormalButton
                    type={4}
                    icon={blueRightArrowIcon}
                    padding={[8, 8]}
                    onClick={() => setShowDefaultGroup(!showDefaultGroup)}
                  />
                </div>
                {showDefaultGroup && (
                  <div>
                    <div className="group-data-section">
                      <p className="group-data-section-heading">
                        Group Settings
                      </p>
                      <div>
                        <Row gutter={[100, 2]}>
                          <Col xs={24} sm={24} md={12} xl={12}>
                            <div className="flex justify-between">
                              <p className="group-setting-name">PF Enabled</p>
                              <p className="group-setting-value">No</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={12} xl={12}>
                            <div className="flex justify-between">
                              <p className="group-setting-name">
                                Variable above CTC
                              </p>
                              <p className="group-setting-value">No</p>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={12} xl={12}>
                            <div className="flex justify-between">
                              <p className="group-setting-name">
                                Professional Tax
                              </p>
                              <p className="group-setting-value">No</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {groupsList.map((data, i) => {
                return (
                  <div className=" groups-card my-16" key={data.name}>
                    <div className="flex justify-between align-center">
                      <p className="group-name">{data.name}</p>
                      <div className="button-container">
                        <NormalButton
                          type={4}
                          icon={userIcon}
                          padding={[8, 8]}
                          onClick={() => {
                            showUserGroupModal(data);
                          }}
                        />
                        <NormalButton
                          type={4}
                          icon={blueEdiIcon}
                          padding={[8, 8]}
                          onClick={() => {
                            showGroupEditModal(data);
                          }}
                        />
                        <NormalButton
                          type={4}
                          icon={blueDeleteIcon}
                          padding={[8, 8]}
                          onClick={() => openDeleteGroupModal(data)}
                        />
                        <NormalButton
                          type={4}
                          icon={
                            data.showAllowance
                              ? blueDownArrowIcon
                              : blueRightArrowIcon
                          }
                          padding={[8, 8]}
                          onClick={() => {
                            let tempGroupList = groupsList;
                            tempGroupList[i].showAllowance = !tempGroupList[i]
                              .showAllowance;
                            setGroupsList([...tempGroupList]);
                          }}
                        />
                      </div>
                    </div>
                    {data.showAllowance && (
                      <div>
                        <p>Group-ID:  <code style={{ fontWeight: "bold" }}>{data.groupId ? data.groupId : <i>General Groups ID is " "( empty string )</i>}</code> </p>
                        <div className="group-data-section">
                          <p className="group-data-section-heading">
                            Group Settings
                          </p>
                          <div>
                            <Row gutter={[100, 2]}>
                              <Col xs={24} sm={24} md={12} xl={12}>
                                <div className="flex justify-between">
                                  <p className="group-setting-name">
                                    PF Enabled
                                  </p>
                                  <p className="group-setting-value">
                                    {data.groupSettings.pfEnabled
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                              <Col xs={24} sm={24} md={12} xl={12}>
                                <div className="flex justify-between">
                                  <p className="group-setting-name">
                                    Variable above CTC
                                  </p>
                                  <p className="group-setting-value">
                                    {data.groupSettings.variableAboveCTC
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="group-data-section">
                          <p className="group-data-section-heading">
                            Overtime Settings
                          </p>
                          <div>
                            <Row gutter={[100, 2]}>
                              <Col xs={24} sm={24} md={12} xl={12}>
                                <div className="flex justify-between">
                                  <p className="group-setting-name">
                                    Calculated From
                                  </p>
                                  <p className="group-setting-value">
                                    {data.overtimeSetting && data.overtimeSetting.component
                                      ? data.overtimeSetting.component
                                      : "Basic"}
                                  </p>
                                </div>
                              </Col>
                              <Col xs={24} sm={24} md={12} xl={12}>
                                <div className="flex justify-between">
                                  <p className="group-setting-name">
                                    Overtime Factor
                                  </p>
                                  <p className="group-setting-value">
                                    {data.overtimeSetting && data.overtimeSetting.factor
                                      ? data.overtimeSetting.factor
                                      : 1}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="group-data-section">
                          <p className="group-data-section-heading">
                            Allowance
                          </p>
                          <div>
                            {Object.keys(data.customAllowances).map(
                              (keyName) => {
                                return (
                                  <div className="groups-allowances">
                                    {data.customAllowances[keyName].label}
                                  </div>
                                );
                              }
                            )}
                            {Object.keys(data.allowances).map((keyName) => {
                              return (
                                <div className="groups-allowances">
                                  {data.allowances[keyName].label}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              {showEditModal && (
                <Modal
                  visible={showEditModal}
                  onCancel={closeShowGroupEditModal}
                  closable={false}
                  footer={false}
                  centered={true}
                  width={800}
                >
                  <Spin size="large" spinning={isLoading}>
                    <div>
                      <div className="flex justify-between align-center">
                        <h1 className="h5-bold-20 m-bottom-20">
                          Group Details of {editGroupsDetails.name}
                        </h1>
                        {!editClicked ?
                          <NormalButton
                            onClick={(e) => {
                              setEditClicked(true)
                            }}
                            buttonText={<><img src={blueEditIcons} alt="blueEdit" /> Edit</>}
                          /> :
                          <div className="flex">
                            <NormalButton
                              type={13}
                              onClick={handleGroupUpdate}
                              buttonText="Save"
                            />
                            <NormalButton
                              buttonText="Cancel"
                              type={5}
                              onClick={() => { setEditClicked(false) }}
                            />
                          </div>
                        }
                      </div>
                      <div className="flex justify-between">
                        <div className="group-modal-setting-container">
                          <p className="subtitle1-med-16 font-size-20 m-bottom-20">
                            Settings
                          </p>
                          <div className="flex justify-between m-bottom-20">
                            <p className="app-label-1">Enable Gratuity</p>
                            {
                              !editClicked ?
                                <p>
                                  {editGroupsDetails.groupSettings.gratuity
                                    ? "Yes"
                                    : "No"}
                                </p>
                                :
                                <Checkbox
                                  checked={editGroupsDetails.groupSettings.gratuity}
                                  onChange={(e) => {
                                    setEditGroupsDetails({
                                      ...editGroupsDetails,
                                      groupSettings: {
                                        ...editGroupsDetails.groupSettings,
                                        gratuity: e.target.checked
                                      }
                                    })
                                  }}
                                >
                                  Enable Gratuity
                                </Checkbox>
                            }
                          </div>
                          {editClicked && editGroupsDetails.groupSettings.gratuity && (
                            <div className="flex justify-between align-center">
                              <InputFieldComponent
                                value={editGroupsDetails.groupSettings.gratuityTenure}
                                type={"Number"}
                                labeltext="Gratuity Tenure"
                                width={"330px"}
                                onChange={(e) => {
                                  setEditGroupsDetails({
                                    ...editGroupsDetails,
                                    groupSettings: {
                                      ...editGroupsDetails.groupSettings,
                                      gratuityTenure: Number(e.target.value)
                                    }
                                  });
                                }}
                              />
                            </div>
                          )}
                          <div className="flex justify-between m-bottom-20">
                            <p className="app-label-1">Enable PF</p>
                            {
                              !editClicked ?
                                <p>
                                  {editGroupsDetails.groupSettings.pfEnabled
                                    ? "Yes"
                                    : "No"}
                                </p>
                                :
                                <Checkbox
                                  checked={editGroupsDetails.groupSettings.pfEnabled}
                                  onChange={(e) => {
                                    setEditGroupsDetails({
                                      ...editGroupsDetails,
                                      groupSettings: {
                                        ...editGroupsDetails.groupSettings,
                                        pfEnabled: e.target.checked
                                      }
                                    })
                                  }}
                                >
                                  Enable PF
                                </Checkbox>
                            }
                          </div>
                          <div className="flex justify-between m-bottom-20">
                            <p className="app-label-1">Variable above CTC</p>
                            {
                              !editClicked ?
                                <p>
                                  {editGroupsDetails.groupSettings.variableAboveCTC
                                    ? "Yes"
                                    : "No"}
                                </p>
                                :
                                <Checkbox
                                  checked={editGroupsDetails.groupSettings.variableAboveCTC}
                                  onChange={(e) => {
                                    setEditGroupsDetails({
                                      ...editGroupsDetails,
                                      groupSettings: {
                                        ...editGroupsDetails.groupSettings,
                                        variableAboveCTC: e.target.checked
                                      }
                                    })
                                  }}
                                >
                                  Variable above CTC
                                </Checkbox>
                            }
                          </div>
                          <h3 className=" m-bottom-10">Overtime Settings</h3>
                          <div className="flex justify-between m-bottom-10">
                            {
                              !editClicked ?
                                <>
                                  <p className="app-label-1">Overtime Calculated From</p>
                                  <p>
                                    {editGroupsDetails.overtimeSetting && editGroupsDetails.overtimeSetting.component ? editGroupsDetails.overtimeSetting.component : "Basic"}
                                  </p>
                                </>
                                :
                                <DropdownComponent
                                  className="block app-select m-top-4"
                                  value={editGroupsDetails.overtimeSetting && editGroupsDetails.overtimeSetting.component ? editGroupsDetails.overtimeSetting.component : "Basic"}
                                  values={["Basic", "Gross"]}
                                  options={["Basic", "Gross"]}
                                  width="330px"
                                  labeltext={"Overtime Calculated From"}
                                  onChange={(e) => {
                                    setEditGroupsDetails({
                                      ...editGroupsDetails,
                                      overtimeSetting: {
                                        ...editGroupsDetails.overtimeSetting,
                                        component: e ? e : "Basic"
                                      }
                                    });
                                  }}
                                >
                                </DropdownComponent>
                            }
                          </div>
                          <div className="flex justify-between m-bottom-20">
                            {
                              !editClicked ?
                                <>
                                  <p className="app-label-1">Overtime Factor</p>
                                  <p>
                                    {editGroupsDetails.overtimeSetting && editGroupsDetails.overtimeSetting.factor ? editGroupsDetails.overtimeSetting.factor : 1}
                                  </p>
                                </>

                                :
                                <InputFieldComponent
                                  className="general-ant-field-input leave-input"
                                  bordered={false}
                                  labeltext={"Factor"}
                                  type={"number"}
                                  width="330px"
                                  value={editGroupsDetails.overtimeSetting && editGroupsDetails.overtimeSetting.factor ? editGroupsDetails.overtimeSetting.factor : 0}
                                  onChange={(e) => {
                                    setEditGroupsDetails({
                                      ...editGroupsDetails,
                                      overtimeSetting: {
                                        ...editGroupsDetails.overtimeSetting,
                                        factor: isNaN(parseInt(e.target.value))
                                          ? 0
                                          : parseInt(e.target.value),
                                      }
                                    });
                                  }}
                                ></InputFieldComponent>
                            }
                          </div>
                        </div>
                        <div className="full-separator"></div>
                        <div className="group-modal-allowance-container">
                          <p className="subtitle1-med-16">Allowances</p>
                          {isPercentageStructure && (
                            <div className="flex justify-between m-bottom-20">
                              <p className="app-label-1">Basic</p>
                              <p>
                                {Math.round(
                                  percentageStructure.basic *
                                  (percentageStructure.basicHRAPercentage
                                    .basic
                                    ? 100
                                    : 1)
                                )}{" "}
                                {percentageStructure.basicHRAPercentage.basic
                                  ? `% Of CTC`
                                  : "Per Month"}
                              </p>
                            </div>
                          )}
                          {isPercentageStructure && (
                            <div className="flex justify-between m-bottom-20">
                              <p className="app-label-1">
                                House Rent Allowances
                              </p>
                              <p>
                                {Math.round(
                                  percentageStructure.hra *
                                  (percentageStructure.basicHRAPercentage.hra
                                    ? 100
                                    : 1)
                                )}{" "}
                                {percentageStructure.basicHRAPercentage.hra
                                  ? `% Of CTC`
                                  : "Per Month"}
                              </p>
                            </div>
                          )}
                          {isPercentageStructure && (
                            <div className="flex justify-between m-bottom-20">
                              <p className="app-label-1">Buffer </p>
                              <p>
                                {Math.round(percentageStructure.buffer * 100)} %
                                of CTC
                              </p>
                            </div>
                          )}
                          {isPercentageStructure && (
                            <div className="flex justify-between m-bottom-20">
                              <p className="app-label-1">Special Allowance</p>
                              <p>
                                {Math.round(
                                  (1 -
                                    calculatePercentage(percentageStructure)) *
                                  100
                                )}{" "}
                                % of CTC
                              </p>
                            </div>
                          )}
                          <div className="my-16 allowance-modal-list">
                            {Object.keys(
                              editGroupsDetails.customAllowances
                            ).map((data, i) => {
                              return (
                                <div
                                  className="flex justify-between m-bottom-20"
                                  key={`allowance-item-${i}`}
                                >
                                  <p className="app-label-1">
                                    {
                                      editGroupsDetails.customAllowances[data]
                                        .label
                                    }
                                  </p>
                                  <p>
                                    {editGroupsDetails.customAllowances[data]
                                      .checked ? (
                                      <span></span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        {" "}
                                        Disabled{" "}
                                      </span>
                                    )}{" "}
                                    {isPercentageStructure &&
                                      editGroupsDetails.customAllowances[data]
                                        .checked &&
                                      `${Math.round(
                                        percentageStructure.customAllowances[
                                          data
                                        ].isPercent
                                          ? percentageStructure
                                            .customAllowances[data].value *
                                          100
                                          : percentageStructure
                                            .customAllowances[data].value
                                      )} ${percentageStructure.customAllowances[
                                        data
                                      ].isPercent
                                        ? "% of CTC"
                                        : "Per Month"
                                      }`}
                                  </p>
                                </div>
                              );
                            })}
                            {Object.keys(editGroupsDetails.allowances).map(
                              (data, i) => {
                                return (
                                  <div
                                    className="flex justify-between m-bottom-20"
                                    key={`allowance-item-${i}`}
                                  >
                                    <p className="app-label-1">
                                      {editGroupsDetails.allowances[data].label}
                                    </p>
                                    <p>
                                      {editGroupsDetails.allowances[data]
                                        .checked ? (
                                        <span></span>
                                      ) : (
                                        <span style={{ color: "red" }}>
                                          {" "}
                                          Disabled{" "}
                                        </span>
                                      )}
                                      {isPercentageStructure &&
                                        editGroupsDetails.allowances[data]
                                          .checked &&
                                        `${Math.round(
                                          percentageStructure.allowances[data]
                                            .isPercent
                                            ? percentageStructure.allowances[
                                              data
                                            ].value * 100
                                            : percentageStructure.allowances[
                                              data
                                            ].value
                                        )} ${percentageStructure.allowances[data]
                                          .isPercent
                                          ? "% of CTC"
                                          : "Per Month"
                                        }`}
                                    </p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>

                      <div className={"text-align-right"}>
                        <NormalButton
                          buttonText="Close"
                          type={5}
                          onClick={closeShowGroupEditModal}
                        />
                      </div>
                    </div>
                  </Spin>
                </Modal>
              )}
              {showUserData && (
                <Modal
                  visible={showUserData}
                  onCancel={closeAssignModal}
                  closable={false}
                  footer={false}
                  centered={true}
                  width={800}
                >
                  <Spin size="large" spinning={isLoading}>
                    <div>
                      <div className="flex justify-between align-center">
                        <h1 className="h5-bold-20 m-bottom-20">
                          Group Details of {editGroupsDetails.name}
                        </h1>
                        {!assignClicked ?
                          <NormalButton
                            onClick={(e) => {
                              setAssignClicked(true)
                            }}
                            buttonText={<><UserAddOutlined /> Assign New</>}
                          /> :
                          <div className="flex justify-between align-center" >
                            <DropdownComponent
                              labeltext={"Employee"}
                              options={[...employeeList.map(item => item.displayName)]}
                              values={[...employeeList.map(item => item._id)]}
                              value={currentEmployee}
                              onChange={(e) => { setCurrentEmployee(e); }}
                              width="250px"
                            />
                            <NormalButton
                              buttonText="Assign"
                              onClick={() => { assignEmployeeInGroup(editGroupsDetails.groupId) }}
                              type={13}
                              margin={[0, 10]}
                              padding={[10, 12]}
                              block={true}
                            />
                            <NormalButton
                              buttonText="Cancel"
                              onClick={() => { setAssignClicked(false) }}
                              type={2}
                              margin={[0, 5]}
                              padding={[10, 12]}
                              block={true}
                            />
                          </div>
                        }
                      </div>
                      <div className="m-top-10">
                        {
                          defaultEmployeeData && defaultEmployeeData.map(user => {
                            return (
                              <div className="flex justify-between align-center m-bottom-20" key={user._id} >
                                <div>
                                  <div className="flex align-center">
                                    <p className="h5-bold-20">{user.displayName}</p>
                                    <code style={{ margin: "4px 0  0 10px" }} className="code-id">{user.username}</code>
                                  </div>
                                  <p className="m-top-8"> PAN - {user.PAN}</p>
                                </div>
                                <Popconfirm title="Are You Sure to De-link this user?" onConfirm={() => { assignEmployeeInGroup("") }} >
                                  <UserDeleteOutlined style={{ color: "#f26b6b" }} onClick={() => { setCurrentEmployee(user._id); }} />
                                </Popconfirm>
                              </div>
                            )
                          })
                        }
                      </div>

                      <div className={"text-align-right"}>
                        <NormalButton
                          buttonText="Close"
                          type={5}
                          onClick={closeAssignModal}
                        />
                      </div>
                    </div>
                  </Spin>
                </Modal>
              )}
              {showDeleteGroupModal && (
                <Modal
                  visible={showDeleteGroupModal}
                  onCancel={closeDeleteGroupModal}
                  closable={false}
                  footer={false}
                  centered={true}
                >
                  <Spin size="large" spinning={isLoading}>
                    <div>
                      <h1 className="app-heading-2 m-bottom-36">
                        Delete Group
                      </h1>
                      {deleteGroupDetails.employeeList &&
                        Object.keys(deleteGroupDetails.employeeList).length ? (
                        <>
                          <div className="warning-text p-bottom-36">
                            Sorry the "{deleteGroupDetails.name}" group cannot
                            be deleted because employees are currently mapped to
                            this group ?
                          </div>
                          <div className="m-top-36 text-align-right">
                            <NormalButton
                              buttonText="Cancel"
                              type={5}
                              onClick={closeDeleteGroupModal}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="error-text p-bottom-36">
                            Are you sure you want to delete the "
                            {deleteGroupDetails.name}" group ?
                          </div>
                          <div className="m-top-36 text-align-right">
                            <NormalButton
                              buttonText="Cancel"
                              type={5}
                              onClick={closeDeleteGroupModal}
                            />
                            <NormalButton
                              buttonText="Delete"
                              type={3}
                              onClick={onDeleteGroup}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Spin>
                </Modal>
              )}
            </div>
          </div>
        )}
      </div>
    </div >
  );
};

const GroupsContainer = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    deviceWidth: state.responsive.deviceWidth,
    organisationSettings: state.organisation.organisationSettings,
    orgLoading: state.organisation.loading,
    groups: state.organisation.groups,
    organisationFreeze: state.organisation.organisationFreeze,
    complainceCredentials: state.organisation.complainceCredentials,
    onboarding: state.organisation.onboarding,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(Groups);

export default GroupsContainer;
