import {
  Col, Row, Select, Skeleton
} from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import axios from "../../../apis";
import { getPayslipHTML, getPayslipsForEmployee } from "../../../apis/organisation";
import config, { monthList, rootUrl } from "../../../utils/config";
import { checkFinancialYear } from "../../../utils/dateCheckUtil";
import { NormalButton } from "../../ComponentLibrary";

const { Option } = Select;

const PayslipAndForms = ({ payslips, user, setPayslips }) => {

  const [showModal, setShowModal] = useState(false);
  const [clickedPayslip, setClickedPayslip] = useState(null);
  const [payslipHtml, setPayslipHtml] = useState(null);
  const [newPayslips, setNewPayslips] = useState([]);



  useEffect(
    () => {
      // Sort payslip by month
      let newPayslipsData = [];
      for (let i = 3; i < 12; i++) {
        const payslip = payslips.filter((a) => a.month === i);
        if (payslip.length) {
          newPayslipsData.push(...payslip)
        }
      }
      for (let i = 0; i < 3; i++) {
        const payslip = payslips.filter((a) => a.month === i);
        if (payslip.length) {
          newPayslipsData.push(...payslip)
        }
      }
      setNewPayslips(newPayslipsData)
    },
    [payslips]
  );

  const handleViewClick = async (payslipData) => {
    setShowModal(true);
    setClickedPayslip(payslipData);
    let { financialYear, month } = payslipData
    let payslipHTML = await getPayslipHTML(`${user._id}/${month}/${financialYear}`);
    if (payslipHTML.status !== 200) {
      setShowModal(false);
      setPayslipHtml(null);
      setClickedPayslip(null);
      return;
    }
    setPayslipHtml(payslipHTML.data);
  };

  const handleAction = async (url) => {
    let res = await axios({
      url, //your url
      method: 'GET',
      responseType: 'blob',
    })
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = urlDownload;
    link.setAttribute('download', `payslip-${monthList[clickedPayslip.month]}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  const [currFinancialYear, setCurrFinancialYear] = useState(
    checkFinancialYear().financialYear
  );
  const [financialYearList, setFinancialYearList] = useState([]);
  useEffect(() => {
    function calculateFinancialYear() {
      let startFYear = 2020;
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let resFinancialYearList = [];
      for (let i = startFYear; i < currentYear + 1; i++) {
        let nextYear = i + 1;
        let tempFYear = `${i}-${nextYear}`;
        resFinancialYearList.push(tempFYear);
      }
      resFinancialYearList.reverse();
      setFinancialYearList([...resFinancialYearList]);
    }
    calculateFinancialYear();
  }, []);

  const setPayslipForNewFY = (financialYear) => {
    getPayslipsForEmployee({ id: user._id, financialYear }).then((res) => {
      setPayslips(res.data);
    }).catch(err => {
      setPayslips([]);
    })
  }

  return (
    <div className="employee-details-container">
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="information-section">
            <div className="section-header">
              <p className="information-category-heading">Payslip</p>
              <div
                className="flex align-center justify-end"
                style={{ marginBottom: "2rem" }}
              >
                <h3 className="font-weight-700 text-size-21 mb-2 ml-5">Financial Year</h3>
                <Select
                  defaultValue={currFinancialYear}
                  className="general-ant-field-select ml-2"
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    background: "none",
                    padding: 0
                  }}
                  size="large"
                  onChange={e => {
                    setPayslipForNewFY(e)
                    setCurrFinancialYear(e);
                  }}
                  bordered={false}
                >
                  {financialYearList.map((val, index) =>
                    <Option key={`opt-${index}`} value={val}>
                      <h3>{val}</h3>
                    </Option>
                  )}
                </Select>
              </div>
            </div>
            <div className="monthly-payslip-container">
              <Skeleton loading={!payslips} active>
                {newPayslips.length > 0 &&
                  newPayslips.map((val) => {
                    return (
                      <div className="monthly-payslips" key={val.month}>
                        <span>
                          {config.monthList[val.month] +
                            " " +
                            val.financialYear}
                        </span>
                        <NormalButton
                          buttonText={"View"}
                          type={13}
                          onClick={() => handleViewClick(val)}
                          className="pay-silp-view-button"
                        />

                      </div>
                    );
                  })}
              </Skeleton>
            </div>
          </div>
          <Modal
            footer={null}
            centered
            visible={showModal}
            width={800}
            onCancel={() => {
              setShowModal(false);
              setPayslipHtml(null);
            }}
          >
            <NormalButton type={13} buttonText="Download Payslip" onClick={() => handleAction(`${rootUrl}/api/payslip/pdf/${clickedPayslip.user._id}/${clickedPayslip.financialYear}/${clickedPayslip.month}`)} className="p-4 m-top-8" />

            <div
              dangerouslySetInnerHTML={{ __html: payslipHtml }}
              id="current_payslip"
            ></div>
          </Modal>
        </Col>
        <p className="divider"></p>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="information-section">
            <div className="section-header">
              <p className="information-category-heading">Forms</p>
            </div>
            <div className="monthly-payslip-container">
              <p>No Form16 document is available for you right now</p>
            </div>
            <p className="divider"></p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PayslipAndForms;