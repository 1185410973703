import { message, Modal, notification, Radio, Switch } from 'antd';
import React, { useEffect, useState } from 'react'
import { HeaderComponent } from '../../../Common';
import { connect } from "react-redux";
import organisation from "../../../../redux/modules/organisation";
import config from "../../../../utils/config";
import editIcon from "../../../../assets/icons/edit.svg";
import radioOptions from '../../../../constants/radioOptions';
import { NormalButton } from '../../../ComponentLibrary';
import { getTermsAndConditions, saveTermsAndConditions, updateESIC_Configurations } from '../../../../apis/organisation';
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"

const ESICConfiguration = ({ user, organisationSettings, organisationReducer, isTrial }) => {
    const [allowances, setAllowance] = useState({});
    const [editable, setEditable] = useState(false);
    const [showData, setShowData] = useState(false);
    const [acceptESICManual, setAcceptESICManual] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [customAllowancesForDisplay, setCustomAllowancesForDisplay] = useState({});
    const [esicConfiguration, setESICConfiguration] = useState({
        esicEnabled: false,
        isESICConfiguredManual: false,
        esicConfigurations: {}
    });
    const [esicConfigurationCopy, setESICConfigurationCopy] = useState({
        esicEnabled: false,
        isESICConfiguredManual: false,
        esicConfigurations: {}
    });
    useEffect(() => {
        if (user.organisationId) {
            getTermsAndConditions({ organisationId: user.organisationId, keyName: "manualEsic" }).then(res => {
                if (res.data.success) {
                    setAcceptESICManual(res.data.data)
                } else {
                    setAcceptESICManual(false)
                }
            }).catch(err => setAcceptESICManual(false))
        }
    }, [user, showData])
    useEffect(
        () => {
            const {
                customAllowances: cusAll,
                esicEnabled,
                esicConfigurations,
                isESICConfiguredManual
            } = organisationSettings;
            let allow =
                Object.keys(organisationSettings.allowances).length > 0
                    ? organisationSettings.allowances
                    : config.allowances;
            setAllowance({ ...allow });
            setCustomAllowancesForDisplay({ ...cusAll });
            const configurations = {}
            Object.keys(allow).forEach(item => configurations[item] = false);
            Object.keys(cusAll).forEach(item => configurations[item] = false);
            if (esicConfigurations && Object.keys(esicConfigurations).length > 0) {
                setESICConfiguration({
                    esicEnabled,
                    esicConfigurations,
                    isESICConfiguredManual
                });
                setESICConfigurationCopy({
                    esicEnabled,
                    esicConfigurations,
                    isESICConfiguredManual
                });
            }
            else {
                setESICConfiguration({
                    ...esicConfiguration, esicEnabled: esicEnabled ?? false, isESICConfiguredManual: isESICConfiguredManual ?? false, esicConfigurations: configurations ?? {}
                })
                setESICConfigurationCopy({
                    ...esicConfiguration, esicEnabled: esicEnabled ?? false, isESICConfiguredManual: isESICConfiguredManual ?? false, esicConfigurations: configurations ?? {}
                })
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organisationSettings, user]
    );

    const updateAcceptTermsAndCondition = (value) => {
        saveTermsAndConditions({ organisationId: user.organisationId, keyName: "manualEsic", keyValue: value }).then(res => {
            if (res.data.success) {
                message.info("Status Recorded Successfully!")
                setShowData(!showData)
            }
        })
        setIsVisible(false);
    }
    const warning = () => {
        Modal.warning({
            visible: isVisible,
            title: 'Warning Message From Mool!',
            content: 'Mool will not be liable for compliances if the configuration is set to Manual mode. Do you agree?',
            okText: "I Accept",
            cancelText: "Cancel",
            closable: true,
            onOk: () => {
                updateAcceptTermsAndCondition(true)
            },
            onCancel: () => {
                setESICConfiguration({
                    ...esicConfiguration,
                    isESICConfiguredManual: false,
                })
            }
        });
    };
    const onChange = (key, e) => {
        setESICConfiguration({
            ...esicConfiguration,
            esicConfigurations: {
                ...esicConfiguration["esicConfigurations"],
                [key]: e
            }
        })
    }

    const onSave = () => {
        setEditable(false);
        updateESIC_Configurations({
            organisationId: user.organisationId,
            ...esicConfiguration
        }).then(res => {
            if (res.data.success) {
                notification.success({
                    message: "Configuration Updated Successfully!"
                })
            } else {
                notification.error({
                    message: "Can Not Update Configurations."
                })
            }
        }).catch(err => notification.error({
            message: "Error while updating"
        }))
    };

    const onCancel = () => {
        setESICConfiguration({
            ...esicConfigurationCopy
        })
        setEditable(false);
    };

    return (
        isTrial ? <TrialPage user={user} trialMessage={"Stay assured. Mool handles any compliance related changes in the law for you."} bgImage={configurationsBgImage}
        /> :
            <div className="site-layout">
                <HeaderComponent show={false}
                    name="ESIC Configuration" />
                <div className="app-main-content-container paper-wrapper">
                    <div className='flex justify-between align-center'>
                        <div>
                            <h1 className="h1-bold-24">ESIC Configuration</h1>
                            <p className="tab-subtitle-1">
                                Configure which heads are coming under ESIC.
                            </p>
                        </div>
                        {
                            !editable ? <NormalButton
                                type={13}
                                icon={editIcon}
                                buttonText="Edit"
                                onClick={() => setEditable(true)}
                            /> :
                                <div>
                                    <NormalButton
                                        type={5}
                                        buttonText="Cancel"
                                        onClick={onCancel}
                                    />
                                    <NormalButton buttonText="Save" type={13} onClick={onSave} />
                                </div>
                        }
                    </div>
                    <div>
                        <div className="flex justify-between align-center my-16 groups-card my-16">
                            <p className="font-size-global-18 font-weight-global-700">
                                Does your company enable ESIC?
                            </p>
                            {editable ? (
                                <div className="app-radio-container">
                                    <Radio.Group
                                        options={radioOptions.options}
                                        value={esicConfiguration.esicEnabled}
                                        onChange={(e) =>
                                            setESICConfiguration({
                                                ...esicConfiguration,
                                                esicEnabled: e.target.value
                                            })
                                        }
                                    />
                                </div>
                            ) : (
                                <div className="app-input-value ">
                                    {esicConfiguration.esicEnabled && "Yes"}
                                    {!esicConfiguration.esicEnabled && "No"}
                                </div>
                            )}
                        </div>
                        {esicConfiguration.esicEnabled &&
                            <div className="flex justify-between align-center my-16 groups-card my-16">
                                <p className="font-size-global-18 font-weight-global-700">
                                    Select Your ESIC Deduction Type ?
                                    <p className="app-label-4 font-weight-global-500">
                                        {esicConfiguration.isESICConfiguredManual ? "You are using Manual Mode!!!, Note: Mool isn't responsible for your compliances!" : "Auto configuration is compliances oriented!, Recommended by Mool!"}
                                    </p>
                                </p>

                                {editable ? (
                                    <div className="app-radio-container">
                                        <Radio.Group
                                            options={[
                                                { label: "Auto", value: false },
                                                { label: "Manual", value: true },
                                            ]}
                                            value={esicConfiguration.isESICConfiguredManual}
                                            onChange={(e) => {
                                                if (e.target.value && !acceptESICManual) {
                                                    setIsVisible(true)
                                                    warning();
                                                }
                                                setESICConfiguration({
                                                    ...esicConfiguration,
                                                    isESICConfiguredManual: e.target.value
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div className="app-input-value ">
                                        {esicConfiguration.isESICConfiguredManual && "Manual"}
                                        {!esicConfiguration.isESICConfiguredManual && "Auto"}
                                    </div>
                                )}
                            </div>
                        }
                        {
                            esicConfiguration.esicEnabled && esicConfiguration.isESICConfiguredManual &&
                            <div className='groups-card my-16'>
                                {customAllowancesForDisplay &&
                                    Object.keys(customAllowancesForDisplay).map(
                                        (allowance, _index) => {
                                            return customAllowancesForDisplay[allowance].applicable ? (
                                                <div className="flex justify-between align-center my-16">
                                                    <p className="app-label-1">
                                                        {customAllowancesForDisplay[allowance].label}
                                                    </p>
                                                    <p className="app-label-value-1 p-right-16">
                                                        <Switch disabled={!editable} checked={esicConfiguration["esicConfigurations"][allowance]} onChange={(e) => { onChange(allowance, e) }} />
                                                    </p>
                                                </div>
                                            ) : (
                                                ""
                                            );
                                        }
                                    )}
                                {Object.keys(allowances).map((allowance, _index) => {
                                    return (
                                        allowances[allowance].applicable && (
                                            <div className="flex justify-between align-center my-16">
                                                <p className="app-label-1">
                                                    {allowances[allowance].label}
                                                </p>
                                                <p className="app-label-value-1 p-right-16">
                                                    <Switch disabled={!editable} checked={esicConfiguration["esicConfigurations"][allowance]} onChange={(e) => { onChange(allowance, e) }} />
                                                </p>
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                        }
                    </div>
                </div>
            </div>
    );
}


const ESICConfigurationContainer = connect(
    (state) => ({
        user: state.auth.user,
        organisationSettings: state.organisation.organisationSettings,
        orgLoading: state.organisation.loading,
        isTrial: state.auth.user.isTrial,
    }),
    (dispatch) => ({
        organisationReducer: organisation.getActions(dispatch),
    })
)(ESICConfiguration);

export default ESICConfigurationContainer;