import axios from './index';

export async function check() {
  return axios.get('auth/check');
}

export async function register(data) {
  return axios.post('auth_check/register', data);
}

export async function login(data) {
  return axios.post('auth_check/login', data);
}

export async function googleLogin(data) {
  return axios.post('auth_check/googleLogin', data);
}

export async function reset(data) {
  return axios.post('auth/reset', data);
}

export async function verify(data) {
  return axios.get(`auth/reset/${data}`);
}
export async function setNewPassword(data) {
  return axios.post('auth/reset/password', data);
}

export async function uploadPhoto(data) {
  return axios.post('auth/user/picture', data);
}

export async function checkUser(data) {
  return axios.post('user/verify', data);
}

export async function changeLoginState(data) {
  return axios.post('change/loginState', data);
}

export async function changeRegisterState(data) {
  return axios.post('change/registerState', data);
}

export async function setPassword(data) {
  return axios.post('user/set/password', data);
}

export async function changePasswordUserName(data) {
  return axios.post('auth/change/username/password', data);
}

export async function checkAuthToken(data) {
  return axios.post('user/auth_token/check', data);
}

export async function changePassword(data) {
  return axios.post('auth/change/password', data);
}

export async function resetPasswordOtpRequest(data) {
  return axios.post('auth_check/reset/password', data);
}

export async function resetPasswordOtpCheck(data) {
  return axios.post('auth_check/verify/reset', data);
}

export async function resetPasswordWithOtp(data) {
  return axios.post('auth_reset/reset/password', data);
}

export async function checkUserExist(data) {
  return axios.post('auth_check/validate/info', data);
}

export async function updateUsername(data) {
  return axios.patch('update/username', data);
}
