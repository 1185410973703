import { Button, DatePicker, notification, Table } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getLoginsByDay } from "../../../../apis/organisation";
import { rootUrl } from "../../../../utils/config";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import axios from "../../../../apis";
import { NormalButton } from "../../../ComponentLibrary";
import TrialPage from "../../../Common/TrialComponent";
import attendanceAttendanceBgImage from "../../../../assets/dummyPages/attendanceAttendanceBgImage.png"

const Logins = ({ user, employeeList, isTrial }) => {
  const [loginList, setLoginList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [isRefresh, setIsRefresh] = useState(false);


  const getAllLoginsList = async (e = null) => {
    setIsLoading(true);
    let date = 0;
    let month = 0;
    let year = 0;
    if (e === null) {
      let today = moment();
      date = today.date();
      month = today.month();
      year = today.year();
    } else {
      setSelectedDate(e);
      date = e.date();
      month = e.month();
      year = e.year();
    }
    let res = await getLoginsByDay(user.organisationId, month, year, date);
    if (res.status === 200) {
      setLoginList(res.data);
    } else {
      notification.error({ message: "Failed to fetch login details." });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllLoginsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);
  const handleAction = async (url) => {
    let res = await axios({
      url, //your url
      method: "GET",
      responseType: "blob",
    });
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = urlDownload;
    link.setAttribute(
      "download",
      `report-${selectedDate.month()}-${selectedDate.date()}-${selectedDate.year()}--.pdf`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }
  const rows = [
    {
      title: "ID",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
      key: "ID",
    },
    {
      title: "Name",
      dataIndex: ["user", "displayName"],
      key: "name",
    },
    {
      title: "Login",
      dataIndex: "start",
      key: "login",
      render: (start) => <div>{moment(start).format("hh:mm a")}</div>,
    },
    {
      title: "Logoff",
      dataIndex: "end",
      key: "logoff",
      render: (end) => (
        <div>{end ? moment(end).format("hh:mm a") : "Not available"}</div>
      ),
    },
    {
      title: "Worked",
      dataIndex: "minutes",
      key: "worked",
      render: (_minutes, row) => (
        <div>
          {row.end
            ? Math.floor((new Date(row.end) - new Date(row.start)) / 3600000) +
            "h"
            : "Not Available"}
        </div>
      ),
    },
  ];

  return (
    isTrial ? <TrialPage user={user} trialMessage={" Get access to India's first tax optimizing and salary personalization tool powering your payroll"} bgImage={attendanceAttendanceBgImage}
    /> :
    <Spinner spinning={isLoading}>
      <div className="flex justify-between align-center">
        <div>
          <h1 className="font-size-global-24 font-weight-global-700">
            {loginList.length} Logins | {employeeList.length - loginList.length}{" "}
            Absent
          </h1>
        </div>
        <div className="flex align-center">
          <span style={{ float: "right" }}>
            <Button
              onClick={startRefresh}
              className={`button-lib button-type-13 p-10`}
              style={{ height: "2.5rem" }}
            >
              <div className="flex justify-between align-center">
                Refresh
              </div>
            </Button>
          </span>
          <NormalButton
            type={13}
            buttonText={"Download PDF"}
            style={{ fontWeight: "700" }}
            onClick={() =>
              handleAction(
                `${rootUrl}/api/daily/attendance/pdf/${user.organisationId
                }/${selectedDate.date()}/${selectedDate.month()}/${selectedDate.year()}`
              )
            }
            className="button-container m-20"
          />

          <DatePicker
            style={{ marginLeft: 10 }}
            value={selectedDate}
            onChange={(e) => {
              getAllLoginsList(e);
            }}
            type="month"
          />
        </div>
      </div>
      <div>
        <Table
          dataSource={loginList.reverse()}
          columns={rows}
          pagination={{ pageSize: 8 }}
        />
      </div>
    </Spinner>
  );
};

const LoginsContainer = connect((state) => ({
  user: state.auth.user,
  employeeList: state.organisation.employeeList,
  isTrial: state.auth.user.isTrial,

}))(Logins);

export default LoginsContainer;
