import { Avatar } from "antd";
import { useContext } from "react";
import "../../../styles/avatar.less";
import { BackgroundColorContext } from "../../../App";

function DefaultAvatar({ user, textSize, size }) {
    const colorContext = useContext(BackgroundColorContext);
    return (
        <Avatar
            style={{
                backgroundColor: colorContext,
                verticalAlign: "middle",
            }}
            size={size}
        >
            {textSize === "big" && (
                <h1 style={{ color: "white", fontSize: "50px" }}>
                    {user.displayName[0]}
                </h1>
            )}
            {textSize !== "big" && (
                <p
                    className="geninfo-default-avatar"
                    style={{ color: "white", fontSize: "15px" }}
                >
                    {" "}
                    {user.displayName[0]}{" "}
                </p>
            )}
        </Avatar>
    );
}

export default DefaultAvatar;