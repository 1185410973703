import { connect } from "react-redux";
import { Table, Tabs, Drawer, message, Radio, notification } from "antd";
import organisation from "../../../redux/modules/organisation";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";
import { NormalButton } from "../../ComponentLibrary";
import { useState, useEffect } from "react";
import Spinner from "../../ComponentLibrary/Spinner/Spinner";
import DropdownComponent from "../../InputLibrary/DropdownComponent/DropdownComponent";
import { postDomesticServantsApis, getDomesticServantsApis } from "../../../apis/perqs";
import { HeaderComponent } from "../../Common";
import { checkFinancialYear } from "../../../utils/config";
import { getFullToolTip } from "../../../utils/common";
import TrialPage from "../../Common/TrialComponent";
import perqsBgImage from "../../../assets/dummyPages/perqsBgImage.png"


const { TabPane } = Tabs

const DomesticServants = ({
    user,
    employeeList,
    isTrial
}) => {

    const [type, setType] = useState(0);
    const [showData, setShowData] = useState(false);
    const [empVisible, setEmpVisible] = useState(false);
    const [orgVisible, setOrgVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [data, setData] = useState([]);


    useEffect(() => {
        getDomesticServantsApis(user.organisationId)
            .then((fetchedData) => {
                setData(fetchedData.data.data);
            })
    }, [type, showData, user]);


    const options2 = [
        {
            label: "Yes", value: true
        },
        {
            label: "No", value: false
        }
    ]

    const [fieldDetails, setFieldDetails] = useState({
        id: "",
        servantType: "",
        servantName: "",
        bifurcatedAmount: 0,
        isFullRecovery: false,
        recoveryAmount: 0,
        isRecovery: true,
        servantId: "",
        isSpecifiedEmployee: false
    });

    const onClose = () => {
        setEmpVisible(false);
        setOrgVisible(false);
    };

    const onSave = () => {
        if (!fieldDetails.id) {
            message.error("Please add an employee");
            return;
        }
        if (!fieldDetails.servantType) {
            message.error("Please add servant Type");
            return;
        }
        if (!fieldDetails.servantName) {
            message.error("Please add servant name");
            return;
        }
        if (!fieldDetails.bifurcatedAmount) {
            message.error("Please reimbursement amount");
            return;
        }


        setLoading(true);

        const { month, financialYear } = checkFinancialYear();
        let postAPIData = {
            ...fieldDetails,
            type,
            month,
            financialYear

        }
        postDomesticServantsApis(postAPIData)
            .then((res) => {
                if (res.data.success) {
                    notification.success({
                        message: "Successfully added the Domestic Servant",
                    });
                    setEmpVisible(false);
                    setOrgVisible(false);
                    reset();
                    setLoading(false)
                    setSubmitSuccess(!submitSuccess);
                    setShowData(!showData)
                }
            }
            )
            .catch((err) => {
                notification.error({
                    message: "Failed to add Domestic Servant entry",
                });
                setEmpVisible(false);
                setOrgVisible(false);
                reset();
                setLoading(false)
            })

        setLoading(false);
        setEmpVisible(false)
        setOrgVisible(false);
    }

    const reset = () => {
        setFieldDetails({
            id: "",
            servantType: "",
            servantName: "",
            bifurcatedAmount: 0,
            isFullRecovery: false,
            recoveryAmount: 0,
            isRecovery: true,
            servantId: "",
            isSpecifiedEmployee: false
        })
        setLoading(false);
    };


    const showEmpDrawer = () => {
        setEmpVisible(true);
    };
    const showOrgDrawer = () => {
        setOrgVisible(true);
    }

    const onChangeTab = (e) => {
        setType(parseInt(e))
    }

    const columns = [
        {
            title: "ID",
            dataIndex: ["user", "currentOrganisationMeta", "employeeId"]
        },
        {
            title: "Employee Name",
            dataIndex: ["user", "displayName"]
        },
        {
            title: "Servant type",
            dataIndex: "servantType",
        },
        {
            title: "Servant Name",
            dataIndex: "servantName",
        },
        {
            title: "Amount Reimbursed / Month (In INR)",
            dataIndex: ["bifurcatedAmount"],
        }
    ];
    // const subHeader = (

    // )
    const employeeEntries = (
        <>
            <div className="font-size-global-14 font-weight-global-700">
                Name of the employee who took the service
                <div className="">
                    <DropdownComponent
                        className="general-ant-field-select"
                        labeltext={"Employee Name"}
                        options={employeeList.map((item) => item.displayName)}
                        values={employeeList.map(item => item._id)}
                        value={fieldDetails.id}
                        onChange={(e) => {
                            setFieldDetails({
                                ...fieldDetails,
                                id: e,
                            });
                        }}
                        width={"100%"}
                        bordered={false}
                        filterOption={(input, option) =>
                            option.children &&
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                        }
                        showSearch
                    />
                </div>
            </div>
            <div className="font-size-global-14 font-weight-global-700">
                What category do the servant fall into ?
                <div className="font-size-global-14 font-weight-global-700">
                    <InputFieldComponent
                        className="general-ant-field-input phone-real-number"
                        labeltext={"Type"}
                        value={fieldDetails.servantType}
                        style={{ marginLeft: "5px", width: "70%" }}
                        onChange={(e) => {
                            let fieldDetailsData = fieldDetails;
                            setFieldDetails({
                                ...fieldDetailsData,
                                servantType: e.target.value
                            })
                        }}
                    />
                </div>
            </div>
            <div className="font-size-global-14 font-weight-global-700">
                <InputFieldComponent
                    className="general-ant-field-input phone-real-number"
                    labeltext={"Servant Name"}
                    value={fieldDetails.servantName}
                    style={{ marginLeft: "5px", width: "70%" }}
                    onChange={(e) => {
                        let fieldDetailsData = fieldDetails;
                        setFieldDetails({
                            ...fieldDetailsData,
                            servantName: e.target.value
                        })
                    }}
                />
            </div>
            <div className="font-size-global-14 font-weight-global-700">
                Amount reimbursed by employer (per month)
                <div className="">
                    <InputFieldComponent
                        type={"number"}
                        className="general-ant-field-input phone-real-number"
                        labeltext={"Amount"}
                        value={fieldDetails.bifurcatedAmount}
                        style={{ marginLeft: "5px", width: "70%" }}
                        onChange={(e) => {
                            let fieldDetailsData = fieldDetails;
                            setFieldDetails({
                                ...fieldDetailsData,
                                bifurcatedAmount: parseInt(e.target.value)
                            })
                        }}
                    />
                </div>
            </div>
        </>
    )
    const organisationEntries = (
        <>
            <div className="font-size-global-14 font-weight-global-700">
                Name of the employee who took the service
                <div className="">
                    <DropdownComponent
                        className="general-ant-field-select"
                        labeltext={"Employee Name"}
                        options={employeeList.map((item) => item.displayName)}
                        values={employeeList.map(item => item._id)}
                        value={fieldDetails.id}
                        onChange={(e) => {
                            setFieldDetails({
                                ...fieldDetails,
                                id: e,
                            });
                        }}
                        width={"100%"}
                        bordered={false}
                        filterOption={(input, option) =>
                            option.children &&
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                            optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                        }
                        showSearch
                    />
                </div>
            </div>
            <div className="font-size-global-14 font-weight-global-700">
                What category do the servant fall into ?
                <div className="font-size-global-14 font-weight-global-700">
                    <InputFieldComponent
                        className="general-ant-field-input phone-real-number"
                        labeltext={"Type"}
                        value={fieldDetails.servantType}
                        style={{ marginLeft: "5px", width: "70%" }}
                        onChange={(e) => {
                            let fieldDetailsData = fieldDetails;
                            setFieldDetails({
                                ...fieldDetailsData,
                                servantType: e.target.value
                            })
                        }}
                    />
                </div>
            </div>
            <div className="font-size-global-14 font-weight-global-700">
                <InputFieldComponent
                    className="general-ant-field-input phone-real-number"
                    labeltext={"Servant Name"}
                    value={fieldDetails.servantName}
                    style={{ marginLeft: "5px", width: "70%" }}
                    onChange={(e) => {
                        let fieldDetailsData = fieldDetails;
                        setFieldDetails({
                            ...fieldDetailsData,
                            servantName: e.target.value
                        })
                    }}
                />
            </div>
            <div className="font-size-global-14 font-weight-global-700">
                Amount paid by employer as salary to servant (per month)
                <div className="">
                    <InputFieldComponent
                        type={"number"}
                        className="general-ant-field-input phone-real-number"
                        labeltext={"Amount"}
                        value={fieldDetails.bifurcatedAmount}
                        style={{ marginLeft: "5px", width: "70%" }}
                        onChange={(e) => {
                            let fieldDetailsData = fieldDetails;
                            setFieldDetails({
                                ...fieldDetailsData,
                                bifurcatedAmount: parseInt(e.target.value)
                            })
                        }}
                    />
                </div>
            </div>
            <div className="font-size-global-14 font-weight-global-700">
                How much do you want to Recover from the employee?
            </div>
            <div className="p-top-8 p-bottom-8">
                <Radio.Group
                    value={fieldDetails.isFullRecovery}
                    onChange={(e) =>
                        setFieldDetails({
                            ...fieldDetails,
                            recoveryAmount: e.target.value ? fieldDetails.bifurcatedAmount : 0,
                            isFullRecovery: e.target.value,
                        })
                    }
                >
                    <Radio value={true}>Fully
                        {getFullToolTip("The actual cost of salaries paid to the servants by employer (per month).")}
                    </Radio>
                    <Radio value={false}>Custom</Radio>
                </Radio.Group>

            </div>
            {
                !fieldDetails.isFullRecovery && <div>
                    <InputFieldComponent
                        type={"number"}
                        disabled={fieldDetails.isFullRecovery}
                        className="general-ant-field-input phone-real-number"
                        labeltext={"Amount"}
                        value={fieldDetails.recoveryAmount}
                        style={{ marginLeft: "5px", width: "70%" }}
                        onChange={(e) => {
                            let fieldDetailsData = fieldDetails;
                            setFieldDetails({
                                ...fieldDetailsData,
                                recoveryAmount: parseInt(e.target.value)
                            })
                        }}
                    />
                </div>
            }
            <div className="font-size-global-14 font-weight-global-700">
                Do you want recover the amount from employee's salary
            </div>
            <div className="p-top-8 p-bottom-8">
                <Radio.Group
                    options={options2}
                    value={fieldDetails.isRecovery}
                    onChange={(e) =>
                        setFieldDetails({
                            ...fieldDetails,
                            isRecovery: e.target.value,
                        })
                    }
                />

            </div>
        </>
    )
    return (
        isTrial ? <TrialPage user={user} trialMessage={"Explore the tax heaven for salaried employees without getting stressed about compliances"} bgImage={perqsBgImage}
        /> :
        <section className="site-layout">
            <HeaderComponent name={"Domestic Servants"} />
            <div className="app-main-content-container paper-wrapper">
                <Spinner spinning={loading}>
                    <div>
                        <h2 className="app-heading-2 font-weight-700">
                            Domestic Servants
                        </h2>
                    </div>
                </Spinner>



                <div className="domestic-servants-tabs">
                    <Tabs onChange={onChangeTab} defaultActiveKey="0" style={{ marginLeft: "10px" }}>
                        <TabPane
                            tab={
                                <div className="tabs">
                                    <span className="tab-name">Engaged by Employee</span>
                                </div>
                            }
                            key="0"
                        >
                            <div className="modal-margin-top-add-global-1-half flex justify-between align-center">
                                <div>
                                    <span className="font-size-global-24 font-weight-global-700 ">Entries</span>
                                </div>
                                <div className="flex justify-between align-center">
                                    <div>
                                        <NormalButton
                                            buttonText={"Add"}
                                            type={13}
                                            onClick={showEmpDrawer}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-margin-top-add-global-1-half">
                                <Table
                                    pagination={false}
                                    scroll={{ y: 400, x: 500 }}
                                    columns={columns}
                                    dataSource={data[type]}
                                />
                            </div>
                            <Drawer
                                title={"Add New Entry"}
                                placement="right"
                                onClose={onClose}
                                visible={empVisible}
                                size={"default"}
                            >
                                <Spinner spinning={loading}>
                                    <div className="drawer">
                                        {employeeEntries}
                                        <div className="button-container">
                                            <NormalButton
                                                buttonText="Save Changes"
                                                type={13}
                                                onClick={onSave}
                                            />
                                            <NormalButton buttonText="Cancel" type={5} onClick={() => {
                                                onClose()
                                                reset()
                                            }} />
                                        </div>
                                    </div>
                                </Spinner>
                            </Drawer>
                            {/* <EngagedByEmployee /> */}
                        </TabPane>
                        <TabPane
                            tab={
                                <div className="tabs">
                                    <span className="tab-name">Engaged by Organisation</span>
                                </div>
                            }
                            key="1"
                        >
                            <div className="modal-margin-top-add-global-1-half flex justify-between align-center">
                                <div>
                                    <span className="font-size-global-24 font-weight-global-700 ">Entries</span>
                                </div>
                                <div className="flex justify-between align-center">
                                    <div>
                                        <NormalButton
                                            buttonText={"Add"}
                                            type={13}
                                            onClick={showOrgDrawer}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-margin-top-add-global-1-half">
                                <Table
                                    pagination={false}
                                    scroll={{ y: 400, x: 500 }}
                                    columns={columns}
                                    dataSource={data[type]}
                                />
                            </div>
                            <Drawer
                                title={"Add New Entry"}
                                placement="right"
                                onClose={onClose}
                                visible={orgVisible}
                                size={"default"}
                            >
                                <Spinner spinning={loading}>
                                    <div className="drawer">
                                        {organisationEntries}
                                        <div className="button-container">
                                            <NormalButton
                                                buttonText="Save Changes"
                                                type={13}
                                                onClick={onSave}
                                            />
                                            <NormalButton buttonText="Cancel" type={5} onClick={() => {
                                                onClose()
                                                reset()
                                            }} />
                                        </div>
                                    </div>
                                </Spinner>
                            </Drawer>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </section>
    );
};

const DomesticServantsPerqs = connect(
    state => ({
        user: state.auth.user,
        organisationSettings: state.organisation.organisationSettings,
        orgLoading: state.organisation.loading,
        employeeList: state.organisation.employeeList,
        isTrial: state.auth.user.isTrial,

    }),
    dispatch => ({
        organisationReducer: organisation.getActions(dispatch)
    })
)(DomesticServants);


export default DomesticServantsPerqs;



