import "../../styles/zeroState.less";
import classroom from "../../assets/icons/classroom.svg";
import blueEllipse from "../../assets/employeeDashboard/ellipse1.svg";
import greenEllipse from "../../assets/employeeDashboard/ellipse2.svg";
import UploadSpace from "./UploadSpace";
import { docs } from "../../utils/data";
import employee from "../../redux/modules/employee";
import organisation from "../../redux/modules/organisation";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    employeeReducer: employee.getActions(dispatch),
    organisationReducer: organisation.getActions(dispatch),
  };
};
const ZeroState = ({authReducer}) => {
  return (
    <div className="zero-state-container">
      <div className="zero-state">
        <div className="head">
          <h1>
            Welcome to mool! 🤠
          </h1>
          <img src={blueEllipse} alt='' className='blue-ellipse' />
          <img src={greenEllipse} alt='' className='green-ellipse' />
          <div className="red-ellipse"></div>
          <div className="yellow-rect"></div>
          <p>
            Lets start automating your payroll processes so that you{" "}
            <b>Save time and focus on Growth</b>
          </p>
        </div>
        <UploadSpace docs={docs} width="100%"  authReducer={authReducer} />
        <div style={{ marginTop: '3rem', width: '100%' , marginBottom: '3rem', borderRadius: '8px', backgroundColor: '#fff', padding: '3.1rem' }}>
          <h3 style={{ fontWeight: '700', fontSize: '21px', marginBottom: '1rem' }}>Learn how to use mool</h3>
          <div
            style={{
            display: "flex",
            flexDirection: "row",
            overflow: 'hidden'
          }}
          >
            <iframe
              width='350px'
              height='230px'
              src="https://www.youtube-nocookie.com/embed/AiIubAgIOT0?controls=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ borderRadius: '8px', margin: 'auto 0' }}
            />
            <div className="box-container">
              <div className="box-box">
                <div className="big-box">
                  <img src={classroom} alt="" />
                </div>
                <h4>Guides</h4>
              </div>
              <div className="box-box">
                <div className="big-box">
                  <img src={classroom} alt="" />
                </div>
                <h4>FAQ</h4>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ZeroState);
