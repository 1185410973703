import { useState } from "react";
import { Button, Modal, notification, Spin } from "antd";
import auth from "../../../redux/modules/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NormalButton } from "../../ComponentLibrary";
import { changePassword } from "../../../apis/auth";
import moment from "moment";
import organisation from "../../../redux/modules/organisation";
import { createLog } from "../../../apis/user";
import DefaultAvatar from "../../Common/DefaultAvatar/DefaultAvatar";
import ColorGenerator from "../../../utils/ColorGenerator";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";
const OrganisationData = (props) => {
  const backgroundColor = ColorGenerator();
  const [loading, setLoading] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [resetPassword, setResetPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleResetCancel = () => {
    setResetPassword({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setIsResetModalVisible(false);
  };

  const handleResetPassword = (e) => {
    setResetPassword({
      ...resetPassword,
      [e.target.name]: e.target.value,
    });
  };
  const onResetPassSubmit = async () => {
    if (
      !resetPassword.oldPassword ||
      !resetPassword.newPassword ||
      !resetPassword.confirmPassword
    ) {
      notification.error({ message: "Field is required" });
      return;
    }
    if (resetPassword.oldPassword === resetPassword.newPassword) {
      notification.error({
        message: "Your new Password cannot be the same as your Old Password",
      });
      return;
    }
    if (resetPassword.newPassword !== resetPassword.confirmPassword) {
      notification.error({
        message: "New Password and Confirm Password are not same",
      });
      return;
    }
    if (
      resetPassword.oldPassword !== resetPassword.newPassword &&
      resetPassword.newPassword === resetPassword.confirmPassword
    ) {
      setLoading(true);
      const response = await changePassword({
        username: props.user.username,
        oldPassword: resetPassword.oldPassword,
        newPassword: resetPassword.newPassword,
      });
      setLoading(false);
      if (response.data.success) {
        await createLog({
          name: props.user.displayName,
          userId: props.user._id,
          organisationId: props.user.organisationId,
          message: `Organisation user has updated the password`,
          category: 2,
        });
        notification.success({ message: "Password Changed Successfully!" });
        handleResetCancel();
      } else {
        if (response.data.message === "WRONG_CREDENTIALS") {
          notification.error({
            message: "Incorrect Old password!, Please try again!",
          });
        } else {
          notification.error({
            message: "Can not change your password, Please try again!",
          });
        }
      }
    }
  };

  return (
    <div className="profile-app-white-card organisation-data-container">
      <div className="app-flex-container-1 organisation-data-heading-container">
        <div className="organisation-name-logo-container flex align-center justify-center">
          <div className="current-logo-holder-has-logo">
            {props.image ? (<img alt="logo" className="current-logoo" src={props.image}></img>

            ) : (<DefaultAvatar backgroundColor={backgroundColor} user={props.user} textSize={'medium'} size={50} />

            )}
          </div>
          <div className="profile-info-container">
            <h2 className="app-profile-heading">{props.user.displayName}</h2>
            <h2 className="app-profile-subheading">
              Joined on: {moment(props.user.createdAt).format("MMMM Do YYYY")}
            </h2>
          </div>
        </div>
      </div>
      <div
        className="header-menu-line"
        style={{ marginBottom: "0.5rem", marginTop: "0" }}
      ></div>
      <div className="org-info-container">
        <div className="flex align-items justify-between">
          <p className="org-app-label-1">PAN</p>
          <p className="org-app-bold-label-1">{props.user.PAN}</p>
        </div>
        <div className="flex align-items justify-between">
          <p className="org-app-label-1">Phone number</p>
          <p className="org-app-bold-label-1">{props.user.phone}</p>
        </div>
        <div className="flex align-items justify-between">
          <p className="org-app-label-1">Username</p>
          <p className="org-app-bold-label-1">{props.user.username}</p>
        </div>
        <div className="flex align-items justify-between">
          <p className="org-app-label-1">Email</p>
          <p className="org-app-bold-label-1">{props.user.primaryEmail}</p>
        </div>
        <div className="flex align-items justify-between">
          <p className="org-app-label-1">Payroll Structure</p>
          <p className="org-app-bold-label-1">{props.user.moolSettings.isPercentageStructure ? "Percentage Structure" : "Mool Structure"}</p>
        </div>

      </div>
      <div className="header-menu-line" style={{ marginTop: "0.5rem" }}></div>

      <div>
        <Button
          className="blue-submit-button"
          onClick={() => setIsResetModalVisible(true)}
        >
          Change password
        </Button>
        <Modal
          visible={isResetModalVisible}
          onCancel={handleResetCancel}
          footer={null}
          centered
          width={400}
        >
          <Spin spinning={loading} size="large">
            <h1 className="font-size-global-24 font-weight-global-700 modal-alignment-center-global">Reset Organisation Password</h1>
            <div>
              <div
                className="modal-alignment-center-global"
                style={{ marginTop: 10 }}
              >
                <InputFieldComponent
                  className="input-register"
                  type={"password"}
                  labeltext={"Old Password"}
                  name="oldPassword"
                  onChange={handleResetPassword}
                  value={resetPassword.oldPassword}
                />
              </div>
              <div
                className="modal-alignment-center-global"
                style={{ marginTop: 10 }}
              >
                <InputFieldComponent
                  name="newPassword"
                  type={"password"}
                  labeltext={"New Password"}
                  className="input-register"
                  onChange={handleResetPassword}
                  value={resetPassword.newPassword}
                />
              </div>
              <div className="modal-alignment-center-global" style={{ marginTop: 10 }}>
                <InputFieldComponent
                  name="confirmPassword"
                  type={"password"}
                  labeltext={"Confirm Password"}
                  className="input-register"
                  onChange={handleResetPassword}
                  value={resetPassword.confirmPassword}
                />
              </div>
              <div>
                <NormalButton
                  buttonText="Change Password"
                  onClick={onResetPassSubmit}
                  type={13}
                  margin={[8, 0]}
                  block={true}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    </div>
  );
};

OrganisationData.propTypes = {
  user: PropTypes.object.isRequired,
};

const OrganisationDataContainer = connect(
  (state) => ({
    user: state.auth.user,
    image: state.auth.image,
    onboarding: state.organisation.onboarding,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    organisationReducer: organisation.getActions(dispatch),
  })
)(OrganisationData);

export default OrganisationDataContainer;
