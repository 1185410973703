import React from "react";
import { DatePicker } from "antd";
import "./DateComponent.less";

function DateComponent({
  maxWidth,
  value,
  onChange,
  width,
  height,
  display,
  margin,
  labelText,
  labelBackground,
  type
}) {
  let styles = {
    width: width ? `${width}` : "288px",
    maxWidth: maxWidth ? `${maxWidth}` : "100%",
    height: height ? `${height}` : "50px",
    borderRadius: "8px",
  };
  let stylediv = {
    display: display ? display : "block",
  };
  let parentdivStyle = {
    margin: margin
      ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`
      : "10px 0px",
  };

  return (
    <div style={parentdivStyle}>
      <div className="datePickerDiv" style={stylediv}>
        <DatePicker
          style={styles}
          className={`custom ${value && 'hasValue'}`}
          placeholder=""
          value={value}
          onChange={onChange}
          picker={type || "date"}
        />
        <label style={{ backgroundColor: labelBackground }} className={value ? "labelup" : "labelnormal"}>{labelText || "Select Date"}</label>
      </div>
    </div>
  );
}

export default DateComponent;
