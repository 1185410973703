import { connect } from "react-redux";
import AllowanceFileUploader from "./otherAllowance/AllowanceFileUploader.js";

const OtherAllowance = ({ user, declaration, organisationFreeze }) => {


  return (
    <div className="other-allowances-container">
      <AllowanceFileUploader user={user} organisationFreeze={organisationFreeze} />
    </div>
  );
};
const OtherAllowanceContainer = connect((state) => ({
  user: state.auth.user,
  declaration: state.employee.declaration,
  organisationFreeze: state.employee.organisationFreeze,
}))(OtherAllowance);
export default OtherAllowanceContainer;
