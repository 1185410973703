import { HeaderComponent } from "../../Common";
import { Tabs } from "antd";
import Holiday from "./Components/Holiday";
import Leave from "./Components/Leave";
import { connect } from "react-redux";
import organisation from "../../../redux/modules/organisation";
import WeeklyOff from "./Components/WeeklyOff";
import { useEffect } from "react";
import TrialPage from "../../Common/TrialComponent";
import attendanceSettingsBgImage from "../../../assets/dummyPages/attendanceSettingsBgImage.png"

const { TabPane } = Tabs;

const OrganisationSettings = ({
  user,
  groups,
  leaveData,
  leaveList,
  organisationReducer,
  isTrial,
}) => {
  useEffect(() => {
    document.title = 'Attendance | Settings';
  });
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Configure your holidays, leaves and week-offs as per your company policies"} bgImage={attendanceSettingsBgImage}
    /> :
      <div className="site-layout">
        <HeaderComponent show={false} name="Settings" />
        <div className="app-main-content-container paper-wrapper ">
          <div className="blue-tabs-container ">
            <Tabs tabPosition={"left"}>
              <TabPane tab="Holiday Settings" key="1">
                <Holiday user={user} groups={groups} />
              </TabPane>
              <TabPane tab="Leave Settings" key="2">
                <Leave
                  user={user}
                  leaveData={leaveData}
                  leaveList={leaveList}
                  organisationReducer={organisationReducer}
                />
              </TabPane>
              <TabPane tab="Weekly Off Settings" key="3">
                <WeeklyOff
                  user={user}
                  groups={groups}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
  );
};

const OrganisationSettingsContainer = connect(
  (state) => ({
    user: state.auth.user,
    groups: state.organisation.groups,
    leaveData: state.organisation.leaveData,
    leaveList: state.organisation.leaveList,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(OrganisationSettings);

export default OrganisationSettingsContainer;
