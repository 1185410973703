import { InputNumber, Skeleton, Row, Col, Divider, Collapse } from "antd";
import "../../../styles/ctccalcstyle.less"

const { Panel } = Collapse;

const Allowances = ({
  allowances,
  customAllowances,
  setCustomAllowances,
  setAllowances,
  organisationSettings,
}) => {
  return (
    <Collapse ghost={true}  >
      <Panel header="Allowance" className="allowance-panel">
        <div className="h-full w-full">
          {/* <p className="font-weight-700 font-size-global-18 mb-2">Can't decide how much allowance to add ?</p>
      <Checkbox className="font-weight-500 font-size-global-18 mb-2" defaultChecked={true}>Allow mool engine to enter the numbers automatically</Checkbox> */}
          <Skeleton
            loading={Object.keys(organisationSettings).length === 0}
            active
            paragraph={{ rows: 15 }}
          >
            <Row className="allowance-row">
              {allowances &&
                Object.keys(allowances).map(
                  (allowance, index) =>
                    allowances[allowance]["applicable"] && (<>
                      <Col xl={{ span: 8 }} lg={{ span: 12 }}>
                        <div
                          className="flex align-center"
                          key={`all-${index}`}
                        >
                          <div
                            className="org-data-container"
                            style={{ marginTop: "8px",lineHeight:'1.8em' }}
                          >
                            <div
                              className="flex align-center justify-start"
                              style={{ background: "none", width: "200px" }}
                            >
                              <h3 className="general-ant-field-label">
                                {allowances[allowance].label} Allowance
                              </h3>
                              {/* <InfoBtn
                            infoText={
                              configAllowances[allowance]["infoBtnText"]
                            }
                            link={
                              "https://www.incometaxindia.gov.in/_layouts/15/dit/mobile/viewer.aspx?path=https://www.incometaxindia.gov.in/charts%20%20tables/list_of_benefits_available_to_a_salaried_person_final.htm&k=&IsDlg=0"
                            }
                          /> */}
                            </div>
                            <InputNumber
                              style={{ borderRadius: "6px", width: "100px" }}
                              placeholder="INR"
                              value={allowances[allowance]["limit"]}
                              onChange={(value) => {
                                let newAllowances = allowances;
                                newAllowances[allowance]["limit"] = parseInt(value)
                                  ? value
                                  : 0;
                                setAllowances({ ...newAllowances });
                              }}
                              labeltext="INR"
                            />
                          </div>
                          <Divider type="vertical" />
                        </div>
                        <Divider />
                      </Col>
                    </>
                    )
                )}

              {customAllowances &&
                Object.keys(customAllowances).map((allowance, index) => (
                  <Col xl={{ span: 8 }} lg={{ span: 12 }}>
                    <div
                      className="flex align-center"
                      key={`all-${index}`}
                    >
                      <div
                        className="org-data-container"
                        style={{ marginTop: "2px" }}
                      >
                        <div
                          className="flex align-center justify-start"
                          style={{ background: "none", width: "200px" }}
                        >
                          <h3 className="general-ant-field-label">
                            {customAllowances[allowance].label}
                          </h3>
                          {/* <InfoBtn
                            infoText={
                              configAllowances[allowance]["infoBtnText"]
                            }
                            link={
                              "https://www.incometaxindia.gov.in/_layouts/15/dit/mobile/viewer.aspx?path=https://www.incometaxindia.gov.in/charts%20%20tables/list_of_benefits_available_to_a_salaried_person_final.htm&k=&IsDlg=0"
                            }
                          /> */}
                        </div>
                        <InputNumber
                          style={{ borderRadius: "6px", width: "100px" }}
                          placeholder="INR"
                          size="large"
                          min={0}
                          width={300}
                          onChange={(value) => {
                            let newAllowances = customAllowances;
                            newAllowances[allowance]["limit"] = parseInt(value)
                              ? value
                              : 0;
                            setCustomAllowances({ ...newAllowances });
                          }}
                          value={customAllowances[allowance]["limit"]}
                        />
                      </div>
                      <Divider type="vertical" />
                    </div>
                    <Divider />
                  </Col>
                ))}</Row>
          </Skeleton>
        </div>
      </Panel>
    </Collapse>
  );
};

export default Allowances;
