import React from "react";
import { Table } from "antd";
import { NormalButton } from "../../ComponentLibrary";
import { Status } from "../../ComponentLibrary";

const TableforSupport = ({ supportListData, loading }) => {
  
  const columns = [
    {
      title: "Requested on",
      dataIndex: "requestedOn",
      key: "requestedOn",
      defaultSortOrder: "descend",
      width: "15%",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "15%",
    },

    // {
    //   title: "Attachment",
    //   dataIndex: "attachments",
    //   key: "documents",
    //   ellipsis: true,
    //   width: "20%",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "20%",
      render: (state) => (
        <div>
          {state === 0 ? (
            <Status type={1} text="Pending" margin={[0, 8]} />
          ) : state === 1 ? (
            <Status type={2} text="Resolved" />
          ) : (
            <>
              <Status type={3} text="Rejected" />
              <NormalButton type={5} buttonText="Reason?" margin={[0, 24]} />
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="table-container">
      <h2 className="table-container-heading">
        <b>Tickets </b>
      </h2>
      <Table
        className="real-table"
        loading={loading}
        columns={columns}
        dataSource={supportListData}
        pagination={{ pageSize: 8 }}
        scroll={{x: 600}}
      />
    </div>
  );
};

export default TableforSupport;
