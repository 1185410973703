import {
    Modal,
    Spin,
} from "antd";
import { Content } from "antd/lib/layout/layout";

const DeleteConfirmation = ({
    modalProperties,
    deleteEmployee,
    resetModals,
    pageState,
    responseData
}) => {
    return (
        <Modal
            title="Delete Confirmation"
            centered
            visible={modalProperties.delete.visible}
            onOk={deleteEmployee}
            closable={false}
            okText={"Confirm"}
            onCancel={() => {
                resetModals();
            }}
        >
            <Spin delay={100} spinning={modalProperties.delete.loading} size="large">
                <Content className="modal-body">
                    <Content className="allowances-container">
                        {pageState.delete === 0 ? (
                            <p>
                                Are you sure you want to delete the selected employees
                            </p>
                        ) : (
                            <div className="Employee-Upload-Status">
                                <p>Employee Delete Status</p>
                                {responseData.map((data) => (
                                    <div
                                        className={
                                            data.success
                                                ? "response-data success"
                                                : "response-data fail"
                                        }
                                    >
                                        <p>{data.name}</p> <p>{data.message}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Content>
                </Content>
            </Spin>
        </Modal>
    )
}

export default DeleteConfirmation;