import {
  Checkbox, InputNumber, message, Modal, notification, Spin
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import {
  updateAllowances,
  updateEmployeePercentage
} from "../../../apis/organisation";
import { createLog } from "../../../apis/user";
import { calculatePercentage } from "../../../utils/common";
import { checkFinancialYear } from "../../../utils/config";
import { NormalButton } from "../../ComponentLibrary";

const UpdatePayStructure = ({
  modalProperties,
  setModalProperties,
  selectedEmployee,
  isPercentageStructure,
  resetUpdatePayStructure,
  structureOfMool,
  user,
  groups,
  selectedEmployeeDeclartion,
}) => {
  const { financialYear } = checkFinancialYear();
  const [displayAllowances, setDisplayAllowances] = useState({});
  const [displayCustomAllowances, setDisplayCustomAllowances] = useState({});
  const [percentage, setPercentage] = useState({
    basicHRAPercentage: {
      basic: true,
      hra: true,
    },
  });

  useEffect(() => {
    if (selectedEmployeeDeclartion) {
      const { declarations, user: usr } = selectedEmployeeDeclartion;
      const { groupId } = usr;
      const { allowances, customAllowances } = declarations;
      setDisplayAllowances({ ...allowances });
      setDisplayCustomAllowances({ ...customAllowances });

      if (isPercentageStructure) {
        let { percentage: psg } = declarations;
        if (!psg || Object.keys(psg).length === 0) {
          let group = groups.filter((gr) => gr.groupId === groupId);
          if (
            group.length === 0 ||
            (group[0].percentage && Object.keys(group[0].percentage).length > 0)
          ) {
            psg = { ...user.organisationSettings.percentageStructure };
          } else {
            psg = { ...group[0].percentage };
          }
        }
        // Refine the percentage structure
        Object.keys(allowances).forEach((val) => {
          if (!psg.allowances[val]) {
            psg.allowances[val] = {
              isPercent: true,
              value: 0,
            };
          }
        });

        Object.keys(customAllowances).forEach((val) => {
          if (!psg.customAllowances[val]) {
            psg.customAllowances[val] = {
              isPercent: true,
              value: 0,
            };
          }
        });
        psg.specialAllowances = 0;
        if (!psg.basicHRAPercentage) {
          psg.basicHRAPercentage = {
            basic: true,
            hra: true,
          };
        }
        setPercentage({ ...psg });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployeeDeclartion]);

  const savePayStructure = async () => {
    setModalProperties({
      ...modalProperties,
      updateAllowances: {
        loading: true,
        visible: true,
      },
    });
    if (isPercentageStructure) {
      let tempPercent = { ...percentage };
      let percentTotal = calculatePercentage(percentage);
      let remaing = Math.round((1 - percentTotal) * 1000) / 1000;
      tempPercent.specialAllowances = remaing > 0 ? remaing : 0;
      await updateEmployeePercentage({
        id: selectedEmployee,
        financialYear,
        percentage,
      });
    }

    await updateAllowances({
      financialYear,
      organisationId: user.organisationId,
      id: selectedEmployee,
      allowances: { ...displayAllowances },
      customAllowances: { ...displayCustomAllowances },
    });
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organization User has updated ${user.displayName}'s pay structure`,
      category: 16,
    });
    notification.success({
      message: "Successfully updated employee salary structure",
    });
    resetUpdatePayStructure();
  };

  const calculateIsApplicable = (percent) => {
    let percentTotal = calculatePercentage(percent);
    let remaining = 1 - percentTotal;
    let isApplicable = remaining >= 0;
    return isApplicable;
  };

  const changeAllowancesState = (key, value, type) => {
    if (type === "allowance") {
      if (
        (!structureOfMool && displayAllowances[key]["amount"] < value && !percentage.allowances[key]["isPercent"]) ||
        !displayAllowances[key]["applicable"]
      ) {
        notification.error({ message: "Please Enter Valid Amount" });
        return;
      }
      let newAllowances = displayAllowances;
      newAllowances[key]["actualExpense"] = value;
      newAllowances[key]["amount"] = value;
      newAllowances[key]["limit"] = value;
      newAllowances[key]["isCalculationLimit"] = true;
      setDisplayAllowances({ ...newAllowances });
    }
    if (type === "custom") {
      if (
        (!percentage.customAllowances[key]["isPercent"] &&
          displayCustomAllowances[key]["limit"] < value) ||
        !displayCustomAllowances[key]["applicable"]
      ) {
        notification.error({ message: "Please Enter Valid Amount" });
        return;
      }

      let newAllowances = displayCustomAllowances;
      newAllowances[key]["actualExpense"] = value;
      newAllowances[key]["amount"] = value;
      newAllowances[key]["isCalculationLimit"] = true;
      newAllowances[key]["limit"] = value;
      setDisplayCustomAllowances({ ...newAllowances });
    }
  };

  /**
   *
   * @param {*} value
   * @param {*} field
   * @param {*} type
   * type - 0 basic hra
   * type - 1 for allowances
   * type - 2 for custom allowances
   */
  const updatePercentageStructure = (value, field, type) => {
    let percentValue = value / 100;
    let tempPercent = { ...percentage };

    if (type < 0 || type > 2) {
      return;
    }

    if (type === 0) {
      tempPercent[field] = tempPercent.basicHRAPercentage[field] ? percentValue : value;
    }

    if (type === 1) {
      let allowance = {
        ...tempPercent.allowances,
      };
      if (allowance[field]) {
        allowance[field] = {
          ...allowance[field],
          value: allowance[field]["isPercent"] ? percentValue : value,
        };
      } else {
        allowance[field] = {
          value: percentValue,
          isPercent: true,
        };
      }
      tempPercent["allowances"] = { ...allowance };
    }

    if (type === 2) {
      let callowance = {
        ...tempPercent.customAllowances,
      };
      if (callowance[field]) {
        callowance[field] = {
          ...callowance[field],
          value: callowance[field]["isPercent"] ? percentValue : value,
        };
      } else {
        callowance[field] = {
          value: percentValue,
          isPercent: true,
        };
      }
      tempPercent["customAllowances"] = { ...callowance };
    }

    let isApplicable = calculateIsApplicable(tempPercent);
    if (!isApplicable) {
      message.warning("The value exceeded the remaining percentage");
      return;
    }
    setPercentage({
      ...tempPercent,
    });
  };

  const updateIsPercentage = (field, value, type) => {
    if (isPercentageStructure) {
      let tempPercent = { ...percentage };
      if (type === 0) {
        let allowance = {
          ...tempPercent.allowances,
        };
        allowance[field]["isPercent"] = value;
        allowance[field]["value"] = value ? 0 : allowance[field]["value"];
        tempPercent["allowances"] = { ...allowance };
      } else if (type === 1) {
        let callowance = {
          ...tempPercent.customAllowances,
        };
        callowance[field]["isPercent"] = value;
        callowance[field]["value"] = value ? 0 : callowance[field]["value"];
        tempPercent["customAllowances"] = { ...callowance };
      } else {
        return;
      }

      setPercentage({
        ...tempPercent,
      });
    }
  };

  return (
    <Modal
      centered
      closable={false}
      width={800}
      visible={modalProperties.updateAllowances.visible}
      className="modal-container"
      footer={null}
    >
      <Spin spinning={modalProperties.updateAllowances.loading} size="large">
        <Content className={"modal-body"}>
          <h1 className="font-weight-global-500">Update Pay Structure</h1>
          {isPercentageStructure && (
            <div>
              <div className="flex justify-between align-center my-20 border-box">
                <p className="app-label-1">Special Allowances</p>
                <p className="break-value">
                  {Math.round(
                    (1 - calculatePercentage(percentage)) * 100 * 1000
                  ) / 1000}{" "}
                  % of CTC
                </p>
              </div>
              <div className="flex justify-between align-center my-20 border-box">
                <p className="app-label-1">Buffer</p>
                <p className="break-value">
                  {Math.round((percentage?.buffer ?? 0) * 100)} % of CTC
                </p>
              </div>
              <div className="flex justify-between align-center my-20 border-box">
                <p className="app-label-1">Basic</p>
                <div>
                  <React.Fragment>
                    {isPercentageStructure && (
                      <Checkbox
                        checked={
                          !percentage.basicHRAPercentage.basic
                        }
                        onChange={(value) =>
                          setPercentage({
                            ...percentage,
                            basicHRAPercentage: {
                              ...percentage.basicHRAPercentage,
                              basic: !value.target.checked
                            }
                          })
                        }
                      >
                        Amount
                      </Checkbox>
                    )}
                    <InputNumber
                      placeholder={0}
                      size="large"
                      className="m-right-16"
                      min={0}
                      width={400}
                      onChange={(value) =>
                        updatePercentageStructure(value, "basic", 0)
                      }
                      value={
                        Number(percentage.basicHRAPercentage.basic
                          ? Math.round(percentage.basic * 100000) / 1000
                          : percentage.basic)
                      }
                    />
                  </React.Fragment>
                  <span className="app-label-5 heading">
                    {percentage.basicHRAPercentage.basic
                      ? `% of CTC`
                      : `Per Month`}
                  </span>
                </div>
              </div>
              <div className="flex justify-between align-center my-20 border-box">
                <p className="app-label-1">HRA</p>
                <div>
                  <React.Fragment>
                    {isPercentageStructure && (
                      <Checkbox
                        checked={
                          !percentage.basicHRAPercentage.hra
                        }
                        onChange={(value) =>
                          setPercentage({
                            ...percentage,
                            basicHRAPercentage: {
                              ...percentage.basicHRAPercentage,
                              hra: !value.target.checked
                            }
                          })
                        }
                      >
                        Amount
                      </Checkbox>
                    )}
                    <InputNumber
                      placeholder={0}
                      size="large"
                      className="m-right-16"
                      min={0}
                      width={400}
                      onChange={(value) =>
                        updatePercentageStructure(value, "hra", 0)
                      }
                      value={
                        Number(percentage.basicHRAPercentage.hra
                          ? Math.round(percentage.hra * 100000) / 1000
                          : Math.round(percentage.hra))
                      }
                    />
                  </React.Fragment>
                  <span className="app-label-5 heading">
                    {percentage.basicHRAPercentage.hra
                      ? `% of CTC`
                      : `Per Month`}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div>
            {Object.keys(displayCustomAllowances).map((val) => {
              return (<>{
                displayCustomAllowances[val].applicable &&
                <div className="flex justify-between align-center my-20 border-box">
                  <p className="app-label-1">
                    {displayCustomAllowances[val].label}
                  </p>
                  <div>
                    <React.Fragment>
                      {isPercentageStructure && (
                        <Checkbox
                          checked={
                            !percentage.customAllowances[val]["isPercent"]
                          }
                          onChange={(value) =>
                            updateIsPercentage(val, !value.target.checked, 1)
                          }
                        >
                          Amount
                        </Checkbox>
                      )}
                      <InputNumber
                        size="large"
                        min={0}
                        width={400}
                        className="m-right-16"
                        onChange={(value) => {
                          if (isPercentageStructure) {
                            updatePercentageStructure(value, val, 2);
                          }
                          changeAllowancesState(val, value, "custom");
                        }}
                        value={
                          Number(isPercentageStructure &&
                            percentage.customAllowances[val]
                            ? Math.round(
                              percentage.customAllowances[val].value *
                              (percentage.customAllowances[val].isPercent
                                ? 100
                                : 1) *
                              1000
                            ) / 1000
                            : displayCustomAllowances[val].amount)
                        }
                      />
                    </React.Fragment>
                    {isPercentageStructure &&
                      displayCustomAllowances[val].applicable && (
                        <span className="app-label-5 heading">
                          {percentage.customAllowances[val].isPercent
                            ? ` % of CTC`
                            : " Per Month"}
                        </span>
                      )}
                  </div>
                </div>
              }</>);
            })}
            {Object.keys(displayAllowances).map((val) => {
              return (
                <>{
                  displayAllowances[val].applicable &&
                  <div className="flex justify-between align-center my-20 border-box">
                    <p className="app-label-1">{displayAllowances[val].label}</p>
                    <div>
                      {displayAllowances[val].applicable && (
                        <React.Fragment>
                          {isPercentageStructure && (
                            <Checkbox
                              checked={!percentage.allowances[val]["isPercent"]}
                              onChange={(value) =>
                                updateIsPercentage(val, !value.target.checked, 0)
                              }
                            >
                              Amount
                            </Checkbox>
                          )}
                          <InputNumber
                            size="large"
                            min={0}
                            width={400}
                            className="m-right-16"
                            onChange={(value) => {
                              if (isPercentageStructure) {
                                updatePercentageStructure(value, val, 1);
                              }
                              changeAllowancesState(val, value, "allowance");
                            }}
                            value={
                              Number(isPercentageStructure && percentage.allowances[val]
                                ? Math.round(
                                  percentage.allowances[val].value *
                                  (percentage.allowances[val].isPercent
                                    ? 100
                                    : 1) *
                                  1000
                                ) / 1000
                                : displayAllowances[val].amount)
                            }
                          />
                        </React.Fragment>
                      )}
                      {isPercentageStructure &&
                        displayAllowances[val].applicable && (
                          <span className="app-label-5 heading">
                            {percentage.allowances[val].isPercent
                              ? ` % of CTC`
                              : " Per Month"}
                          </span>
                        )}
                    </div>
                  </div>
                }</>);
            })}
          </div>
          <div className="submit-container-modal">
            <NormalButton
              onClick={resetUpdatePayStructure}
              buttonText={"Cancel"}
              type={5}
              className="cancel-button"
              style={{
                border: "none",
                boxShadow: "none",
                marginRight: "0px",
              }}
            />
            <NormalButton
              onClick={savePayStructure}
              id="UPSBttnId"
              buttonText={"Update"}
              className="submit-button"
              type={13}
              style={{
                marginRight: "0px",
                padding: "0px 24px",
              }}
            />
          </div>
        </Content>
      </Spin>
    </Modal>
  );
};

export default UpdatePayStructure;
