import axios from './index';
import { checkFinancialYear } from "../utils/config";
const { financialYear } = checkFinancialYear()

export async function getJannForm12BBDocument(organisationId) {
    return axios.get(`/fetch/all/form12BB/${organisationId}/${financialYear}`);
}
export async function updateForm12BBVerifiedStatus(data) {
    return axios.put(`/update/verified/form12BB`, data);
}

export async function getJannRentReceiptDocument(organisationId) {
    return axios.get(`fetch/all/rent-receipt/${organisationId}`);
}

export async function downloadJannForm12Document(id, financialYear) {
    return axios.get(`form12BB/download/documents/${id}/${financialYear}`);
}

export async function downloadJannRentReceiptDocument(id) {
    return axios.get(`rent-receipt/documentBuffer/${id}`);
}
