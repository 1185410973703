import { connect } from "react-redux";
import { HeaderComponent } from "../../Common";
import { Tabs } from 'antd';
import Logins from "./Component/Logins";
import Report from "./Component/Report";
import { useEffect } from "react";
import TrialPage from "../../Common/TrialComponent";
import attendanceAttendanceBgImage from "../../../assets//dummyPages/attendanceAttendanceBgImage.png"

const { TabPane } = Tabs;

const OrganisationAttendanceContainer = (isTrial) => {
    useEffect(() => {
        document.title = 'Attendance | Attendance';
    });

    return (
        !isTrial ? <TrialPage trialMessage={"Manage employee attendance using Mool mobile app (also integrates with third party attendance systems)"} bgImage={attendanceAttendanceBgImage}
        /> :
            <div className="site-layout">
                <HeaderComponent show={false} name="Attendance" />
                <div className="app-main-content-container paper-wrapper ">
                    <div className="blue-tabs-container ">
                        <Tabs
                            tabPosition={"left"}
                        >
                            <TabPane tab="Daily Attendance" key="1">
                                <Logins />
                            </TabPane>
                            <TabPane tab="Report" key="2">
                                <Report />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
    )
}
const OrganisationAttendance = connect(
    (state) => ({
        isTrial: state.auth.user.isTrial,

    })
)(OrganisationAttendanceContainer);
export default OrganisationAttendance;