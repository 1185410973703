import { useState, useEffect } from "react";
import { NormalButton } from "../../../ComponentLibrary";
import { notification, Spin, Table, Modal, Row, Col } from "antd";
import {
  assignDeptBulk,
  createDept,
  getDeptData,
  getDeptUsers,
  deleteDeptUsersBulk,
  deleteDept,
} from "../../../../apis/organisation";
import { deleteLogo } from "../../../../assets/common/editAndDeleteLogos";
import blueEditIcon from "../../../../assets/icons/blueEditIcon.png";
import backArrow from "../../../../assets/icons/white-arrow-back-outline.svg";
import { createLog } from "../../../../apis/user";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";

const NewOrUpdateDepartment = ({
  setPageState,
  employeeList,
  organisationReducer,
  setUpdateOrView,
  updateOrView,
  user,
  updateOnboarding,
}) => {
  const [employeesWithNoDepartment, setEmployeesWithNoDepartment] = useState(
    []
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [departmentObj, setDepartmentObj] = useState({
    departmentName: "",
    color: "#000",
    description: "",
  });
  const [existingDepartmentObj, setExistingDepartmentObj] = useState({});
  const [existingDepartmentUsersPan, setExistingDepartmentUsersPan] = useState(
    []
  );
  const [
    existingDepartmentUsersList,
    setExistingDepartmentUsersList,
  ] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatePage, setIsUpdatePage] = useState(false);
  const [isCreatePage, setIsCreatePage] = useState(false);
  const [showDepartmentDeleteModal, setShowDepartmentDeleteModal] = useState(
    false
  );

  useEffect(() => {
    if (updateOrView.length > 0) {
      getDepartmentData();
    } else {
      setIsCreatePage(true);
      generateEmployeeList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDepartmentData = async () => {
    getDeptUsers(updateOrView).then((_res) => {
      if (_res.status === 200) {
        let existDepetOrPan = [];
        _res.data.forEach((data) => {
          existDepetOrPan.push(data.PAN);
        });
        onSelectChange(existDepetOrPan);
        setExistingDepartmentUsersPan(existDepetOrPan);
        setExistingDepartmentUsersList(_res.data);
        generateEmployeeList(_res.data);
      }
    });
    let res = await getDeptData(updateOrView);
    if (res.status === 200) {
      setDepartmentObj(res.data);
      setExistingDepartmentObj(res.data);
    }
  };

  const generateEmployeeList = async (existingEmployeeList = []) => {
    let newList = await employeeList.filter((obj) =>
      obj.departmentId ? false : true
    );
    if (existingEmployeeList.length) {
      existingEmployeeList = existingEmployeeList.concat(newList);
      setEmployeesWithNoDepartment(existingEmployeeList);
    } else {
      setEmployeesWithNoDepartment(newList);
    }
    setIsLoading(false);
  };

  const onSelectChange = (rows) => {
    setSelectedRowKeys([...rows]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSave = async () => {
    setIsLoading(true);
    if (departmentObj.departmentName.length < 1) {
      notification.error({ message: "Department name cannot be empty" });
      setIsLoading(false);
      return;
    }
    if (departmentObj.color.length < 1) {
      notification.error({ message: "Select a color" });
      setIsLoading(false);
      return;
    }
    if (updateOrView.length > 0) {
      if (
        existingDepartmentObj.departmentName !== departmentObj.departmentName ||
        existingDepartmentObj.color !== departmentObj.color
      ) {
        let res = await createDept({
          ...departmentObj,
          organisationId: user.organisationId,
        });
        if (!(res.status === 200 && res.data.success)) {
          await createLog({
            name: user.displayName,
            userId: user._id,
            organisationId: user.organisationId,
            message: `User has created a department`,
            category: 14,
          });
          notification.error({
            msg: "Failed to update department, please try again",
          });
          setIsLoading(false);
        }
      }
      let deletePansFromDept = [];
      existingDepartmentUsersPan.forEach((PanNumber) => {
        let index = selectedRowKeys.indexOf(PanNumber);
        if (index === -1) {
          deletePansFromDept.push(PanNumber);
        } else {
          selectedRowKeys.splice(index, 1);
        }
      });
      let deleteResponse = "";
      let selectedRowsAddResponse = "";
      if (deletePansFromDept.length) {
        let tempObj = {
          listOfPAN: deletePansFromDept,
        };
        deleteResponse = await deleteDeptUsersBulk(
          existingDepartmentObj.departmentId,
          tempObj
        );
      }
      if (selectedRowKeys.length) {
        let tempObj = {
          listOfPAN: selectedRowKeys,
          departmentId: existingDepartmentObj.departmentId,
        };
        selectedRowsAddResponse = await assignDeptBulk(tempObj);
      }
      if (
        (deleteResponse === "" || deleteResponse.data.success) &&
        (selectedRowsAddResponse === "" || selectedRowsAddResponse.data.success)
      ) {
        await createLog({
          name: user.displayName,
          userId: user._id,
          organisationId: user.organisationId,
          message: `User has updated  a department`,
          category: 14,
        });
        notification.success({
          message:
            "Successfully updated department and assigned employees details.",
        });
      } else {
        notification.warning({
          msg:
            "Successfully updated department details, but failed to update assigned employees details",
        });
      }
      organisationReducer.getDepartment(user.organisationId);
      organisationReducer.updateEmployeeList({
        organisationId: user.organisationId,
      });
      setUpdateOrView("");
      setPageState(0);
    } else {
      let res = await createDept({
        ...departmentObj,
        organisationId: user.organisationId,
      });
      if (res.status === 200 && res.data.success) {
        await createLog({
          name: user.displayName,
          userId: user._id,
          organisationId: user.organisationId,
          message: `User has created a department`,
          category: 14,
        });
        let tempObj = {
          listOfPAN: selectedRowKeys,
          departmentId: res.data.data.departmentId,
        };
        let response = await assignDeptBulk(tempObj);
        if (response.status === 200 && response.data.success) {
          await createLog({
            name: user.displayName,
            userId: user._id,
            organisationId: user.organisationId,
            message: `User has updaed the department`,
            category: 14,
          });
          notification.success({
            message:
              "Successfully created department and assigned employees to it",
          });
        } else {
          notification.warn({
            message:
              "Successfully created department, but failed to assign employees",
          });
        }
        await updateOnboarding("department")
        organisationReducer.getDepartment(user.organisationId);
        organisationReducer.updateEmployeeList({
          organisationId: user.organisationId,
        });
        setUpdateOrView("");
        setPageState(0);
      } else {
        notification.error({
          message: "Failed to create department, please try again",
        });
      }
    }
    setIsLoading(false);
  };

  const onDelete = () => {
    setShowDepartmentDeleteModal(true);
  };

  const onDeleteConfirm = async () => {
    setIsLoading(true);
    let deleteResponse = "";
    let deptDeleteResponse = "";
    if (existingDepartmentUsersPan.length) {
      let tempObj = {
        listOfPAN: existingDepartmentUsersPan,
      };
      deleteResponse = await deleteDeptUsersBulk(
        existingDepartmentObj.departmentId,
        tempObj
      );
      if (deleteResponse.status === 200 && deleteResponse.data.success) {
        deptDeleteResponse = await deleteDept(updateOrView);
        if (
          deptDeleteResponse.status === 200 &&
          deptDeleteResponse.data.success
        ) {
          await createLog({
            name: user.displayName,
            userId: user._id,
            organisationId: user.organisationId,
            message: `User has deleted a department`,
            category: 14,
          });
          notification.success({
            message: "Successfully deleted the department",
          });
        } else {
          notification.error({
            message: "Failed to delete the department , please try again.",
          });
          setIsLoading(false);
        }

        organisationReducer.getDepartment(user.organisationId);
        organisationReducer.updateEmployeeList({
          organisationId: user.organisationId,
        });
        setUpdateOrView("");
        setPageState(0);
      } else {
        notification.error({
          message: "Failed to delete the department , please try again.",
        });
        setIsLoading(false);
      }
    } else {
      deptDeleteResponse = await deleteDept(updateOrView);
      if (deptDeleteResponse.status === 200 && deptDeleteResponse.data.success) {
        notification.success({
          message: "Successfully deleted the department",
        });
      } else {
        notification.error({
          message: "Failed to delete the department , please try again.",
        });
        setIsLoading(false);
      }

      organisationReducer.getDepartment(user.organisationId);
      organisationReducer.updateEmployeeList({
        organisationId: user.organisationId,
      });
      setUpdateOrView("");
      setPageState(0);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "currentOrganisationMeta",
      width: "15%",
      render: (currentOrganisationMeta) => (
        <div>{currentOrganisationMeta.employeeId}</div>
      ),
    },
    {
      title: "Name",
      dataIndex: "displayName",
      width: "60%",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      width: "25%",
    },
  ];

  return (
    <>
      <div className="new-or-update-department-container">
        <Spin spinning={isLoading}>
          <div className="heading-style-2-container flex justify-between align-center">
            <div className="flex align-center">
              {updateOrView.length > 0 ? <div className="backArrow-container m-right-10"><img src={backArrow} alt="" className={"arrow-left-outline"} onClick={() => {
                setPageState(0);
                setUpdateOrView("");
              }} /></div> : ""}
              <h1 className="h5-bold-20">
                {updateOrView.length > 0
                  ? departmentObj.departmentName
                  : "New Department"}
              </h1>
            </div>
            {updateOrView.length === 0 || isUpdatePage ? (
              <div>
                <NormalButton
                  type={5}
                  buttonText="Cancel"
                  margin={[0, 16]}
                  onClick={() => {
                    setPageState(0);
                    setUpdateOrView("");
                  }}
                />
                <NormalButton type={13} buttonText="Save" onClick={onSave} />              </div>
            ) : !isUpdatePage && (
              <div>
                <NormalButton
                  icon={deleteLogo}
                  type={5}
                  buttonText="Delete"
                  onClick={() => {
                    onDelete();
                  }}
                />
                <NormalButton
                  type={12}
                  icon={blueEditIcon}
                  buttonText="Edit"
                  margin={[0, 16]}
                  onClick={() => {
                    setIsUpdatePage(true);
                  }}
                />
              </div>
            )}
          </div>
          <div className="new-or-update-department-body-container">
            <Row gutter={[20, 20]} >
              <Col sm={24} xs={24} md={12} lg={12} style={{ margin: "20px 0" }}>
                <InputFieldComponent
                  className={`input-field-container input-field`}
                  labeltext="Department Name"
                  autoFocus={true}
                  disabled={!(updateOrView.length === 0 || isUpdatePage)}
                  value={departmentObj.departmentName}
                  style={{
                    display: "block",
                    width: 400
                  }}
                  onPressEnter={onSave}
                  onChange={(e) => {
                    setDepartmentObj({
                      ...departmentObj,
                      departmentName: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <div className="flex justify-between align-center m-top-20">
              <p className="h6-bold-18 m-bottom-10">Employee List</p>
            </div>
            <div>
              <Table
                dataSource={
                  isCreatePage
                    ? employeesWithNoDepartment
                    : isUpdatePage
                      ? employeesWithNoDepartment
                      : existingDepartmentUsersList
                }
                columns={columns}
                pagination={employeeList.length < 8 ? { pageSize: 8 } : false}
                rowSelection={
                  isCreatePage
                    ? rowSelection
                    : isUpdatePage
                      ? rowSelection
                      : null
                }
                rowKey={(record) => record.PAN}
                scroll={{ y: 450 }}
              />
            </div>
          </div>
        </Spin>
      </div>
      <Modal
        spinning={isLoading}
        centered
        closable={false}
        visible={showDepartmentDeleteModal}
        className="modal-container"
        footer={null}
        onCancel={() => {
          setShowDepartmentDeleteModal(false);
        }}
      >
        <Spin spinning={isLoading} size="default">
          <div className="">
            <div className="flex justify-between">
              <h1 className="app-heading-2">Delete Department</h1>
            </div>
            <div className="">
              <p className="font-size-16 m-bottom-10 font-weight-500">
                All employees under {departmentObj.departmentName} department
                would be removed from the department.
              </p>
              <p className="font-size-16 font-weight-500">
                Are you sure you want to delete the "
                {departmentObj.departmentName}" department?
              </p>
            </div>
            <div className="m-top-36  text-align-right">
              <NormalButton
                type={5}
                buttonText={"cancel"}
                margin={[0, 12]}
                onClick={() => {
                  setShowDepartmentDeleteModal(false);
                }}
              />
              <NormalButton
                type={5}
                buttonText="Delete"
                onClick={() => {
                  onDeleteConfirm();
                }}
              />
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default NewOrUpdateDepartment;
