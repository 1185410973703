import React, { useState, useEffect } from "react";
import { Layout, Button, notification } from "antd";
import { withRouter } from "react-router-dom";
import * as auth from "../../../../apis/auth";
import PasswordComponent from "../../../InputLibrary/PasswordComponent/PasswordComponent";
import ConfirmPasswordComponent from "../../../InputLibrary/ConfirmPasswordComponent/ConfirmPasswordComponent";
import InputVerifyComponent from "../../../InputLibrary/InputVerifyComponent/InputVerifyComponent";
const { Content } = Layout;

const ChangePassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState("");
  const [password, setPassword] = useState("");
  const [checkUserExist, setCheckUserExist] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [passwordlength, setPasswordlength] = useState(0);
  const passwordvalidationmessage = ["Password is Poor", "Password is moderate", "Password is Strong"];
  const [passwordvalidationindex, SetPasswordvalidationindex] = useState(0);
  const [upper, Setupper] = useState(0);
  const [lower, Setlower] = useState(0);
  const [special, Setspecial] = useState(0);
  const [number, Setnumber] = useState(0);
  const [casesmatched, Setcasesmatched] = useState(0);
  useEffect(() => {
    setPasswordlength(password.length);
    if (password.match(/[A-Z]/) != null) {
      if (upper === 0) Setcasesmatched(casesmatched + 1);
      Setupper(1);
    } else {
      if (upper === 1) Setcasesmatched(casesmatched - 1);
      Setupper(0);
    }
    if (password.match(/[a-z]/) != null) {
      if (lower === 0) Setcasesmatched(casesmatched + 1);
      Setlower(1);
    } else {
      if (lower === 1) Setcasesmatched(casesmatched - 1);
      Setlower(0);
    }
    if (password.match(/[0-9]/) != null) {
      if (number === 0) Setcasesmatched(casesmatched + 1);
      Setnumber(1);
    } else {
      if (number === 1) Setcasesmatched(casesmatched - 1);
      Setnumber(0);
    }
    if (password.match(/[!@#$%^&*]/) != null) {
      if (special === 0) Setcasesmatched(casesmatched + 1);
      Setspecial(1);
    } else {
      if (special === 1) Setcasesmatched(casesmatched - 1);
      Setspecial(0);
    }
  }, [password]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (casesmatched === 4 && passwordlength >= 8) {
      SetPasswordvalidationindex(2)
    }
    else if (casesmatched === 4 && passwordlength < 8) {
      SetPasswordvalidationindex(1);
    }
    else if (casesmatched === 3 && passwordlength >= 8) {
      SetPasswordvalidationindex(1);
    } else {
      SetPasswordvalidationindex(0);
    }
  }, [upper, lower, special, number, passwordlength]); // eslint-disable-line react-hooks/exhaustive-deps

  const changePassword = async () => {
    if(newUserName === "" || checkUserExist){
      setSubmitClicked('newUserName');
      return;
    }
    if (casesmatched !== 4 || passwordlength < 8) {
      handlepassworderror();
    }
    if (password !== confirmPassword) {
      handlepassworderror();
      return;
    }
    setLoading(true);
    if (
      !password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,100}$/)
    ) {
      handlepassworderror();
      setLoading(false);
      return;
    }
    let data = await auth.changePasswordUserName({id :props.user._id, username: newUserName, 
      password });
    if (data.data.success) {
      localStorage.setItem('mool_auth', data.data.data.auth);
      props.changePageState();
    } else {
      notification.error({ message: "Sorry unable to set the password" });
    }
    setLoading(false);
  };
  const [passworderror, setPassworderror] = useState(0);
  const [confirmpassworderror, setconfirmPassworderror] = useState(0);
  /**
   * setPassworderror(2) - it is for required fields
   * setPassworderror(1) - custom error of password field
   * setPassworderror(3) - when confirm password is not matched
   * setPassworderror(-1) - sucess message when paswords are matched
   */
  const handlepassworderror = () => {
    if (password === "") {
      setPassworderror(2);
    } else if (casesmatched !== 4 || passwordlength < 8) {
      setPassworderror(1);
    }
    if (casesmatched === 4 && passwordlength >= 8) {
      if (confirmPassword === "") {
        setconfirmPassworderror(2);
      } else {
        setconfirmPassworderror(1);
      }
    }
  }

  const [passwordFocused, setPasswordFocused] = useState(false);

  return (
      <Content className="stages">
        <div className="main-container">
          <p className="header-title">Don't worry you can change it whenever you want</p>

          {/* <InputFieldComponent margin={['30','0','20','0']} width={'100%'} maxWidth={'25rem'} required={true}
          labeltext={'User Name'} setSubmitClicked={setSubmitClicked} submitClicked={submitClicked}
          onChange={(e)=>{setNewUserName(e.target.value)}} value={newUserName}/> */}
          
          <InputVerifyComponent margin={['30', '0', '20', '0']} width={'100%'} 
          maxWidth={'35rem'} setCheckUserExist={setCheckUserExist} required={true} uniqueKey={'newUserName'}
            labeltext={'User Name'} onChange={(e) => { setNewUserName(e.target.value) }} 
            value={newUserName} 
            setSubmitClicked={setSubmitClicked} submitClicked={submitClicked}
            />
          <div className="password-container">
            {/* <p className="header-display"></p> */}
            <div
              className="w-full position-relative"
              style={{ marginTop: 10 }}
              onFocus={e => {
                if (e.target !== e.currentTarget) { setPasswordFocused(true) }
              }}
              onBlur={e => {
                if (e.target !== e.currentTarget) { setPasswordFocused(false) }
              }}
            >
              <PasswordComponent setPassworderror={setPassworderror} passworderror={passworderror}
                width={'100%'} maxWidth={'35rem'} margin={['0', '0', '10', '0']}
                labeltext={'Enter a new Password'}
                value={password} onChange={(e) => setPassword(e.target.value)} required={true} />
            </div>
            {
              (<div className={`validationContainer ${(!passwordFocused && password === "")?
            ``:`${(passwordFocused || !(!passwordFocused &&
              casesmatched === 4 && passwordlength >= 8)) && `active`}`}`}>

                <div className='passwordstrengthcontainer'>
                  <div className={passwordvalidationindex > 0 ? `${passwordvalidationindex > 1 ?
                    'strongStrength' : 'mediumStrength'}` : 'poorStrength'}></div>
                  <div className={passwordvalidationindex > 0 ? `${passwordvalidationindex > 1 ?
                    'strongStrength' : 'mediumStrength'}` : 'defaultStrength'}></div>
                  <div className={passwordvalidationindex > 1 ? 'strongStrength' : 'defaultStrength'}></div>
                </div>

                <div className="strength_message">
                  <h4 className={passwordvalidationindex > 0 ? `${passwordvalidationindex > 1 ?
                    'strong' : 'medium'}` : 'poor'}>{passwordvalidationmessage[passwordvalidationindex]}</h4>
                </div>

                <div className='validation_check'>
                  <div className='validation'>
                    <div className={upper ? 'active' : 'inactive'}></div>
                    <p className={upper ? 'active_validation_msg' : 'inactive_validation_msg'}>
                      One Uppercase Character</p>
                  </div>
                  <div className='validation'>
                    <div className={lower ? 'active' : 'inactive'}></div>
                    <p className={lower ? 'active_validation_msg' : 'inactive_validation_msg'}>
                      One lowercase Character</p>
                  </div>
                </div>
                <div className='validation_check'>
                  <div className='validation'>
                    <div className={special ? 'active' : 'inactive'}></div>
                    <p className={special ? 'active_validation_msg' : 'inactive_validation_msg'}>
                      One Special Character</p>
                  </div>
                  <div className='validation'>
                    <div className={passwordlength >= 8 ? 'active' : 'inactive'}></div>
                    <p className={passwordlength >= 8 ? 'active_validation_msg' : 'inactive_validation_msg'}>
                      8-character Minimum</p>
                  </div>
                </div>
                <div className='validation_check'>
                  <div className='validation'>
                    <div className={number ? 'active' : 'inactive'}></div>
                    <p className={number ? 'active_validation_msg' : 'inactive_validation_msg'}>
                      One Numeric Character</p>
                  </div>
                </div>

              </div>)}
            <div
              className="w-full position-relative"
              style={{ marginTop: 10 }}
            >
              <ConfirmPasswordComponent
                required={true}
                password={password}
                setPassworderror={setconfirmPassworderror}
                disabled={!(casesmatched === 4 && passwordlength >= 8)}
                passworderror={confirmpassworderror} width={'100%'} maxWidth={'35rem'}
                margin={['30', '0', '10', '0']}
                labeltext={'Re-Enter Password'} value={confirmPassword} onChange={(e) =>
                  setConfirmPassword(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="submit-container-password">
          <Button
            loading={loading}
            onClick={changePassword}
            className="submit-button"
            style={{ background: '#405cd2', padding:'15px', height:'60px', fontSize:'1.125rem' }}
            htmlType="submit"
          >
            Save and Continue
          </Button>
        </div>
      </Content>
  );
};

export default withRouter(ChangePassword);
