import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { notification, Spin, Table } from 'antd';
import { connect } from "react-redux";
import React, { useEffect, useState } from 'react';
import { fetchActiveEmployees, newPayoutBeneficiary } from "../../apis/organisation";
import { NormalButton } from '../ComponentLibrary';

const UpdateBeneficiary = ({ user, clearAndCloseAddNewBeneficiaryPage }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [employeeList, setEmployeeList] = useState([])
    const columns = [

        {
            key: "employeeId",
            title: "Employee Id",
            dataIndex: ["currentOrganisationMeta", "employeeId"],
        },
        {
            key: "displayName",
            title: "Name",
            dataIndex: "displayName",
        },
        {
            key: "",
            title: "Username",
            dataIndex: "username",
        },
        {
            key: "primaryEmail",
            title: "Email",
            dataIndex: "primaryEmail",
        },
        {
            key: "bankName",
            title: "Bank Name",
            dataIndex: ["bankInfo", "bankName"],
        },
        {
            key: "accountNumber",
            title: "Account Number",
            dataIndex: ["bankInfo", "accountNumber"],
        },
        {
            key: "ifsc",
            title: "IFSC Code",
            dataIndex: ["bankInfo", "ifsc"],
        },
        {
            key: "username",
            title: "Is Eligible",
            dataIndex: "username",
            render: (key, data) => {
                const { bankInfo } = data
                const accountNumber = bankInfo && bankInfo.accountNumber ? bankInfo.accountNumber : "";
                const IFSC = bankInfo && bankInfo.ifsc ? bankInfo.ifsc : "";
                const address = bankInfo && bankInfo.bankAddress ? bankInfo.bankAddress : "";
                const pin = bankInfo && bankInfo.pinCode ? bankInfo.pinCode : "";
                const state = bankInfo && bankInfo.state ? bankInfo.state : "";
                const city = bankInfo && bankInfo.city ? bankInfo.city : "";
                return (<p>{accountNumber && IFSC && address && city && state && pin ? <CheckCircleOutlined style={{ fontSize: "20px", color: 'green' }} /> : <CloseCircleOutlined style={{ fontSize: "20px", color: 'red' }} />}</p>)
            }
        },
    ];


    const [disabled] = useState(false);


    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    useEffect(() => {
        if (user && user.organisationId) {
            fetchActiveEmployees(user.organisationId).then(res => {
                setEmployeeList(res.data)
            }).catch(err => {
                setEmployeeList([])
            })
        } else {
            setEmployeeList([])

        }
    }, [user])
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys([...newSelectedRowKeys]);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };

    const onSave = async () => {
        if (selectedRowKeys.length === 0) {
            notification.warning({
                message: "Please select at least one employee to process the payroll",
            });
            return;
        }
        setIsLoading(true);
        const newEmployeeList = employeeList.filter(item => selectedRowKeys.includes(item._id.toString()))
        for (const row of newEmployeeList) {
            const { bankInfo } = row
            const accountNumber = bankInfo && bankInfo.accountNumber ? bankInfo.accountNumber : "";
            const IFSC = bankInfo && bankInfo.ifsc ? bankInfo.ifsc : "";
            if (!accountNumber && !IFSC) {
                continue;
            }
            await newPayoutBeneficiary({
                id: row._id,
                name: row.displayName,
                email: row.primaryEmail,
                phone: row.phone,
                bankAccount: bankInfo.accountNumber,
                IFSC: bankInfo.ifsc,
                address1: bankInfo.bankAddress,
                state: bankInfo.state,
                city: bankInfo.city,
                pincode: bankInfo.pinCode,
                organisationId: row.organisationId,
            });
        }
        notification.success({
            message: "Beneficiary Saved Successfully!"
        })
        setIsLoading(false);
    }

    return (
        <div className="app-main-content-container paper-wrapper" >
            <Spin spinning={isLoading}>
                <div className='flex justify-between'>
                    <div>
                        <h2>Create / Update Beneficiary ({employeeList ? employeeList.length : 0})</h2>
                        <p>Select Employee(s) to Update/Create their Beneficiary Record.</p>
                    </div>
                    <div>
                        <NormalButton
                            type={5}
                            buttonText="Cancel"
                            margin={[0, 16]}
                            onClick={() => { clearAndCloseAddNewBeneficiaryPage() }}
                        />
                        <NormalButton
                            type={13}
                            disabled={disabled}
                            buttonText={"Update"}
                            onClick={onSave}
                        />
                    </div>
                </div>
                <div className="w-full" style={{ marginTop: "30px" }}>
                    <Table rowKey={"_id"} rowSelection={rowSelection} dataSource={employeeList} columns={columns} pagination={false}
                        scroll={{ y: 600 }} />
                </div>
            </Spin>
        </div >

    )
}

const UpdateBeneficiaryContainer = connect((state) => ({
    user: state.auth.user,
}))(UpdateBeneficiary);

export default UpdateBeneficiaryContainer;
