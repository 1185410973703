/* eslint-disable jsx-a11y/anchor-is-valid */
import { Select, Skeleton } from "antd";
import { useEffect, useState } from "react";
import employee from "../../../redux/modules/employee";
import auth from "../../../redux/modules/auth";
import config, { checkFinancialYear, monthList, rootUrl } from "../../../utils/config";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "antd/lib/modal/Modal";
import axios from "../../../apis";
import { getPayslipHTML } from "../../../apis/organisation";
import { NormalButton } from "../../ComponentLibrary";
import { getPayslipsByFy } from "../../../apis/employee";
const { Option } = Select;
const Payslip = ({ employeeReducer, user, financialYear, month, freezeInfo, organisationSettings, orgInfo }) => {
  const [showModal, setShowModal] = useState(false);
  const [payslipHtml, setPayslipHtml] = useState(null);
  const [clickedPayslip, setClickedPayslip] = useState(null);
  const currentDate = new Date().getDate();
  const [payslipDisplay, setPayslipDisplay] = useState([]);
  const [currFinancialYear, setCurrFinancialYear] = useState(
    checkFinancialYear().financialYear
  );
  const [financialYearList, setFinancialYearList] = useState([]);

  useEffect(() => {
    function calculateFinancialYear() {
      let startFYear = 2020;
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let resFinancialYearList = [];
      for (let i = startFYear; i < currentYear + 1; i++) {
        let nextYear = i + 1;
        let tempFYear = `${i}-${nextYear}`;
        resFinancialYearList.push(tempFYear);
      }
      resFinancialYearList.reverse();
      setFinancialYearList([...resFinancialYearList]);
    }
    calculateFinancialYear();
  }, []);

  useEffect(() => {
    getPayslipsByFy(user._id, currFinancialYear).then(res => {
      if (res.data) {
        let sortedPayslip = res.data.sort((a, b) => a.month - b.month);
        setPayslipDisplay([...sortedPayslip]);
      }
    })
  }, [currFinancialYear, employeeReducer, user._id])


  const handleViewClick = async (payslipData) => {
    setShowModal(true);
    setClickedPayslip(payslipData);
    let { financialYear, month } = payslipData

    let payslipHTML = await getPayslipHTML(`${user._id}/${month}/${financialYear}`);
    if (payslipHTML.status !== 200) {
      setShowModal(false);
      setPayslipHtml(null);
      setClickedPayslip(null);
      return;
    }
    setPayslipHtml(payslipHTML.data);
  };

  const handleAction = async (url) => {
    let res = await axios({
      url, //your url
      method: 'GET',
      responseType: 'blob',
    })
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = urlDownload;
    link.setAttribute('download', `payslip-${monthList[clickedPayslip.month]}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
  return (
    <div className="payslip-container">
      <div className="payslip-header-container">
        <h3 className="payslip-header">
          Payslip generates on / after{" "}
          {organisationSettings.payrollFreezeDate} of every month.
        </h3>
      </div>
      <div className="payslip-body-container">
        <div className="flex justify-between align-items">
          <h1 className="card-headers">Payslip</h1>
          <Select
            defaultValue={currFinancialYear}
            className="general-ant-field-select ml-2"
            style={{
              width: "8rem",
              marginLeft: "1rem",
              background: "none",
              padding: 0
            }}
            size="large"
            onChange={e => {
              setCurrFinancialYear(e);
            }}
            bordered={false}
          >
            {financialYearList.map((val, index) =>
              <Option key={`opt-${index}`} value={val}>
                <h3>{val}</h3>
              </Option>
            )}
          </Select>
        </div>
        <div className="monthly-payslip-container">
          <Skeleton loading={!payslipDisplay} active>
            {payslipDisplay.length > 0 &&
              payslipDisplay.map((val) => {
                if (
                  !(
                    financialYear === val.financialYear &&
                    month === val.month &&
                    freezeInfo === null
                  )
                ) {
                  return (
                    <>
                      <div className="monthly-payslips">
                        <span>
                          {val.financialYear +
                            " " +
                            config.monthList[val.month]}
                        </span>
                        <NormalButton
                          type={13}
                          buttonText={"View"}
                          onClick={() => handleViewClick(val)}
                          size="large"
                          className="pay-silp-view-button"
                        />
                      </div>
                    </>
                  );
                }
                return <></>
              })}
          </Skeleton>
        </div>
        <Modal
          footer={null}
          centered
          visible={showModal}
          width={800}
          onCancel={() => {
            setShowModal(false);
            setPayslipHtml(null);
            setClickedPayslip(null);
          }}
        >
          {clickedPayslip &&
            !(
              financialYear === clickedPayslip.financialYear &&
              month === clickedPayslip.month &&
              orgInfo.organisationSettings.payrollFreezeDate > currentDate
            ) && (
              <NormalButton
                type={13}
                buttonText="Download Payslip"
                onClick={() => handleAction(`${rootUrl}/api/payslip/pdf/${clickedPayslip.user._id}/${clickedPayslip.financialYear}/${clickedPayslip.month}`)}
                className="p-4 m-top-8"
              />
            )}
          <div
            dangerouslySetInnerHTML={{ __html: payslipHtml }}
            id="current_payslip"
          ></div>

        </Modal>
      </div>
    </div >
  );
};

Payslip.propTypes = {
  user: PropTypes.object.isRequired,
  payslips: PropTypes.object.isRequired,
};

const PayslipContainer = connect(
  (state) => ({
    user: state.auth.user,
    ctc: state.employee.ctc,
    payslips: state.employee.payslips,
    organisationSettings: state.employee.organisationSettings,
    orgInfo: state.employee.orgInfo,
    financialYear: state.employee.financialYear,
    month: state.employee.month,
    freezeInfo: state.employee.freezeInfo,
    designation: state.employee.designation
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    employeeReducer: employee.getActions(dispatch),
  })
)(Payslip);

export default PayslipContainer;
