import { rootUrl, monthList } from "../../utils/config";
import moment from "moment";
import { NormalButton } from "../ComponentLibrary";
import { deleteLogo } from "../../assets/common/editAndDeleteLogos";
import CommonFunctionality from "./components/CommonFunctionality.js";
import { useState } from "react";
import { message, Select, Spin, notification, Radio, Popconfirm } from "antd";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";

let Option = Select;

const options = [
  { label: "Credit", value: "credit" },
  { label: "Debit", value: "debit" },
];

const Custom = ({
  monthCloseFields,
  startRefresh,
  user,
  employeeList,
  organisation,
  deleteData,
  loading,
  financialYearList,
  name,
}) => {
  const [isloading, setIsLoading] = useState(false);
  const [singleModalShow, setSingleModalShow] = useState(false);
  const [bulkModalShow, setBulkModalShow] = useState(false);

  const [fieldDetails, setFieldDetails] = useState({
    id: "",
    type: "",
    details: "Other",
    subDetails: "Other",
    amount: null,
    othertext: "",
    taxable: true,
    month: new Date().getMonth(),
    financialYear: "",
  });

  const submitForm = async () => {
    if (!fieldDetails.id) {
      message.error("Please select an employee");
      return;
    }

    if (!fieldDetails.financialYear) {
      message.error("Please enter Financial Year");
      return;
    }
    if (!fieldDetails.month && fieldDetails.month !== 0) {
      message.error("Please enter month");
      return;
    }
    if (!fieldDetails.type) {
      message.error("Please enter field type");
      return;
    }
    if (!fieldDetails.amount) {
      message.error("Please enter fine amount");
      return;
    }

    setIsLoading(true);
    let response = await organisation.addMonthClose({
      monthClose: {
        ...fieldDetails,
        organisationId: user.organisationId,
      },
    });
    setIsLoading(false);
    if (response.data.success) {
      notification.success({
        message: "Successfully added the arrears and bonuses details",
      });
    } else {
      notification.error({
        message: "Unable to add the arrears and bonuses  details",
      });
    }
    organisation.updateMonthClose({ organisationId: user.organisationId });
    reset();
  };

  const reset = () => {
    setFieldDetails({
      ...{
        id: "",
        type: "",
        details: "Other",
        subDetails: "Other",
        amount: null,
        othertext: "",
        taxable: true,
        month: new Date().getMonth(),
        financialYear: "",
      },
    });
    setSingleModalShow(false);
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user", "displayName"],
    },
    {
      title: "Employee Id",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
    },
    {
      title: "Added On",
      dataIndex: "createdAt",
      defaultSortOrder: "aescend",
      render: (text) => <p>{moment(text).format("ll")}</p>,
    },
    {
      title: "Applicable On",
      dataIndex: "month",
      defaultSortOrder: "aescend",
      render: (text) => <p>{monthList[text]}</p>,
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Reason",
      dataIndex: "details",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount, row) => (
        <p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
          {row.type === "debit" ? "-" : "+"}
          {amount}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (data) => (
        <Popconfirm title={"Are you sure ?"} onConfirm={() => deleteData(data)}>
          <NormalButton
            type={5}
            icon={deleteLogo}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <CommonFunctionality
      columns={columns}
      user={user}
      employeeList={employeeList}
      monthCloseFields={monthCloseFields}
      organisation={organisation}
      deleteData={deleteData}
      loading={loading}
      singleModalShow={singleModalShow}
      startRefresh={startRefresh}
      bulkModalShow={bulkModalShow}
      setSingleModalShow={setSingleModalShow}
      setBulkModalShow={setBulkModalShow}
      name={name}
      reset={reset}
      downloadURL={`${rootUrl}/api/monthclose/generate/excel/other/${user.organisationId}`}
      actionUrl={`${rootUrl}/api/monthclose/custom/parse/${user.organisationId}`}
    >
      <div className="">
        <h2 className="font-size-global-24 font-weight-global-700">
          Add Custom Amount
        </h2>
        <Spin spinning={isloading} size="large">
          <div className="org-anb-data-container p-top-15">
            <DropdownComponent
              className="general-ant-field-select"
              labeltext={"Employee Name"}
              options={employeeList.map((item) => item.displayName)}
              values={employeeList.map((item) => item._id)}
              value={fieldDetails.id}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  id: e,
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            />
          </div>

          <div className="org-anb-data-container p-top-15">
            <DropdownComponent
              className="general-ant-field-select"
              labeltext={"Financial Year"}
              options={financialYearList}
              values={financialYearList}
              value={fieldDetails.financialYear}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  financialYear: e,
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            >
              {financialYearList.map((val, index) => {
                return (
                  <Option key={`fyear-${index}`} value={val}>
                    {val}
                  </Option>
                );
              })}
            </DropdownComponent>
          </div>

          <div className="org-anb-data-container p-top-15">
            <DropdownComponent
              className="general-ant-field-select"
              labeltext={"Month"}
              value={fieldDetails.month}
              values={monthList.map((_val, index) => index)}
              options={monthList}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  month: e,
                });
              }}
              width={"100%"}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {monthList.map((val, index) => {
                return (
                  <Option key={`employees-${index}`} value={index}>
                    {val}
                  </Option>
                );
              })}
            </DropdownComponent>
          </div>

          <div className="org-anb-data-container p-top-15">
            <h3 className="general-ant-field-label">Field Type</h3>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setFieldDetails({
                  ...fieldDetails,
                  type: e.target.value,
                  details:
                    e.target.value === "credit"
                      ? "Others_Credits"
                      : "Others_Debits",
                })
              }
              value={fieldDetails.type}
              className="p-top-8 p-bottom-8"
            />
          </div>

          <div className="org-anb-data-container p-top-15">
            <InputFieldComponent
              className="general-ant-field-input"
              value={fieldDetails.othertext}
              labeltext={"Name"}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  othertext: e.target.value,
                  subDetails: e.target.value,
                });
              }}
              width={"100%"}
            />
          </div>

          <div
            style={{ marginTop: "-2em" }}
            className="org-anb-data-container p-top-15"
          >
            <InputFieldComponent
              className="general-ant-field-input"
              width={"100%"}
              type={"number"}
              labeltext={"Amount ( INR )"}
              value={parseInt(fieldDetails.amount)}
              onChange={(e) => {
                let fieldDetailsData = fieldDetails;
                setFieldDetails({
                  ...fieldDetailsData,
                  amount: isNaN(parseInt(e.target.value))
                    ? 0
                    : parseInt(e.target.value),
                });
              }}
              placeholder="Amount"
              size={"large"}
            />
          </div>

          <div className="select-button-container">
            <NormalButton
              type={13}
              buttonText={"Add to the month end data"}
              block={"100%"}
              onClick={submitForm}
              margin={[30, 0]}
            />
          </div>
        </Spin>
      </div>
    </CommonFunctionality>
  );
};

export default Custom;
