import React from "react";
import { numformatter } from "../../../utils/config";

function ItemExemption({ data, totalExemption, previousIncome }) {
  const ExemptionBody = ({ name, value }) =>
    <tr>
      <td>
        {name.toUpperCase()}
      </td>
      <td>
        {value}
      </td>
    </tr>;

  return (
    <React.Fragment>
      <tr>
        <th>Item</th>
        <th>Exemption</th>
      </tr>
      {Object.keys(data).map((val, i) =>
        <ExemptionBody name={val} value={data[val]} />
      )}
      <tr>
        <td>Previous employment exemptions</td>
        <td>
          {previousIncome.totalExemption}
        </td>
      </tr>
      <tr>
        <th>Total Exemptions</th>
        <th>
          {numformatter(
            totalExemption
              >
              0
              ? totalExemption
              : 0
          )}
        </th>
      </tr>
    </React.Fragment>
  );
}

export default ItemExemption;
