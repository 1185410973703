module.exports = {
    audit: [
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "All Employees",
            downloadURL: "reports/all_employees",
            category: "all-employee"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Employee database",
            downloadURL: "reports/e_database",
            category: "e-database"
        },

        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Wages register",
            downloadURL: "reports/wages_register",
            category: "wages-register",
        },
        {
            downlaodType: [
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Wages register Type 2",
            category: "wages-register-form",
            downloadURL: "reports/wages/report-forms"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Fines",
            category: "fine-register",
            downloadURL: "reports/register_fine"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Overtime",
            category: "overtime-register",
            downloadURL: "reports/register_overtime"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Bonus",
            category: "bonus-register",
            downloadURL: "reports/bonus_register"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Accident",
            category: "accident-register",
            downloadURL: "reports/accident"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "ESIC Contribution",
            category: "esic-contribution",
            downloadURL: "reports/esic_contribution"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "PF Contribution",
            category: "pf-contribution",
            downloadURL: "reports/pf_contribution"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "PF Register Salary",
            category: "generate-pf-register-salary",
            downloadURL: "reports/pf_register_salary"
        },
        // {
        //     fileType: "XLSX",
        //     fileName: "Leave",
        //     downloadURL: "reports/leave_register"
        // },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Workman Register",
            category: "workman-register",
            downloadURL: "reports/workman-register"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Workman Register Type 2",
            category: "workman-report",
            downloadURL: "reports/workman"
        },
        // {
        //     fileType: "XLSX",
        //     fileName: "Deduction",
        //     downloadURL: "reports/deduction"
        // },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Payslip Summary",
            category: "payslip-summary",
            downloadURL: "reports/payslip_summary"
        },
        {
            downlaodType: [
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "All Employee Card",
            category: "employment-card-list",
            downloadURL: "reports/employment_card_list"
        },
        {
            downlaodType: [
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Employees Wages Card",
            category: "wages-card-list",
            downloadURL: "reports/wages-card-list"
        },
    ],
    complaince: [
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Muster roll",
            category: "muster-roll",
            downloadURL: "reports/muster_roll"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Form T",
            category: "formt",
            downloadURL: "reports/formT"
        },
        // {
        //     fileType: "XLSX",
        //     fileName: "Form C",
        //     downloadURL: "reports/form_c"
        // },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Form D",
            category: "formd",
            downloadURL: "reports/form_d"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "ESIC Contribution Sheet",
            category: "esic-contribution-sheet",
            downloadURL: "reports/esic-contribution-register"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "PF Monthly Contribution with account wise",
            category: "pf-contribution-account",
            downloadURL: "reports/pf-contribution-account-register"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "PF Monthly Contribution Sheet",
            category: "pf-monthly-contribution-sheet",
            downloadURL: "reports/pf-contribution-register"
        },
        {
            downlaodType: [
                {
                    type: "xlsx",
                    color: "#048c7f"
                },
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "PF Annual Summary",
            category: "pf-annual-summary",
            downloadURL: "reports/pf-annual-summary-register"
        },
        // {
        //     fileType: "XLSX",
        //     fileName: "Form H",
        //     downloadURL: "reports/formH"
        // },

    ],
    employeeCards: [
        // {
        //     fileType: "XLSX",
        //     fileName: "Form A",
        //     downloadURL: "reports/form_a"
        // },
        {
            downlaodType: [
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Form Q",
            category: "formq",
            downloadURL: "reports/formq"
        },
        {
            downlaodType: [
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Employment Card",
            category: "employment_card",
            downloadURL: "reports/employment_card"
        },
        {
            downlaodType: [
                {
                    type: "pdf",
                    color: "#f26b6b"
                }
            ],
            fileName: "Service Certificate (Form XV)",
            category: "service-card",
            downloadURL: "reports/service-card"
        }
    ],
    pdf: {
        type: "pdf",
        color: "#f26b6b"
    },
    xlsx: {
        type: "xlsx",
        color: "#048c7f"
    }
}