import { Table, notification, Checkbox, Drawer } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import organisation from "../../../../redux/modules/organisation";
import ".././perqs.css";
import { getGiftAndTokenInLieu, postGiftAndTokenInLieu } from "../../../../apis/perqs";
import { NormalButton } from "../../../ComponentLibrary";
import { checkFinancialYear } from "../../../../utils/config";
import InputNumberComponent from "../../../InputLibrary/InputNumberComponent/InputNumberComponent";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import { HeaderComponent } from "../../../Common";

const { month, financialYear } = checkFinancialYear()

const GiftVoucherTokens = ({ user, employeeList }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [requestBody, setRequestBody] = useState({
    id: "",
    annualBifurcatedAmount: 0,
    recoveryAmount: 0,
    isFullRecovery: false,
    isRecovery: true,
  });


  useEffect(() => {
    getGiftAndTokenInLieu(user.organisationId)
      .then((res) => {
        let data = res.data.data;
        setData(data);
      });
  }, [user, refresh]);


  const onSave = () => {
    setLoading(true)
    postGiftAndTokenInLieu({ ...requestBody, financialYear, month }).then(res => {
      if (res.data.success) {
        notification.success({ message: "Data Saved Successfully!" })
        setOpen(false)
      } else {
        notification.error({ message: "Error while Saving The Data!" })
      }
    }).catch(err => {
      notification.error({ message: "Error while Saving The Data!" })
    })
    setRefresh(!refresh)
    setLoading(false)
  }
  const configureEmployeePerq = (record) => {
    showDrawer();
    setRequestBody({ ...record, isRecovery: record["perquisites"] && record["perquisites"][month] ? record["perquisites"][month]['isRecovery'] : true });
  }
  const columns = [
    {
      title: "",
      dataIndex: "flag",
      render: (flag) => {
        return <Checkbox checked={flag} disabled={true} />
      }
    },
    {
      title: "ID",
      dataIndex: "employeeId",
    },
    {
      title: "Name",
      dataIndex: "displayName",
    },
    {
      title: "Cost/ month (in INR)",
      dataIndex: "id",
      render: (id, data) => {
        return <p>{data.flag ? data.annualBifurcatedAmount ? data.annualBifurcatedAmount : 0 : "Not Defined"}</p>
      }
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record, index) => {
        return (
          <NormalButton
            buttonText={"Configure"}
            type={15}
            onClick={() => configureEmployeePerq(record)}
          ></NormalButton>
        );
      },
    },
  ];


  const employeeListDiv = (
    <>
      <h1 className="h5-bold-20">Employee List</h1>
      <div className="section">
        <Table
          columns={columns}
          dataSource={data ?? []}
        />
      </div>
    </>
  );



  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const info = (
    <div className="section">
      {employeeListDiv}
    </div>
  );



  const fieldSection = (
    <div className="section">
      <div style={{ marginTop: "2rem" }}>
        <p className="subtitle3-med-16">
          {
            "Amount paid by employer for gifts ( per annum ) "
          }
        </p>
        <InputNumberComponent labeltext={"Amount"} value={requestBody.annualBifurcatedAmount}
          onChange={(e) => {
            setRequestBody({
              ...requestBody,
              annualBifurcatedAmount: e
            })
          }} width={"100%"} />
        {
          requestBody.annualBifurcatedAmount > 5000 && <span className="text-danger m-20">Amount can not be more than 5000 ( per annum )</span>
        }
      </div>
      <div className="flex" style={{ marginTop: "5rem" }} >
        <NormalButton
          type={13}
          disabled={requestBody.annualBifurcatedAmount > 5000}
          onClick={onSave}
          buttonText="Save Changes"
        />
        <NormalButton type={5} onClick={() => { setOpen(false) }} buttonText="Cancel" />
      </div>
    </div>
  );



  return (

    <section className="site-layout">
      <HeaderComponent name="Gifts vouchers and tokens in lieu of such gifts"/>
      <div className="app-main-content-container paper-wrapper">
        <Spinner spinning={loading}>
          <div>
            <h2 className="app-heading-2 font-weight-700">
              Gifts vouchers and tokens in lieu of such gifts
            </h2>
            {info}
          </div>
        </Spinner>
      </div>
      <Drawer
        width={500}
        title={<><p className="subtitle3-med-16">{requestBody.displayName}</p><p className="subtitle4">{requestBody.employeeId}</p></>}
        placement="right"
        onClose={onClose}
        visible={open}
      >
        <div className="section">{fieldSection}</div>
      </Drawer>
    </section>
  );
};

const GiftVoucherTokensPerqs = connect(
  (state) => ({
    user: state.auth.user,
    organisationSettings: state.organisation.organisationSettings,
    orgLoading: state.organisation.loading,
    employeeList: state.organisation.employeeList,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(GiftVoucherTokens);

export default GiftVoucherTokensPerqs;