import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NormalButton } from "../../ComponentLibrary";
import auth from "../../../redux/modules/auth";
import organisation from "../../../redux/modules/organisation";
import { Upload, Modal, message, Col, Row, notification, Spin, Drawer } from "antd";
import Spinner from "../../ComponentLibrary/Spinner/Spinner";
import uploadLogo from "../../../assets/icons/savefile.svg";
import config from "../../../utils/config";
import {
  updateEmployerOnBoarding,
  updateOrganisationDetails
} from "../../../apis/organisation";
import { createLog, updateAddress } from "../../../apis/user";
import blueEditIcon from "../../../assets/common/blueEditIcon.svg";
import Delete from "../../../assets/common/delete.svg";
import { removeCAFromOrg } from "../../../apis/organisation";
import DefaultAvatar from "../../Common/DefaultAvatar/DefaultAvatar";
import ColorGenerator from "../../../utils/ColorGenerator";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";
import { pincodeData } from "../../../constants/common_functions"

const { Dragger } = Upload;

const GenInfo = ({
  user,
  authReducer,
  onboarding,
  organisationReducer,
  image,
  addresses,
  caUser,
}) => {
  const backgroundColor = ColorGenerator();
  const [editable, setEditable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [revokeModal, setRevokeModal] = useState(false);
  const [address, setAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    country: "India"
  });
  const [navSticky, setNavSticky] = useState(false);
  const [visible, setVisible] = useState(false);

  const [generalInformation, setGeneralInformation] = useState({
    organisationName: user.organisationName,
    pointOfContact: user.pointOfContact,
    designation: user.designation,
    displayName: user.displayName,
    organisationCode: user.organisationCode || ""
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    setNavSticky(window.pageYOffset > 80);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const onClose = () => {

    toggleEdit();
    setVisible(false);

  };
  const showDrawer = () => {
    setVisible(true);

  };

  const uploadProps = {
    name: "files",
    action: `${config.rootUrl}/api/auth/user/picture`,
    headers: {
      authorization: localStorage.getItem("mool_auth")
    },
    async onChange(info) {
      if (info.file.status === "done") {
        const { response } = info.file;
        message.success(`${info.file.name} file uploaded successfully`);
        authReducer.updateImage(response.data.image);
        await updateOnboarding("profilePicture");
        handleCancel();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const handleChange = async info => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      authReducer.updateUser(user._id);
      setLoading(false);
      handleCancel();
    }
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  useEffect(() => {
    updateOrgDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, addresses]);

  const updateOrgDetails = () => {
    setGeneralInformation({
      organisationName: user.organisationName,
      pointOfContact: user.pointOfContact,
      designation: user.designation,
      displayName: user.displayName,
      organisationCode: user.organisationCode || ""
    });
    if (addresses) {
      setAddress({
        addressLine1: addresses.addressLine1 ? addresses.addressLine1 : "",
        addressLine2: addresses.addressLine2 ? addresses.addressLine2 : "",
        landmark: addresses.landmark ? addresses.landmark : "",
        city: addresses.city ? addresses.city : "",
        state: addresses.state ? addresses.state : "",
        pincode: addresses.pincode ? addresses.pincode : "",
        country: "India"
      });
    }
  };

  const updateGeneralInformationField = (e, item) => {
    setGeneralInformation({
      ...generalInformation,
      [item]: e.target.value
    });
  };

  const toggleEdit = () => {
    if (editable) {
      updateOrgDetails();

    }
    setEditable(!editable);
    showDrawer();
  };

  const revokeCa = () => {
    setRevokeModal(true);
  };
  const removeOrganisation = async () => {
    setLoading(true);
    let res = await removeCAFromOrg({ PAN: user.PAN });
    if (res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Organisaiton user has removed the  mapped CA from the platform`,
        category: 2
      });
      organisationReducer.resetCA();
      notification.success({ message: "CA has been removed successfully" });
    } else {
      notification.error({ message: "Unable to remove CA from Organisation" });
    }
    setLoading(false);
  };

  const submitForm = async () => {
    setLoading(true);
    onClose()
    let res = await updateOrganisationDetails({
      ...generalInformation,
      id: user._id
    });
    await updateAddress({ address, id: user._id });
    if (res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Organisaiton user has updated the profile`,
        category: 2
      });
      authReducer.updateUser(user._id);
      await updateOnboarding("profile");
      notification.success({
        message: "Successfully updated the organisation details"
      });
    } else {
      notification.error({ message: "Sorry Unable to update the details" });
    }
    setEditable(false);
    setLoading(false);
  };

  const handleCancel = () => {
    setEditable(false);
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const updateOnboarding = async type => {
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployerOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id
      });
      organisationReducer.updateEmployerOnBoarding({
        organisationId: user.organisationId,
        id: user._id
      });
    }
  };

  const updateAddressField = (e, item) => {
    setAddress({
      ...address,
      [item]: e.target.value
    });
  };

  return (
    <div>
      {Object.keys(caUser).length > 0 && (
        <div
          className="ca-info-container app-white-card"
          style={{ marginBottom: "1.5rem" }}
        >
          <h1 className="get-info-heading">Associated CA</h1>
          <div className="ca-photo-name-container flex align-center justify-between">
            <div className="flex align-center justify-between">
              <div className="img-container">
                <img src={caUser.image} alt="CA" />
              </div>
              <div className="ca-info-details">
                <h4>{caUser.displayName}</h4>
                <p>
                  {caUser.currentOrganisationMeta &&
                    caUser.currentOrganisationMeta.dateOfJoining
                    ? caUser.currentOrganisationMeta.dateOfJoining
                    : ""}
                </p>
              </div>
            </div>
            <div className="flex align-center justify-center">
              <div className="ca-pan-details">
                <h5>PAN</h5>
                <h4>{caUser.PAN}</h4>
              </div>
              <NormalButton
                type={14}
                buttonText=""
                icon={Delete}
                onClick={revokeCa}
              ></NormalButton>
            </div>
          </div>
          <Modal
            visible={revokeModal}
            onCancel={() => setRevokeModal(false)}
            footer={false}
            closable={false}
            centered={true}
          >
            <Spin spinning={loading}>
              <div>
                <h4 className="app-heading-3">Are you sure you want revoke?</h4>
              </div>
              <div className="flex justify-end">
                <NormalButton
                  buttonText="Cancel"
                  type={2}
                  onClick={() => setRevokeModal(false)}
                  margin={[0, 16]}
                />
                <NormalButton
                  buttonText="Revoke"
                  type={1}
                  onClick={removeOrganisation}
                />
              </div>
            </Spin>
          </Modal>
        </div>
      )}
      <div className="gen-info-container app-white-card">
        <div>
          <div className="flex justify-between align-center">
            <p className="font-size-global-24 font-weight-global-700 m-bottom-16">General Information</p>
            <div className={navSticky && "sticky"}>
              <NormalButton
                type={12}
                icon={blueEditIcon}
                buttonText="Edit"
                onClick={toggleEdit}
              ></NormalButton>
            </div>
          </div>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Organisation Name</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{generalInformation.organisationName}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Display Name</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{generalInformation.displayName}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Organisation Code</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{generalInformation.organisationCode}</p>
            </Col>
          </Row>
        </div>

        <div style={{ marginTop: "50px" }}>
          <p className="font-size-global-24 font-weight-global-700 m-bottom-16">POC Information</p>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">POC Name</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{generalInformation.pointOfContact}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">POC Designation</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{generalInformation.designation}</p>
            </Col>
          </Row>

        </div>

        <div style={{ marginTop: "50px" }}>
          <p className="font-size-global-24 font-weight-global-700 m-bottom-16">Address Details</p>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Address</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{address.addressLine1}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Landmark</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{address.landmark}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Pincode</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{address.pincode}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">City</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{address.city}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">State</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{address.state}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">country</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{address.country}</p>
            </Col>
          </Row>
        </div>


        <Drawer
          title="Edit"
          placement="right"
          onClose={onClose}
          visible={visible}
          size={"large"}
        >
          <Spinner spinning={loading}>
            <div className="drawer">

              <div className="gen-info-header flex align-center justify-between">
                <h1 className="font-size-global-24 font-weight-global-700">General information</h1>
              </div>
              {editable && (
                <div className="flex align-center justify-start">
                  <div style={{ marginTop: "-2em" }} className="logo-container">
                    {image ? (
                      <img style={{ height: "10em", marginRight: "2em" }} src={image} alt="" className="gen-logo" />
                    ) : (
                      <DefaultAvatar
                        backgroundColor={backgroundColor}
                        user={user}
                        textSize={"medium"}
                        size={50}
                      />
                    )}
                  </div>
                  <div>
                    <NormalButton
                      type={11}
                      buttonText="Update Photo"
                      onClick={showModal}
                      margin={[8, 0]}
                    />
                    <div>
                      <div className="current-logo-holder-has-logo">
                        <Modal
                          visible={isModalVisible}
                          onOk={handleOk}
                          onCancel={handleCancel}
                          footer={null}
                          centered
                          width={400}
                          title="Upload Organisation Logo"
                        >
                          <Dragger
                            name="avatar"
                            listType="picture-card"
                            className="avatar-update"
                            maxCount={1}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            showUploadList={false}
                            {...uploadProps}
                          >
                            <p className="ant-upload-drag-icon">
                              <img
                                alt="upload"
                                src={uploadLogo}
                                height="70"
                                width="70"
                              />
                            </p>
                            <p className="ant-upload-text">
                              Drag And Drop Organisation Logo Here
                            </p>
                            <p className="ant-upload-hint">
                              or <u>Click Here</u> to upload
                            </p>
                          </Dragger>
                        </Modal>
                      </div>
                    </div>
                    <p style={{ color: "#6e6e78" }}>
                      Preferred image Size: 240 x 240 Pixels, maximum size of 1MB
                    </p>
                  </div>
                </div>
              )}
              <Row
                gutter={[60, 10]}
                className="company-data"
              // style={{ marginTop: "1rem" }}
              >
                <Col xs={24} sm={24} md={9}>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"Organisation Name"}
                        disabled={!editable}
                        block={true}
                        name="organisationName"
                        onChange={(e) => updateGeneralInformationField(e, 'organisationName')}
                        value={generalInformation.organisationName}
                      ></InputFieldComponent>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={9}>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"Display Name"}
                        disabled={!editable}
                        block={true}
                        name="displayName"
                        onChange={(e) => updateGeneralInformationField(e, 'displayName')}
                        value={generalInformation.displayName}
                      ></InputFieldComponent>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={9}>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"Organisation Code"}
                        disabled={!editable}
                        block={true}
                        name="organisationCode"
                        onChange={(e) => updateGeneralInformationField(e, 'organisationCode')}
                        value={generalInformation.organisationCode}
                      ></InputFieldComponent>
                    </div>
                  </div>
                </Col>
              </Row>
              <h3 className="font-size-global-24 font-weight-global-700">POC Information</h3>
              <Row gutter={[60, 10]} className="company-data">
                <Col xs={24} sm={24} md={9}>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"POC Name"}
                        disabled={!editable}
                        block={true}
                        name="pointOfContact"
                        onChange={(e) => updateGeneralInformationField(e, 'pointOfContact')}
                        value={generalInformation.pointOfContact}
                      ></InputFieldComponent>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={9}>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"POC Designation"}
                        disabled={!editable}
                        block={true}
                        name="designation"
                        onChange={(e) => updateGeneralInformationField(e, 'designation')}
                        value={generalInformation.designation}
                      ></InputFieldComponent>
                    </div>
                  </div>
                </Col>
              </Row>
              <h3 className="font-size-global-24 font-weight-global-700">Address Details</h3>
              <Row gutter={[60, 100]} className="company-data">
                <Col xs={24} sm={24} md={9}>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"Address"}
                        name="addressLine1"
                        onChange={e => updateAddressField(e, "addressLine1")}
                        value={address.addressLine1}
                        block={true}
                        disabled={!editable}
                      ></InputFieldComponent>
                    </div>
                  </div>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"Pincode"}
                        value={address.pincode}
                        onChange={e => {
                          updateAddressField(e, "pincode")
                          if (e.target.value.length === 6) {
                            updateAddressField(e, "pincode");
                            const data = pincodeData(e.target.value);
                            Promise.resolve(data).then((res) => {
                              setAddress({
                                ...address,
                                pincode: e.target.value,
                                city: res.Block,
                                state: res.State,
                              });
                            });
                          }
                        }}
                        name="pincode"
                        type="number"
                        block={true}
                        disabled={!editable}
                      ></InputFieldComponent>
                    </div>
                  </div>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"State"}
                        value={address.state}
                        onChange={e => updateAddressField(e, "state")}
                        name="state"
                        block={true}
                        disabled={!editable}
                      ></InputFieldComponent>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={9}>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"Landmark"}
                        value={address.landmark}
                        onChange={e => updateAddressField(e, "landmark")}
                        name="landmark"
                        block={true}
                        disabled={!editable}
                      ></InputFieldComponent>
                    </div>
                  </div>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"City"}
                        value={address.city}
                        onChange={e => updateAddressField(e, "city")}
                        name="city"
                        block={true}
                        disabled={!editable}
                      ></InputFieldComponent>
                    </div>
                  </div>
                  <div className="app-input-container">
                    <div className="company-address-individual-data-container">
                      <InputFieldComponent
                        labeltext={"Country"}
                        value={address.country}
                        onChange={e => updateAddressField(e, "country")}
                        name="country"
                        block={true}
                        disabled={!editable}
                      ></InputFieldComponent>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="button-container">
                <NormalButton buttonText="Cancel" type={5} onClick={onClose} />
                <NormalButton
                  buttonText="save changes"
                  type={13}
                  onClick={submitForm}
                />
              </div>
            </div>
          </Spinner>
        </Drawer>
      </div>
    </div>
  );
};

const GenInfoContainer = connect(
  state => ({
    addresses: state.auth.address,
    user: state.auth.user,
    image: state.auth.image,
    onboarding: state.organisation.onboarding,
    caUser: state.organisation.caUser
  }),
  dispatch => ({
    authReducer: auth.getActions(dispatch),
    organisationReducer: organisation.getActions(dispatch)
  })
)(GenInfo);

export default GenInfoContainer;
