import React from "react";
import { numformatter } from "../../../utils/config";

function Section16({ totalProffTax, previousEmployment }) {
  return (
    <React.Fragment>
      <tr>
        <th>Sl No.</th>
        <th>Description</th>
        <th>Amount</th>
      </tr>
      <tr>
        <th>
          {"("}i{")"}
        </th>
        <th>Standard Deductions</th>
        <th>
          {numformatter(50000)}
        </th>
      </tr>
      <tr>
        <th>
          {"("}ii{")"}
        </th>
        <th>Yearly Professional Tax</th>
        <th>
          {numformatter(totalProffTax)}
        </th>
      </tr>
      <tr>
        <th>
          {"("}iii{")"}
        </th>
        <th>Total Professional Tax Paid In Previous Employment</th>
        <th>
          {numformatter(previousEmployment && previousEmployment.totalProffTaxDeducted ? previousEmployment.totalProffTaxDeducted : 0)}
        </th>
      </tr>
    </React.Fragment>
  );
}

export default Section16;
