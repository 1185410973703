
const AllowanceInfo = ({ allowanceInfo }) => {
    return (
        <div className="allowance-info">
            <h1 className="allowance-info-heading1">{allowanceInfo.heading1}</h1>
            <h1 className="allowance-info-heading2">{allowanceInfo.heading2}</h1>
            <p className="allowance-info-para1 para">{allowanceInfo.para1}</p>
            <p className="allowance-info-para2 para">
                {
                    allowanceInfo.para2.split('\n').map((text) => {
                        return (
                            <span>
                                { text} <br></br>
                            </span>

                        )
                    })
                }
            </p>
            <p className="allowance-info-para4 para">{allowanceInfo.para4}</p>
        </div >
    )
}

export default AllowanceInfo;