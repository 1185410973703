/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Spin,
    message,
    Upload,
    notification,
    Table,
    Progress,
    Row,
    Col,
} from "antd";
import { updateUserOvertimeDays } from "../../apis/organisation";
import { Content } from "antd/lib/layout/layout";
import config, { monthList } from "../../utils/config";
import uploadLogo from "../../assets/icons/savefile.svg";
import { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createLog } from "../../apis/user";
import NormalButton from "../ComponentLibrary/ButtonComponents/NormalButton";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";

const yearList = [];
const currYear = new Date().getFullYear();
for (let item = currYear - 10; item < currYear + 10; item++) {
    yearList.push(item);
}

const { Dragger } = Upload;

const OvertimeDays = ({ user, handleAction, commonColumn }) => {
    const [pageState, setPageState] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [overtimeDays, setTotalWorkingDaysData] = useState([]);
    const [completed, setCompleted] = useState(0);
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(currYear);

    const heading = ["Upload the Document", "Verify and Update"];
    const uploadProps = {
        name: "file",
        action: `${config.rootUrl}/api/parser/overtimeDays/${month}/${year}`,
        headers: {
            authorization: localStorage.getItem("mool_auth"),
        },
        multiple: false,
        async onChange(info) {
            setIsLoading(true);
            let { response } = info.file;
            if (response) {
                setIsLoading(false);
                setPageState(1);
                setTotalWorkingDaysData([...response]);
            }
        },
    };

    const reset = () => {
        setTotalWorkingDaysData([]);
        setPageState(0);
        setCompleted(0);
    };

    const updateTotalWorkingDays = async () => {
        if (overtimeDays.length === 0) {
            message.error("Please Upload The File");
            return;
        }
        setIsLoading(true);
        let totalSuccess = 0;
        let { financialYear } = config.checkFinancialYear();
        for (const element of overtimeDays) {
            let { overtimeDays: overtime, month, id } = element;
            await updateUserOvertimeDays({
                financialYear,
                id,
                overtimeDays: overtime,
                month,
                year
            });
            totalSuccess += 1;
            setCompleted(totalSuccess);
        }
        await createLog({
            name: user.displayName,
            userId: user._id,
            organisationId: user.organisationId,
            message: `Total working days of the users updated successfully!`,
            category: 15,
        });
        notification.success({
            message: "Total working days of the users updated successfully!",
        });
        setIsLoading(false);
        reset();
    };

    return (
        <div className="">
            {isLoading && pageState === 1 && (
                <Progress
                    percent={Math.round((completed / overtimeDays.length) * 100)}
                    size="small"
                    status="active"
                />
            )}
            <Spin size="large" spinning={isLoading}>
                <Row gutter={[20, 20]}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div className="flex align-center">
                            {pageState !== 0 && (
                                <ArrowLeftOutlined
                                    onClick={() => {
                                        let page = pageState;
                                        page = page - 1;
                                        setPageState(page);
                                    }}
                                    style={{ marginRight: 10 }}
                                    className="arrow-left-button font-weight-700"
                                />
                            )}
                            <h3 className="font-weight-global-700 font-size-global-24">{heading[pageState]}</h3>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        {pageState === 1 && (
                            <>
                                <div className="flex w-full justify-end">
                                    <NormalButton
                                        onClick={reset}
                                        type={5}
                                        buttonText="Cancel"

                                        className="cancel-button"
                                        style={{
                                            border: "none",
                                            boxShadow: "none",
                                            marginRight: 10,
                                        }}
                                    />
                                    <NormalButton
                                        type={13}
                                        buttonText="Upload Total Working Days Detail"
                                        onClick={updateTotalWorkingDays}
                                        className="submit-button"
                                        style={{
                                            marginRight: "0px",
                                            padding: "0px 24px",
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
                {pageState === 0 && (
                    <UploadDocument
                        platforms={{ ...uploadProps }}
                        user={user}
                        handleAction={handleAction}
                        month={month}
                        setMonth={setMonth}
                        year={year}
                        setYear={setYear}
                    />
                )}
                {pageState === 1 && (
                    <VerifyAndUpload overtimeDays={overtimeDays} commonColumn={commonColumn} />
                )}
            </Spin>
        </div>
    );
};

const UploadDocument = ({ platforms, user, handleAction, setMonth, month, year, setYear }) => {
    return (
        <Content className="bulk-wrapper-left ">
            <div >
                <div>
                    <p className="paper-title">
                        Download Sample in{" "}
                        <a
                            className="download-employee-list"
                            onClick={() =>
                                handleAction(
                                    `${config.rootUrl}/api/generate/document/overtimeDays/${user.organisationId}`,
                                    "Overtime_Days.xlsx"
                                )
                            }
                        >
                            .xlsx format
                        </a>{" "}
                        file to ensure that you have the correct file ready to import.
                    </p>
                </div>
                <div className="flex  justify-end m-top-10">
                    <div className="flex">
                        <DropdownComponent
                            className="general-ant-field-select"
                            size="large"
                            value={month}
                            options={monthList}
                            values={[...new Array(12).keys()]}
                            labeltext="Select Month"
                            onChange={(e) => {
                                setMonth(e)
                            }}
                            defaultValue={new Date().getMonth()}
                            bordered={false}
                        />
                        <div className="m-right-10"></div>
                        <DropdownComponent
                            className="general-ant-field-select"
                            size="large"
                            value={year}
                            options={yearList}
                            values={yearList}
                            labeltext="Select Year"
                            onChange={(e) => {
                                setYear(e)
                            }}
                            showSearch={true}
                            defaultValue={new Date().getFullYear()}
                            bordered={false}
                        />
                    </div>
                </div>
            </div>
            <div>
                <Dragger
                    {...platforms}
                    showUploadList={false}
                    height={300}
                    className="bulk-wrapper-dragger"
                >
                    <p className="ant-upload-drag-icon">
                        <img alt="" src={uploadLogo} height="80" width="80" />
                    </p>

                    <p className="ant-upload-text">Drag and drop CSV file</p>
                    <p className="ant-upload-hint">
                        or <u>Click Here</u> to upload
                    </p>
                </Dragger>
            </div>
        </Content>
    );
};

const VerifyAndUpload = ({ overtimeDays, commonColumn }) => {
    const tableData = [
        ...commonColumn,
        {
            title: "Overtime Days",
            dataIndex: "overtimeDays",
            key: "overtimeDays",
            render: (data) => <span>{data}</span>,
        },
    ];

    return (
        <Table
            columns={tableData}
            dataSource={overtimeDays}
            pagination={false}
            scroll={{ x: 600, y: "65vh" }}
        />
    );
};

export default OvertimeDays;
