import { Button, Breadcrumb, DatePicker, Select, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, withRouter } from "react-router-dom";
import { HeaderComponent } from "../../Common";
import moment from "moment";
import {
  bankAdvice,
  getCurrentMclose,
  getCurrentReim,
  getPayRegister,
  getReportsData,
  getStatutory,
  getYearlyCTC,
  getYTDData
} from "../../../apis/organisation";
import axios from "../../../apis";

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  numformatter,
  monthList,
  reimbursementTypes,
  rootUrl
} from "../../../utils/config";
import blackDownload from "../../../assets/icons/blackDownload.svg";
const { Option } = Select;
const ReportsTable = ({ history, user, designation, childOrganisations }) => {
  const generateFinancialYear = () => {
    let tempMonth = Number(moment(reportDate).format("M"));
    let tempYear = Number(moment(reportDate).format("yyyy"));
    let tempFinancialYear =
      tempMonth > 3
        ? `${tempYear}-${tempYear + 1}`
        : `${tempYear - 1}-${tempYear}`;
    return tempFinancialYear;
  };
  const [reportTable, setReportTable] = useState([]);
  const [reportDate, setReportDate] = useState(moment());
  const reportState = history.location.state.data;
  const [reportData, setReportData] = useState([]);
  const [month, setMonth] = useState(Number(moment().format("M")) - 1);
  const [financialYear, setFinancialYear] = useState(generateFinancialYear());
  const [designationMapper, setDesignationMapper] = useState({});
  const [selectedOrganisationId, setSelectedOrganisationId] = useState("");
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    document.title = 'Reports | Table';
  });
  // TODO: Write the update function here.
  const handelDateChange = date => {
    let tempMonth = Number(moment(date).format("M"));
    let tempYear = Number(moment(date).format("yyyy"));
    let tempFinancialYear =
      tempMonth > 3
        ? `${tempYear}-${tempYear + 1}`
        : `${tempYear - 1}-${tempYear}`;
    setMonth(tempMonth - 1);
    setFinancialYear(tempFinancialYear);
    setReportDate(date);
  };

  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }
  useEffect(
    () => {
      let desMapper = {};
      for (let des of designation) {
        desMapper[des.designationId] = des.designationName;
      }
      setDesignationMapper({ ...desMapper });
    },
    [designation, isRefresh]
  );

  useEffect(
    () => {
      setSelectedOrganisationId(user.organisationId);
    },
    [user, isRefresh]
  );

  useEffect(
    () => {
      switch (reportState.type) {
        case "payRegister": {
          getPayRegister(
            `${selectedOrganisationId}/${month}/${financialYear}`
          ).then(res => {
            if (res.status === 200) {
              setReportData(res.data.payRegister);
              setReportTable(
                res.data.columnArray ? [...res.data.columnArray] : []
              );
            }
          });
          break;
        }
        case "general": {
          getReportsData(
            `${selectedOrganisationId}/${month}/${financialYear}`
          ).then(res => {
            if (res.status === 200) {
              setReportData(res.data);
            }
          });
          break;
        }
        case "ctc": {
          getYearlyCTC(
            `${selectedOrganisationId}/${month}/${financialYear}`
          ).then(res => {
            if (res.status === 200) {
              setReportData(res.data);
            }
          });
          break;
        }
        case "ytd": {
          getYTDData(
            `${selectedOrganisationId}/${month}/${financialYear}`
          ).then(res => {
            if (res.status === 200) {
              setReportData(res.data);
            }
          });
          break;
        }
        case "mclose": {
          getCurrentMclose(
            `${selectedOrganisationId}/${month}/${financialYear}`
          ).then(res => {
            if (res.status === 200) {
              setReportData(res.data);
            }
          });
          break;
        }
        case "reim": {
          getCurrentReim(
            `${selectedOrganisationId}/${month}/${financialYear}`
          ).then(res => {
            if (res.status === 200) {
              setReportData(res.data);
            }
          });
          break;
        }
        case "statutory": {
          getStatutory(
            `${selectedOrganisationId}/${month}/${financialYear}`
          ).then(res => {
            if (res.status === 200) {
              setReportData(res.data);
            }
          });
          break;
        }
        case "bankInfo": {
          bankAdvice(
            `${selectedOrganisationId}/${month}/${financialYear}`
          ).then(res => {
            if (res.status === 200) {
              setReportData(res.data);
            }
          });
          break;
        }
        default:
          break;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [selectedOrganisationId, month, financialYear, reportState.type, isRefresh]
  );

  const reportTabelRows = {
    "PF Register": [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name"
      },
      {
        title: "UAN",
        dataIndex: ["user", "credentials", "UAN"],
        key: "UAN",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {data}
            </p>
      },
      {
        title: "PF No",
        dataIndex: ["user", "credentials", "pfNo"],
        key: "UAN",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {data}
            </p>
      },
      {
        title: "PF Salary (In INR)",
        dataIndex: ["finalPayslip", "employer_PF"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(Math.ceil(data * 8.333))}
            </p>
      },
      {
        title: "ELDI Wage (In INR)",
        dataIndex: ["finalPayslip", "employer_PF"],
        key: "grossSalary",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(Math.ceil(data * 8.333))}
            </p>
      },
      {
        title: "Pension Wage (In INR)",
        dataIndex: ["finalPayslip", "employer_PF"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(Math.ceil(data * 8.333))}
            </p>
      },
      {
        title: "Gross Salary (In INR)",
        dataIndex: ["finalPayslip", "totalEarnings"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {data}
            </p>
      },
      {
        title: "Self Contribution(In INR)",
        dataIndex: ["finalPayslip", "employer_PF"],
        key: "EmployeeContribution",
        render: (data, _row) =>
          !data
            ? <p>0</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Employer Contribution(In INR)",
        dataIndex: ["finalPayslip", "employer_PF"],
        key: "EmployerContribution",
        render: (data, _row) =>
          !data
            ? <p>0</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Volunter PF Contribution(In INR)",
        dataIndex: ["finalPayslip", "employee_PF"],
        key: "EmployeeContribution",
        render: (data, _row) =>
          !data
            ? <p>0</p>
            : <p>
              {numformatter(data - _row.finalPayslip.employer_PF)}
            </p>
      },
      {
        title: "A/C 01 (In INR)",
        dataIndex: ["finalPayslip", "employer_PF"],
        key: "EmployerContribution",
        render: (data, _row) => {
          if (data === 0) return <p>0</p>;
          let proxyBasicDa = data * 8.33;
          proxyBasicDa = proxyBasicDa < 15000 ? proxyBasicDa : 15000;
          let eps = Math.ceil(proxyBasicDa * 0.0833);
          let epf = numformatter(data - eps);
          return (
            <p>
              {epf}
            </p>
          );
        }
      },
      {
        title: "A/C 02 (In INR)",
        dataIndex: ["finalPayslip", "employer_PF"],
        key: "EmployerContribution",
        render: (data, _row) =>
          <p>
            {_row.finalPayslip.employee_PF === 0
              ? 0
              : Math.ceil(data * 8.333 * 0.005) > 75
                ? 75
                : Math.ceil(data * 8.333 * 0.005)}
          </p>
      },
      {
        title: "A/C 10 (In INR)",
        dataIndex: ["finalPayslip", "employer_PF"],
        key: "EmployerContribution",
        render: (data, _row) => {
          if (data === 0) return <p>0</p>;
          let proxyBasicDa = data * 8.33;
          proxyBasicDa = proxyBasicDa < 15000 ? proxyBasicDa : 15000;
          let eps = numformatter(Math.ceil(proxyBasicDa * 0.0833));
          return (
            <p>
              {eps}
            </p>
          );
        }
      },
      {
        title: "A/C 21 (In INR)",
        dataIndex: ["finalPayslip", "employer_PF"],
        key: "EmployerContribution",
        render: (data, _row) =>
          <p>
            {_row.finalPayslip.employer_PF === 0
              ? 0
              : numformatter(
                Math.ceil(data * 8.333 * 0.005) > 75
                  ? 75
                  : Math.ceil(data * 8.333 * 0.005)
              )}
          </p>
      },
      {
        title: "Total Employer Contribution(In INR)",
        dataIndex: ["finalPayslip", "employer_PF"],
        key: "EmployerContribution",
        render: (data, _row) =>
          !data
            ? <p>0</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Total PF(In INR)",
        key: "Total_PF",
        render: data =>
          <span>
            {data.finalPayslip.employer_PF + data.finalPayslip.employee_PF}
          </span>
      }
    ],
    "ESI Register": [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "Gross Salary",
        dataIndex: ["finalPayslip", "totalEarnings"],
        key: "grossSalary",
        width: "15%"
      },
      {
        title: "Employee Contribution",
        dataIndex: ["finalPayslip", "employee_ESI"],
        key: "EmployeeContribution",
        width: "15%"
      },
      {
        title: "Employer Contribution",
        dataIndex: ["finalPayslip", "employer_ESI"],
        key: "EmployerContribution",
        width: "15%"
      },
      {
        title: "Total PF",
        key: "Total_PF",
        width: "15%",
        render: data =>
          <span>
            {data.finalPayslip.employer_ESI + data.finalPayslip.employee_ESI}
          </span>
      }
    ],
    "Salary Register": [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "Take Home Amount",
        dataIndex: ["user", "regimeOptedStatus"],
        key: "takeHome",
        width: "15%",
        render: (text, _row) =>
          <p>
            {text === 1
              ? numformatter(_row.finalPayslip.newRegime.takeHome)
              : numformatter(_row.finalPayslip.oldRegime.takeHome)}
          </p>
      },
      {
        title: "IT Regime",
        dataIndex: ["user", "regimeOptedStatus"],
        key: "takeHome",
        width: "15%",
        render: text =>
          <p>
            {text === 1 ? "New Regime" : "Old Regime"}
          </p>
      },
      {
        title: "Income Tax",
        dataIndex: ["user", "regimeOptedStatus"],
        key: "takeHome",
        width: "15%",
        render: (text, _row) =>
          <p>
            {text === 1
              ? numformatter(
                _row.finalPayslip.newRegime.incomeTax +
                (isNaN(parseInt(_row.finalPayslip.advanceTax))
                  ? 0
                  : _row.finalPayslip.advanceTax)
              )
              : numformatter(
                _row.finalPayslip.oldRegime.incomeTax +
                (isNaN(parseInt(_row.finalPayslip.advanceTax))
                  ? 0
                  : _row.finalPayslip.advanceTax)
              )}
          </p>
      },
      {
        title: "Total Deduction",
        dataIndex: ["user", "regimeOptedStatus"],
        key: "takeHome",
        width: "15%",
        render: (text, _row) =>
          <p>
            {text === 1
              ? numformatter(_row.finalPayslip.newRegime.deductions)
              : numformatter(_row.finalPayslip.oldRegime.deductions)}
          </p>
      },
      {
        title: "Total Earning",
        dataIndex: ["finalPayslip", "totalFixedIncome"],
        key: "takeHome",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data)}
            </p>
      }
    ],
    Salary: [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "IT Regime",
        dataIndex: ["user", "regimeOptedStatus"],
        key: "takeHome",
        width: "15%",
        render: text =>
          <p>
            {text === 1 ? "New Regime" : "Old Regime"}
          </p>
      },
      {
        title: "Income Tax(In INR)",
        dataIndex: ["user", "regimeOptedStatus"],
        key: "takeHome",
        width: "15%",
        render: (text, _row) =>
          <p>
            {text === 1
              ? numformatter(
                _row.finalPayslip.newRegime.incomeTax +
                (isNaN(parseInt(_row.finalPayslip.advanceTax))
                  ? 0
                  : _row.finalPayslip.advanceTax)
              )
              : numformatter(
                _row.finalPayslip.oldRegime.incomeTax +
                (isNaN(parseInt(_row.finalPayslip.advanceTax))
                  ? 0
                  : _row.finalPayslip.advanceTax)
              )}
          </p>
      },
      {
        title: "Total Deduction(In INR)",
        dataIndex: ["user", "regimeOptedStatus"],
        key: "takeHome",
        width: "15%",
        render: (text, _row) =>
          <p>
            {text === 1
              ? numformatter(_row.finalPayslip.newRegime.deductions)
              : numformatter(_row.finalPayslip.oldRegime.deductions)}
          </p>
      },
      {
        title: "Total Earning(In INR)",
        dataIndex: ["finalPayslip", "totalEarnings"],
        key: "takeHome",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Take Home Amount",
        dataIndex: ["user", "regimeOptedStatus"],
        key: "takeHome",
        width: "15%",
        render: (text, _row) =>
          <p>
            {text === 1
              ? numformatter(_row.finalPayslip.newRegime.takeHome)
              : numformatter(_row.finalPayslip.oldRegime.takeHome)}
          </p>
      }
    ],
    "Tax Deduction": [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "PAN",
        dataIndex: ["user", "PAN"],
        key: "PAN",
        width: "15%"
      },
      {
        title: "Gross Earning",
        dataIndex: ["finalPayslip", "totalEarnings"],
        key: "Earning",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>0</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Tax",
        dataIndex: ["finalPayslip", "oldRegime", "incomeTax"],
        key: "Tax",
        width: "15%",
        render: (_data, _row) => {
          let incometax =
            _row.user.regimeOptedStatus === 1
              ? _row.finalPayslip.newRegime.incomeTax
              : _row.finalPayslip.oldRegime.incomeTax;
          let surcharge =
            _row.user.regimeOptedStatus === 1
              ? _row.finalPayslip.newRegime.surcharge
              : _row.finalPayslip.oldRegime.surcharge;
          surcharge = surcharge ? parseInt(surcharge) : 0;
          let cess =
            _row.user.regimeOptedStatus === 1
              ? _row.finalPayslip.newRegime.cess
              : _row.finalPayslip.oldRegime.cess;
          if (!cess) {
            cess = Math.ceil(incometax * 0.038461);
          }
          let tax = incometax - cess - surcharge;
          return (
            <p>
              {numformatter(tax)}
            </p>
          );
        }
      },
      {
        title: "Cess",
        key: "Cess",
        dataIndex: ["finalPayslip", "oldRegime", "incomeTax"],
        width: "15%",
        render: (_data, _row) => {
          return _row.user.regimeOptedStatus === 1
            ? <p>
              {_row.finalPayslip.newRegime.cess
                ? numformatter(_row.finalPayslip.newRegime.cess)
                : numformatter(
                  Math.ceil(_row.finalPayslip.newRegime.incomeTax * 0.0385)
                )}
            </p>
            : <p>
              {_row.finalPayslip.oldRegime.cess
                ? numformatter(_row.finalPayslip.oldRegime.cess)
                : numformatter(
                  Math.ceil(_row.finalPayslip.oldRegime.incomeTax * 0.0385)
                )}
            </p>;
        }
      },
      {
        title: "Surcharge",
        key: "Surcharge",
        dataIndex: ["finalPayslip", "oldRegime", "incomeTax"],
        width: "15%",
        render: (_data, _row) => {
          return _row.user.regimeOptedStatus === 1
            ? <p>
              {numformatter(
                _row.finalPayslip.newRegime.surcharge
                  ? _row.finalPayslip.newRegime.surcharge
                  : 0
              )}
            </p>
            : <p>
              {numformatter(
                _row.finalPayslip.oldRegime.surcharge
                  ? _row.finalPayslip.oldRegime.surcharge
                  : 0
              )}
            </p>;
        }
      },
      {
        title: "TDS ",
        key: "Total",
        width: "15%",
        render: (_data, _row) => {
          return _row.user.regimeOptedStatus !== 1
            ? <p>
              {numformatter(_row.finalPayslip.oldRegime.incomeTax)}
            </p>
            : <p>
              {numformatter(_row.finalPayslip.newRegime.incomeTax)}
            </p>;
        }
      }
    ],
    "Professional Tax": [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "PAN",
        dataIndex: ["user", "PAN"],
        key: "Name",
        width: "15%"
      },
      {
        title: "Gross Salary",
        dataIndex: ["finalPayslip", "totalEarnings"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Professional Tax",
        dataIndex: ["finalPayslip", "proffTax"],
        key: "professionalTax",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data)}
            </p>
      }
    ],
    "Bank Advice": [
      {
        title: "Employee ID",
        dataIndex: ["employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "Bank Name",
        dataIndex: ["bankName"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {data}
            </p>
      },
      {
        title: "Account Number",
        dataIndex: ["accountNumber"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {data}
            </p>
      },
      {
        title: "IFSC Number",
        dataIndex: ["ifsc"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {data}
            </p>
      },
      {
        title: "Net Salary(INR)",
        dataIndex: ["takeHome"],
        key: "grossSalary",
        width: "15%",
        render: (_data, _row) => numformatter(_data)
      }
    ],
    "Monthly CTC": [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "Designation",
        dataIndex: ["user", "designationId"],
        width: "15%",
        render: (data, _row) =>
          data
            ? <p>
              {designationMapper[data]}
            </p>
            : <p>N/A</p>
      },
      {
        title: "Date of Joining",
        dataIndex: ["user", "currentOrganisationMeta", "dateOfJoining"],
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {new Date(data).toDateString()}
            </p>
      },
      {
        title: "Earning(In INR)",
        dataIndex: ["finalPayslip", "totalEarnings"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Deductions(In INR)",
        dataIndex: ["finalPayslip", "oldRegime", "takeHome"],
        key: "grossSalary",
        width: "15%",
        render: (_data, _row) => {
          return _row.user.regimeOptedStatus !== 1
            ? <p>
              {numformatter(_row.finalPayslip.oldRegime.deductions)}
            </p>
            : <p>
              {numformatter(_row.finalPayslip.newRegime.deductions)}
            </p>;
        }
      },
      {
        title: "Take Home Salary(In INR)",
        dataIndex: ["finalPayslip", "monthlyCTC"],
        key: "grossSalary",
        width: "15%",
        render: (_data, _row) => {
          return _row.user.regimeOptedStatus !== 1
            ? <p>
              {numformatter(_row.finalPayslip.oldRegime.takeHome)}
            </p>
            : <p>
              {numformatter(_row.finalPayslip.newRegime.takeHome)}
            </p>;
        }
      },
      {
        title: "Monthly CTC(In INR)",
        dataIndex: ["finalPayslip", "monthlyCTC"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data - _row.finalPayslip.monthlyVariable)}
            </p>
      }
    ],
    "Yearly CTC": [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "Designation",
        dataIndex: ["user", "designationId"],
        width: "15%",
        render: (data, _row) =>
          data
            ? <p>
              {designationMapper[data]}
            </p>
            : <p>N/A</p>
      },
      {
        title: "Fixed Cost(In INR)",
        dataIndex: "fixedIncome",
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          <p>
            {numformatter(data)}
          </p>
      },
      {
        title: "Variable Cost(In INR)",
        dataIndex: ["variableIncome"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          <p>
            {numformatter(data)}
          </p>
      },
      {
        title: "Total CTC(In INR)",
        dataIndex: ["total"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          <p>
            {numformatter(data)}
          </p>
      }
    ],
    "YTD Earning": [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "Designation",
        dataIndex: ["user", "designationId"],
        width: "15%",
        render: (data, _row) =>
          data
            ? <p>
              {designationMapper[data]}
            </p>
            : <p>N/A</p>
      },
      {
        title: "Earning(In INR)",
        dataIndex: ["earning"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Deductions(In INR)",
        dataIndex: ["deduction"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Take Home(In INR)",
        dataIndex: ["takeHome"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data)}
            </p>
      }
    ],
    "Statutory Bonus": [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "Designation",
        dataIndex: ["user", "designationId"],
        width: "15%",
        render: (data, _row) =>
          data
            ? <p>
              {designationMapper[data]}
            </p>
            : <p>N/A</p>
      },
      {
        title: "Wages(In INR)",
        dataIndex: ["finalPayslip", "totalEarnings"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Statutory Bonus(In INR)",
        dataIndex: ["finalPayslip", "statutoryBonuse"],
        key: "grossSalary",
        width: "15%",
        render: (data, _row) => {
          return (
            <p>
              {data}
            </p>
          );
        }
      }
    ],
    Reimbursement: [
      {
        title: "Employee ID",
        dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
        key: "Employee_ID",
        width: "15%"
      },
      {
        title: "Name",
        dataIndex: ["user", "displayName"],
        key: "Name",
        width: "15%"
      },
      {
        title: "Designation",
        dataIndex: ["user", "designationId"],
        width: "15%",
        render: (data, _row) =>
          data
            ? <p>
              {designationMapper[data]}
            </p>
            : <p>N/A</p>
      },
      {
        title: "Category",
        dataIndex: "type",
        width: "15%",
        render: data =>
          <p>
            {reimbursementTypes[data]}
          </p>
      },
      {
        title: "Amount(In INR)",
        dataIndex: "amount",
        key: "grossSalary",
        width: "15%",
        render: (data, _row) =>
          !data
            ? <p>N/A</p>
            : <p>
              {numformatter(data)}
            </p>
      },
      {
        title: "Applied On",
        dataIndex: "reimbursementBillDate",
        width: "15%",
        render: data =>
          <p>
            {new Date(data).toDateString()}
          </p>
      },
      {
        title: "Updated By",
        dataIndex: ["manager", "name"],
        width: "15%",
        render: data =>
          !data
            ? <p>Organisation</p>
            : <p>
              {data}
            </p>
      }
    ],
    "Arrears and Bonuses": [
      {
        title: "Employee Id",
        dataIndex: ["employeeId"]
      },
      {
        title: "Employee Name",
        dataIndex: ["name"]
      },
      {
        title: "Added On",
        dataIndex: "createdAt",
        defaultSortOrder: "aescend",
        render: text =>
          <p>
            {new Date(text).toDateString()}
          </p>
      },
      {
        title: "Applicable On",
        dataIndex: "month",
        defaultSortOrder: "aescend",
        render: text =>
          <p>
            {monthList[text]}
          </p>
      },
      {
        title: "Credit Amount (In INR)",
        dataIndex: "credit"
      },
      {
        title: "Debit Amount (In INR)",
        dataIndex: "debit"
      }
    ]
  };

  // TODO: Optimize to use it anywhere
  const handleAction = async url => {
    let res = await axios({
      url, //your url
      method: "GET",
      responseType: "blob"
    });
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = urlDownload;
    link.setAttribute(
      "download",
      `${reportState.title}-${monthList[month]}.xlsx`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <HeaderComponent name="Reports" />
      <div className="app-breadcrum-container">
        <Breadcrumb className="app-breadcrum">
          <Breadcrumb.Item>
            <Link to={`/org/reports/report`}>Reports</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="app-breadcrum-active">
            {reportState.title}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Content
        className="app-main-content-container app-white-card"
        style={{ padding: "40px 40px" }}
      >
        <div className="app-flex-container-1" style={{ marginBottom: "40px" }}>
          <h1 className="h5-bold-20">{reportState.title}</h1>
          <div className="flex align-center justify-between">
            <Select
              className="general-ant-field-select"
              style={{ marginTop: 10, marginRight: 20, width: 300 }}
              size="large"
              value={selectedOrganisationId}
              dropdownMatchSelectWidth={false}
              onChange={(e) => {
                setSelectedOrganisationId(e);
              }}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            >
              <Option value={user.organisationId}>{user.displayName}</Option>
              {childOrganisations.map((val, index) => (
                <Option key={`child-org-${index}`} value={val.organisationId}>
                  {val.displayName}
                </Option>
              ))}
            </Select>

            <span className="subtitle1-med-18">Financial year</span>
            <DatePicker
              format={"YYYY - MM"}
              className="app-date-picker month-picker"
              onChange={handelDateChange}
              picker={"month"}
              value={reportDate}
              style={{ padding: 0, margin: 0, marginLeft: "0.5rem" }}
            />
            <div className="m-left-16">
              {reportState.fileDownload && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  className={` anchor-lib anchor-type-9 flex align-center`}
                  onClick={() =>
                    handleAction(
                      `${rootUrl}/api/${reportState.fileDownloadLink}/${selectedOrganisationId}/${month}/${financialYear}`
                    )
                  }
                >
                  <img
                    alt=""
                    src={blackDownload}
                    className="button-lib-icon"
                    style={{ width: "20px", height: "20px" }}
                  />
                  Download Excel
                </a>
              )}
            </div>
            <span style={{ marginLeft: "1em" }}>
              <Button
                onClick={startRefresh}
                className={`button-lib button-type-13 p-10`}
                style={{ height: "2.5rem" }}
              >
                <div className="flex justify-between align-center">Refresh</div>
              </Button>
            </span>
          </div>
        </div>
        <Table
          scroll={{ x: 5000 }}
          columns={
            reportState.type === "payRegister"
              ? reportTable
              : reportTabelRows[reportState.title]
          }
          dataSource={reportData}
          pagination={false}
          sticky
        />
      </Content>
    </div>
  );
};

const ReportsTableContainer = connect(state => ({
  user: state.auth.user,
  designation: state.organisation.designation,
  childOrganisations: state.organisation.childOrganisations
}))(ReportsTable);

export default withRouter(ReportsTableContainer);
