import { rootUrl, monthList } from "../../utils/config";
import moment from "moment";
import { NormalButton } from "../ComponentLibrary";
import { deleteLogo } from "../../assets/common/editAndDeleteLogos";
import CommonFunctionality from "./components/CommonFunctionality.js";
import { useState } from "react";
import {
	message,
	Spin,
	notification,
	Popconfirm,
	Select,

} from "antd";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
const { Option } = Select;
const AdvanceSalaryCredit = ({
	monthCloseFields,
	user,
	employeeList,
	organisation,
	startRefresh,
	deleteData,
	loading,
	financialYearList,
	name
}) => {
	const [isloading, setIsLoading] = useState(false);
	const [singleModalShow, setSingleModalShow] = useState(false);
	const [bulkModalShow, setBulkModalShow] = useState(false);

	const [fieldDetails, setFieldDetails] = useState({
		id: "",
		type: "credit",
		details: "AdvanceSalaryCredit",
		subDetails: "AdvanceSalaryCredit",
		amount: null,
		taxable: false,
		month: new Date().getMonth(),
		financialYear: "",
	});

	const submitForm = async () => {
		if (!fieldDetails.id) {
			message.error("Please select an employee");
			return;
		}

		if (!fieldDetails.financialYear) {
			message.error("Please enter Financial Year");
			return;
		}
		if (!fieldDetails.month && fieldDetails.month !== 0) {
			message.error("Please enter month");
			return;
		}
		if (!fieldDetails.amount) {
			message.error("Please enter amount");
			return;
		}


		setIsLoading(true);
		let response = await organisation.addMonthClose({
			monthClose: {
				...fieldDetails,
				organisationId: user.organisationId
			}
		});
		setIsLoading(false);
		if (response.data.success) {
			notification.success({
				message: "Successfully added the advance salary details"
			});
		} else {
			notification.error({ message: "Unable to add the advance salary details" });
		}
		organisation.updateMonthClose({ organisationId: user.organisationId });
		reset();
	};

	const reset = () => {
		setFieldDetails({
			...{
				id: "",
				type: "credit",
				details: "AdvanceSalaryCredit",
				subDetails: "AdvanceSalaryCredit",
				amount: null,
				taxable: true,
				month: new Date().getMonth(),
				financialYear: "",
				
			}
		});
		setSingleModalShow(false);
	}

	const columns = [
		{
			title: "Employee Name",
			dataIndex: ["user", "displayName"]
		},
		{
			title: "Employee Id",
			dataIndex: ["user", "currentOrganisationMeta", "employeeId"]
		},
		{
			title: "Added On",
			dataIndex: "createdAt",
			defaultSortOrder: "aescend",
			render: text =>
				<p>
					{moment(text).format("ll")}
				</p>
		},
		{
			title: "Applicable On",
			dataIndex: "month",
			defaultSortOrder: "aescend",
			render: text =>
				<p>
					{monthList[text]}
				</p>
		},
		{
			title: "Amount",
			dataIndex: "amount",
			render: (amount, row) =>
				<p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
					{row.type === "debit" ? "-" : "+"}
					{amount}
				</p>
		},
		{
			title: "Action",
			dataIndex: "_id",
			render: (data) =>
				<Popconfirm title={"Are you sure ?"} onConfirm={() => deleteData(data)}>
					<NormalButton
						type={5}
						icon={deleteLogo}
					/>
				</Popconfirm>
		}
		// {
		//     title: "Cause",
		//     dataIndex: ["meta", "causeOfInjury"],
		//     defaultSortOrder: "aescend",
		// },
		// {
		//     title: "Insurance number",
		//     dataIndex: ["meta", "insuranceNo"],
		//     defaultSortOrder: "aescend",
		// },
		// {
		//     title: "Nature of injury",
		//     dataIndex: ["meta", "natureOfInjury"],
		//     defaultSortOrder: "aescend",
		// },
		// {
		//     title: "Accident Date",
		//     dataIndex: ["meta", "dateOfAccident"],
		//     defaultSortOrder: "aescend",
		// },
		// {
		//     title: "Accident Time",
		//     dataIndex: ["meta", "timeOfAccident"],
		//     defaultSortOrder: "aescend",
		// },
		// {
		//     title: "Accident Place",
		//     dataIndex: ["meta", "placeOfAccident"],
		//     defaultSortOrder: "aescend",
		// },
		// {
		//     title: "Description",
		//     dataIndex: ["meta", "description"],
		//     defaultSortOrder: "aescend",
		// },
		// {
		//     title: "Witness",
		//     dataIndex: ["meta", "witnessName"],
		//     defaultSortOrder: "aescend",
		// },
	];

	return (
		<CommonFunctionality
			columns={columns}
			user={user}
			employeeList={employeeList}
			monthCloseFields={monthCloseFields}
			organisation={organisation}
			deleteData={deleteData}
			loading={loading}
			singleModalShow={singleModalShow}
			bulkModalShow={bulkModalShow}
			setSingleModalShow={setSingleModalShow}
			setBulkModalShow={setBulkModalShow}
			name={name}
			startRefresh={startRefresh}
			reset={reset}
			downloadURL={`${rootUrl}/api/monthclose/generate/excel/accident/${user.organisationId}`}
			actionUrl={`${rootUrl}/api/organisation/validate/accident/${user.organisationId}`}
		>
			<div className="">
				<h2 className="font-size-global-24 font-weight-global-700">
					Add Advance Salary
				</h2>
				<Spin spinning={isloading} size="large">
					<div className="org-anb-data-container p-top-15">
						<DropdownComponent
							className="general-ant-field-select"
							value={fieldDetails.id}
							labeltext={"Employee Name"}
							values={employeeList.map((item) => item._id)}
							options={employeeList.map((item) => item.displayName)}
							onChange={(e) => {
								let fieldDetailsData = fieldDetails;
								setFieldDetails({
									...fieldDetailsData,
									id: e,
								});
							}}
							width={"100%"}
							bordered={false}
							filterOption={(input, option) =>
								option.children &&
								option.children.toLowerCase().indexOf(input.toLowerCase()) >=
								0
							}
							filterSort={(optionA, optionB) =>
								optionA.children
									.toLowerCase()
									.localeCompare(optionB.children.toLowerCase())
							}
							showSearch
						>
							<Option value={""}>Select An Employee</Option>
							{employeeList.map((val, index) => {
								return (
									<Option key={`employees-${index}`} value={val._id}>
										{val.displayName}
									</Option>
								);
							})}
						</DropdownComponent>
					</div>

					<div className="org-anb-data-container p-top-15">
						<DropdownComponent
							className="general-ant-field-select"
							value={fieldDetails.financialYear}
							values={financialYearList}
							options={financialYearList}
							labeltext="Financial Year"
							onChange={(e) => {
								let fieldDetailsData = fieldDetails;
								setFieldDetails({
									...fieldDetailsData,
									financialYear: e,
								});
							}}
							width={"100%"}
							bordered={false}
						>
							{financialYearList.map((val, index) => {
								return (
									<Option key={`fyear-${index}`} value={val}>
										{val}
									</Option>
								);
							})}
						</DropdownComponent>
					</div>

					<div className="org-anb-data-container p-top-15">
						<DropdownComponent
							className="general-ant-field-select"
							value={fieldDetails.month}
							labeltext={"Month"}
							values={monthList.map((_val, index) => index)}
							options={monthList}
							onChange={(e) => {
								let fieldDetailsData = fieldDetails;
								setFieldDetails({
									...fieldDetailsData,
									month: e,
								});
							}}
							width={"100%"}
							bordered={false}
							filterOption={(input, option) =>
								option.children &&
								option.children.toLowerCase().indexOf(input.toLowerCase()) >=
								0
							}
							showSearch
						>
							{monthList.map((val, index) => {
								return (
									<Option key={`employees-${index}`} value={index}>
										{val}
									</Option>
								);
							})}
						</DropdownComponent>
					</div>
					<div
						style={{ marginTop: "1em" }}
						className="org-anb-data-container p-top-15"
					>
						<InputFieldComponent
							labeltext={"Advance Salary Amount"}
							type={"number"}
							className="general-ant-field-input"
							value={fieldDetails.amount}
							onChange={(e) => {
								let fieldDetailsData = fieldDetails;
								setFieldDetails({
									...fieldDetailsData,
									amount: e.target.value,
								});
							}}
							width={"100%"}
						/>
					</div>

					<div className="select-button-container">
						<NormalButton
							type={13}
							buttonText={"Add to the month end data"}
							block={"100%"}
							onClick={submitForm}
							margin={[30, 0]}
						/>
					</div>
				</Spin>
			</div>
		</CommonFunctionality>
	);
};
export default AdvanceSalaryCredit