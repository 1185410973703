// import {useState} from "react";
// import { Tabs } from "antd";
// import FullAndFinal from "./Settlement/fullAndFinal";
import Status from "./Settlement/status";


// const { TabPane } = Tabs;

const Settlement = ({ settlementData, user, id }) => {
  // const [activeTab, setActiveTab] = useState(1);

  return (
    <div className="employee-details-container">
      <div className="blue-tabs-container">
        {/* <Tabs
          tabPosition="left"
          style={{ height: "100%" }}
          defaultActiveKey="1"
          onChange={(e) => {
            setActiveTab(parseInt(e));
          }}
        >
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name">Status</span>
              </div>
            }
            key="1"
          >
              
          </TabPane>
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name">Full and Final</span>
              </div>
            }
            key="2"
          >
              <FullAndFinal />
          </TabPane>
        </Tabs> */}
        <Status settlementData={settlementData} id={id} />
      </div>
    </div>
  );
};

export default Settlement;