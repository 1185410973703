import React, { useEffect, useState } from "react";
import {
  getUserFromId,
} from "../../../apis/organisation";
import {
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Avatar, Modal } from "antd";
import { FileViewer, FileLogos, NormalButton } from "../../ComponentLibrary";
import { verifyNo, verifyYes } from "../../../assets/common/verifiedLogos"
import { getDocumentURL } from "../../../apis/user";
import PasswordResponses from "react-pdf/dist/esm/PasswordResponses";
import packageJson from "../../../../package.json";
// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import EmployeeLogo from "../../../assets/employeeProfile/EmployeeLogo.svg";
import EmployeeDocs from "./EmployeeDocs";
const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];
const imageDocumentType = ["image", "png", "jpg"];
const EmployeeUploadFiles = ({
  pageData,
  setPageState,
  setEmployeeData,
  employeeData,
  user,
  financialYear
}) => {
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [viewFile, setViewFile] = useState(false);
  const [imageViewCount, setImageViewCount] = useState(0);
  const [viewFileDocumentProps, setviewFileDocumentProps] = useState({});
  const [
    viewFileDocumentVerifyProps,
    setviewFileDocumentVerifyProps,
  ] = useState({});
  const [document, setDocument] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [count, setCount] = useState(0);
  let AllowanceOrDeduction = pageData.name === "Allowance" ? true : false;

  const getUserData = async () => {
    let res = await getUserFromId(employeeData.employeeData._id);
    setEmployeeDetails({ ...res.data });
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData]);

  const handleOk = () => {
    handleCancel();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setViewFile(false);
    setCount(0);
  };
  const imageCloseHandler = () => {
    setViewFile(false);
    setCount(0);
    setImageViewCount(0);
  }
  if (imageDocumentType.includes(viewFileDocumentProps.documentType && imageViewCount === 0)) {
    setImageViewCount(imageViewCount + 1);
    setCount(1);
  }
  if ((viewFileDocumentProps.documentType === "pdf" && viewFile && count === 0) || (count === 0 && viewFile)) {
    const getDocumentURLFromKey = async () => {
      let documents = await getDocumentURL({ Key: viewFileDocumentProps.documentKey });
      setIsModalVisible(true);
      setDocument(documents.data);
      setCount(1);
    };
    (async () => {
      await getDocumentURLFromKey();
    })();
  }

  const renderToolbar = (Toolbar) => (React.createElement(Toolbar, null, (slots) => {
    const { CurrentPageInput, Download, EnterFullScreen, GoToNextPage, GoToPreviousPage, NumberOfPages, Zoom, ZoomIn, ZoomOut } = slots;
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <div style={{ padding: '0px 2px' }}>
          <ZoomOut />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <Zoom />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <ZoomIn />
        </div>
        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
          <GoToPreviousPage />
        </div>
        <div style={{ padding: '0px 2px', width: '4rem' }}>
          <CurrentPageInput />
        </div>
        <div style={{ padding: '0px 2px' }}>
          / <NumberOfPages />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <GoToNextPage />
        </div>
        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
          <EnterFullScreen />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <Download />
        </div>
      </div>
    );
  }));

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
  });
  const onPasswordHandler = function (callback, reason) {
    function callbackProxy(password) {
      // Cancel button handler
      if (!password) {
        return
      }
      callback(password);
    }

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(password);
        break;
      }
      default:
    }
  }
  return (
    <>
      {imageDocumentType.includes(viewFileDocumentProps.documentType) && (imageViewCount === 1 || viewFile) && <FileViewer
        visible={viewFile}
        onclose={imageCloseHandler}
        fileProps={viewFileDocumentProps}
        verifyProps={viewFileDocumentVerifyProps}
      />}
      {viewFileDocumentProps.documentType === "pdf" && document !== "" &&
        <Modal
          title={
            <>
              <FileLogos type={1} /> {viewFileDocumentProps.documentName}
            </>
          }
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1500}
          footer={null}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {
              viewFileDocumentVerifyProps.showVerificationStatus
              && (viewFileDocumentVerifyProps.userCanVerify
                ? viewFileDocumentVerifyProps.fileIsVerified
                  ? <div className="verified-text-container"><img alt="" src={verifyYes} className="verified-logo-img"></img>Verified</div>
                  : <div >
                    <NormalButton
                      buttonText="Verify The Document"
                      type={1}
                      onClick={() => viewFileDocumentVerifyProps.verifyCallback(viewFileDocumentVerifyProps.propsForVerifyCallback)}
                    />
                  </div>
                : viewFileDocumentVerifyProps.fileIsVerified
                  ? <div className="verified-text-container"><img alt="" src={verifyYes} className="verified-logo-img"></img>Verified</div>
                  : <div className="verified-text-container"><img alt="" src={verifyNo} className="verified-logo-img"></img>Pending</div>)

            }

          </div>
          <div>
            {document ? (
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={document}
                  plugins={[defaultLayoutPluginInstance]}
                  renderError={console.log}
                  onDocumentAskPassword={onPasswordHandler}
                  initialPage={1}
                />
              </Worker>
            ) : (
              "Document URL not found"
            )}
          </div>
        </Modal>
      }
      <div className="app-main-content-container employee-upload-files-container">
        <div className="employee-upload-files-header">
          <div className=" header-left-side flex align-center">
            <ArrowLeftOutlined
              onClick={() => {
                setEmployeeDetails({});
                setEmployeeData({});
                setPageState(0);
              }}
              className="arrow-left-button"
            />
            <Avatar
              className="avatar"
              src={employeeDetails.image ? employeeDetails.image : EmployeeLogo}
              size="large"
              style={{
                backgroundColor: employeeDetails.image ? "" : "#f3a02e",
              }}
            />
            <p className="employee-display-name">
              {employeeData.employeeData.displayName}
            </p>
            <p className="employee-id">
              {"ID " + employeeData.employeeData.employeeId}
            </p>
          </div>
        </div>
        <EmployeeDocs
          organisation={user}
          setViewFile={setViewFile}
          employeeData={employeeData}
          setViewFileDocumentVerifyProps={setviewFileDocumentVerifyProps}
          setViewFileDocumentProps={setviewFileDocumentProps}
          AllowanceOrDeduction={AllowanceOrDeduction}
          employeeDetails={employeeDetails}
          id={employeeData.id}
        />
      </div>
    </>
  );
};

export default EmployeeUploadFiles;
