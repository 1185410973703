function singlePriceWithComma(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const formatNumberWithThousandSeparatorAndTwoDecimalPlaces = value => new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
}).format(value).replace(/€/g, '');
function camelToSpaceSeparated(text) {
    const result = [];
    result.push(text.charAt(0).toUpperCase());
    let i;
    for (i = 1; i < text.length; i++) {
        if (checkCase(text[i]) === 1 && checkCase(text[i - 1]) !== 1) {
            result.push(" ");
        }
        result.push(text[i]);
    }
    if (result.length !== text.length) {
        result.push(text[i]);
    }
    return result.join("");
}

function checkCase(c) {
    let u = c.toUpperCase();
    return c.toLowerCase() === u ? -1 : c === u ? 1 : 0;
}

module.exports = { camelToSpaceSeparated, singlePriceWithComma, formatNumberWithThousandSeparatorAndTwoDecimalPlaces }
