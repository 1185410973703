module.exports = {
  appLevel: `This Mool's End-User License Agreement (EULA) is a legal agreement between you (either an individual or a single entity) and Mool Innovation Labs (P.) Ltd. (Mool) for the Mool's product identified above which includes the product, CD-Rom Disc and user's manual accompanying this EULA. By installing, copying or otherwise using the product, you agree to be bound by the terms of this EULA. If you do not agree to the terms of this agreement, you are not authorized to use the product. The product is protected by copyright laws and international copyright treaties, as well as other intellectual property laws and treaties. The product is Licensed, not sold.

  <h4>Grant of license</h4>
  
  1.1 In order to preserve and protect its rights under applicable laws, Mool does not sell any right in its product. Rather Mool grants the right to use its Product by means of a product license as detailed below. Mool specifically retains title to website www.Mool.com
  
  1.2 Mool grants you a non-exclusive license to use the product according to the terms set forth below.
  
  <h4>Restrictions and rights</h4>
  
  2.1 You can operate/use the product.
  
  2.2 You cannot make the product available to any person or entity other than your employees, who must use the product as specified above.
  
  2.3 You cannot modify the product or merge it with another program, except for your personal use on a single computer. Any modified or merged portion of the product is subject to this agreement.
  
  2.4 You cannot reserve, engineer, disassemble, decompile or make any attempt to discover the source code to the product or make any attempts to unlock or by-pass the initialization system used on initialiser disks.
  
  2.5 You cannot translate or create derivative works based on the product wholly or in part.
  
  2.6 You cannot remove, obscure or alter any notice of the patent, copyright, trademark or other proprietary rights related to the product.
  
  2.7 You cannot sub-license, sell, lend, rent or lease any portion of the product or otherwise distribute the program, nor can you make the program available by 'Bulletin boards', on-line service, remote dial-in, or network or telecommunications links of any kind.
  
  2.8 You cannot transfer the product or any part of the product to any person or entity in violation of applicable laws.
  
  2.9 You cannot make copies or distribute copies of this product.
  
  2.10 You must maintain all copyright notices on all copies of the product.
  
  2.11 You must comply with applicable laws regarding the use of product.
  
  <h4>Termination</h4>
  
  3.1 Mool may terminate this EULA if you failed to comply with the terms and conditions of this EULA. In such event, you must destroy all copies of the product.
  
  <h4>Disclaimer</h4>
  
  4.1 Every effort has been made to avoid errors or omissions in this product. In spite of this, errors may creep in. Any mistake, error or discrepancy noted may be brought to our notice which shall be taken care of in the next version. It is notified that neither the manufacturer/publisher nor the seller will be responsible for any damage or loss to anyone, of any kind, in any manner arising there from. It is suggested that to avoid any doubt the reader should cross-check all the facts, law and contents of the product with original government publications or notifications.
  
  4.2 Mool makes no warranties or representations with respect to the accuracy, contents or performance of this product or operation manual. It particularly disclaims any implied warranty of fitness for any particular purpose.
  
  4.3 Any claims made by sales literature or sales persons do not constitute warranties. Because of the diversity of hardware, product and conditions under which the product may be used, Mool cannot make any warranty of fitness for a particular purpose. The entire risk of using the product must be assumed by the user. Accordingly, the user is recommended to thoroughly test the product before relying on it. In any event, any liability of Mool is limited exclusively to refund of the purchase price of the product.
  
  4.4 Any use of product is at your own risk. The product is provided for use only to the maximum extent permitted by applicable law. Mool disclaims all warranties and conditions, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
  
  4.5 Mool reserves the right to revise and make changes to the product and/or this manual without incurring any obligation to notify any person of such changes and revisions.
  
  <h4>Copyright</h4>
  
  5.1 All title and intellectual property rights in and to the content which may be accessed through use of the product is the property of the respective content owner and may be protected by applicable copyright or other intellectual property laws and treaties. This EULA grants you no rights to use such content. All copyrights are acknowledged.
  
  5.2 No part of this product may be reproduced or copied in any form or by any means graphic, electronic or mechanical including, printing, photocopying, recording, taping, or information retrieval systems or reproduced on any disc, tape perforated media or other information storage device, etc., without the written permission of the manufacturer/publishers. This product can be exported only by the manufacturer/Publishers. Breach of this condition is liable for legal action. All disputes are subject to Delhi jurisdiction only.
  
  <h4>No liability for consequential damages</h4>
  
  6.1 Mool shall not be liable for any special incidental, indirect or consequential damages whatsoever (including, without limitation, damages for loss of business profits, business interruption, loss of business information, or any other pecuniary loss) arising out of the use of or inability to use the product.
  
  6.2 Mool represents and warrants that it provide access to its website as per this agreement and to make all reasonable efforts within its control to ensure that the access is uninterrupted. Mool cannot be held liable for not providing access to the website due to some technical glitches, natural calamity, Government Order, riots or due to any other event.
  
  <h4>Research Box</h4>
  
  7.1 We make reasonable efforts to ensure smooth and error-free functioning of Research Box. Though we try to provide suitable back-up of your research work, yet it is advisable to synchronize Research Box with your Dropbox account on regular basis. Under no circumstances Mool shall be liable for any loss of the research work or matter attributable to the use of Research Box. Mool reserves the right to remove Research Box either temporarily or permanently without any notice. Mool also reserves the right to charge suitably for Research Box separately or otherwise.
  
  <h4>Miscellaneous</h4>
  
  8.1 Mool is not responsible for maintaining or helping you to use product, except through Mool's Registered User Support Service and extended Technical Support Service. To receive these services, you must follow the directions accompanying the Registration Card in this package.
  
  8.2 This EULA is governed by the laws of India.
  
  8.3 Should you have any questions concerning this EULA or if you desire to contact Mool for any reason, please contact us or write to :
  
  Mool Innovation Labs Pvt. Ltd.
  59/32, New Lokhandwala Road, New Mumbai - 110 005.
  e-mail: contactus@moolfinance.com`
}