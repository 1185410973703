import { Checkbox } from "antd";
import DropdownComponent from "../../../../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../../../../InputLibrary/InputFieldComponent/InputFieldComponent";

const GeneralInformation = ({ newGroupsDetails, setNewGroupsDetails }) => {
  return (
    <div>
      <h1 className="tab-subtitle-1" style={{ color: "black" }}>
        General Configuration
      </h1>
      <div>
        <InputFieldComponent
          className="input-field-container"
          labeltext={"Name"}
          autoFocus={true}
          style={{
            paddingLeft: "0.1rem",
            fontSize: "0.9rem",
            width: "50%",
            margin: "1rem 0"
          }}
          placeholder="Group Name"
          value={newGroupsDetails.name}
          onChange={(e) =>
            setNewGroupsDetails({
              ...newGroupsDetails,
              name: e.target.value
            })
          }
        />
      </div>

      <p className="app-label-5" style={{ color: "#405cd2", margin: "1rem 0" }}>
        General Details
      </p>
      <div className="group-modal-setting-container">
        <div className="flex justify-between m-bottom-36">
          <p className="app-label-1">Enable Gratuity</p>
          <Checkbox
            checked={newGroupsDetails.groupSettings.gratuity}
            onChange={(e) => {
              setNewGroupsDetails({
                ...newGroupsDetails,
                groupSettings: {
                  ...newGroupsDetails.groupSettings,
                  gratuity: e.target.checked
                }
              });
            }}
          />
        </div>
        {newGroupsDetails.groupSettings.gratuity && (
          <div className="flex justify-between align-center">
            <InputFieldComponent
              value={newGroupsDetails.groupSettings.gratuityTenure}
              type={"Number"}
              labeltext="Gratuity Tenure"
              width={"330px"}
              onChange={(e) => {
                setNewGroupsDetails({
                  ...newGroupsDetails,
                  groupSettings: {
                    ...newGroupsDetails.groupSettings,
                    gratuityTenure: Number(e.target.value)
                  }
                });
              }}
            />
          </div>
        )}
        <div className="flex justify-between m-bottom-36">
          <p className="app-label-1">Enable PF</p>
          <Checkbox
            checked={newGroupsDetails.groupSettings.pfEnabled}
            onChange={(e) => {
              setNewGroupsDetails({
                ...newGroupsDetails,
                groupSettings: {
                  ...newGroupsDetails.groupSettings,
                  pfEnabled: e.target.checked
                }
              });
            }}
          />
        </div>
        <div className="flex justify-between m-bottom-20">
          <p className="app-label-1">Variable above CTC</p>
          <Checkbox
            checked={newGroupsDetails.groupSettings.variableAboveCTC}
            onChange={(e) => {
              setNewGroupsDetails({
                ...newGroupsDetails,
                groupSettings: {
                  ...newGroupsDetails.groupSettings,
                  variableAboveCTC: e.target.checked
                }
              });
            }}
          />
        </div>
        <div className="flex justify-between flex-direction-column m-bottom-20">
          <DropdownComponent
            className="block app-select m-top-4"
            value={newGroupsDetails.overtimeSetting && newGroupsDetails.overtimeSetting.component ? newGroupsDetails.overtimeSetting.component : "Basic"}
            values={["Basic", "Gross"]}
            options={["Basic", "Gross"]}
            width="330px"
            labeltext={"Overtime Calculated From"}
            onChange={(e) => {
              setNewGroupsDetails({
                ...newGroupsDetails,
                overtimeSetting: {
                  ...newGroupsDetails.overtimeSetting,
                  component: e
                }
              });
            }}
          >
          </DropdownComponent>
        </div>
        <div className="flex justify-between flex-direction-column m-bottom-20">
          <InputFieldComponent
            className="general-ant-field-input leave-input"
            bordered={false}
            labeltext={"Overtime Factor"}
            type={"number"}
            width="330px"
            value={newGroupsDetails.overtimeSetting && newGroupsDetails.overtimeSetting.factor ? newGroupsDetails.overtimeSetting.factor : 0}
            onChange={(e) => {
              setNewGroupsDetails({
                ...newGroupsDetails,
                overtimeSetting: {
                  ...newGroupsDetails.overtimeSetting,
                  factor: isNaN(parseInt(e.target.value))
                    ? 0
                    : parseInt(e.target.value),
                }
              });
            }}
          ></InputFieldComponent>
        </div>
      </div>

      {/* <Col xs={24} sm={24} md={24} xl={24}>
          <p className="app-label-5" style={{ color: "#405cd2" }}>
            PF Configuration
          </p>
        </Col>
        <Col>
          <Radio.Group onChange={onChangeRadio} value={value}>
            <Radio value={1}>Rs 1,800 of the Employee CTC</Radio>
            <Radio value={2}>12% of basic of the employee payslip</Radio>
          </Radio.Group>
        </Col> */}
    </div>
  );
};

export default GeneralInformation;
