import { Button } from 'antd'
import React, { useState } from 'react'
import { ReloadOutlined } from "@ant-design/icons";
import './style.less'
const RefreshButton = ({ setRefresh, refresh, method, refreshMethod }) => {
    const [active, setActive] = useState(false)
    function handleRefresh() {
        if (method) {
            refreshMethod()
        } else {
            setRefresh(!refresh);
        }
        setActive(true);
        setTimeout(() => setActive(false), 3000)
    }

    return (
        <Button
            onClick={handleRefresh}
            id="refresh-btn"
            className={`button-lib button-type-13 p-10`}
            style={{ height: "2.5rem" }}
        >
            <div className="flex justify-between align-center">
                Refresh
                <p className={active ? "active m-left-8" : "in-active m-left-8"}><ReloadOutlined /></p>
            </div>
        </Button>
    )
}

export default RefreshButton