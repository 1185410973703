import React from 'react'

function IncomePreviousEmployer({previousIncome}) {
    return (
        <React.Fragment>
            <tr>
                <th>Pay Item</th>
                <th>Amount</th>
            </tr>
            <tr>
                <td>Total Gross Income</td>
                <td>{previousIncome.totalGrossSalary}</td>
            </tr>
            <tr>
                <td>Income Tax</td>
                <td>{previousIncome.totalTaxDeducted}</td>
            </tr>
            <tr>
                <td>Provident Fund</td>
                <td>{previousIncome.totalPFPaid}</td>
            </tr>
        </React.Fragment>
    )
}

export default IncomePreviousEmployer