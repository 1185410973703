import { notification } from "antd";
import { gapi } from "gapi-script";
import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { connect } from "react-redux";
import auth from "../../redux/modules/auth";
import googleicon from "./google.svg";

const GoogleLoginComponent = ({ authReducer }) => {

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    React.useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    }, [clientId]);

    const onSuccess = async (res) => {
        try {
            authReducer.googleLogin(res)
        } catch (err) {
            notification.error({ message: "Failed to login with google" })
        }
    };

    const onFailure = (err) => {
        notification.error({ message: "Failed to login with google" })
    };

    return (
        <div className="login-container">
            <div className="login-contianer">
                <div
                    className="login-info-holder "
                    style={{
                        top: "120px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <GoogleLogin
                        clientId={clientId}
                        buttonText="Sign in with Google"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                        render={(renderProps) => {
                            return (
                                <button
                                    style={{
                                        width: "200px",
                                        height: "40px",
                                        backgroundColor: "#4285F4",
                                        borderColor: "#4285F4",
                                        borderRadius: "1px",
                                        color: "white",
                                        cursor: "pointer",
                                        fontSize: "15px",
                                    }}
                                    onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                >
                                    <img
                                        src={googleicon}
                                        alt="icon"
                                        style={{
                                            backgroundColor: "white",
                                            width: "42px",
                                            height: "42px",
                                            marginLeft: "-13px",
                                            padding: "4px",
                                            marginRight: "30px",
                                        }}
                                    />
                                    Sign In with Google
                                </button>
                            )
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const GoogleLoginPage = connect(
    state => ({
        user: state.auth.user,
        isLoggedIn: state.auth.isLoggedIn
    }),
    dispatch => ({
        authReducer: auth.getActions(dispatch)
    })
)(GoogleLoginComponent)

export default GoogleLoginPage;