import { HeaderComponent } from "../Common";
import { Content } from "antd/lib/layout/layout";
import broadcastLogo from "../../assets/icons/activeIcons/activeBroadcastLogo.svg"
import { connect } from "react-redux";
import organisation from '../../redux/modules/organisation';
import employee from "../../redux/modules/employee";
import auth from "../../redux/modules/auth";
import moment from "moment";
import { useEffect, useState } from "react"
import { withRouter } from "react-router";
import { Typography } from 'antd';
import TrialPage from "../Common/TrialComponent";
import broadcastBgImage from "../../assets/dummyPages/broadcastBgImage.png"


const { Text } = Typography;

const BroadcastMessage = ({ orgBroadcastMessage, empBroadcastMessage, user, organisationReducer, employeeReducer, isTrial }) => {

    const [orgMessage, setOrgMessage] = useState([])
    const [empMessage, setEmpMessage] = useState([])

    useEffect(() => {
        if (user.role === "org") {
            organisationReducer.organisationBroadcastMessage(1)
        } else {
            employeeReducer.employeeBroadcastMessage(3)
        }
    }, [employeeReducer, organisationReducer, user.role])

    useEffect(() => {
        setOrgMessage(orgBroadcastMessage.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1))
    }, [orgBroadcastMessage])

    useEffect(() => {
        setEmpMessage(empBroadcastMessage.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1))
    }, [empBroadcastMessage])

    useEffect(() => {
        document.title = 'Account & Activity | Broadcasts';
    });

    return (
        isTrial ? <TrialPage user={user} trialMessage={" Designed for your needs, Mool is always at your service"} bgImage={broadcastBgImage}
        /> :
            <div className="site-layout">
                <HeaderComponent show={false} showMoolCoins={true} icon={broadcastLogo} name="Broadcast" />
                <Content className="app-main-content-container">
                    <div className="paper-wrapper">
                        <div className="paper-header-container m-bottom-12">
                            <p className="font-size-global-24 font-weight-global-700">All Broadcasts</p>
                        </div>
                        <div>
                            {
                                user.role === "org"
                                    ? orgMessage.map((data, i) => {
                                        return (
                                            <MessageCard key={`message-${i}`} messageData={data} i={i} />
                                        )
                                    })
                                    : empMessage.map((data, i) => {
                                        return (
                                            <MessageCard key={`message-${i}`} messageData={data} i={i} />
                                        )
                                    })
                            }
                        </div>
                    </div>
                </Content>
            </div>
    )
}

const MessageCard = ({ messageData, i }) => {
    return (
        <div key={`message-card-${i}`} className=" flex flex-direction-column justify-center message-card-container">
            <div className="flex justify-between align-center message-card">
                <div>
                    <p className="message-title">{messageData.title}</p>
                    <Text
                        className="message-msg"
                        style={{ width: 1200 }}
                        ellipsis={{ tooltip: messageData.message, rows: 1 }}
                    >
                        {messageData.message}
                    </Text>
                </div>
                <div>
                    <p className="message-date">{moment(messageData.updatedAt).format("DD MMM YYYY")}</p>
                </div>
            </div>
        </div>
    )
}

const BroadcastMessageContainer = connect(
    state => ({
        user: state.auth.user,
        orgBroadcastMessage: state.organisation.broadcastMessage,
        empBroadcastMessage: state.employee.broadcastMessage,
        isTrial: state.auth.user.isTrial,

    }),
    dispatch => ({
        organisationReducer: organisation.getActions(dispatch),
        employeeReducer: employee.getActions(dispatch),
        authReducer: auth.getActions(dispatch),
    })
)(BroadcastMessage)


export default withRouter(BroadcastMessageContainer);