import employeeCompanyLogo from "../../../assets/employeeDashboard/employeeCompanyLogo.png"

const CompanyInfoCard = ({ companyName, image }) => {
    return (
        <div className="company-info-card-container">
            <h3 className="card-data-heading">Company</h3>
            <div className="company-info">
                <img alt="" className="company-logo" src={image ? image : employeeCompanyLogo}></img>
                <h4 className="company-name">{companyName}</h4>
            </div>
        </div>
    )
}

export default CompanyInfoCard;