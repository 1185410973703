import { Col, Button, Row, notification } from "antd";
import { connect } from "react-redux";
import employee from "../../../redux/modules/employee";
// import auth from "../../../redux/modules/auth"
import { useEffect, useState } from "react";
import { checkFinancialYear } from "../../../utils/config";
import { savePreviousEmployment } from "../../../apis/employee";
import InputNumberComponent from "../../InputLibrary/InputNumberComponent/InputNumberComponent";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";

const { financialYear } = checkFinancialYear();

const Form16 = ({
  id,
  employeeReducer,
  changePageState,
  type,
  user,
  updateList = () => {},
  closeDrawer = () => {},
}) => {
  const [previousEmployementData, setPreviousEmployementData] = useState({
    id: id,
    financialYear: financialYear,
    hra: 0,
    basic: 0,
    totalGrossSalary: 0,
    totalExemption: 0,
    totalTaxDeducted: 0,
    totalPFPaid: 0,
    totalProffTaxDeducted: 0,
    exemptionReverse: 0,
    meta: [],
    monthWorked: 0,
    companyName: "",
  });
  const [loading, setLoading] = useState(false);
  // const [isSalaryInputEmpty, setIsSalaryInputEmpty] = useState(false);

  useEffect(() => {
    // getPreviousEmploymentData(`${id}/${financialYear}`).then(res => {
    //     const previousEmployment = res.data
    //     if (previousEmployment && Object.keys(previousEmployment).length > 0) {
    //         setPreviousEmployementData(
    //             {
    //                 id,
    //                 financialYear: previousEmployment.financialYear ? previousEmployment.financialYear : financialYear,
    //                 hra: previousEmployment.hra ? previousEmployment.hra : 0,
    //                 basic: previousEmployment.basic ? previousEmployment.basic : 0,
    //                 totalGrossSalary: previousEmployment.totalGrossSalary ? previousEmployment.totalGrossSalary : 0,
    //                 totalExemption: previousEmployment.totalExemption ? previousEmployment.totalExemption : 0,
    //                 totalTaxDeducted: previousEmployment.totalTaxDeducted ? previousEmployment.totalTaxDeducted : 0,
    //                 totalProffTaxDeducted: previousEmployment.totalProffTaxDeducted ? previousEmployment.totalProffTaxDeducted : 0,
    //                 totalPFPaid: previousEmployment.totalPFPaid ? previousEmployment.totalPFPaid : 0,
    //                 exemptionReverse: previousEmployment.exemptionReverse ? previousEmployment.exemptionReverse : 0,
    //                 meta: previousEmployment.meta ? previousEmployment.meta : [],
    //                 monthWorked: previousEmployment.monthWorked ? previousEmployment.monthWorked : 0
    //             }
    //         )
    //     } else {
    setPreviousEmployementData({
      ...previousEmployementData,
      id: id,
      financialYear: financialYear,
    });
    //     }

    // })
    // console.log(`user:${user}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const updateRebalancing = async () => {
    setLoading(true);
    if (
      previousEmployementData.totalGrossSalary !== 0 &&
      previousEmployementData.totalGrossSalary !== null
    ) {
      let res = await savePreviousEmployment(previousEmployementData);
      // console.log(res);
      if (res.data.success) {
        closeDrawer();
        const { financialYear } = checkFinancialYear();
        employeeReducer.updateEmployeeRebalancing({ financialYear, id });
        notification.success({ message: "Successfully Updated" });
        setPreviousEmployementData({
          id: id,
          financialYear: financialYear,
          hra: 0,
          basic: 0,
          totalGrossSalary: 0,
          totalExemption: 0,
          totalTaxDeducted: 0,
          totalPFPaid: 0,
          totalProffTaxDeducted: 0,
          exemptionReverse: 0,
          meta: [],
          monthWorked: 0,
          companyName: "",
        });
        updateList();
      } else {
        notification.error({ message: "Sorry Unable To Update" });
      }
    } else {
      notification.error({ message: "Gross salary must be entered" });
    }

    if (type === "login") {
      changePageState();
    }
    setLoading(false);
  };
  return (
    <>
      <Row gutter={[50, 30]}>
        <Col xs={24} sm={24} md={24} xl={24}>
          <InputFieldComponent
            margin={["10", "0", "10", "0"]}
            labeltext={"Company Name"}
            width={"100%"}
            maxWidth={"35rem"}
            onChange={(e) => {
              setPreviousEmployementData({
                ...previousEmployementData,
                companyName: e.target.value,
              });
            }}
            value={previousEmployementData.companyName}
            bordered={false}
          />
        </Col>
        <Col xs={24} sm={24} md={24} xl={24}>
          {/* <p className="app-label-5">Gross Salary</p> */}
          <InputNumberComponent
            margin={["10", "0", "10", "0"]}
            labeltext={"Total Gross Salary"}
            width={"100%"}
            maxWidth={"35rem"}
            value={previousEmployementData.totalGrossSalary}
            onChange={(val) => {
              setPreviousEmployementData({
                ...previousEmployementData,
                totalGrossSalary: val,
              });
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} xl={24}>
          {/* <p className="app-label-5">Total Basic Salary</p> */}
          <InputNumberComponent
            margin={["10", "0", "10", "0"]}
            labeltext={"Total Basic Salary"}
            width={"100%"}
            maxWidth={"35rem"}
            value={previousEmployementData.basic}
            onChange={(val) => {
              setPreviousEmployementData({
                ...previousEmployementData,
                basic: val,
              });
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} xl={24}>
          {/* <p className="app-label-5">Total HRA Exemption</p> */}
          <InputNumberComponent
            margin={["10", "0", "10", "0"]}
            width={"100%"}
            maxWidth={"35rem"}
            labeltext={"Total HRA Exemption"}
            value={previousEmployementData.hra}
            onChange={(val) => {
              setPreviousEmployementData({
                ...previousEmployementData,
                hra: val,
              });
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} xl={24}>
          {/* <p className="app-label-5">Exemption</p> */}
          <InputNumberComponent
            margin={["10", "0", "10", "0"]}
            width={"100%"}
            maxWidth={"35rem"}
            labeltext={"Total Exemption"}
            value={previousEmployementData.totalExemption}
            onChange={(val) => {
              setPreviousEmployementData({
                ...previousEmployementData,
                totalExemption: val,
              });
            }}
          />
        </Col>

        <Col xs={24} sm={24} md={24} xl={24}>
          {/* <p className="app-label-5">Total PF Paid</p> */}
          <InputNumberComponent
            margin={["10", "0", "10", "0"]}
            width={"100%"}
            maxWidth={"35rem"}
            labeltext={"Total PF Paid"}
            value={previousEmployementData.totalPFPaid}
            onChange={(val) => {
              setPreviousEmployementData({
                ...previousEmployementData,
                totalPFPaid: val,
              });
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} xl={24}>
          {/* <p className="app-label-5">Total PF Paid</p> */}
          <InputNumberComponent
            margin={["10", "0", "10", "0"]}
            width={"100%"}
            maxWidth={"35rem"}
            labeltext={"Total Professional Tax Paid"}
            value={previousEmployementData.totalProffTaxDeducted}
            onChange={(val) => {
              setPreviousEmployementData({
                ...previousEmployementData,
                totalProffTaxDeducted: val,
              });
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} xl={24}>
          {/* <p className="app-label-5">Total Tax Deducted</p> */}
          <InputNumberComponent
            margin={["10", "0", "10", "0"]}
            width={"100%"}
            maxWidth={"35rem"}
            labeltext={"Total Tax Deducted"}
            value={previousEmployementData.totalTaxDeducted}
            onChange={(val) => {
              setPreviousEmployementData({
                ...previousEmployementData,
                totalTaxDeducted: val,
              });
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} xl={24}>
          {/* <p className="app-label-5">Total Month Worked</p> */}
          <InputNumberComponent
            margin={["10", "0", "10", "0"]}
            width={"100%"}
            maxWidth={"35rem"}
            labeltext={"Total Month Worked"}
            value={previousEmployementData.monthWorked}
            max={12}
            onChange={(val) => {
              setPreviousEmployementData({
                ...previousEmployementData,
                monthWorked: val,
              });
            }}
          />
        </Col>
      </Row>
      <Col xs={24} sm={24} md={12} xl={12}></Col>
      <Button
        loading={loading}
        onClick={updateRebalancing}
        className='submit-button'
        style={{
          margin: "10px 0 10px 0",
          background: "#405cd2",
          padding: "15px",
          height: "60px",
          fontSize: "1.125rem",
          borderRadius: "8px",
          width: "100px",
        }}
        htmlType='submit'
      >
        Save
      </Button>
    </>
  );
};

const Form16Container = connect(
  (state) => ({
    user: state.auth.user,
    previousEmployement: state.employee.previousEmployement,
  }),
  (dispatch) => ({
    employeeReducer: employee.getActions(dispatch),
  })
)(Form16);
export default Form16Container;
