import { Col, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { paths } from "../../../../constants/routes";
import { NormalButton } from "../../../ComponentLibrary";

const { Text } = Typography;

const Status = ({ settlementData, id }) => {

  return (
    <div className="status-container">
      <div className="status-header-container">
        <p className="status-header">Separation Information</p>
        {settlementData.active
          ? <Link to={{
            pathname: `${paths.org.settlement}/${id}`,
            state: { fromEmployeeProfile: true }
          }}>
            <NormalButton margin={[0, 0]} buttonText="Initiate Full and Final" type={13}></NormalButton>
          </Link>
          : " "
        }
      </div>
      <div className="current-status">
        <p className="current-status-header">Status</p>
        <div className="status-display">
          {settlementData.active ? (
            <p className="application-accepted">Accepted by Manager</p>
          ) : (
            <p className="application-pending">Pending</p>
          )}
        </div>
      </div>
      <div className="current-status">
        <p className="current-status-header">Notice Period</p>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={7} lg={7}>
            <div className="current-status-container">
              <p className="current-status-label">Start Date</p>
              <p className="current-status-data">: {moment(settlementData.initiationDate).format('LL')}</p>
            </div>
            <div className="current-status-container">
              <p className="current-status-label">End Date</p>
              <p className="current-status-data">: {moment(settlementData.lastDayOfWorking).format('LL')}</p>
            </div>
            {/* <div className="current-status-container">
              <p className="current-status-label">Reason of leaving</p>
              <p className="current-status-data">: Starting Up</p>
            </div> */}
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <div className="days-left-container">
              <p className="days-left-label">Days under Notice period</p>
              <p className="days-left-data">
                <Text type="danger">
                  {moment(settlementData.lastDayOfWorking).diff(moment(settlementData.initiationDate), "days")}
                </Text>
              </p>
            </div>
          </Col>
        </Row>
      </div>
      {/* <div className="current-status">
        <p className="current-status-header">Comments</p>
        <div className="comments-container flex">
          <div>
            <img className="image" src={employeeLogo}>
            </img>
          </div>
          <div>
            <p className="name">Kirti Sharon (Manager)</p>
            <p className="date">12 MAR, 2021</p>
            <p className="comment">Good Luck For your Future Endeavours !! 😃</p>
          </div>
        </div>
        <TextArea rows={4} className="text-area" placeholder="Add Comment" bordered={false} />
      </div> */}
    </div>
  );
};

export default Status;