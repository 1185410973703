import { message, Steps } from "antd";
import { useState } from "react";
import { NormalButton } from "../../../../ComponentLibrary";
import General from "./GeneralInforamtion";
import AllowancesSelect from "./AllowancesSelect";
const CreateGroup = ({
  setActive,
  percentageStructure,
  onSaveGroup,
  newGroupsDetails,
  setNewGroupsDetails,
  isPercentageStructure,
  updateIsPercentage,
  updatePercentageStructure,
  setPercentageStructure,
  calculatePercentage
}) => {
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);
  const onSave = async () => {
    if (current === 1) {
      await onSaveGroup();
      setCurrent(0);
    }
    else if (current === 0) {
      if (!newGroupsDetails.name) {
        message.warning("Please enter the group name");
        return
      }
      setCurrent(1)
    }
  };
  return (
    <div>
      <h1 className="tab-heading-1">New Group</h1>
      <Steps
        current={current}
        size="small"
        labelPlacement="vertical"
        style={{ width: "30%", margin: "1.8rem 0", marginLeft: "-2.75rem" }}
      >
        <Step title="General" />
        <Step title="Allowances Defining" />
      </Steps>
      {current === 0 ? (
        <General
          newGroupsDetails={newGroupsDetails}
          setNewGroupsDetails={setNewGroupsDetails}
        />
      ) : (
        <AllowancesSelect
          updateIsPercentage={updateIsPercentage}
          percentageStructure={percentageStructure}
          updatePercentageStructure={updatePercentageStructure}
          newGroupsDetails={newGroupsDetails}
          setNewGroupsDetails={setNewGroupsDetails}
          isPercentageStructure={isPercentageStructure}
          calculatePercentage={calculatePercentage}
          setPercentageStructure={setPercentageStructure}
        />
      )}
      <div style={{ marginTop: "1.5rem", marginLeft: "-0.5rem" }}>
        <NormalButton
          buttonText="Save and continue"
          type={13}
          onClick={onSave}
        />
        <NormalButton
          buttonText="Cancel"
          type={5}
          onClick={() => {
            setActive(false);
          }}
        />
      </div>
    </div>
  );
};

export default CreateGroup;
