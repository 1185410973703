import AllowanceInfo from "../allowanceGeneralComponents/allowanceInfo.js"

const allowanceInfo = {
    heading1: "HRA",
    heading2: "House rent allowance",
    para1: "To be eligible for HRA exemption, you must meet the following criteria:",
    para2: "You must be living in a rented accommodation.\nYou must be paying rent to the landlord.\nYou must be able to provide the rent receipts and rent agreement as proof.\nIf your rent is above Rs 1 lakh per annum, you have to submit your landlord's PAN.",
    para4: "A metro city implies Chennai, Kolkata, Mumbai or Delhi. All other cities in India including Gurgaon, Hyderabad and Bengaluru are non-metros."
}

const HraInfo = () => {
    return (
        <AllowanceInfo allowanceInfo={allowanceInfo} />
    )
}

export default HraInfo;