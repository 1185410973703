import {
  Spin,
  Modal,
  Row,
  Col,
  Progress,
  notification,
} from "antd";
import {
  checkUser,
  createChildOrganisation,
  createDept,
  createGroupFromParent,
  createOrUpdateDesignation,
  createOrUpdateStatus,
} from "../../apis/organisation";
import { Content } from "antd/lib/layout/layout";
import userAdd from "../../assets/icons/user-add.svg";
import { useEffect, useState } from "react";
import { checkUserExist } from "../../apis/auth";
import config from "../../utils/config";
import { createLog } from "../../apis/user";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
import NormalButton from "../ComponentLibrary/ButtonComponents/NormalButton"

const orgCreateFormat = {
  name: "",
  username: "",
  PAN: "",
  email: "",
  phone: "",
  designation: "",
  pointOfContact: "",
  organisationName: "",
  password: "",
  confirmPassword: "",
};

const errorDataState = {
  name: false,
  username: false,
  PAN: false,
  email: false,
  phone: false,
  designation: false,
  pointOfContact: false,
  organisationName: false,
  password: false,
  confirmPassword: false,
};

const errorMessages = {
  name: "",
  username: "",
  PAN: "",
  email: "",
  phone: "",
  designation: "",
  pointOfContact: "",
  organisationName: "",
  password: "",
  confirmPassword: "",
};

const message = [
  "Creating Organisation",
  "Creating Group",
  "Creating Status",
  "Creating Designations",
  "Creating Departments",
];

const AddOrganisation = ({
  modalProperties,
  user,
  resetModals,
  setModalProperties,
  organisationReducer,
  groups,
  department,
  designation,
  status,
}) => {
  const [organisation, setOrganisation] = useState({
    ...orgCreateFormat,
  });

  const [error, setError] = useState({
    ...errorDataState,
  });

  const [errorMessage, setErrorMessage] = useState({
    ...errorMessages,
  });
  const [progressStatus, setProgressStatus] = useState(0);

  useEffect(() => {
    setOrganisation({
      ...orgCreateFormat,
      PAN: user.PAN,
    });
  }, [user]);

  const checkUsername = async () => {
    if (organisation.username === "") return;
    let response = await checkUserExist({
      details: organisation.username,
      type: 2,
    });
    if (response.data) {
      setError({
        ...errorDataState,
        username: true,
      });
      setErrorMessage({
        ...errorMessage,
        username: `User with the username: ${organisation.username} already exists`,
      });
      setOrganisation({ ...organisation, username: "" });
    } else {
      setError({
        ...errorDataState,
      });
      setErrorMessage({ ...errorMessages });
    }
  };

  const searchEmail = async () => {
    if (organisation.email === "") return;
    let response = await checkUserExist({
      details: organisation.email,
      type: 1,
    });
    if (response.data) {
      setError({
        ...errorDataState,
        email: true,
      });
      setErrorMessage({
        ...errorMessage,
        email: `User with the email: ${organisation.email} already exists`,
      });

      setOrganisation({ ...organisation, email: "" });
    } else {
      setError({
        ...errorDataState,
      });
      setErrorMessage({ ...errorMessages });
    }
  };

  const searchByField = async (field) => {
    if (organisation[field] === "") return;
    let response = await checkUser(field, organisation[field]);
    if (response.status === 200) {
      if (response.data.success) {
        setError({
          ...errorDataState,
        });
        setErrorMessage({ ...errorMessages });
        return;
      } else {
        setError({
          ...errorDataState,
          phone: true,
        });
        if (response.data) {
          setError({
            ...errorDataState,
            email: true,
          });
          setErrorMessage({
            ...errorMessage,
            email: `User with the email: ${organisation.email} already exists`,
          });

          setOrganisation({ ...organisation, email: "" });
        } else {
          setError({
            ...errorDataState,
          });
          setErrorMessage({ ...errorMessages });
          return;
        }
      }
    }
  };

  const createOrganisation = async () => {
    let errors = {
      ...errorDataState,
    };
    let errorMessagesDemo = {
      ...errorMessage,
    };
    let localError = false;
    if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(organisation.PAN)) {
      localError = true;
      errors.PAN = true;
      errorMessagesDemo.PAN = "Please enter a valid PAN number";
    }
    if (!/^[a-zA-Z_@.-\d]+$/.test(organisation.username)) {
      localError = true;
      errors.username = true;
      errorMessagesDemo.username = "Please enter a valid username";
    }
    if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,100}$/.test(
        organisation.password
      )
    ) {
      localError = true;
      errors.password = true;
      errorMessagesDemo.password =
        "Password must have a length of 8 with atleast one lowercase, one uppercase, one integer.";
    }
    if (organisation.password !== organisation.confirmPassword) {
      localError = true;
      errors.confirmPassword = true;
      errorMessagesDemo.confirmPassword =
        "Password and confirm password is not matching";
    }
    if (!/^\d{10}$/.test(organisation.phone)) {
      localError = true;
      errors.phone = true;
      errorMessagesDemo.phone = "Please enter a valid phone number";
    }
    if (!/^[a-zA-Z ,.'-\d]+$/.test(organisation.name)) {
      localError = true;
      errors.name = true;
      errorMessagesDemo.name = "Invalid name format";
    }
    if (!/^[a-zA-Z ,.'-\d]+$/.test(organisation.organisationName)) {
      localError = true;
      errors.organisationName = true;
      errorMessagesDemo.organisationName = "Invalid name format";
    }
    if (!config.validateEmail(organisation.email)) {
      localError = true;
      errors.email = true;
      errorMessagesDemo.email = "Please enter a valid email";
    }
    if (!/^[a-zA-Z ,.'-\d]+$/.test(organisation.designation)) {
      localError = true;
      errors.designation = true;
      errorMessagesDemo.designation = "Invalid designation format";
    }
    if (!/^[a-zA-Z ,.'-\d]+$/.test(organisation.pointOfContact)) {
      localError = true;
      errors.pointOfContact = true;
      errorMessagesDemo.pointOfContact = "Invalid point of contact format";
    }
    if (localError) {
      setError({ ...errors });
      setErrorMessage({ ...errorMessagesDemo });
      return;
    }
    setError({ ...errorDataState });
    setErrorMessage({ ...errorMessage });

    setModalProperties({
      ...modalProperties,
      single: {
        visible: true,
        loading: true,
      },
    });
    let progress = 0;
    let createdOrg = await createChildOrganisation(organisation);
    progress += 1;
    setProgressStatus(progress);

    let { organisation: org } = createdOrg.data.data;
    delete org.organisationSettings;
    delete org.password;
    for (let g of groups) {
      let dummyGroup = { ...g };
      delete dummyGroup._id;
      delete dummyGroup.groupId;
      dummyGroup.user = org;
      await createGroupFromParent(dummyGroup);
    }

    progress += 1;
    setProgressStatus(progress);

    for (let sta of status) {
      let postBody = { ...sta };
      delete postBody._id;
      delete postBody.statusId;
      delete postBody.organisationId;
      delete postBody.createdAt;
      delete postBody.updatedAt;
      postBody.organisationId = org.organisationId;
      await createOrUpdateStatus(postBody);
    }

    progress += 1;
    setProgressStatus(progress);

    for (let des of designation) {
      let postBody = { ...des };
      delete postBody._id;
      delete postBody.designationId;
      delete postBody.organisationId;
      postBody.organisationId = org.organisationId;
      await createOrUpdateDesignation(postBody);
    }

    progress += 1;
    setProgressStatus(progress);

    for (let dep of department) {
      let postBody = { ...dep };
      delete postBody._id;
      delete postBody.departmentId;
      delete postBody.organisationId;
      postBody.organisationId = org.organisationId;
      await createDept(postBody);
    }

    progress += 1;
    setProgressStatus(progress);

    notification.success({
      message: "Successfully created child organisation",
    });
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: "User has created a new child organisation",
      category: 0,
    });
    organisationReducer.updateChildOrganisationList();
    setProgressStatus(0);
    setOrganisation({ ...orgCreateFormat, PAN: user.PAN });
    setError({ ...errorDataState });
    setErrorMessage({ ...errorMessage });
    resetModals();
  };

  return (
    <Modal
      centered
      closable={false}
      visible={modalProperties.single.visible}
      width={900}
      className="modal-container"
      footer={null}
    >
      {modalProperties.single.loading && (
        <div>
          <p className="font-weight-700 font-size-24 text-align-center mb-10">
            {message[progressStatus]}
          </p>
          <Progress
            percent={Math.round((progressStatus / message.length) * 100)}
            size="large"
            status="active"
          />
        </div>
      )}
      <Spin spinning={modalProperties.single.loading} size="large">
        <Content className={"modal-body"}>
          <div className="paper-wrapper">
            <div style={{marginLeft:"1.5em"}} className="employee-list-section-header">
              <img alt="" src={userAdd} height={25} width={25} />
              <p className="employee-list-section-heading">Add Organisation</p>
            </div>
            <Row gutter={[ 0, 5]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="org-data-container">
                  <InputFieldComponent
                    value={organisation.username}
                    labeltext={"Username"}
                    className="general-input-field"
                    placeholder="Organisation Username"
                    bordered={false}
                    onBlur={checkUsername}
                    onChange={(e) => {
                      setOrganisation({
                        ...organisation,
                        username: e.target.value,
                      });
                    }}
                    size={"large"}
                  />
                  {error.username && (
                    <p className="error m-top-minus-1">
                      {errorMessage.username}
                    </p>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="org-data-container">
                  <InputFieldComponent
                    value={organisation.PAN}
                    labeltext={"PAN Number"}
                    className="general-input-field"
                    placeholder="Organisation PAN Number"
                    bordered={false}
                    onChange={(e) => {
                      if (e.target.value.length < 11)
                        setOrganisation({
                          ...organisation,
                          PAN: e.target.value.toUpperCase(),
                        });
                    }}
                    size={"large"}
                  />
                  {error.PAN && (
                    <p className="error m-top-minus-1">{errorMessage.PAN}</p>
                  )}
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="org-data-container">
                  <InputFieldComponent
                    value={organisation.phone}
                    type={"number"}
                    labeltext={"Phone number"}
                    placeholder="Organisation 10 Digit Phone Number"
                    onBlur={() => searchByField("phone")}
                    onChange={(e) => {
                      if (e.target.value.length < 11)
                        setOrganisation({
                          ...organisation,
                          phone: e.target.value,
                        });
                    }}
                    bordered={false}
                    size={"large"}
                    className="general-input-field"
                  />
                  {error.phone && (
                    <p className="error m-top-minus-1">{errorMessage.phone}</p>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="org-data-container">
                  <InputFieldComponent
                    value={organisation.email}
                    labeltext={"Email"}
                    className="general-input-field"
                    placeholder="Organisation Email"
                    bordered={false}
                    onBlur={searchEmail}
                    onChange={(e) =>
                      setOrganisation({
                        ...organisation,
                        email: e.target.value,
                      })
                    }
                    size={"large"}
                  />
                  {error.email && (
                    <p className="error m-top-minus-1">{errorMessage.email}</p>
                  )}
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="org-data-container">
                  <InputFieldComponent
                    value={organisation.password}
                    labeltext={"Password"}
                    type={"password"}
                    className="general-input-field"
                    placeholder="Password"
                    bordered={false}
                    onChange={(e) => {
                      if (e.target.value.length < 11)
                        setOrganisation({
                          ...organisation,
                          password: e.target.value,
                        });
                    }}
                    size={"large"}
                  />
                  {error.PAN && (
                    <p className="error m-top-minus-1">
                      {errorMessage.password}
                    </p>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="org-data-container">
                  <InputFieldComponent
                    value={organisation.confirmPassword}
                    labeltext={"Confirm Password"}
                    className="general-input-field"
                    type={"password"}
                    placeholder="Confirm Password"
                    bordered={false}
                    onChange={(e) => {
                      if (e.target.value.length < 11)
                        setOrganisation({
                          ...organisation,
                          confirmPassword: e.target.value,
                        });
                    }}
                    size={"large"}
                  />
                  {error.PAN && (
                    <p className="error m-top-minus-1">
                      {errorMessage.confirmPassword}
                    </p>
                  )}
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="org-data-container">
                  <InputFieldComponent
                    value={organisation.name}
                    labeltext={"Company Legal Name"}
                    placeholder="Organisation Legal Name"
                    className="general-input-field"
                    bordered={false}
                    onChange={(e) => {
                      setOrganisation({
                        ...organisation,
                        name: e.target.value,
                      });
                    }}
                    size={"large"}
                  />
                  {error.name && (
                    <p className="error m-top-minus-1">{errorMessage.name}</p>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="org-data-container">
                  <InputFieldComponent  
                    value={organisation.organisationName}
                    labeltext={"Organization Name"}
                    placeholder="Organisation Name"
                    className="general-input-field"
                    bordered={false}
                    onChange={(e) => {
                      setOrganisation({
                        ...organisation,
                        organisationName: e.target.value,
                      });
                    }}
                    size={"large"}
                  />
                  {error.organisationName && (
                    <p className="error m-top-minus-1">
                      {errorMessage.organisationName}
                    </p>
                  )}
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="org-data-container">
                  <InputFieldComponent
                    value={organisation.pointOfContact}
                    labeltext={"Point of Contact"}
                    className="general-input-field"
                    placeholder="Point of contact"
                    bordered={false}
                    onChange={(e) => {
                      setOrganisation({
                        ...organisation,
                        pointOfContact: e.target.value,
                      });
                    }}
                    size={"large"}
                  />
                  {error.pointOfContact && (
                    <p className="error m-top-minus-1">
                      {errorMessage.pointOfContact}
                    </p>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="org-data-container">
                  <InputFieldComponent
                    value={organisation.designation}
                    className="general-input-field"
                    labeltext={"Designation"}
                    placeholder="Designation of point of contact"
                    bordered={false}
                    onChange={(e) => {
                      setOrganisation({
                        ...organisation,
                        designation: e.target.value,
                      });
                    }}
                    size={"large"}
                  />
                  {error.designation && (
                    <p className="error m-top-minus-1">
                      {errorMessage.designation}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
            <div className="flex w-full justify-start">
            <NormalButton
                buttonText={"Create Organization"}
                onClick={createOrganisation}
                type={13}
                className="submit-button"
              />
              <NormalButton
                onClick={() => {
                  setModalProperties({
                    ...modalProperties,
                    single: {
                      visible: false,
                      loading: false,
                    },
                  });
                }}
                buttonText={"Cancel"}
                type={5}
                className="cancel-button"
                style={{
                  border: "none",
                  boxShadow: "none",
                  marginRight: "0px",
                }}
              />
            </div>
          </div>
        </Content>
      </Spin>
    </Modal>
  );
};

export default AddOrganisation;
