import React from 'react';

/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Col,
  Progress,
  Row,
  Spin,
  Table,
  Upload,
  message,
  notification,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useState } from "react";
import { updateFormCDetails } from '../../apis/employee';
import { createLog } from "../../apis/user";
import uploadLogo from "../../assets/icons/savefile.svg";
import config from "../../utils/config";
import { NormalButton } from "../ComponentLibrary";

const { Dragger } = Upload;

const FormCBulk = ({ user, groups, handleAction, commonColumn }) => {
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formCList, setFormCList] = useState([]);
  const [displayFormCList, setDisplayFormCList] = useState(
    []
  );
  const [completed, setCompleted] = useState(0);

  const heading = ["Upload the Document", "Verify and Update"];
  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/parser/formC`,
    headers: {
      authorization: localStorage.getItem("mool_auth")
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        setPageState(1);
        setFormCList([...response]);
        let res = response.map((val, index) => {
          return { ...val, key: index + 1 };
        });
        setDisplayFormCList([...res]);
      }
    }
  };

  const reset = () => {
    setFormCList([]);
    setPageState(0);
    setCompleted(0);
  };

  const updateFormCData = async () => {
    if (formCList.length === 0) {
      message.error("Please Upload Excel File");
      return;
    }
    setIsLoading(true);
    let totalSuccess = 0;
    let { financialYear } = config.checkFinancialYear();
    for (const element of formCList) {
      const { id, remarks, dateOnWhichPaid, } = element;
      await updateFormCDetails({
        id,
        financialYear,
        remarks,
        dateOnWhichPaid,
      })
      totalSuccess += 1;
      setCompleted(totalSuccess);
    }
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organisation user updated ${formCList.length} number of advance tax for ${financialYear} financial year`,
      category: 15,
    });
    notification.success({
      message: "Successfully Uploaded the advance tax data"
    });

    setIsLoading(false);
    reset();
  };

  return (
    <div className="">
      {isLoading &&
        pageState === 1 &&
        <Progress
          percent={Math.round(completed / formCList.length * 100)}
          size="small"
          status="active"
        />}
      <Spin size="large" spinning={isLoading}>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="flex align-center">
              {pageState !== 0 &&
                <ArrowLeftOutlined
                  onClick={() => {
                    let page = pageState;
                    page = page - 1;
                    setPageState(page);
                  }}
                  style={{ marginRight: 10 }}
                  className="arrow-left-button font-weight-700"
                />}
              <h3 className="app-heading-3">
                {heading[pageState]}
              </h3>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="flex w-full justify-end">
              <NormalButton
                type={5}
                buttonText="Cancel"
                onClick={reset}
                className="cancel-button"
                style={{
                  border: "none",
                  boxShadow: "none",
                  marginRight: 10
                }}
              />
              <NormalButton
                type={13}
                buttonText="Upload Form-C"
                onClick={updateFormCData}
                className="submit-button"
                style={{
                  marginRight: "0px",
                  padding: "0px 24px"
                }}
              />
            </div>
          </Col>
        </Row>
        {pageState === 0 &&
          <UploadDocument
            platforms={{ ...uploadProps }}
            user={user}
            handleAction={handleAction}
          />}
        {pageState === 1 &&
          <VerifyAndUpload
            displayPercentageStructure={displayFormCList}
            commonColumn={commonColumn}
          />}
      </Spin>
    </div>
  );
};

const UploadDocument = ({ platforms, user, handleAction }) => {
  return (
    <Content className="bulk-wrapper-left">
      <div>
        <p className="paper-title">
          Download Sample in{" "}
          <a
            className="download-employee-list"
            onClick={() =>
              handleAction(
                `${config.rootUrl}/api/generate/document/formC/${user.organisationId}`,
                "FormC.xlsx"
              )}
          >
            .xlsx format
          </a>{" "}
          file to ensure that you have the correct file ready to import.
        </p>
      </div>
      <div>
        <Dragger
          {...platforms}
          showUploadList={false}
          height={300}
          className="bulk-wrapper-dragger"
        >
          <p className="ant-upload-drag-icon">
            <img alt="" src={uploadLogo} height="80" width="80" />
          </p>

          <p className="ant-upload-text">Drag and drop Excel file</p>
          <p className="ant-upload-hint">
            or <u>Click Here</u> to upload
          </p>
        </Dragger>
      </div>
    </Content>
  );
};

const VerifyAndUpload = ({ displayPercentageStructure, commonColumn }) => {
  const columns = [
    ...commonColumn,
    {
      title: "Date On Which Paid",
      dataIndex: "dateOnWhichPaid",
      key: "dateOnWhichPaid",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    }
  ]
  return (
    <Table
      columns={columns}
      dataSource={displayPercentageStructure}
      pagination={false}
      scroll={{ x: 600, y: "65vh" }}
    />
  );
};

export default FormCBulk