import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLeaveLogsByStatus } from "../../../../apis/organisation";
import TrialPage from "../../../Common/TrialComponent";
import attendanceBgImage from "../../../../assets/dummyPages/attendanceBgImage.png"

const History = ({ user, isTrial }) => {
  const [history, setHistory] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    const getLeaveRecords = async () => {
      let approved = await getLeaveLogsByStatus(`${user.organisationId}/1`);
      let rejected = await getLeaveLogsByStatus(`${user.organisationId}/2`);
      let final = [...approved.data, ...rejected.data];
      final = final.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setHistory([...final]);
    };
    getLeaveRecords();
  }, [user, isRefresh]);
  const status = ["", "Approved", "Rejected"]
  const columns = [
    {
      title: "Employee ID",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
      key: "name",
      render: (data) => <p style={{ fontSize: '0.7rem' }}>{data}</p>
    },
    {
      title: "Name",
      dataIndex: ["user", "displayName"],
      key: "name",
      render: (data) => <p style={{ fontSize: '0.7rem' }}>{data}</p>
    },
    {
      title: "Type",
      dataIndex: ["leaveType", "name"],
      key: "type",
      render: (data) => <p style={{ fontSize: '0.7rem' }}>{data}</p>
    },
    {
      title: "Leave",
      dataIndex: "startDate",
      key: "leave",
      render: (data, row) => <p style={{ fontSize: '0.7rem' }}>{`${new Date(data).toDateString()} - ${new Date(row.endDate).toDateString()}`}</p>
    },
    {
      title: "Requested",
      dataIndex: "createdAt",
      key: "requested",
      render: (data) => <p style={{ fontSize: '0.7rem' }}>{new Date(data).toDateString()}</p>
    },
    {
      title: "Approved",
      dataIndex: "status",
      key: "approved",
      render: (data) => <p className={`approved-${data}`}>{status[data]}</p>
    },

    {
      title: "Reason",
      dataIndex: "status",
      key: "notes",
      render: (status, data) => <p style={{ fontSize: '0.7rem' }}>{status === 1 ? data.reason : data.comments}</p>

    },

    {
      title: "Days",
      dataIndex: "duration",
      key: "days",
      render: (data) => <p style={{ fontSize: '0.7rem' }}>{data}</p>
    },
  ];
  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }
  return (
    isTrial ? <TrialPage user={user} trialMessage={" Get access to India's first tax optimizing and salary personalization tool powering your payroll"} bgImage={attendanceBgImage}
    /> :
    <div className="report-container">
      <div className="report-header-container">
        <p className="font-size-global-24 font-weight-global-700">History</p>
        <span style={{ float: "right", marginRight: "0.5em" }}>
          <Button
            onClick={startRefresh}
            className={`button-lib button-type-13 p-10`}
            style={{ height: "2.5rem" }}
          >
            <div className="flex justify-between align-center">
              Refresh
            </div>
          </Button>
        </span>
      </div>
      <Table pagination={history.length <= 8 ? false : { pageSize: 8 }} columns={columns} dataSource={history.reverse()} />
    </div>
  );
};

const LeaveHistory = connect((state) => ({
  user: state.auth.user,
  isTrial: state.auth.user.isTrial,

}))(History);

export default LeaveHistory;
