import HeaderComponent from "../Common/Header/Header";
import reportActive from "../../assets/icons/activeIcons/reports.svg";
import { Content } from "antd/lib/layout/layout";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { paths } from "../../constants/routes";
import { useEffect } from "react";
import TrialPage from "../Common/TrialComponent";
import reportsBgImage from "../../assets/dummyPages/reportsBgImage.png"
const OrganisationReport = ({ access, user, isTrial }) => {

    const ComplianceReport = [
      {
        key: "salaryRegister",
        title: "Salary Register",
        subTitle:
          "Get the reports of the overall salary structure of employees",
        showMonth: false,
        fileDownload: true,
        fileDownloadLink: "payslip/excel",
        type: "payRegister",
      },
      {
        key: "taxDeduction",
        title: "Tax Deduction",
        subTitle: "Get to know the overall tax details of the employees",
        showMonth: true,
        fileDownload: true,
        fileDownloadLink: `organisation/tds`,
        type: "general",
      },
      {
        key: "pfRegister",
        title: "PF Register",
        subTitle: "See all the PF information",
        showMonth: true,
        fileDownload: true,
        type: "general",
        fileDownloadLink: "organisation/pf",
      },
      {
        key: "professionalTax",
        title: "Professional Tax",
        subTitle: "View the professional tax data that the company pays",
        showMonth: true,
        fileDownload: true,
        type: "general",
        fileDownloadLink: "organisation/profTax",
      },
    ];
    const Report = [
      {
        key: "bankAdvice",
        title: "Bank Advice",
        subTitle: "Bank Advice for the specific month",
        showMonth: true,
        fileDownload: true,
        type: "bankInfo",
        fileDownloadLink: "bankadvice",
      },
      {
        key: "salary",
        title: "Salary",
        subTitle:
          "Get the reports of the overall salary structure of employees",
        showMonth: false,
        fileDownload: true,
        type: "general",
        fileDownloadLink: "organisation",
      },
      {
        key: "monthlyCTC",
        title: "Monthly CTC",
        subTitle: "MonthlyCTC For All Employees",
        showMonth: true,
        fileDownload: true,
        type: "general",
        fileDownloadLink: "ctc/monthly",
      },
      {
        key: "yearlyCTC",
        title: "Yearly CTC",
        subTitle: "Yearly CTC For All Employees",
        showMonth: true,
        fileDownload: true,
        type: "ctc",
        fileDownloadLink: "ctc/excel/yearly",
      },
      {
        key: "YTDEarning",
        title: "YTD Earning",
        subTitle: "Year Till Date For All Employees",
        showMonth: true,
        fileDownload: true,
        type: "ytd",
        fileDownloadLink: "payslip/excel/ytd",
      },
      {
        key: "statutoryBonus",
        title: "Statutory Bonus",
        subTitle: "Statutory Bonus Report",
        showMonth: true,
        fileDownload: true,
        type: "general",
        fileDownloadLink: "payslip/statutory_bonus/excel",
      },
      {
        key: "reim",
        title: "Reimbursement",
        subTitle: "Reimbursement Report",
        showMonth: true,
        fileDownload: true,
        type: "reim",
        fileDownloadLink: "reimbursement/report",
      },
      {
        key: "monthClose",
        title: "Arrears and Bonuses",
        subTitle: "Arrears and Bonuses Report",
        showMonth: true,
        fileDownload: true,
        type: "mclose",
        fileDownloadLink: "mclose/excel",
      },
    ];

    useEffect(() => {
        document.title = 'Reports';
    });

    return (
        isTrial ? <TrialPage user={user} trialMessage={"Access all HR & Payroll related statutory reports in one place"} bgImage={reportsBgImage}
        /> :
            <div>
                <HeaderComponent show={false} showMoolCoins={user.role === "org"} icon={reportActive} name="Reports" />
                <Content className="app-main-content-container">
                    {
                        ComplianceReport.map((orgReportType) => {
                            return (user.role === "org" || (user.role === "employee" && access.reports.subAccess[orgReportType.key].access)) && (
                                <Link to={{
                                    pathname: paths.org.reportsTable,
                                    state: { data: orgReportType }
                                }}
                                >
                                    <div className="org-report-card">
                                        <p className="org-report-type">{orgReportType.title}</p>
                                        <p className="org-report-subtitle">{orgReportType.subTitle}</p>
                                    </div>
                                </Link>
                            )
                        })
                    }
                    {user.role === "org" && <h3 className="h5-bold-20" style={{ marginTop: "40px" }}>Reports</h3>}
                    {
                        Report.map((orgReportType) => {
                            return (user.role === "org" || (user.role === "employee" && access.reports.subAccess[orgReportType.key].access)) && (
                                <Link to={{
                                    pathname: paths.org.reportsTable,
                                    state: { data: orgReportType }
                                }}
                                >
                                    <div className="org-report-card">
                                        <p className="org-report-type m-bottom-5">{orgReportType.title}</p>
                                        <p className="org-report-subtitle">{orgReportType.subTitle}</p>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </Content>

            </div>
    )
}

const OrganisationReportContainer = connect(
    (state) => ({
        access: state.auth.access,
        user: state.auth.user,
        isTrial: state.auth.user.isTrial,

    })
)(OrganisationReport)

export default withRouter(OrganisationReportContainer);