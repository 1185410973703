import { Col, notification, Progress, Row, Spin, Table, Switch } from "antd";
import { useEffect, useState } from "react";
import { generatePayslip } from "../../../apis/employee";
import { fetchActiveEmployees, setPayslipLog } from "../../../apis/organisation";
import { blockPayroll } from "../../../apis/organisation";
import { createLog } from "../../../apis/user";
import { NormalButton } from "../../ComponentLibrary";
import axios from "../../../apis";
import "./payslip.css";
const PayslipFreeze = ({
  user: usr,
  organisationId,
  month,
  financialYear,
  updatePayrollData,
  orgPayslipInfo,
  getPayslipFreeze,
  closeRonPayroll,
}) => {
  const [freezing, setFreezing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noOfPayslipUpdated, setNoOfPayslipUpdated] = useState(0);
  const [employeeDisplayData, setEmployeeDisplayData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    if (organisationId) {
      fetchActiveEmployees(organisationId).then(res => {
        setEmployeeList(res.data)
      }).catch(err => {
        setEmployeeList([])
      })
    }
  }, [organisationId])
  const [blockedEmployees, setBlockedEmployees] = useState({});

  useEffect(
    () => {
      updateEmployeeList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [employeeList, month]
  );
  useEffect(() => {
    axios
      .get(
        `/employees/blocked/payroll/${organisationId}/${month}/${financialYear}`
      )
      .then((res) => {
        setBlockedEmployees(res.data.data);
      });
  }, [employeeList, organisationId, month, financialYear]);
  const columns = [
    {
      title: "Employee Id",
      dataIndex: ["currentOrganisationMeta", "employeeId"],
      defaultSortOrder: "descend",
    },
    {
      title: "Name",
      dataIndex: "displayName",
      defaultSortOrder: "descend",
    },
    {
      title: "PAN",
      dataIndex: "PAN",
      defaultSortOrder: "descend",
    },
    {
      title: "Email",
      dataIndex: "primaryEmail",
      defaultSortOrder: "descend",
    },
    {
      title: "Block Employee",
      render: (_data, row) => (
        <Switch
          onChange={(checked) => handleChange(row, checked)}
          checked={blockedEmployees[row._id]}
        ></Switch>
      ),
    },
  ];
  const handleCss = (row) => {
    if (blockedEmployees[row._id]) return "blocked";
  };
  const handleChange = (row, checked) => {
    setBlockedEmployees({ ...blockedEmployees, [row._id]: checked });
    setSelectedRowKeys([...new Set([...selectedRowKeys, row._id])])
    blockPayroll({
      id: row._id,
      isFreeze: checked,
      month: month,
      financialYear: financialYear,
      organisationId: organisationId,
    })
  };
  const onSelectChange = (rows) => {
    setSelectedRowKeys([...rows]);
  };
  const updateEmployeeList = async () => {
    let modifiedEmployeeList = [];
    let increment = 0;
    employeeList.forEach((val) => {
      let payslipYear = parseInt(
        month < 3 ? financialYear.split("-")[1] : financialYear.split("-")[0]
      );
      let dateOfJoining = new Date(val.currentOrganisationMeta.dateOfJoining);
      if (
        dateOfJoining.getFullYear() < payslipYear ||
        (payslipYear === dateOfJoining.getFullYear() &&
          dateOfJoining.getMonth() <= month)
      ) {
        modifiedEmployeeList.push({
          key: increment,
          ...val,
          dateOfJoining: dateOfJoining.toLocaleDateString(),
          employeeId: val.currentOrganisationMeta.employeeId,
        });
        increment++;
      }
    });
    setEmployeeDisplayData([...modifiedEmployeeList]);
  };

  const processPayroll = async () => {
    try {
      if (
        selectedRowKeys.length === 0 &&
        orgPayslipInfo.totalNonGeneratedPayslips <= 0
      ) {
        setLoading(true);
        setFreezing(true);
        await setPayslipLog({
          organisationId,
          month,
          financialYear,
        });
        setLoading(false);
        setFreezing(false);
        await updatePayrollData();
        setNoOfPayslipUpdated(0);
        setSelectedRowKeys([]);
        await getPayslipFreeze();
        return;
      }
      if (selectedRowKeys.length === 0) {
        notification.warning({
          message: "Please select at least one employee to process the payroll",
        });
        return;
      }
      setLoading(true);
      setFreezing(true);
      let total = 1;
      let startTime = Date.now();
      for (const element of selectedRowKeys) {
        await generatePayslip({
          id: element,
          financialYear,
          month,
          getGraphDataByKey: true,
          sendGraphData: false,
          savePayslip: true,
        });
        setNoOfPayslipUpdated(total);
        total++;
      }
      let endTime = Date.now();
      await createLog({
        name: usr.displayName,
        userId: usr._id,
        organisationId: usr.organisationId,
        message: `Organization user executed run payroll in ${(endTime -
          startTime) /
          1000} sec.`,
        category: 1,
      });
      await setPayslipLog({
        organisationId,
        totalPayslipGenerated: total,
        month,
        financialYear,
      });
      await updatePayrollData();
      setFreezing(false);
      setLoading(false);
      setNoOfPayslipUpdated(0);
      setSelectedRowKeys([]);
      await getPayslipFreeze();
      notification.success({
        message: "Payroll has been processed successfully",
      });
      closeRonPayroll();
    } catch (err) {
      setFreezing(false);
      setLoading(false);
      closeRonPayroll();
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  return (
    <div className="w-full h-full flex flex-direction-column justify-between align-start">
      <div className="settlement-body w-full">
        {freezing && (
          <Progress
            percent={Math.round(
              (noOfPayslipUpdated / selectedRowKeys.length) * 100
            )}
            size="small"
            status="active"
          />
        )}
      </div>
      <div className="settlement-body w-full">
        <Spin spinning={loading} size="large">
          <Row>
            {/* <Col md={6} xs={24} sm={24}>
              <InfoCard
                heading="Total Employee"
                value={orgPayslipInfo.totalEmployees}
              />
            </Col> */}
            <Col md={6} xs={24} sm={24}>
              <InfoCard
                heading="Salary Frozen"
                value={orgPayslipInfo.totalPayslipGenerated}
              />
            </Col>
            <Col md={6} xs={24} sm={24}>
              <InfoCard
                heading="Unfrozen Salary"
                value={
                  orgPayslipInfo.totalNonGeneratedPayslips < 0
                    ? 0
                    : orgPayslipInfo.totalNonGeneratedPayslips
                }
              />
            </Col>
            <Col md={6} xs={24} sm={24}>
              <InfoCard
                heading="Selected Employees"
                value={selectedRowKeys.length}
              />
            </Col>
          </Row>
          <div className="w-full">
            <Table
              rowClassName={handleCss}
              pagination={false}
              scroll={{ y: 300 }}
              rowKey={"_id"}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={employeeDisplayData}
            />
          </div>
          <div className="w-full flex justify-end m-top-8">
            <NormalButton
              type={13}
              buttonText={"Run Payroll"}
              onClick={processPayroll}
              margin={[0, 8]}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};

const InfoCard = ({ heading, value }) => (
  <div className="flex justify-start align-center">
    <h3 className="title-gray-4">{heading} : </h3>
    <p className="info-number-4"> {value} </p>
  </div>
);

export default PayslipFreeze;
