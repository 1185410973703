import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import configureStore from './redux/configureStore';
import reportWebVitals from './reportWebVitals';
import {
  gAnalyticsKey, gTagKey, hotJarKey,
  hotJarSv
} from './utils/env';
const initialState = {};
const store = configureStore(initialState);

// Tag Manager

if (gTagKey) {
  const tagManagerArgs = {
    gtmId: gTagKey
  }
  TagManager.initialize(tagManagerArgs);
}

// Google Analytics
if (gAnalyticsKey) {
  ReactGA.initialize(gAnalyticsKey);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

// Hotjar
if (hotJarKey && hotJarSv) {
  hotjar.initialize(parseInt(hotJarKey), parseInt(hotJarSv));
}

// Clarity
// if (clarityID) {
//   clarity.init(clarityID);
// }

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
