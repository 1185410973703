import { Checkbox, Drawer, notification, Radio, Table, Tabs } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getGasWaterElectricity, postGasWaterElectricity } from "../../../apis/perqs";
import organisation from "../../../redux/modules/organisation";
import { getFullToolTip } from "../../../utils/common";
import { checkFinancialYear } from "../../../utils/config";
import { HeaderComponent } from "../../Common";
import { NormalButton } from "../../ComponentLibrary";
import Spinner from "../../ComponentLibrary/Spinner/Spinner";
import InputNumberComponent from "../../InputLibrary/InputNumberComponent/InputNumberComponent";
import "./perqs.css";
import TrialPage from "../../Common/TrialComponent";
import perqsBgImage from "../../../assets/dummyPages/perqsBgImage.png"


const { financialYear, month } = checkFinancialYear()
const { TabPane } = Tabs;

const GasElectricityWater = ({ organisationSettings, employeeList, user, isTrial }) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(0);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [showData, setShowData] = useState(false);
  const [requestBody, setRequestBody] = useState({
    id: "",
    bifurcatedAmount: 0,
    recoveryAmount: 0,
    isFullRecovery: false,
    isRecovery: true,
    isServiceProvidedByEmployer: true,
    displayName: "",
    employeeId: ""
  })
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    getGasWaterElectricity(user.organisationId).then(res => {
      setData(res.data.data);
    })
  }, [user, showData]);

  function callback(key) {
    setType(parseInt(key))
  }

  const onSave = () => {
    setLoading(true)
    postGasWaterElectricity({ ...requestBody, type, financialYear, month }).then(res => {
      if (res.data.success) {
        notification.success({ message: "Data Saved Successfully!" })
        setOpen(false)
      } else {
        notification.error({ message: "Error while Saving The Data!" })
      }
    }).catch(err => {
      notification.error({ message: "Error while Saving The Data!" })
    })
    setShowData(!showData)
    setLoading(false)
  }


  const info = (
    <div className="section">
      <div style={{ marginTop: "2rem" }}>
        <p className="subtitle3-med-16">The services are being provided by</p>
        <Radio.Group onChange={(e) => {
          setRequestBody({
            ...requestBody,
            isServiceProvidedByEmployer: e.target.value
          });
        }} value={requestBody.isServiceProvidedByEmployer} className="section-top">
          <Radio value={true}>Organisation</Radio>
          <Radio value={false}>Agency</Radio>
        </Radio.Group>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <p className="subtitle3-med-16">
          {
            requestBody.isServiceProvidedByEmployer ? "What is the manufacturing cost incurred (per month) ?" : "Amount paid by employer to agency (per month) ?"
          }
        </p>
        <span style={{ display: "flex" }}>
          <InputNumberComponent labeltext={"Amount"} value={requestBody.bifurcatedAmount} onChange={(e) => {
            setRequestBody({
              ...requestBody,
              bifurcatedAmount: e
            })
          }} width={"100%"} /> &nbsp;{" "}
          <div style={{ marginTop: "6%" }}>/month</div>
        </span>
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        <p className="subtitle3-med-16">How do you want to recover the amount from your employee (per month) ?</p>
        <Radio.Group onChange={(e) => {
          setRequestBody({
            ...requestBody,
            isFullRecovery: e.target.value,
            recoveryAmount: e.target.value ? requestBody.bifurcatedAmount : 0
          });
        }} value={requestBody.isFullRecovery} className="section-top">
          <Radio value={true}>Fully
            {getFullToolTip(requestBody.isServiceProvidedByEmployer ? "Manufacturing cost incurred by employer (per month)" : "The sum amount paid by employer to the agency (per month)")}
          </Radio>
          <Radio value={false}>Custom</Radio>
        </Radio.Group>
      </div>
      {
        !requestBody.isFullRecovery && <div style={{ marginTop: "2rem" }}>
          <InputNumberComponent labeltext={"Amount"} value={requestBody.recoveryAmount}
            disabled={requestBody.isFullRecovery}
            onChange={(value) => {
              setRequestBody({
                ...requestBody,
                recoveryAmount: value
              })
            }} width={"100%"} />
        </div>
      }
      <div style={{ marginTop: "2rem" }}>
        <p className="subtitle3-med-16">Do you want recover the amount from employee’s salary</p>
        <Radio.Group onChange={(e) => {
          setRequestBody({
            ...requestBody,
            isRecovery: e.target.value
          });
        }} value={requestBody.isRecovery} className="section-top">
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </div>
      <div className="flex" style={{ marginTop: "5rem" }} >
        <NormalButton
          type={13}
          onClick={onSave}
          buttonText="Save Changes"
        />
        <NormalButton type={5} onClick={() => { setOpen(false) }} buttonText="Cancel" />
      </div>
    </div >
  );

  const columns = [
    {
      title: "",
      dataIndex: "flag",
      render: (flag) => {
        return <Checkbox checked={flag} disabled={true} />
      }
    },
    {
      title: "ID",
      dataIndex: "employeeId",
    },
    {
      title: "Name",
      dataIndex: "displayName",
    },
    {
      title: "Service provided by",
      dataIndex: "id",
      render: (id, data) => {
        return <p>{data.flag ? data.isServiceProvidedByEmployer ? "Organisation" : "Employee" : "Not Defined"}</p>
      }
    },
    {
      title: "Cost/ month (in INR)",
      dataIndex: "id",
      render: (id, data) => {
        return <p>{data.flag ? data.recoveryAmount ? data.recoveryAmount : 0 : "Not Defined"}</p>
      }
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, record, index) => {
        return (
          <NormalButton
            buttonText={"Configure"}
            type={15}
            onClick={() => configureEmployeePerq(record)}
          ></NormalButton>
        );
      },
    },
  ];

  const configureEmployeePerq = (record) => {
    showDrawer();
    setRequestBody({ ...record, isRecovery: record["perquisites"] && record["perquisites"][month] ? record["perquisites"][month]['isRecovery'] : true });
  }


  const employeeListDiv = (
    <>
      <h1 className="h5-bold-20">Employee List</h1>
      <div className="section">
        <Table
          columns={columns}
          dataSource={data && data[type] ? data[type] : []}
        />
      </div>
    </>
  );

  return (
    isTrial ? <TrialPage user={user} trialMessage={" Explore the tax heaven for salaried employees without getting stressed about compliances"} bgImage={perqsBgImage}
    /> :
      <section className="site-layout">
        <HeaderComponent name="Gas, Electricity and Water" />
        <div className="app-main-content-container paper-wrapper">
          <Spinner spinning={loading}>
            <div>
              <h2 className="app-heading-2 font-weight-700">
                Gas, Electricity and Water
              </h2>

              <Tabs defaultActiveKey="0" onChange={callback}>
                <TabPane tab="Gas" key="0">
                  <div>{employeeListDiv}</div>
                </TabPane>
                <TabPane tab="Electricity" key="1">
                  <div>{employeeListDiv}</div>
                </TabPane>
                <TabPane tab="Water" key="2">
                  <div>{employeeListDiv}</div>:
                </TabPane>
              </Tabs>

            </div>
          </Spinner>
        </div>
        <Drawer
          width={500}
          title={<><p className="subtitle3-med-16">{requestBody.displayName}</p><p className="subtitle4">{requestBody.employeeId}</p></>}
          placement="right"
          onClose={onClose}
          visible={open}
        >
          <div className="section">{info}</div>
        </Drawer>
      </section>
  );
};

const GasElectricityWaterPerqs = connect(
  (state) => ({
    user: state.auth.user,
    organisationSettings: state.organisation.organisationSettings,
    orgLoading: state.organisation.loading,
    employeeList: state.organisation.employeeList,
    isTrial: state.auth.user.isTrial,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(GasElectricityWater);

export default GasElectricityWaterPerqs;
