import React, { useState } from "react";
import { Input } from "antd";
import "../InputFieldComponent/InputFieldComponent.less";
import * as check from "../../../apis/organisation";

function InputVerifyComponent({
  setCheckUserExist,
  uniqueKey,
  setSubmitClicked,
  submitClicked,
  onChange,
  maxWidth,
  required,
  disabled,
  value,
  width,
  height,
  labeltext,
  display,
  type,
  margin,
  min,
  max,
}) {
  let styles = {
    width: width ? `${width}` : "288px",
    maxWidth: maxWidth ? `${maxWidth}` : "100%",
    height: height ? `${height}` : "50px",
    borderRadius: "8px",
  };
  let stylediv = {
    position: "relative",
    display: display ? display : "block",
  };
  let parentdivStyle = {
    margin: margin
      ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`
      : "10px 0px",
  };
  const [checkingState, setCheckingState] = useState(false);
  const [userMatched, setUserMatched] = useState(false);
  const [messageVisible, setMessageVisible] = useState(true);
  let timer;
  const handleCheckUserName = async () => {
    let data = await check.checkUserName({
      details: value.toLowerCase(),
      type: 2,
    });
    setMessageVisible(true);
    setCheckingState(false);
    setUserMatched(data.data);
    setCheckUserExist(data.data);
  };
  return (
    <div style={parentdivStyle}>
      <div className="inputdiv" style={stylediv}>
        <Input
          className={
            submitClicked === uniqueKey &&
            (value === "" || userMatched) &&
            "errorState"
          }
          type={type}
          required={required}
          disabled={disabled}
          value={value}
          min={min}
          autoComplete={"off"}
          max={max}
          onFocus={() => {
            setSubmitClicked("");
          }}
          onBlur={() => {
            if (value !== "") {
              setCheckingState(true);
              setTimeout(handleCheckUserName, 1000);
            }
            if (!userMatched || value === "") {
              setMessageVisible(false);
            }
          }}
          onKeyDown={() => {
            setCheckingState(true);
            clearTimeout(timer);
          }}
          onKeyUp={() => {
            clearTimeout(timer);
            timer = setTimeout(handleCheckUserName, 1000);
          }}
          placeholder=" "
          style={styles}
          onChange={onChange}
        />
        <label
          className={`labelnormal ${submitClicked === uniqueKey &&
            `${value === "" ? `errorState` : `errorShakeEffect`}`}`}
        >
          {labeltext ? labeltext : "Your Label"}
        </label>
      </div>
      {submitClicked === uniqueKey && value === "" ? (
        <p className="activeerrormessage">This is required Field</p>
      ) : (
        <p
          className={`${checkingState
            ? `verifying`
            : `verifyMsg ${messageVisible &&
            (value !== ""
              ? `${userMatched ? "errormsg" : "successmsg"}`
              : ``)}`
            }`}
        >
          {value !== ""
            ? checkingState
              ? "checking....."
              : `${userMatched
                ? "Username already exists"
                : "User Name available"
              }`
            : "checking....."}
        </p>
      )}
    </div>
  );
}

export default InputVerifyComponent;
