import { useState, useRef, useEffect } from "react";
import {
  Layout,
  Button,
  Input,
  notification,
  Switch,
  message,
  Checkbox,
} from "antd";
import { withRouter } from "react-router-dom";
import * as org from "../../../../apis/organisation";
import { calculatePercentage } from "../../../../utils/common";
import InputNumberComponent from "../../../InputLibrary/InputNumberComponent/InputNumberComponent";
const { Content } = Layout;

const ManageAllowances = ({
  user,
  isCustomStructure,
  changePageState,
  isPercentageStructure
}) => {
  const [loading, setLoading] = useState(false);
  const [allowances, setAllowances] = useState(
    {}
  );
  const [displayAllowances, setDisplayAllowances] = useState({});
  const [percentageStructure, setPercentageStructure] = useState({
    basic: 0,
    hra: 0,
    buffer: 0.15,
    specialAllowances: 0,
    allowances: {
      tiffinLunchDinner: { isPercent: false, value: 2000 },
      mobile: { isPercent: false, value: 1000 },
      LTA: { isPercent: false, value: 3000 },
    },
    customAllowances: {},
    basicHRAPercentage: {
      basic: true,
      hra: true,
    },
  });

  useEffect(() => {
    const { allowances: allowancesObject } = user.organisationSettings;
    const allowancesDuplicate = { ...allowancesObject }
    if (allowancesDuplicate) {
      let sortedAllowances = {};
      for (let allowancesKey in allowancesDuplicate) {
        if (allowancesDuplicate[allowancesKey]["applicable"]) {
          sortedAllowances[allowancesKey] = allowancesDuplicate[allowancesKey];
          delete allowancesDuplicate[allowancesKey];
        }
      }
      sortedAllowances = {
        ...sortedAllowances,
        ...allowancesDuplicate,
      }
      setAllowances(sortedAllowances);
      setDisplayAllowances(sortedAllowances);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.organisationSettings.allowances])



  const submitAllowances = async () => {
    if (isPercentageStructure && percentageStructure.basic <= 0) {
      handleBasicPercentError();
      notification.error({ message: "Please enter all fields" });
      return;
    }
    if (isPercentageStructure && percentageStructure.hra <= 0) {
      notification.error({ message: "Please enter all fields" });
      return;
    }
    let allowancesKeys = Object.keys(allowances);
    if (isCustomStructure) {
      for (const element of allowancesKeys) {
        if (
          allowances[element]["applicable"] &&
          !allowances[element]["limit"]
        ) {
          notification.error({ message: "Please enter all allowances limit" });
          return;
        }
      }
    }
    setLoading(true);
    let tempPercent = { ...percentageStructure };
    if (isPercentageStructure) {
      let percentTotal = calculatePercentage(percentageStructure);
      let remaining = Math.round((1 - percentTotal) * 1000) / 1000;
      tempPercent.specialAllowances = remaining > 0 ? remaining : 0;
    }

    let data = await org.updateSettings({
      organisationId: user.organisationId,
      organisationSettings: {
        ...user.organisationSettings,
        allowances,
        percentageStructure: tempPercent,
      },
    });
    if (data.data.success) {
      localStorage.removeItem('localOrgSettings');
      changePageState();
    } else {
      notification.error({ message: "Sorry unable to set the allowance" });
    }
    setLoading(false);
  };

  const changeAllowancesState = (key, value) => {
    if (isPercentageStructure) {
      let tempPercent = { ...percentageStructure };
      if (value) {
        tempPercent.allowances[key] = {
          isPercent: true,
          value: 0,
        };
      } else {
        delete tempPercent.allowances[key];
      }
      setPercentageStructure({ ...tempPercent });
    }
    let newAllowances = allowances;
    newAllowances[key]["applicable"] = value;
    setAllowances({ ...newAllowances });
  };

  const [searchtextval, setSearchtextval] = useState("");
  const searchAllowance = (e) => {
    setSearchtextval(e.target.value);
    let newAllowances = {};
    Object.keys(allowances).forEach((val) => {
      if (
        allowances[val].label
          .toLowerCase()
          .search(e.target.value.toLowerCase()) !== -1
      )
        newAllowances[val] = allowances[val];
    });
    setDisplayAllowances(newAllowances);
  };

  const calculateIsApplicable = (percentage) => {
    let percentTotal = calculatePercentage(percentage);
    let remaining = 1 - percentTotal;
    let isApplicable = remaining >= 0;
    return isApplicable;
  };

  /**
   *
   * @param {*} value
   * @param {*} field
   * @param {*} type
   * type - 0 basic hra
   * type - 1 for allowances
   * type - 2 for custom allowances
   */
  const updatePercentageStructure = (value, field, type) => {
    let percentValue = value / 100;
    let tempPercent = { ...percentageStructure };
    if (type === 0) {
      tempPercent[field] = tempPercent.basicHRAPercentage[field]
        ? percentValue
        : value;
    } else if (type === 1) {
      let allowance = {
        ...tempPercent.allowances,
      };
      if (allowance[field]) {
        allowance[field] = {
          ...allowance[field],
          value: allowance[field]["isPercent"] ? percentValue : value,
        };
      } else {
        allowance[field] = {
          value: percentValue,
          isPercent: true,
        };
      }
      tempPercent["allowances"] = { ...allowance };
    }
    else {
      return
    }
    let isApplicable = calculateIsApplicable(tempPercent);
    if (!isApplicable) {
      message.warning("The value exceeded the remaining percentage");
      return;
    }
    setPercentageStructure({
      ...tempPercent,
    });
  };

  const updateIsPercentage = (field, value) => {
    if (isPercentageStructure) {
      let tempPercent = { ...percentageStructure };
      let allowance = {
        ...tempPercent.allowances,
      };
      allowance[field]["isPercent"] = value;
      allowance[field]["value"] = value ? 0 : allowance[field]["value"];
      tempPercent["allowances"] = { ...allowance };
      setPercentageStructure({
        ...tempPercent,
      });
    }
  };

  const myRef = useRef(null);
  const handleBasicPercentError = () => {
    myRef.current.scrollTo({
      behavior: "smooth",
      top: 0
    });
  }
  return (
    <Content className="stages">
      <div className="main-container">
        <p className="header-title">
          Select or create monthly custom allowance for your organisation
        </p>
        <div className="allowance-search-container">
          <Input
            style={{ paddingLeft: '15px' }}
            onChange={searchAllowance}
            className="Allowance-input-field"
            size={"large"}
            placeholder="Search for allowance "
            bordered={false}
            allowClear={true}
          />
          {/* <NormalButton
              onClick={showAllowanceModal}
              type={8}
              buttonText={"+ Add Allowance"}
              padding={[2]}
            /> */}
        </div>
        {!searchtextval && isPercentageStructure && (
          <div className="flex justify-between align-center p-5" style={{ margin: '26px 30px 26px 0' }}>
            <p className="app-label-5 font-weight-600">Special Allowance</p>
            <p className="app-label-3 font-weight-700">
              {Math.round(
                (1 - calculatePercentage(percentageStructure)) * 100
              )}{" "}
              % of CTC
            </p>
          </div>
        )}
        {!searchtextval && isPercentageStructure && (
          <div className="flex justify-between align-center p-5" style={{ marginRight: '30px' }}>
            <p className="app-label-5 font-weight-600">Buffer</p>
            <p className="app-label-3 font-weight-700">
              {percentageStructure.buffer * 100} % of CTC
            </p>
          </div>
        )}
        <div className="allowances-container" ref={myRef}>
          {!searchtextval && isPercentageStructure && (
            <div>
              {!searchtextval &&
                <div className="flex justify-between align-center my-20 border-box">
                  <p className="app-label-1">Basic</p>
                  <div>
                    {
                      !percentageStructure.basicHRAPercentage.basic && <Checkbox
                        checked={!percentageStructure.basicHRAPercentage.basic}
                        onChange={(value) => {
                          setPercentageStructure({
                            ...percentageStructure,
                            basicHRAPercentage: {
                              ...percentageStructure.basicHRAPercentage,
                              basic: !value.target.checked,
                            },
                            basic: value.target.checked
                              ? 0
                              : percentageStructure.basic,
                          });
                        }}
                      >
                        Amount
                      </Checkbox>
                    }
                    <InputNumberComponent
                      placeholder={0}
                      min={0}
                      display={'inline-block'}
                      width={'6.5rem'}
                      height={'2.4rem'}
                      size="large"
                      labeltext={"% of CTC"}
                      className="m-right-16"
                      onChange={(value) =>
                        updatePercentageStructure(value, "basic", 0)
                      }
                      value={
                        percentageStructure.basicHRAPercentage.basic
                          ? percentageStructure.basic * 100
                          : percentageStructure.basic
                      }
                    />
                  </div>
                </div>
              }
              {!searchtextval &&
                <div className="flex justify-between align-center my-20 border-box">
                  <p className="app-label-1">HRA</p>
                  <div>
                    {
                      !percentageStructure.basicHRAPercentage.hra && <Checkbox
                        checked={!percentageStructure.basicHRAPercentage.hra}
                        onChange={(value) => {
                          setPercentageStructure({
                            ...percentageStructure,
                            basicHRAPercentage: {
                              ...percentageStructure.basicHRAPercentage,
                              hra: !value.target.checked,
                            },
                            hra: value.target.checked
                          });
                        }}
                      >
                        Amount
                      </Checkbox>
                    }

                    <InputNumberComponent
                      placeholder={0}
                      size="large"
                      className="m-right-16"
                      min={0}
                      display={'inline-block'}
                      width={'6.5rem'}
                      height={'2.4rem'}
                      labeltext={"% of CTC"}
                      onChange={(value) =>
                        updatePercentageStructure(value, "hra", 0)
                      }
                      value={
                        percentageStructure.basicHRAPercentage.hra
                          ? percentageStructure.hra * 100
                          : percentageStructure.hra
                      }
                    />
                  </div>
                </div>}
            </div>
          )}
          {Object.keys(displayAllowances).map((val, index) => (
            <div className="single-allowance" key={`allowance-${index}`}>
              <div className="flex align-center">
                {isPercentageStructure && (
                  <Switch
                    className="m-right-16"
                    size="large"
                    checked={allowances[val]["applicable"]}
                    onChange={(checked) =>
                      changeAllowancesState(val, checked)
                    }
                  />
                )}
                {searchtextval === "" ? (<p>{allowances[val].label}</p>) : (
                  <p>{allowances[val].label.split(new RegExp(`(${searchtextval})`, 'gi')).map((part, i) =>
                    <span key={i} style={part.toLowerCase() === searchtextval.toLowerCase() ?
                      { background: '#ffebb7' } : {}}>
                      {part}
                    </span>)}</p>
                )}
              </div>
              <div className="allownace-action-container">
                {!isPercentageStructure && (
                  <Switch
                    style={{ marginRight: 10 }}
                    size="large"
                    checked={allowances[val]["applicable"]}
                    onChange={(checked) =>
                      changeAllowancesState(val, checked)
                    }
                  />
                )}
                {allowances[val].applicable &&
                  (isCustomStructure || isPercentageStructure) && (
                    <>
                      {isPercentageStructure && (
                        percentageStructure.allowances[val] && <Checkbox
                          checked={
                            !percentageStructure.allowances[val]["isPercent"]
                          }
                          onChange={(value) =>
                            updateIsPercentage(val, !value.target.checked)
                          }
                        >
                          Amount
                        </Checkbox>
                      )}
                      <InputNumberComponent
                        disabled={!allowances[val]["applicable"]}
                        min={0}
                        display={'inline-block'}
                        width={'6.5rem'}
                        height={'2.4rem'}
                        onChange={(value) => {
                          if (isPercentageStructure) {
                            updatePercentageStructure(value, val, 1);
                          }
                          let newAllowances = allowances;
                          newAllowances[val]["limit"] = parseInt(value)
                            ? value
                            : 0;
                          setAllowances({ ...newAllowances });
                        }}
                        value={
                          isPercentageStructure
                            ? percentageStructure.allowances[val] &&
                              percentageStructure.allowances[val]?.isPercent
                              ? Math.round(percentageStructure["allowances"][val].value *
                                100 * 1000) / 1000
                              : percentageStructure.allowances[val]?.value
                            : allowances[val]["limit"]
                        }
                        labeltext={isPercentageStructure ? (percentageStructure?.allowances[val] &&
                          allowances[val].applicable && (percentageStructure.allowances[val].isPercent
                            ? ` % of CTC`
                            : " Per Month"
                          )) : ``}
                      />
                    </>
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="submit-container" style={{ marginTop: 40 }}>
        <Button
          loading={loading}
          onClick={submitAllowances}
          className="submit-org"
          style={{ background: '#405cd2', padding: '15px', height: '60px', fontSize: '1.125rem' }}
          htmlType="submit"
        >
          Save and Submit
        </Button>
        <Button
          disabled={loading}
          style={{ marginRight: 10, padding: '15px', height: '60px', fontSize: '1.125rem' }}
          className="skip-button"
          type="dashhed"
          htmlType="submit"
          onClick={submitAllowances}
        >
          I'll do it later
        </Button>
      </div>
    </Content>
  );
};
export default withRouter(ManageAllowances);
