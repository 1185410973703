import {
  Spin,
  notification,
  Select,
  Modal,
  Row,
  Col,
  Radio,
  message,
} from "antd";
import {
  checkUser,
  createNewEmployee,
  getUserFromEmail,
  getUserFromPAN,
  createDept,
  createOrUpdateLocation,
  createOrUpdateDesignation,
} from "../../../apis/organisation";
import { Content } from "antd/lib/layout/layout";
import userAdd from "../../../assets/icons/user-add.svg";
import config, { states, statesCodes } from "../../../utils/config";
import {
  pincodeData,
  getKeyByValue,
} from "../../../constants/common_functions";
import { useState } from "react";
import { createLog } from "../../../apis/user";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";
import DateComponent from "../../InputLibrary/DateComponent/DateComponent";
import DropdownComponent from "../../InputLibrary/DropdownComponent/DropdownComponent";
import { NormalButton } from "../../ComponentLibrary";
import InputNumberComponent from "../../InputLibrary/InputNumberComponent/InputNumberComponent";

const singleOkText = ["Save and Continue", "Save and Create"];
const { Option } = Select;

const employeeCreateFormat = {
  name: "",
  phone: "",
  PAN: "",
  email: "",
  ctc: 0,
  employeeId: "",
  dateOfJoining: "",
  dateOfBirth: "",
  organisationId: "",
  variablePercentage: 0,
  groupId: "",
  departmentId: "",
  designationId: "",
  locationId: "",
  isEmployerContributionIncludedInCTC: true,
  pfEnabled: false,
};

const errorDataState = {
  name: false,
  phone: false,
  PAN: false,
  email: false,
  ctc: false,
  employeeId: false,
  dateOfJoining: false,
  dateOfBirth: false,
  organisationId: false,
  variablePercentage: false,
  groupId: false,
  departmentId: false,
  designationId: false,
  locationId: false,
};

const errorMessages = {
  name: "",
  phone: "",
  PAN: "",
  email: "",
  ctc: 0,
  employeeId: "",
  dateOfJoining: "",
  dateOfBirth: "",
  organisationId: "",
  variablePercentage: 0,
  groupId: "",
  departmentId: "",
  designationId: "",
  locationId: "",
};

const AddEmployee = ({
  modalProperties,
  pageState,
  setPageState,
  user,
  resetModals,
  setModalProperties,
  organisationReducer,
  groups,
  location,
  department,
  designation,
  updateOnboarding,
}) => {
  const [singleEmployee, setSingleEmployee] = useState({
    ...employeeCreateFormat,
  });

  const [error, setError] = useState({
    ...errorDataState,
  });

  const [errorMessage, setErrorMessage] = useState({
    ...errorMessages,
  });

  const [addModal, setAddModal] = useState({ showModal: false, type: "" });

  const [field, setField] = useState("");

  const [newLocationData, setNewLocationData] = useState({
    officeName: "",
    color: "#fff",
    address: "",
    postalCode: null,
    country: "India",
    city: "",
    state: "",
  });

  const disabledDate = (value) => {
    const form = Date.now();
    return value > form;
  };

  const findEmployee = async () => {
    if (singleEmployee.PAN === "") return;
    let response = await getUserFromPAN(singleEmployee.PAN);
    if (response.status === 200) {
      let usr = response.data;
      if (Object.keys(usr).length === 0) {
        setError({
          ...errorDataState,
        });
        setErrorMessage({ ...errorMessages });
        return;
      }
      if (usr.currentOrganisationId || usr.organisationId) {
        setError({
          ...errorDataState,
          PAN: true,
        });
        setErrorMessage({
          ...errorMessage,
          PAN: `User with the PAN: ${singleEmployee.PAN} already is in a organisation`,
        });
        setSingleEmployee({ ...employeeCreateFormat });
      } else {
        let dummyEmployee = singleEmployee;
        dummyEmployee.phone = usr.phone;
        dummyEmployee.name = usr.displayName;
        setSingleEmployee({ ...dummyEmployee });
      }
    }
  };

  const serachEmployeeEmail = async () => {
    if (singleEmployee.email === "") return;
    let response = await getUserFromEmail(singleEmployee.email);
    if (response.status === 200) {
      let usr = response.data;
      if (!response.data.success) {
        setError({
          ...errorDataState,
        });
        setErrorMessage({ ...errorMessages });
        return;
      }
      if (usr.currentOrganisationId || usr.organisationId) {
        setError({
          ...errorDataState,
          email: true,
        });
        setErrorMessage({
          ...errorMessages,
          email: `User with the email: ${singleEmployee.email} already is in a organisation`,
        });
        setSingleEmployee({ ...singleEmployee, email: "" });
      }
    }
  };

  const searchByField = async (field) => {
    if (singleEmployee[field] === "") return;
    let response = await checkUser(field, singleEmployee[field]);
    if (response.status === 200) {
      if (response.data.success) {
        setError({
          ...errorDataState,
        });
        setErrorMessage({ ...errorMessages });
        return;
      } else {
        setError({
          ...errorDataState,
          phone: true,
        });
        setErrorMessage({
          ...errorMessages,
          phone: `User with the phone: ${singleEmployee.phone} already is in a organisation`,
        });
        let employee = singleEmployee;
        employee[field] = "";
        setSingleEmployee({ ...employee });
      }
    }
  };

  const handleCancel = () => {
    if (pageState.single === 0) {
      setSingleEmployee({
        ...employeeCreateFormat,
      });
      setError({ ...errorDataState });
      setErrorMessage({ ...errorMessages });
      resetModals();
    } else {
      setError({ ...errorDataState });
      setErrorMessage({ ...errorMessages });
      let newPageState = pageState;
      newPageState.single--;
      setPageState({ ...newPageState });
    }

    modalProperties.single.visible = false;
  };

  const setSingleEmployeeData = (key, value) => {
    let val = value;
    if (key === "PAN") val = val.toUpperCase();
    let tempEmployeeData = singleEmployee;
    tempEmployeeData[key] = val;
    setSingleEmployee({ ...tempEmployeeData });
  };

  const createSingleEmployee = async () => {
    if (pageState.single < 1) {
      let errors = {
        ...errorDataState,
      };
      let errorMessagesDemo = {
        ...errorMessage,
      };
      let localError = false;
      if (!(singleEmployee.PAN || singleEmployee.email)) {
        localError = true;
        errors.PAN = true;
        errors.email = true;
        errorMessagesDemo.PAN = "Please enter a valid PAN number or Email ID ";
        errorMessagesDemo.email = "Please enter a valid Email ID or PAN number";
      } else {
        if (singleEmployee.PAN && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(singleEmployee.PAN)) {
          localError = true;
          errors.PAN = true;
          errorMessagesDemo.PAN = "Please enter a valid PAN number";
        }
        if (singleEmployee.email && !config.validateEmail(singleEmployee.email)) {
          localError = true;
          errors.email = true;
          errorMessagesDemo.email = "Please enter a valid email";
        }
      }

      // else {
      //   let response = await getUserFromPAN(singleEmployee.PAN);
      //   if (response.status === 200) {
      //     let usr = response.data;
      //     if (Object.keys(usr).length !== 0) {
      //       if (usr.currentOrganisationId || usr.organisationId) {
      //         localError = true;
      //         errors.PAN = true;
      //         errorMessagesDemo.PAN = `User with the PAN: ${singleEmployee.PAN} already is in a organisation`;
      //       }
      //     }
      //   }
      // }
      if (singleEmployee.phone && !/^\d{10}$/.test(singleEmployee.phone)) {
        localError = true;
        errors.phone = true;
        errorMessagesDemo.phone = "Please enter a valid phone number";
      }
      if (!/^[A-Za-z\s]+$/.test(singleEmployee.name)) {
        localError = true;
        errors.name = true;
        errorMessagesDemo.name = "Invalid name format";
      }
      if (!singleEmployee.dateOfJoining) {
        localError = true;
        errors.dateOfJoining = true;
        errorMessagesDemo.dateOfJoining = "Please enter the date of joining";
      }

      if (!/^[A-Za-z0-9\s]+$/.test(singleEmployee.employeeId)) {
        localError = true;
        errors.employeeId = true;
        errorMessagesDemo.employeeId = "Please enter an employee ID";
      }
      if (localError) {
        setError({ ...errors });
        setErrorMessage({ ...errorMessagesDemo });
        return;
      }
      let newPageState = pageState;
      newPageState.single++;
      setPageState({ ...newPageState });
    } else {
      let errors = {
        ...errorDataState,
      };
      let localError = false;
      let errorMessagesDemo = {
        ...errorMessage,
      };
      if (!singleEmployee.ctc || !parseInt(singleEmployee.ctc)) {
        localError = true;
        errors.ctc = true;
        errorMessagesDemo.ctc = "Please enter the CTC";
      }

      if (!singleEmployee.departmentId) {
        localError = true;
        errors.departmentId = true;
        errorMessagesDemo.departmentId = "Please assign a department";
      }
      if (!singleEmployee.designationId) {
        localError = true;
        errors.designationId = true;
        errorMessagesDemo.designationId = "Please assign a designation";
      }
      if (!singleEmployee.locationId) {
        localError = true;
        errors.locationId = true;
        errorMessagesDemo.locationId = "Please assign a location";
      }
      if (localError) {
        setError({ ...errors });
        setErrorMessage({ ...errorMessagesDemo });
        return;
      }

      // !Feature changed to Randomized Username
      // let username = "";
      // // Select username
      // if (singleEmployee.PAN) {
      //   username = singleEmployee.PAN;
      // } else if (singleEmployee.email) {
      //   username = singleEmployee.email;
      // } else if (singleEmployee.phone) {
      //   username = singleEmployee.phone;
      // } else {
      //   notification.error({ message: "Unable to select username" });
      //   return;
      // }
      let modalProps = modalProperties;
      modalProps.single.loading = true;
      setModalProperties({ ...modalProps });

      let res = await createNewEmployee({
        ...singleEmployee,
        organisationId: user.organisationId,
      });
      if (res.data.success) {
        await createLog({
          name: user.displayName,
          userId: user._id,
          organisationId: user.organisationId,
          message: `Organisation User created a new employee: ${singleEmployee.name}`,
          category: 16,
        });
        await updateOnboarding("employeeAddition");
        notification.success({
          message: `Successfully created the employee`,
        });
      } else {
        notification.error({
          message: `Sorry Unable to create one employee`,
        });
      }
      organisationReducer.updateEmployeeList({
        organisationId: user.organisationId,
      });
      resetModals();
      setSingleEmployee({ ...employeeCreateFormat });
      setError({ ...errorDataState });
      setErrorMessage({ ...errorMessages });
    }
  };

  const handleAddModalCancel = () => {
    setAddModal({ ...addModal, showModal: false });
  };

  const createField = async () => {
    if (addModal.type === "department") {
      let data = {
        departmentName: field,
        color: "#000",
        description: "",
        organisationId: user.organisationId,
      };

      let res = await createDept(data);
      if (res.data.success) {
        message.success("New department field has been created");
      } else {
        message.error("Unable to create field");
      }
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has created a department`,
        category: 14,
      });
      organisationReducer.getDepartment(user.organisationId);
      let newDeptId = res.data.data.departmentId;
      setSingleEmployee({ ...singleEmployee, departmentId: newDeptId });
    }
    if (addModal.type === "location") {

      if (newLocationData.officeName === "") {
        notification.error({ message: "Name cannot be empty." });
        return;
      }
      if (newLocationData.address === "") {
        notification.error({ message: "Address cannot be empty." });
        return;
      }
      if (newLocationData.city === "") {
        notification.error({ message: "City cannot be empty." });
        return;
      }
      if (!newLocationData.country) {
        notification.error({ message: "Country cannot be empty." });
        return;
      }
      if (newLocationData.state === "") {
        notification.error({ message: "State cannot be empty." });
        return;
      }
      if (!parseInt(newLocationData.postalCode)) {
        notification.error({ message: "Pincode cannot be empty." });
        return;
      }
      let data = {
        ...newLocationData,
        organisationId: user.organisationId,
      };

      let res = await createOrUpdateLocation(data);
      if (res.data.success) {
        message.success("New location field has been created");
      } else {
        message.error("Unable to create field");
      }
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has created a location`,
        category: 14,
      });
      organisationReducer.getLocation(user.organisationId);
      let newLocationId = res.data.data.locationId;
      setSingleEmployee({ ...singleEmployee, locationId: newLocationId });
    }
    if (addModal.type === "designation") {
      let data = {
        designationName: field,
        color: "#fff",
        description: "",
        organisationId: user.organisationId,
      };

      let res = await createOrUpdateDesignation(data);
      if (res.data.success) {
        message.success("New designation field has been created");
      } else {
        message.error("Unable to create field");
      }
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `User has created a designation`,
        category: 14,
      });
      organisationReducer.getDesignation(user.organisationId);
      let newDesignationId = res.data.data.designationId;
      setSingleEmployee({ ...singleEmployee, designationId: newDesignationId });
    }

    setAddModal({ showModal: false, type: "" });
  };
  return (
    <Modal
      centered
      closable={true}
      width={700}
      visible={modalProperties.single.visible}
      className="modal-container"
      footer={null}
      onCancel={handleCancel}
    >
      <Spin spinning={modalProperties.single.loading} size="large">
        <Content className={"modal-body"}>
          <div className="">
            <div className="employee-list-section-header org-data-container">
              <img alt="" src={userAdd} height={25} width={25} />
              <p className="employee-list-section-heading">Add employee</p>
            </div>
            <Modal
              visible={addModal.showModal}
              closable={true}
              footer={null}
              onCancel={handleAddModalCancel}
              width={400}
            >
              <p className="font-size-18 font-weight-700">
                Create new {addModal.type}
              </p>
              <p className="font-size-16 font-weight-500">
                Fill in {addModal.type}
              </p>

              {addModal.type !== "location" && (
                <InputFieldComponent
                  labeltext="Name"
                  width={"400px"}
                  value={field}
                  onChange={(e) => setField(e.target.value)}
                />
              )}
              {addModal.type === "location" && (
                <>
                  <InputFieldComponent
                    labeltext={"Name"}
                    autoFocus={true}
                    width={"400px"}
                    value={newLocationData.officeName}
                    onChange={(e) => {
                      setNewLocationData({
                        ...newLocationData,
                        officeName: e.target.value,
                      });
                    }}
                  />
                  <InputFieldComponent
                    labeltext={"Address"}
                    width={"400px"}
                    value={newLocationData.address}
                    onChange={(e) => {
                      setNewLocationData({
                        ...newLocationData,
                        address: e.target.value,
                      });
                    }}
                  />
                  <InputFieldComponent
                    labeltext={"City"}
                    width={"400px"}
                    value={newLocationData.city}
                    onChange={(e) => {
                      setNewLocationData({
                        ...newLocationData,
                        city: e.target.value,
                      });
                    }}
                  />
                  <InputFieldComponent
                    type={"number"}
                    width={"400px"}
                    value={newLocationData.postalCode}
                    labeltext={"Postal Code"}
                    onChange={(e) => {
                      if (e.target.value.length > 6) {
                        return;
                      }
                      setNewLocationData({
                        ...newLocationData,
                        postalCode: parseInt(e.target.value),
                      });

                      if (
                        parseInt(e.target.value) &&
                        e.target.value.length < 7
                      ) {
                        setNewLocationData({
                          ...newLocationData,
                          postalCode: parseInt(e.target.value),
                        });
                        if (e.target.value.length === 6) {
                          setNewLocationData({
                            ...newLocationData,
                            postalCode: parseInt(e.target.value),
                          });
                          const data = pincodeData(e.target.value);
                          Promise.resolve(data).then((res) => {
                            setNewLocationData({
                              ...newLocationData,
                              postalCode: parseInt(e.target.value),
                              city: res.Block,
                              state: getKeyByValue(statesCodes, res.State),
                            });
                          });
                        }
                      }
                    }}
                  />
                  <DropdownComponent
                    size="large"
                    width={"400px"}
                    labeltext={"Organization State"}
                    value={newLocationData.state}
                    options={Object.keys(states)}
                    values={Object.values(states)}
                    style={{ width: "100%", paddingLeft: "5px" }}
                    showSearch
                    optionFilterProp="state"
                    filterOption={(input, option) =>
                      option.children
                        .split(" ")
                        .join("")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(val) =>
                      setNewLocationData({
                        ...newLocationData,
                        state: val,
                      })
                    }
                  >
                    {Object.keys(config.states).map((val, index) => (
                      <Option value={config.states[val]} key={`month-${index}`}>
                        {val}
                      </Option>
                    ))}
                  </DropdownComponent>
                  <InputFieldComponent
                    labeltext={"Country"}
                    width={"400px"}
                    margin={[30, 0, 0, 0]}
                    value={newLocationData.country}
                  />
                </>
              )}
              <div className="flex">
                <NormalButton
                  buttonText={"Save and Create"}
                  type={13}
                  onClick={createField}
                  className="submit-button"
                  style={{
                    marginRight: "0px",
                    padding: "0px 24px",
                  }}
                />
                <NormalButton
                  onClick={handleAddModalCancel}
                  buttonText={"Cancel"}
                  type={5}
                  className="submit-button"
                  style={{
                    marginRight: "0px",
                    padding: "0px 24px",
                  }}
                />
              </div>
            </Modal>
            {pageState.single === 0 ? (
              <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <InputFieldComponent
                      labeltext={"PAN Number"}
                      value={singleEmployee.PAN}
                      className="general-input-field"
                      placeholder="Employee PAN Number"
                      bordered={false}
                      onBlur={findEmployee}
                      onChange={(e) => {
                        if (e.target.value.length < 11)
                          setSingleEmployeeData("PAN", e.target.value);
                      }}
                      size={"large"}
                    />
                    {error.PAN && (
                      <p className="error m-top-minus-1">{errorMessage.PAN}</p>
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <InputFieldComponent
                      labeltext={"Phone Number"}
                      type={"number"}
                      value={singleEmployee.phone}
                      onBlur={() => searchByField("phone")}
                      onChange={(e) => {
                        if (e.target.value.length < 11)
                          setSingleEmployeeData("phone", e.target.value);
                      }}
                      bordered={false}
                      size={"large"}
                      className="general-input-field"
                    />
                    {error.phone && (
                      <p className="error m-top-minus-1">
                        {errorMessage.phone}
                      </p>
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <InputFieldComponent
                      labeltext={"Email"}
                      value={singleEmployee.email}
                      className="general-input-field"
                      placeholder="Employee Email"
                      bordered={false}
                      onBlur={serachEmployeeEmail}
                      onChange={(e) =>
                        setSingleEmployeeData("email", e.target.value)
                      }
                      size={"large"}
                    />
                    {error.email && (
                      <p className="error m-top-minus-1">
                        {errorMessage.email}
                      </p>
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <InputFieldComponent
                      labeltext={"Name"}
                      value={singleEmployee.name}
                      className="general-input-field"
                      placeholder="Employee Name"
                      bordered={false}
                      onChange={(e) =>
                        setSingleEmployeeData("name", e.target.value)
                      }
                      size={"large"}
                    />
                    {error.name && (
                      <p className="error m-top-minus-1">{errorMessage.name}</p>
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <InputFieldComponent
                      labeltext={"Employee ID"}
                      value={singleEmployee.employeeId}
                      className="general-input-field"
                      placeholder="Employee ID"
                      bordered={false}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (/^[0-9A-Za-z]{0,20}$/.test(inputValue))
                          setSingleEmployeeData("employeeId", e.target.value);
                      }}
                      size={"large"}
                    />
                    {error.employeeId && (
                      <p className="error m-top-minus-1">
                        {errorMessage.employeeId}
                      </p>
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <DateComponent
                      labelText={"Date of Joining"}
                      onChange={(date) => {
                        setSingleEmployeeData("dateOfJoining", date);
                      }}
                      value={singleEmployee.dateOfJoining}
                      disabledDate={disabledDate}
                      style={{ width: "100%" }}
                      size={"large"}
                    />
                    {error.dateOfJoining && (
                      <p className="error">{errorMessage.dateOfJoining}</p>
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <InputNumberComponent
                      value={singleEmployee.ctc}
                      type={"number"}
                      margin={[10, 0, 10, 0]}
                      labeltext={"CTC"}
                      className="general-input-field"
                      placeholder="Employee CTC"
                      max={500000000}
                      bordered={false}
                      onChange={(e) =>
                        setSingleEmployeeData("ctc", parseInt(e))
                      }
                      size={"large"}
                    />
                    {error.ctc && (
                      <p className="error">{errorMessage.ctc}</p>
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <p className="font-size-16 ">
                      Employer contribution included in CTC ?
                    </p>
                    <Radio.Group
                      onChange={(e) => {
                        setSingleEmployee({
                          ...singleEmployee,
                          isEmployerContributionIncludedInCTC: e.target.value,
                        });
                      }}
                      value={singleEmployee.isEmployerContributionIncludedInCTC}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <p className="font-size-16 ">
                      Is the employee opting for PF?
                    </p>
                    <Radio.Group
                      onChange={(e) => {
                        setSingleEmployee({
                          ...singleEmployee,
                          pfEnabled: e.target.value,
                        });
                      }}
                      value={singleEmployee.pfEnabled}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <InputFieldComponent
                      value={singleEmployee.variablePercentage}
                      type={"number"}
                      labeltext={"Variable Percentage"}
                      className="general-input-field"
                      style={{ width: "100%", padding: "2px" }}
                      placeholder="Variable Salary Percentage"
                      bordered={false}
                      max={100}
                      onChange={(e) =>
                        setSingleEmployeeData(
                          "variablePercentage",
                          parseInt(e.target.value)
                        )
                      }
                      size={"large"}
                    />
                    {error.variablePercentage && (
                      <p className="error m-top-minus-1">
                        {errorMessage.variablePercentage}
                      </p>
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <DropdownComponent
                      className="general-input-field"
                      value={singleEmployee.groupId}
                      valueCanBeBlankString
                      labeltext={"Allowance Group Type"}
                      values={[
                        ...groups.map((item) => item.groupId),
                        "",
                        "intern",
                      ]}
                      options={[
                        ...groups.map((item) => item.name),
                        "General",
                        "Intern",
                      ]}
                      onChange={(e) => setSingleEmployeeData("groupId", e)}
                      style={{ width: "100%", padding: "2px" }}
                      bordered={false}
                      size={"large"}
                      filterOption={(input, option) =>
                        option.children &&
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    />

                    {error.groupId && (
                      <p className="error m-top-minus-1">
                        {errorMessage.groupId}
                      </p>
                    )}
                  </div>
                </Col>
                {/* {console.log(addModal)} */}
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div className="org-data-container">
                    <DropdownComponent
                      className="general-input-field"
                      labeltext={"Department"}
                      value={singleEmployee.departmentId}
                      values={department.map((item) => item.departmentId)}
                      options={department.map((item) => item.departmentName)}
                      onChange={(e) => setSingleEmployeeData("departmentId", e)}
                      style={{ width: "100%", padding: "2px" }}
                      bordered={false}
                      size={"large"}
                      addMoreButton={true}
                      setAddModal={setAddModal}
                      filterOption={(input, option) =>
                        option.children &&
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    >
                      {department.map((val, index) => {
                        return (
                          <Option
                            key={`dept-select-${index}`}
                            value={val.departmentId}
                          >
                            {val.departmentName}
                          </Option>
                        );
                      })}
                    </DropdownComponent>
                    {error.departmentId && (
                      <p className="error">
                        {errorMessage.departmentId}
                      </p>
                    )}
                  </div>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12}>
                  <div
                    style={{ marginTop: "2em" }}
                    className="org-data-container"
                  >
                    <DropdownComponent
                      className="general-input-field"
                      labeltext={"Designation"}
                      value={singleEmployee.designationId}
                      values={designation.map((item) => item.designationId)}
                      options={designation.map((item) => item.designationName)}
                      onChange={(e) =>
                        setSingleEmployeeData("designationId", e)
                      }
                      style={{ width: "100%", padding: "2px" }}
                      bordered={false}
                      size={"large"}
                      addMoreButton={true}
                      setAddModal={setAddModal}
                      filterOption={(input, option) =>
                        option.children &&
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    >
                      {designation.map((val, index) => {
                        return (
                          <Option
                            key={`designation-select-${index}`}
                            value={val.designationId}
                          >
                            {val.designationName}
                          </Option>
                        );
                      })}
                    </DropdownComponent>
                    {error.designationId && (
                      <p className="error">
                        {errorMessage.designationId}
                      </p>
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <div
                    style={{ marginTop: "2em" }}
                    className="org-data-container"
                  >
                    <DropdownComponent
                      className="general-input-field"
                      labeltext={"Location"}
                      value={singleEmployee.locationId}
                      values={location.map((item) => item.locationId)}
                      options={location.map((item) => item.officeName)}
                      onChange={(e) => setSingleEmployeeData("locationId", e)}
                      style={{ width: "100%", padding: "2px" }}
                      bordered={false}
                      size={"large"}
                      addMoreButton={true}
                      setAddModal={setAddModal}
                      filterOption={(input, option) =>
                        option.children &&
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    >
                      {location.map((val, index) => {
                        return (
                          <Option
                            key={`location-select-${index}`}
                            value={val.locationId}
                          >
                            {val.officeName}
                          </Option>
                        );
                      })}
                    </DropdownComponent>
                    {error.locationId && (
                      <p className="error">
                        {errorMessage.locationId}
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            )}
            <div className="submit-container-modal">
              <NormalButton
                onClick={createSingleEmployee}
                buttonText={singleOkText[pageState.single]}
                type={13}
                className="submit-button"
                style={{
                  marginRight: "0px",
                  padding: "0px 24px",
                }}
              />
              {pageState.single ? (
                <NormalButton
                  onClick={() => {
                    if (pageState.single === 0) {
                      setSingleEmployee({
                        ...employeeCreateFormat,
                      });
                      setError({ ...errorDataState });
                      setErrorMessage({ ...errorMessages });
                      resetModals();
                    } else {
                      setError({ ...errorDataState });
                      setErrorMessage({ ...errorMessages });
                      let newPageState = pageState;
                      newPageState.single--;
                      setPageState({ ...newPageState });
                    }
                  }}
                  buttonText={"Back"}
                  type={5}
                  className="cancel-button"
                  style={{
                    border: "none",
                    boxShadow: "none",
                    marginRight: "0px",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </Content>
      </Spin>
    </Modal>
  );
};

export default AddEmployee;
