import React, { useEffect, useState } from "react";
import { Select, Tabs } from "antd";
import Allowance from "./Declaration/allowance";
import Deduction from "./Declaration/deduction";
import { connect } from "react-redux";
import { checkFinancialYear } from "../../../utils/config";

const { TabPane } = Tabs;
const { Option } = Select;

const Declaration = ({ user, orgInfo }) => {
  const [currFinancialYear, setCurrFinancialYear] = useState(
    checkFinancialYear().financialYear
  );
  const [financialYearList, setFinancialYearList] = useState([]);
  useEffect(() => {
    function calculateFinancialYear() {
      let startFYear = 2020;
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let resFinancialYearList = [];
      for (let i = startFYear; i < currentYear + 1; i++) {
        let nextYear = i + 1;
        let tempFYear = `${i}-${nextYear}`;
        resFinancialYearList.push(tempFYear);
      }
      resFinancialYearList.reverse();
      setFinancialYearList([...resFinancialYearList]);
    }
    calculateFinancialYear();
  }, []);
  return (
    <div className="employee-details-container" >
      <div className="flex items-center justify-between">
        <h2 className="font-weight-700 text-size-25">Proofs</h2>
        <div
          className="flex align-center justify-end"
          style={{ marginBottom: "2rem" }}
        >
          <h3 className="font-weight-700 text-size-21 mb-2 ml-5">Financial Year</h3>
          <Select
            defaultValue={currFinancialYear}
            className="general-ant-field-select ml-2"
            style={{
              width: "8rem",
              marginLeft: "1rem",
              background: "none",
              padding: 0
            }}
            size="large"
            onChange={e => {
              setCurrFinancialYear(e);
            }}
            bordered={false}
          >
            {financialYearList.map((val, index) =>
              <Option key={`opt-${index}`} value={val}>
                <h3>{val}</h3>
              </Option>
            )}
          </Select>
        </div>
      </div>
      <div className="declaration-page-main-tabs-container" style={{ marginTop: "-2rem" }}>
        <Tabs
          defaultActiveKey="1"
        >
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name">Deduction</span>
              </div>
            }
            key="1"
          >
            <div className="personal-info-container">
              <Deduction user={user} orgInfo={orgInfo} financialYear={currFinancialYear} />
            </div>
          </TabPane>
          <TabPane
            tab={
              <div className="tabs">
                <span className="tab-name">Allowance</span>
              </div>
            }
            key="2"
          >
            <div className="personal-info-container">
              <Allowance user={user} orgInfo={orgInfo} financialYear={currFinancialYear} />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const DeclarationContainer = connect((state) => ({
  deductions: state.employee.deductions,
}))(Declaration);

export default DeclarationContainer;
