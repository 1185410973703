import { notification, Table } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { payoutBeneficiary, updateTestPayoutApis } from "../../apis/organisation";
import { HeaderComponent } from "../Common";
import { NormalButton } from "../ComponentLibrary";
import Spinner from "../ComponentLibrary/Spinner/Spinner";
import TrialPage from "../Common/TrialComponent";
import testPayoutsBgImage from "../../assets/dummyPages/testPayoutsBgImage.png"

const PayoutVerificationFlow = ({ user, payout, isTrial }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSelectChange = rows => {
    setSelectedRowKeys([...rows]);
  };

  let getAllBeneficiary = async () => {
    setIsLoading(true);
    let res = await payoutBeneficiary(user.organisationId);
    if (res.status === 200 && res.data.success) {
      setBeneficiaryList(res.data.data.beneficiaries);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllBeneficiary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payout]);

  const onSave = async () => {
    const selectedRows = beneficiaryList.filter(item =>
      selectedRowKeys.includes(item.id)
    );
    if (!selectedRows.length) {
      notification.error({
        message: "Please select at least one employee."
      });
      return;
    }
    let value = 0;
    setIsLoading(true)
    for (let row of selectedRows) {
      const response = await updateTestPayoutApis({ organisationId: row.organisationId, id: row.id });
      if (response.data.success) {
        value += 1
      }
    }
    notification.info({
      message: ` Number of successful Tests carried out is/are : ${value} and number of failed Tests is/are : ${selectedRows.length - value}`
    });
    setIsLoading(false)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE
    ]
  };

  return (
    isTrial ? <TrialPage user={user} trialMessage={"No need to depend on banks. Setup payouts & disburse salaries directly to employees bank account without any errors"} bgImage={testPayoutsBgImage}
    /> :
      <div className="site-layout">
        <HeaderComponent name="Test Payouts" />
        <div className="app-main-content-container reimbursement-container">
          <Spinner spinning={isLoading} size="large">
            <div className="paper-header-container">
              <div className="app-main-content-container ">
                <h1 style={{ marginBottom: "1.5em", marginLeft: "-1em" }} className="font-size-global-24 font-weight-global-700">Beneficiaries ( {beneficiaryList ? beneficiaryList.length : 0} )</h1>
              </div>
            </div>
            <div>
              <Table
                pagination={false}
                scroll={{ y: 500 }}
                rowKey={"id"}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={beneficiaryList}
              />
            </div>
            <div
              style={{ textAlign: "right", marginTop: "2em" }}
            >
              {beneficiaryList.length !== 0 ? <NormalButton type={13} buttonText="Run Test" onClick={onSave} /> : ""}

            </div>
          </Spinner>
        </div>
      </div>
  );
};

const PayoutVerificationFlowContainer = connect(state => ({
  user: state.auth.user,
  employeeList: state.organisation.employeeList,
  isTrial: state.auth.user.isTrial,
  payout: state.organisation.payout,
}))(PayoutVerificationFlow);

const columns = [
  {
    title: "Username",
    dataIndex: ["user", "username"],
    ellipsis: true,
    width: "20%",
  },
  {
    title: "Name",
    dataIndex: "name",
    ellipsis: true,
    width: "20%",
  },
  {
    title: "Email",
    dataIndex: "email",
    ellipsis: true,
    width: "20%",
  },
  {
    title: "Account Number",
    dataIndex: "bankAccount",
    ellipsis: true,
  },
  {
    title: "IFSC",
    dataIndex: "IFSC",
    ellipsis: true,
  },
  {
    title: "Address",
    dataIndex: "address1",
    ellipsis: true,
  },
  {
    title: "City",
    dataIndex: "city",
    ellipsis: true,
  },
  {
    title: "Pin Code",
    dataIndex: "pincode",
    ellipsis: true,
  },
];
export default PayoutVerificationFlowContainer;
