import React, { useEffect, useState } from 'react'
import { HeaderComponent } from '../../Common'
import InputFieldComponent from '../../InputLibrary/InputFieldComponent/InputFieldComponent';
import { NormalButton } from '../../ComponentLibrary';
import { fetchDefinedDetails, saveDefinedDetails } from '../../../apis/organisation';
import { notification } from 'antd';
import { connect } from 'react-redux';

const OrgDetails = ({ user }) => {
	const [input, setInput] = useState({
		contractorName: '',
		contractorAddress: '',
		establishmentName: '',
		establishmentAddress: '',
		natureOfWork: '',
		locationOfWork: '',
		principalEmployerName: '',
		principalEmployerAddress: ''
	});

	useEffect(() => {
		fetchDefinedDetails(user.organisationId).then(res => {
			if (res.data.success) {
				setInput(res.data.data.definedOrganisationInfo)
			}
		})
	}, [user.organisationId]);

	const handleSave = () => {
		saveDefinedDetails({
			...input,
			organisationId: user.organisationId
		}).then(res => {
			if (res.data.success) {
				notification.success({
					message: "Successfully! updated data!"
				});
			}
		}).catch(err => {
			notification.error({
				message: "Error while updating data!"
			});
		})
	}
	const handleReset = () => {
		setInput({
			contractorName: '',
			contractorAddress: '',
			establishmentName: '',
			establishmentAddress: '',
			natureOfWork: '',
			locationOfWork: '',
			principalEmployerName: '',
			principalEmployerAddress: ''
		});
	}
	return (
		<div className="site-layout">
			<HeaderComponent show={false} name="Organization Configuration" />
			<div className="app-main-content-container paper-wrapper">
				<div className='flex justify-between align-center'>
					<div>
						<h1 className="h1-bold-24">Organization Configuration</h1>
						<p className="tab-subtitle-1">
							Configure which heads will have which value.
						</p>
					</div>
				</div>
				<div className='m-top-5'>
					<div className='flex' style={{ gap: "3rem" }}>
						<InputFieldComponent
							value={input?.contractorName ?? ''}
							labeltext={"Contractor Name"}
							placeholder="Contractor Name"
							className="general-input-field"
							bordered={false}
							onChange={(e) => {
								setInput({
									...input,
									contractorName: e.target.value,
								});
							}}
							size={"large"}
						/>
						<InputFieldComponent
							value={input?.contractorAddress ?? ''}
							labeltext={"Contractor Address"}
							placeholder="Contractor Address"
							className="general-input-field"
							bordered={false}
							onChange={(e) => {
								setInput({
									...input,
									contractorAddress: e.target.value,
								});
							}}
							size={"large"}
						/>
					</div>
					<div className='flex' style={{ gap: "3rem" }}>
						<InputFieldComponent
							value={input?.establishmentName ?? ''}
							labeltext={"Establishment Name"}
							placeholder="Establishment Name"
							className="general-input-field"
							bordered={false}
							onChange={(e) => {
								setInput({
									...input,
									establishmentName: e.target.value,
								});
							}}
							size={"large"}
						/>
						<InputFieldComponent
							value={input?.establishmentAddress ?? ''}
							labeltext={"Establishment Address"}
							placeholder="Establishment Address"
							className="general-input-field"
							bordered={false}
							onChange={(e) => {
								setInput({
									...input,
									establishmentAddress: e.target.value,
								});
							}}
							size={"large"}
						/>
					</div>
					<div className='flex' style={{ gap: "3rem" }}>
						<InputFieldComponent
							value={input?.principalEmployerName ?? ''}
							labeltext={"Principal Employer Name"}
							placeholder="Principal Employer Name"
							className="general-input-field"
							bordered={false}
							onChange={(e) => {
								setInput({
									...input,
									principalEmployerName: e.target.value,
								});
							}}
							size={"large"}
						/>
						<InputFieldComponent
							value={input?.principalEmployerAddress ?? ''}
							labeltext={"Principal Employer Address"}
							placeholder="Principal Employer Address"
							className="general-input-field"
							bordered={false}
							onChange={(e) => {
								setInput({
									...input,
									principalEmployerAddress: e.target.value,
								});
							}}
							size={"large"}
						/>
					</div>
					<div className='flex' style={{ gap: "3rem" }}>
						<InputFieldComponent
							value={input?.natureOfWork ?? ''}
							labeltext={"Nature of work"}
							placeholder="Nature of work"
							className="general-input-field"
							bordered={false}
							onChange={(e) => {
								setInput({
									...input,
									natureOfWork: e.target.value,
								});
							}}
							size={"large"}
						/>
						<InputFieldComponent
							value={input?.locationOfWork ?? ''}
							labeltext={"Location of work"}
							placeholder="Location of work"
							className="general-input-field"
							bordered={false}
							onChange={(e) => {
								setInput({
									...input,
									locationOfWork: e.target.value,
								});
							}}
							size={"large"}
						/>
					</div>
					<div>
						<NormalButton onClick={handleReset} type={5} buttonText="Reset" />
						<NormalButton onClick={handleSave} type={13} buttonText="Save" />
					</div>
				</div>
			</div>
		</div>
	)
}


const OrgDetailsContainer = connect((state) => ({
	user: state.auth.user,

}))(OrgDetails);

export default OrgDetailsContainer;