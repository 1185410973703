import { Tabs } from "antd";
import { HeaderComponent } from "../../Common/index.js";
import EmployeePerqsProofs from "./EmployeePerqsProofs.js";
import NonTaxable from "./NonTaxablePerqs.js";
import { useEffect } from "react";

const { TabPane } = Tabs;

const listOfAllowanceTabs = [
  {
    key: "1",
    tabName: "Non Taxable Perqs",
    component: NonTaxable
  },
  {
    key: "2",
    tabName: "Upload Proofs",
    component: EmployeePerqsProofs
  }
];

const Perqs = () => {
  useEffect(() => {
    document.title = 'Declarations | Non Taxable Perqs';
  });
  return (
    <section className="site-layout">
      <HeaderComponent />
      <div className="app-main-content-container paper-wrapper">
        <div className="blue-tabs-deduction-container">
          <Tabs tabPosition="left">
            {listOfAllowanceTabs.map(data => {
              return (
                <TabPane
                  tab={
                    <span className="left-side-tabs">
                      {data.tabName}
                    </span>
                  }
                  key={data.key}
                >
                  <data.component />
                </TabPane>
              );
            })}
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default Perqs;
