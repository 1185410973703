import { connect } from "react-redux";
import organisation from "../../../redux/modules/organisation";
import companyCardBackground from "../../../assets/employeeProfile/bg-company-card.png";

const CompanyData = ({ orgUniqueCode, caUser }) => {
  return (
    Object.keys(caUser).length === 0 && (
      <div className="company-data-card">
        <img src={companyCardBackground} alt="" className="company-card-bg" />
        <div className="company-card-info">
          <h3 className="">Company code</h3>
        </div>
        <p className="company-data-code">{orgUniqueCode.verificationNumber}</p>
        <p className="company-data-card-info">
          Use this code to link an External CA's Mool account to your
          organization account
        </p>
      </div>
    )
  );
};

const CompanyDataContainer = connect(
  (state) => ({
    user: state.auth.user,
    orgUniqueCode: state.organisation.orgUniqueCode,
    caUser: state.organisation.caUser,
  }),
  (dispatch) => ({
    organisation: organisation.getActions(dispatch),
  })
)(CompanyData);

export default CompanyDataContainer;
