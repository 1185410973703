import { Tabs, Breadcrumb } from 'antd';
import { HeaderComponent } from '../../Common';
import DocumentActionContainer from './extras/DocumentAction';
import SectionData from './extras/sectionData';
import { Link } from 'react-router-dom';
import { deducationTabsLists } from '../../../utils/config';
import { paths } from '../../../constants/routes';

const { TabPane } = Tabs;

const Deduction = (props) => {
    const {userObject} = props.location.state

    return (
        <div className="site-layout">
            <HeaderComponent show={false} showMoolCoins={true}/>
            <div className="app-breadcrum-container">
                <Breadcrumb className="app-breadcrum" >
                    <Breadcrumb.Item>
                        <Link to={paths.org.employee}>Employee Management</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/org/employee_details/${userObject._id}`}>{userObject.displayName}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="app-breadcrum-active">Deduction Documents</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="manage-declaration-container">
                <Tabs tabPosition='left' style={{ height: '100%' }} >
                    {
                        deducationTabsLists.map((data, index) => {
                            return (
                                <TabPane key={`deduction-tab-pane-${index}`}
                                    tab={
                                        <SectionData
                                            sectionName={data.sectionName}
                                            sectionType={data.sectionType}
                                            sectionInfo={data.sectionInfo}
                                        />
                                    }
                                >
                                    <DocumentActionContainer
                                        Category={data.Category}
                                        sectionName={data.sectionName}
                                        sectionType={data.sectionType}
                                    />
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        </div >
    )
}


export default Deduction;