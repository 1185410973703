import { HeaderComponent } from "../../Common";
import { connect } from "react-redux";
import employee from "../../../redux/modules/employee";
import auth from "../../../redux/modules/auth";
import { Row, Col } from "antd";
import Attendance from "./component/Attendance";
import AttendanceReport from "./component/AttendanceReport";
import { withRouter } from "react-router-dom";

const EmployeeDashboard = ({
  user,
  employeeReducer,
  group,
  orgInfo,
  leaveBalanceReport,
  holidays,
  attendances,
  leaveLogs,
  monthlyReport,
  todayAttendance,
  weeklyOffs,
  authReducer,
  history
}) => {

  return (
    <div className="site-layout">
      <HeaderComponent
        name={"Dashboard"}
        showMoolCoins={true}
      />
      <div className="app-main-content-container">
        <Row gutter={[40, 20]}>
          <Col xs={24} sm={24} md={6} lg={7}>
            <Attendance
              leaveBalanceReport={leaveBalanceReport}
              todayAttendance={todayAttendance}
              user={user}
              employeeReducer={employeeReducer}
              authReducer={authReducer}
              history={history}
            />
          </Col>
          <Col xs={24} sm={24} md={18} lg={17}>
            <AttendanceReport
              holidays={holidays}
              attendances={attendances}
              monthlyReport={monthlyReport}
              group={group}
              leaveBalanceReport={leaveBalanceReport}
              leaveLogs={leaveLogs}
              user={user}
              orgInfo={orgInfo}
              employeeReducer={employeeReducer}
              weeklyOffs={weeklyOffs}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const EmployeeDashboardContainer = connect(
  (state) => ({
    user: state.auth.user,
    group: state.employee.group,
    orgInfo: state.employee.orgInfo,
    leaveList: state.employee.leaveList,
    leaveBalanceReport: state.employee.leaveBalanceReport,
    holidays: state.employee.holidays,
    attendances: state.employee.attendances,
    leaveLogs: state.employee.leaveLogs,
    monthlyReport: state.employee.monthlyReport,
    todayAttendance: state.employee.todayAttendance,
    weeklyOffs: state.employee.weeklyOffs,
  }),
  (dispatch) => ({
    employeeReducer: employee.getActions(dispatch),
    authReducer: auth.getActions(dispatch)
  })
)(EmployeeDashboard);

export default withRouter(EmployeeDashboardContainer);
