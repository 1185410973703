//
/* eslint-disable jsx-a11y/anchor-is-valid */
import { connect } from "react-redux";
import {
  Spin,
  message,
  Upload,
  notification,
  Table,
  Modal,
  Button
} from "antd";
import { uploadPayslips } from "../../../apis/organisation";
import { Content } from "antd/lib/layout/layout";
import config, { numformatter } from "../../../utils/config";
import uploadLogo from "../../../assets/icons/savefile.svg";
import { useState } from "react";
import organisation from "../../../redux/modules/organisation";
import axios from "../../../apis";

const { Dragger } = Upload;

const OverridePayslip = ({
  user,
  financialYear,
  month,
  modal,
  setModal,
  getPayslipFreeze
}) => {
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [payslipData, setPayslipData] = useState({
    employeeData: []
  });

  const heading = ["Upload the Document", "Verify and Update"];

  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/validate/payslip`,
    headers: {
      authorization: localStorage.getItem("mool_auth")
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        setPageState(1);
        setPayslipData({ ...payslipData, employeeData: response });
      }
    }
  };

  const resetModal = () => {
    setPayslipData({
      financialYear: "",
      month: 0,
      employeeData: []
    });
    setPageState(0);
    setModal(false);
  };

  const uploadPayslip = async () => {
    if (payslipData.employeeData.length === 0) {
      message.error("Please Upload CSV File");
      return;
    }
    setIsLoading(true);
    let data = await uploadPayslips({ ...payslipData, financialYear, month });
    if (data.data.success) {
      await getPayslipFreeze();
      notification.success({ message: "Successfully Uploaded the payslip" });
    } else {
      notification.error({ message: "Unable to upload payslip" });
    }
    setIsLoading(false);
    resetModal();
  };

  const handleAction = async (url, name) => {
    let res = await axios({
      url, //your url
      method: "GET",
      responseType: "blob"
    });
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = urlDownload;
    link.setAttribute("download", name); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Modal
      centered
      footer={null}
      width={1200}
      onCancel={resetModal}
      style={{ padding: 20 }}
      visible={modal}
    >
      <div className="flex align-center">
        <h3 className="app-heading-3">
          {heading[pageState]}
        </h3>
      </div>

      {pageState === 0 &&
        <UploadDocument
          platforms={{ ...uploadProps }}
          user={user}
          isLoading={isLoading}
          handleAction={handleAction}
          financialYear={financialYear}
          month={month}
        />}
      {pageState === 1 &&
        <VerifyAndUpload
          uploadPayslip={uploadPayslip}
          setPayslipData={setPayslipData}
          payslipData={payslipData}
          reset={resetModal}
          isLoading={isLoading}
        />}
    </Modal>
  );
};

const UploadDocument = ({
  platforms,
  user,
  isLoading,
  handleAction,
  financialYear,
  month
}) => {
  return (
    <Content className="bulk-wrapper-left">
      <div className="paper-header-container">
        <div>
          <p className="paper-title">
            Download Sample in{" "}
            <a
              className="download-employee-list"
              onClick={() =>
                handleAction(
                  `${config.rootUrl}/api/organisation/payslip/existing/${user.organisationId}/${month}/${financialYear}`,
                  "PayslipStructure.csv"
                )}
            >
              .csv format
            </a>{" "}
            file to ensure that you have the correct file ready to import.
          </p>
        </div>
      </div>
      <Spin spinning={isLoading} size="large">
        <div>
          <div>
            <Dragger
              {...platforms}
              showUploadList={false}
              height={300}
              className="bulk-wrapper-dragger"
            >
              <p className="ant-upload-drag-icon">
                <img alt="" src={uploadLogo} height="80" width="80" />
              </p>

              <p className="ant-upload-text">Drag and drop CSV file</p>
              <p className="ant-upload-hint">
                or <u>Click Here</u> to upload
              </p>
            </Dragger>
          </div>
        </div>
      </Spin>
    </Content>
  );
};

const VerifyAndUpload = ({ payslipData, isLoading, reset, uploadPayslip }) => {
  return (
    <Spin size="large" spinning={isLoading}>
      <div className="flex justify-end align-center">
        <Button
          onClick={uploadPayslip}
          className="submit-button"
          style={{
            marginRight: "10px",
            padding: "0px 24px"
          }}
        >
          Override The Payslips
        </Button>
        <Button
          onClick={reset}
          className="cancel-button"
          style={{
            border: "none",
            boxShadow: "none",
            marginRight: "0px"
          }}
        >
          Cancel
        </Button>
      </div>

      <Table
        scroll={{ x: 600 }}
        columns={tableData}
        dataSource={payslipData.employeeData}
      />
    </Spin>
  );
};

const OverridePayslipContainer = connect(
  state => ({
    user: state.auth.user,
    onboarding: state.organisation.onboarding
  }),
  dispatch => ({
    organisationReducer: organisation.getActions(dispatch)
  })
)(OverridePayslip);

const tableData = [
  {
    title: "Employee ID",
    dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
    key: "Employee_ID",
    width: "15%"
  },
  {
    title: "Name",
    dataIndex: ["user", "displayName"],
    key: "Name",
    width: "15%"
  },
  {
    title: "IT Regime",
    dataIndex: ["user", "regimeOptedStatus"],
    key: "takeHome",
    width: "15%",
    render: text =>
      <p>
        {text === 1 ? "New Regime" : "Old Regime"}
      </p>
  },
  {
    title: "Total Earning",
    dataIndex: ["finalPayslip", "totalFixedIncome"],
    key: "takeHome",
    width: "15%",
    render: data =>
      !data
        ? <p>N/A</p>
        : <p>
            {numformatter(data)}
          </p>
  },
  {
    title: "Income Tax",
    dataIndex: ["user", "regimeOptedStatus"],
    key: "takeHome",
    width: "15%",
    render: (text, row) =>
      <p>
        {text === 1
          ? numformatter(
              row.finalPayslip.newRegime.incomeTax +
                (isNaN(parseInt(row.finalPayslip.advanceTax))
                  ? 0
                  : row.finalPayslip.advanceTax)
            )
          : numformatter(
              row.finalPayslip.oldRegime.incomeTax +
                (isNaN(parseInt(row.finalPayslip.advanceTax))
                  ? 0
                  : row.finalPayslip.advanceTax)
            )}
      </p>
  },
  {
    title: "Total Deduction",
    dataIndex: ["user", "regimeOptedStatus"],
    key: "takeHome",
    width: "15%",
    render: (text, row) =>
      <p>
        {text === 1
          ? numformatter(row.finalPayslip.newRegime.deductions)
          : numformatter(row.finalPayslip.oldRegime.deductions)}
      </p>
  },
  {
    title: "Take Home Amount",
    dataIndex: ["user", "regimeOptedStatus"],
    key: "takeHome",
    width: "15%",
    render: (text, row) =>
      <p>
        {text === 1
          ? numformatter(row.finalPayslip.newRegime.takeHome)
          : numformatter(row.finalPayslip.oldRegime.takeHome)}
      </p>
  }
];

export default OverridePayslipContainer;
