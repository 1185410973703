import React from "react";
import { useState } from "react";
import { Input } from "antd";
import "./InputFieldComponent.less";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

function InputFieldComponent({
  setSubmitClicked,
  submitClicked,
  maxWidth,
  required,
  disabled,
  value,
  onChange,
  width,
  height,
  labeltext,
  display,
  type,
  margin,
  min,
  max,
  name,
  labelColor,
  defaultValue,
  description
}) {
  const [visible, setvisible] = useState(false);
  let styles = {
    width: width ? `${width}` : "288px",
    maxWidth: maxWidth ? `${maxWidth}` : "100%",
    height: height ? `${height}` : "50px",
    borderRadius: "8px"
  };
  let stylediv = {
    position: "relative",
    display: display ? display : "block"
  };
  let parentdivStyle = {
    margin: margin
      ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`
      : "10px 0px"
  };
  return (
    <div style={parentdivStyle}>
      <div className="inputdiv" style={stylediv}>
        <Input
          className={submitClicked && required && value === "" && "errorState"}
          type={type !== "password" ? type : visible ? "text" : "password"}
          required={required}
          disabled={disabled}
          onFocus={() => {
            setSubmitClicked(false);
          }}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          min={min}
          max={max}
          placeholder=" "
          style={styles}
          name={name}
        />
        <label
          style={{ backgroundColor: labelColor ?? "white" }}
          className={`labelnormal ${submitClicked && required && value === "" && "errorState"}`}
          id={labelColor}
        >
          {labeltext ? labeltext : "Your Label"}
        </label>
        {type === "password" ? (
          <span
            style={{ position: "relative", left: "-2rem", zIndex: "2" }}
            onClick={() => {
              setvisible(!visible);
            }}
          >
            {visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
          </span>
        ) : (
          <></>
        )}
      </div>
      <p className="font-size-12 m-left-4 m-top-4">
        {description}
      </p>
      <p
        className={
          submitClicked && required && value === ""
            ? "activeerrormessage"
            : "errormessage"
        }
      >
        Required Field
      </p>
    </div>
  );
}

export default InputFieldComponent;
