import axios from './index';
import { checkFinancialYear } from "../utils/config";
const { financialYear } = checkFinancialYear()

// get and post tickets data
export async function getTickets(id) {
    return axios.get(`/fetch/concessionalTicket/${id}/${financialYear}`);
}

export async function postTickets(data) {
    return axios.post(`/save/concessionalTicket`, data);
}

// get and post Gas, Water & Electricity data
export async function getGasWaterElectricity(id) {
    return axios.get(`/fetch/gasElectricityWater/${id}/${financialYear}`);
}

export async function postGasWaterElectricity(data) {
    return axios.post(`/save/gasElectricityWater`, data);
}
// get and post Gas, Water & Electricity data
export async function getClubExpenditure(id) {
    return axios.get(`/fetch/clubExpenditure/${id}/${financialYear}`);
}

export async function postClubExpenditure(data) {
    return axios.post(`/save/clubExpenditure`, data);
}

// get and post interest free concessional loan data
export async function getInterestFreeConcessionalLoan(id) {
    return axios.get(`/fetch/interestFreeConcessionalLoan/${id}/${financialYear}`);
}

export async function postInterestFreeConcessionalLoan(data) {
    return axios.post(`/save/interestFreeConcessionalLoan`, data);
}

// get and post Free and concessional food and non alcoholic beverages data
export async function getFreeConcessionalFood(id) {
    return axios.get(`/fetch/freeConcessionalFoodAndBeverages/${id}/${financialYear}`);
}

export async function postFreeConcessionalFood(data) {
    return axios.post(`/save/freeConcessionalFoodAndBeverages`, data);
}

// get and post Gifts vouchers and tokens in lieu of such gifts
export async function getGiftAndTokenInLieu(id) {
    return axios.get(`/fetch/giftAndTokenInLieu/${id}/${financialYear}`);
}

export async function postGiftAndTokenInLieu(data) {
    return axios.post(`/save/giftAndTokenInLieu`, data);
}

// get and post Credit Card Expense
export async function getCreditCardExpenses(id) {
    return axios.get(`/fetch/creditCardExpenses/${id}/${financialYear}`);
}

export async function postCreditCardExpenses(data) {
    return axios.post(`/save/creditCardExpenses`, data);
}

// get and post Other Benefits
export async function getOtherBenefitsOrAmenity(id) {
    return axios.get(`/fetch/otherBenefitsOrAmenity/${id}/${financialYear}`);
}

export async function postOtherBenefitsOrAmenity(data) {
    return axios.post(`/save/otherBenefitsOrAmenity`, data);
}

export async function getConcessionalEducationFacilities(id) {
    return axios.get(`fetch/concessionalEducation/${id}/${financialYear}`);
}

export async function postConcessionalEducationFacilities(data) {
    return axios.post(`save/concessionalEducation`, data);
}

export async function getEquitySharesApis(id) {
    return axios.get(`/fetch/specifiedSecurityNotBeingEquityShare/${id}/${financialYear}`);
}

export async function postEquitySharesApis(data) {
    return axios.post(`/save/specifiedSecurityNotBeingEquityShare`, data);
}
export async function getMovableAssets(id) {
    return axios.get(`fetch/movableAssets/${id}/${financialYear}`);
}

export async function postMovableAssets(data) {
    return axios.post(`save/movableAssets`, data);
}

export async function getTransferOfMovableAsset(id) {
    return axios.get(`fetch/transferOfMovableAssets/${id}/${financialYear}`);
}

export async function postTransferOfMovableAsset(data) {
    return axios.post(`save/transferOfMovableAssets`, data);
}



//residential accommodation

export async function getResidentialAccommodation(id) {
    return axios.get(`/fetch/residentialAccommodation/${id}/${financialYear}`);
}

export async function postResidentialAccommodation(data) {
    return axios.post('/save/residentialAccommodation', data)
}
//motor car 

export async function getMotorCar(id) {
    return axios.get(`fetch/motorCars/${id}/${financialYear}`)
}

export async function postMotorCar(data) {
    return axios.post('save/motorCars', data)
}
export async function getDomesticServantsApis(id) {
    return axios.get(`/fetch/domesticServant/${id}/${financialYear}`)
}

export async function postDomesticServantsApis(data) {
    return axios.post(`/save/domesticServant`, data)
}

export async function saveSpecifiedEmployee(data) {
    return axios.post(`/specified/employee`, data)
}

export async function getAllSpecifiedEmployees(organisationID) {
    return axios.get(`/specified/employees/${organisationID}`)
}

