import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";

const TermsAndCondition = ({ isModalVisible, setIsModalVisible }) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Modal
        className="terms-and-condition-modal"
        centered
        closable={false}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <div className="terms-and-condition-modal-header-container">
          <h1 className="terms-and-condition-modal-header">
            Terms and Conditions
          </h1>
          <Button onClick={handleCancel}>Close</Button>
        </div>
        <div style={{background:"#f5f6ff", maxHeight: 600, padding: 20, overflow: 'auto'}}>
          <div className="terms-and-condition-modal-body">
            <p className="intro-p">
              This agreement is made by Mool Innovation Labs Pvt.Ltd., having a
              principal place of business at A-202, Gunina, Plot No.2 & 3,
              Sector 16A, Sanpada, Navi Mumbai, Thane, Maharashtra - 400705,
              (The “Company”).
            </p>
            <p className="intro-p">
              Please read these Terms and Conditions (“Terms”, “Terms and
              Conditions”) carefully before using the{" "}
              <a href="https://moolfinance.com">https://moolfinance.com</a>{" "}
              website (the “Service”) operated by Mool Innovation Labs Pvt. Ltd.
            </p>
            <p className="intro-p">
              Your access to and use of the Service is conditioned to you
              acceptance if and compliance with these Terms. There Terms apply
              to all visitors, users and others who access or use the Service.
            </p>
            <p className="intro-p">
              By accessing or using the Service you agree to be bound by these
              Terms. If you disagree with any part of the Terms then you may not
              access the Service.
            </p>

            <ol className="terms-and-conditions-list">
              <li className="li-items">
                <span className="list-heading">Background - </span>
                <p className="list-p">
                  The Company and Customer intend to engage in discussions and
                  negotiations concerning the establishment of a business
                  relationship between them. In the course of such discussions
                  and negotiations it is anticipated that either party may
                  disclose or deliver to the other party certain confidential
                  information as defined in section 2 hereof for the limited
                  purpose of enabling the other party to evaluate the
                  feasibility of such a business relationship. The parties have
                  entered into this agreement in order to assure the
                  confidentiality of such confidential information in accordance
                  with the terms of this agreement.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">
                  Definition of Confidential Information -{" "}
                </span>
                <p className="list-p">
                  “Confidential Information “as used in this agreement shall
                  mean any and all technical and non-technical information
                  including but not limited to Employee data, patent, copyright,
                  trade,secret and proprietary
                  information,techniques,sketches,drawings,modeIs,inventions,know-
                  how, processes, apparatus, equipment, aLgorithms, software,
                  software programs, software source documents, Application
                  program interface (API), and formulae related to the current,
                  future and proposed products and services of each of the
                  parties and includes without limitation their respective
                  information concerning research, experimental work,
                  development, design details and specifications, engineering,
                  financial information, procurement requirements, purchasing,
                  manufacturing, customer lists ,business forecasts, sales and
                  merchandising, marketing plans and information ,and
                  documentation.” Confidential information” shall also include
                  all such information which appears to be confidential by its
                  nature in normal course and all proprietary or confidential
                  information of any third party that may disclose such
                  information to either party in the course of the other party’s
                  business. Any such information disclosed by the disclosing
                  party (“Discloser”) shall be considered Confidential
                  Information by the receiving party (“Recipient”), whether
                  communicated orally, in writing or otherwise ,and which is
                  designated as confidential or which nature would reasonably be
                  considered confidential .
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">
                  Nondisclosure and Nonuse Obligations -{" "}
                </span>
                <p className="list-p">
                  Each party agrees that it shall not make use of, disseminate
                  in any way disclose any confidential information of the party
                  to any reason, firm, or business, except to the extent
                  necessary for negotiations, discussions, and consultations
                  with personnel or authorized representatives of the other
                  party, and any purpose the other party may hereafter authorize
                  in writing. Furthermore, the existence of any business
                  negotiations, discussions, consultations, or agreements in
                  progress between the parties shall not be released in any form
                  of public media without the written approval of disclosing/
                  other Party. Each party agrees that it shall treat all
                  Confidential Information of the other party with the same
                  degree of care as it accords to its own Confidential
                  Information, and each party represents that it exercises
                  reasonable care to protect its own Confidential information.
                  If either party is not an individual, such party agrees that
                  it shall disclose confidential Information of the other party
                  only to those of its employees who needs to know such
                  information and certifies that such employees have agreed,
                  either as a condition to employment or in order to obtain the
                  Confidential Information to be bound by terms and conditions
                  substantially similar to those of this agreement. Recipients
                  shall immediately give notice to disclosure of any
                  unauthorized use or disclosure of the Confidential
                  Information. Recipient agrees to assist Disclosure in
                  remedying any such unauthorized use or disclosure of the
                  Confidential Information.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">
                  Exclusions from Nondisclosure and Nonuse Obligations -{" "}
                </span>
                <p className="list-p">
                  Each party’s obligations under section 3, with respect to any
                  portion of the other party’s Confidential Information ,shall
                  terminate when the party seeking to avoid its obligation under
                  such section can document that(i)It was in the public domain
                  at or subsequent to the time it was communicated to recipient
                  by disclosure through no fault of recipient ,(ii) It was
                  rightfully in recipient’s possession ,free of any obligation
                  of confidence at or subsequent to the time it was communicated
                  to recipient by disclosure ,(iii) It was developed by
                  employees or agents of recipient ,independency of and without
                  reference to any information communicated to recipient by
                  disclosure (iv) It was communicated to an unaffiliated third
                  party free of any obligation of confidence or (v) The
                  communication was in response to a valid order by a court or
                  other governmental body ,was otherwise required by law, or was
                  necessary to establish the rights of either party under this
                  agreement.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">
                  Ownership of Confidential Information and other materials -{" "}
                </span>
                <p className="list-p">
                  All confidential information and any derivatives thereof,
                  whether created by disclosure or recipient, shall remain the
                  property of disclosure and no license or other right to
                  Confidential Information is granted or implied hereby. For
                  purpose of this agreement, “Derivatives “shall mean (i) For
                  copyrightable or copyrighted material, any translation,
                  abridgement, revision, or other form in which an existing work
                  may be recast, transformed, or adapted (ii) For patentable or
                  patented material and any improvement thereon ,and (iii) For
                  material which is protected by trade secret ,any new material
                  derived from such existing trade secret materials ,including
                  new material which may be protected by copyright patent, and
                  /or trade secret . AII materials including without limitation,
                  documents, models, drawings, apparatus, sketches, designs and
                  lists furnished to one party by the other and which are
                  designated in writing to be the property of such party, shall
                  remain the property of such party and shall be returned to it
                  promptly at its request, together with any copies thereof.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">Independent Development - </span>
                <p className="list-p">
                  Disclosure understands that recipient may currently or in the
                  future be developing information internally or receiving
                  information from other parties that may be similar to
                  Discloser’s information .Accordingly ,nothing in this
                  agreement shall be constructed as representation or
                  interference that recipient will not develop products ,or have
                  products developed for it, that would violation of this
                  agreement ,compete with the products or systems contemplated
                  by discloser’s Confidential Information.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">
                  Disclosure of Third Party Information -{" "}
                </span>
                <p className="list-p">
                  Neither party shall communicate any information to the other
                  in violation of the proprietary rights of any third party.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">No Warranty - </span>
                <p className="list-p">
                  All Confidential Information is provided by “AS IS" and
                  without any warranty, express, implied, or otherwise,
                  regarding its accuracy or performance.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">Term - </span>
                <p className="list-p">
                  This agreement shall govern all communications between the
                  parties that are made during the period from the effective
                  date of this agreement to the date on which either party
                  receives from the other written notice that subsequent
                  communications shall not be so governed ,provided, however,
                  that each party’s obligations under Section 2 with respect to
                  Confidential Information of the other party, which it has
                  previously received ,shall continue in per equity unless
                  terminated pursuant to Section 3.
                </p>
                <p className="list-p">
                  The obligations under this Agreement shall survive for 3 years
                  from the date of termination of this Agreement or definitive
                  Agreement if entered thereto or from the date of the last
                  information shared by either Party, whichever is later.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">No Assignment - </span>
                <p className="list-p">
                  Neither Company nor Customer shall assign or transfer any
                  rights or obligations under this agreement without the prior
                  written consent of company or client.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">Non-Hiring Agreement - </span>
                <p className="list-p">
                  Neither Company nor Customer shall offer or seek to offer
                  directly or indirectly to the employees of the other party
                  during the course of relationship and for a period of two year
                  after the termination of this agreement, unless such
                  employment or offer results solely from the employee'
                  unsolicited response to a general public advertisement.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">Notices - </span>
                <p className="list-p">
                  Any notice required or permitted by this agreement shall be
                  delivered as follows, with notice deemed given as indicated:
                  (i) By personal delivery when received personally (ii)By
                  overnight courier upon written verification of receipt (iii)
                  By telecopy or facsimile transmission upon acknowledgement of
                  receipt of electronic transmission ,or (iv) By certified or
                  registered mail return receipt requested upon verification of
                  receipt. Notice shall be sent to the addresses set forth above
                  or such other address as either party may specify in writing.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">Governing Law - </span>
                <p className="list-p">
                  This agreement shall be constructed and interpreted in
                  accordance with the laws of Republic of India and the courts
                  of Bangalore, Karnataka state, India shall have jurisdiction
                  over this agreement .
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">Severability - </span>
                <p className="list-p">
                  Should any provisions of this agreement be held by a court of
                  law to be illegal, invalid or unenforceable the legacy,
                  validity and enforceability of the remaining provisions of
                  this agreement shall not be affected or impaired thereby.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">Waiver-</span>
                <p className="list-p">
                  The waiver by disclosure of a breach of any provision of this
                  agreement shall not operate or be constructed as a waiver of
                  any other or subsequent breach by the recipient.
                </p>
              </li>
              <li className="li-items">
                <span className="list-heading">Entire Agreement-</span>
                <p className="list-p">
                  This agreement constitutes the entire agreement with respect
                  to the confidential information disclosed herein and
                  supersedes all prior or contemporaneous oral or written
                  agreements concerning such confidential information . This
                  agreement may only be changed by mutual agreement of
                  authorized representatives of the parties in writing.
                </p>
              </li>
            </ol>

            <p className="last-p">
              IN WITNESS WHEREOF, the parties have executed this agreement as of
              the date first written above.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TermsAndCondition;
