import React from "react";

function Total({ data }) {
  return (
    <tr>
      <th>Total</th>
      <th>
        {data.values[3]}
      </th>
      <th>
        {data.values[4]}
      </th>
      <th>
        {data.values[5]}
      </th>
      <th>
        {data.values[6]}
      </th>
      <th>
        {data.values[7]}
      </th>
      <th>
        {data.values[8]}
      </th>
      <th>
        {data.values[9]}
      </th>
      <th>
        {data.values[10]}
      </th>
      <th>
        {data.values[11]}
      </th>
      <th>
        {data.values[0]}
      </th>
      <th>
        {data.values[1]}
      </th>
      <th>
        {data.values[2]}
      </th>
      <th>
        {data.total}
      </th>
    </tr>
  );
}

export default Total;
