import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/money.json";
import { Switch, Route, Redirect, Link, withRouter } from "react-router-dom";
import auth from "../../redux/modules/auth";
import responsive from "../../redux/modules/responsive";
import logoMini from "../../assets/common/miniMool.svg";
import logouticon from "../../assets/icons/Logout.svg";
import { Layout, Menu } from "antd";
import "./PrivatePages.less";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import employee from "../../redux/modules/employee";
import organisation from "../../redux/modules/organisation";
import { checkFinancialYear } from "../../utils/config";
import { routes, navigator, accessRoutes } from "../../constants/routes";
import accessIcon from "../../assets/icons/accessIcon.svg";
import { LOG_CONFIG } from "../../constants/logs";

const { Sider } = Layout;
function PrivateRoute({
  user,
  loginstate,
  access,
  responsiveReducer,
  employeeReducer,
  organisationReducer,
  empLoading,
  orgLoading,
  history,
  authReducer,
  currentTab,
  selectedCategory,
  categoryChildren,
  isCurrentFinancialYearJoinee
}) {
  window.addEventListener("resize", responsiveReducer.updateScreenResolution);
  const [close, setClose] = useState(true);
  const handleClick = () => {
    setClose(!close);
  };

  const [selectedRoute, setSelectedRoute] = useState(null);
  const [rootPath, setRootPath] = useState("");
  const [doesUserAccessAvailable, setDoesUserAccessAvailable] = useState(false);
  /**
   * selectedRoute: routes for the user
   * selectedCategory: Slected category for the selected route
   * categoryChildren: Children menus for the selcted category
   * rootPath: root path for the user
   * categoryPath: category path for the user.
   * user.role: role of the user.
   */

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (user.role === "employee") {
      let userAccessAvailable = Object.keys(access).some(
        (userAccess) => access[userAccess].access
      );
      setDoesUserAccessAvailable(userAccessAvailable);
    }
  }, [access, user.role]);

  useEffect(() => {
    if (user.role === "employee") {
      employeeReducer.initiateEmployee(user._id);
      if (access.payroll) {
        organisationReducer.updateEmployeeList({
          organisationId: user.organisationId,
        });
        const { financialYear } = checkFinancialYear();
        organisationReducer.freezeResult(
          `${user.organisationId}/${financialYear}`
        );
      }
    } else if (user.role === "org") {
      organisationReducer.initiateState({
        organisationId: user.organisationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access.payroll, user]);

  // Set user role
  useEffect(() => {
    const { role } = user;
    const route = navigator[role];
    const { path, defaultSelections, routes: rt } = route;
    const category = rt[defaultSelections];
    const defaultSelected = category.defaultPath;
    authReducer.updateSelectedCategory(defaultSelections);
    setSelectedRoute({ ...rt });
    setRootPath(path);
    authReducer.updateCurrentTab(defaultSelected);
  }, [authReducer, user]);

  const selectCategory = (category) => {
    authReducer.updateSelectedCategory(category);
    if (
      category === "access" &&
      user.role === "employee" &&
      doesUserAccessAvailable
    ) {
      let selectedAccess = {};
      let selected = "";
      Object.keys(access).forEach((acRoutes) => {
        if (access[acRoutes].access && acRoutes !== "leave") {
          if (!selected) {
            selected = acRoutes;
          }
          selectedAccess[acRoutes] = accessRoutes[acRoutes];
        }
      });
      authReducer.updateCurrentTab(selected);
      authReducer.updateChildren(selectedAccess);
      history.push(`/${rootPath}/access/${selected}`);
    } else {
      const cat = selectedRoute[category];
      const catPath = cat.path;
      const defaultSelected = cat.defaultPath;
      authReducer.updateCurrentTab(defaultSelected);
      history.push(`/${rootPath}/${catPath}/${defaultSelected}`);
    }
  };

  return (
    <Switch>
      {selectedRoute && (
        <Layout className="display-container">
          {(user.role === "employee" && empLoading) ||
            (user.role === "org" && orgLoading) ? (
            <div
              className="flex justify-center align-center"
              style={{ width: "100vw", height: "100vh" }}
            >
              <Lottie options={animationOptions} height={300} width={300} />
            </div>
          ) : (
            !loginstate && (
              <Sider
                theme="light"
                style={{
                  background: "#202741",
                  minWidth: "16rem!important",
                  width: "16rem!important",
                  maxWidth: "16rem!important",
                }}
              >
                <div className="navigation">
                  <div
                    className="navigation__first-menu"
                    style={{ width: close ? "50px" : "16rem" }}
                  >
                    <div className="navigation__logo">
                      
                        <img
                          style={{ width: "2rem" }}
                          src={logoMini}
                          alt="logo"
                        />
                     
                    </div>
                    <div
                      style={{
                        height: `calc(100vh - 6rem)`,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="navigation__menu">
                        <Menu
                          style={{ background: "#151b30 !important" }}
                          defaultSelectedKeys={[selectedCategory]}
                          selectedKeys={[selectedCategory]}
                        >
                          {user.role === "org" &&
                            Object.keys(selectedRoute)
                              .slice(1, 10)
                              .map((menu) => {
                                return (
                                  <>
                                    <Menu.Item
                                      key={`${menu}`}
                                      className="navigation__menu-item"
                                      onClick={() => selectCategory(menu)}
                                    >
                                      {selectedCategory === menu ? (
                                        <img
                                          className="navigation__menu-image"
                                          alt={`${selectedRoute[menu].label}`}
                                          src={selectedRoute[menu].activeIcon}
                                        />
                                      ) : (
                                        <img
                                          className="navigation__menu-image"
                                          alt={`${selectedRoute[menu].label}`}
                                          src={selectedRoute[menu].icon}
                                        />
                                      )}
                                      <span className="navigation__menu-label">
                                        {selectedRoute[menu].label}
                                      </span>
                                    </Menu.Item>
                                    {close && (
                                      <div
                                        key={`${menu}-tooltip`}
                                        className="tooltipdiv"
                                      >
                                        <span className="tooltip">
                                          {selectedRoute[menu].label}
                                        </span>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                          {user.role === "employee" &&
                            Object.keys(selectedRoute).map((menu) => {
                              return (
                                <>
                                  <Menu.Item
                                    key={`${menu}`}
                                    className="navigation__menu-item"
                                    onClick={() => selectCategory(menu)}
                                  >
                                    {selectedCategory === menu ? (
                                      <img
                                        className="navigation__menu-image"
                                        alt={`${selectedRoute[menu].label}`}
                                        src={selectedRoute[menu].activeIcon}
                                      />
                                    ) : (
                                      <img
                                        className="navigation__menu-image"
                                        alt={`${selectedRoute[menu].label}`}
                                        src={selectedRoute[menu].icon}
                                      />
                                    )}
                                    <span className="navigation__menu-label">
                                      {selectedRoute[menu].label}
                                    </span>
                                  </Menu.Item>
                                  {close && (
                                    <div
                                      key={`${menu}-tooltip`}
                                      className="tooltipdiv"
                                    >
                                      <span className="tooltip">
                                        {selectedRoute[menu].label}
                                      </span>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          {user.role === "employee" && doesUserAccessAvailable && (
                            <>
                              <Menu.Item
                                key={`access`}
                                className="navigation__menu-item"
                                onClick={() => selectCategory("access")}
                              >
                                <img
                                  className="navigation__menu-image"
                                  alt={`access`}
                                  src={accessIcon}
                                />
                                <span className="navigation__menu-label">
                                  Organisation Access
                                </span>
                              </Menu.Item>
                              {close && (
                                <div className="tooltipdiv">
                                  <span className="tooltip">
                                    Organisation_access
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                        </Menu>
                      </div>
                      <div className="navigation__menu">
                        {user.role === "org" && (
                          <Menu
                            style={{ background: "#151b30 !important" }}
                            defaultSelectedKeys={[selectedCategory]}
                            selectedKeys={[selectedCategory]}
                          >
                            {Object.keys(selectedRoute)
                              .slice(0, 1)
                              .map((menu) => {
                                return (
                                  <>
                                    <Menu.Item
                                      key={`${menu}`}
                                      className="navigation__menu-item"
                                      onClick={() => selectCategory(menu)}
                                    >
                                      <img
                                        className="navigation__menu-image"
                                        alt={`${selectedRoute[menu].label}`}
                                        src={selectedRoute[menu].icon}
                                      />
                                      <span className="navigation__menu-label">
                                        {selectedRoute[menu].label}
                                      </span>
                                    </Menu.Item>
                                    {close && (
                                      <div
                                        key={`${menu}-tooltip`}
                                        className="tooltipdiv"
                                      >
                                        <span className="tooltip">
                                          {selectedRoute[menu].label}
                                        </span>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                          </Menu>
                        )}
                        <div
                          className="navigation__colapsible"
                          onClick={handleClick}
                        >
                          <img className="" src={logouticon} alt="logo" />
                          <span className="navigation__menu-label">
                            Collapse
                          </span>
                        </div>
                        {close && (
                          <div className="tooltipdiv">
                            <span className="tooltip">Expand</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="navigation__second-menu"
                    style={{ width: close ? "calc(16rem - 50px)" : "0px" }}
                  >
                    <div className="navigation__second-menu-heading">
                      <img
                        className="navigation__menu-image"
                        alt={`${selectedCategory === "access" &&
                          "Access"}${selectedCategory !== "access" &&
                          selectedRoute[selectedCategory].label}`}
                        src={
                          selectedCategory === "access"
                            ? accessIcon
                            : selectedRoute[selectedCategory].icon
                        }
                      />
                      <span className="navigation__menu-label">
                        {selectedCategory === "access" && "Access"}
                        {selectedCategory !== "access" &&
                          selectedRoute[selectedCategory].label}
                      </span>
                    </div>
                    <div className="navigation__menu-second">
                      <Menu
                        selectedKeys={[`${currentTab}`]}
                        defaultSelectedKeys={`${currentTab}`}
                      >
                        {Object.keys(categoryChildren).map((val) => {
                          if (!categoryChildren[val]) return "";
                          if (categoryChildren[val].name === "Previous Employment") {
                            return (
                              <>
                                {isCurrentFinancialYearJoinee && (
                                  <Menu.Item
                                    key={`${val}`}
                                    onClick={() => {
                                      authReducer.updateCurrentTab(val);
                                    }}
                                  >
                                    <Link
                                      className="menu-content"
                                      to={`/${rootPath}/${categoryChildren[val].path}`}//////////////////
                                    >
                                      <span className="navigation__menu-label">
                                        {categoryChildren[val].name}
                                      </span>
                                    </Link>
                                  </Menu.Item>
                                )}
                              </>
                            )
                          }
                          return (
                            <Menu.Item
                              key={`${val}`}
                              onClick={() => {
                                authReducer.updateCurrentTab(val);
                              }}
                            >
                              <Link
                                className="menu-content"
                                to={`/${rootPath}/${categoryChildren[val].path}`}//////////////////
                              >
                                <span className="navigation__menu-label">
                                  {categoryChildren[val].name}
                                </span>
                              </Link>
                            </Menu.Item>
                          )
                        }
                        )}
                        {LOG_CONFIG[user.role][selectedCategory] && (
                          <Menu.Item
                            key={`logs`}
                            onClick={() => {
                              authReducer.updateCurrentTab("logs");
                            }}
                          >
                            <Link
                              className="menu-content"
                              to={`/${rootPath}/common/logs`}
                            >
                              <span className="navigation__menu-label">
                                Activities
                              </span>
                            </Link>
                          </Menu.Item>
                        )}
                      </Menu>
                    </div>
                  </div>
                </div>
              </Sider>
            )
          )}

          <Layout className="site-layout">
            {Object.keys(routes[user.role]["routes"]).map((val) => (
              <Route
                exact
                key={`route-${user.role}-${val}`}
                path={`/${rootPath}/${routes[user.role]["routes"][val].subpath
                  }/${routes[user.role]["routes"][val].path}`}
                component={withRouter(
                  routes[user.role]["routes"][val].component
                )}
              />
            ))}

            {user.role === "employee" &&
              Object.keys(access).length > 0 &&
              Object.keys(routes.common).map((ar) =>
                access[ar] && access[ar].access ? (
                  <Route
                    exact
                    key={`route-${user.role}-${ar}`}
                    path={`/employee/access/${routes.common[ar].path}`}
                    component={withRouter(routes.common[ar].component)}
                  />
                ) : (
                  <React.Fragment></React.Fragment>
                )
              )}

            {/* Payroll access */}
            {Object.keys(access).length > 0 &&
              user.role === "employee" &&
              access.payroll.access && (
                <React.Fragment>
                  <Route
                    exact
                    path={`/employee/access/${routes.common.payroll.path}`}
                    component={withRouter(routes.common.payroll.component)}
                  />
                  <Route
                    exact
                    path={`/employee/access/${routes.common.process.path}`}
                    component={withRouter(routes.common.process.component)}
                  />
                </React.Fragment>
              )}

            {/* Reports Table Access */}
            {Object.keys(access).length > 0 &&
              user.role === "employee" &&
              access.reports.access && (
                <React.Fragment>
                  <Route
                    exact
                    path={`/employee/access/${routes.common.report_table.path}`}
                    component={withRouter(routes.common.report_table.component)}
                  />
                </React.Fragment>
              )}

            <Redirect
              path={"/"}
              to={
                loginstate
                  ? `${user.role}/login/flow`
                  : `${user.role}/home/dashboard`
              }
            />
          </Layout>
        </Layout>
      )
      }
    </Switch >
  );
}

PrivateRoute.propTypes = {
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loginstate: PropTypes.bool.isRequired,
  access: PropTypes.object.isRequired,
};

const PrivateContainer = connect(
  (state) => ({
    user: state.auth.user,
    isLoggedIn: state.auth.isLoggedIn,
    loginstate: state.auth.loginstate,
    access: state.auth.access,
    currentTab: state.auth.currentTab,
    selectedCategory: state.auth.selectedCategory,
    categoryChildren: state.auth.categoryChildren,
    orgLoading: state.organisation.loading,
    empLoading: state.employee.loading,
    onboarding: state.employee.onboarding,
    isCurrentFinancialYearJoinee: state.auth.isCurrentFinancialYearJoinee
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    responsiveReducer: responsive.getActions(dispatch),
    employeeReducer: employee.getActions(dispatch),
    organisationReducer: organisation.getActions(dispatch),
  })
)(PrivateRoute);

export default withRouter(PrivateContainer);
