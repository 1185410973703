import {
  message,
  Upload,
  Modal,
  Select,
  notification,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import EditImg from "../../../assets/employeeProfile/EditImg.svg";
import config from "../../../utils/config";
import uploadLogo from "../../../assets/icons/savefile.svg";
import { NormalButton } from "../../ComponentLibrary";
import blueEditIcon from "../../../assets/icons/blueEditIcon.png";
import EmployeeLogo from "../../../assets/employeeProfile/EmployeeLogo.svg";
import { mapUser, updateEmployeeData } from "../../../apis/organisation";
import { connect } from "react-redux";
import { changePassword, updateUsername } from "../../../apis/auth";
import InputVerifyComponent from "../../InputLibrary/InputVerifyComponent/InputVerifyComponent";
import DropdownComponent from "../../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error("Image must smaller than 5MB!");
  }
  return isJpgOrPng && isLt2M;
}

const resetHeading = ["", "Reset Employee Password", "Reset Employee Username"]

const { Dragger } = Upload;
const { Option } = Select;
const EmployeInfo = ({
  user,
  authReducer,
  statusName,
  department,
  location,
  getEmployeeData,
  employeeList,
  departmentList,
  locations,
  designationList,
  statusList,
  updateOnboarding,
  designation,
  isEmployeeView,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateInfoBody, setUpdateInfoBody] = useState({
    id: user._id,
    departmentId: department ? department.departmentId : "",
    statusId: statusName ? statusName.statusId : "",
    locationId: location ? location.locationId : "",
    designationId: user.designationId ? user.designationId : "",
    mappedTo: user.mappedTo._id,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const [resetPassword, setResetPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [username, setUsername] = useState("");
  /**
   * 1: password
   * 2: username
   */
  const [resetType, setResetType] = useState(0);
  const [submitClicked, setSubmitClicked] = useState("");
  const [checkUserExist, setCheckUserExist] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const showRestModal = (type) => {
    setIsResetModalVisible(true);
    setResetType(type);
    if (type === 2) {
      setUsername(user.username)
    }
  };
  useEffect(() => {
    document.title = 'Account & Activity | Profile';
  });
  useEffect(() => {
    setUpdateInfoBody({
      id: user._id,
      departmentId: user.departmentId ? user.departmentId : "",
      statusId: user.statusId ? user.statusId : "",
      locationId: user.locationId ? user.locationId : "",
      designationId: user.designationId ? user.designationId : "",
      mappedTo: user.mappedTo._id,
    });
  }, [user]);

  const handleResetCancel = () => {
    setResetPassword({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setResetType(0);
    setUsername("");
    setIsResetModalVisible(false);
  };

  const handleResetPassword = (e) => {
    setResetPassword({
      ...resetPassword,
      [e.target.name]: e.target.value,
    });
  };

  const onResetPassSubmit = async () => {
    if (
      !resetPassword.oldPassword ||
      !resetPassword.newPassword ||
      !resetPassword.confirmPassword
    ) {
      notification.error({ message: "Field is required" });
      return;
    }
    if (resetPassword.oldPassword === resetPassword.newPassword) {
      notification.error({
        message: "Your new Password cannot be the same as your Old Password",
      });
      return;
    }
    if (resetPassword.newPassword !== resetPassword.confirmPassword) {
      notification.error({
        message: "New Password and Confirm Password are not same",
      });
      return;
    }
    if (
      resetPassword.oldPassword !== resetPassword.newPassword &&
      resetPassword.newPassword === resetPassword.confirmPassword
    ) {
      setIsLoading(true);
      const response = await changePassword({
        username: user.username,
        oldPassword: resetPassword.oldPassword,
        newPassword: resetPassword.newPassword,
      });
      setIsLoading(false);
      if (response.data.success) {
        notification.success({ message: "Password Changed Successfully!" });
        handleResetCancel();
      } else {
        if (response.data.message === "WRONG_CREDENTIALS") {
          notification.error({
            message: "Incorrect Old password!, Please try again!",
          });
        } else {
          notification.error({
            message: "Can not change your password, Please try again!",
          });
        }
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsLoading(false);
    setIsModalVisible(false);
    setIsResetModalVisible(false);
  };

  const handleChange = async (info) => {
    if (info.file.status === "uploading") {
      setIsLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setIsLoading(false);
      handleCancel();
    }
  };

  const uploadProps = {
    name: "files",
    action: `${config.rootUrl}/api/auth/user/picture`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    async onChange(info) {
      if (info.file.status === "done") {
        const { response } = info.file;
        message.success(`${info.file.name} file uploaded successfully`);
        await updateOnboarding("profilePicture");
        authReducer.updateImage(response.data.image);
        handleCancel();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onShowEditModal = () => {
    setShowEditModal(true);
    setUpdateInfoBody({
      id: user._id,
      departmentId: user.departmentId ? user.departmentId : "",
      statusId: user.statusId ? user.statusId : "",
      locationId: user.locationId ? user.locationId : "",
      designationId: user.designationId ? user.designationId : "",
      mappedTo: user.mappedTo._id,
    });
  };

  const onUpdate = async () => {
    setIsLoading(true);
    let res = await updateEmployeeData(user._id, updateInfoBody);
    await mapUser({
      id: user._id,
      mappedId: updateInfoBody.mappedTo,
    });
    if (res.status === 200 && res.data.success) {
      notification.success({
        message: "Successfully update user information.",
      });
      getEmployeeData(user._id);
      onUpdateClose();
    } else {
      notification.success({
        message: "Failed to upload user information, please try again.",
      });
      setIsLoading(false);
    }
  };

  const onUpdateClose = async () => {
    setShowEditModal(false);
    setUpdateInfoBody({
      PAN: user.PAN,
      departmentId: "",
      statusId: "",
      locationId: "",
      designationId: "",
      mappedTo: "",
    });
    setIsLoading(false);
  };


  const updateUsernameDetails = async () => {
    if (username === "" || checkUserExist) {
      setSubmitClicked(username);
      return;
    }
    setIsLoading(true)
    let res = await updateUsername({
      id: user._id,
      username,
    });
    if (res.data.success) {
      localStorage.setItem("mool_auth", res.data.data.auth);
      notification.success({ message: "Successfully updated the username" })
      window.location.reload();
    } else {
      notification.error({ message: "Sorry unable to set the username" });
    }
    handleCancel()
  }

  // TODO: Designation and mappedto information needed to get added in the application
  return (
    <>

      <div className="employe-profile-container">
        <div className="text-align-right p-top-16">
          {!isEmployeeView && (
            <NormalButton
              icon={blueEditIcon}
              margin={[0, 20]}
              buttonText="Edit"
              type={12}
              onClick={onShowEditModal}
            />
          )}
        </div>

        <div className="employe-information-container">
          <div className="employe-img-container">
            <img
              alt="employeeimage"
              src={user.image ? user.image : EmployeeLogo}
              className="employe-card"
              onClick={showModal}
            />
            <img
              src={EditImg}
              alt="edit"
              className="edit-img"
              onClick={showModal}
            />
          </div>
          <p className="h5-bold-20 m-top-8">{user.displayName}</p>
          {designation && (
            <p className="employe-designation">{designation.designationName}</p>
          )}
          {isEmployeeView && (
            <div className="flex justify-between" style={{ margin: "10px 20px" }}>
              <NormalButton
                buttonText="Reset Password"
                type={13}
                onClick={() => showRestModal(1)}
              />
              <NormalButton
                icon={blueEditIcon}
                buttonText="Username"
                type={11}
                onClick={() => showRestModal(2)}
              />
            </div>
          )}
        </div>

        <p className="employe-border"></p>
        <div className="employe-details-container">
          {statusName && (
            <div className="employe-info">
              <p className="employe-info-header">Status</p>
              <p className="employe-info-data break-word">{statusName.statusName ?? "N/A"}</p>
            </div>
          )}
          <div className="employe-info">
            <p className="employe-info-header">Username</p>
            <p className="employe-info-data break-word">
              {user.username}
            </p>
          </div>
          <div className="employe-info">
            <p className="employe-info-header">Email</p>
            <p className="employe-info-data break-word">{user.primaryEmail}</p>
          </div>{" "}
          <div className="employe-info">
            <p className="employe-info-header">PAN</p>
            <p className="employe-info-data break-word">{user.PAN}</p>
          </div>{" "}
          {department && (
            <div className="employe-info">
              <p className="employe-info-header">Department</p>
              <p className="employe-info-data break-word">{department.departmentName}</p>
            </div>
          )}
          <div className="employe-info">
            <p className="employe-info-header">Phone</p>
            <p className="employe-info-data break-word">{user.phone}</p>
          </div>{" "}
          {location && (
            <div className="employe-info">
              <p className="employe-info-header">Location</p>
              <p className="employe-info-data break-word">
                {location.officeName + ", " + location.address}
              </p>
            </div>
          )}{" "}
          <div className="employe-info">
            <p className="employe-info-header">Employee ID</p>
            <p className="employe-info-data break-word">
              {user.currentOrganisationMeta.employeeId
                ? user.currentOrganisationMeta.employeeId
                : ""}
            </p>
          </div>
          {user.ip && <div className="employe-info">
            <p className="employe-info-header">IP Address</p>
            <p className="employe-info-data break-word">
              {user.ip}
            </p>
          </div>}
          <div className="employe-info">
            <p className="employe-info-header">Regime Status</p>
            <p className="employe-info-data break-word">
              {user.regimeOptedStatus === 1 ? "New Regime" : "Old Regime"}
            </p>
          </div>
        </div>
      </div>

      <Modal
        visible={isResetModalVisible}
        onOk={handleOk}
        onCancel={handleResetCancel}
        footer={null}
        centered
        width={400}
      >
        <Spin spinning={isLoading} size="large">
          <h1 className="font-size-global-24 font-weight-global-700">{resetHeading[resetType]}</h1>
          {resetType === 1 && (
            <div>
              <div
                className="modal-alignment-center-global"
                style={{ marginTop: 10 }}
              >

                <InputFieldComponent
                  className="input-register"
                  type={"password"}
                  labeltext={"Old Password"}
                  name="oldPassword"
                  onChange={handleResetPassword}
                  value={resetPassword.oldPassword}
                />
              </div>
              <div
                className="modal-alignment-center-global"
                style={{ marginTop: 10 }}
              >

                <InputFieldComponent
                  name="newPassword"
                  type={"password"}
                  labeltext={"New Password"}
                  className="input-register"
                  onChange={handleResetPassword}
                  value={resetPassword.newPassword}
                />
              </div>
              <div className="modal-alignment-center-global" style={{ marginTop: 10 }}>

                <InputFieldComponent
                  name="confirmPassword"
                  type={"password"}
                  labeltext={"Confirm Password"}
                  className="input-register"
                  onChange={handleResetPassword}
                  value={resetPassword.confirmPassword}
                />
              </div>
              <div>
                <NormalButton
                  buttonText="Change Password"
                  onClick={onResetPassSubmit}
                  type={13}
                  margin={[8, 0]}
                  block={true}
                />
              </div>
            </div>
          )}
          {resetType === 2 && (
            <div>
              <div
                className="w-full position-relative"
                style={{ marginTop: 10 }}
              >
                <InputVerifyComponent
                  margin={["30", "0", "20", "0"]}
                  width={"100%"}
                  maxWidth={"35rem"}
                  setCheckUserExist={setCheckUserExist}
                  required={true}
                  uniqueKey={"username"}
                  labeltext={"Username"}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  value={username}
                  setSubmitClicked={setSubmitClicked}
                  submitClicked={submitClicked}
                />
              </div>
              <div className="modal-margin-top-add-global-3">
                <NormalButton
                  buttonText="Change Username"
                  onClick={updateUsernameDetails}
                  type={13}
                  margin={[8, 0]}
                  block={true}
                />
              </div>
            </div>
          )}
        </Spin>
      </Modal>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Update Profile Image"
        footer={null}
        centered
        width={400}
      >
        <div className="update-img-modal-body">
          <Dragger
            name="avatar"
            listType="picture-card"
            className="avatar-update"
            maxCount={1}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            showUploadList={false}
            {...uploadProps}
          >
            <p className="ant-upload-drag-icon">
              <img alt="upload" src={uploadLogo} height="70" width="70" />
            </p>
            <p className="ant-upload-text">
              Drag And Drop Profile Picture Here
            </p>
            <p className="ant-upload-hint">
              or <u>Click Here</u> to upload
            </p>
          </Dragger>
        </div>
      </Modal>
      <Modal
        visible={showEditModal}
        onCancel={onUpdateClose}
        footer={null}
        closable={false}
        centered
        width={400}
      >
        <Spin spinning={isLoading} size="large">
          <div className="update-img-modal-body">
            <h1 className="font-weight-global-500 font-size-global-24 modal-margin-left-global-heading">Edit Details</h1>
            <div className="app-input-container modal-alignment-center-global">
              <DropdownComponent
                labeltext={"Designation"}
                className="app-select"
                value={updateInfoBody.designationId}
                options={designationList.map(item => item.designationName)}
                values={designationList.map(item => item.designationId)}
                onChange={(e) =>
                  setUpdateInfoBody({
                    ...updateInfoBody,
                    designationId: e,
                  })
                }
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
              >
                {designationList.map((designationItem, i) => {
                  return (
                    <Option key={i} value={designationItem.designationId}>
                      {designationItem.designationName}
                    </Option>
                  );
                })}
              </DropdownComponent>
            </div>
            <div className="app-input-container modal-alignment-center-global modal-margin-top-reduce-global-1">
              <DropdownComponent
                labeltext={"Status"}
                className="app-select"
                value={updateInfoBody.statusId}
                options={statusList.map(item => item.statusName)}
                values={statusList.map(item => item.statusId)}
                onChange={(e) =>
                  setUpdateInfoBody({
                    ...updateInfoBody,
                    statusId: e,
                  })
                }
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
              >
                {statusList.map((statusItem, i) => {
                  return (
                    <Option key={i} value={statusItem.statusId}>
                      {statusItem.statusName}
                    </Option>
                  );
                })}
              </DropdownComponent>
            </div>
            <div className="app-input-container modal-alignment-center-global modal-margin-top-reduce-global-1">
              <DropdownComponent
                labeltext={"Department"}
                className="app-select"
                value={updateInfoBody.departmentId}
                options={departmentList.map(item => item.departmentName)}
                values={departmentList.map(item => item.departmentId)}
                onChange={(e) =>
                  setUpdateInfoBody({
                    ...updateInfoBody,
                    departmentId: e,
                  })
                }
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
              >
                {departmentList.map((departmentItem, i) => {
                  return (
                    <Option key={i} value={departmentItem.departmentId}>
                      {departmentItem.departmentName}
                    </Option>
                  );
                })}
              </DropdownComponent>
            </div>
            <div className="app-input-container modal-alignment-center-global modal-margin-top-reduce-global-1 ">
              <DropdownComponent
                labeltext={"Locations"}
                className="app-select"
                value={updateInfoBody.locationId}
                values={locations.map(item => item.locationId)}
                options={locations.map(item => item.officeName)}
                onChange={(e) =>
                  setUpdateInfoBody({
                    ...updateInfoBody,
                    locationId: e,
                  })
                }
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
              >
              </DropdownComponent>
            </div>
            <div className="app-input-container modal-alignment-center-global modal-margin-top-reduce-global-1 margin-bottom-global">
              <DropdownComponent
                labeltext={"Reports To"}
                className="app-select"
                value={updateInfoBody.mappedTo}
                values={employeeList.map(item => item._id)}
                options={employeeList.map(item => item.displayName)}
                onChange={(e) =>
                  setUpdateInfoBody({
                    ...updateInfoBody,
                    mappedTo: e,
                  })
                }
              >
                {employeeList.map((empItem, i) => {
                  return (
                    <Option key={i} value={empItem._id}>
                      {empItem.displayName} -{" "}
                      {empItem.currentOrganisationMeta.employeeId}
                    </Option>
                  );
                })}
              </DropdownComponent>
            </div>
            <div className="modal-margin-left-global-1 ">
              <NormalButton type={13} buttonText="Update" onClick={onUpdate} />
              <NormalButton
                type={5}
                buttonText="Cancel"
                margin={[0, 16]}
                onClick={onUpdateClose}
              />
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

const EmployeInfoContainer = connect((state) => ({
  employeeList: state.organisation.employeeList,
  image: state.auth.image,
  locations: state.organisation.location,
  designationList: state.organisation.designation,
  departmentList: state.organisation.department,
  statusList: state.organisation.status,
}))(EmployeInfo);

export default EmployeInfoContainer;
