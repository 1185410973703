// organistion/mclose/fields/
import { Button, Dropdown, Menu, Spin, Table } from "antd";

import { useEffect, useState } from "react";
import {
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import downoutined from "../../../assets/icons/downoutlined.svg";
import plus from "../../../assets/icons/whitePlus.svg";

const CurrentMonthClosingFields = ({
  monthCloseFields,
  startRefresh,
  loading,
  columns,
  name,
  setSingleUploadModal,
  setBulkUploadModal
}) => {
  let [tableData, setTableData] = useState([]);
  const [monthCloseCount, setMonthCloseCount] = useState(0);


  useEffect(() => {
    let data = monthCloseFields.map((val, index) => {
      return {
        ...val,
        key: index
      }
    })
    setTableData([...data])
  }, [monthCloseFields])

  function handleMenuClick(e) {
    if (e.key === "0") {
      setSingleUploadModal(true)
    } else if (e.key === "1") {
      setBulkUploadModal(true)
    }
  }



  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="0" icon={<UserAddOutlined />}>
        Single Record
      </Menu.Item>
      <Menu.Item key="1" icon={<UsergroupAddOutlined />}>
        Multiple Record
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="paper-wrapper-month-closing">
      <div className="flex justify-between align-center">
        <h2 className="app-heading-2 font-weight-global-700 font-size-24 ">Month Closing Data  ( {monthCloseCount === 0 ? monthCloseFields.length : monthCloseCount} )</h2>
        <div style={{ display: "flex" }}>
          <Button
            onClick={startRefresh}
            className={`button-lib button-type-13 p-10`}
            style={{ height: "2.5rem" }}
          >
            <div className="flex justify-between align-center">
              Refresh
            </div>
          </Button>
          <Dropdown overlay={menu}>
            <Button
              className={`button-lib button-type-13 p-10`}
              style={{ height: "2.5rem" }}
            >
              <div className="flex justify-between align-center">
                <img style={{ height: 18, width: 18 }} alt="pri-icon" src={plus} className="button-lib-icon"></img>
                Add
                <img style={{ height: 18, width: 18 }} src={downoutined} alt="sec-icon" className="button-lib-icon m-left-10" />
              </div>
            </Button>
            {/* <NormalButton buttonText={"Add"} type={13} icon={plus} secondaryIcon={downoutined} /> */}
          </Dropdown>
        </div>
      </div>
      <Spin spinning={loading} size="large">
        <Table
          pagination={false}
          scroll={{ y: 400, x: 500 }}
          columns={columns}
          dataSource={tableData.reverse()}
          onChange={(pagination, filters, sorter, extra) => { setMonthCloseCount(extra.currentDataSource.length ?? monthCloseCount.length) }}
        />
      </Spin>
    </div>
  );
};

export default CurrentMonthClosingFields;
