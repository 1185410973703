import { Tabs } from "antd";
import SectionData from "./generalComponents/sectionData.js";
import SubmitFormContainer from "./generalComponents/SubmitForm";

const { TabPane } = Tabs;

const moreDeducationData = [
  {
    key: "1",
    sectionFullData: {
      sectionName: "Section 80TTA",
      sectionType: "Interest on savings account",
      sectionInfo: "You can claim tax deductions on savings accounts deposits held in a post office, bank, or cooperative society. However, your exemption claim should be less than Rs. 10,000.",
      sectinDescription: "Claim deductions on savings accounts deposits",
      notes: "80TTA tax deduction is only applicable on the interest earned and mentioned by you under “Other sources of Income” head.",
      limit: "Rs. 10,000/Financial year"
    },
    category: "80TTA",
    documents: [
      {
        declaration: "interestSavingsAccountDepositFDPostOfficeCooperativeSociety",
        label: "80TTA - Interest on Deposits in Savings Account, FDs, Post Office And Cooperative Society",
      }
    ],
    subCategory: "",
  },
  {
    key: "2",
    documents: [
      {
        declaration: "donation100PrcntExemption",
        label: "80G - Donation - 100% Exemption",
      },
      {
        declaration: "donation50PrcntExemption",
        label: "80G - Donation - 50% Exemption",
      },
      {
        declaration: "donationChildrenEducation",
        label: "80G - Donation - Children Education",
      },
      {
        declaration: "donationPoliticalParties",
        label: "80G - Donation - Political Parties",
      }
    ],
    sectionFullData: {
      sectionName: "Section 80G",
      sectionType: "Donation to charitable Organisation",
      sectionInfo: "If you have made any contributions to certain charitable organisations and/or relief funds, you can claim them as a tax deduction*. Donations made to only prescribed funds qualify as a deduction.",
      sectinDescription: "Tax exemptions on contributions to Charitable institution",
      notes: "Percentage of deductions depends on the type of charitable organisation. Please visit Income Tax India website for further details.",
      limit: "No limits"
    },
    category: "80G",
  },
  {
    key: "3",
    sectionFullData: {
      sectionName: "Section 80CCC",
      sectionType: "Contribution to pension plan/ Annuity fund",
      sectionInfo: "If you buy a pension plan from a life insurance company that will give you regular payouts or annuities on regular periods, you can claim an income tax deduction on your contribution.",
      sectinDescription: "Income tax benefits for an annuity plan with a pension fund",
      notes: "",
      limit: ""
    },
    category: "80CCC",
    documents: [
      {
        declaration: "contributionPensionFund",
        label: "Contribution to Pension Fund",
      }
    ],
  },
  {
    key: "4",
    sectionFullData: {
      sectionName: "Section 80CCD(1B)",
      sectionType: "Self contribution in NPS",
      sectionInfo: "You can claim tax deduction by investing in NPS.",
      sectinDescription: "Tax benefit for a contribution made to the NPS by employee",
      notes: "",
      limit: ""
    },
    category: "80CCD1(B)",
    documents: [
      {
        declaration: "contributionNPS2015_CCD1B",
        label: "80CCD1(B) - Contribution to NPS 2015",
      }
    ],
    subCategory: "",
  },
];

const MoreDeducation = ({ the12BBApproved, financialYear }) => {
  return (
    <div>
      <div className="blue-tabs-deduction-info-tabs-container">
        <Tabs tabPosition="left">
          {moreDeducationData.map((data) => {
            return (
              <TabPane
                className="main-content-tabs"
                tab={
                  <SectionData
                    sectionName={data.sectionFullData.sectionName}
                    sectionType={data.sectionFullData.sectionType}
                    sectionInfo={data.sectionFullData.sectionInfo}
                  />
                }
                key={data.key}
              >
                <SubmitFormContainer the12BBApproved={the12BBApproved} sectionDocuments={data.documents} sectionFullData={data.sectionFullData} Category={data.category} subCatagory={data.subCategory} financialYear={financialYear} />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default MoreDeducation;
