const CATEGORIES = {
  0: "Default",
  1: "Payroll",
  2: "Profile",
  3: "Declaration",
  4: "Allowance",
  5: "AdvanceTax",
  6: "Attendance",
  7: "Salary",
  8: "Access",
  9: "Proofs",
  10: "Reimbursement",
  11: "Arrears And Bonuses",
  12: "House Rent Allowances",
  13: "Settings",
  14: "Data Sync",
  15: "Employee Manage",
  16: "Full And Final"
};

const LOG_CATEGORY_LIST = {
  org: {
    config: [14],
    home: [0, 9],
    employees: [15, 16, 9],
    payroll: [1, 11, 17],
    reports: [],
    payouts: [17],
    attendance: [6],
    utility: [],
    common: [2],
  },
  employee: {
    home: [0],
    salary: [7, 11],
    declarations: [3, 4, 5, 10, 13],
    attendance: [6],
    common: [2],
  },
};

const LOG_CONFIG = {
  org: {
    config: true,
    home: true,
    employees: true,
    payroll: true,
    reports: false,
    payouts: false,
    attendance: true,
    utility: false,
    common: true,
  },
  employee: {
    home: true,
    salary: true,
    declarations: true,
    attendance: true,
    common: true,
  },
};


export { LOG_CATEGORY_LIST, CATEGORIES, LOG_CONFIG };
