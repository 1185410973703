/* eslint-disable no-param-reassign */
import axios from 'axios';
import config from '../utils/config'
axios.defaults.baseURL = `${config.rootUrl}/api`;
axios.interceptors.request.use((config) => {
  const accessSecret = window.localStorage.getItem('mool_auth');
  if (accessSecret !== null || accessSecret !== undefined) {
    config.headers.authorization = accessSecret;
    return config;
  }
  return config;
});
export default axios;
