import React, { useState, useEffect } from "react";
import { NormalButton } from "../../../ComponentLibrary";
import { Col, notification, Row, Drawer } from "antd";
import { updateBankInfo } from "../../../../apis/user";
import blueEditIcon from "../../../../assets/icons/blueEditIcon.png";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import { pincodeData } from "../../../../constants/common_functions";

const BankDetails = ({
  bankInformation,
  updateInfo,
  updateOnboarding,
  user,
}) => {
  const [loading, setLoading] = useState(false);

  const [bankInfo, setBankInfo] = useState({
    accountNumber: "",
    ifsc: "",
    bankName: "",
    bankAddress: "",
    state: "",
    city: "",
    pinCode: "",
    country: "India"
  });

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    bankInfoUpdate();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankInformation]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const bankInfoUpdate = () => {
    if (bankInformation) {
      setBankInfo({
        accountNumber: bankInformation.accountNumber || "",
        ifsc: bankInformation.ifsc || "",
        bankName: bankInformation.bankName || "",
        bankAddress: bankInformation.bankAddress || "",
        state: bankInformation.state || "",
        city: bankInformation.city || "",
        pinCode: bankInformation.pinCode || "",
        country: bankInformation.country || "India"
      });
    }
  };

  const submitForm = async () => {
    setLoading(true);
    let res = await updateBankInfo({ ...bankInfo, id: user._id });
    if (res.data.success) {
      await updateInfo();
      await updateOnboarding("bankInformation");
      notification.success({
        message: "Bank Information Has Been Updated Successfully",
      });
    } else {
      bankInfoUpdate();
      notification.error({
        message: "Sorry unable to update the bank information",
      });
    }
    setLoading(false);
    onClose()
  };

  const updateAddressField = (field, value) => {
    setBankInfo({
      ...bankInfo,
      [field]: value,
    });
  };

  // useEffect(() => {
  //   const tempPincode = bankInfo.pinCode
  //   if (tempPincode.length === 6) {
  //     const data = pincodeData(tempPincode)
  //     Promise.resolve(data).then(res => {
  //       setBankInfo({
  //         ...bankInfo,
  //         city: res.Block,
  //         state: res.State,
  //       });
  //     })
  //   }
  // }, [bankInfo])

  const bank = (
    <>
      <div className="employe-profileinfo-container" id="bankDetails">
        <div className="personal-information">
          <div className="header-container">
            <p style={{ marginLeft: "0.5em" }} className="font-size-global-24 font-weight-global-700 modal-margin-left-half">Bank Information</p>
          </div>
          <Row gutter={[30, 20]} className="details-container">
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="employee-info modal-margin-top-add-global-2">
                <InputFieldComponent
                  className="profile-data-display"
                  value={bankInfo.accountNumber}
                  type="text"
                  labeltext={"Account Number"}
                  onChange={(e) => {
                    if (/\d+/.test(e.target.value) || e.target.value === "") {
                      updateAddressField(
                        "accountNumber",
                        /^\d+$/.test(e.target.value)
                          ? e.target.value
                          : ""
                      );
                    }
                  }}
                  name="accountNumber"
                  block={true}
                ></InputFieldComponent>
              </div>
              <div className="employee-info m-top-10">
                <InputFieldComponent
                  className="profile-data-display"
                  labeltext={"Bank Name"}
                  name="bankName"
                  onChange={(e) => {
                    updateAddressField("bankName", e.target.value);
                  }}
                  value={bankInfo.bankName}
                  block={true}
                ></InputFieldComponent>
              </div>
              <div className="employee-info m-top-10">
                <InputFieldComponent
                  className="profile-data-display"
                  labeltext={"Pincode"}
                  name="city"
                  onChange={(e) => {
                    updateAddressField("pinCode", e.target.value);
                    if (e.target.value.length === 6) {
                      updateAddressField("pinCode", e.target.value);
                      const data = pincodeData(e.target.value);
                      Promise.resolve(data).then((res) => {
                        setBankInfo({
                          ...bankInfo,
                          pinCode:e.target.value,
                          city: res.Block,
                          state: res.State,
                        });
                      });
                    }
                  }}
                  value={bankInfo.pinCode}
                  block={true}
                ></InputFieldComponent>
              </div>
              <div className="employee-info m-top-10">
                <InputFieldComponent
                  className="profile-data-display"
                  value={bankInfo.state}
                  labeltext={"State"}
                  onChange={(e) => {
                    updateAddressField("state", e.target.value);
                  }}
                  name="state"
                  block={true}
                ></InputFieldComponent>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="employee-info">
                <InputFieldComponent
                  className="profile-data-display"
                  value={bankInfo.ifsc}
                  labeltext={"IFSC Code"}
                  onChange={(e) => {
                    updateAddressField("ifsc", e.target.value.toUpperCase());
                  }}
                  name="ifsc"
                  block={true}
                ></InputFieldComponent>
              </div>
              <div className="employee-info m-top-10">
                <InputFieldComponent
                  className="profile-data-display"
                  value={bankInfo.bankAddress}
                  labeltext={"Bank Address"}
                  onChange={(e) => {
                    updateAddressField("bankAddress", e.target.value);
                  }}
                  name="bankAddress"
                  block={true}
                ></InputFieldComponent>
              </div>
              <div className="employee-info m-top-10">
                <InputFieldComponent
                  className="profile-data-display"
                  labeltext={"City"}
                  name="city"
                  onChange={(e) => {
                    updateAddressField("city", e.target.value);
                  }}
                  value={bankInfo.city}
                  block={true}
                ></InputFieldComponent>
              </div>
              <div className="employee-info m-top-10">
                <InputFieldComponent
                  className="profile-data-display"
                  value={bankInfo.country}
                  labeltext={"Country"}
                  disabled={true}
                  name="country"
                  block={true}
                ></InputFieldComponent>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );

  return (
    <div className="employe-profileinfo-container" id="bankInfoContainer">
      <div className="employe-profileinfo-container" id="bankDetails">
        <div className="personal-information">
          <div className="header-container">
            <p className="font-size-global-24 font-weight-global-700">Bank Information</p>

            <NormalButton
              type={12}
              icon={blueEditIcon}
              buttonText="Edit"
              onClick={showDrawer}
            ></NormalButton>
          </div>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Account number</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{bankInfo.accountNumber}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Bank Name</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{bankInfo.bankName}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">IFSC code</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{bankInfo.ifsc}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Address</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{bankInfo.bankAddress}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">City</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{bankInfo.city}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">State</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{bankInfo.state}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">PinCode</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{bankInfo.pinCode}</p>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <h3 className="fields-heading">Country</h3>
            </Col>
            <Col span={4}>:</Col>
            <Col span={10}>
              <p className="info">{bankInfo.country}</p>
            </Col>
          </Row>
        </div>
      </div>
      <Drawer
        title="Edit"
        placement="right"
        onClose={onClose}
        visible={visible}
        size={"large"}
      >
        <Spinner spinning={loading}>
          <div className="drawer">
            {bank}
            <div className="modal-margin-left-global-half">
              <NormalButton
                buttonText="save changes"
                type={13}
                onClick={submitForm}
              />
              <NormalButton buttonText="Cancel" type={5} onClick={onClose} />
            </div>
          </div>
        </Spinner>
      </Drawer>
    </div>
  );
};

export default BankDetails;
