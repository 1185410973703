import { Col, Divider, InputNumber, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { HeaderComponent } from "../../Common";
import { NormalButton } from "../../ComponentLibrary";
import EditIcon from "../../../assets/common/EditButton.svg";
import { perquisites } from "../../../utils/config";
import Spinner from "../../ComponentLibrary/Spinner/Spinner";
import { connect } from "react-redux";
import organisation from "../../../redux/modules/organisation";
import TrialPage from "../../Common/TrialComponent";
import nonTaxableBgImage from "../../../assets/dummyPages/nonTaxableBgImage.png"

const NonTaxable = ({ organisationSettings, organisationReducer, user, isTrial }) => {
  const [nonTaxablePerqs, setNonTaxableperqs] = useState({ ...perquisites });
  const [loading, setLoading] = useState(false);
  const [structureOfMool, setStructureOfMool] = useState(false);
  const [editable, setEditable] = useState(false);
  const reset = () => {
    setEditable(false);
    setLoading(false);
  };

  useEffect(
    () => {
      if (user.moolSettings) {
        setStructureOfMool(user.moolSettings.structureOfMool);
      }
      let perqs = { ...perquisites };
      if (
        organisationSettings.perquisites &&
        Object.keys(organisationSettings.perquisites).length > 0
      ) {
        perqs = { ...organisationSettings.perquisites };
      }
      setNonTaxableperqs({ ...perqs });
    },
    [organisationSettings, user.moolSettings]
  );

  const updatePerqs = (key, itemName, value) => {
    let NTP = { ...nonTaxablePerqs };
    NTP[key][itemName] = value;
    setNonTaxableperqs({ ...NTP });
  };

  const savePerqs = async () => {
    if (!editable) {
      return;
    }
    setLoading(true);
    // save the perq
    let newOrgSettings = {
      ...organisationSettings,
      perquisites: { ...nonTaxablePerqs }
    };
    organisationReducer.updateSettings({
      organisationSettings: newOrgSettings,
      organisationId: user.organisationId
    });
    // Update the Perqs respectively
    reset();
  };
  useEffect(() => {
    document.title = 'Perquisites | Non Taxable';
  });
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Explore the tax heaven for salaried employees without getting stressed about compliances"} bgImage={nonTaxableBgImage}
    /> :
      <section className="site-layout">
        <HeaderComponent name="Non Taxable" />
        <div className="app-main-content-container paper-wrapper">
          <Spinner spinning={loading}>
            <div className="flex justify-between align-center">
              <h2 className="app-heading-2 font-size-global-24 font-weight-global-700">Perquisites</h2>
              <div>
                {editable &&
                  <NormalButton buttonText={"Cancel"} onClick={reset} type={5} />}
                {editable &&
                  <NormalButton
                    buttonText={"Submit"}
                    onClick={savePerqs}
                    type={13}
                  />}
                {!editable &&
                  <NormalButton
                    type={13}
                    buttonText="Manage"
                    icon={EditIcon}
                    onClick={() => setEditable(true)}
                  />}
              </div>
            </div>
            <Row gutter={[20]}>
              <Col sm={18} md={18} xs={18} lg={18}>
                <p className="app-label-2 font-weight-700">Name</p>
              </Col>
              <Col sm={4} md={4} xs={4} lg={4}>
                {editable &&
                  !structureOfMool &&
                  <p className="app-label-2 font-weight-700">Limit</p>}
                {!editable &&
                  <p className="app-label-2 font-weight-700">Limit</p>}
              </Col>
              <Divider />
            </Row>
            {Object.keys(nonTaxablePerqs).map((perq, i) =>
              <IndividualPerqs
                key={i}
                perq={nonTaxablePerqs[perq]}
                perqKey={perq}
                updatePerqs={updatePerqs}
                editable={editable}
                structureOfMool={structureOfMool}
                loading={loading}
              />
            )}
          </Spinner>
        </div>
      </section>
  );
};

const IndividualPerqs = ({
  perq,
  updatePerqs,
  structureOfMool,
  editable,
  perqKey,
  loading
}) =>
  <Spinner spinning={loading}>
    <Row gutter={[20]}>
      <Col sm={18} md={18} xs={18} lg={18}>
        <p className="app-label-3 font-weight-700">
          {perq.label}
        </p>
      </Col>
      <Col sm={4} md={4} xs={4} lg={4}>
        {perq.applicable &&
          !editable &&
          (structureOfMool ? "No Limit" : `INR ${perq.amount}`)}
        {editable &&
          !structureOfMool &&
          perq.applicable &&
          <InputNumber
            value={perq.amount}
            onChange={e => updatePerqs(perqKey, "amount", e)}
          />}
      </Col>
      <Col sm={2} md={2} xs={2} lg={2}>
        {editable &&
          <Switch
            checked={perq.applicable}
            onChange={e => updatePerqs(perqKey, "applicable", e)}
          />}
      </Col>
      <Divider />
    </Row>
  </Spinner>;

const NonTaxablePerqs = connect(
  state => ({
    user: state.auth.user,
    organisationSettings: state.organisation.organisationSettings,
    orgLoading: state.organisation.loading,
    isTrial: state.auth.user.isTrial,

  }),
  dispatch => ({
    organisationReducer: organisation.getActions(dispatch)
  })
)(NonTaxable);

export default NonTaxablePerqs;
