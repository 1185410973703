import axios from "../apis";

const handleAction = async (url, name) => {
    let res = await axios({
        url, //your url
        method: 'GET',
        responseType: 'blob',
    })
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = urlDownload;
    link.setAttribute('download', name); //or any other extension
    document.body.appendChild(link);
    link.click();
}

const pincodeData = async(pincode) =>{
    // return null;
    if(pincode.length!==6) return null
    const res = await fetch(`https://api.postalpincode.in/pincode/${pincode}`,{method:'GET'}).then(res=>{
        return res.json()
    })
    if(res[0].Status==="404") return null
    return res[0].PostOffice[0]
}

const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }
export { handleAction, pincodeData, getKeyByValue }