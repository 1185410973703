import { HeaderComponent } from '../Common';
import PayrollMonths from './components/PayrollMonths'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { monthList } from '../../utils/config';
import { useEffect } from 'react';

const OrganisationRunPayroll = () => {
  const {financialYear, month} = useParams()
  useEffect(() => {
    document.title = 'Organization | Run Payroll';
  });
  return (
    <div className="site-layoyt">
      <HeaderComponent backButton={true} showMoolCoins={false}  name={`Run Payroll (${monthList[month]})`} />
      <PayrollMonths financialYear={financialYear} month={parseInt(month)} />
    </div>
  )
}

const OrganisationRunPayrollContainer = connect(
  (state) => ({
    user: state.auth.user,
  }),
)(OrganisationRunPayroll);
export default OrganisationRunPayrollContainer;