import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import netpayIcon from "../../../assets/OrganisationDashboardd/netpay.svg";
import pfsumIcon from "../../../assets/OrganisationDashboardd/pfsum.svg";
import taxesIcon from "../../../assets/OrganisationDashboardd/taxes.svg";
import config, { monthList } from "../../../utils/config";
import { Col, Row } from "antd";

const PayrollSummary = ({ freezeResult, pf, taxs, netPay }) => {
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
      type: "bar",
      stacked: "true"
      // stackType: "100%"
    },
    fill: {
      colors: ["#4190EB", "#fa6f6f", "#FEB73D"]
    },
    legend: {
      show: true,
      markers: {
        fillColors: ["#4190EB", "#fa6f6f", "#FEB73D"]
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: []
    }
  });

  const [series, setSeries] = useState([
    {
      name: "Total salary",
      data: []
    }
  ]);

  useEffect(
    () => {
      let categories = [];
      let data = [];
      let pfData = [];
      let taxData = [];
      let tempFreeze = freezeResult.sort((a, b) => a.month - b.month);
      let newFreeze = tempFreeze.slice(0,2)
      let newFreeze2 = tempFreeze.slice(2,11)
      let freeze = [...newFreeze2,...newFreeze]
      freeze.forEach(obj => {
        data.push(obj.totalEarnings ? obj.totalEarnings : 0);
        taxData.push(obj.totalTax ? obj.totalTax : 0);
        pfData.push(obj.totalPF ? obj.totalPF : 0);
        categories.push(obj.month || obj.month ===0 ? monthList[obj.month] : "");
      });
      setOptions(
        JSON.parse(
          JSON.stringify({
            ...options,
            xaxis: {
              categories: categories
            }
          })
        )
      );
      setSeries([
        {
          name: "Total salary",
          data: data
        },
        {
          name: "Total Tax",
          data: taxData
        },
        {
          name: "Total PF",
          data: pfData
        }
      ]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [freezeResult]
  );

  return (
    <div className="app-white-card-1 p-36 payroll-summary">
      <Row>
        <Col xs={24} sm={24} md={24} lg={16}>
          <div className="m-right-36 chart-section-container">
            <div className="m-bottom-32">
              <h2 style={{marginBottom:"2em"}} className="font-size-20 font-weight-700 m-bottom-12">
                Payroll Summary
              </h2>
            </div>
            <Chart
              options={options}
              series={series}
              type="bar"
              style={{ marginLeft: "-1rem" }}
            />{" "}
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <div className="m-left-36 sum-total-section-container">
            <h2 className="font-size-20 font-weight-700 m-bottom-8">
              {" "}Sum Total
            </h2>
            <div className="flex align-center my-36 break-word">
              <img alt="img" src={netpayIcon} />
              <div className="m-left-24">
                <h1 className=".subtitle2-med-14 color-grey">Netpay</h1>
                <p className="font-size-16 font-weight-700">
                  {config.numformatter(netPay)} INR
                </p>{" "}
              </div>
            </div>
            <div className="flex align-center my-36 break-word">
              <img alt="img" src={taxesIcon} />
              <div className="m-left-24">
                <h1 className=".subtitle2-med-14 color-grey">
                  Taxes
                </h1>
                <p className="font-size-16 font-weight-700">
                  {config.numformatter(taxs)} INR
                </p>{" "}
              </div>
            </div>
            <div className="flex align-center my-36 break-word">
              <img alt="img" src={pfsumIcon} />
              <div className="m-left-24">
                <h1 className=".subtitle2-med-14 color-grey">PF</h1>
                <p className="font-size-16 font-weight-700">
                  {config.numformatter(pf)} INR
                </p>{" "}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PayrollSummary;
