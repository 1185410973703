import { useState, useEffect } from "react";
import {
  Table,
  notification,
  Spin,
  Dropdown,
  Menu,
  Select,
  Button,
  message,
  Space,
} from "antd";
import {
  DeleteOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import organisation from "../../redux/modules/organisation";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  removeFromOrg,
  updateEmployerOnBoarding,
} from "../../apis/organisation";
import { HeaderComponent } from "../Common";
import activeEmployees from "../../assets/icons/activeIcons/eac.svg";
import { useHistory } from "react-router-dom";
import DeleteConfirmation from "./components/DeleteConfirmation";
import AddEmployee from "./components/AddEmployee";
import downoutined from "../../assets/icons/downoutlined.svg";
import { paths } from "../../constants/routes";

import UpdatePayStructure from "./components/UpdatePayStructure";
import { getEmployeeDeclaration } from "../../apis/employee";
import { checkFinancialYear } from "../../utils/config";
import UpdateEmployeeDetails from "./components/UpdateEmployeeDetails";
import UpdateEmployeePassowrd from "./components/ChangePassword";
import { createLog } from "../../apis/user";
import actionIcon from "../../assets/icons/action.svg";
import TrialPage from "../Common/TrialComponent";
import eployeeListBgImage from "../../assets/dummyPages/employeeListBgImage.png"

const { Option } = Select;

const dropDownAdd = ["single", "bulk", "payslip"];



const EmployeeList = ({
  onboarding,
  isTrial,
  user,
  organisationReducer,
  employeeList,
  groups,
  location,
  department,
  designation,
  status,
  organisationSettings
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeDisplayData, setEmployeeDisplayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [groupId, setGroupId] = useState("all");
  const [pageState, setPageState] = useState({
    single: 0,
    delete: 0,
  });

  let history = useHistory();

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedEmployeeDeclartion, setSelectedEmployeeDeclaration] = useState(
    null
  );
  const [updateSelectedEmployee, setUpdateSelectedEmployee] = useState(null);

  const [modalProperties, setModalProperties] = useState({
    single: {
      visible: false,
      loading: false,
    },
    delete: {
      visible: false,
      loading: false,
    },
    resignation: {
      visible: false,
      loading: false,
    },
    updateAllowances: {
      visible: false,
      loading: false,
    },
    employeeDetails: {
      visible: false,
      loading: false,
    },
    changePassword: {
      visible: false,
      loading: false,
    },
  });

  const resetModals = () => {
    let modalProps = modalProperties;
    modalProps.single.visible = false;
    modalProps.single.loading = false;
    modalProps.delete.visible = false;
    modalProps.delete.loading = false;
    modalProps.resignation.visible = false;
    modalProps.resignation.loading = false;
    modalProps.employeeDetails.visible = false;
    modalProps.employeeDetails.loading = false;
    modalProps.changePassword.visible = false;
    modalProps.changePassword.loading = false;
    setModalProperties({ ...modalProps });
    let newPageState = pageState;
    newPageState.single = 0;
    newPageState.delete = 0;
    setPageState({ ...newPageState });
    setResponseData([]);
  };

  const onSelectChange = (rows) => {
    setSelectedRowKeys([...rows]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const updateOnboarding = async (type) => {
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployerOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id,
      });
      organisationReducer.updateEmployerOnBoarding({
        organisationId: user.organisationId,
        id: user._id,
      });
    }
  };

  // TODO: Declare It globally
  useEffect(() => {
    updateEmployeeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeList]);

  useEffect(() => {
    organisationReducer.updateEmployeeList({
      organisationId: user.organisationId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);
  useEffect(() => {
    let employeeOfGroup = [];
    if (groupId === "all") {
      employeeOfGroup = [...employeeData];
    } else {
      employeeData.forEach((ed, _i) => {
        if (ed.groupId === groupId) {
          employeeOfGroup.push(ed);
        }
      });
    }
    setEmployeeDisplayData(employeeOfGroup);
  }, [groupId, employeeData]);

  function handleMenuClick(e) {
    if (e.key === "1") {
      history.push(paths.org.employeeAddition, user);
    } else {
      let modalProps = modalProperties;
      modalProps[dropDownAdd[e.key]].visible = true;
      modalProps[dropDownAdd[e.key]].loading = false;
      setModalProperties({ ...modalProps });
    }
  }

  // TODO: Remove these functions
  function removeEmployeeFromOrganisation() {
    if (selectedRowKeys.length === 0) {
      notification.warning({
        message: "Please select the employee who are needed to be removed",
      });
      return;
    }
    let modalProps = modalProperties;
    modalProps.delete.visible = true;
    modalProps.delete.loading = false;
    setModalProperties({ ...modalProps });
  }
  useEffect(() => {
    document.title = 'Employees | List';
  });
  const deleteEmployee = async () => {
    if (pageState.delete === 1) {
      resetModals();
    }
    let newPageState = pageState;
    newPageState.delete++;
    setPageState({ ...newPageState });
    const employeeIdsToGetRemoved = selectedRowKeys.map((val) => {
      return employeeDisplayData[val];
    });
    let deleteResponseData = [];
    for (const element of employeeIdsToGetRemoved) {
      let response = await removeFromOrg({
        id: element._id,
      });
      let tempResponseData = {
        name: element.displayName,
        success: response.data.success,
        message: response.data.success
          ? "Employee Successfully Removed"
          : "Unable to Remove Employee",
      };
      deleteResponseData.push(tempResponseData);
    }
    setResponseData(deleteResponseData);
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organiation User has deleted ${employeeIdsToGetRemoved.length} employees`,
      category: 16,
    });
    organisationReducer.updateEmployeeList({
      organisationId: user.organisationId,
    });
    setSelectedRowKeys([]);
  };

  const resetUpdatePayStructure = () => {
    setModalProperties({
      ...modalProperties,
      updateAllowances: {
        loading: false,
        visible: false,
      },
    });
    setSelectedEmployeeDeclaration(null);
    setSelectedEmployee("");
  };

  const getEmployeeDeclarationValue = async (id) => {
    const { financialYear } = checkFinancialYear();
    try {
      let res = await getEmployeeDeclaration(`${id}/${financialYear}`);
      if (!res.data) resetUpdatePayStructure();
      setSelectedEmployeeDeclaration(res.data);
      setModalProperties({
        ...modalProperties,
        updateAllowances: {
          visible: true,
          loading: false,
        },
      });
      setSelectedEmployee(id);
    } catch (e) {
      resetUpdatePayStructure();
    }
  };

  const employeeDetails = (id) => {
    let data = employeeList.find((val) => val._id === id);
    if (!data) {
      message.error("Sorry cannot able to find the employee");
      return;
    }
    setUpdateSelectedEmployee({ ...data });
    setModalProperties({
      ...modalProperties,
      employeeDetails: {
        visible: true,
        loading: false,
      },
    });
  };

  const employeePasswordDetails = (id) => {
    let data = employeeList.find((val) => val._id === id);
    if (!data) {
      message.error("Sorry cannot able to find the employee");
      return;
    }
    setUpdateSelectedEmployee({ ...data });
    setModalProperties({
      ...modalProperties,
      changePassword: {
        visible: true,
        loading: false,
      },
    });
  };

  const getActionMenu = (_id) => {
    return (

      <Menu>
        <Menu.Item
          key="1"
          onClick={(e) => {
            getEmployeeDeclarationValue(_id);
            e.preventDefault();
          }}
        >
          Update Pay Structure
        </Menu.Item>
        <Menu.Item key="2" onClick={() => employeeDetails(_id)}>
          Edit Credentials
        </Menu.Item>
        <Menu.Item key="3" onClick={() => employeePasswordDetails(_id)}>
          Change Password
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "Employee Id",
      dataIndex: "employeeId",
      defaultSortOrder: "ascend",
      fixed: "left",
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "displayName",
      defaultSortOrder: "ascend",
      width: 200,
      fixed: "left",
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
    },
    {
      title: "PAN",
      dataIndex: "PAN",
      defaultSortOrder: "ascend",
      width: 150,
    },
    {
      title: "Username",
      dataIndex: "username",
      defaultSortOrder: "ascend",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "primaryEmail",
      defaultSortOrder: "ascend",
      width: 250,
    },
    {
      title: "Group",
      dataIndex: "groupName",
      defaultSortOrder: "ascend",
      width: 100,
    },
    {
      title: "Joining Date",
      dataIndex: "dateOfJoining",
      defaultSortOrder: "ascend",
      width: 100,
      render: (dateOfJoining) => dateOfJoining,
    },
    {
      title: "Designation",
      dataIndex: "designationId",
      defaultSortOrder: "ascend",
      width: 150,
      render: (data) => {
        let des = designation.filter((val) => val.designationId === data);
        return <p>{des.length > 0 ? des[0].designationName : "N/A"}</p>;
      },
    },
    {
      title: "IP Address",
      dataIndex: "ip",
      width: 150,
    },
    {
      title: "Action",
      dataIndex: "_id",
      fixed: "right",
      width: 200,
      render: (_id) => {
        const actionMenu = getActionMenu(_id);
        return (
          <Space
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown
              trigger={["click"]}
              overlay={actionMenu}
              placement="bottomLeft"
            >
              <div onClick={(e) => e.stopPropagation()}>
                <img src={actionIcon} alt="actionIcon" />
              </div>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }

  const updateEmployeeList = async () => {
    let modifiedEmployeeList = employeeList.map((val, index) => {
      let group = getGroupName(val.groupId);
      return {
        key: index,
        ...val,
        dateOfJoining: new Date(
          val.currentOrganisationMeta.dateOfJoining
        ).toLocaleDateString(),
        employeeId: val.currentOrganisationMeta.employeeId,
        groupId: val.groupId ? val.groupId : "*-",
        groupName: val.groupId
          ? group.length > 0
            ? group[0].name
            : val.groupId === "intern"
              ? "Intern / Contractor"
              : "General"
          : "General",
      };
    });
    setEmployeeData([...modifiedEmployeeList]);
    setEmployeeDisplayData([...modifiedEmployeeList]);
    setLoading(false);
  };

  const getGroupName = (id) => {
    return groups.filter((val) => val.groupId === id);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="0" icon={<UserAddOutlined />}>
        Single Employee
      </Menu.Item>
      <Menu.Item key="1" icon={<UsergroupAddOutlined />}>
        Multiple Employees
      </Menu.Item>
    </Menu>
  );

  return (
    isTrial ? <TrialPage user={user} trialMessage={"Add, delete, update & access all employees' data at one place."} bgImage={eployeeListBgImage}
    /> :
    <div className="site-layout">
      <HeaderComponent
        show={false}
        showMoolCoins={true}
        icon={activeEmployees}
        name="Employees"
      />
      <Content className="app-main-content-container">
        <Content className="emoloyee-list-container">
          <Content className="header-container">
            <h3 className="font-weight-global-700 font-size-global-24">
              Employees ({employeeData.length}){" "}
            </h3>
            <div className="action-container">
              {selectedRowKeys.length > 0 && (
                <DeleteOutlined
                  className="employee-icons"
                  size="middle"
                  onClick={removeEmployeeFromOrganisation}
                />
              )}
              {/* TODO: Update the serach bar for exhustive use */}
              {/* <Input
                onChange={(e) => setSearchString(e.target.value.toLowerCase())}
                className="employee-list-search-field"
                size={"large"}
                placeholder="search"
                bordered={false}
              /> */}
              <p
                style={{
                  marginRight: 8,
                  marginBottom: 4,
                }}
                className="app-label-1"
              >
                Group
              </p>
              <Select
                defaultValue="all"
                style={{
                  width: "12rem",
                  marginRight: 20,
                  fontWeight: 600,
                }}
                onChange={(e) => setGroupId(e)}
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
              >
                <Option value="all">All</Option>
                <Option value="intern">Intern / Contracter</Option>

                {groups.map((group, i) => {
                  return (
                    <Option key={`group-${i}`} value={group.groupId}>
                      {group.name}
                    </Option>
                  );
                })}
              </Select>
              <div className="button-container">
                {/* <Button
                  onClick={uploadPayslipModal}
                  className="edit-button"
                  style={{ marginRight: 10 }}
                >
                  Previous data sync
                </Button> */}
                <Button
                  onClick={startRefresh}
                  className={`button-lib button-type-13 p-10`}
                  style={{ height: "2.5rem" }}
                >
                  <div className="flex justify-between align-center">
                    Refresh
                  </div>
                </Button>
                <Dropdown overlay={menu}>
                  <Button
                    className={`button-lib button-type-13 p-10`}
                    style={{ height: "2.5rem" }}
                  >
                    <div className="flex justify-between align-center">

                      Add
                      <img
                        style={{ height: 18, width: 18 }}
                        src={downoutined}
                        alt="sec-icon"
                        className="button-lib-icon m-left-10"
                      />
                    </div>
                  </Button>
                  {/* <NormalButton buttonText={"Add"} type={13} icon={plus} secondaryIcon={downoutined} /> */}
                </Dropdown>
              </div>
            </div>
          </Content>
          <Spin spinning={loading} delay={500} size={"large"}>
            <Table
              pagination={false}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={employeeDisplayData}
              scroll={{ x: 600, y: "65vh" }}
              rowClassName="cursor-pointer"
              onRow={(record) => {
                return {
                  onClick: () =>
                    history.push(`${paths.org.employee_details}/${record._id}`),
                };
              }}
            />
          </Spin>
          <AddEmployee
            modalProperties={modalProperties}
            pageState={pageState}
            setPageState={setPageState}
            user={user}
            resetModals={resetModals}
            setModalProperties={setModalProperties}
            organisationReducer={organisationReducer}
            groups={groups}
            location={location}
            department={department}
            designation={designation}
            status={status}
            updateOnboarding={updateOnboarding}
          />

          <DeleteConfirmation
            modalProperties={modalProperties}
            deleteEmployee={deleteEmployee}
            resetModals={resetModals}
            pageState={pageState}
            responseData={responseData}
          />

          <UpdatePayStructure
            isPercentageStructure={user.moolSettings?.isPercentageStructure}
            structureOfMool={user.moolSettings?.structureOfMool}
            setModalProperties={setModalProperties}
            modalProperties={modalProperties}
            resetUpdatePayStructure={resetUpdatePayStructure}
            selectedEmployee={selectedEmployee}
            user={user}
            groups={groups}
            selectedEmployeeDeclartion={selectedEmployeeDeclartion}
          />
          <UpdateEmployeeDetails
            setModalProperties={setModalProperties}
            modalProperties={modalProperties}
            resetModals={resetModals}
            user={updateSelectedEmployee}
            setUpdateSelectedEmployee={setUpdateSelectedEmployee}
            organisationReducer={organisationReducer}
          />
          <UpdateEmployeePassowrd
            setModalProperties={setModalProperties}
            modalProperties={modalProperties}
            resetModals={resetModals}
            user={updateSelectedEmployee}
            setUpdateSelectedEmployee={setUpdateSelectedEmployee}
            organisationReducer={organisationReducer}
          />
        </Content>
      </Content>
    </div>
  );
};

EmployeeList.propTypes = {
  user: PropTypes.object.isRequired,
  employeeList: PropTypes.array.isRequired,
};

const EmployeeListContainer = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    groups: state.organisation.groups,
    location: state.organisation.location,
    department: state.organisation.department,
    designation: state.organisation.designation,
    status: state.organisation.status,
    onboarding: state.organisation.onboarding,
    organisationSettings: state.organisation.organisationSettings,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(EmployeeList);

export default EmployeeListContainer;
