const SectionData = ({ sectionName, sectionType, sectionInfo }) => {
    return (
        <div className="section-data-container">
            {
                sectionType && <h3 className="section-name">{sectionType}</h3>
            }
            {
                sectionName && <h4 className="section-type">{sectionName}</h4>
            }
            {
                sectionInfo && <p className="section-info">{sectionInfo}</p>
            }
        </div>
    )
}

export default SectionData;