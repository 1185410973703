import { rootUrl, monthList } from "../../utils/config";
import moment from "moment";
import { NormalButton } from "../ComponentLibrary";
import { deleteLogo } from "../../assets/common/editAndDeleteLogos";
import CommonFunctionality from "./components/CommonFunctionality.js";
import { useState } from "react";
import {
    message,
    Select,
    Spin,
    notification,
    Popconfirm,

} from "antd";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
import DateComponent from "../InputLibrary/DateComponent/DateComponent"

let Option = Select;

const Accident = ({
    monthCloseFields,
    user,
    employeeList,
    organisation,
    startRefresh,
    deleteData,
    loading,
    financialYearList,
    name
}) => {
    const [isloading, setIsLoading] = useState(false);
    const [singleModalShow, setSingleModalShow] = useState(false);
    const [bulkModalShow, setBulkModalShow] = useState(false);

    const [fieldDetails, setFieldDetails] = useState({
        id: "",
        type: "credit",
        details: "Accident",
        subDetails: "Accident",
        amount: null,
        othertext: "",
        taxable: true,
        month: new Date().getMonth(),
        financialYear: "",
        meta: {
            causeOfInjury: "",
            insuranceNo: "",
            natureOfInjury: "",
            dateOfAccident: "",
            timeOfAccident: "",
            placeOfAccident: "",
            description: "",
            witnessName: ""
        }
    });

    const submitForm = async () => {
        if (!fieldDetails.id) {
            message.error("Please select an employee");
            return;
        }

        if (!fieldDetails.financialYear) {
            message.error("Please enter Financial Year");
            return;
        }
        if (!fieldDetails.month && fieldDetails.month !== 0) {
            message.error("Please enter month");
            return;
        }
        if (!fieldDetails.meta.causeOfInjury) {
            message.error("Please enter accident cause");
            return;
        }

        if (!fieldDetails.meta.insuranceNo) {
            message.error("Please enter insurance number");
            return;
        }

        if (!fieldDetails.meta.dateOfAccident) {
            message.error("Please enter date of accident");
            return;
        }
        if (!fieldDetails.meta.timeOfAccident) {
            message.error("Please enter time of accident");
            return;
        }
        if (!fieldDetails.meta.placeOfAccident) {
            message.error("Please enter place of accident");
            return;
        }
        if (!fieldDetails.meta.description) {
            message.error("Please enter description");
            return;
        }
        if (!fieldDetails.meta.witnessName) {
            message.error("Please enter witness name");
            return;
        }
        if (!fieldDetails.amount) {
            message.error("Please enter accident amount");
            return;
        }


        setIsLoading(true);
        let response = await organisation.addMonthClose({
            monthClose: {
                ...fieldDetails,
                organisationId: user.organisationId
            }
        });
        setIsLoading(false);
        if (response.data.success) {
            notification.success({
                message: "Successfully added the acciedent details"
            });
        } else {
            notification.error({ message: "Unable to add the acciedent details" });
        }
        organisation.updateMonthClose({ organisationId: user.organisationId });
        reset();
    };

    const reset = () => {
        setFieldDetails({
            ...{
                id: "",
                type: "credit",
                details: "Accident",
                subDetails: "Accident",
                amount: null,
                othertext: "",
                taxable: true,
                month: new Date().getMonth(),
                financialYear: "",
                meta: {
                    causeOfInjury: "",
                    insuranceNo: "",
                    natureOfInjury: "",
                    dateOfAccident: "",
                    timeOfAccident: "",
                    placeOfAccident: "",
                    description: "",
                    witnessName: ""
                }
            }
        });
        setSingleModalShow(false);
    }

    const columns = [
        {
            title: "Employee Name",
            dataIndex: ["user", "displayName"]
        },
        {
            title: "Employee Id",
            dataIndex: ["user", "currentOrganisationMeta", "employeeId"]
        },
        {
            title: "Added On",
            dataIndex: "createdAt",
            defaultSortOrder: "aescend",
            render: text =>
                <p>
                    {moment(text).format("ll")}
                </p>
        },
        {
            title: "Applicable On",
            dataIndex: "month",
            defaultSortOrder: "aescend",
            render: text =>
                <p>
                    {monthList[text]}
                </p>
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (amount, row) =>
                <p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
                    {row.type === "debit" ? "-" : "+"}
                    {amount}
                </p>
        },
        {
            title: "Action",
            dataIndex: "_id",
            render: (data) =>
                <Popconfirm title={"Are you sure ?"} onConfirm={() => deleteData(data)}>
                    <NormalButton
                        type={5}
                        icon={deleteLogo}
                    />
                </Popconfirm>
        }
        // {
        //     title: "Cause",
        //     dataIndex: ["meta", "causeOfInjury"],
        //     defaultSortOrder: "aescend",
        // },
        // {
        //     title: "Insurance number",
        //     dataIndex: ["meta", "insuranceNo"],
        //     defaultSortOrder: "aescend",
        // },
        // {
        //     title: "Nature of injury",
        //     dataIndex: ["meta", "natureOfInjury"],
        //     defaultSortOrder: "aescend",
        // },
        // {
        //     title: "Accident Date",
        //     dataIndex: ["meta", "dateOfAccident"],
        //     defaultSortOrder: "aescend",
        // },
        // {
        //     title: "Accident Time",
        //     dataIndex: ["meta", "timeOfAccident"],
        //     defaultSortOrder: "aescend",
        // },
        // {
        //     title: "Accident Place",
        //     dataIndex: ["meta", "placeOfAccident"],
        //     defaultSortOrder: "aescend",
        // },
        // {
        //     title: "Description",
        //     dataIndex: ["meta", "description"],
        //     defaultSortOrder: "aescend",
        // },
        // {
        //     title: "Witness",
        //     dataIndex: ["meta", "witnessName"],
        //     defaultSortOrder: "aescend",
        // },
    ];

    return (
      <CommonFunctionality
        columns={columns}
        user={user}
        employeeList={employeeList}
        monthCloseFields={monthCloseFields}
        organisation={organisation}
        deleteData={deleteData}
        loading={loading}
        singleModalShow={singleModalShow}
        bulkModalShow={bulkModalShow}
        setSingleModalShow={setSingleModalShow}
        setBulkModalShow={setBulkModalShow}
        name={name}
        startRefresh={startRefresh}
        reset={reset}
        downloadURL={`${rootUrl}/api/monthclose/generate/excel/accident/${user.organisationId}`}
        actionUrl={`${rootUrl}/api/organisation/validate/accident/${user.organisationId}`}
      >
        <div className="">
          <h2 className="font-size-global-24 font-weight-global-700">
            Add Accident
          </h2>
          <Spin spinning={isloading} size="large">
            <div className="org-anb-data-container p-top-15">
              <DropdownComponent
                className="general-ant-field-select"
                value={fieldDetails.id}
                labeltext={"Employee Name"}
                values={employeeList.map((item) => item._id)}
                options={employeeList.map((item) => item.displayName)}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    id: e,
                  });
                }}
                width={"100%"}
                bordered={false}
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
              >
                <Option value={""}>Select An Employee</Option>
                {employeeList.map((val, index) => {
                  return (
                    <Option key={`employees-${index}`} value={val._id}>
                      {val.displayName}
                    </Option>
                  );
                })}
              </DropdownComponent>
            </div>

            <div className="org-anb-data-container p-top-15">
              <DropdownComponent
                className="general-ant-field-select"
                value={fieldDetails.financialYear}
                values={financialYearList}
                options={financialYearList}
                labeltext="Financial Year"
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    financialYear: e,
                  });
                }}
                width={"100%"}
                bordered={false}
              >
                {financialYearList.map((val, index) => {
                  return (
                    <Option key={`fyear-${index}`} value={val}>
                      {val}
                    </Option>
                  );
                })}
              </DropdownComponent>
            </div>

            <div className="org-anb-data-container p-top-15">
              <DropdownComponent
                className="general-ant-field-select"
                value={fieldDetails.month}
                labeltext={"Month"}
                values={monthList.map((_val, index) => index)}
                options={monthList}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    month: e,
                  });
                }}
                width={"100%"}
                bordered={false}
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
                showSearch
              >
                {monthList.map((val, index) => {
                  return (
                    <Option key={`employees-${index}`} value={index}>
                      {val}
                    </Option>
                  );
                })}
              </DropdownComponent>
            </div>
            <div className="org-anb-data-container p-top-15">
              <InputFieldComponent
                labeltext={"Cause of Injury"}
                className="general-ant-field-input"
                value={fieldDetails.meta.causeOfInjury}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      causeOfInjury: e.target.value,
                    },
                  });
                }}
                width={"100%"}
              />
            </div>
            <div
              style={{ marginTop: "-2em" }}
              className="org-anb-data-container p-top-15"
            >
              <InputFieldComponent
                labeltext={"Insurance Number"}
                type={"number"}
                className="general-ant-field-input"
                value={fieldDetails.meta.insuranceNo}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      insuranceNo: e.target.value,
                    },
                  });
                }}
                width={"100%"}
              />
            </div>
            <div
              style={{ marginTop: "-2em" }}
              className="org-anb-data-container p-top-15"
            >
              <DateComponent
                width={"100%"}
                labelText={"Date of Accident"}
                className="general-ant-field-input"
                value={
                  fieldDetails.meta.dateOfAccident
                    ? moment(
                        new Date(fieldDetails.meta.dateOfAccident),
                        "YYYY-MM-DD"
                      )
                    : ""
                }
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      dateOfAccident: new Date(e).toDateString(),
                    },
                  });
                }}
                size={"large"}
              />
            </div>

            <div className="org-anb-data-container p-top-15">
              <InputFieldComponent
                labeltext={"Time of Accident"}
                className="general-ant-field-input"
                value={fieldDetails.meta.timeOfAccident}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      timeOfAccident: e.target.value,
                    },
                  });
                }}
                width={"100%"}
              />
            </div>
            <div
              style={{ marginTop: "-2em" }}
              className="org-anb-data-container p-top-15"
            >
              <InputFieldComponent
                labeltext={"Place of Accident"}
                className="general-ant-field-input"
                value={fieldDetails.meta.placeOfAccident}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      placeOfAccident: e.target.value,
                    },
                  });
                }}
                width={"100%"}
              />
            </div>

            <div
              style={{ marginTop: "-2em" }}
              className="org-anb-data-container p-top-15"
            >
              <InputFieldComponent
                labeltext={"Description of Accident"}
                className="general-ant-field-input"
                value={fieldDetails.meta.description}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      description: e.target.value,
                    },
                  });
                }}
                width={"100%"}
              />
            </div>

            <div
              style={{ marginTop: "-2em" }}
              className="org-anb-data-container p-top-15"
            >
              <InputFieldComponent
                labeltext={"Witness Name"}
                className="general-ant-field-input"
                value={fieldDetails.meta.witnessName}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    meta: {
                      ...fieldDetailsData.meta,
                      witnessName: e.target.value,
                    },
                  });
                }}
                width={"100%"}
              />
            </div>

            <div
              style={{ marginTop: "-2em" }}
              className="org-anb-data-container p-top-15"
            >
              <InputFieldComponent
                className="general-ant-field-input"
                type={"number"}
                labeltext={"Amount ( INR )"}
                width={"100%"}
                value={parseInt(fieldDetails.amount)}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    amount: isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value),
                  });
                }}
                placeholder="Amount"
                size={"large"}
              />
            </div>

            <div className="select-button-container">
              <NormalButton
                type={13}
                buttonText={"Add to the month end data"}
                block={"100%"}
                onClick={submitForm}
                margin={[30, 0]}
              />
            </div>
          </Spin>
        </div>
      </CommonFunctionality>
    );
};

export default Accident;
