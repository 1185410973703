import { useState } from "react";
import {
  Row,
  Col,
  Typography,
  notification,
  Spin,
} from "antd";
import { NormalButton } from "../../../ComponentLibrary";
import moment from "moment";
import { daysLeft } from "../../../../utils/config";
import Modal from "antd/lib/modal/Modal";
import { deleteSettlement } from "../../../../apis/organisation";

// const { TextArea } = Input;
const { Text } = Typography;

const SeparationInfo = ({ user, separations, CancelSeparation }) => {
  const [cancelSeparationModal, setCancelSeparationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const CancelFNF = async () => {
    setLoading(true);
    let res = await deleteSettlement(`${user._id}/${user.organisationId}`);
    if (res.data.success) {
      notification.success({
        message: "Separation has been canceled successfully",
      });
      CancelSeparation();
    } else {
      notification.error({ message: "Sorry unable to cancel the separation" });
    }
    setLoading(false);
  };

  return (
    // <div className="separation-active">
    //   <div className="header-component">
    //     <h3 className="app-heading-3">Separation Information</h3>
    //     <NormalButton
    //       buttonText={"Cancel Separation"}
    //       type={5}
    //       onClick={() => {
    //         setCancelSeparationModal(true);
    //       }}
    //     />
    //   </div>
    //   <div className="employe-info">
    //     <p className="employe-info-header">Status</p>
    //     {separations.status ? (
    //       <p className="">Approved</p>
    //     ) : (
    //       <p className="">In Review</p>
    //     )}
    //   </div>
    // </div>
    <div className="status-container">
      <div className="status-header-container">
        <p className="status-header">Separation Information</p>
        <NormalButton
          buttonText={"Cancel Separation"}
          type={5}
          onClick={() => {
            setCancelSeparationModal(true);
          }}
        />
      </div>
      <Modal
        closable={false}
        footer={null}
        visible={cancelSeparationModal}
        centered
        width={600}
      >
        <Spin spinning={loading} size="large">
          <p className="title-gray-4">
            Are you sure you want to withdraw your separation application and
            continue with the company?
          </p>
          <div className="flex justify-between align-center">
            <NormalButton
              buttonText={"No, Cancel"}
              type={5}
              onClick={() => {
                setCancelSeparationModal(false);
              }}
            />
            <NormalButton
              buttonText={"Yes, Continue"}
              type={2}
              onClick={CancelFNF}
            />
          </div>
        </Spin>
      </Modal>
      <div className="current-status">
        <p className="current-status-header">Status</p>
        <div className="status-display">
          {separations.status ? (
            <span className="application-accepted">Accepted by Manager</span>
          ) : (
            <span className="application-pending">Pending</span>
          )}
        </div>
      </div>
      <div className="current-status">
        <p className="current-status-header">Notice Period</p>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={7} lg={7}>
            <div className="current-status-container">
              <p className="current-status-label">Start Date</p>
              <p className="current-status-data">
                : {moment(separations.initiationDate).format("LL")}
              </p>
            </div>
            <div className="current-status-container">
              <p className="current-status-label">End Date</p>
              <p className="current-status-data">
                : {moment(separations.lastDayOfWorking).format("LL")}
              </p>
            </div>
            {/* <div className="current-status-container">
              <p className="current-status-label">Reason of leaving</p>
              <p className="current-status-data">: Starting Up</p>
            </div> */}
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <div className="days-left-container">
              <p className="days-left-label">Days Left</p>
              <p className="days-left-data">
                <Text type="danger">
                  {daysLeft(separations.lastDayOfWorking)}
                </Text>
              </p>
            </div>
          </Col>
        </Row>
      </div>
      {/* <div className="current-status">
        <p className="current-status-header">Comments</p>
        <div className="comments-container flex">
          <div>
            <img className="image" src={employeeLogo}>
            </img>
          </div>
          <div>
            <p className="name">Kirti Sharon (Manager)</p>
            <p className="date">12 MAR, 2021</p>
            <p className="comment">Good Luck For your Future Endeavours !! 😃</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SeparationInfo;
