import { Tabs } from "antd";
import SubmitFormContainer from "./generalComponents/SubmitForm";
import SectionData from "./generalComponents/sectionData.js";

const { TabPane } = Tabs;

const generalTabsData = [
  {
    key: "1",
    sectionFullData: {
      sectionName: "Section 80EE",
      sectionType: "Home Loan Interest (2016-2017)",
      sectinDescription:
        "Additional Interest on Housing loan borrowed as on 1st Apr 2016",
      sectionInfo:
        "You can claim an income tax deduction on home loan interest paid. But you can only claim for deduction up to Rs 50,000 per financial year under this Section. The deductions can be claimed until you have fully repaid the home loan.",
      notes:
        "The loan must have sanctioned between 1st April 2016 to 31st March 2017, the loan amount shoule be 35 Lakh or less and the property value should not exceed 50 Lakh and should be the first house of the owner",
      limit: "Rs. 50,000 / (2016- 2017) Financial year",
    },
    documents: [
      {
        declaration: "additionalInterestOnHousingLoanBorrowed1stApr2016",
        label:
          "Additional Interest on Housing loan borrowed as on 1st Apr 2016",
      },
    ],
    category: "80EE",
  },
  {
    key: "2",
    sectionFullData: {
      sectionName: "Section 80EEA",
      sectionType: "Home Loan Interest (2019-2021)",
      sectinDescription:
        "Additional Interest on Housing loan borrowed as on 1st Apr 2019",
      sectionInfo:
        "You can claim an income tax deduction on home loan interest paid. But you can only claim for deduction up to Rs 1,50,000 per financial year under this Section. The deductions can be claimed until you have fully repaid the home loan.",
      notes:
        "The loan must have sanctioned between 1st April 2019 to 31st March 2021, the loan amount shoule be 45 Lakh or less and should be the first house of the owner",
      limit: "Rs. 1,50,000 / (2019-2021) Financial year",
    },
    documents: [
      {
        declaration: "additionalInterestOnHousingLoanBorrowed1stApr2019",
        label:
          "Additional Interest on Housing loan borrowed as on 1st Apr 2019",
      },
    ],
    category: "80EEA",
  },
  {
    key: "3",
    sectionFullData: {
      sectionName: "Section 80EEB",
      sectionType: "Interest on Electric Vehicle",
      sectinDescription:
        "Interest on Electric Vehicle borrowed as on 1st Apr 2019",
      sectionInfo:
        "You can claim an income tax deduction on elctric vehicle loan interest paid. But you can only claim for deduction up to Rs 1,50,000 per financial year under this Section. The deductions can be claimed until you have fully repaid the home loan.",
      notes: "",
      limit: "Rs. 1,50,000 / (2019-2021) Financial year",
    },
    documents: [
      {
        declaration: "interestOnElectricVehicleBorrowed1stApr2019",
        label: "Interest on Electric Vehicle borrowed as on 1st Apr 2019",
      },
    ],
    category: "80EEB",
  },
  {
    key: "4",
    documents: [
      {
        declaration: "preConstructionHomeLoanInterest",
        label: "Home Loan Interest - Pre Construction Period",
      },
      {
        declaration: "currentYearHomeLoanInterest",
        label: "Home Loan Interest - Current Year",
      },
    ],
    sectionFullData: {
      sectionName: "Section 24B",
      sectionType: "Home Loan Interest (Multiple houses)",
      sectionInfo:
        "You can claim an income tax deduction on home loan interest paid. But you can only claim for deduction up to Rs 2,0,000 per financial year under this Section. The deductions can be claimed until you have fully repaid the home loan.",
      sectinDescription: "Income Tax exemption on interest of the home loan",
      notes: "",
      limit: "Rs. 2,00,000 / Financial year",
    },
    category: "24B",
  },
];

const Loans = ({ the12BBApproved, financialYear }) => {
  return (
    <div>
      <div className="blue-tabs-deduction-info-tabs-container">
        <Tabs tabPosition="left">
          {generalTabsData.map((data) => {
            return (
              <TabPane
                className="main-content-tabs"
                tab={
                  <SectionData
                    sectionName={data.sectionFullData.sectionName}
                    sectionType={data.sectionFullData.sectionType}
                    sectionInfo={data.sectionFullData.sectinDescription}
                  />
                }
                key={`tab-${data.key}`}
              >
                <SubmitFormContainer
                  key={`container-deduction-${data.key}`}
                  sectionDocuments={data.documents ? data.documents : []}
                  sectionFullData={data.sectionFullData}
                  subCatagory={data.subCategory}
                  Category={data.category}
                  financialYear={financialYear}
                  the12BBApproved={the12BBApproved}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default Loans;
