import React from "react";
import EmployeeInfo from "../../EmployeProfile/Components/EmployeInfoContainer";
import { Row, Col } from "antd";
import PersonalInformation from "../../EmployeProfile/Components/TabContents/personalInformation";
import BankDetails from "../../EmployeProfile/Components/TabContents/bankDetails";
import ContactInfo from "../../EmployeProfile/Components/TabContents/contactInfo";
import Credentials from "../../EmployeProfile/Components/TabContents/Credntials";
import { updateEmployeeOnBoarding } from "../../../apis/employee";
import { createLog } from "../../../apis/user";

const PersonalInformationInfo = ({
  getEmployeeData,
  user,
  familyDetail,
  address,
  bankInformation,
  contactInfo,
  credentials,
  statusName,
  department,
  location,
  onboarding,
  designation,
  orgInfo
}) => {
  const updateInfo = async () => {
    await createLog({
      name: orgInfo.displayName,
      userId: orgInfo._id,
      organisationId: user.organisationId,
      message: `Organiation User has updated the ${user.displayName}(${user.currentOrganisationMeta.employeeId})'s information`,
      category: 16,
    });
    await getEmployeeData(user._id);
  };
  const updateOnboarding = async (type) => {
    if (onboarding && Object.keys(onboarding).length > 0 && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployeeOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id,
      });
      await updateInfo();
    }
  };
  return (
    <div className="site-layout">
      <div>
        <Row gutter={[20, 20]}>
          <Col sm={24} xs={24} md={8} lg={8}>
            <EmployeeInfo
              getEmployeeData={getEmployeeData}
              user={user}
              statusName={statusName}
              department={department}
              location={location}
              designation={designation}
            />
          </Col>
          <Col sm={24} xs={24} md={16} lg={16}>
            <div className="personal-info-container">
              <PersonalInformation
                user={user}
                familyDetail={familyDetail}
                updateInfo={updateInfo}
                addresses={address}
                updateOnboarding={updateOnboarding}
              />
              <br />
              <BankDetails
                user={user}
                updateInfo={updateInfo}
                bankInformation={bankInformation}
                updateOnboarding={updateOnboarding}
              />
              <ContactInfo
                user={user}
                updateInfo={updateInfo}
                contactInfo={contactInfo}
                updateOnboarding={updateOnboarding}
              />
              <Credentials
                user={user}
                updateInfo={updateInfo}
                credentials={credentials}
                updateOnboarding={updateOnboarding}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PersonalInformationInfo;
