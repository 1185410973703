import React from "react";
import "./InputNumberComponent.less";
import { InputNumber } from "antd";

function InputNumberComponent({
  maxWidth,
  disabled,
  value,
  onChange,
  width,
  height,
  labeltext,
  display,
  margin,
  min,
  max,
  defaultValue,
  onPressEnter,
}) {
  let styles = {
    // borderWidth:inputnumbererror?'2px':`${value?'2px':'1px'}`,
    // borderColor: inputnumbererror?'#F05050':`${value?'#5E5F62':'#d9d9d9'}`,
    borderWidth: `${value ? "2px" : "1px"}`,
    borderColor: `${value ? "#5E5F62" : "#d9d9d9"}`,
    border: value ? "2px #5E5F62 solid" : "1px #d9d9d9 solid",
    width: width ? `${width}` : "288px",
    maxWidth: maxWidth ? `${maxWidth}` : "100%",
    height: height ? `${height}` : "50px",
    borderRadius: "8px",
  };
  let stylediv = {
    position: "relative",
    display: display ? display : "block",
    margin: margin
      ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`
      : "10px 10px",
  };

  return (
    <div className="inputnumberdiv" style={stylediv}>
      <InputNumber
        disabled={disabled}
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
        min={min}
        max={max}
        defaultValue={defaultValue}
        placeholder=" "
        style={styles}
        type="number"
      />
      <label
        className={`${value === null || value === "" ? "labelnormal" : "labelup"
          }`}
      >
        {labeltext ? labeltext : ""}
      </label>
      {/* <p className={inputnumbererror?'activeerrormessage':'errormessage'}>
      {(value || value>0)?`error message`
      :'This is a required field'}</p> */}
    </div>
  );
}

export default InputNumberComponent;
