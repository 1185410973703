import { Tabs, notification, Table, Drawer, Button, Radio } from "antd";
import { useEffect, useState } from "react";
import { HeaderComponent } from "../../../Common";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import { connect } from "react-redux";
import organisation from "../../../../redux/modules/organisation";
import ".././perqs.css";
import { NormalButton } from "../../../ComponentLibrary";
import { getTransferOfMovableAsset, postTransferOfMovableAsset } from "../../../../apis/perqs";
import { checkFinancialYear } from "../../../../utils/config";
import InputNumberComponent from "../../../InputLibrary/InputNumberComponent/InputNumberComponent";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";
import { getFullToolTip } from "../../../../utils/common";

const { TabPane } = Tabs;
const { month, financialYear } = checkFinancialYear();

const TransferOfMovableAsset = ({ organisationSettings, employeeList, user }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [type, setType] = useState(0);
  const [requestBody, setRequestBody] = useState({
    id: "",
    assetId: "",
    assetName: "",
    annualBifurcatedAmount: 0,
    isRecovery: true,
    isFullRecovery: false,
    recoveryAmount: 0,
  });


  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    setLoading(true)
    getTransferOfMovableAsset(user.organisationId).then((res) => {
      if (res.data.success) {
        setData(res.data.data);
      } else {
        setData([]);
      }
    });
    setLoading(false)
  }, [user.organisationId, refresh]);


  const updatePerqs = () => {
    setLoading(true)
    postTransferOfMovableAsset({
      ...requestBody,
      financialYear,
      month,
      type
    }).then((data) => {
      if (data.status === 200) {
        notification.success({
          message: `Data successfully updated`,
        });
        onClose();
      } else {
        notification.success({
          message: `Data couldn't get updated`,
        });
      }
    }).catch(err => {
      notification.error({ message: "Data couldn't get updated" });
    })
    setLoading(false)
    setRefresh(!refresh)
  };

  const columns = [
    {
      title: "ID",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
    },
    {
      title: "Name",
      dataIndex: ["user", "displayName"],
    },
    {
      title: "Asset Name",
      dataIndex: "assetName",
    },
    {
      title: "Amount",
      dataIndex: "annualBifurcatedAmount",
    },
  ];

  const employeeListDiv = (
    <>
      <div style={{ width: "100%" }}>
        <span style={{ width: "40%" }}>
          <span className="h5-bold-20">Employee List</span>
        </span>
        <span
          className="button-container"
          style={{ width: "60%", float: "right" }}
        >
          <span>
            <Button
              className={`button-lib button-type-13 p-10`}
              style={{ height: "2.5rem", float: "right" }}
              onClick={showDrawer}
            >
              <div className="flex justify-between align-center">Add</div>
            </Button>
          </span>
        </span>
      </div>

      <div className="section">
        <Table columns={columns} dataSource={data ? data[type] : []} rowKey="id" />
      </div>
    </>
  );

  const info = (
    <div className="section">
      <div className="section-top">
        <p className="subtitle3-med-16">
          Name of the employee who it’s been assigned to &nbsp;{" "}
        </p>
        <DropdownComponent
          className="general-ant-field-select"
          labeltext={"Employee Name"}
          options={employeeList.map((item) => item.displayName)}
          values={employeeList.map(item => item._id)}
          value={requestBody.id}
          onChange={(e) => {
            setRequestBody({
              ...requestBody,
              id: e,
            });
          }}
          width={"100%"}
          bordered={false}
          filterOption={(input, option) =>
            option.children &&
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          showSearch
        />
      </div>
      <div style={{ marginTop: "2rem" }}>
        <p className="subtitle3-med-16">What’s the model name of the asset</p>
        <InputFieldComponent
          value={requestBody.assetName}
          onChange={(e) => setRequestBody({
            ...requestBody,
            assetName: e.target.value
          })}
          labeltext={"Model no"}
        ></InputFieldComponent>
      </div>
      <div style={{ marginTop: "2rem 0" }}>
        <p className="subtitle3-med-16">
          Enter the depreciated value of the asset
        </p>
        <span>
          <InputNumberComponent
            margin={[10, 0, 0, 0]}
            value={requestBody.annualBifurcatedAmount}
            labeltext={"Amount"}
            onChange={(e) => setRequestBody({
              ...requestBody,
              annualBifurcatedAmount: e
            })}
          ></InputNumberComponent>
        </span>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <p className="subtitle3-med-16">
          How do you want to recover the amount from your employee ( per month) ?
        </p>
        <Radio.Group
          value={requestBody.isFullRecovery}
          onChange={(e) => {
            setRequestBody({
              ...requestBody,
              isFullRecovery: e.target.value,
              recoveryAmount: e.target.value ? Math.round(requestBody.annualBifurcatedAmount / 12) : 0
            })
          }}
          className="section-top"
        >
          <Radio value={true}>Fully {getFullToolTip(type === 0 ? "Depreciated value of asset [depreciation computed @50% on WDV for each completed year of usage]" : type === 1 ? "Depreciated value of asset [depreciation computed @20% on WDV for each completed year of usage]" : "Depreciated value of asset [depreciation computed @10% on SLM for each completed year of usage]")}</Radio>
          <Radio value={false}>Custom</Radio>
        </Radio.Group>
        {
          !requestBody.isFullRecovery &&
          <span>
            <InputNumberComponent
              margin={[10, 0, 0, 0]}
              labeltext={"Amount"}
              value={
                requestBody.recoveryAmount
              }
              disabled={requestBody.isFullRecovery}
              onChange={
                (e) => {
                  setRequestBody({
                    ...requestBody,
                    recoveryAmount: e
                  })
                }
              }
            ></InputNumberComponent>
          </span>
        }
      </div>
      <div style={{ marginTop: "2rem" }}>
        <p className="subtitle3-med-16">
          Do you want recover the amount from employee’s salary
        </p>
        <Radio.Group
          value={requestBody.isRecovery}
          onChange={(e) => setRequestBody({
            ...requestBody,
            isRecovery: e.target.value
          })}
          className="section-top"
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </div>
    </div>
  );

  const onChange = (e) => {
    setType(parseInt(e));
  };

  return (
    <section className="site-layout">
      <HeaderComponent name="Transfer of movable assets"/>
      <div className="app-main-content-container paper-wrapper">
        <Spinner spinning={loading}>
          <div>
            <h2 className="app-heading-2 font-weight-700">
              Transfer of movable assets
            </h2>

            <Tabs defaultActiveKey="0" onChange={onChange}>
              <TabPane tab="Computer and electronic items" key="0">
                {employeeListDiv}
              </TabPane>
              <TabPane tab="Motor cars" key="1">
                {employeeListDiv}
              </TabPane>
              <TabPane tab="Others" key="2">
                {employeeListDiv}
              </TabPane>
            </Tabs>
          </div>
          <Drawer placement="right" onClose={onClose} visible={open}>
            <div className="section">{info}</div>
            <div style={{ margin: "2.5rem" }}>
              <NormalButton
                type={13}
                onClick={() => {
                  updatePerqs();
                }}
                buttonText="Save"
              />
              <NormalButton type={5} onClick={onClose} buttonText="Cancel" />
            </div>
          </Drawer>
        </Spinner>
      </div>
    </section>
  );
};

const TransferOfMovableAssetPerqs = connect(
  (state) => ({
    user: state.auth.user,
    organisationSettings: state.organisation.organisationSettings,
    orgLoading: state.organisation.loading,
    employeeList: state.organisation.employeeList,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(TransferOfMovableAsset);

export default TransferOfMovableAssetPerqs;