import { Button, Row, Col } from "antd";
import CtcCard from "./components/ctcCard.js";
import CompanyInfoCard from "./components/companyInfoCard.js";
import TaxSheetDownloadCard from "./components/taxSheetDownloadCard.js";
import auth from "../../redux/modules/auth";
import employee from "../../redux/modules/employee";
import organisation from "../../redux/modules/organisation";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { HeaderComponent } from "../Common";
import Updates from "./components/Updates";
import Broadcast from "./components/Broadcast";
import activeDashboard from "../../assets/icons/activeIcons/activeDashboard.svg";
import { docs } from "../../utils/data.js";
import { empDocs } from "../../utils/data.js";
import { useState, useEffect } from "react";
import "../../styles/uploadSpace.less";
import { withRouter } from "react-router-dom";
import { Collapse } from 'antd';
import { Progress } from "antd";
import tick from "../../assets/icons/Tick.svg";
import rightArrow from "../../assets/employeeDashboard/right-arrow.svg";
import { HashLink } from 'react-router-hash-link';
import { categoryMappring } from "../../constants/routes";

const EmployeeDashboard = ({
  payslips,
  user,
  ctc,
  orgInfo,
  broadcastMessage,
  empOnboarding,
  authReducer,
  loading,
  history
}) => {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(0)
  const [empOnboard, setEmpOnboard] = useState(empOnboarding);
  const [completed, setCompleted] = useState(0);
  const [next, setNext] = useState([]);
  const renderComponent = (val) => {
    authReducer.updateCurrentTab(val)
    authReducer.updateSelectedCategory(categoryMappring[user.role][val])
  }
  const { Panel } = Collapse;
  const text = `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem 
  `;
  const items = ['allowances', 'hra', 'bankInformation', 'declarations', 'personalInformation', 'personalCredentials', 'address', 'profilePicture']
  useEffect(() => {
    if(empOnboarding) {
      setEmpOnboard(empOnboarding)
    }
  }, [empOnboarding])

  useEffect(() => {
    let itemsArr = []
    const items = ['allowances', 'hra', 'bankInformation', 'declarations', 'personalInformation', 'personalCredentials', 'address', 'profilePicture']
    items.forEach(val => {
        if (empOnboard && !empOnboard.fields[val]) {
          itemsArr.push(empDocs[val]["name"])
        }
      })
    setNext(itemsArr)
  }, [empOnboard])

useEffect(() => {
    if ((user.role === "employee" && empOnboarding)) {
      let totalSuccessPercent = 0;
      const items = ['allowances', 'hra', 'bankInformation', 'declarations', 'personalInformation', 'personalCredentials', 'address', 'profilePicture']
      items.forEach(val => {
        if (empOnboard.fields[val]) {
          totalSuccessPercent += 13;
        }
      })
      setCompleted(totalSuccessPercent);
    }
  }, [empOnboarding, empOnboard, user.role])
  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-lone-blocks
  {
    Object.keys(docs).forEach((doc) => {
      if (!docs[doc].completed && !show) {
        setShow(true);
      }
    })
  }
  useEffect(() => {
    document.title = 'Employee | Dashboard';
  });
  return (
    !loading && (
      <div className="site-layout">
        <HeaderComponent icon={activeDashboard} name="Dashboard" />
        <div className="app-main-content-container">
            {empOnboarding &&
              <div className="floating-div" style={{ paddingBottom: active && '1rem',  paddingTop: active && '1rem' }} >
                <Collapse 
                  expandIconPosition="right" 
                  showArrow={true} 
                  bordered={false} 
                  expandIcon={({isActive}) => isActive ? setActive(false) : setActive(true) }
                >
                  <Panel  
                    header={<div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 .9rem',
                      backgroundColor:  '#f2f4ff'
                    }}>
                        <div style={{ fontSize: '14px'  }}>Getting started with mool<span>
                        {!active
                        ? <img src={rightArrow} className='non-collapse' alt='arrow' /> : <img src={rightArrow} className='non-collapse-up' alt='arrow' />
                        }
                      </span></div>
                      <div>{!active && <Progress percent={completed} strokeColor={'#405cd2'} status="active" style={{ width: 240  }}/>}</div>
                    </div>}
                    key="1"   
                  > 
                    <Collapse 
                      expandIconPosition="right" 
                      bordered={false}
                      showArrow={false} 
                      accordion
                      onChange={(a) => {setOpen(a)}}
                    >
                      {items.map((item) => (
                        <Panel
                          showArrow={false}
                          className='panel-container'
                          style={{ borderBottom: '#fff', backgroundColor: '#fff' }}
                          header= {
                            empOnboard['fields'][item] ? 
                              <div className='justify-number-heading' >
                                <div 
                                  className="panel-num" 
                                  style={{ backgroundColor: '#10d3a0', borderColor: '#10d3a0', marginRight: '.7rem' }}
                                >
                                  <img src={tick} alt='tick' className="tick" /> 
                                </div>
                                <div>
                                
                                <div className="justify-div">
                                  <div className="font-heading" style={{ fontWeight: open === empDocs[item]['num'] && '700' }}>{empDocs[item]["name"]}</div>
                                  <div>
                                    {open === empDocs[item]['num'] ? 
                                    <img src={rightArrow} alt='arrow' className='non-collapse'  /> : 
                                    <img src={rightArrow} alt='arrow' className='collapsed' />}
                                  </div>
                                  </div>
                                </div>
                                </div>  : 
                              <div className='justify-number-heading' >
                                <div style= {{ marginRight: '.5rem', marginLeft: empDocs[item]['num'] === '3' && '-.25rem' , border: empDocs[item]['num'] === '3' && 'solid 3px #ffb6b6', borderRadius: '100%' , }}>
                                  <div className="panel-num" style={{ border: empDocs[item]['num'] === '3' && 'solid #f05050'}}></div>
                                </div>
                                <div className="justify-div">
                                  <div className="font-heading" style={{ fontWeight: open === empDocs[item]['num'] && '700' }}>{empDocs[item]["name"]}</div>
                                  <div>
                                    {open === empDocs[item]['num'] ? 
                                    <img src={rightArrow} alt='arrow' className='non-collapse'  /> : 
                                    <img src={rightArrow} alt='arrow' className='collapsed' />}
                                  </div>
                                </div> 
                              </div>
                            }
                          key = {empDocs[item]["num"]} 
                          expandIcon={({ isActive }) => isActive ? <img src={rightArrow} style={{margin: 0, padding: 0}} className='non-collapse' alt='arrow' /> : <img src={rightArrow} className='collapsed' alt='arrow' /> }
                        >
                          <div className='panel-div'>
                            <p>{text}</p>
                          </div>
                          {
                            !empOnboard["fields"][item] && 
                              <HashLink onClick={empOnboard["fields"][item] ? '' : (() => renderComponent(empDocs[item].subcategory)) } to={!empOnboard["fields"][item] && (empDocs[item]["path"])} style={{ cursor: empOnboard["fields"][item] ? 'default' : 'pointer' }} >
                                <Button
                                  type="primary"
                                  style={{ margin: '1rem', marginLeft: '4rem', borderRadius: '.2rem' }}
                                >Update</Button>
                              </HashLink>
                          }
                        </Panel>
                      ))}
                    </Collapse>
                  </Panel>
                </Collapse>
                <div className="below-inner-collapse">
                  {active && <Progress percent={completed} strokeColor={'#405cd2'} status="active" showInfo={false} />}
                  {active && <div style={{ fontWeight: '500' }}>{completed}% - Next, {next[0]}</div>}
                </div>
              <div>
            </div>
          </div>
          }

          <Row className="top-row" gutter={[20, 20]}>
            <Col xs={24} sm={24} md={16} lg={16}>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <CtcCard
                    ctc={ctc}
                    payslips={payslips}
                    authReducer={authReducer}
                    history={history}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <TaxSheetDownloadCard payslips={payslips} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Updates user={user} />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <CompanyInfoCard
                    image={orgInfo.image}
                    companyName={orgInfo.displayName}
                  />{" "}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Broadcast broadcastMessage={broadcastMessage} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    )
  );
};

EmployeeDashboard.propTypes = {
  user: PropTypes.object.isRequired,
};

const EmployeeDashboardContainer = connect(
  (state) => ({
    user: state.auth.user,
    empOnboarding: state.employee.onboarding,
    ctc: state.employee.ctc,
    payslips: state.employee.payslips,
    orgInfo: state.employee.orgInfo,
    financialYear: state.employee.financialYear,
    month: state.employee.month,
    broadcastMessage: state.employee.broadcastMessage,
    access: state.auth.access,
    loading: state.employee.loading,
    empLoading: state.employee.loading,
    isLoggedIn: state.auth.isLoggedIn,
    loginstate: state.auth.loginstate,
    onboarding: state.organisation.onboarding,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    employeeReducer: employee.getActions(dispatch),
    organisationReducer: organisation.getActions(dispatch),
  })
)(EmployeeDashboard);

export default withRouter(EmployeeDashboardContainer);
