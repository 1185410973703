import { message, Switch, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { getAllSpecifiedEmployees, saveSpecifiedEmployee } from '../../../apis/perqs'
import organisation from "../../../redux/modules/organisation"
import { HeaderComponent } from '../../Common'
import Spinner from '../../ComponentLibrary/Spinner/Spinner'
import TrialPage from '../../Common/TrialComponent'
import perqsBgImage from "../../../assets/dummyPages/perqsBgImage.png"

function SpecifiedEmployees({ user, isTrial }) {
    const [loading, setLoading] = useState(false);
    const [dataSet, setDataSet] = useState([]);
    const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        setLoading(true)
        getAllSpecifiedEmployees(user.organisationId).then(res => {
            if (res.data.success) {
                setDataSet(res.data.data)
            } else {
                setDataSet([])
            }
        }).catch(err => {
            setDataSet([])
        })
        setLoading(false)
    }, [user.organisationId, refresh])

    const onEmployeeChecked = (ID, data) => {
        setLoading(true);
        if (data.isSalaryMoreThan50K || data.holdsMoreThan20PercentageEquity || data.isDirector) {
            data.status = true;
        }
        saveSpecifiedEmployee({
            organisationID: user.organisationId,
            ID,
            ...data
        }).then(res => {
            if (res.data.success) {
                message.success("Data Saved Successfully!")
            } else {
                message.error("Error while Changing the status")
            }
            setRefresh(!refresh)
        }).catch(err => {
            message.error("Error while Changing the status")
        })
        setLoading(false)
    }

    const columns = [
        {
            title: "ID",
            dataIndex: "employeeId"
        },
        {
            title: "Employee Name",
            dataIndex: "displayName",
        },
        {
            title: "Is Employee Director",
            dataIndex: "id",
            render: (id, data) => {
                return <div>
                    <Switch checked={data.isDirector} onChange={(checked) => { data.isDirector = checked; onEmployeeChecked(id, data) }} />
                </div>
            }
        },
        {
            title: "Is Employee Holds More Than 20% of Company Equity",
            dataIndex: "id",
            render: (id, data) => {
                return <div>
                    <Switch checked={data.holdsMoreThan20PercentageEquity} onChange={(checked) => { data.holdsMoreThan20PercentageEquity = checked; onEmployeeChecked(id, data) }} />
                </div>
            }
        },
        {
            title: "Is Salary More Than 50,000/annum",
            dataIndex: "id",
            render: (id, data) => {
                return <div>
                    <Switch disabled checked={data.isSalaryMoreThan50K} />
                </div>
            }
        },
        {
            title: "Is Specified Employee",
            dataIndex: "id",
            render: (id, data) => {
                return <strong>
                    {data.isSalaryMoreThan50K || data.holdsMoreThan20PercentageEquity || data.isDirector ? "Yes" : "No"}
                </strong>
            }
        },
    ]
    return (
        isTrial ? <TrialPage user={user} trialMessage={"Explore the tax heaven for salaried employees without getting stressed about compliances"} bgImage={perqsBgImage}
        /> :
            <section className="site-layout">
                <HeaderComponent name="Specified Employees" />
                <div className="app-main-content-container paper-wrapper">
                    <Spinner spinning={loading}>
                        <div className="">
                            <div>
                                <h2 className="app-heading-2 font-weight-700">Configure Specified Employees</h2>
                            </div>
                            <div>
                                <Table
                                    columns={columns}
                                    dataSource={dataSet}
                                />
                            </div>

                        </div>
                    </Spinner>
                </div>
            </section>
    )
}

const SpecifiedEmployeesConfig = connect(
    state => ({
        user: state.auth.user,
        isTrial: state.auth.user.isTrial,

    }),
    dispatch => ({
        organisationReducer: organisation.getActions(dispatch)
    })
)(SpecifiedEmployees);

export default SpecifiedEmployeesConfig