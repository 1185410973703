/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Spin,
  message,
  Upload,
  notification,
  Table,
  Progress,
  Row,
  Col,
  Select
} from "antd";
import NormalButton from "../../components/ComponentLibrary/ButtonComponents/NormalButton"
import { updateAllowances } from "../../apis/organisation";
import { Content } from "antd/lib/layout/layout";
import config, { numformatter } from "../../utils/config";
import uploadLogo from "../../assets/icons/savefile.svg";
import { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createLog } from "../../apis/user";

const { Dragger } = Upload;
const { Option } = Select;

const Allowances = ({ user, groups, handleAction, commonColumn }) => {
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [allowancesData, setAllowancesData] = useState([]);
  const [displayAllowances, setDisplayAllowances] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [groupId, setGroupId] = useState("general");

  const heading = ["Upload the Document", "Verify and Update"];
  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/parser/allowances/${groupId}`,
    headers: {
      authorization: localStorage.getItem("mool_auth")
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        setPageState(1);
        setAllowancesData([...response]);
        let res = response.map((val, index) => {
          return { ...val, key: index + 1 };
        });
        setDisplayAllowances([...res]);
      }
    }
  };

  const reset = () => {
    setAllowancesData([]);
    setPageState(0);
    setCompleted(0);
    setGroupId("general")
  };

  const updateAllowance = async () => {
    if (allowancesData.length === 0) {
      message.error("Please Upload CSV File");
      return;
    }
    setIsLoading(true);
    let totalSuccess = 0;
    let { financialYear } = config.checkFinancialYear();
    for (const element of allowancesData) {
      let { allowances, customAllowances, id } = element
      await updateAllowances({
        financialYear,
        organisationId: user.organisationId,
        id,
        allowances,
        customAllowances
      });
      totalSuccess += 1;
      setCompleted(totalSuccess);
    }

    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organiation user updated ${allowancesData.length} number of allowances`,
      category: 15,
    });
    notification.success({ message: "Successfully Uploaded the allowances data" });

    setIsLoading(false);
    reset();
  };

  return (
    <div className="">
      {isLoading && pageState === 1 && (
        <Progress
          percent={Math.round((completed / allowancesData.length) * 100)}
          size="small"
          status="active"
        />
      )}
      <Spin size="large" spinning={isLoading}>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="flex align-center">
              {pageState !== 0 && (

                <ArrowLeftOutlined
                  onClick={() => {
                    let page = pageState;
                    page = page - 1;
                    setPageState(page);
                  }}
                  style={{ marginRight: 10 }}
                  className="arrow-left-button font-weight-700"
                />
              )}
              <h3 className="font-weight-global-700 font-size-global-24">{heading[pageState]}</h3>
            </div>

          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {pageState === 1 ? (
              <div className="flex w-full justify-end">
                <NormalButton
                  onClick={reset}
                  type={5}
                  className="cancel-button"
                  buttonText={"Cancel"}
                  style={{
                    border: "none",
                    boxShadow: "none",
                    marginRight: 10
                  }}
                />
                <NormalButton
                  onClick={updateAllowance}
                  className="submit-button"
                  type={13}
                  buttonText={"Upload Allowances"}
                  style={{
                    marginRight: "0px",
                    padding: "0px 24px"
                  }}
                />
              </div>
            ) : <Select
              className="general-input-field"
              onChange={(e) => setGroupId(e)}
              style={{ width: "100%", padding: "2px" }}
              bordered={false}
              size={"large"}
              filterOption={(input, option) =>
                option.children &&
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
              value={groupId}
              placeholder="Select Group"
            >
              <Option value="general">General</Option>
              {groups.map((val, index) => {
                return (
                  <Option
                    key={`group-select-${index}`}
                    value={val.groupId}
                  >
                    {val.name}
                  </Option>
                );
              })}
            </Select>}

          </Col>
        </Row>
        {pageState === 0 && (
          <UploadDocument platforms={{ ...uploadProps }} user={user} groupId={groupId} handleAction={handleAction} />
        )}
        {pageState === 1 && (
          <VerifyAndUpload displayAllowances={displayAllowances} commonColumn={commonColumn} />
        )}
      </Spin>
    </div>
  );
};

const UploadDocument = ({ platforms, user, groupId, handleAction }) => {
  return (
    <Content className="bulk-wrapper-left">
      <div>
        <p className="paper-title">
          Download Sample in{" "}
          <a
            className="download-employee-list"
            onClick={() =>
              handleAction(
                `${config.rootUrl}/api/generate/document/allowances/${user.organisationId}/${groupId}`,
                "Allowances.xlsx"
              )
            }
          >
            .csv format
          </a>{" "}
          file to ensure that you have the correct file ready to import.
        </p>
      </div>
      <div>
        <Dragger
          {...platforms}
          showUploadList={false}
          height={300}
          className="bulk-wrapper-dragger"
        >
          <p className="ant-upload-drag-icon">
            <img alt="" src={uploadLogo} height="80" width="80" />
          </p>

          <p className="ant-upload-text">Drag and drop CSV file</p>
          <p className="ant-upload-hint">
            or <u>Click Here</u> to upload
          </p>
        </Dragger>
      </div>
    </Content>
  );
};

const VerifyAndUpload = ({ displayAllowances, commonColumn }) => {
  return (
    <Table
      columns={commonColumn}
      dataSource={displayAllowances}
      expandable={{
        expandedRowRender: (record) => (
          <div>
            {
              Object.keys(record.allowances).length > 0 && <>
                <h4 className="app-heading-4 font-weight-700">Allowances</h4>
                {
                  Object.keys(record.allowances).map((data, _index) => <div className="flex justify-between align-center">
                    <p className="text-label-2 font-weight-700">
                      {record.allowances[data].label}
                    </p>
                    <p className="text-heading-2 font-weight-500">
                      INR {numformatter(record.allowances[data].actualExpense)}
                    </p>
                  </div>)
                }
              </>
            }

            {
              Object.keys(record.customAllowances).length > 0 && <>
                <h4 className="app-heading-4 font-weight-700">Custom Allowances</h4>
                {
                  Object.keys(record.customAllowances).map((data, _index) => <div className="flex justify-between align-center">
                    <p className="text-label-2 font-weight-700">
                      {record.customAllowances[data].label}
                    </p>
                    <p className="text-heading-2 font-weight-500">
                      INR {numformatter(record.customAllowances[data].actualExpense)}
                    </p>
                  </div>)
                }
              </>
            }
            {/* Zero State Handle */}

          </div>
        ),
        rowExpandable: (record) => record
      }}
      pagination={false}
      scroll={{ x: 600, y: "65vh" }}
    />
  );
};


export default Allowances;
