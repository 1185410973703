import config from "../../../utils/config";
import { Link } from "react-router-dom";
import ManageIcon from "../../../assets/ManageIcon.svg";
import { useEffect, useState } from "react";
import { categoryMappring, paths } from "../../../constants/routes";

const CtcCard = ({ ctc, payslips, authReducer, history }) => {
  const [salary, setSalary] = useState(0);
  const currentMonth = new Date().getMonth()
  useEffect(() => {
    if (payslips.length > 0) {
      let latestPayslip = payslips[payslips.length - 1];
      let sal =
        latestPayslip.user.regimeOptedStatus === 1
          ? parseInt(latestPayslip.finalPayslip.newRegime.takeHome)
          : parseInt(latestPayslip.finalPayslip.oldRegime.takeHome);
      setSalary(sal);
    }
  }, [payslips]);

  const renderComponent = () => {
    authReducer.updateCurrentTab("salary_manage")
    authReducer.updateSelectedCategory(categoryMappring.employee.salary_manage)
    history.push(paths.employee.salary_manage)
  }

  return (
    <div className="ctc-card-container border-left-blue">
      <div className="header-component">
        <h3 className="app-heading-3 font-weight-global-700">Salary Information</h3>
        <Link
          onClick={renderComponent}
          to={paths.employee.salary_manage}
          className="header-link"
        >
          <img src={ManageIcon} alt="Manage Icon" />
          Manage
        </Link>
      </div>
      <div className="info-container">
        <div className="ctc-data-container">
          <h3 className="card-data-heading">CTC</h3>
          <span className="amount">
            {ctc.costToCompany[currentMonth].ctc ? ctc.costToCompany[currentMonth].ctc : 0}
          </span>
          <span className="font-weight-global-700">{"  "} INR</span>
        </div>
        <div className="separator"></div>
        <div className="ctc-data-container">
          <h3 className="card-data-heading">Monthly Takehome</h3>
          <span className="amount">
            {config.numformatter(salary)}
          </span>
          <span className="font-weight-global-700">{" "}INR</span>
        </div>
      </div>
    </div>
  );
};

export default CtcCard;
