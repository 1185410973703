import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal, notification, Table } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLeaveLogsByStatus, orgAcceptRejectLeave } from "../../../../apis/organisation";
import { getDocumentURL } from "../../../../apis/user";
import {
  verifyNo,
  verifyYes
} from "../../../../assets/common/verifiedLogos";
import employeeLogo from "../../../../assets/employeeProfile/EmployeeLogo.svg";
import "../../../../styles/leavereq.less";
import { FileLogos, FileViewer, NormalButton } from "../../../ComponentLibrary";
import TextAreaComponent from "../../../InputLibrary/TextAreaComponent/TextAreaComponent";

import packageJson from "../../../../../package.json";
import attendanceBgImage from "../../../../assets/dummyPages/attendanceBgImage.png";
import TrialPage from "../../../Common/TrialComponent";
// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
// Plugins
import {
  defaultLayoutPlugin
} from "@react-pdf-viewer/default-layout";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];

const confirmHeading = [
  "", "Approve", "Reject"
]
const Request = ({ user, isTrial }) => {
  const [viewFile, setViewFile] = useState(false);
  const [viewFileDocumentProps, setviewFileDocumentProps] = useState({});
  const [
    viewFileDocumentVerifyProps,
    setviewFileDocumentVerifyProps,
  ] = useState({});
  const [loading, setLoading] = useState(false)
  const [leaveLogs, setLeaveLogs] = useState([]);
  const [docType, setDocType] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState([]);
  const [url, setUrl] = useState("");

  const renderToolbar = (Toolbar) => (React.createElement(Toolbar, null, (slots) => {
    const { CurrentPageInput, Download, EnterFullScreen, GoToNextPage, GoToPreviousPage, NumberOfPages, Zoom, ZoomIn, ZoomOut } = slots;
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <div style={{ padding: '0px 2px' }}>
          <ZoomOut />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <Zoom />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <ZoomIn />
        </div>
        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
          <GoToPreviousPage />
        </div>
        <div style={{ padding: '0px 2px', width: '4rem' }}>
          <CurrentPageInput />
        </div>
        <div style={{ padding: '0px 2px' }}>
          / <NumberOfPages />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <GoToNextPage />
        </div>
        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
          <EnterFullScreen />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <Download />
        </div>
      </div>
    );
  }));

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
  });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setViewFile(false);
    setDocType("");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setViewFile(false);
    setDocType("");
  };
  const imageCloseHandler = () => {
    setViewFile(false);
    setDocType("");
  }
  const getDocumentURLFromKey = async (key) => {
    let response = await getDocumentURL({ Key: key });
    setDocType("pdf")
    setUrl(response.data);
    showModal();
  }

  useEffect(() => {
    if (user.organisationId) {
      getLeaveLogsByStatus(`${user.organisationId}/0`).then(res => {
        setLeaveLogs(res.data);
      }).catch(err => {
        setLeaveLogs([]);
      })
    }
  }, [user]);

  let data = {}

  const getLeaveRecords = async () => {
    let res = await getLeaveLogsByStatus(`${user.organisationId}/0`);
    setLeaveLogs(res.data);
  };

  const leaveAction = async (leaveObj, status) => {
    Modal.destroyAll();
    setLoading(true)
    data = {
      status, id: leaveObj.user._id, leaveLogId: leaveObj.leaveLogId, reason: leaveObj.reason, comments: leaveObj.comments
    }
    let res = await orgAcceptRejectLeave(user.organisationId, data)
    if (res.data.success) {
      notification.success({
        message: `Successfully ${confirmHeading[status]}ed The Leave`,
      });
    } else {
      notification.error({
        message: `Sorry Unable To ${confirmHeading[status]} The Leave`,
      });
    }
    await getLeaveRecords();
    setLoading(false)
  }

  const confirmLeaveAction = (leaveObj, status) => {
    if (status > 0 && status < 3) {
      Modal.confirm({
        className: "requestLeave",
        title: `${confirmHeading[status]} Leave ?`,
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        okText: confirmHeading[status],
        okButtonProps: {
          type: "primary",
          danger: status === 2,
        },
        cancelText: "Cancel",
        onOk: () => leaveAction(leaveObj, status),
        content: (status === 2 && <>

          <h4>Reason for rejection</h4>
          <TextAreaComponent
            placeholder="Reason for rejection"
            rows={4}
            onChange={(e) => {
              leaveObj.comments = e.target.value
            }}
          ></TextAreaComponent>
        </>
        )
      })
    }
    else {
      notification.error({ message: "Sorry Unable To Process The Request" })
    }

  }

  const handleViewDocument = (fileProps) => {
    setViewFile(true);
    let key = fileProps.key;
    if (!key) {
      notification.error({ message: "Error while opening this document!" })
      return;
    }
    let tempFileProps = {
      documentKey: key,
      documentType: key.split(".").pop(),
      documentName: key.split("reimbursement-").pop(),
    };
    let tempViewFileDocumentVerifyProps = {
      showVerificationStatus: true,
      userCanVerify: true,
      fileIsVerified: true,
      verifyCallback: () => { console.log("Mool!, Rocks") },
      propsForVerifyCallback: {
        proofId: 1,
        index: 0,
        verified: true,
      },
    };
    setviewFileDocumentProps(tempFileProps);
    setviewFileDocumentVerifyProps(tempViewFileDocumentVerifyProps);
    if (tempFileProps.documentType === "pdf") {
      (async () => {
        await getDocumentURLFromKey(tempFileProps.documentKey);
      })();
    } else {
      setDocType("image");
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: ["user"],
      render: (usr) => {
        return (
          <div className="flex align-center">
            <img
              alt="img"
              className="rounded-image-1"
              src={usr.image ? usr.image : employeeLogo}
            />
            <p className="app-label-3" style={{ marginLeft: 10 }}>
              {usr.displayName}
            </p>
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: ["leaveType", "name"],
      key: "type",
      render: (data) => (
        <p>
          <b>Type:</b> {data}
        </p>
      ),
    },
    {
      title: " ",
      dataIndex: "duration",
      key: "duration",
      render: (data, rowData) => {
        return (
          <p style={{ padding: 3 }}>{data} Days</p>
        );
      },
    },
    {
      title: " ",
      dataIndex: "duration",
      key: "duration",
      render: (data, rowData) => {
        return (
          <div className="flex algin-center" style={{ width: "200px", overflowX: "auto" }}>
            {Object.keys(rowData.documents).length > 0 ? Object.values(rowData.documents).map((item, index) => {
              return (
                <NormalButton
                  key={item.key}
                  onClick={() => handleViewDocument(item)}
                  type={28}
                  buttonText={`#${index + 1}`}
                />
              )
            }) : "No Documents"}
          </div>
        );
      },
    },
    {
      title: " ",
      dataIndex: "reason",
      key: "reason",
      ellipsis: true,
    },
    {
      title: " ",
      dataIndex: "button",
      key: "button",
      render: (_data, row) => (
        <>
          <NormalButton onClick={() => confirmLeaveAction(row, 1)} type={13} buttonText="Accept" />
          <NormalButton onClick={() => confirmLeaveAction(row, 2)} type={9} buttonText="Decline" />
        </>
      ),
    },
  ];
  return (
    isTrial ? <TrialPage user={user} trialMessage={" Get access to India's first tax optimizing and salary personalization tool powering your payroll"} bgImage={attendanceBgImage}
    /> :
      <div className="request-container">
        <p className="font-size-global-24 font-weight-global-700">Leave Request</p>
        <Table loading={loading} pagination={leaveLogs.length <= 8 ? false : { pageSize: 8 }} columns={columns} dataSource={leaveLogs.reverse()} />
        {docType === "image" && <FileViewer
          visible={viewFile}
          onclose={imageCloseHandler}
          fileProps={viewFileDocumentProps}
          verifyProps={viewFileDocumentVerifyProps}
        />}
        {docType === "pdf" && <Modal
          title={
            <>
              <FileLogos type={1} /> {viewFileDocumentProps.documentName}
            </>
          }
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          footer={null}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {
              viewFileDocumentVerifyProps.showVerificationStatus
              && (viewFileDocumentVerifyProps.userCanVerify
                ? viewFileDocumentVerifyProps.fileIsVerified
                  ? <div className="verified-text-container"><img alt="" src={verifyYes} className="verified-logo-img"></img>Verified</div>
                  : <div >
                    <NormalButton
                      buttonText="Verify The Document"
                      type={1}
                      onClick={() => viewFileDocumentVerifyProps.verifyCallback(viewFileDocumentVerifyProps.propsForVerifyCallback)}
                    />
                  </div>
                : viewFileDocumentVerifyProps.fileIsVerified
                  ? <div className="verified-text-container"><img alt="" src={verifyYes} className="verified-logo-img"></img>Verified</div>
                  : <div className="verified-text-container"><img alt="" src={verifyNo} className="verified-logo-img"></img>Pending</div>)

            }
          </div>
          <div>
            {url ? (
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={url}
                  plugins={[defaultLayoutPluginInstance]}
                  renderError={console.log}
                />
              </Worker>
            ) : (
              "Document URL not found"
            )}
          </div>
        </Modal>}
      </div>
  );
};

const LeaveRequest = connect((state) => ({
  user: state.auth.user,
  isTrial: state.auth.user.isTrial,

}))(Request);

export default LeaveRequest;
