import { Table } from "antd";
import { useEffect, useState } from "react";
import { HeaderComponent } from "../../Common";
import Spinner from "../../ComponentLibrary/Spinner/Spinner";
import { connect } from "react-redux";
import organisation from "../../../redux/modules/organisation";
import "./perqs.css";
import TrialPage from "../../Common/TrialComponent";
import perqsBgImage from "../../../assets/dummyPages/perqsBgImage.png"

const Valuation = ({
  history,
  isTrial,
  user
}) => {
  const [loading] = useState(false);

  useEffect(() => {
  });


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 1000,
    },
  ];

  const data = [
    {
      key: "1",
      name: "Interest free and concessional loan",
      route: "interest_free_cocessional_loan",
    },
    {
      key: "2",
      name: "Free and concessional food and non alcoholic beverages",
      route: "free_cocessional_food",
    },
    {
      key: "3",
      name: "Gifts vouchers and tokens in lieu of such gifts",
      route: "gifts_vouchers_tokens",
    },
    {
      key: "4",
      name: "Credit card expenses",
      route: "credit_card_expenses",
    },
    {
      key: "5",
      name: "Club expenditure",
      route: "club_expenditure",
    },
    {
      key: "6",
      name: "Use of movable assets",
      route: "movable_assets",
    },
    {
      key: "9",
      name: "Transfer of movable assets",
      route: "transfer_movable_assets",
    },
    {
      key: "10",
      name: "Other benefits or amenities",
      route: "benefits_amenities",
    },
  ];

  useEffect(() => { });
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Explore the tax heaven for salaried employees without getting stressed about compliances"} bgImage={perqsBgImage}
    /> :
      <section className="site-layout">
        <HeaderComponent name="Other Fringe Benefits" />
        <div className="app-main-content-container paper-wrapper">
          <Spinner spinning={loading}>
            <div>
              <h2 className="app-heading-2 font-weight-700">Other Fringe Benefits</h2>
            </div>
            <Table
              columns={columns}
              dataSource={data}
              style={{ marginTop: "1.5rem" }}
              pagination={false}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => { history.push(`/org/perqs/valuation/${record.route}`) },
                };
              }}
            />
          </Spinner>
        </div>
      </section>
  );
};

const ValuationPerqs = connect(
  (state) => ({
    user: state.auth.user,
    organisationSettings: state.organisation.organisationSettings,
    orgLoading: state.organisation.loading,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(Valuation);

export default ValuationPerqs;