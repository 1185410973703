import moment from "moment";
import { monthList, numformatter } from "../../../utils/config";
import blueEmptyBox from "../../../assets/organisationDashboard/blueEmptyBox.svg"
import { useEffect, useState } from "react";
const { Table } = require("antd")

const PayrollProcessingHistory = ({ freezeResult }) => {
    let [tableData, setTableData] = useState([]);

    useEffect(() => {
        let data = freezeResult.map((val, index) => {
            return {
                ...val,
                key: index
            }
        })
        setTableData([...data])
    }, [freezeResult])

    const columns = [
        {
            title: "Payroll Process Date",
            dataIndex: "createdAt",
            width: "13%",
            render: createdAt => (<span>{moment(createdAt).format("DD-MM-YYYY")}</span>)
        },
        {
            title: "Month",
            dataIndex: "month",
            width: "13%",
            render: month => (<span>{monthList[month]}</span>)
        },
        {
            title: "Total Payslips Generated",
            dataIndex: "totalPayslipsGenerated",
            width: "13%",
        },
        {
            title: "Total Pay (INR)",
            dataIndex: "totalEarnings",
            width: "13%",
            render: data => <p>{numformatter(data)}</p>
        },
    ]

    return (
        <div className="app-white-card-1 p-36 payroll-summary m-bottom-36" >
            <h1 className="font-size-20 font-weight-700">Payroll Processing History</h1>
            <div style={{marginTop:"2em"}}>
                {
                    freezeResult.length > 0
                        ? <Table
                            pagination={false}
                            dataSource={tableData.reverse()}
                            columns={columns}
                            scroll={{ y: 300 }}
                            className="font-size-16 font-weight-700"
                            key={1}
                        />
                        : <div className="text-align-center py-36">
                            <img
                                alt="img"
                                src={blueEmptyBox}
                            />
                            <p className="title-gray-2 py-16">It looks empty out here Run Payroll to see history</p>
                        </div>
                }
            </div>
        </div>
    )
}

export default PayrollProcessingHistory;