import { useEffect, useState } from "react";
import { Layout, Steps } from "antd";
import Logo from "../../assets/common/logo.svg";
import { withRouter } from "react-router-dom";
import {
  ChangePassword,
  RegimeSelection,
  ManageAllowances
} from "./Stages";
import auth from "./../../redux/modules/auth";
import employee from "./../../redux/modules/employee";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NormalButton } from "../ComponentLibrary";
// import Form16Container from "../PreviousEmployment/component/Form16";
import { updatePreviousEmployment } from "../../apis/employee";
import { checkFinancialYear } from "../../utils/config";
import staticimg from "../../assets/orgloginflow/loginflow-constantImg.svg"
import { changeLoginState } from "../../apis/auth";
import PreviousEmployment from "../PreviousEmployment/PreviousEmployment";

const { Content, Sider } = Layout;

const { Step } = Steps;

const steps = [
  {
    title: 'Password',
  },
  {
    title: 'spend',
  },
  {
    title: 'Previous Employment',
  },
  {
    title: 'Agreement',
  },
];

const EmployeeLoginFLow = ({ user, authReducer, isCurrentFinancialYearJoinee, previousEmployement }) => {
  const [previouslyEmployed, setPreviouslyEmployed] = useState(false);
  const pageHeadings = [
    "Welcome! first things first",
    "How do you want to view your payslip",
    "Payslip",
    "Terms and Conditions",
  ];

  useEffect(() => {
    document.title = 'Employee | Login Flow';
  });

  const [pageNumber, setPageNumber] = useState(user.loginstate);

  const updateLoginState = async (pgn) => {
    await changeLoginState({ id: user._id, loginstate: pgn });
    if (pgn === 4) {
      authReducer.changeLoginState({ loginstate: pgn, role: user.role });
    }
  }


  const changePageState = async () => {
    if (pageNumber === 1 && !isCurrentFinancialYearJoinee) {
      await updateLoginState(pageNumber + 2);
      setPageNumber(pageNumber + 2);
    } else {
      await updateLoginState(pageNumber + 1);
      setPageNumber(pageNumber + 1);
    }
  };

  const updatePreviousEmployed = async () => {
    try {
      const { financialYear } = checkFinancialYear();
      await updatePreviousEmployment({
        id: user._id,
        financialYear,
        isPreviouslyEmployed: true
      })
      setPreviouslyEmployed(true);
    }
    catch (e) {

    }
  }

  useEffect(() => {
    if (previousEmployement) {
      setPreviouslyEmployed(previousEmployement.isPreviouslyEmployed)
    }
  }, [previousEmployement])

  return (
    <Layout>
      <Sider className="constantSider">
        <img src={Logo} className="moolLogo" alt="Mool Logo"></img>
        <img style={{ width: '35vw', height: '100vh', objectFit: 'cover' }} src={staticimg} alt="" />
      </Sider>
      <Layout>
        <Content className="first-login-container">
          {/* <div className="Logo-container-login-flow">
            <img src={Logo} alt="Mool Logo"></img>
          </div> */}
          <section className="stage-container">
            <Steps current={pageNumber} labelPlacement={"vertical"} className='loginflowsteps'>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <h3 className="stage-heading m-bottom-10">
              {pageHeadings[pageNumber]}
            </h3>
            {pageNumber === 0 ? (
              <ChangePassword
                user={user}
                changePageState={changePageState}
              />
            ) : pageNumber === 1 ? (
              <ManageAllowances
                user={user}
                changePageState={changePageState}
              />
            ) : pageNumber === 2 ? (
              <div>
                {!previouslyEmployed && <div>
                  <h1 className="page-heading m-bottom-10">Were you previously employed?</h1>
                  <div className="flex align-center justify-start">
                    <NormalButton buttonText={"Yes, I was employed"} type={13} margin={[0, 0]} onClick={updatePreviousEmployed} />
                    <NormalButton buttonText={"No, I wasn't employed"} type={5} onClick={() => changePageState()} />
                  </div>
                </div>}
                {previouslyEmployed && <div className="w-full">
                  <h1 className="page-heading">Your previous data</h1>
                  <PreviousEmployment id={user._id} changePageState={changePageState} type="login"/>
                  {/* <Form16Container id={user._id} changePageState={changePageState} type="login" /> */}
                </div>}
              </div>
              // <VerifyCTC user={user} changePageState={changePageState} />
            ) : (
              <RegimeSelection
                user={user}
                changePageState={changePageState}
              />
            )}
          </section>
        </Content>
      </Layout>
    </Layout>
  );
};

EmployeeLoginFLow.propTypes = {
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loginstate: PropTypes.bool.isRequired,
};

const EmployeeLoginFlowContainer = connect(
  (state) => ({
    user: state.auth.user,
    isLoggedIn: state.auth.isLoggedIn,
    loginstate: state.auth.loginstate,
    isCurrentFinancialYearJoinee: state.auth.isCurrentFinancialYearJoinee,
    // previousEmployement: state.employee.previousEmployement,
    previousEmployement: false,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    employeeReducer: employee.getActions(dispatch),
  })
)(EmployeeLoginFLow);

export default withRouter(EmployeeLoginFlowContainer);
