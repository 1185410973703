import { Tabs, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { HeaderComponent } from '../../Common';
import SectionData from './extras/sectionData';
import DocumentActionContainer from './extras/DocumentAction';
import { paths } from '../../../constants/routes';

const { TabPane } = Tabs;

const deducationTabsLists = [
    {
        key: "1",
        sectionName: "Allowances",
        Category: 'Allowances'
    },
    {
        key: "2",
        sectionName: "House Rent Allowances",
        Category: 'HRA'
    }
]

const Allowance = (props) => {
    const {userObject} = props.location.state

    return (
        <div className="site-layout">
            <HeaderComponent show={false} showMoolCoins={true}/>
            <div className="app-breadcrum-container">
                <Breadcrumb className="app-breadcrum" >
                    <Breadcrumb.Item>
                        <Link to={paths.org.employee}>Employee Management</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/org/employee_details/${userObject._id}`}>{userObject.displayName}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="app-breadcrum-active">Allowance Documents</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="manage-declaration-container">

                <Tabs tabPosition='left'>
                    {
                        deducationTabsLists.map((data, index) => {
                            return (
                                <TabPane key={`deduction-tab-pane-${index}`}
                                    tab={
                                        <SectionData
                                            sectionName={data.sectionName}
                                        />
                                    }
                                >
                                    <DocumentActionContainer
                                        Category={data.Category}
                                        sectionName={data.sectionName}
                                    />
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        </div >
    )
}

export default Allowance;