import { PoweroffOutlined, WarningOutlined } from '@ant-design/icons';
import { Checkbox, Drawer, message, Modal, Radio, Table } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { removeCustomAllowance, saveTermsAndConditions, updateSingleAllowance } from '../../../../apis/organisation';
import { deleteBlackLogo, editBlackLogo } from "../../../../assets/common/editAndDeleteBlackLogos";
import plusSymbol from "../../../../assets/icons/whitePlus.svg";
import organisation from "../../../../redux/modules/organisation";
import { HeaderComponent } from "../../../Common";
import { NormalButton } from "../../../ComponentLibrary";
import Spinner from '../../../ComponentLibrary/Spinner/Spinner';
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import InputNumberComponent from "../../../InputLibrary/InputNumberComponent/InputNumberComponent";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png";


function AllowanceNew({ user, organisationSettings, organisationReducer, isTrial }) {

  const [visible, setVisible] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPercentageStructure, setIsPercentageStructure] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [allowances, setAllowances] = useState({});
  const [customAllowances, setCustomAllowances] = useState({});
  const [currentRecord, setCurrentRecord] = useState({
    isTaxable: false,
    label: "",
    amount: 0,
    isPercentage: false,
    isLimit: true,
    limit: 0,
    displayName: "",
    isCustom: false,
    nonTaxableLimit: 0,
    isCalculatedAnnually: false,
    applicable: false,
    isCalculationLimit: false,
    preDefinedLimit: 0
  });

  useEffect(() => {
    if (user && user.moolSettings) {
      setIsPercentageStructure(user.moolSettings.isPercentageStructure ?? false);
    }
  }, [user])

  useEffect(() => {
    if (organisationSettings) {
      setAllowances(organisationSettings.allowances)
      setCustomAllowances(organisationSettings.customAllowances)
    }
  }, [organisationSettings])

  const warning = (data) => {
    Modal.warning({
      visible: isVisible,
      title: 'Warning Message From Mool!',
      content: 'Trying to Override Limit of pre-configured allowances by govt., Mool will not be liable for compliances if the configuration is set against the rules. Do you agree to continue?',
      okText: "I Accept",
      cancelText: "Cancel",
      closable: true,
      onOk: () => {
        updateAcceptTermsAndCondition(true);
        updateAllowanceData(data);
      },
      onCancel: () => {
        setIsVisible(false)
      },
    });
  };

  const updateAcceptTermsAndCondition = (value) => {
    saveTermsAndConditions({ organisationId: user.organisationId, keyName: "allowancesOverride", keyValue: value }).then(res => {
      if (res.data.success) {
        message.info("Status Recorded Successfully!")
      }
    })
    setIsVisible(false);
  }
  const deleteCustomAllowance = (value) => {
    setLoading(true)
    removeCustomAllowance(user.organisationId, value.key).then(res => {
      if (res.data.success) {
        setAllowances({ ...res.data.data.allowances });
        setCustomAllowances({ ...res.data.data.customAllowances });
        organisationReducer.fetchAndUpdateOrganisationSettings(user.organisationId);
        onCloseDrawer();
      } else {
        setAllowances({});
        setCustomAllowances({});
      }
    })
    setLoading(false)
  }



  function reset() {
    setCurrentRecord({
      isTaxable: false,
      label: "",
      amount: 0,
      isPercentage: false,
      isLimit: true,
      limit: 0,
      displayName: "",
      isCustom: false,
      nonTaxableLimit: 0,
      isCalculatedAnnually: false,
      isCalculationLimit: false,
      preDefinedLimit: 0
    })
  }

  const updateAllowanceData = (data) => {
    setLoading(true);
    updateSingleAllowance(user.organisationId, data).then(res => {
      if (res.data.success) {
        setAllowances({ ...res.data.data.allowances });
        setCustomAllowances({ ...res.data.data.customAllowances });
        organisationReducer.fetchAndUpdateOrganisationSettings(user.organisationId);
        onCloseDrawer();
      } else {
        setAllowances({});
        setCustomAllowances({});
      }
    }).catch(err => {
      setAllowances({});
      setCustomAllowances({});
    });
    setLoading(false);
  }

  const updateAllowanceStatus = (data, label) => {
    if (label !== "applicable") {
      if (!data.label) {
        message.error("Can not proceed without a name!");
        return;
      }
      if (data.isCalculationLimit && !data.limit) {
        message.error("Can not update without a limit!");
        return;
      }
    }
    let updatedAllowance = { ...data, nonTaxableLimit: data.isCalculatedAnnually ? data.nonTaxableLimit / 12 : data.nonTaxableLimit };
    if (label === "applicable") {
      updatedAllowance = {
        ...data,
        applicable: !data.applicable
      };
    } else {
      if (data.isOverridePermissionRequired && data.preDefinedLimit < data.limit) {
        warning(updatedAllowance);
        return;
      }
    }
    updateAllowanceData(updatedAllowance);
    setLoading(false);
  }


  const columns = [
    {
      title: 'Name',
      dataIndex: 'label',
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      key: 'name',
      width: '40%',
      render: (key, record) => {
        return <p>{record.displayName ? record.displayName : key}</p>
      }
    },
    {
      title: 'Type',
      dataIndex: 'isCustom',
      sorter: (a, b) => a.isCustom - b.isCustom,
      key: 'type',
      width: '10%',
      render: (key) => {
        return <p>{key ? "Custom" : "General"}</p>
      }
    },
    {
      title: 'Category',
      dataIndex: 'isTaxable',
      sorter: (a, b) => a.isTaxable - b.isTaxable,
      key: 'isTaxable',
      width: '20%',
      render: (key) => {
        return <p>{key ? "Fully Taxable" : "Non-Taxable"}</p>
      },
    },
    {
      title: 'Calculation',
      dataIndex: 'limit',
      sorter: (a, b) => a.limit - b.limit,
      key: 'key',
      width: '20%',
      render: (key, record) => {
        return <p><span>{record.limit ?? 0}</span>{record.isPercentage ? "% of CTC" : " per Month"}</p>
      }
    },
    {
      title: 'State',
      dataIndex: 'applicable',
      filters: [{ text: <span>Active</span>, value: true }, { text: <span>Inactive</span>, value: false }],
      onFilter: (value, record) => record.applicable === value,
      filterSearch: true,
      render: (key, record) => {
        return (
          <>
            <h3 style={key ? { color: 'green', cursor: "pointer" } : { color: 'grey', cursor: "pointer" }} onClick={() => { updateAllowanceStatus(record, "applicable") }}>
              <PoweroffOutlined style={key ? {} : { transform: "rotate(180deg)" }} />{key ? "  Active" : "  Inactive"}
            </h3>
          </>
        )
      },
      width: '10%',
    },
    {
      title: '',
      key: 'edit',
      width: '10%',
      render: (record) => {
        return (
          <>
            <NormalButton
              type={5}
              icon={editBlackLogo}
              margin={[3, 0]}
              onClick={() => { showDrawer(); setEditClicked(true); setCurrentRecord({ ...currentRecord, ...record, nonTaxableLimit: record.isCalculatedAnnually ? record.nonTaxableLimit * 12 : record.nonTaxableLimit, preDefinedLimit: record.isOverridePermissionRequired ? record.limit : 0 }); }}
            />
          </>
        )
      }
    },
    {
      title: '',
      key: 'delete',
      width: '10%',
      render: (record) => {
        return (
          <>
            {record.isCustom && <NormalButton
              type={5}
              onClick={() => { deleteCustomAllowance(record) }}
              icon={deleteBlackLogo}
              margin={[3, 0]}
            />}
          </>
        )
      }
    },
  ];

  const getWarningNote = (allowanceName, amount) => {
    return (
      <div
        className="flex justify-between align-center"
        style={{
          backgroundColor: "#d7b47224",
          color: "#da9711",
          marginTop: "20px",
          borderRadius: "5px",
          padding: "10px"
        }}
      >
        <WarningOutlined style={{ fontSize: "1.5rem", marginRight: "1rem" }} />
        <p>
          Note: {allowanceName} allowance has govt declared rule, which is {amount} per month. Overriding limit is against the rules.
        </p>
      </div>
    )
  }


  const showDrawer = () => {
    setVisible(true);
    setCurrentRecord({
      ...currentRecord,
      isCustom: true,
    })
  };

  const onCloseDrawer = () => {
    setEditClicked(false);
    reset();
    setVisible(false);
  };



  return (
    isTrial ? <TrialPage user={user} trialMessage={"Stay assured. Mool handles any compliance related changes in the law for you."} bgImage={configurationsBgImage}
    /> :
      <div className="site-layout">

        <HeaderComponent
          show={false}
          name="Allowances Management"
        />

        <div className="app-main-content-container paper-wrapper">
          <div className="allowance-heading-row">
            <div className="allowance-heading-div">
              <h1 className="h4-bold-30">Allowances</h1>
              <p className="tab-subtitle-1">Select all the components that you want your employee to benefit from</p>
            </div>
            <div className="allowance-searchbar-flex">
              <NormalButton
                onClick={showDrawer}
                icon={plusSymbol}
                className="allowance-button"
                buttonText="Add New"
                type={13}
              />
            </div>
          </div>
          <Table dataSource={Object.values(allowances).concat(Object.values(customAllowances)) ?? []} columns={columns} pagination="none" />

          <Drawer
            title={editClicked ? "Edit allowance" : "Add Custom Allowance"}
            placement="right"
            onClose={onCloseDrawer}
            visible={visible}
            width={"450px"}
          >
            <Spinner spinning={loading}>
              <div className="allowance-radio-div">
                <InputFieldComponent
                  labeltext={editClicked ? "Allowance Name" : "Name"}
                  value={currentRecord.label}
                  width={"400px"}
                  margin={[18, 0, 5, 0]}
                  onChange={(e) => {
                    setCurrentRecord({
                      ...currentRecord,
                      label: e.target.value
                    })
                  }}
                  disabled={editClicked}
                />
                {
                  editClicked && !currentRecord.isCustom &&
                  <InputFieldComponent
                    labeltext={"Display Name"}
                    onChange={(e) => {
                      setCurrentRecord({
                        ...currentRecord,
                        displayName: e.target.value
                      })
                    }}
                    value={currentRecord.displayName}
                    width={"400px"}
                  />
                }
              </div>

              {
                (currentRecord.isCustom) &&
                <>
                  <div style={{ margin: "0 30px 30px" }}>
                    <p className="subtitle3-med-16">How do you want to recover the amount from your employee (per month) ?</p>
                    <Radio.Group
                      onChange={(e) => {
                        setCurrentRecord({ ...currentRecord, isTaxable: e.target.value })
                      }}
                      value={currentRecord.isTaxable}
                      className="section-top">
                      <Radio value={true}>Fully Taxable
                      </Radio>
                      <Radio value={false}>Non-taxable</Radio>
                    </Radio.Group>
                  </div>
                  {
                    !currentRecord.isTaxable &&
                    <div style={{ margin: "0 30px 30px" }}>
                      <p className="subtitle3-med-16">How does the non-taxable look like ?</p>
                      <Radio.Group
                        onChange={(e) => {
                          setCurrentRecord({ ...currentRecord, isLimit: e.target.value })
                        }}
                        value={currentRecord.isLimit}
                        className="section-top">
                        <Radio value={false}>Got no-limit
                        </Radio>
                        <Radio value={true}>Has Limit</Radio>
                      </Radio.Group>
                      {
                        currentRecord.isLimit &&
                        <div className='limit-amount-box'>
                          <InputNumberComponent
                            value={currentRecord.nonTaxableLimit}
                            width={"100%"}
                            onChange={(e) => {
                              setCurrentRecord({
                                ...currentRecord,
                                nonTaxableLimit: parseInt(e)
                              })
                            }}
                            labeltext={"Amount"}
                            margin={[20, 0, 20, 0]}
                          />
                          <Checkbox
                            onChange={(e) => {
                              setCurrentRecord({
                                ...currentRecord,
                                isCalculatedAnnually: e.target.checked
                              })
                            }}
                            checked={currentRecord.isCalculatedAnnually}
                            className="checkbox"
                          >
                            Calculated annually
                          </Checkbox>
                        </div>
                      }
                    </div>
                  }

                </>
              }
              <div className="allowance-radio-div">
                <h3
                  className="font-size-16"
                  style={{ fontWeight: "bold" }}
                >
                  Calculation Type
                </h3>
                <Radio.Group
                  className="section-top allowance-radio"
                  name="isCalculationLimit"
                  value={currentRecord.isCalculationLimit}
                  onChange={(e) => { setCurrentRecord({ ...currentRecord, isCalculationLimit: e.target.value, limit: 0 }) }}
                >
                  <Radio value={false}>Got No Limit</Radio>
                  <Radio value={true}>Has Limit</Radio>
                </Radio.Group>
              </div>
              <div className='limit-amount-box'>
                {
                  currentRecord.isCalculationLimit &&
                  <div style={{ margin: "10px 30px" }}>
                    {
                      isPercentageStructure &&
                      <Radio.Group
                        name="isPercentage"
                        value={currentRecord.isPercentage}
                        onChange={(e) => { setCurrentRecord({ ...currentRecord, isPercentage: e.target.value }) }}
                      >
                        <Radio value={true}>% of CTC</Radio>
                        <Radio value={false}>Flat Amount</Radio>
                      </Radio.Group>
                    }
                    <InputNumberComponent
                      value={currentRecord.limit}
                      width={"100%"}
                      onChange={(e) => { setCurrentRecord({ ...currentRecord, limit: parseInt(e) }) }}
                      labeltext={currentRecord.isPercentage ? "Enter Percentage" : "Enter Amount"}
                      margin={[20, 0, 20, 0]}
                    />
                    {
                      currentRecord.isOverridePermissionRequired && getWarningNote(currentRecord.label, currentRecord.preDefinedLimit ?? currentRecord.limit)
                    }
                  </div>
                }
              </div>
              <div className="save-allowance-div" >
                <NormalButton
                  onClick={() => {
                    updateAllowanceStatus(currentRecord, "update")
                  }}
                  buttonText="Save data"
                  type={13}
                />
                <NormalButton
                  onClick={onCloseDrawer}
                  buttonText="Cancel"
                  type={5}
                />
              </div>
            </Spinner>
          </Drawer>


        </div>
      </div >
  )
}



const AllowancesContainer2 = connect(
  (state) => ({
    user: state.auth.user,
    organisationSettings: state.organisation.organisationSettings,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(AllowanceNew);

export default AllowancesContainer2;