import { HeaderComponent } from "../../Common";
import { Tabs } from 'antd';
import Request from "./Component/Request";
import History from "./Component/History";
import { useEffect } from "react";

const { TabPane } = Tabs;

const OrganisationLeave = () => {
    useEffect(() => {
        document.title = 'Attendance | Leave Applications';
      });
    return (
        <div className="site-layout">
            <HeaderComponent show={false} name="Manager Leave Management" />
            <div className="app-main-content-container paper-wrapper ">
                <div className="blue-tabs-container ">
                    <Tabs
                        tabPosition={"left"}
                    >
                        <TabPane tab="Request" key="1">
                            <Request />
                        </TabPane>
                        <TabPane tab="History" key="2">
                            <History />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default OrganisationLeave