import { Button, message, Modal, notification, Radio, Table } from "antd";
import { NormalButton } from "../../../ComponentLibrary";
import { deleteOrgLeave, orgCreateLeave } from "../../../../apis/organisation";
import {
  editLogo,
  deleteLogo,
} from "../../../../assets/common/editAndDeleteLogos";
import { useState, useEffect } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { leaveCreditFrequency } from "../../../../utils/config";
import plusSymbol from "../../../../assets/common/plusSymbol.svg"
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import { createLog } from "../../../../apis/user";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";


const encashableOption = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const carryForWardedOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const Leave = ({ user, leaveList, organisationReducer }) => {
  const [showAddLeaveModal, setShowAddLeaveModal] = useState(false);
  const [editFunction, setEditFunction] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [leaveObj, setLeaveObj] = useState({
    name: "",
    description: "",
    balance: 0,
    encashable: false,
    carryForwarded: true,
    creditFrequency: 0,
  });
  const [isRefresh, setIsRefresh] = useState(false);

  const addLeaveOpperation = async () => {
    if (!leaveObj.name) {
      message.error("Please mention the leave name");
      return;
    }
    if (leaveObj.balance <= 0) {
      message.error("Please Update the leave balance");
      return;
    }

    // TODO: Need to add an alert if other parameter has been set by the user or not
    setIsLoading(true);
    let res = await orgCreateLeave({
      organisationId: user.organisationId,
      leaveObj,
    });
    if (res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Organisaiton user has created a leave`,
        category: 6,
      });
      notification.success({
        message: "Successfully created the leave",
      });
    } else {
      notification.error({
        message: "Sorry Unable to create the leave",
      });
    }
    organisationReducer.updateLeaveData(user.organisationId);
    setIsLoading(false);
    resetAddLeaveObject();
  };

  useEffect(() => {
    organisationReducer.updateLeaveData(user.organisationId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);
  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }

  const deleteLeaves = async (leaveId) => {
    setIsLoading(true);
    Modal.destroyAll();
    let res = await deleteOrgLeave(`${user.organisationId}/${leaveId}`);
    if (res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Organisaiton user has deleted a leave`,
        category: 6,
      });
      notification.success({
        message: "Successfully deleted the leave",
      });
    } else {
      notification.error({
        message: "Sorry Unable to delete the leave",
      });
    }
    organisationReducer.updateLeaveData(user.organisationId);
    setIsLoading(false);
  };

  const rows = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Frequency",
      dataIndex: "creditFrequency",
      key: "creditFrequency",
      render: (data) => <div>{leaveCreditFrequency[data]}</div>,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance) => <div>{balance === 0 ? "No count" : balance}</div>,
    },
    {
      title: "Carry forwarded",
      dataIndex: "carryForwarded",
      key: "carryForwarded",
      render: (carryForwarded) => <div>{carryForwarded ? "Yes" : "No"}</div>,
    },
    {
      title: "Encashable",
      dataIndex: "encashable",
      key: "encashable",
      render: (encashable) => <div>{encashable ? "Yes" : "No"}</div>,
    },
    {
      title: "",
      dataIndex: "leaveId",
      key: "action",
      render: (data, row) => (
        <div className="flex justify-end align-center">
          <NormalButton
            icon={editLogo}
            type={5}
            onClick={() => {
              setEditFunction(true);
              setLeaveObj({
                ...row,
              });
              setShowAddLeaveModal(true);
            }}
          />
          <NormalButton
            icon={deleteLogo}
            type={5}
            margin={[0, 10]}
            onClick={() => {
              confirmDelete(data);
            }}
          />
        </div>
      ),
    },
  ];
  const confirmDelete = (leaveId) => {
    Modal.confirm({
      title: "Comfirm Delete",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete the leave ?",
      okText: "Delete",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      cancelText: "Cancel",
      onOk: () => deleteLeaves(leaveId),
    });
  };

  const resetAddLeaveObject = () => {
    setLeaveObj({
      name: "",
      description: "",
      balance: 0,
      encashable: false,
      carryForwarded: true,
      creditFrequency: 1,
    });
    setShowAddLeaveModal(false);
    setEditFunction(false);
  };

  return (
    <Spinner spinning={isLoading}>
      <div>
        <h1 className="font-size-global-24 font-weight-global-700">Leave Settings</h1>
        <p className="title-gray-5 font-weight-global-500">
          Define and create the type of leaves for your organization{" "}
        </p>
      </div>

      <div className="flex justify-between align-center">
        <div>
          <h1 className="app-heading-4 my-4 font-size-global-18 font-weight-global-700">Records ({leaveList.length})</h1>
        </div>
        <div className="flex align-center">
          <span style={{ float: "right" }}>
            <Button
              onClick={startRefresh}
              className={`button-lib button-type-13 p-10`}
              style={{ height: "2.5rem" }}
            >
              <div className="flex justify-between align-center">
                Refresh
              </div>
            </Button>
          </span>
          <NormalButton
            buttonText="Add New"
            icon={plusSymbol}
            type={13}
            onClick={() => setShowAddLeaveModal(true)}
          />
        </div>
      </div>
      <div>
        <Table
          dataSource={leaveList.reverse()}
          columns={rows}
          pagination={{ pageSize: 8 }}
        />
      </div>
      <Modal
        visible={showAddLeaveModal}
        onCancel={resetAddLeaveObject}
        closable={false}
        centered={true}
        width={400}
        footer={false}
        style={{
          borderRadius: 10,
        }}
        className="add-new-leave-modal"
      >
        <Spinner spinning={isLoading}>
          <h1 className="font-size-global-24 font-weight-global-700 modal-margin-left-global-heading">Add New Leave</h1>
          <div style={{ display: "flex", justifyContent: "center" }} className="app-input-field-container-1">
            <InputFieldComponent
              labeltext={"Leave Name"}
              value={leaveObj.name}
              onChange={(e) => {
                setLeaveObj({
                  ...leaveObj,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div className="app-input-field-container-1 modal-alignment-center-global modal-margin-top-reduce-global-3">
            <InputFieldComponent
              labeltext={"Leave Description"}
              value={leaveObj.description}
              onChange={(e) => {
                setLeaveObj({
                  ...leaveObj,
                  description: e.target.value,
                });
              }}
            />
          </div>
          <div className="app-input-field-container-1 modal-alignment-center-global modal-margin-top-reduce-global-3">
            <InputFieldComponent
              value={leaveObj.balance}
              labeltext={"Leave Balance"}
              onChange={(e) => {
                setLeaveObj({
                  ...leaveObj,
                  balance: isNaN(parseInt(e.target.value))
                    ? 0
                    : parseInt(e.target.value),
                });
              }}
            />
          </div>
          <div className="app-input-field-container-1 modal-alignment-center-global modal-margin-top-reduce-global-3">
            <DropdownComponent
              className="block app-select m-top-4"
              value={leaveCreditFrequency[leaveObj.creditFrequency]}
              labeltext={"Leave credit Frequency"}
              values={Object.keys(leaveCreditFrequency)}
              options={Object.values(leaveCreditFrequency)}
              onChange={(e) => {
                setLeaveObj({
                  ...leaveObj,
                  creditFrequency: e,
                });
              }}
            />
          </div>
          <div className="app-input-field-container-1 modal-margin-left-global-2 ">
            <p style={{ display: "flex", justifyContent: "start", marginBottom: "10px" }} className="font-size-global-18 font-weight-global-700" >Are Leaves Encashable ?</p>
            <Radio.Group
              style={{ display: "flex", justifyContent: "start", marginTop: "-2em" }}
              options={encashableOption}
              onChange={(e) =>
                setLeaveObj({
                  ...leaveObj,
                  encashable: e.target.value,
                })
              }
              value={leaveObj.encashable}
            />
          </div>
          <div className="app-input-field-container-1 modal-margin-left-global-2">
            <p style={{ display: "flex", justifyContent: "start", marginBottom: "10px" }} className="font-size-global-18 font-weight-global-700">
              Can this leave be carried forward to next year?
            </p>
            <Radio.Group
              style={{ display: "flex", justifyContent: "start", marginTop: "-2em", }}
              options={carryForWardedOptions}
              onChange={(e) =>
                setLeaveObj({
                  ...leaveObj,
                  carryForwarded: e.target.value,
                })
              }
              value={leaveObj.carryForwarded}
            />
          </div>
          <div className="text-align-left m-top-36 add-new-leave-modal-body modal-margin-left-global-small">
            <NormalButton
              buttonText={editFunction ? "Update" : "Submit"}
              type={13}
              onClick={addLeaveOpperation}
            />
            <NormalButton
              buttonText="Cancel"
              margin={[0, 10]}
              type={5}
              onClick={resetAddLeaveObject}
            />
          </div>
        </Spinner>
      </Modal>
    </Spinner>
  );
};

export default Leave;