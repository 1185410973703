import { Col, Divider, message, Tabs } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { askMool } from "../../apis/employee";
import config from "../../utils/config";
import { HeaderComponent } from "../Common";
import { Allowances, BasicInfo, Chapter6A, SalaryStructure } from "./component";
import ca from "../../assets/icons/activeIcons/ca.svg";
import { NormalButton } from "../ComponentLibrary";
import { TabPane } from "rc-tabs";
import FreeCalculator from "./FreeCalculator";

const Calculator = ({ organisationSettings, isTrial }) => {
  const [basicInfo, setBasicInfo] = useState({
    ctc: 0,
    rent: 0,
    isMetro: "false",
    variable: 0,
    isMonthly: "true",
    isSavings: "false",
    slider: 0,
  });
  const [finalPayslip, setFinalPayslip] = useState(null);
  const [allowances, setAllowances] = useState({});
  const [customAllowances, setCustomAllowances] = useState(false);
  const [modal, setModal] = useState(false);
  const [ctc, setCtc] = useState(0);
  const [tabKey, setTabKey] = useState("true");
  const [dec, setDec] = useState({
    "80C": 0,
    "80D": 0,
    "80E": 0,
    "80EE": 0,
    "80TTA": 0,
    "80G": 0,
    "80CCC": 0,
    "80CCD(2)": 0,
    "80CCD1(B)": 0,
    "80CCG": 0,
    "80EEA": 0,
    "80TTB": 0,
    "80EEB": 0,
    "80DD": 0,
    "80U": 0,
    "80DDB": 0,
    "24B": 0,
  });

  useEffect(() => {
    updateOrganisationSettings(organisationSettings);
  }, [organisationSettings]);

  const updateOrganisationSettings = (orgSettings) => {
    if (orgSettings.allowances) {
      setAllowances({ ...orgSettings.allowances });
    }
    if (orgSettings.customAllowances) {
      setCustomAllowances({ ...orgSettings.customAllowances });
    }
  };
  const CalculateCTC = async () => {
    if (!basicInfo.ctc) {
      message.error("Please Enter CTC Value");
      return;
    }
    setModal(true);
    let declarations = config.deductionData;
    let chapter6A = [];
    let tempDeclaration = { ...dec };
    declarations.forEach((d) => {
      if (d.declaration !== "preventiveHealthCheckupDependantParents") {
        let newDecl = {
          name: d.declaration,
          amount: isNaN(tempDeclaration[d.section])
            ? 0
            : parseInt(tempDeclaration[d.section]),
        };
        chapter6A.push(newDecl);
        tempDeclaration[d.section] = 0;
      }
    });

    chapter6A.push({
      name: "preventiveHealthCheckupDependantParents",
      amount: 0,
    });

    let allowance = Object.keys(allowances)
      .filter((k) => {
        return organisationSettings.allowances[k].applicable;
      })
      .map((all) => {
        return {
          name: all,
          amount: organisationSettings.allowances[all].limit
            ? parseInt(organisationSettings.allowances[all].limit)
            : 0,
          actualExpense: parseInt(allowances[all].limit)
            ? parseInt(allowances[all].limit)
            : 0,
          meta: {},
        };
      });

    let customAllowance = Object.keys(customAllowances)
      .filter((k) => {
        return organisationSettings.customAllowances[k].applicable;
      })
      .map((all) => {
        return {
          name: all,
          amount: organisationSettings.customAllowances[all].limit
            ? parseInt(organisationSettings.customAllowances[all].limit)
            : 0,
          actualExpense: parseInt(customAllowances[all].limit)
            ? parseInt(customAllowances[all].limit)
            : 0,
          meta: {},
        };
      });

    let houseRent = {
      status: basicInfo.rent > 0,
      isMetro: basicInfo.isMetro === "true",
      rent: basicInfo.rent ? basicInfo.rent : 0,
    };

    let isEmployerContributionIncludedInCTC =
      organisationSettings.isEmployerContributionIncludedInCTC !== undefined
        ? organisationSettings.isEmployerContributionIncludedInCTC
        : true;
    let lwfEligible =
      organisationSettings.lwfEligible !== undefined
        ? organisationSettings.lwfEligible
        : true;
    let pfContributionType =
      organisationSettings.pfContributionType !== undefined
        ? organisationSettings.pfContributionType
        : true;
    let orgPFContribution =
      organisationSettings.orgPFContribution !== undefined
        ? organisationSettings.orgPFContribution
        : false;

    let pfEnabled = true;
    let esicEnabled = true;

    if (organisationSettings.pfEnabled !== undefined) {
      pfEnabled = organisationSettings.pfEnabled;
    }

    if (organisationSettings.esicEnabled !== undefined) {
      esicEnabled = organisationSettings.esicEnabled;
    }

    let isStatutoryBonusIncludedInSalary =
      organisationSettings.isStatutoryBonusIncludedInSalary !== undefined
        ? organisationSettings.isStatutoryBonusIncludedInSalary
        : true;
    let organisationPFContribution =
      organisationSettings.organisationPFContribution !== undefined
        ? organisationSettings.organisationPFContribution
        : false;
    let employeePFContribution =
      organisationSettings.employeePFContribution !== undefined
        ? organisationSettings.employeePFContribution
        : false;

    const ctcAprox = parseInt(basicInfo.ctc) * 12;
    const CTCValue =
      basicInfo.isMonthly === "true"
        ? pfEnabled && isEmployerContributionIncludedInCTC
          ? parseInt(ctcAprox + 3800 * 12)
          : parseInt(ctcAprox)
        : parseInt(basicInfo.ctc);
    setCtc(CTCValue);
    let sendGraphData =
      basicInfo.isMonthly === "true" ? false : basicInfo.isSavings === "true";
    let dataCustomPoint = 0.66;
    let employeeData = {
      regimeOptedStatus: 0,
      customPoint: dataCustomPoint,
      pfContributionType,
      orgPFContribution,
      getGraphDataByKey: false,

      graphDataKey: 0,
      proffTaxEnabled: true,
      lwfEligible,
      pfEnabled,
      esicEnabled,
      previousData: {
        pfPaid: 0,
        taxPaid: 0,
        monthRemaining: 12,
        previousExemption: 0,
        previousEmployeePFPaid: 0,
        previousGrossIncome: 0,
        totalMonth: 12,
      },
      sendGraphData,
      sendDebugInfo: false,
      annualCTC: parseInt(CTCValue),
      stateAbbrev: "MH",
      feesOrCommissions: [],
      organisationPFContribution,
      isEmployerContributionIncludedInCTC,
      employeePFContribution,
      isStatutoryBonusIncludedInSalary,
      salaryAdjustDays: 0,
      ctcVariableComponent: {
        status: true,
        percentage: basicInfo.variable ? parseInt(basicInfo.variable) : 0,
        variableCompensationReceived: 0,
        isOverAndAboveCTC: organisationSettings.variableAboveCTC,
      },
      gratuity: organisationSettings.gratuity,
      houseRent,
      allowances: allowance,
      employerContributionToNPS_80CCD_2: 0,
      bonuses: [],
      reimbursements: [],
      monthClose: [],
      customAllowances: customAllowance,
      perks: [],
      chapter6A,
    };
    let res = await askMool(employeeData);
    if (basicInfo.isMonthly === "true") {
      let fPayslip = res.data.finalPayslip;
      let deficit = basicInfo.ctc - fPayslip.oldRegime.takeHome;
      let netTaxableIncome = fPayslip.totalTaxableIncome;
      if (deficit > 0) {
        let inhandDeficit = deficit * 12;
        let taxPercent =
          netTaxableIncome > 1000000
            ? 0.3
            : netTaxableIncome > 500000
              ? 0.2
              : 0;
        let taxSlabLimit =
          taxPercent === 0.2 ? 1000000 : taxPercent === 0.3 ? 10000000 : 0;
        let taxSlabAmountRemaining =
          taxSlabLimit === 0 ? 0 : taxSlabLimit - netTaxableIncome;
        let amountRequired =
          inhandDeficit *
          (taxPercent === 0.3 ? 10 / 7 : taxPercent === 0.2 ? 10 / 8 : 0);
        let revisedCTC = 0;
        if (amountRequired < taxSlabAmountRemaining) {
          revisedCTC = CTCValue + amountRequired;
        } else {
          // TODO: Look into the calculation add a slab for non taxable values
          let amountRemaining = inhandDeficit - taxSlabAmountRemaining;
          let amountAllocatedInCurrentSlab = amountRemaining * (10 / 7);
          revisedCTC = amountAllocatedInCurrentSlab + CTCValue;
        }
        let revisedEmployeeData = {
          ...employeeData,
          annualCTC: parseInt(revisedCTC),
        };
        setCtc(revisedCTC);
        let response = await askMool(revisedEmployeeData);
        setFinalPayslip({ ...response.data.finalPayslip });
      } else {
        setFinalPayslip({ ...fPayslip });
      }
    } else if (basicInfo.isSavings === "true") {
      setFinalPayslip({ ...res.data.graphData[basicInfo.slider][3] });
    } else {
      setFinalPayslip({ ...res.data.finalPayslip });
    }
  };

  const reset = () => {
    setTabKey("true");
    setBasicInfo({
      ...{
        ctc: 0,
        rent: 0,
        isMetro: false,
        variable: 0,
        isMonthly: "true",
        isSavings: false,
        slider: 0,
      },
    });
    let allowance = allowances;
    for (let i in allowance) {
      allowance[i].limit = null;
    }
    setAllowances(allowance);

    let customAllow = customAllowances;
    for (let i in customAllow) {
      customAllow[i].limit = null;
    }
    setCustomAllowances(customAllow);
    setDec({
      ...{
        "80C": 0,
        "80D": 0,
        "80E": 0,
        "80EE": 0,
        "80TTA": 0,
        "80G": 0,
        "80CCC": 0,
        "80CCD(2)": 0,
        "80CCD1(B)": 0,
        "80CCG": 0,
        "80EEA": 0,
        "80TTB": 0,
        "80EEB": 0,
        "80DD": 0,
        "80U": 0,
        "80DDB": 0,
      },
    });
    updateOrganisationSettings(organisationSettings);
    setFinalPayslip(null);
    setCtc(0);
    setModal(false);
  };

  useEffect(() => {
    document.title = "Tools | Calculator";
  });
  const handleTabchange = (key) => {
    setTabKey(key);
    setBasicInfo({ ...basicInfo, isMonthly: key });
  };

  return (
    isTrial ? <FreeCalculator /> :
      <div className="site-layout">
        <HeaderComponent
          name="Calculator"
          show={false}
          showMoolCoins={true}
          icon={ca}
        />
        <div style={{ padding: "0px 30px" }}>
          <div className="flex align-center justify-between">
            <h2 className="app-heading-bold font-size-global-26 font-weight-global-700">
              Let’s crunch some numbers for the day
            </h2>
          </div>
          <Tabs onChange={handleTabchange} activeKey={tabKey} defaultActiveKey={tabKey}>
            <TabPane tab="CTC Calculator" key={true}></TabPane>
            <TabPane tab="Optimal Pay Structure" key={false}></TabPane>
          </Tabs>
          <Divider style={{ marginTop: "-1rem" }} />
        </div>

        <div className="app-main-content-container paper-wrapper">
          <Col>
            <BasicInfo
              organisationSettings={organisationSettings}
              basicInfo={basicInfo}
              setBasicInfo={setBasicInfo}
              reset={reset}
            />
            <Allowances
              organisationSettings={organisationSettings}
              allowances={allowances}
              customAllowances={customAllowances}
              setAllowances={setAllowances}
              setCustomAllowances={setCustomAllowances}
            />
            <Chapter6A
              dec={dec}
              setDec={setDec}
              organisationSettings={organisationSettings}
            />
          </Col>
          <SalaryStructure
            finalPayslip={finalPayslip}
            organisationSettings={organisationSettings}
            setModal={setModal}
            setFinalPayslip={setFinalPayslip}
            modal={modal}
            reset={reset}
            ctc={ctc}
            monthly={basicInfo.isMonthly}
          />
          <div
            className=" flex justify-start"
            style={{ margin: "24px 0", padding: "1rem" }}
          >
            <NormalButton
              onClick={CalculateCTC}
              type={13}
              buttonText={"Calculate Data"}
            />
            <NormalButton
              onClick={reset}
              type={5}
              buttonText={"Clear all fields"}
            />
          </div>
        </div>
      </div>
  );
};

const CalculatorContainer = connect((state) => ({
  organisationSettings: state.organisation.organisationSettings,
  isTrial: state.auth.user.isTrial
}))(Calculator);

export default CalculatorContainer;
