import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Skeleton, Typography } from "antd";
import React, { useState } from "react";
import { FileLogos } from "../../ComponentLibrary"
import packageJson from "../../../../package.json";
// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PasswordResponses from "react-pdf/dist/esm/PasswordResponses";
import { removeProof } from "../../../apis/employee";
import { getDocumentURL } from "../../../apis/user";
import verifiedFalse from "../../../assets/common/verifiedLogos/verify-no.svg";
import verifiedTrue from "../../../assets/common/verifiedLogos/verify-yes.svg";
import config from "../../../utils/config";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const pdfjsVersion = packageJson.dependencies["pdfjs-dist"];

const { Text } = Typography;

const DocumentViewer = ({
  items,
  type,
  updateDeclarationDocument,
}) => {
  const [document, setDocument] = useState("");
  const [modalstatus, setModalStatus] = useState(false);
  const [format, setFormat] = useState("");
  const [viewFileDocumentProps, setViewFileDocumentProps] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderToolbar = (Toolbar) => (React.createElement(Toolbar, null, (slots) => {
    const { CurrentPageInput, Download, EnterFullScreen, GoToNextPage, GoToPreviousPage, NumberOfPages, Zoom, ZoomIn, ZoomOut } = slots;
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <div style={{ padding: '0px 2px' }}>
          <ZoomOut />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <Zoom />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <ZoomIn />
        </div>
        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
          <GoToPreviousPage />
        </div>
        <div style={{ padding: '0px 2px', width: '4rem' }}>
          <CurrentPageInput />
        </div>
        <div style={{ padding: '0px 2px' }}>
          / <NumberOfPages />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <GoToNextPage />
        </div>
        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
          <EnterFullScreen />
        </div>
        <div style={{ padding: '0px 2px' }}>
          <Download />
        </div>
      </div>
    );
  }));

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
      defaultTabs[0], // Bookmarks tab
      defaultTabs[1], // Thumbnails tab
    ],
  });
  const getDocumentURLFromKey = async (data) => {
    let fileProperty = {
      documentKey: data.Key,
      documentType: data.Key.split(".").pop(),
      documentName: data.name,
    }
    setViewFileDocumentProps(fileProperty);
    let documents = await getDocumentURL({ Key: data.Key });
    setDocument(documents.data);
    if (fileProperty.documentType === "pdf") {
      setFormat("pdf");
      setModalStatus(false);
      showModal();
    } else {
      setFormat("image");
      setModalStatus(true);
    }
  };
  const onPasswordHandler = function (callback, reason) {
    function callbackProxy(password) {
      // Cancel button handler
      if (!password) {
        setModalStatus(false)
        return
      }
      callback(password);
    }

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(password);
        break;
      }
      default:
    }
  }

  const DocumentAllowances = ({ data }) => {
    return (
      <div className="document-container">
        <div
          style={{ display: "flex" }}
          onClick={() => {
            setModalStatus(true);
            getDocumentURLFromKey(data);
          }}
        >
          <span
            className="file-typeHeader"
          >
            {data.Key.split(".").pop()}
          </span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text
              style={{ width: 200 }}
              ellipsis={{ tooltip: data.name }}
            >{data.name}</Text>
            <span className="date-contianer">
              {new Date(data.createdAt).toDateString()}
            </span>
          </div>
        </div>
        {data.verified ? (
          <span className="verifiection-text verified">
            <img
              alt="verification status logo"
              className="verifiection-logo"
              src={verifiedTrue}
            >
            </img>
            Verified
          </span>
        ) : (
          <span className="verifiection-text not-verified">
            <img
              alt="verification status logo"
              className="verifiection-logo"
              src={verifiedFalse}
            >
            </img>
            Not Verified
          </span>
        )}
      </div>
    );
  };
  const removeDocument = async (data) => {
    await removeProof(data.proofId);
    await updateDeclarationDocument();
  };

  return (
    <div className="otherAllowance-files-container">
      <div className="otherAllowance-files-header">
        <h1 className="otherAllowance-files-heading">Documents</h1>
      </div>
      <div className="document-list-container ">
        <Skeleton
          loading={false}
          active
          paragraph={{ rows: 3 }}
          avatar
        >
          {items &&
            items.map((value, index) => {
              return (
                <div>
                  <Card
                    bordered={false}
                    className="document-group"
                    style={{
                      borderBottom: 16
                    }}
                  >
                    <div className="document-header">
                      <div className="document-header-button-and-name-container">
                        <div className="document-action-container">
                          <Button
                            danger
                            shape="circle"
                            type=""
                            onClick={() => removeDocument(value)}
                          >
                            <DeleteOutlined key={`doc-container-delete-${index}`} />
                          </Button>
                        </div>
                        <p className="document-header-labele">
                          {
                            config[type][value.subCatagory].label
                          }
                        </p>
                      </div>
                      <p className="allowance-amount">
                        {config.numformatter(value.amount)} INR
                      </p>
                    </div>
                    <div className="document-list">
                      {value.documents.map((val, i) => (
                        <DocumentAllowances data={val} key={`all-docs-${i}`} />
                      ))}
                    </div>
                  </Card>
                  <div className="seperator"></div>
                </div>
              );
            })}
        </Skeleton>
        {format === "image" && <Modal
          onCancel={() => {
            setDocument("");
            setModalStatus(false);
            setFormat("");
          }}
          visible={modalstatus}
          footer={null}
          centered
          width={900}
        >
          <Skeleton loading={!document} active avatar paragraph={{ rows: 5 }}>
            <a download href={document}>
              Download Document
            </a>
            <img src={document} className="document-display" alt="document-display" />
          </Skeleton>
        </Modal>}
        {format === "pdf" && <Modal
          title={
            <>
              <FileLogos type={1} /> {viewFileDocumentProps.documentName}
            </>
          }
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          footer={null}
        >
          <div>
            {document ? (
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={document}
                  plugins={[defaultLayoutPluginInstance]}
                  renderError={console.log}
                  onDocumentAskPassword={onPasswordHandler}
                  initialPage={1}
                />
              </Worker>
            ) : (
                "Document URL not found"
            )}
          </div>
        </Modal>
        }
      </div>
    </div>
  );
};

export default DocumentViewer;
