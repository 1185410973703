import { useState, useEffect } from "react";
import {
  Layout,
  notification,
  Switch,
  Button
} from "antd";
import { withRouter } from "react-router-dom";
import employee from "../../../../redux/modules/employee";
import { connect } from "react-redux";
import {
  updateDeclarations,
  updateEmployeeOnBoarding
} from "../../../../apis/employee";
import blueEmptyBox from "../../../../assets/organisationDashboard/blueEmptyBox.svg";
import InputNumberComponent from "../../../InputLibrary/InputNumberComponent/InputNumberComponent";

const { Content } = Layout;

const ManageAllowances = ({
  user,
  changePageState,
  organisationSettings,
  group,
  structureOfMool,
  employeeReducer,
  isPercentageStructure,
  ctc,
  declaration
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allowances, setAllwoances] = useState({});
  const [customAllowances, setCustomAllowances] = useState({});
  const [orgAllowances, setOrgAllowances] = useState({});
  const [orgCustomAllowances, setOrgCustomAllowances] = useState({});
  const [percentage, setPercentage] = useState({});
  const currentMonth = new Date().getMonth();
  const [hra, setHra] = useState({
    applicable: false,
    isMetro: false,
    rent: ""
  });

  const submitAllowances = async () => {
    setIsLoading(true);
    let { declarations } = declaration;
    declarations.allowances = { ...allowances };
    declarations.customAllowances = { ...customAllowances };
    declarations.houseRent = { ...hra };
    let houseRent = hra;
    houseRent.rent =
      houseRent.rent === "" ||
        houseRent.rent === null ||
        !parseInt(houseRent.rent)
        ? 0
        : parseInt(houseRent.rent);
    if (houseRent.rent === 0) {
      houseRent.applicable = false;
      houseRent.isMetro = false;
      houseRent.ownerPAN = "";
    } else {
      hra.applicable = true;
    }
    declarations.houseRent = { ...hra };
    if (isPercentageStructure) {
      declarations.percentage = { ...percentage }
    }
    else {
      declarations.percentage = { percentage }
    }

    await updateDeclarations({
      id: user._id,
      declarations: declarations,
      month: currentMonth
    });
    await updateEmployeeOnBoarding({
      fields: { allowances: true },
      organisationId: user.organisationId,
      id: user._id,
    });
    await updateEmployeeOnBoarding({
      fields: { hra: true },
      organisationId: user.organisationId,
      id: user._id,
    });
    employeeReducer.updateAllowances({
      allowances,
      customAllowances,
      hra
    });
    changePageState();
    setIsLoading(false);
  };

  useEffect(() => {
    let { declarations } = declaration;
    if (declarations) {
      if (isPercentageStructure) {
        setPercentage({ ...declarations.percentage })
      }
      let allowance = declarations.allowances;
      let customAllowance = declarations.customAllowances;
      setOrgCustomAllowances({ ...organisationSettings.customAllowances });
      setOrgAllowances({ ...organisationSettings.allowances });

      setCustomAllowances({ ...customAllowance });
      setAllwoances({ ...allowance });
    }
  }, [declaration, isPercentageStructure, organisationSettings]);

  const changeAllowancesState = (key, value, type) => {
    if (type === "allowance") {
      if (
        (!structureOfMool && orgAllowances[key]["amount"] < value) ||
        !orgAllowances[key]["applicable"]
      ) {
        notification.error({ message: "Please Enter Valid Amount" });
        return;
      }
      let newAllowances = allowances;
      newAllowances[key]["actualExpense"] = value;
      newAllowances[key]["amount"] = value;
      setAllwoances({ ...newAllowances });
    }
    if (type === "custom") {
      if (
        orgCustomAllowances[key]["limit"] < value ||
        !orgCustomAllowances[key]["applicable"]
      ) {
        notification.error({ message: "Please Enter Valid Amount" });
        return;
      }

      let newAllowances = customAllowances;
      newAllowances[key]["actualExpense"] = value;
      newAllowances[key]["amount"] = value;
      setCustomAllowances({ ...newAllowances });
    }
  };

  const updatePercentageStructure = (key, value, type) => {
    // Get Groups and organisation settings data
    // check if it is a group or organisation settings percentage
    // If the user belong to intern group then return
    if (user.groupId === "intern") {
      return
    }
    let allowancesPercentage = {}
    let customAllowancesPercentage = {}
    if (!Object.keys(group).length) {
      allowancesPercentage = { ...organisationSettings.percentageStructure.allowances }
      customAllowancesPercentage = { ...organisationSettings.percentageStructure.customAllowances }
    }
    else {
      allowancesPercentage = { ...group.percentage.allowances }
      customAllowancesPercentage = { ...group.percentage.customAllowances }
    }
    if (type === 0) {
      // Update allowances
      if (allowancesPercentage[key] && allowancesPercentage[key].isPercent) {
        return
      }
      setPercentage({
        ...percentage,
        allowances: {
          ...percentage.allowances,
          [key]: {
            isPercent: false,
            value: allowancesPercentage[key].value <= value ? allowancesPercentage[key].value : value
          }
        }
      })
    }
    else if (type === 1) {
      if (customAllowancesPercentage[key] && customAllowancesPercentage[key].isPercent) {
        return
      }
      setPercentage({
        ...percentage,
        customAllowances: {
          ...percentage.customAllowances,
          [key]: {
            isPercent: false,
            value: customAllowancesPercentage[key].value <= value ? customAllowancesPercentage[key].value : value
          }
        }
      })
    }
  }

  return (
    <Content className="stages">
      <div className="main-container">
        <div className="header-display">
          <p className="header-title">
            You can always modify it later
          </p>
          {/* <Search
              onChange={searchAllwoance}
              className="search-feild"
              placeholder="Search Allowances"
              size="medium"
            /> */}
        </div>
        <div className="allowances-container employee">
          <div className="switch flex align-center justify-between">
            <div className="flex flex-direction-column">
              <p className="font-weight-700 font-size-18 m-top-12">Do you live in a metro city</p>
              <p className="font-weight-500 font-size-16 m-top-4" style={{ color: '#F05050' }}>Note: Only Mumbai,
                Delhi, Chennai, Kolkata are considered as metro</p>
            </div>
            <Switch
              size={"medium"}
              checked={hra.isMetro}
              onChange={(checked) => {
                setHra({ ...hra, isMetro: checked });
              }}
              style={{ marginRight: "1rem" }}
            />
          </div>

          <div className="single-allowance spl flex flex-direction-row house-rent">
            <InputNumberComponent labeltext={'House Rent'} min={0}
              margin={['30', '0', '20', '0']}
              value={hra.rent} onChange={(val) => {
                setHra({ ...hra, rent: val })
              }} />
            <p></p>
          </div>
          <div className="direction" style={{ marginTop: "1rem", marginBottom: "1rem", fontSize: '1rem' }} >
            <p>
              Don’t know your house rent ? Worry not you can always update your rent in{" "}
              <b>
                Declaration {">"} Allowance {">"} HRA
              </b>
            </p>
          </div>
          <div className="flex flex-direction-column">
            <p className="font-weight-700 font-size-18 m-top-12">Allowance List</p>
            <p className="font-weight-500 font-size-16 m-top-4 m-bottom-16">Note: Fill in the spends that you do monthly,
              if you don’t know or couldn’t figure out you can leave them as 0</p>
          </div>
          {isPercentageStructure ? (
            <>
              {percentage.allowances && percentage.allowances[currentMonth] &&
                Object.keys(percentage.allowances[currentMonth]).map((val, _index) => {
                  if (!allowances[val] || !allowances[val].label) return "";
                  return (
                    <div className="single-allowance" key={val}>
                      <p>{allowances[val].label}</p>
                      <InputNumberComponent
                        width={'130px'}
                        min={0}
                        onChange={value => {
                          if (!percentage.allowances[currentMonth][val].isPercent) {
                            // Add the actual Expencense 
                            // Check the limit and replace it with it
                            updatePercentageStructure(val, value, 0)
                          }
                        }}
                        value={percentage.allowances[currentMonth][val].isPercent ? Math.floor(percentage.allowances[currentMonth][val].value * parseInt(ctc.costToCompany[currentMonth].ctc) / 12) : percentage.allowances[currentMonth][val].value
                        }
                        disabled={percentage.allowances[currentMonth][val].isPercent}
                      />
                    </div>
                  )
                })}
              {percentage.customAllowances &&
                Object.keys(percentage.customAllowances).map((val, _index) => (
                  <div className="single-allowance" key={val}>
                    <p>{customAllowances[val].label}</p>
                    <InputNumberComponent
                      width={'130px'}
                      min={0}
                      onChange={value => {
                        if (!percentage.customAllowances[val].isPercent) {
                          // Add the actual Expencense 
                          // Check the limit and replace it with it
                          updatePercentageStructure(val, value, 1)
                        }
                      }}
                      value={percentage.customAllowances[val].isPercent ? Math.floor(
                        (parseInt(ctc.costToCompany[currentMonth].ctc) * percentage.customAllowances[val].value) / 12) : percentage.customAllowances[val].value
                      }
                      disabled={percentage.customAllowances[val].isPercent}
                    />
                  </div>
                ))}
            </>
          ) : (
            <>
              {Object.keys(allowances).map((val, _index) => (
                <div className="single-allowance" key={val}>
                  <p>{allowances[val].label}</p>
                  <InputNumberComponent
                    width={'130px'}
                    min={0}
                    max={
                      structureOfMool ? 1000000000 : orgAllowances[val]["limit"]
                    }
                    value={allowances[val]["actualExpense"]}
                    onChange={(value) =>
                      changeAllowancesState(val, value, "allowance")
                    }
                  />
                </div>
              ))}
              {Object.keys(customAllowances).map((val, _index) => (
                <div className="single-allowance" key={val}>
                  <p>{customAllowances[val].label}</p>
                  <InputNumberComponent
                    width={'130px'}
                    min={0}
                    max={orgCustomAllowances[val]["limit"]}
                    value={customAllowances[val]["actualExpense"]}
                    onChange={(value) =>
                      changeAllowancesState(val, value, "custom")
                    }
                  />
                </div>
              ))}
              {Object.keys(allowances).length === 0 &&
                Object.keys(customAllowances).length === 0 && (
                  <div className="flex justify-center align-center">
                    <img
                      style={{
                        height: 200,
                        width: 200
                      }}
                      src={blueEmptyBox}
                      alt="empty"
                    ></img>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
      <div className="submit-container">
        <Button
          loading={isLoading}
          onClick={submitAllowances}
          className="submit-button"
          style={{ background: '#405cd2', padding: '15px', height: '60px', fontSize: '1.125rem' }}
          htmlType="submit"
        >
          Save and Continue
        </Button>
      </div>
    </Content>
  );
};

const ManageAllowancesContainer = connect(
  (state) => ({
    user: state.auth.user,
    organisationSettings: state.employee.organisationSettings,
    group: state.employee.group,
    structureOfMool: state.employee.structureOfMool,
    isPercentageStructure: state.employee.isPercentageStructure,
    ctc: state.employee.ctc,
    declaration: state.employee.declaration
  }),
  (dispatch) => ({
    employeeReducer: employee.getActions(dispatch)
  })
)(ManageAllowances);

export default withRouter(ManageAllowancesContainer);