import { message, Modal, notification, Select, Table } from "antd";
import {
  weeklyOffUpdate,
  getWeeklyOff,
  removeWeeklyOff,
} from "../../../../apis/organisation";
import { useState, useEffect } from "react";
import { NormalButton } from "../../../ComponentLibrary";
import {
  editLogo,
  deleteLogo,
} from "../../../../assets/common/editAndDeleteLogos";
import plusSymbol from "../../../../assets/common/plusSymbol.svg"
import { daylist, dayListObject } from "../../../../utils/config";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import { createLog } from "../../../../apis/user";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";

const { Option } = Select;

const WeeklyOff = ({ user, groups }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAddWeeklyOffModal, setShowAddWeeklyOffModal] = useState(false);
  const [WeeklyOffList, setWeeklyOffList] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [WeeklyOffData, setWeeklyOffData] = useState({
    organisationId: user.organisationId,
    groupId: "",
    name: "",
    weeklyOffOne: "",
    weeklyOffTwo: "",
  });
  const [editFunction, setEditFunction] = useState(false);
  const [editOrDeleteWeeklyOffData, setEditOrDeleteWeeklyOffData] = useState(
    {}
  );
  const [showDeleteWeeklyOffModal, setShowDeleteWeeklyOffModal] = useState(
    false
  );

  const getWeeklyOffList = async () => {
    setIsLoading(true);
    let res = await getWeeklyOff(`${user.organisationId}`);

    if (res.status === 200) {
      setWeeklyOffList(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getWeeklyOffList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.organisationId, isRefresh]);

  const closeModal = () => {
    setShowAddWeeklyOffModal(false);
    setWeeklyOffData({
      organisationId: user.organisationId,
      name: "",
      weeklyOffOne: "",
      weeklyOffTwo: "",
      groupId: "",
    });
    setEditFunction(false);
  };

  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }
  const findGroupFromGroupId = (groupId) => {
    if (groupId === "") {
      return "General";
    }
    if (groupId === "intern") {
      return "Intern";
    }
    for (let g of groups) {
      if (g.groupId === groupId) {
        return g.name;
      }
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Group",
      dataIndex: "groupId",
      key: "groupId",
      render: (type) => <div>{findGroupFromGroupId(type)}</div>,
    },
    {
      title: "Weekly Off Day One",
      dataIndex: "weeklyOffOne",
      key: "weeklyOffOne",
      render: (type) => <div>{Object.keys(dayListObject)[type]}</div>,
    },
    {
      title: "Weekly Off Day Two",
      dataIndex: "weeklyOffTwo",
      key: "weeklyOffTwo",
      render: (type) => <div>{isNaN(type) ? "Disabled" : Object.keys(dayListObject)[type]}</div>,
    },
    {
      title: "",
      dataIndex: "groupId",
      key: "groupId",
      render: (_data, rowData) => (
        <div className="flex justify-end align-center">
          <NormalButton
            icon={editLogo}
            type={5}
            onClick={() => {
              setEditFunction(true);
              setWeeklyOffData({
                ...rowData,
              });
              setEditOrDeleteWeeklyOffData(rowData);
              setShowAddWeeklyOffModal(true);
            }}
          />
          <NormalButton
            icon={deleteLogo}
            type={5}
            margin={[0, 10]}
            onClick={() => {
              setShowDeleteWeeklyOffModal(true);
              setEditOrDeleteWeeklyOffData(rowData);
            }}
          />
        </div>
      ),
    },
  ];

  const createWeeklyOff = async () => {
    if (WeeklyOffData.name === "") {
      notification.error({
        message: "Name of the weekly off cannot be empty.",
      });
      return;
    }
    if (isNaN(parseInt(WeeklyOffData.weeklyOffOne))) {
      notification.error({ message: "Weekly off cannot be empty." });
      return;
    }

    setIsLoading(true);
    let res = await weeklyOffUpdate(WeeklyOffData);
    if (res.status === 200 && res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        weeklyOffOne: WeeklyOffData.weeklyOffOne,
        weeklyOffTwo: WeeklyOffData.weeklyOffTwo,
        organisationId: user.organisationId,
        message: `Organisation user has created a weekly off`,
        category: 6,
      });
      await getWeeklyOffList();
      notification.success({
        message: "Successfully added the new weekly off.",
      });
      closeModal();
      return;
    } else {
      notification.error({
        message: "Failed to add the weekly off, please try again.",
      });
    }
    setIsLoading(false);
  };

  const onDeleteConfirm = async (weekOffData) => {
    if (weekOffData.groupId === "" || weekOffData.groupId === "intern") {
      message.warning("Cannot delete General and Intern group weekly off");
      setShowDeleteWeeklyOffModal(false);
      return;
    }
    setIsLoading(true);
    let res = await removeWeeklyOff(
      `${weekOffData.groupId}/${user.organisationId}`
    );
    if (res.status === 200 && res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Organisation user has deleted a weekly off`,
        category: 6,
      });
      await getWeeklyOffList();
      notification.success({ message: "Successfully deleted the weekly off." });
      setShowDeleteWeeklyOffModal(false);
    } else {
      notification.error({
        message: "Failed to delete the weekly off, please try again.",
      });
      setIsLoading(false);
    }
  };

  return (
    <Spinner spinning={isLoading}>
      <div>
        <h1 className="start font-size-global-24 font-weight-global-700">Weekly Off Settings</h1>
        <p className="title-gray-5 font-weight-global-500">
          Define custom Weekly Offs for multiple employee groups within your
          organization{" "}
        </p>
      </div>
      <div className="flex justify-between align-center margin-bottom-global">
        <div className="flex justify-between align-center">
          <h1 className="app-heading-4 my-4 font-size-global-18 font-weight-global-700">
            Records ({WeeklyOffList.length})
          </h1>
        </div>
        <div>
          <span style={{ marginRight: "0.5em", float: "left" }}>
            <NormalButton
              buttonText={"Refresh"}
              type={13}
              onClick={startRefresh}
              className={`button-lib button-type-13 p-10`}
              style={{ height: "2.5rem" }}
            />
          </span>
          <NormalButton
            icon={plusSymbol}
            buttonText="Add New"
            type={13}
            onClick={() => setShowAddWeeklyOffModal(true)}
          />
        </div>
      </div>

      <Table
        dataSource={WeeklyOffList.reverse()}
        columns={columns}
        pagination={WeeklyOffList.length < 10 ? false : { pageSize: 10 }}
        className="app-table"
      />
      <Modal
        visible={showAddWeeklyOffModal}
        onCancel={closeModal}
        closable={false}
        centered={true}
        width={390}
        footer={false}
      >
        <Spinner spinning={isLoading}>
          <div>
            <h1 className=" m-top-8 font-size-global-24 font-weight-global-700 modal-margin-left-global-heading">Add New Weekly Off</h1>
          </div>
          <div className="add-new-leave-modal-body">
            <div style={{ display: "flex", justifyContent: "center" }} className="app-input-field-container-1">
              <InputFieldComponent
                value={WeeklyOffData.name}
                labeltext={"Name"}
                onChange={(e) => {
                  setWeeklyOffData({
                    ...WeeklyOffData,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "-3em" }} className="app-input-field-container-1">
              <DropdownComponent
                dropdownMatchSelectWidth={false}
                labeltext={"Weekly off group"}
                style={{ width: "100%" }}
                size="large"
                value={WeeklyOffData.groupId}
                values={groups.map(item => item.groupId)}
                options={groups.map(item => item.name)}
                valueCanBeBlankString={true}
                onChange={(e) => {
                  setWeeklyOffData({
                    ...WeeklyOffData,
                    groupId: e,
                  });
                }}
              >
                <Option value={""}>General</Option>
                <Option value={"intern"}>Intern</Option>
                {groups.map((val, index) => (
                  <Option key={`group-dropdown-${index}`} value={val.groupId}>
                    {val.name}
                  </Option>
                ))}
              </DropdownComponent>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "-1em" }} className="app-input-field-container-1">
              <DropdownComponent
                value={WeeklyOffData.weeklyOffOne}
                labeltext={"Weekly day off one"}
                dropdownMatchSelectWidth={false}
                style={{ width: "100%" }}
                values={Object.values(dayListObject)}
                options={Object.keys(dayListObject)}
                size="large"
                onChange={(e) => {
                  setWeeklyOffData({
                    ...WeeklyOffData,
                    weeklyOffOne: e,
                  });
                }}
              >
                {Object.values(dayListObject)}
                {daylist.map((val, index) => (
                  <Option key={`group-year-${index}`} value={index}>
                    {val}
                  </Option>
                ))}
              </DropdownComponent>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "-1em" }} className="app-input-field-container-1">
              <DropdownComponent
                value={WeeklyOffData.weeklyOffTwo}
                labeltext={"Weekly day off Two"}
                dropdownMatchSelectWidth={false}
                style={{ width: "100%" }}
                values={Object.values(dayListObject)}
                options={Object.keys(dayListObject)}
                size="large"
                onChange={(e) => {
                  setWeeklyOffData({
                    ...WeeklyOffData,
                    weeklyOffTwo: e,
                  });
                }}
              >
                {daylist.map((val, index) => (
                  <Option key={`group-year-${index}`} value={index}>
                    {val}
                  </Option>
                ))}
              </DropdownComponent>
            </div>
          </div>
          <div className="text-align-left m-top-36 add-new-leave-modal-body modal-margin-left-global-button">
            <NormalButton
              buttonText={editFunction ? "Update" : "Submit"}
              type={13}
              onClick={createWeeklyOff}
            />
            <NormalButton
              buttonText="Cancel"
              margin={[0, 10]}
              type={5}
              onClick={closeModal}
            />
          </div>
        </Spinner>
      </Modal>
      <Modal
        spinning={isLoading}
        centered
        closable={false}
        visible={showDeleteWeeklyOffModal}
        className="modal-container"
        footer={null}
        onCancel={() => {
          showDeleteWeeklyOffModal(false);
          setEditOrDeleteWeeklyOffData({});
        }}
      >
        <Spinner spinning={isLoading}>
          <div className="">
            <div className="flex justify-between">
              <h1 className="app-heading-2">Delete WeeklyOff</h1>
            </div>
            <div className="">
              <p className="danger-text font-size-16 font-weight-500">
                Are you sure you want to delete the weekly off "
                {editOrDeleteWeeklyOffData.name}"
              </p>
            </div>
            <div className="m-top-36  text-align-right">
              <NormalButton
                type={5}
                buttonText={"cancel"}
                margin={[0, 12]}
                onClick={() => {
                  setShowDeleteWeeklyOffModal(false);
                  editOrDeleteWeeklyOffData({});
                }}
              />
              <NormalButton
                type={13}
                buttonText="Delete"
                onClick={() => {
                  onDeleteConfirm(editOrDeleteWeeklyOffData);
                }}
              />
            </div>
          </div>
        </Spinner>
      </Modal>
    </Spinner>
  );
};

export default WeeklyOff;