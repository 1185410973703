/* eslint-disable array-callback-return */
import { NormalButton } from "../ComponentLibrary";
import { Table, Drawer, Modal, notification } from "antd";
import Profile from "./Profile";
import exclamation from "../../assets/icons/exclamation.svg";
import { useState } from "react";
import config from "../../utils/config";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { updateTaxSettlementDeclaration } from "../../apis/organisation";

const Declaration = ({ updateStructure, taxSettlementData, notifyTaxSettlement, setProgress, organisationId, organisationReducer,
    setLoading, financialYear }) => {
    const [visible, setVisible] = useState(false);
    const [empProfile, setEmpProfile] = useState({});
    const [count, setCount] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    const onSelectChange = rows => {
        setSelectedRowKeys([...rows]);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE
        ]
    };

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const notifyAssociates = employeeList => {
        const selectedRows = employeeList.filter(item =>
            selectedRowKeys.includes(item._id)
        );
        if (!selectedRows.length) {
            notification.error({
                message: "Please select at least one employee."
            });
            return;
        }
        setVisible(false)
        Modal.confirm({
            title: `This will notify selected employees about the settlement difference.`,
            icon: <ExclamationCircleOutlined />,
            okText: "Confirm",
            okButtonProps: {
                type: "primary",
                danger: true,
            },
            cancelText: "Cancel",
            onOk: () => notifyTaxSettlement([...selectedRows])
        });
    };

    // Configure it for every employee
    const settleAllDeclarations = async (employeeList) => {
        const selectedRows = employeeList.filter(item =>
            selectedRowKeys.includes(item._id)
        );
        if (!selectedRows.length) {
            notification.error({
                message: "Please select at least one employee."
            });
            return;
        }
        setVisible(false)
        Modal.confirm({
            title: `If you are doing this operation then the employee declarations will be updated and tax will be calculated accordingly`,
            icon: <ExclamationCircleOutlined />,
            okText: "Confirm",
            okButtonProps: {
                type: "primary",
                danger: true,
            },
            cancelText: "Cancel",
            onOk: async () => await updateSettlement(selectedRows),
        });
    }


    const updateSettlement = async (employeeList) => {
        setLoading(true);
        let success = 0;
        for (let employee of employeeList) {
            try {
                let postBody = {
                    financialYear,
                    id: employee.user._id
                };
                await updateTaxSettlementDeclaration(postBody);

            } catch (e) { }
            success++;
            setProgress(success);
        }
        organisationReducer.updateTaxSettlement({
            organisationId: organisationId,
            financialYear
        });
        setProgress(0)
        setLoading(false);
    }

    const columns = [
        {
            title: "Name",
            dataIndex: ["user", "displayName"],
            key: "name",
            render: (name, rowData) => <Profile title={name} rowData={rowData} />,
        },
        {
            title: "Declared amount",
            dataIndex: ["deductions", "deductionAlloted", "schemeNHB"],
            key: "amount",
            render: (value, rowData) => {
                let total = rowData.previousDeclaredAmount ?? 0
                // Object.values(rowData.deductions.deductionAllotted).map((val) => {
                //     Object.values(val).map((c) => {
                //         total += c;
                //     });
                // });
                return "Rs " + total.toLocaleString();
            },
        },
        {
            title: "Proof submitted amount ( Only Verified )",
            dataIndex: ["deductions", "deductionsProof", "mobile"],
            key: "subAmount",
            render: (value, rowData) => {
                let total = 0;
                Object.values(rowData.deductions.deductionsProof).map((val) => {
                    Object.values(val).map((c) => {
                        total += c;
                    });
                });
                return "Rs " + total.toLocaleString();
            },
        },
        {
            title: "Difference (Proof submitted amount - Allotted amount) / Settled",
            dataIndex: ["deductions", "deductionProofDifference", "mobile"],
            key: "diff",
            render: (key, rowData) => {
                let total = 0;
                let cnt = 0;
                Object.values(rowData.deductions.deductionProofDifference).map(
                    (val) => {
                        Object.values(val).map((c) => {
                            if (c < 0) cnt += 1;
                            total += c;
                        });
                    }
                );

                const value = total < 0 ? (
                    <div className="flex align-center justify-between">
                        <div className="flex flex-direction-column align-start justify-center">
                            <p
                                style={{ color: "red", fontWeight: "600", fontSize: "0.95rem" }}
                            >
                                {" "}
                                -Rs{" "}
                                {Math.abs(total).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                })}
                            </p>
                            <div className="flex align-center justify-center">
                                <img
                                    src={exclamation}
                                    alt=""
                                    style={{ marginRight: "0.5rem" }}
                                />
                                <p>{`${cnt} declaration amount unfulfilled`}</p>
                            </div>
                        </div>
                        <NormalButton
                            className="drawer-btn"
                            type={13}
                            onClick={() => {
                                showDrawer();
                                setEmpProfile({ ...rowData });
                                setCount(cnt);
                            }}
                            buttonText={"View"}
                        />
                    </div>
                ) : total === 0 ? (
                    <div className="flex align-center justify-between">
                        <p
                            style={{ color: "black", fontWeight: "600", fontSize: "0.95rem" }}
                        >
                            INR {total}
                        </p>
                        <NormalButton
                            type={13}
                            className="drawer-btn"
                            onClick={() => {
                                showDrawer();
                                setEmpProfile({ ...rowData });
                            }}
                            buttonText={"View"}
                        />
                    </div>
                ) : (
                    <div className="flex align-center justify-between">
                        <p
                            style={{ color: "green", fontWeight: "600", fontSize: "0.95rem" }}
                        >
                            Rs {total}
                        </p>
                        <NormalButton
                            type={13}
                            className="drawer-btn"
                            onClick={() => {
                                showDrawer();
                                setEmpProfile({ ...rowData });
                            }}
                            buttonText={"View"}
                        />
                    </div>
                );

                return rowData.deductionRebalanced ? "Settled" : value;
            },
        },
    ];


    return (
        <div className="declaration">
            <div className="flex align-center justify-between">
                <h2 className="font-weight-global-700 font-size-global-24">IT Declarations Data</h2>
                <div className="flex align-center">
                    <NormalButton onClick={() => { updateStructure() }} type={13} buttonText={"Update list"} />
                    <NormalButton disabled={selectedRowKeys.length <= 0} onClick={() => notifyAssociates([...taxSettlementData])} buttonText={"Notify"} />
                    <NormalButton
                        onClick={() => settleAllDeclarations(taxSettlementData)}
                        type={19}
                        disabled={selectedRowKeys.length <= 0}
                        buttonText={"Settle Declarations"}
                    />
                </div>
            </div>
            <Table
                pagination={false}
                scroll={{ y: 600 }}
                rowKey={"_id"}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={taxSettlementData} />
            {visible && <Drawer
                title={
                    <Profile
                        title={empProfile.user.displayName}
                        rowData={empProfile}
                    />
                }
                placement="right"
                onClose={onClose}
                visible={visible}
                width={600}
                className="drawer-rebalance-section"
            >
                <div className="drawer-content-container">
                    <div className="warning">
                        <p>Require Attention ({count})</p>
                    </div>
                    <div className="table-container">
                        <table className="drawer-table">
                            <tr className="head-tr">
                                <th className="item">Item</th>
                                <th>Declared amount</th>
                                <th>Proof Submitted</th>
                                <th>Difference</th>
                            </tr>
                            <div className="thin-line-breaker"></div>
                            {Object.keys(
                                empProfile["deductions"]["deductionProofDifference"]
                            ).map((inv) => {
                                return (
                                    <>
                                        {Math.min(
                                            ...Object.values(
                                                empProfile["deductions"][
                                                "deductionProofDifference"
                                                ][inv]
                                            )
                                        ) < 0 && <h2>{inv} Investment</h2>}
                                        {Object.keys(
                                            empProfile["deductions"][
                                            "deductionProofDifference"
                                            ][inv]
                                        )
                                            .filter(
                                                (item) =>
                                                    empProfile["deductions"][
                                                    "deductionProofDifference"
                                                    ][inv][item] !== 0
                                            )
                                            .map((val) => {
                                                return (
                                                    <tr>
                                                        <td style={{ color: "#505057" }}>
                                                            {config.deducationList[inv]["subSections"][val]}
                                                        </td>
                                                        <td
                                                            style={{ fontWeight: "700", color: "#151b30" }}
                                                        >
                                                            {
                                                                empProfile["deductions"][
                                                                "deductionAllotted"
                                                                ][inv][val]
                                                            }
                                                        </td>
                                                        <td
                                                            style={{ fontWeight: "700", color: "#151b30" }}
                                                        >
                                                            {
                                                                empProfile["deductions"][
                                                                "deductionsProof"
                                                                ][inv][val]
                                                            }
                                                        </td>
                                                        <td
                                                            style={{ fontWeight: "700", color: "#de2424" }}
                                                        >
                                                            {
                                                                empProfile["deductions"][
                                                                "deductionProofDifference"
                                                                ][inv][val]
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </>
                                );
                            })}
                        </table>

                    </div>
                </div>
            </Drawer>}
        </div>
    );
};

export default Declaration;
