import EmployeeLogo from "../../assets/employeeProfile/EmployeeLogo.svg";


const Profile = ({ title, empId, img, rowData }) => {
    return (
        <div className="flex align-center">
            <img src={rowData.user.image ? rowData.user.image : EmployeeLogo} alt="" style={{ height: "2rem", width: "2.1rem", borderRadius: "50%", marginRight: "0.5rem" }} />
            <div className="profile">
                <p>{title}</p>
                <p style={{ fontSize: "0.75rem" }}>Employee ID: {rowData.user.currentOrganisationMeta.employeeId}</p>
            </div>
        </div>
    )
}

export default Profile
