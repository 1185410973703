/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Spin,
  message,
  Upload,
  notification,
  Table,
  Progress,
  Row,
  Col,
} from "antd";
import { houseRentUpdate } from "../../apis/organisation";
import { Content } from "antd/lib/layout/layout";
import config from "../../utils/config";
import uploadLogo from "../../assets/icons/savefile.svg";
import { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createLog } from "../../apis/user";
import NormalButton from "../ComponentLibrary/ButtonComponents/NormalButton";

const { Dragger } = Upload;

const HRAAllowances = ({ user, handleAction, commonColumn }) => {
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [HRAData, setHRAData] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [groupId, setGroupId] = useState("general");

  const heading = ["Upload the Document", "Verify and Update"];
  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/parser/hra`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        setPageState(1);
        setHRAData([...response]);
      }
    },
  };

  const reset = () => {
    setHRAData([]);
    setPageState(0);
    setCompleted(0);
    setGroupId("general");
  };

  const updateAllowance = async () => {
    if (HRAData.length === 0) {
      message.error("Please Upload The File");
      return;
    }
    setIsLoading(true);
    let totalSuccess = 0;
    let { financialYear, month } = config.checkFinancialYear();
    for (const element of HRAData) {
      let { houseRent, id } = element;
      await houseRentUpdate({
        financialYear,
        organisationId: user.organisationId,
        id,
        houseRent,
        month
      });
      totalSuccess += 1;
      setCompleted(totalSuccess);
    }
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organisation User updated ${HRAData.length} number of HRA Data`,
      category: 15,
    });
    notification.success({
      message: "Successfully Uploaded the House Rent Data",
    });
    setIsLoading(false);
    reset();
  };

  return (
    <div className="">
      {isLoading && pageState === 1 && (
        <Progress
          percent={Math.round((completed / HRAData.length) * 100)}
          size="small"
          status="active"
        />
      )}
      <Spin size="large" spinning={isLoading}>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="flex align-center">
              {pageState !== 0 && (
                <ArrowLeftOutlined
                  onClick={() => {
                    let page = pageState;
                    page = page - 1;
                    setPageState(page);
                  }}
                  style={{ marginRight: 10 }}
                  className="arrow-left-button font-weight-700"
                />
              )}
              <h3 className="font-weight-global-700 font-size-global-24">{heading[pageState]}</h3>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {pageState === 1 && (
              <div className="flex w-full justify-end">
                <NormalButton
                  onClick={reset}
                  type={5}
                  buttonText="Cancel"

                  className="cancel-button"
                  style={{
                    border: "none",
                    boxShadow: "none",
                    marginRight: 10,
                  }}
                />
                <NormalButton
                  type={13}
                  buttonText="Upload House Rent Detail"
                  onClick={updateAllowance}
                  className="submit-button"
                  style={{
                    marginRight: "0px",
                    padding: "0px 24px",
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
        {pageState === 0 && (
          <UploadDocument
            platforms={{ ...uploadProps }}
            user={user}
            groupId={groupId}
            handleAction={handleAction}
          />
        )}
        {pageState === 1 && (
          <VerifyAndUpload HRAData={HRAData} commonColumn={commonColumn} />
        )}
      </Spin>
    </div>
  );
};

const UploadDocument = ({ platforms, user, handleAction }) => {
  return (
    <Content className="bulk-wrapper-left">
      <div>
        <p className="paper-title">
          Download Sample in{" "}
          <a
            className="download-employee-list"
            onClick={() =>
              handleAction(
                `${config.rootUrl}/api/generate/document/hra/${user.organisationId}`,
                "HRA_Data.xlsx"
              )
            }
          >
            .xlsx format
          </a>{" "}
          file to ensure that you have the correct file ready to import.
        </p>
      </div>
      <div>
        <Dragger
          {...platforms}
          showUploadList={false}
          height={300}
          className="bulk-wrapper-dragger"
        >
          <p className="ant-upload-drag-icon">
            <img alt="" src={uploadLogo} height="80" width="80" />
          </p>

          <p className="ant-upload-text">Drag and drop CSV file</p>
          <p className="ant-upload-hint">
            or <u>Click Here</u> to upload
          </p>
        </Dragger>
      </div>
    </Content>
  );
};

const VerifyAndUpload = ({ HRAData, commonColumn }) => {
  const tableData = [
    ...commonColumn,
    {
      title: "Living In Metro",
      dataIndex: ["houseRent", "isMetro"],
      key: "isMetro",
      render: (data) => <span>{data ? "Yes" : "No"}</span>,
    },
    {
      title: "Rent Per Month",
      dataIndex: ["houseRent", "rent"],
      key: "rent",
    },
  ];

  return (
    <Table
      columns={tableData}
      dataSource={HRAData}
      pagination={false}
      scroll={{ x: 600, y: "65vh" }}
    />
  );
};

export default HRAAllowances;
