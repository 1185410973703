import { Spin, Modal, notification } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { organisaitonUserChangePassword } from "../../../apis/organisation";
import { createLog } from "../../../apis/user";
import { NormalButton } from "../../ComponentLibrary";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";

const errorDataState = {
  password: false,
  confirmPassword: false,
};

const errorMessages = {
  password: "",
  confirmPassword: "",
};

const UpdateEmployeePassowrd = ({
  modalProperties,
  user,
  setModalProperties,
  resetModals,
  setUpdateSelectedEmployee,
}) => {
  const [employeeData, setEmployeeData] = useState({
    id: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    ...errorDataState,
  });

  const [errorMessage, setErrorMessage] = useState({
    ...errorMessages,
  });

  useEffect(() => {
    if (user) {
      setEmployeeData({
        password: "",
        confirmPassword: "",
        id: user._id,
      });
    }
  }, [user]);
  const resetDetails = () => {
    setEmployeeData({
      id: "",
      password: "",
      confirmPassword: "",
    });
    setError({ ...errorDataState });
    setErrorMessage({ ...errorMessages });
    resetModals();
    setUpdateSelectedEmployee(null);
  };

  const saveDetails = async () => {
    let errors = {
      ...errorDataState,
    };
    let errorMessagesDemo = {
      ...errorMessage,
    };
    let localError = false;

    if (
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,100}$/.test(
        employeeData.password
      )
    ) {
      localError = true;
      errors.password = true;
      errorMessagesDemo.password = "Please enter a correct password";
    }

    if (employeeData.password !== employeeData.confirmPassword) {
      localError = true;
      errors.confirmPassword = true;
      errorMessagesDemo.confirmPassword =
        "Password and confirm password doesnot match";
    }

    if (localError) {
      setErrorMessage({ ...errorMessagesDemo });
      setError({ ...errors });
      return;
    }
    let modalProps = modalProperties;
    modalProps.changePassword.loading = true;
    setModalProperties({ ...modalProps });
    let res = await organisaitonUserChangePassword({ ...employeeData });
    if (res.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Organiation User has changed ${user.displayName}(${user.currentOrganisationMeta.employeeId})'s password`,
        category: 16,
      });
      notification.success({
        message: `Successfully updated the employee password`,
      });
    } else {
      notification.error({
        message: `Sorry Unable to update the employee password`,
      });
    }
    resetDetails();
  };

  return (
    <Modal
      centered
      closable={false}
      width={400}
      visible={modalProperties.changePassword.visible}
      className="modal-container"
      footer={null}
    >
      <Spin spinning={modalProperties.changePassword.loading} size="large">
        <Content className={"modal-body"}>
          <h1 className="font-weight-global-700 font-size-global-24 modal-margin-left-global-heading">Change Password</h1>
          <div className="org-data-container modal-alignment-center-global ">
            <InputFieldComponent
              value={employeeData.password}
              id="PssdBttnId"
              labeltext={"Password"}
              type={"password"}
              className="general-input-field"
              placeholder="Employee Password"
              bordered={false}
              onChange={(e) => {
                setEmployeeData({
                  ...employeeData,
                  password: e.target.value,
                });
              }}
              size={"large"}
            />
            {error.password && (
              <p className="error m-top-minus-1">{errorMessage.password}</p>
            )}
          </div>
          <div className="org-data-container modal-alignment-center-global modal-margin-top-reduce-global-1">
            <InputFieldComponent
              value={employeeData.confirmPassword}
              id="CnfrmPssdBttnId"
              type={"password"}
              labeltext={"Confirm Password"}
              className="general-input-field"
              placeholder="Employee Confirm Password"
              bordered={false}
              onChange={(e) => {
                setEmployeeData({
                  ...employeeData,
                  confirmPassword: e.target.value,
                });
              }}
              size={"large"}
            />
            {error.confirmPassword && (
              <p className="error m-top-minus-1">
                {errorMessage.confirmPassword}
              </p>
            )}
          </div>
          <div className="modal-margin-left-global-1 modal-margin-top-add-global-2">
            <NormalButton
              onClick={saveDetails}
              id="CPBttnId"
              className="submit-button"
              type={13}
              buttonText={"Update"}
              style={{
                marginRight: "0px",
                padding: "0px 24px",
              }}
            />
            <NormalButton
              onClick={resetDetails}
              className="cancel-button"
              type={5}
              buttonText={"Cancel"}
              style={{
                border: "none",
                boxShadow: "none",
                marginRight: "0px",
              }}
            />
          </div>
        </Content>
      </Spin>
    </Modal>
  );
};

export default UpdateEmployeePassowrd;
