import React, { useEffect, useState } from "react";
import { HeaderComponent } from "../Common";
import ca from "../../assets/icons/activeIcons/ca.svg";
import { Select } from "antd";
import {
  checkFinancialYear,
  findOneInArrayOfObjects,
  monthList
} from "../../utils/config";
import { Option } from "antd/lib/mentions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import organisation from "../../redux/modules/organisation";
import payrollHistoryLogoActive from "../../assets/icons/activeIcons/runPay.svg"
import { paths } from "../../constants/routes";
import TrialPage from "../Common/TrialComponent";
import payrollHistoryBgImage from "../../assets/dummyPages/payrollHistoryBgImage.png"

const Payroll = ({ user, payrolls, organisationReducer, isTrial }) => {
  const [currFinancialYear, setCurrFinancialYear] = useState(
    checkFinancialYear().financialYear
  );
  const [financialYearList, setFinancialYearList] = useState([]);
  const [processedPayroll, setProcessedPayroll] = useState([]);

  useEffect(() => {
    function calculateFinancialYear() {
      let startFYear = 2020;
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let resFinancialYearList = [];
      for (let i = startFYear; i < currentYear + 1; i++) {
        let nextYear = i + 1;
        let tempFYear = `${i}-${nextYear}`;
        resFinancialYearList.push(tempFYear);
      }
      resFinancialYearList.reverse();
      setFinancialYearList([...resFinancialYearList]);
    }
    calculateFinancialYear();
  }, []);

  useEffect(
    () => {
      let payrollArray = [];
      const { month: mon, financialYear } = checkFinancialYear();


      let month = financialYear !== currFinancialYear ? 2 : mon;

      let years = currFinancialYear.split("-");
      for (let i = 3; i < 12; i++) {
        let res = findOneInArrayOfObjects(payrolls, "month", i);
        if (i <= month || (month >= 0 && month < 3)) {
          if (res) {
            payrollArray.push({
              ...res,
              payrollAvailable: true,
              year: parseInt(years[0])
            });
          } else {
            payrollArray.push({
              month: i,
              year: parseInt(years[0]),
              financialYear: currFinancialYear,
              totalEarnings: 0,
              totalPayslipsGenerated: 0,
              payrollFreeze: false,
              payrollAvailable: false,
              totalTax: 0,
            });
          }
        } else {
          break;
        }
      }
      for (let i = 0; i < 3; i++) {
        let res = findOneInArrayOfObjects(payrolls, "month", i);
        if (month < 3 && i <= month) {
          if (res) {
            payrollArray.push({
              ...res,
              payrollAvailable: true,
              year: parseInt(years[1])
            });
          } else {
            payrollArray.push({
              month: i,
              year: parseInt(years[1]),
              financialYear: currFinancialYear,
              totalEarnings: 0,
              totalPayslipsGenerated: 0,
              payrollFreeze: false,
              totalTax: 0,
              payrollAvailable: false
            });
          }
        } else {
          break;
        }
      }
      payrollArray = payrollArray.reverse();
      setProcessedPayroll([...payrollArray]);
    },
    [currFinancialYear, payrolls]
  );

  useEffect(() => {
    organisationReducer.freezeResult(`${user.organisationId}/${currFinancialYear}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currFinancialYear, user.organisationId])
  useEffect(() => {
    document.title = 'Payroll | History';
  });
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Process payroll, mail payslips and disburse payouts in a few clicks."} bgImage={payrollHistoryBgImage}
    /> :
      <div className="payroll">
        <HeaderComponent name="Payroll History" icon={payrollHistoryLogoActive} />
        <div className="payroll-container">
          <div
            className="flex align-center justify-start"
            style={{ marginBottom: "2rem" }}
          >
            <p className="font-weight-700 text-size-14 mb-2 ml-5">Financial Year</p>
            <Select
              defaultValue={currFinancialYear}
              className="general-ant-field-select ml-2"
              style={{
                width: "6rem",
                marginLeft: "1rem",
                background: "none",
                padding: 0
              }}
              size="large"
              onChange={e => {
                setCurrFinancialYear(e);
                organisationReducer.freezeResult(`${user.organisationId}/${e}`);
              }}
              bordered={false}
            >
              {financialYearList.map((val, index) =>
                <Option key={`opt-${index}`} value={val}>
                  {val}
                </Option>
              )}
            </Select>
          </div>
          {
            processedPayroll.map((val, index) => <PayrollCards {...val} key={`payroll-list-${index}`} />)
          }
        </div>
      </div>
  );
};

const PayrollCards = ({ payrollAvailable, financialYear, month, payrollFreeze, totalEarnings, totalPayslipsGenerated, totalTax }) =>
  <Link to={`${paths.org.process}/${financialYear}/${month}`}>
    <div className="single-payroll">
      <div className="flex align-center justify-between single-payroll-heading">
        <h3>
          Payroll for {monthList[month]}
        </h3>
        {payrollAvailable && (payrollFreeze
          ? <div className="payroll-status-processed">
            <img src={ca} alt="" />
            <p>Processed</p>
          </div>
          : <div className="payroll-status-draft">
            <img src={ca} alt="" />
            <p>Draft</p>
          </div>)}
        {!payrollAvailable && <div className="payroll-status-draft">
          <img src={ca} alt="" />
          <p>Yet to be generated</p>
        </div>}
      </div>
      <div className="flex align-center justify-between">
        <div className="left-flex">
          <div className="group">
            <h4>Payroll Cost</h4>
            <h3>{payrollAvailable ? totalEarnings : "Yet to be generated"}</h3>
          </div>
          <div className="group">
            <h4>Total Tax Paid</h4>
            <h3>{payrollAvailable ? totalTax : "Yet to generated"}</h3>
          </div>
          <div className="group">
            <h4>Salaries Processed</h4>
            <h3>{payrollAvailable ? totalPayslipsGenerated : "Yet to generated"}</h3>
          </div>
        </div>
        <div className="right-flex">
          <div className="flex align-center justify-center view">
            <p>View</p>
            <img src={ca} alt="" style={{ marginLeft: "0.5rem" }} />
          </div>
        </div>
      </div>
    </div>
  </Link>;

const PayrollContainer = connect(
  state => ({
    user: state.auth.user,
    payrolls: state.organisation.freezeResult,
    isTrial: state.auth.user.isTrial,

  }),
  dispatch => ({
    organisationReducer: organisation.getActions(dispatch)
  })
)(Payroll);
export default PayrollContainer;
