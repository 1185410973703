import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Form from "./Component/Forms";
import TableforSupport from "./Component/Table";
import { HeaderComponent } from "../Common";
import { getSupportRequestData } from "../../apis/employee";
import { connect } from "react-redux";
import TrialPage from "../Common/TrialComponent";
import perqsBgImage from "../../assets/dummyPages/perqsBgImage.png"

const Support = ({ user, isTrial }) => {
  const [loading, setLoading] = useState(false);
  const [supportListData, setSupportListData] = useState([]);

  useEffect(() => {
    setLoading(true);
    getSupportRequestData(user._id).then((data) => {
      if (data.data.data)
        setSupportListData([...data.data.data.requests]);
      setLoading(false);
    });
    setLoading(false);
  }, [loading, user._id]);

  const updateSupportData = () => {
    setLoading(true);
    getSupportRequestData(user._id).then((data) => {
      if (data.data.data.requests)
        setSupportListData([...data.data.data.requests]);
      setLoading(false);
    });
    setLoading(false);
  };
  useEffect(() => {
    document.title = 'Account & Activity | Support';
  });

  return (
    isTrial ? <TrialPage user={user} trialMessage={" Designed for your needs, Mool is always at your service"} bgImage={perqsBgImage}
    /> :
      <>
        <div className="site-layout">
          <HeaderComponent name="Support" show={false} showMoolCoins={true} />
          <div className="support-container">
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={24} md={6} className="form">
                <Form updateSupportData={updateSupportData} />
              </Col>
              <Col xs={24} sm={24} md={18} className="table">
                <TableforSupport
                  supportListData={supportListData}
                  loading={loading}
                />
              </Col>
            </Row>
          </div>
        </div>
      </>
  );
};

const SupportContainer = connect((state) => ({
  user: state.auth.user,
  isTrial: state.auth.user.isTrial,
}))(Support);

export default SupportContainer;
