import { NormalButton } from "../../ComponentLibrary";
import { message } from "antd";
import "./TrialComponent.less"
import { sendPotentialClientMail } from "../../../apis/organisation";

const TrialPage = ({ bgImage, trialMessage, user }) => {

    const clickHandler = async (e) => {
        let mailResult = await sendPotentialClientMail({
            orgName: user.organisationName,
            PAN: user.PAN,
        });
        console.log(mailResult);
        if (mailResult.status === 200) {
            message.success("We have received your request we will get back to you soon")
        }
    };



    return (
        <div className="mainDiv">
            < img className="backGroundImage" src={bgImage} alt="" />
            <div className="SignUpContent">
                <div className="signUpMessage">
                    <p className="signUpMessageHeader">This is a premium feature!</p>
                    <p style={{ fontWeight: '600' }} > {trialMessage}</p>
                    <div className="signUpButton">
                        <NormalButton
                            buttonText={"Request for full access"}
                            type={13}
                            onClick={clickHandler}
                        />
                    </div>
                </div>

            </div>
        </div >
    )
}

export default TrialPage;