/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Spin,
  Select,
  message,
  Upload,
  notification,
  Row,
  Col,
  Table,
} from "antd";
import { updateAttendanceReport } from "../../apis/organisation";
import { Content } from "antd/lib/layout/layout";
import config, { checkFinancialYear, monthList } from "../../utils/config";
import uploadLogo from "../../assets/icons/savefile.svg";
import { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createLog } from "../../apis/user";
import NormalButton from "../ComponentLibrary/ButtonComponents/NormalButton";

const { Dragger } = Upload;
const { Option } = Select;

const Attendance = ({ user, handleAction }) => {
  const { financialYear } = checkFinancialYear()
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [yearList, setYearList] = useState([]);
  const [attendanceData, setAttendanceData] = useState({
    financialYear: financialYear,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    employeeData: []
  });

  useEffect(() => {
    let startFYear = 2015;
    let currentYear = new Date().getFullYear();
    let yList = []
    for (let i = startFYear; i <= currentYear; i++) {
      yList.push(i)
    }
    setYearList([...yList])

  }, [user]);

  useEffect(() => {
    let startFYear = 2015;
    let currentYear = new Date().getFullYear();
    let yList = [];
    for (let i = startFYear; i <= currentYear; i++) {
      yList.push(i);
    }
    setYearList([...yList]);
  }, [user]);

  const heading = ["Upload the Document", "Verify and Update"];

  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/parser/attendance/${attendanceData.month}/${attendanceData.financialYear}`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        setPageState(1);
        setAttendanceData({
          financialYear,
          year: new Date().getFullYear(),
          month: new Date().getMonth(),
          employeeData: response.dataList
        });
      }
    },
  };

  const reset = () => {
    setAttendanceData({
      financialYear: financialYear,
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      employeeData: [],
    });
    setPageState(0);
  };

  const updateAttendance = async () => {
    if (attendanceData.month > 11 && attendanceData.month < 0) {
      message.error("Please enter month");
      return;
    }
    if (attendanceData.employeeData.length === 0) {
      message.error("Please upload xlsx file");
      return;
    }
    setIsLoading(true);

    for (let att of attendanceData.employeeData) {
      await updateAttendanceReport(att);
    }
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `Organisation user updated ${attendanceData.employeeData.length} number of attendance for ${monthList[attendanceData.month]}`,
      category: 15,
    });
    notification.success({
      message: "Successfully uploaded the attendance report",
    });

    setIsLoading(false);
    reset();
  };

  return (
    <Spin spinning={isLoading} size="large">
      <Row gutter={[20, 20]}>
        <Col sm={24} xs={24} md={12} lg={12}>
          {pageState !== 0 && (
            <ArrowLeftOutlined
              onClick={() => {
                let page = pageState;
                page = page - 1;
                setPageState(page);
              }}
              style={{ marginRight: 5 }}
              className="arrow-left-button font-weight-700"
            />
          )}
          <span className="font-weight-global-700 font-size-global-24">{heading[pageState]}</span>
        </Col>
        <Col sm={24} xs={24} md={6} lg={6}>
          {pageState === 0 && (
            <div className="radio-group">
              <p className="general-ant-field-label">Select Year</p>
              <Select
                className="general-ant-field-select"
                style={{ width: "100%", marginTop: 10 }}
                size="large"
                value={attendanceData.year}
                onChange={(e) => {
                  let financialYearData =
                    attendanceData.month < 3
                      ? `${parseInt(e) - 1}-${parseInt(e)}`
                      : `${parseInt(e)}-${parseInt(e) + 1}`;
                  setAttendanceData({
                    ...attendanceData,
                    year: parseInt(e),
                    financialYear: financialYearData,
                  });
                }}
                bordered={false}
              >
                {yearList.map((val, index) => (
                  <Option key={`yearlist-${index}`} value={val}>
                    {val}
                  </Option>
                ))}
              </Select>
            </div>
          )}

          {pageState === 1 && (
            <div className="radio-group">
              <p className="general-ant-field-label">Financial Year</p>
              <p className="app-label-3">{attendanceData.financialYear}</p>
            </div>
          )}
        </Col>
        <Col sm={24} xs={24} md={6} lg={6}>
          {pageState === 0 && (
            <div className="radio-group">
              <p className="general-ant-field-label">Select Month</p>
              <Select
                className="general-ant-field-select"
                size="large"
                value={attendanceData.month}
                style={{ width: "100%", marginTop: 10 }}
                onChange={(e) => {
                  let financialYearData =
                    parseInt(e) < 3
                      ? `${attendanceData.year - 1}-${attendanceData.year}`
                      : `${attendanceData.year}-${attendanceData.year + 1}`;
                  setAttendanceData({
                    ...attendanceData,
                    financialYear: financialYearData,
                    month: parseInt(e),
                  });
                }}
                placeholder="month"
                defaultValue={new Date().getMonth()}
                bordered={false}
              >
                {config.monthList.map((val, index) => (
                  <Option value={index} key={`month-${index}`}>
                    {val}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          {pageState === 1 && (
            <div>
              <NormalButton
                onClick={reset}
                type={5}
                buttonText="Cancel"
                className="cancel-button"
                style={{
                  border: "none",
                  boxShadow: "none",
                  marginRight: "0px",
                }}
              />
                <NormalButton
                  onClick={updateAttendance}
                  type={13}
                  buttonText="Upload Data"
                  className="submit-button"
                  style={{
                    marginRight: "10px",
                    padding: "0px 24px",
                  }}
                />
            </div>
          )}
        </Col>
      </Row>
      {pageState === 0 && (
        <UploadDocument
          platforms={{ ...uploadProps }}
          user={user}
          handleAction={handleAction}
          year={attendanceData.year}
          month={attendanceData.month}
        />
      )}
      {pageState === 1 && <VerifyAndUpload attendanceData={attendanceData} />}
    </Spin>
  );
};

const UploadDocument = ({ platforms, user, handleAction, year, month }) => {
  return (
    <Content className="bulk-wrapper-left">
      <div className="paper-header-container">
        <div>
          <p className="paper-title">
            Download Sample in{" "}
            <a
              className="download-employee-list"
              onClick={() =>
                handleAction(
                  `${config.rootUrl}/api/attendance/excel/${user.organisationId}/${month}/${year}`,
                  "Attendance.xlsx"
                )
              }
            >
              .xlsx format
            </a>{" "}
            file to ensure that you have the correct file ready to import.
          </p>
        </div>
      </div>
      <div>
        <div>
          <Dragger
            {...platforms}
            showUploadList={false}
            height={300}
            className="bulk-wrapper-dragger"
          >
            <p className="ant-upload-drag-icon">
              <img alt="" src={uploadLogo} height="80" width="80" />
            </p>

            <p className="ant-upload-text">Drag and drop CSV file</p>
            <p className="ant-upload-hint">
              or <u>Click Here</u> to upload
            </p>
          </Dragger>
        </div>
      </div>
    </Content>
  );
};

const VerifyAndUpload = ({ attendanceData }) => {
  const widthTwo = 150;
  const tableData = [
    {
      title: "Name",
      dataIndex: ["attendance", "NAME"],
      key: "Name",
      width: widthTwo,
      fixed: "left",
    },
    {
      title: "Employee ID",
      dataIndex: ["attendance", "EMPLOYEE_ID"],
      key: "EMPLOYEE_ID",
      width: widthTwo,
      fixed: "left",
    },
    {
      title: "Total Working Days",
      dataIndex: ["attendance", "totalWorkingDays"],
      key: "totalWorkingDays",
      width: widthTwo,
    },
    {
      title: "Total Absent Days",
      dataIndex: ["attendance", "totalAbsentDays"],
      key: "totalAbsentDays",
      width: widthTwo,
    },
    {
      title: "Total Weekly Off",
      dataIndex: ["attendance", "totalW_O"],
      key: "totalW_O",
      width: widthTwo,
    },
    {
      title: "Total Sick Leave",
      dataIndex: ["attendance", "totalSickLeaveTaken"],
      key: "totalSickLeaveTaken",
      width: widthTwo,
    },
    {
      title: "Total Holidays",
      dataIndex: ["attendance", "totalHolidays"],
      key: "totalHolidays",
      width: widthTwo,
    },
    {
      title: "Total Personal Leaves",
      dataIndex: ["attendance", "totalP_L"],
      key: "totalP_L",
      width: widthTwo,
    },
    {
      title: "Total Client Leaves",
      dataIndex: ["attendance", "totalC_L"],
      key: "totalC_L",
      width: widthTwo,
    },
    {
      title: "Total C/O",
      dataIndex: ["attendance", "totalC_O"],
      key: "totalC_O",
      width: widthTwo,
    },
    {
      title: "Total Leave Taken",
      dataIndex: ["attendance", "totalLeaveTaken"],
      key: "totalLeaveTaken",
      width: widthTwo,
    },
  ];
  return (
    <Table
      style={{ marginTop: 10 }}
      scroll={{ x: 600 }}
      columns={tableData}
      dataSource={attendanceData.employeeData}
    />
  );
};

export default Attendance;
