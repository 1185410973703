import { InfoCircleOutlined } from '@ant-design/icons';
import { message, Modal, notification } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { getEmployeeCTC } from '../../../../apis/employee';
import { getTermsAndConditions, minWagesCurrentStructure, saveMinWagesStructure, saveTermsAndConditions, updateApplicableStructure } from '../../../../apis/organisation';
import { checkFinancialYear } from '../../../../utils/config';
import { camelToSpaceSeparated } from '../../../../utils/utility';
import { NormalButton } from '../../../ComponentLibrary';
import Spinner from '../../../ComponentLibrary/Spinner/Spinner';
import InputNumberComponent from '../../../InputLibrary/InputNumberComponent/InputNumberComponent';


const { month, financialYear } = checkFinancialYear();

export const PayStructureUpdate = ({ user, loading, setLoading, showModal, setShowModal, resetUpdatePayStructure, generatedStructure, structure, setStructure }) => {

    const [isVisible, setIsVisible] = useState(false)
    const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
    const [showData, setShowData] = useState(false);
    const [ctc, setCtc] = useState({});

    useEffect(() => {
        if (user.organisationId) {
            getEmployeeCTC(user._id, financialYear).then(res => {
                if (res.data.success) {
                    setCtc(res.data.data)
                } else {
                    setCtc({})
                }
            });
        }
    }, [user]);
    useEffect(() => {
        if (user.organisationId) {
            getTermsAndConditions({ organisationId: user.organisationId, keyName: "minWages" }).then(res => {
                if (res.data.success) {
                    setAcceptTermsAndConditions(res.data.data)
                } else {
                    setAcceptTermsAndConditions(false)
                }
            }).catch(err => setAcceptTermsAndConditions(false))
        }
    }, [user, showData]);


    const updateTemporaryToggleOfPayStructure = async (value) => {
        const res = await updateApplicableStructure({
            id: user._id,
            financialYear,
            isApplicable: value
        });
        return res.data.success ?? false;
    }
    const runPayrollForNewStructure = async () => {
        const data = await minWagesCurrentStructure(user._id);
        let totalCTC = 0;
        if (data.data) {
            const { employer_ESI, employer_PF, employer_LWF, gratuity, totalFixedIncome } = data.data;
            totalCTC = totalFixedIncome + (employer_ESI + employer_PF + employer_LWF + gratuity);
        }
        return totalCTC;
    }


    const getUpdatedSalaryStructure = async () => {
        // check if the employer contribution incl. in ctc
        // enable the pay structure
        // run the payroll
        // if true then get the gross + emp contribution 
        // if sum is equal to monthly ctc
        // then continue ( update the payroll checked option )
        // if not then say error while update.
        await updateStructureData(false)
        if (!ctc) { return false; }
        const { isEmployerContributionIncludedInCTC, costToCompany } = ctc;
        const ctcAmount = costToCompany && costToCompany[month] && costToCompany[month].ctc ? costToCompany[month].ctc : 0;
        if (isEmployerContributionIncludedInCTC) {
            const res = await updateTemporaryToggleOfPayStructure(true);
            if (res) {
                const data = await runPayrollForNewStructure();
                await updateTemporaryToggleOfPayStructure(false);
                return data === ctcAmount / 12;
            }
            return false;
        } else {
            return true;
        }

    }

    const savePayStructure = async () => {
        setLoading(true)
        let isClearFlag = false;
        if (generatedStructure.basicPerMonth > structure.basic) {
            isClearFlag = true
        }
        if (generatedStructure.vdaPerMonth > structure.DA) {
            isClearFlag = true
        }
        if (getSpecialAllowances() >= 0) {
            const data = await getUpdatedSalaryStructure();
            if (data) {
                if (isClearFlag) {
                    if (acceptTermsAndConditions) {
                        await updateStructureData(true)
                    } else {
                        setIsVisible(true)
                        await warning();
                    }
                } else {
                    await updateStructureData(true)
                }
            } else {
                message.error("Employee salary is not sufficient, Please update the pay-structure!");
                setShowModal(false)
            }
        } else {
            message.error("Special allowances can not be less than zero!")
        }
        setLoading(false)
    }


    const updateStructureData = async (isPayrollChecked) => {
        const { basic, DA, HRA, LTA, FOOD, otherAllowances, customAllowances } = structure;
        const specialAllowance = getSpecialAllowances();
        const statutoryBonuse = getStatutoryBonus();
        const payStructure = { basic, DA, HRA, LTA, FOOD, allowances: otherAllowances, customAllowances, statutoryBonuse, specialAllowance };
        const res = await saveMinWagesStructure({ id: user._id, structure: payStructure, isPayrollChecked, financialYear });
        if (isPayrollChecked) {
            if (res.data.success) {
                notification.success({
                    message: "Structure saved successfully!"
                })
            } else {
                notification.success({
                    message: "Error while saving the structure!"
                })
            }
            setShowModal(false)
        }
        setLoading(true);
    }


    const updateAcceptTermsAndCondition = (value) => {
        saveTermsAndConditions({ organisationId: user.organisationId, keyName: "minWages", keyValue: value }).then(res => {
            if (res.data.success) {
                message.info("Status Recorded Successfully!")
                setShowData(!showData)
            }
        })
        setIsVisible(false);
    }

    const warning = async () => {
        Modal.warning({
            visible: isVisible,
            title: 'Warning Message From Mool!',
            content: 'Mool will not be liable for compliances if the configuration is set against the rules. Do you agree to continue?',
            okText: "I Accept",
            cancelText: "Cancel",
            closable: true,
            onOk: async () => {
                updateAcceptTermsAndCondition(true);
                await updateStructureData(true)
            },
            onCancel: () => {
                setIsVisible(false)
            },
        });
    };

    const getStatutoryBonus = () => {
        const { basic } = structure;
        return Math.round(basic * .0833);
    }

    const getSpecialAllowances = () => {
        const { basic, DA, HRA, LTA, FOOD, otherAllowances, customAllowances, statutoryBonuse } = structure;
        let totalOtherAllowances = 0;
        let totalCustomAllowances = 0;

        if (otherAllowances && Object.keys(otherAllowances).length) {
            for (let item in otherAllowances) {
                totalOtherAllowances += otherAllowances[item]
            }
        }
        if (customAllowances && Object.keys(customAllowances).length) {
            for (let item in customAllowances) {
                totalCustomAllowances += customAllowances[item]
            }
        }

        return structure.totalFixedIncome - (basic + DA + HRA + LTA + FOOD + totalOtherAllowances + totalCustomAllowances + statutoryBonuse)
    }


    // useEffect(() => {
    // TODO: Need to check with jyoti bhai = about allowances
    // const getEmployeeDeclarationValue = async (id) => {
    //     const { financialYear } = checkFinancialYear();
    //     try {
    //       let res = await getEmployeeDeclaration(`${id}/${financialYear}`);
    //       if (!res.data) resetUpdatePayStructure();
    //       setSelectedEmployeeDeclaration(res.data);
    //       setModalProperties({
    //         ...modalProperties,
    //         updateAllowances: {
    //           visible: true,
    //           loading: false,
    //         },
    //       });
    //       setSelectedEmployee(id);
    //     } catch (e) {
    //       resetUpdatePayStructure();
    //     }
    //   }
    //     if (user._id) {
    //         setLoading(true)
    //         setLoading(false)
    //     }
    // }, [user]);

    return (
        <Modal
            centered
            closable={false}
            visible={showModal && (generatedStructure && Object.keys(generatedStructure).length > 0) && (structure && Object.keys(structure).length > 0)}
            title={<>
                <h2>Define Min-Wage Structure</h2>
                <p className='m-top-10'>Configure the structure according to minimum wages rules</p>
            </>}
            footer={null}
        >
            <Spinner spinning={loading || (generatedStructure && Object.keys(generatedStructure).length <= 0)}>
                {
                    (generatedStructure && Object.keys(generatedStructure).length > 0) && (structure && Object.keys(structure).length > 0) ?
                        <Content className={"modal-body"}>
                            <div>

                                <InputNumberComponent
                                    labeltext={"Basic"}
                                    placeholder="Basic"
                                    bordered={false}
                                    size="large"
                                    width={"100%"}
                                    onChange={(value) => {
                                        setStructure({
                                            ...structure,
                                            basic: parseInt(value ?? 0)
                                        })
                                    }
                                    }
                                    value={
                                        structure.basic
                                    }
                                />
                                {generatedStructure.basicPerMonth &&
                                    <span className='m-left-15' style={generatedStructure.basicPerMonth <= structure.basic ? { color: "#4bb543" } : { color: "#cf142b" }}>
                                        Should be greater than <b>{generatedStructure.basicPerMonth}</b> INR
                                    </span>
                                }
                            </div>
                            <div className='m-top-20'>
                                <InputNumberComponent
                                    labeltext={"HRA"}
                                    placeholder="HRA"
                                    width={"100%"}
                                    bordered={false}
                                    size="large"
                                    onChange={(value) => {
                                        setStructure({
                                            ...structure,
                                            HRA: parseInt(value ?? 0)
                                        })
                                    }
                                    }
                                    value={
                                        structure.HRA
                                    }
                                />
                                {generatedStructure.HRA &&
                                    <span className='m-left-15' style={generatedStructure.HRA <= structure.HRA ? { color: "#4bb543" } : { color: "#cf142b" }}>
                                        Should be greater than <b>{generatedStructure.HRA}</b> INR
                                    </span>
                                }
                            </div>
                            <div className='m-top-20'>
                                <InputNumberComponent
                                    labeltext={"LTA"}
                                    placeholder="LTA"
                                    width={"100%"}
                                    bordered={false}
                                    size="large"
                                    onChange={(value) => {
                                        setStructure({
                                            ...structure,
                                            LTA: parseInt(value ?? 0)
                                        })
                                    }
                                    }
                                    value={
                                        structure.LTA
                                    }
                                />
                                {generatedStructure.LTA &&
                                    <span className='m-left-15' style={generatedStructure.LTA <= structure.LTA ? { color: "#4bb543" } : { color: "#cf142b" }}>
                                        Should be greater than <b>{generatedStructure.LTA}</b> INR
                                    </span>
                                }
                            </div>
                            <div className='m-top-20'>
                                <InputNumberComponent
                                    labeltext={"Dearness"}
                                    placeholder="Dearness"
                                    bordered={false}
                                    width={"100%"}
                                    size="large"
                                    onChange={(value) => {
                                        setStructure({
                                            ...structure,
                                            DA: parseInt(value ?? 0)
                                        })
                                    }
                                    }
                                    value={
                                        structure.DA
                                    }
                                />
                                {generatedStructure.vdaPerMonth &&
                                    <span className='m-left-15' style={generatedStructure.vdaPerMonth <= structure.DA ? { color: "#4bb543" } : { color: "#cf142b" }}>
                                        Should be greater than <b>{generatedStructure.vdaPerMonth}</b> INR
                                    </span>
                                }
                            </div>
                            <div className='m-top-20'>
                                <InputNumberComponent
                                    labeltext={"FOOD"}
                                    placeholder="FOOD"
                                    width={"100%"}
                                    bordered={false}
                                    size="large"
                                    onChange={(value) => {
                                        setStructure({
                                            ...structure,
                                            FOOD: parseInt(value ?? 0)
                                        })
                                    }
                                    }
                                    value={
                                        structure.FOOD
                                    }
                                />
                            </div>
                            <div>
                                {structure.otherAllowances && Object.keys(structure.otherAllowances).length ? Object.keys(structure["otherAllowances"]).map((val) => {
                                    return (
                                        <div key={val} className="m-top-20">
                                            <React.Fragment>
                                                <InputNumberComponent
                                                    labeltext={camelToSpaceSeparated(val)}
                                                    placeholder={camelToSpaceSeparated(val)}
                                                    width={"100%"}
                                                    bordered={false}
                                                    size="large"
                                                    onChange={(value) => {
                                                        setStructure({
                                                            ...structure,
                                                            otherAllowances: {
                                                                ...structure.otherAllowances,
                                                                [val]: value
                                                            }
                                                        })
                                                    }
                                                    }
                                                    value={
                                                        structure["otherAllowances"][val]
                                                    }
                                                />
                                                {generatedStructure[val] &&
                                                    <span className='m-left-15' style={generatedStructure[val] <= structure["otherAllowances"][val] ? { color: "#4bb543" } : { color: "#cf142b" }}>
                                                        Should be greater than <b>{generatedStructure[val]}</b> INR
                                                    </span>
                                                }
                                            </React.Fragment>
                                        </div>
                                    );
                                }) : <></>}
                            </div>
                            <div>
                                {structure.customAllowances && Object.keys(structure.customAllowances).length ? Object.keys(structure["customAllowances"]).map((val) => {
                                    return (
                                        <div className="flex justify-between align-center my-20 border-box">
                                            <div key={val}>
                                                {structure["customAllowances"][val].applicable && (
                                                    <React.Fragment>
                                                        <InputNumberComponent
                                                            labeltext={structure["customAllowances"][val].label}
                                                            placeholder={structure["customAllowances"][val].label}
                                                            bordered={false}
                                                            size="large"
                                                            onChange={(value) => {
                                                                setStructure({
                                                                    ...structure,
                                                                    hra: value
                                                                })
                                                            }
                                                            }
                                                            value={
                                                                structure["customAllowances"][val].amount
                                                            }
                                                        />
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    );
                                }) : <></>}
                            </div>
                            <div className='m-top-20'>
                                <InputNumberComponent
                                    labeltext={"Statutory Bonus"}
                                    placeholder="Statutory Bonus"
                                    width={"100%"}
                                    bordered={false}
                                    disabled={true}
                                    size="large"
                                    value={
                                        getStatutoryBonus()
                                    }
                                />
                            </div>
                            <div className='m-top-20'>
                                <InputNumberComponent
                                    labeltext={"Special Allowances"}
                                    placeholder="Special Allowances"
                                    width={"100%"}
                                    bordered={true}
                                    disabled={true}
                                    size="large"
                                    value={
                                        getSpecialAllowances()
                                    }
                                />
                                {getSpecialAllowances() < 0 &&
                                    <span className='m-left-15' style={{ color: "#cf142b" }}>
                                        Special Allowances can not be negative.
                                    </span>
                                }
                            </div>
                            <div className="submit-container-modal">
                                <NormalButton
                                    onClick={resetUpdatePayStructure}
                                    buttonText={"Cancel"}
                                    type={5}
                                    className="cancel-button"
                                    style={{
                                        border: "none",
                                        boxShadow: "none",
                                        marginRight: "0px",
                                    }}
                                />
                                <NormalButton
                                    onClick={async () => { await savePayStructure() }}
                                    buttonText={"Update"}
                                    className="submit-button"
                                    type={13}
                                    style={{
                                        marginRight: "0px",
                                        padding: "0px 24px",
                                    }}
                                />
                            </div>
                        </Content> : <></>
                }

                <p>{acceptTermsAndConditions ? <><InfoCircleOutlined /> Already approved to terms and conditions.</> : ""}</p>
            </Spinner>
        </Modal >
    )

}


