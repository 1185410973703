import { Table } from "antd";
import { useEffect, useState } from "react";
import { getLogByUser } from "../../apis/user";
import { CATEGORIES } from "../../constants/logs";
import Spinner from "../ComponentLibrary/Spinner/Spinner";
import { Search } from "../Common";
import styles from "./style.less";

const roles = {
  org: "Organisation",
  employee: "Employee",
}

const IndividualLogs = ({ category, user }) => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const [searchLogs, setSearchLogs] = useState([]);
  useEffect(() => {
    getLogByUser(`${user._id}/${category}`)
      .then((res) => {
        setLogs([...res.data]);
        setSearchLogs([...res.data])
        setLoading(false);
      })
      .catch((_err) => {
        setLoading(false);
      });
  }, [category, user]);

  const columns = [
    {
      title: "User Role",
      dataIndex: ["role"],
      width: 250,
      render: (data) => roles[data]
    },
    {
      title: "Name",
      dataIndex: ["name"],
      onFilter: (v, d) => d.name.startsWith(v),
      sorter: (a, b) => a.name - b.name,
      width: 250,
    },
    {
      title: "Date",
      dataIndex: ["dateTime"],
      width: 150,
      sorter: (a, b) => new Date(a.dateTime) - new Date(b.dateTime),
      render: (d) => <p>{new Date(d).toDateString()}</p>,
    },
    {
      title: "Time",
      dataIndex: ["dateTime"],
      width: 150,
      render: (d) => (
        <p>
          {new Date(d).toLocaleTimeString("en-US", {
            hour12: true,
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
      ),
    },
    {
      title: "Activity",
      dataIndex: ["message"],
      width: 450,
    },
  ];
  return (
    <div>
      <div className="flexContainer">
        <div className="heading">
          <h1 className="h1-bold-24">{CATEGORIES[category]}</h1>{" "}
          <h3 className="subtitle1-med-16">
            All of the tasks or activities that people do are logged.
          </h3>
        </div>
        <div className={styles.searchBar}>
          <Search data={logs} setSearchLogs={setSearchLogs}></Search>
        </div>
      </div>
      <Spinner spinning={loading} size="large">
        <Table
          dataSource={searchLogs.reverse()}
          columns={columns}
          scroll={{ x: 600, y: "65vh" }}
        />
      </Spinner>
    </div>
  );
};

export default IndividualLogs;
