function ColorGenerator() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
        //   this runs the loop and gives us all the numbers/letters of the hex code of colors randomly generating each number/letter and is merged
        //  with '#' symbol as color is by default assigned '#'
    }

    return color;


}
export default ColorGenerator