import { useEffect, useState } from "react";
import { Layout, Steps } from "antd";
import Logo from "../../assets/common/logo.svg";
import { withRouter } from "react-router-dom";
import staticimg from "../../assets/orgloginflow/loginflow-constantImg.svg"
import {
  ChangePassword,
  OrganisationSettings,
  ManageAllowances,
  Structure,
} from "./Stages";
import auth from "./../../redux/modules/auth";
import organisation from "./../../redux/modules/organisation";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { changeLoginState } from "../../apis/auth";

const { Step } = Steps;

const { Content, Sider } = Layout;

const OrgLoginFlow = ({ authReducer, user, organisationReducer }) => {
  const steps = [
    {
      title: 'Password',
    },
    {
      title: 'Configuration',
    },
    {
      title: 'System',
    },
    {
      title: 'Allowance',
    },
  ];
  const [isCustomStructure, setIsCustomStructure] = useState(false);
  const [isPercentageStructure, setIsPercentageStructure] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const pageHeadings = [
    "Welcome! first things first",
    "Let's setup your organisation a bit",
    "How are you planning to use mool?",
    "How does your employee benefit look like?",
  ];

  const updateLoginState = async (pgn) => {
    await changeLoginState({ id: user._id, loginstate: pgn });
    if (pgn === 4) {
      authReducer.changeLoginState({ loginstate: pgn, role: user.role });
    }
  }

  const changePageState = async () => {
    await updateLoginState(pageNumber + 1);
    setPageNumber(pageNumber + 1);
  };

  const changePageStateBack = async () => {
    await updateLoginState(pageNumber - 1);
    setPageNumber(pageNumber - 1);
  };

  const changeCustomStructure = (type) => {
    setIsCustomStructure(type);
  };

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setIsCustomStructure(!user.moolSettings.structureOfMool)
      setIsPercentageStructure(user.moolSettings.isPercentageStructure)
      setPageNumber(user.loginstate);
    }
  }, [user, authReducer])
  const pages = [
    <ChangePassword
      user={user}
      changePageState={changePageState}
    />,
    <OrganisationSettings
      user={user}
      changePageState={changePageState}
      changePageStateBack={changePageStateBack}
      organisationReducer={organisationReducer}
    />,
    <Structure
      user={user}
      changePageState={changePageState}
      changePageStateBack={changePageStateBack}
      changeCustomStructure={changeCustomStructure}
      setIsPercentageStructure={setIsPercentageStructure}
    />,
    <ManageAllowances
      user={user}
      changePageState={changePageState}
      changePageStateBack={changePageStateBack}
      isCustomStructure={isCustomStructure}
      isPercentageStructure={isPercentageStructure}
    />
  ];
  useEffect(() => {
    document.title = 'Organization | Login Flow';
  });
  return (
    <Layout>
      <Sider className="constantSider">
        <img src={Logo} className="moolLogo" alt="Mool Logo"></img>
        <img style={{width: '35vw', height: '100vh',objectFit:'cover'}} src={staticimg} alt="" />
      </Sider>
      <Layout>
        <Content className="first-login-container">
          <section className="stage-container">
            <Steps current={pageNumber} labelPlacement={"vertical"} className='loginflowsteps'>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <h1 className="stage-heading">
              {pageHeadings[pageNumber]}
            </h1>
            {
              pages[pageNumber]
            }
          </section>
        </Content>
      </Layout>
    </Layout>
  );
};

OrgLoginFlow.propTypes = {
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loginstate: PropTypes.bool.isRequired,
};

const OrgLoginFlowContainer = connect(
  (state) => ({
    user: state.auth.user,
    isLoggedIn: state.auth.isLoggedIn,
    loginstate: state.auth.loginstate,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    organisationReducer: organisation.getActions(dispatch),
  })
)(OrgLoginFlow);

export default withRouter(OrgLoginFlowContainer);
