import { useState, useEffect } from "react"
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { HeaderComponent } from '../Common';
import EmployeeUploadFiles from "./Components/EmployeeUploadFiles";
import UploadList from "./Components/UploadList";
import organisation from "../../redux/modules/organisation";
import { Select } from "antd";
import { checkFinancialYear } from "../../utils/config";
const { Option } = Select;
const AllInvestementAllowance = ({ history, location, user }) => {

    let pageData = location.state
    const [pageState, setPageState] = useState(0)
    const [currFinancialYear, setCurrFinancialYear] = useState(checkFinancialYear().financialYear)
    const [employeeData, setEmployeeData] = useState({})
    const [financialYearList, setFinancialYearList] = useState([]);
    useEffect(() => {
        document.title = 'Investment | Allowances';
    });

    useEffect(() => {
        function calculateFinancialYear() {
            let startFYear = 2020;
            let currentDate = new Date();
            let currentYear = currentDate.getFullYear();
            let resFinancialYearList = [];
            for (let i = startFYear; i < currentYear + 1; i++) {
                let nextYear = i + 1;
                let tempFYear = `${i}-${nextYear}`;
                resFinancialYearList.push(tempFYear);
            }
            resFinancialYearList.reverse();
            setFinancialYearList([...resFinancialYearList]);
        }
        calculateFinancialYear();
    }, []);

    return (
        <div className="site-layout">
            <HeaderComponent name={pageData.name} showMoolCoins={true} />
            <div
                className="flex align-center justify-end"
                style={{ marginBottom: "2rem" }}
            >
                <h3 className="font-weight-700 text-size-21 mb-2 ml-5">Financial Year</h3>
                <Select
                    defaultValue={currFinancialYear}
                    className="general-ant-field-select ml-2"
                    style={{
                        width: "8rem",
                        marginLeft: "1rem",
                        background: "none",
                        padding: 0
                    }}
                    size="large"
                    onChange={e => {
                        setCurrFinancialYear(e);
                    }}
                    bordered={false}
                >
                    {financialYearList.map((val, index) =>
                        <Option key={`opt-${index}`} value={val}>
                            <h3>{val}</h3>
                        </Option>
                    )}
                </Select>
            </div>
            {
                pageState === 0
                    ? (
                        <UploadList
                            history={history}
                            setPageState={setPageState}
                            pageData={pageData}
                            user={user}
                            setEmployeeData={setEmployeeData}
                            financialYear={currFinancialYear}
                        />
                    )
                    : (
                        <EmployeeUploadFiles
                            user={user}
                            pageData={pageData}
                            employeeData={employeeData}
                            setEmployeeData={setEmployeeData}
                            setPageState={setPageState}
                            financialYear={currFinancialYear}
                        />
                    )
            }
        </div>
    )
}

const AllInvestementAllowanceContainer = connect((state) => ({
    user: state.auth.user,
}),
    (dispatch) => ({
        organisationReducer: organisation.getActions(dispatch),
    })
)(AllInvestementAllowance);

export default withRouter(AllInvestementAllowanceContainer);
