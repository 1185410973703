/* eslint-disable jsx-a11y/anchor-is-valid */
import { message, Modal, notification, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import Dragger from "antd/lib/upload/Dragger";
import { useState } from "react";
import {
  createDept,
  createOrUpdateDesignation,
  createOrUpdateStatus
} from "../../../apis/organisation";
import uploadLogo from "../../../assets/icons/savefile.svg";
import config, { rootUrl } from "../../../utils/config";
import axios from "../../../apis"

const BulkUpload = ({ type, updateFunction, modalStatus, setModalStatus }) => {
  const [loading, setLoading] = useState(false);

  const documents = {
    dept: {
      download: "department",
      link: "department/bulk/parser",
      uploadFunction: createDept,
      label: "Department Bulk Upload"
    },
    des: {
      download: "designation",
      link: "designation/bulk/parser",
      uploadFunction: createOrUpdateDesignation,
      label: "Designation Bulk Upload"
    },
    status: {
      download: "status",
      link: "status/bulk/parser",
      uploadFunction: createOrUpdateStatus,
      label: "Status Bulk Upload"
    }
  };

  const handleAction = async (url) => {
    let res = await axios({
      url, //your url
      method: 'GET',
      responseType: 'blob',
    })
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = urlDownload;
    link.setAttribute('download', `${type}.csv`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  const reset = async () => {
    setLoading(false);
    setModalStatus(false);
  };

  useState(() => {
    if (type !== "dept" || type !== "des" || type !== "status") {
      reset();
    }
  }, [type]);


  let uploadProps = {
    name: "file",
    headers: {
      authorization: localStorage.getItem("mool_auth")
    },
    multiple: false,
    progress: { strokeWidth: 2, showInfo: false },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "text/csv";
      if (!isJpgOrPng) {
        message.error("You can only upload CSV files!");
        setLoading(false);
        reset()
      }
      return isJpgOrPng;
    },
    async onChange(info) {
      let { response } = info.file;
      if (response) {
        let result = [...response];
        if (result.length) {
          for (const element of result) {
            let record = element;
            await documents[type].uploadFunction({ ...record });
          }
          await updateFunction();
        }
        notification.success({ message: "Successfully updated" });
        reset();
      }
    }
  };

  return (
    <Modal visible={modalStatus} footer={null} onCancel={reset} width={500}>
      <div className="flex align-center justify-between w-full">
        <h3 className="app-heading-2 font-weight-700">{documents[type].label}</h3>
      </div>
      <Content className="bulk-wrapper-left">
        <div className="paper-header-container">
          <div>
            <p className="paper-title">
              Download Sample in{" "}
              <a
                className="download-employee-list"
                onClick={() => handleAction(`${rootUrl}/api/config/upload/${documents[type].download}`)}
              >
                .csv format
              </a>{" "}
              file to ensure that you have the correct file ready to import.
            </p>
          </div>
        </div>
        <Spin spinning={loading} size="large">
          <div>
            <div>
              <Dragger
                {...uploadProps}
                action={`${config.rootUrl}/api/${documents[type].link}`}
                showUploadList={false}
                height={300}
                className="bulk-wrapper-dragger"
              >
                <p className="ant-upload-drag-icon">
                  <img alt="" src={uploadLogo} height="80" width="80" />
                </p>

                <p className="ant-upload-text">Drag and drop CSV file</p>
                <p className="ant-upload-hint">
                  or <u>Click Here</u> to upload
                </p>
              </Dragger>
            </div>
          </div>
        </Spin>
      </Content>
    </Modal>
  );
};

export default BulkUpload;
