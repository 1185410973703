import {
  Button,
  message,
  Modal,
  notification,
  Popconfirm,
  Radio,
  Spin,
  Table,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  addLoansAndSalaries,
  updateLoansAndAdvanceSalaryByRefID,
} from "../../apis/organisation";
import { deleteLogo, editLogo } from "../../assets/common/editAndDeleteLogos";
import plus from "../../assets/icons/whitePlus.svg";
import { monthList } from "../../utils/config";
import { timeDiffCalc } from "../../utils/dateCheckUtil";
import { NormalButton } from "../ComponentLibrary";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";

const options = [
  { label: "Loan", value: "0" },
  { label: "Advance Salary", value: "1" },
];

const LoanAndSalary = ({
  monthCloseFields,
  user,
  employeeList,
  deleteData,
  loading,
  startRefresh,
}) => {
  const [isloading, setIsLoading] = useState(false);
  const [singleModalShow, setSingleModalShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [monthCloseCount, setMonthCloseCount] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [emiAmount, setEmiAmount] = useState(0);

  const [fieldDetails, setFieldDetails] = useState({
    ID: "",
    type: "0",
    interestAmount: 0,
    emiAmount: 0,
    totalAmount: 0,
    organisationID: "",
    month: new Date().getMonth(),
    startMonth: "",
    endMonth: "",
    startYear: "",
    endYear: "",
  });

  const [updateData, setUpdateData] = useState({
    userId: "",
    month: 0,
    id: "",
    financialYear: 0,
    amount: 0,
  });

  const yearList = [];
  for (
    let year = new Date().getFullYear() - 10;
    year <= new Date().getFullYear() + 20;
    year++
  ) {
    yearList.push(year);
  }

  const reset = () => {
    setFieldDetails({
      ...{
        ID: "",
        type: "0",
        interestAmount: null,
        emiAmount: null,
        totalAmount: null,
        organisationID: "",
        month: new Date().getMonth(),
        startMonth: "",
        endMonth: "",
        startYear: "",
        endYear: "",
      },
    });
    setTenure(0);
    setEmiAmount(0);
    setSingleModalShow(false);
  };

  const onSave = () => {
    if (!fieldDetails.ID) {
      message.error("Please select an employee");
      return;
    }

    if (!fieldDetails.startMonth && fieldDetails.startMonth !== 0) {
      message.error("Please enter Start Month");
      return;
    }
    if (!fieldDetails.startYear) {
      message.error("Please enter Start Year");
      return;
    }
    if (!fieldDetails.endMonth && fieldDetails.endMonth !== 0) {
      message.error("Please enter End Month");
      return;
    }
    if (!fieldDetails.endYear) {
      message.error("Please enter End Year");
      return;
    }
    if (!fieldDetails.type) {
      message.error("Please enter field type");
      return;
    }
    if (!fieldDetails.totalAmount) {
      message.error("Please enter Total Amount");
      return;
    }

    setIsLoading(true);
    addLoansAndSalaries({
      ...fieldDetails,
      organisationID: user.organisationId,
      tenure,
      emiAmount,
      type: parseInt(fieldDetails.type ?? 0),
    })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: "Successfully added the Loan and Advance Salary",
          });
          setSingleModalShow(false);
          reset();
          setSubmitSuccess(!submitSuccess);
        } else {
          notification.error({
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Failed to add the Loan and Advance Salary",
        });
      });

    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (user.organisationId) {
  //     getLoansAndSalaries(user.organisationId)
  //       .then((res) => {
  //         setTableData(res.data)
  //       })
  //       .catch((err) => { "No data" })
  //   }

  // }, [user, submitSuccess]
  // )

  function handleUpdate(data) {
    setUpdateData({
      userId: user._id,
      month: data.month,
      id: data._id,
      financialYear: data.financialYear,
      amount: data.amount,
    });
    setEditModal(true);
  }

  function onUpdate() {
    setIsLoading(true);
    updateLoansAndAdvanceSalaryByRefID(updateData)
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: "Successfully Updated the Loan and Advance Salary",
          });
          setIsLoading(false);
          setEditModal(false);
          setSubmitSuccess(!submitSuccess);
        } else {
          notification.error({
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Failed to Update the Loan and Advance Salary",
        });
      });
    startRefresh();
  }

  useEffect(() => {
    if (
      fieldDetails.startMonth !== "" &&
      fieldDetails.endMonth !== "" &&
      fieldDetails.startYear !== "" &&
      fieldDetails.endYear !== ""
    ) {
      const pastDate = new Date(
        new Date().setFullYear(fieldDetails.startYear)
      ).setMonth(fieldDetails.startMonth);
      const futureDate = new Date(
        new Date().setFullYear(fieldDetails.endYear)
      ).setMonth(fieldDetails.endMonth);
      const { monthDiff } = timeDiffCalc(pastDate, futureDate);
      setTenure(monthDiff < 0 ? 0 : monthDiff + 1);
      if (monthDiff < 0) {
        notification.error({
          message: "Start Month & Year Should Be Lesser than End Month & Year",
        });
      }
    }
  }, [
    fieldDetails.startMonth,
    fieldDetails.endMonth,
    fieldDetails.startYear,
    fieldDetails.endYear,
  ]);

  useEffect(() => {
    if (tenure && fieldDetails.totalAmount) {
      const emi = Math.round(fieldDetails.totalAmount / tenure);
      setEmiAmount(emi);
      return emi;
    }
  }, [fieldDetails.totalAmount, tenure]);

  // const deleteData = async (ID) => {
  //   if (!ID || loading) {
  //     return;
  //   }
  //   setIsLoading(true);
  //   let res = await deleteLoansAndSalaries(ID);
  //   if (res.data.success) {
  //     notification.success({ message: "Successfully deleted month close" });
  //     setSubmitSuccess(!submitSuccess)

  //   } else {
  //     notification.error({ message: "Sorry unable to delete the month close" });
  //   }
  //   setIsLoading(false);
  // };

  // const updateData = async(ID)=>{

  // }

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user", "displayName"],
    },
    {
      title: "Employee Id",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
    },
    {
      title: "Added On",
      dataIndex: "createdAt",
      defaultSortOrder: "ascend",
      render: (text) => <p>{moment(text).format("ll")}</p>,
    },
    {
      title: "Applicable On",
      dataIndex: "month",
      defaultSortOrder: "ascend",
      render: (text) => <p>{monthList[parseInt(text)]}</p>,
      filters: [
        ...monthList.map((_val, index) => {
          return { text: _val, value: index };
        }),
      ],
      onFilter: (value, record) => {
        return record.month === value;
      },
      filterSearch: true,
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Reason",
      dataIndex: "details",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (amount, row) => (
        <p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
          {row.type === "debit" ? "-" : "+"}
          {amount}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "_id",
      align: "center",
      render: (data, record) => (
        <>
          <Popconfirm
            title={"Are you sure ?"}
            onConfirm={() => deleteData(data)}
          >
            <NormalButton type={5} icon={deleteLogo} />
          </Popconfirm>
          <NormalButton
            type={5}
            icon={editLogo}
            onClick={() => handleUpdate(record)}
          />
        </>
      ),
    },
  ];

  return (
    <div className="paper-wrapper-month-closing">
      <Modal
        visible={editModal}
        centered
        width={400}
        footer={null}
        onCancel={() => {
          setEditModal(false);
          reset();
        }}
      >
        <div className="">
          <h2 className="month-close-cards-data-heading">
            Edit Loan / Advance Salary
          </h2>
          <Spin spinning={isloading} size="large">
            <div className="org-anb-data-container p-top-15 ">
              <InputFieldComponent
                disabled={true}
                className="general-ant-field-input"
                width={"100%"}
                labeltext={"Month"}
                value={monthList[updateData.month]}
                placeholder="Month"
                size={"large"}
              />
            </div>
            <div className="org-anb-data-container p-top-15 ">
              <InputFieldComponent
                className="general-ant-field-input"
                width={"100%"}
                type={"number"}
                labeltext={"EMI Amount"}
                value={parseInt(updateData.amount)}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    amount: isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value),
                  });
                }}
                placeholder="EMI Amount"
                size={"large"}
              />
            </div>
            <NormalButton
              type={13}
              buttonText={"Update MonthClose"}
              block={"100%"}
              onClick={onUpdate}
              margin={[30, 0]}
            />
          </Spin>
        </div>
      </Modal>

      <Modal
        visible={singleModalShow}
        centered
        width={400}
        footer={null}
        onCancel={() => {
          setSingleModalShow(false);
          reset();
        }}
      >
        <div className="p-6 p-top-10">
          <div className="m-bottom-10">
            <h2 className="font-weight-global-700 font-size-global-24">
              Add Loan / Advance Salary
            </h2>
            <h3 className="general-ant-field-label font-weight-global-500">
              Only For Debit Types
            </h3>
          </div>
          <Spin spinning={isloading} size="large">
            <div className="org-anb-data-container p-top-15">
              <DropdownComponent
                className="general-ant-field-select"
                labeltext={"Employee Name"}
                options={employeeList.map((item) => item.displayName)}
                values={employeeList.map((item) => item._id)}
                value={fieldDetails.ID}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    ID: e,
                  });
                }}
                width={"100%"}
                bordered={false}
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
              />
            </div>
            <div className="org-anb-data-container p-top-10">
              <h3
                className="general-ant-field-label font-size-global-18 font-weight-global-700"
                style={{ color: "black" }}
              >
                Type
              </h3>
              <Radio.Group
                options={options}
                onChange={(e) =>
                  setFieldDetails({
                    ...fieldDetails,
                    type: e.target.value,
                    details:
                      e.target.value === "credit"
                        ? "Others_Credits"
                        : "Others_Debits",
                  })
                }
                value={fieldDetails.type}
                className="p-top-8 p-bottom-8"
              />
            </div>

            <div className="p-top-10 flex justify-between">
              <DropdownComponent
                labeltext={"Start Month"}
                width={"200px"}
                className="general-ant-field-input"
                value={fieldDetails.startMonth}
                values={monthList.map((_val, index) => index)}
                options={monthList}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    startMonth: e,
                  });
                }}
                size={"large"}
              />
              <div style={{ marginLeft: "8px" }} />
              <DropdownComponent
                labeltext={"Start Year"}
                width={"140px"}
                className="general-ant-field-input"
                value={fieldDetails.startYear}
                values={yearList.map((_val, index) => _val)}
                options={yearList}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    startYear: e,
                  });
                }}
                size={"large"}
              />
            </div>

            <div className="flex justify-between ">
              <DropdownComponent
                labeltext={"End Month"}
                width={"200px"}
                className="general-ant-field-input"
                value={fieldDetails.endMonth}
                values={monthList.map((_val, index) => index)}
                options={monthList}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    endMonth: e,
                  });
                }}
                size={"large"}
              />
              <div style={{ marginLeft: "8px" }} />
              <DropdownComponent
                labeltext={"End Year"}
                width={"140px"}
                className="general-ant-field-input"
                value={fieldDetails.endYear}
                values={yearList.map((_val, index) => _val)}
                options={yearList}
                showSearch={true}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    endYear: e,
                  });
                }}
                size={"large"}
              />
            </div>
            <div className="org-anb-data-container p-top-15 ">
              <InputFieldComponent
                className="general-ant-field-input"
                width={"100%"}
                type={"number"}
                disabled={true}
                labeltext={"Tenure"}
                value={tenure}
                placeholder="Tenure"
                size={"large"}
              />
            </div>
            {fieldDetails.type === "0" && (
              <div className="org-anb-data-container ">
                <InputFieldComponent
                  className="general-ant-field-input"
                  width={"100%"}
                  type={"number"}
                  labeltext={"Interest Amount"}
                  value={parseInt(fieldDetails.interestAmount)}
                  onChange={(e) => {
                    let fieldDetailsData = fieldDetails;
                    setFieldDetails({
                      ...fieldDetailsData,
                      interestAmount: isNaN(parseInt(e.target.value))
                        ? 0
                        : parseInt(e.target.value),
                    });
                  }}
                  placeholder="Interest Amount"
                  size={"large"}
                />
              </div>
            )}

            <div
              style={{ marginTop: "-2em" }}
              className="org-anb-data-container p-top-15"
            >
              <InputFieldComponent
                className="general-ant-field-input"
                width={"100%"}
                type={"number"}
                labeltext={
                  fieldDetails.type === "0"
                    ? "Total Amount Incl. Interest Amount"
                    : "Total Amount"
                }
                value={parseInt(fieldDetails.totalAmount)}
                onChange={(e) => {
                  let fieldDetailsData = fieldDetails;
                  setFieldDetails({
                    ...fieldDetailsData,
                    totalAmount: isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value),
                  });
                }}
                placeholder="Amount"
                size={"large"}
              />
            </div>

            <div className="org-anb-data-container">
              <InputFieldComponent
                className="general-ant-field-input"
                width={"100%"}
                type={"number"}
                disabled={true}
                labeltext={"EMI Amount"}
                value={emiAmount}
                placeholder="Amount"
                size={"large"}
              />
            </div>

            <NormalButton
              type={13}
              buttonText={"Add to the month end data"}
              block={"100%"}
              onClick={onSave}
              margin={[30, 0]}
            />
          </Spin>
        </div>
      </Modal>
      <div className="flex justify-between align-center">
        <h2 className="app-heading-2 font-weight-global-700 font-size-global-24 ">
          Month Closing Data ({" "}
          {monthCloseCount === 0 ? monthCloseFields.length : monthCloseCount} )
        </h2>
        <div style={{ display: "flex" }}>
          <Button
            onClick={startRefresh}
            className={`button-lib button-type-13 p-10`}
            style={{ height: "2.5rem" }}
          >
            <div className="flex justify-between align-center">Refresh</div>
          </Button>
          <Button
            onClick={() => setSingleModalShow(true)}
            type={13}
            className={`button-lib button-type-13 p-10`}
            style={{ height: "2.5rem", paddingRight: "1.5em" }}
          >
            <div className="flex justify-between align-center">
              <img
                style={{ height: 18, width: 18 }}
                alt="pri-icon"
                src={plus}
                className="button-lib-icon"
              ></img>
              Add
            </div>
          </Button>
        </div>
        {/* <NormalButton buttonText={"Add"} type={13} icon={plus} secondaryIcon={downoutined} /> */}
      </div>
      <Spin spinning={loading} size="large">
        <Table
          pagination={false}
          scroll={{ y: 400, x: 500 }}
          columns={columns}
          dataSource={monthCloseFields.reverse()}
          onChange={(pagination, filters, sorter, extra) => {
            setMonthCloseCount(
              extra.currentDataSource.length ?? monthCloseCount.length
            );
          }}
        />
      </Spin>
    </div>
  );
};

export default LoanAndSalary;