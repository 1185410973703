import axios from './index';

export async function updateAddress(data) {
    return axios.put(`user/address`, data);
}

export async function updatePersonalInfo(data) {
    return axios.put(`user/personal_info`, data);
}

export async function getDocumentURL(data) {
    return axios.get(`document`, {params: data})
}

export async function updateBankInfo(data) {
    return axios.put(`user/bank_info`, data);
}

export async function updateContactInfo(data) {
    return axios.put(`user/contact_info`, data);
}

export async function updateCredentials(data) {
    return axios.put(`user/credentials_info`, data);
}

export async function updateIpAddress(data) {
    return axios.put(`update/ip_details`, data);
}

export async function updateEducationAndQualification(data) {
    return axios.put(`employee/education/qualification`, data);
}

export async function updateEmergencyContact(data) {
    return axios.put(`employee/emergency/contact`, data);
}

export async function updateNominee(data) {
    return axios.put(`employee/nominee`, data);
}

export async function createLog(data) {
    return axios.post(`log`, data)
}

export async function getLogByUser(data) {
    return axios.get(`logs/user/${data}`)
}

export async function getLogByOrganiastion(data) {
    return axios.get(`logs/org/${data}`)
}