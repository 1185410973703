import { HeaderComponent } from "../Common";
import { NormalButton } from "../ComponentLibrary";
import { Modal, notification, Spin, Table } from "antd";
import { connect } from "react-redux";
import organisation from "../../redux/modules/organisation";
import { useEffect, useState } from "react";
// import { paths } from "../../constants/routes";
// import { Link } from "react-router-dom";
import moment from "moment";
import { deleteLogo } from "../../assets/common/editAndDeleteLogos";
import { removeApplicationFromApiAccess } from "../../apis/organisation";
import plusSymbol from "../../assets/common/plusSymbol.svg";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
import { addNewApplicationToApiAccess } from "../../apis/organisation";
import TrialPage from "../Common/TrialComponent";
import configurationsBgImage from "../../assets/dummyPages/configurationsBgImage.png"




const OrganisationApiAccessKeyList = ({
  applicationWithApiAccessKey,
  organisationReducer,
  user,
  deviceWidth,
  isTrial
}) => {
  const [accessKeyPair, setAccessKeyPair] = useState({})
  const [pageState, setPageState] = useState(1)
  const [deleteAppObject, setDeleteAppObject] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewApplicationModal, setShowNewApplicationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newAppDetails, setNewAppDetails] = useState({
    appName: "",
    organisationID: user.organisationId
  })

  useEffect(() => {
    organisationReducer.fetchApplicationWithApiKeys(user.organisationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationWithApiAccessKey]);

  const onDelete = (data) => {
    setDeleteAppObject(data);
    setShowDeleteModal(true);
  };
  const reset = () => {
    setNewAppDetails({
      ...newAppDetails,
      appName: ""
    })
  }
  const onSave = async () => {
    setIsLoading(true);
    if (newAppDetails.appName.length < 1) {
      notification.error({ message: "App name should not empty." });
      setIsLoading(false);
      return;
    }
    let res = await addNewApplicationToApiAccess(newAppDetails);
    if (res.status === 200 && res.data.success) {
      setAccessKeyPair(res.data.data)
      setPageState(2);
    } else {
      notification.error({
        message: "Failed to add new application, please try again.",
      });
    }
    setIsLoading(false);
  };

  const onDeleteConfirm = async () => {
    setIsLoading(true);
    let res = await removeApplicationFromApiAccess(
      deleteAppObject._id,
      user.organisationId
    );
    if (res.status === 200 && res.data.success) {
      setDeleteAppObject(null);
      setShowDeleteModal(false);
      organisationReducer.fetchApplicationWithApiKeys(user.organisationId);
      notification.success({ message: "Successfully deleted application" });
    } else {
      notification.error({
        message: "Failed to delete application, please try again.",
      });
    }
    setIsLoading(false);
  };

  const columns = [
    {
      key: "appName",
      dataIndex: "appName",
      title: "Name",
    },
    {
      key: "createdOn",
      dataIndex: "createdOn",
      title: "Created on",
      render: (createdOn) => <p>{moment(createdOn).format("DD MMM YYYY")}</p>,
    },
    {
      key: "lastUsed",
      dataIndex: "lastUsed",
      title: "Last used on",
      render: (lastUsed) => (
        <p>{lastUsed ? moment(lastUsed).format("DD MMM YYYY") : "Never"}</p>
      ),
    },
    {
      key: "_id",
      dataIndex: "_id",
      title: "",
      width: deviceWidth > 1500 ? "10%" : "20%",
      render: (appID, row) => (
        <div className="flex justify-end align-center">
          <NormalButton
            type={5}
            margin={[3, 0]}
            icon={deleteLogo}
            onClick={() => {
              onDelete(row);
            }}
          />
        </div>
      ),
    },
  ];
  useEffect(() => {
    document.title = 'Settings | API Access Keys';
  });

  const showApplicationModal = () => {
    setShowNewApplicationModal(true);
  }
  const closeApplicationModal = () => {
    setShowNewApplicationModal(false);
    reset()
  }
  const closeApplicationModal2 = () => {
    setShowNewApplicationModal(false);
    setPageState(1)
    reset()

  }
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Supercharge your payroll process. Seamlessly integrate with any standard Payroll & HRMS"} bgImage={configurationsBgImage}
    /> :
    <div className="site-layout">
      <HeaderComponent
        show={false}
        name="API Access Keys"
      />
      <div className="app-main-content-container paper-wrapper">
        <div className="flex justify-between">
          <div style={{ width: "100%" }}>
            <h1 className="h1-bold-24">API Access Keys</h1>
            <div
              className="tab-subtitle-1"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>
                Add third party applications to allow them to fetch your data.
              </p>
              <p>
                Organisation Id:{" "}
                <code style={{ fontWeight: "bold" }}>
                  {user.organisationId}
                </code>
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between align-center">
          <p className="h5-bold-20">
            List ({applicationWithApiAccessKey.length})
          </p>
          <NormalButton
            type={13}
            icon={plusSymbol}
            buttonText="Add New Application"
            onClick={showApplicationModal}
          >

          </NormalButton>
        </div>
        <Table
          style={{ fontWeight: 500, marginTop: 16 }}
          columns={columns}
          dataSource={applicationWithApiAccessKey.reverse() ?? []}
          pagination={false}
          scroll={{ y: 520 }}
        />
      </div>
      <Modal
        centered
        closable={false}
        visible={showDeleteModal}
        className="modal-container"
        footer={null}
        onCancel={() => {
          setShowDeleteModal(false);
          setDeleteAppObject(null);
        }}
      >
        <Spin spinning={isLoading}>
          <div className="">
            <div className="flex justify-between">
              <h1 className="app-heading-2">Delete Application</h1>
            </div>
            <div className="">
              <p className="font-size-16 font-weight-500">
                Are you sure you want to delete the application "
                {deleteAppObject && deleteAppObject.appName}"
              </p>
            </div>
            <div className="m-top-36  text-align-right">
              <NormalButton
                type={5}
                buttonText={"cancel"}
                margin={[0, 12]}
                onClick={() => {
                  setShowDeleteModal(false);
                  setDeleteAppObject(null);
                }}
              />
              <NormalButton
                type={5}
                buttonText="Delete"
                onClick={() => {
                  onDeleteConfirm();
                }}
              />
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        centered
        closable={false}
        visible={showNewApplicationModal}
        width={400}
        className="modal-container"
        footer={null}
        onCancel={closeApplicationModal2}

      >
        <Spin spinning={isLoading}>
          {
            pageState === 1 && (
              <>
                <h1 style={{ marginBottom: '1em' }} className="font-size-global-24 font-weight-global-700">
                  Generate new API access keys
                </h1>
                <InputFieldComponent
                  value={newAppDetails.appName}
                  labeltext={"Application Name"}
                  onChange={(e) => {
                    setNewAppDetails({
                      ...newAppDetails,
                      appName: e.target.value,
                    });
                  }}
                />
                <div>
                  <NormalButton type={13} buttonText="Save" onClick={onSave} />
                  <NormalButton
                    type={5}
                    buttonText="Cancel"
                    margin={[0, 16]}
                    onClick={closeApplicationModal}
                  />
                </div>
              </>
            )
          }
          {
            pageState === 2 && (
              <>
                <h1 style={{ marginBottom: "1em" }} className="font-size-global-24 font-weight-global-700">
                  Here is your new pair of access keys
                </h1>

                <div>
                  <p className={"app-label-1 mb-4"}>Please store your access key ID and access key secret, this will be the last time this credentials will be available.</p>
                  <p className={"app-label-1 mb-4"}>Access key ID:  <code style={{ fontWeight: "bold", background: "#ED864C", padding: "5px", borderRadius: "4px" }} className={"subtitle1-med-16"}>{accessKeyPair.accessKeyID}</code></p>
                  <p className={"app-label-1 mb-4"}>Access key secret: </p>
                  <p className={"app-label-1 mb-4"}> <code style={{ fontWeight: "bold", background: "#ED864C", padding: "5px", borderRadius: "4px" }} className={"subtitle1-med-16"}>{accessKeyPair.accessKeySecret}</code></p>
                </div>
                <div style={{ marginTop: "2em" }}>
                  <NormalButton type={13} buttonText="Close" onClick={closeApplicationModal2} />
                </div>
              </>
            )
          }
        </Spin>
      </Modal>
    </div>
  );
};

const OrganisationApiAccessKeyListContainer = connect(
  (state) => ({
    user: state.auth.user,
    applicationWithApiAccessKey: state.organisation.applicationWithApiAccessKey,
    deviceWidth: state.responsive.deviceWidth,
    isTrial: state.auth.user.isTrial,
  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(OrganisationApiAccessKeyList);

export default OrganisationApiAccessKeyListContainer;
