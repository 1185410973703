import { message, notification, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import DropdownComponent from "../../../../src/components/InputLibrary/DropdownComponent/DropdownComponent";
import { monthList } from "../../../utils/config";
import { NormalButton } from "../../ComponentLibrary";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";

let Option = Select;
// eslint-disable-next-line no-unused-vars
const options = [
  { label: "Credit", value: "credit" },
  { label: "Debit", value: "debit" },
];
const defaultCredit = 'credit'

const selectOption = {
  credit: {
    Incentive: {
      label: "Incentive",
      taxable: true,
    },
    Variable_Salary: {
      label: "Variable Salary",
      taxable: true,
    },
    Other_Allowance: {
      label: "Other Allowances",
      taxable: true,
    },
    Awards: {
      label: "Awards",
      taxable: true,
    },
    Rewards: {
      label: "Rewards",
      taxable: true,
    },
    Leave_Encashment: {
      label: "Leave Encashment",
      taxable: true,
    },
    Attendance_Bonus: {
      label: "Attendance Bonus",
      taxable: true,
    },
  },
  debit: {
    Loans: {
      label: "Loan",
      taxable: true,
    },
    Advance_Salary: {
      label: "Advance Salary Debits",
      taxable: true,
    },
  },
};

const MonthClosingFields = ({
  user,
  organisation,
  getMonthClose,
  employeeList,
  setSingleModalShow,
}) => {
  const [loading, setLoading] = useState(false);
  const [financialYearList, setFinancialYearList] = useState([]);

  const [fieldDetails, setFieldDetails] = useState({
    id: "",
    type: "credit",
    details: "",
    subDetails: "",
    amount: null,
    othertext: "",
    taxable: true,
    month: new Date().getMonth(),
    financialYear: "",
  });

  useEffect(() => {
    let startFYear = 2015;
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let resFinancialYearList = [];
    for (let i = startFYear; i < currentYear + 1; i++) {
      let nextYear = i + 1;
      let tempFYear = `${i}-${nextYear}`;
      resFinancialYearList.push(tempFYear);
    }
    setFinancialYearList([...resFinancialYearList]);
  }, [user]);

  const submitForm = async () => {
    if (!fieldDetails.id) {
      message.error("Please select an employee");
      return;
    }
    if (!fieldDetails.financialYear) {
      message.error("Please enter Financial Year");
      return;
    }
    if (!fieldDetails.month && fieldDetails.month !== 0) {
      message.error("Please enter month");
      return;
    }
    if (!fieldDetails.type) {
      message.error("Please enter the month close type");
      return;
    }
    if (!fieldDetails.details) {
      message.error("Please enter month close details");
      return;
    }
    if (!fieldDetails.amount) {
      message.error("Please enter month close amount");
      return;
    }
    setLoading(true);
    let response = await organisation.addMonthClose({
      monthClose: {
        ...fieldDetails,
        organisationId: user.organisationId,
      },
    });
    setLoading(false);
    if (response.data.success) {
      notification.success({ message: "Successfully added the month close" });
    } else {
      notification.error({ message: "Unable to add the month close" });
    }
    organisation.updateMonthClose({ organisationId: user.organisationId });
    setFieldDetails({
      ...{
        id: "",
        type: "credit",
        details: "",
        subDetails: "",
        amount: null,
        othertext: "",
        taxable: true,
        month: new Date().getMonth(),
        financialYear: "",
      },
    });
    setSingleModalShow(false);
  };
  return (
    <div className="paper-wrapper-anb">
      <div className="m-bottom-10">
        <h2 className="font-size-global-24 font-weight-global-700">
          Add Payroll Inputs
        </h2>
        <h3 className="general-ant-field-label font-weight-global-700">Only For Credit Types</h3>
      </div>
      <Spin spinning={loading} size="large">
        <div className="org-anb-data-container p-top-15">
          <DropdownComponent
            className="general-ant-field-select"
            labeltext={"Employee Name"}
            values={employeeList.map(item => item._id)}
            options={employeeList.map((item) => item.displayName)}
            value={fieldDetails.id}
            onChange={(e) => {
              let fieldDetailsData = fieldDetails;
              setFieldDetails({
                ...fieldDetailsData,
                id: e,
              });
            }}
            style={{ width: "100%" }}
            bordered={false}
            filterOption={(input, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            showSearch
          >
            {employeeList.map((val, index) => {
              return (
                <Option key={`employees-${index}`} value={val._id}>
                  {val.displayName}
                </Option>
              );
            })}
          </DropdownComponent>
        </div>

        <div className="org-anb-data-container p-top-15">
          <DropdownComponent
            className="general-ant-field-select"
            labeltext={"Financial Year"}
            values={financialYearList}
            options={financialYearList}
            value={fieldDetails.financialYear}
            onChange={(e) => {
              let fieldDetailsData = fieldDetails;
              setFieldDetails({
                ...fieldDetailsData,
                financialYear: e,
              });
            }}
            style={{ width: "100%" }}
            bordered={false}
            filterOption={(input, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            showSearch
          >
            {financialYearList.map((val, index) => {
              return (
                <Option key={`fyear-${index}`} value={val}>
                  {val}
                </Option>
              );
            })}
          </DropdownComponent>
        </div>

        <div className="org-anb-data-container p-top-15">
          <DropdownComponent
            labeltext={"Month"}
            className="general-ant-field-select"
            value={fieldDetails.month}
            values={monthList.map((_val, index) => index)}
            options={monthList}
            onChange={(e) => {
              let fieldDetailsData = fieldDetails;
              setFieldDetails({
                ...fieldDetailsData,
                month: e,
              });
            }}
            style={{ width: "100%" }}
            bordered={false}
            filterOption={(input, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {monthList.map((val, index) => {
              return (
                <Option key={`employees-${index}`} value={index}>
                  {val}
                </Option>
              );
            })}
          </DropdownComponent>
        </div>

        {/* <div className="org-anb-data-container p-top-15">
          <h3 className="general-ant-field-label">Field Type</h3>
          <Radio.Group
            options={options}
            disabled={true}
            value={fieldDetails.type}
            className="p-top-8 p-bottom-8"
          />
        </div> */}


        <div className="org-anb-data-container p-top-15">
          <DropdownComponent
            className="general-ant-field-select"
            labeltext={"Credit Category"}
            value={fieldDetails.details}
            values={Object.keys(selectOption[defaultCredit])}
            options={Object.keys(selectOption[defaultCredit]).map(
              (val) => selectOption[defaultCredit][val].label
            )}
            onChange={(e) => {
              setFieldDetails({
                ...fieldDetails,
                taxable: selectOption[defaultCredit][e].taxable,
                details: e,
                subDetails: e,
              });
            }}
            style={{ width: "100%" }}
            placeholder="Tax Info"
            bordered={false}
            size={"large"}
            filterOption={(input, option) =>
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            showSearch
          >
            {fieldDetails.type !== "" &&
              Object.keys(selectOption[fieldDetails.type]).map(
                (val, index) => (
                  <Option key={`fields-${index}`} value={val}>
                    {selectOption[fieldDetails.type][val].label}
                  </Option>
                )
              )}
          </DropdownComponent>
        </div>

        <div className="org-anb-data-container p-top-15">
          <InputFieldComponent
            labeltext={"Amount (INR)"}
            type={"number"}
            className="general-ant-field-input"
            style={{ width: "100%" }}
            value={parseInt(fieldDetails.amount)}
            onChange={(e) => {
              let fieldDetailsData = fieldDetails;
              setFieldDetails({
                ...fieldDetailsData,
                amount: isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value),
              });
            }}
            placeholder="Amount"
            size={"large"}
          />
        </div>

        <div className="select-button-container">
          <NormalButton
            type={13}
            buttonText={"Add to the month end data"}
            block={"100%"}
            onClick={submitForm}
            margin={[30, 0]}
          />
        </div>
      </Spin>
    </div>
  );
};

export default MonthClosingFields;
