import { useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import config from "../../../../utils/config";
import { proofsByCategory } from "../../../../apis/employee";
import { createLog, getDocumentURL } from "../../../../apis/user";
import Modal from "antd/lib/modal/Modal";
import { Button, Skeleton, Card, notification } from "antd";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PasswordResponses from "react-pdf/dist/esm/PasswordResponses";
import { verifyDocument } from "../../../../apis/organisation";
import verifiedTrue from "../../../../assets/common/verifiedLogos/verify-yes.svg";
import verifiedFalse from "../../../../assets/common/verifiedLogos/verify-no.svg";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const SubmitForm = ({ sectionName, sectionType, Category, user }) => {
  const [employeeDocument, setEmployeeDocument] = useState([]);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState("");
  const [modalstatus, setModalStatus] = useState(false);
  const [format, setFormat] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [verifyPayload, setVerifyPayload] = useState({
    proofId: "",
    index: 0,
    verified: false,
  })

  const increaseCurrentPage = () => {
    let currPage = currentPage;
    currPage = currPage < pageNumber ? currPage + 1 : currPage;
    setCurrentPage(currPage);
  };

  const decreaseCurrentPage = () => {
    let currPage = currentPage;
    currPage = currPage > 1 ? currPage - 1 : currPage;
    setCurrentPage(currPage);
  };

  useEffect(() => {
    setLoading(true);
    getProofsByCategory();
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const getDocumentURLFromKey = async (key) => {
    setFormat(key.split(".").pop());
    let doc = await getDocumentURL({ Key: key });
    setDocument(doc.data);
  };

  const verifyProof = async () => {
    await verifyDocument(verifyPayload);
    await getProofsByCategory();
    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: "User has been verifying the proofs",
      category: 9,
    });
    notification.success({ message: "Document has been verified" });
    setModalStatus(false);
  }

  const onPasswordHandler = function (callback, reason) {
    function callbackProxy(password) {
      // Cancel button handler
      if (!password) {
        setModalStatus(false)
        return
      }
      callback(password);
    }

    switch (reason) {
      case PasswordResponses.NEED_PASSWORD: {
        const password = prompt('Enter the password to open this PDF file.');
        callbackProxy(password);
        break;
      }
      case PasswordResponses.INCORRECT_PASSWORD: {
        const password = prompt('Invalid password. Please try again.');
        callbackProxy(password);
        break;
      }
      default:
    }
  }

  const getDeductionName = (category) => {
    let tempDeduction = config.deductionData;
    if (Category === "HRA") {
      return category === "rentRecipt" ? "Rent Recipt" : "Rent Agreement";
    }
    if (Category === "Allowances") {
      return user.organisationSettings.allowances[category]
        ? user.organisationSettings.allowances[category].label
        : user.organisationSettings.customallowances[category].label;
    }
    return tempDeduction.filter((val) => val.declaration === category)[0].label;
  };

  const DocumentAllowances = ({ data, proofId, index }) => {
    return (
      <div className="document-container">
        <div style={{ display: "flex" }}>
          <span
            onClick={() => {
              setModalStatus(true);
              setVerifyPayload({
                proofId,
                index,
                verified: data.verified
              })
              getDocumentURLFromKey(data.Key);
            }}
            className="file-typeHeader"
          >
            {data.Key.split(".").pop()}
          </span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{data.Key}</span>
            <span className="date-contianer">
              {new Date(data.createdAt).toDateString()}
            </span>
          </div>
        </div>
        {data.verified ? (
          <span className="verifiection-text verified">
            <img
              alt="verification status logo"
              className="verifiection-logo"
              src={verifiedTrue}
            >
            </img>
            Verified
          </span>
        ) : (
          <span className="verifiection-text not-verified">
            <img
              alt="verification status logo"
              className="verifiection-logo"
              src={verifiedFalse}
            >
            </img>
            Not Verified
          </span>
        )}
      </div>
    );
  };

  const getProofsByCategory = async () => {
    let data = await proofsByCategory({
      id: user._id,
      category: Category,
    });
    setEmployeeDocument([...data.data]);
  };

  return (
    <div className="section-full-data-container">
      {sectionType && (
        <h3 className="section-full-data-heading">{sectionType}</h3>
      )}
      {sectionName && <h4 className="section-full-data-type">{sectionName}</h4>}
      <div className="otherAllowance-files-container">
        <div className="otherAllowance-files-header">
          <h1 className="otherAllowance-files-heading"> Documents</h1>
        </div>
        <div id="document-list-container">
          <Skeleton
            loading={false}
            active
            paragraph={{ rows: 2 }}
            avatar
          >
            {employeeDocument.length !== 0 ? (
              employeeDocument.map((value, index) => {
                return (
                  <Card
                    key={`employee-doc-main-${index}`}
                    className="document-groupp"
                  >
                    <div
                      key={`employee-doc-header-${index}`}
                      className="document-headerr"
                    >
                      <p
                        key={`employee-doc-label-${index}`}
                        className="document-header-labele"
                      >
                        {" "}
                        {getDeductionName(value.subCatagory)}{" "}
                      </p>
                      <p
                        key={`employee-doc-amount-${index}`}
                        className="allowance-amount"
                      >
                        {config.numformatter(value.amount)} INR
                      </p>
                    </div>
                    <div
                      key={`employee-doc-body-${index}`}
                      className="document-list"
                    >
                      {value.documents.map((val, index) => (
                        <DocumentAllowances
                          key={`employee-doc-doc-${index}`}
                          data={val}
                          proofId={value.proofId}
                          index={index}
                        />
                      ))}
                    </div>
                  </Card>
                );
              })
            ) : (
              <p>Sorry no documents has been provided by the user</p>
            )}
          </Skeleton>
        </div>
        <Modal
          onCancel={() => {
            setDocument("");
            setModalStatus(false);
            setFormat("");
            setVerifyPayload({
              proofId: "",
              index: 0,
              verified: false
            })
          }}
          visible={modalstatus}
          footer={null}
          centered
          width={900}
        >
          <Skeleton loading={!document} active avatar paragraph={{ rows: 5 }}>
            <Button type="link" size="large" style={{ padding: 10 }} download href={document}>
              Download Document
            </Button>
            {
              verifyPayload.verified
                ? <p style={{ padding: 10 }}>
                  <img
                    alt="verification status logo"
                    className="verifiection-logo"
                    src={verifiedTrue}
                  ></img>
                  Document has been verified
                </p>
                : <Button style={{ margin: 16 }} onClick={verifyProof} type="primary" size="large">
                  Verify The Document
                </Button>
            }

            {format === "pdf" ? (
              <>
                <Document
                  className="document-viewer"
                  file={document}
                  onPassword={onPasswordHandler}
                  onLoadSuccess={(pn) => setPageNumber(pn.numPages)}
                >
                  <Page pageNumber={currentPage} />
                </Document>
                <div className="pdf-document-action">
                  <Button onClick={decreaseCurrentPage} type="default">
                    <LeftOutlined />
                  </Button>
                  <p>
                    Page {currentPage} of {pageNumber}
                  </p>
                  <Button onClick={increaseCurrentPage} type="default">
                    <RightOutlined />
                  </Button>
                </div>
              </>
            ) : (
              <img alt="img" src={document} className="document-display" />
            )}
          </Skeleton>
        </Modal>
      </div>
    </div>
  );
};

const DocumentActionContainer = connect((state) => ({
  user: state.auth.user,
}))(SubmitForm);

export default DocumentActionContainer;
