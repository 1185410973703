import React, { useState } from 'react'
import { FileViewer } from "../../../ComponentLibrary";
import EmployeeDocs from "../../../AllInvestementAllowance/Components/EmployeeDocs";

const Deduction = ({ user, orgInfo, financialYear }) => {
  const [viewFile, setViewFile] = useState(false);
  const [viewFileDocumentProps, setviewFileDocumentProps] = useState({});
  const [
    viewFileDocumentVerifyProps,
    setviewFileDocumentVerifyProps,
  ] = useState({});

  return (
    <>
      <FileViewer
        visible={viewFile}
        onclose={setViewFile}
        fileProps={viewFileDocumentProps}
        verifyProps={viewFileDocumentVerifyProps}
      />
      <div className="employee-deduction-file-container">
        <div className="employee-upload-files-container">
          <div className="deduction-header">
            <h1 className="app-heading-3 h5-bold-20 p-left-8">Deduction List</h1>
            {/* <div className="year-container">
          <span className="app-label-2">Financial year</span>
          <DatePicker
              format={"YYYY"}
              className="app-date-picker month-picker"
              // onChange={handelDateChange}
              picker={"year"}
              // value={reportDate}
          />
          </div> */}
          </div>
          <EmployeeDocs
            organisation={orgInfo}
            employeeDetails={user}
            setViewFile={setViewFile}
            id={user._id}
            setViewFileDocumentVerifyProps={setviewFileDocumentVerifyProps}
            setViewFileDocumentProps={setviewFileDocumentProps}
            AllowanceOrDeduction={false}
            financialYear={financialYear}
          />
        </div>
      </div>
    </>
  );
};

export default Deduction;