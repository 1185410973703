import React, { useState } from "react";
import { Layout, Spin, Checkbox, message } from "antd";
import { withRouter } from "react-router-dom";
import sidebar from "../../assets/sidebarlogo.svg";
import "./index.less";
import { NormalButton } from "../ComponentLibrary";

const { Content } = Layout;

function Attach() {
  const [loading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const props = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const signMeOut = () => {
    localStorage.setItem("mool_auth", "")
    localStorage.removeItem("mool_auth")
    localStorage.setItem("organisationId","");
    localStorage.removeItem("organisationId");
    window.location = "/"
  }
  return (
    <Content className="register-container">
      <Spin spinning={loading} size="large">
        <div className="main">
          <div
            style={{
              minHeight: "100vh",
              width: "26vw",
              padding: "3rem",
              backgroundImage:
                "linear-gradient(155deg, #e7ebfd -6%, #a3b5ff 114%)"
            }}
          >
            <img src={sidebar} alt="" />
          </div>
          <div className="attachDiv">
            <div className="navbar">
              <div className="sign-outDiv">
                <p className="h6-bold-18" style={{ cursor: "pointer" }} onKeyDown={signMeOut} onClick={signMeOut}>Sign out</p>
              </div>
            </div>
            <div className="contentDiv">
              <p className="font-size-18">
                Step <b>1</b> of <b>3</b>
              </p>
              <h1 className="h1-bold-32">Agreement</h1>
              <div className="textDiv">
                <p className="textP font-size-16">
                  Your membership starts as soon as you start the payment
                </p>
                <p className="textP font-size-16">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p className="textP font-size-16">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum is simply dummy text of the
                  printing and typesetting industry. Lorem Ipsum is simply dummy
                  text of the printing and typesetting industry
                </p>
              </div>
              <div>
                <Checkbox onChange={onChange} className="textDiv font-size-16">
                  <b>I accept the agreement</b>
                </Checkbox>
              </div>

              <div className="btnDiv">
                <NormalButton
                  buttonText={"Continue"}
                  type={13}
                  heightWidth={["50px", "100px"]}
                ></NormalButton>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Content>
  );
}

export default withRouter(Attach);
