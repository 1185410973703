import ReducerFactory from "../utils/reducerFactory";
import * as auth from "../../apis/auth";
import { message, notification } from "antd";
import { getUserFromId } from "../../apis/organisation";
import {
  checkFinancialYear,
  defaultUserAccess,
  getFinancialYearFromDate,
} from "../../utils/config";
import { navigator } from "../../constants/routes";
import { createLog } from "../../apis/user";

const initialState = {
  loading: false,
  user: {},
  image: "",
  address: {},
  bankInfo: {},
  contactInfo: {},
  credentials: {},
  familyDetails: {},
  document: {
    aadhaar: {},
    PAN: {},
  },
  access: {},
  isLoggedIn: false,
  error: null,
  loginstate: false,
  isTrial: false,
  registerState: false,
  showNewPassword: false,
  currentTab: "",
  selectedCategory: "",
  categoryChildren: {},
  isCurrentFinancialYearJoinee: false,
};
const reducerFactory = new ReducerFactory("auth", initialState);

reducerFactory.addAction(
  "LOGIN_LOADING",
  "loading",
  (status) => status,
  (state, action) => {
    state.loading = action.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "LOGOUT",
  "logout",
  (status) => status,
  (state, _action) => {
    state.isLoggedIn = false;
    state.user = {};
    localStorage.setItem("mool_auth", "");
    localStorage.removeItem("mool_auth");
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "LOGIN",
  "login",
  async (data) => {
    let res = await auth.login(data);
    return res.data;
  },
  (state, action) => {
    let loginSuccess = false;
    let user = null
    if (action.data.success) {
      user = action.data.data.user;
      if ((user.registerState < 2 || user.status) && user.role === "org") {
        state.user = user;
        state.image = user.image;
        state.address = user.address;
        state.isLoggedIn = true;
        state.loginstate = action.data.data.user.loginstate < 4 ? true : false;
        state.registerState = action.data.data.user.registerState < 2 ? true : false;
        state.currentTab = "dashboard";
        localStorage.setItem("mool_auth", action.data.data.auth);
        loginSuccess = true;
      } else if (user.role === "org") {
        notification.error({
          message:
            "Sorry user is not confirmed! Please connect with our customer support",
        });
      } else {
        state.user = user;
        state.image = user.image;
        state.address = user.address;
        state.familyDetails = user.familyDetails;
        state.bankInfo = user.bankInfo || {};
        state.credentials = user.credentials || {};
        state.contactInfo = user.contactInfo || {};
        if (user.document) {
          state.document =
            Object.keys(user.document) > 0
              ? {
                aadhaar: user.document.aadhaar ? user.document.aadhaar : "",
                PAN: user.document.PAN,
              }
              : { PAN: {}, aadhaar: {} };
        }
        state.isLoggedIn = true;
        state.loginstate = action.data.data.user.loginstate < 4 ? true : false;
        state.isTrial = action.data.data.user.isTrial ? true : false;
        state.registerState = action.data.data.user.registerState < 2 ? true : false;
        localStorage.setItem("mool_auth", action.data.data.auth);
        loginSuccess = true;
        let access = user.access || defaultUserAccess;
        access.reports.access = Object.keys(access.reports.subAccess).some(
          (acs) => access.reports.subAccess[acs].access === true
        );
        access.reportTable = { access: access.reports.access, subAccess: {} };
        state.access = access;
        state.currentTab = "dashboard";
        const { dateOfJoining } = user.currentOrganisationMeta;
        let joiningDate = new Date(dateOfJoining);
        let employeeJoiningFinancialYear = getFinancialYearFromDate(
          joiningDate
        );
        let { financialYear } = checkFinancialYear();
        state.isCurrentFinancialYearJoinee =
          employeeJoiningFinancialYear.financialYear === financialYear;
      }
    } else {
      notification.error({ message: "Sorry Login Failed" });
    }
    state.loading = false;
    if (loginSuccess) {
      createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: "User has signed into the system",
        category: 0,
      });
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "GOOGLE_LOGIN",
  "googleLogin",
  async (data) => {
    let res = await auth.googleLogin(data);
    if (res.data.message === "USER_NOT_FOUND") {
      notification.error({ message: "No user with this google account found." })
    }
    return res.data;
  },
  (state, action) => {
    let loginSuccess = false;
    let user = null
    if (action.data.success) {
      user = action.data.data.user;
      if (user.status && user.role === "org") {
        state.user = user;
        state.image = user.image;
        state.address = user.address;
        state.isLoggedIn = true;
        state.loginstate = action.data.data.user.loginstate < 4 ? true : false;
        state.registerState = action.data.data.user.registerState < 2 ? true : false;
        state.currentTab = "dashboard";
        localStorage.setItem("mool_auth", action.data.data.auth);
        loginSuccess = true;
      } else if (user.role === "org") {
        notification.error({
          message:
            "Sorry user is not confirmed! Please connect with our customer support",
        });
      } else {
        state.user = user;
        state.image = user.image;
        state.address = user.address;
        state.familyDetails = user.familyDetails;
        state.bankInfo = user.bankInfo || {};
        state.credentials = user.credentials || {};
        state.contactInfo = user.contactInfo || {};
        if (user.document) {
          state.document =
            Object.keys(user.document) > 0
              ? {
                aadhaar: user.document.aadhaar ? user.document.aadhaar : "",
                PAN: user.document.PAN,
              }
              : { PAN: {}, aadhaar: {} };
        }
        state.isLoggedIn = true;
        state.loginstate = action.data.data.user.loginstate < 4 ? true : false;
        state.registerState = action.data.data.user.registerState < 2 ? true : false;
        localStorage.setItem("mool_auth", action.data.data.auth);
        loginSuccess = true;
        let access = user.access || defaultUserAccess;
        access.reports.access = Object.keys(access.reports.subAccess).some(
          (acs) => access.reports.subAccess[acs].access === true
        );
        access.reportTable = { access: access.reports.access, subAccess: {} };
        state.access = access;
        state.currentTab = "dashboard";
        const { dateOfJoining } = user.currentOrganisationMeta;
        let joiningDate = new Date(dateOfJoining);
        let employeeJoiningFinancialYear = getFinancialYearFromDate(
          joiningDate
        );
        let { financialYear } = checkFinancialYear();
        state.isCurrentFinancialYearJoinee =
          employeeJoiningFinancialYear.financialYear === financialYear;
      }
    } else {
      notification.error({ message: "Sorry Login Failed" });
    }
    state.loading = false;
    if (loginSuccess) {
      createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: "User has signed into the system",
        category: 0,
      });
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "RESET",
  "reset",
  async (data) => {
    let res = await auth.reset(data);
    return res.data;
  },
  (state, action) => {
    state.loading = false;
    if (action.data.success) {
      message.info(
        "We have sent an email to your registered e-mail Id carrying instructions to reset your password. Please check"
      );
      setTimeout(() => {
        window.location.href = "#/auth/login";
      }, 2500);
    } else {
      message.error(action.data.message);
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "SET_PASSWORD",
  "setNewPassword",
  async (data) => {
    let res = await auth.setNewPassword(data);
    return res.data;
  },
  (state, _action) => {
    state.loading = false;
    const newState = Object.assign({}, state);
    message.success("Successfully reset password. Redirecting you to login");
    return newState;
  }
);

reducerFactory.addAction(
  "VERIFY",
  "verify",
  async (data) => {
    let res = await auth.verify(data);
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      state.user = { primaryEmail: action.data.data.primaryEmail };
      state.showNewPassword = true;
    } else {
      message.error(
        "The verification code is invalid or has expired. Please try resetting your password again"
      );
      setTimeout(() => {
        window.location.href = "#/auth/login";
      }, 2500);
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "REGISTER",
  "register",
  async (data) => {
    let res = await auth.register(data);
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      notification.success({
        message:
          "Successfully registered. You can now login to the dashboard with your credentials",
      });
      // setTimeout(()=>{
      //   window.location.reload()
      // },3000)
    } else {
      notification.error({ message: action.data.message });
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "CHECK_LOGIN",
  "check",
  async (_data) => {
    reducerFactory.action("loading", true);
    let authExists = localStorage.getItem("mool_auth");
    if (!authExists) {
      return { data: { success: false } };
    }
    let res = await auth.check();
    return res.data;
  },
  (state, action) => {
    let loginSuccess = false;
    let user = null
    if (action.data.success) {
      user = action.data.data.user;
      if ((user.registerState < 2 || user.status) && user.role === "org") {
        state.user = user;
        state.image = user.image;
        state.address = user.address;
        state.familyDetails = user.familyDetails;
        state.isLoggedIn = true;
        state.loginstate = action.data.data.user.loginstate < 4 ? true : false;
        state.registerState = action.data.data.user.registerState < 2 ? true : false;
        localStorage.setItem("mool_auth", action.data.data.auth);
        loginSuccess = true
        state.currentTab = "dashboard";
      } else if (user.role === "org") {
        notification.error({
          message:
            "Sorry user is not confirmed! Please connect to our customer support",
        });
      } else {
        loginSuccess = true
        state.user = user;
        state.image = user.image;
        state.address = user.address;
        state.bankInfo = user.bankInfo || {};
        state.credentials = user.credentials || {};
        state.contactInfo = user.contactInfo || {};
        if (user.document) {
          state.document = Object.keys(user.document)
            ? {
              aadhaar: user.document.aadhaar ? user.document.aadhaar : "",
              PAN: user.document.PAN,
            }
            : { PAN: {}, aadhaar: {} };
        }
        state.familyDetails = user.familyDetails;
        let access = user.access || defaultUserAccess;
        access.reports.access = Object.keys(access.reports.subAccess).some(
          (acs) => access.reports.subAccess[acs].access === true
        );
        access.reportTable = { access: access.reports.access, subAccess: {} };
        state.access = access;
        state.isLoggedIn = true;
        state.loginstate = action.data.data.user.loginstate < 4 ? true : false;
        state.registerState = action.data.data.user.registerState < 2 ? true : false;
        state.currentTab = "dashboard";
        const { dateOfJoining } = user.currentOrganisationMeta;
        let joiningDate = new Date(dateOfJoining);
        let employeeJoiningFinancialYear = getFinancialYearFromDate(
          joiningDate
        );
        let { financialYear } = checkFinancialYear();
        state.isCurrentFinancialYearJoinee =
          employeeJoiningFinancialYear.financialYear === financialYear;
        localStorage.setItem("mool_auth", action.data.data.auth);
      }
    }
    if (loginSuccess) {
      createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: "User has signed into the system",
        category: 0,
      });
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_USER",
  "updateUser",
  async (data) => {
    let res = await getUserFromId(data);
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      let user = action.data;
      state.user = user;
      state.image = user.image;
      state.address = user.address;
      state.bankInfo = user.bankInfo || {};
      state.credentials = user.credentials || {};
      state.contactInfo = user.contactInfo || {};
      if (user.document) {
        state.document = Object.keys(user.document)
          ? {
            aadhaar: user.document.aadhaar ? user.document.aadhaar : "",
            PAN: user.document.PAN,
          }
          : { PAN: {}, aadhaar: {} };
      }
      state.familyDetails = user.familyDetails;
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "ADD_PHOTO",
  "uploadPhoto",
  async (data) => {
    let res = await auth.uploadPhoto(data);
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      message.error(action.data.message);
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_IMAGE",
  "updateImage",
  (status) => status,
  (state, action) => {
    state.image = action.data;
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "CHANGE_LOGIN_STATE",
  "changeLoginState",
  (data) => data,
  (state, action) => {
    if (action.data.loginstate === 4) {
      window.location = `${action.data.role}/home/dashboard`;
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_DOCUMENT",
  "updateDocument",
  (doc) => doc,
  (state, action) => {
    state.document = action.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_CURRENT_TAB",
  "updateCurrentTab",
  (link) => link,
  (state, action) => {
    state.currentTab = action.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_CATEGORY",
  "updateSelectedCategory",
  (link) => link,
  (state, action) => {
    state.selectedCategory = action.data;
    if (action.data !== "access")
      state.categoryChildren =
        navigator[state.user.role].routes[action.data].children;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_Childrem",
  "updateChildren",
  (link) => link,
  (state, action) => {
    state.categoryChildren = action.data;
    return Object.assign({}, state);
  }
);

export default reducerFactory;
