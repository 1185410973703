import React from "react";
import {
  Login,
  Register,
  CompletionMessage,
  PasswordReset
} from "./../../components";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Authorize from "../Authenticate/Authorize";
import GoogleLoginPage from "../../components/GoogleLogin/index";
import Attach from "../../components/Attachment";
import OrderSuccess from "../../components/OrderSuccess";
import OrderSummary from "../../components/OrderSummary";
import PricingModel from "../../components/PricingModel";
const routes = [
  {
    name: "Login",
    path: "/login",
    component: Login,
    icon: "",
    subpath: ""
  },
  {
    name: "Register",
    path: "/register",
    component: Register,
    icon: "",
    subpath: ""
  },
  {
    name: "CompletionMessage",
    path: "/completionMessage",
    component: CompletionMessage,
    icon: "",
    subpath: ""
  },
  {
    name: "Authenticate",
    path: "/authenticate/:authToken",
    component: Authorize,
    icon: "",
    subpath: ""
  },
  {
    name: "Password Reset",
    path: "/password-reset",
    component: PasswordReset,
    icon: "",
    subpath: ""
  },
  {
    name: "Attach",
    path: "/attach",
    component: Attach,
    icon: "",
    subpath: ""
  },
  {
    name: "OrderSuccess",
    path: "/ordersuccess",
    component: OrderSuccess,
    icon: "",
    subpath: ""
  },
  {
    name: "OrderSummary",
    path: "/ordersummary",
    component: OrderSummary,
    icon: "",
    subpath: ""
  },
  {
    name: "PricingModel",
    path: "/pricemodel",
    component: PricingModel,
    icon: "",
    subpath: "",
  }
];

function PublicRoute({ user }) {
  const history = useHistory()
  const getRegisteredState = () => {
    const pathList = ["/pricemodel", "/ordersummary", "/ordersuccess"];
    try {
      if (user && user.role === "org" && user.registerState < 2) {
        const path = pathList[user.registerState ?? 0];
        localStorage.setItem("organisationId", user.organisationId)
        history.push(path)
      }
    } catch (err) {
      return <Redirect path="/" to={"/"} />

    }
  }

  return (
    <Switch>
      {routes.map((val) => {
        return (
          <Route key={val} exact path={val.subpath + val.path}>
            {val.component}
          </Route>
        );
      })}
      <Route path="/" exact render={(props) => <Login {...props} />} />
      <Route
        path="/googleLogin"
        exact
        render={(props) => <GoogleLoginPage {...props} />}
      />
      {user ? getRegisteredState() : ""}
      <Redirect path="*" to="/" />
    </Switch>
  );
}

export default PublicRoute;
