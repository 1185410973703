// import { findByDisplayValue } from "@testing-library/dom";
// import { Button } from "antd";

const Form16 = ({ todayDate, dateOfJoining }) => {

    return (
        <div className="form-16-container">
            <h1 className="card-headers">Form 16</h1>
            <div className="financial-year-list-container">
                {/* {
                    financialYearList.reverse().map((year) => {
                        return (
                            <div className="financial-year-container">
                                <span className="financial-year">{year}</span>
                                <Button className="financial-year-view-button">View</Button>
                            </div>
                        )
                    })
                } */}
                <p>No Form16 document is available for you right now</p>
            </div>
        </div>
    )
}

export default Form16;