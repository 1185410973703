import { useEffect } from "react";
import { Layout, Button, Spin } from "antd";
import employee from "../../../../redux/modules/employee";
import auth from "../../../../redux/modules/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import config, {
  checkFinancialYear,
  numformatter,
} from "../../../../utils/config";
const { Content } = Layout;

const VerifyCTC = ({
  employeeReducer,
  ctc,
  user,
  finalPayslip,
  selectedIndex,
  changePageState,
}) => {
  useEffect(() => {
    const { financialYear, month } = checkFinancialYear();
    employeeReducer.askMool({
      financialYear,
      month,
      id: user._id,
      user,
      ctc,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, ctc]);
  const currentMonth = new Date().getMonth();

  return (
    <Spin spinning={finalPayslip.basic === 0} size={"large"}>
      <Content className="stages">
        <div className="main-container">
          <p className="payslip-title">
            According to the data that you gave us we run some math, and found
            these as the perfect numbers for you
          </p>

          <div className="allowances-container">
            <div className="single-allowance">
              <p>Basic</p>
              <p>{numformatter(finalPayslip.basic)} INR</p>
            </div>
            {finalPayslip.HRA !== 0 && (
              <div className="single-allowance">
                <p>House Rent Allowances</p>
                <p>{numformatter(finalPayslip.HRA)} INR</p>
              </div>
            )}
            {finalPayslip.specialAllowance !== 0 && (
              <div className="single-allowance">
                <p>Special Allowances</p>
                <p>{numformatter(finalPayslip.specialAllowance)} INR</p>
              </div>
            )}
            {finalPayslip.DA !== 0 && (
              <div className="single-allowance">
                <p>Dearness Allowances</p>
                <p>{numformatter(finalPayslip.DA)} INR</p>
              </div>
            )}
            {finalPayslip.LTA !== 0 && (
              <div className="single-allowance">
                <p>Leave Travel Allowances</p>
                <p>{numformatter(finalPayslip.LTA)} INR</p>
              </div>
            )}
            {Object.keys(finalPayslip.otherAllowances).map((val) => (
              <div className="single-allowance">
                <p>{val}</p>
                <p>{numformatter(finalPayslip.otherAllowances[val])} INR</p>
              </div>
            ))}
            {Object.keys(finalPayslip.customAllowances).map((val) => (
              <div className="single-allowance">
                <p>{val}</p>
                <p>{numformatter(finalPayslip.customAllowances[val])} INR</p>
              </div>
            ))}
            {Object.keys(finalPayslip.bonuses).map((val) => (
              <div className="single-allowance">
                <p>{val}</p>
                <p>{numformatter(finalPayslip.bonuses[val])} INR</p>
              </div>
            ))}
            <div className="single-allowance">
              <p>Earning</p>
              <p>{numformatter(finalPayslip.totalFixedIncome)} INR</p>
            </div>
            {finalPayslip.employee_PF !== 0 && (
              <div className="single-allowance">
                <p>PF</p>
                <p>{numformatter(finalPayslip.employee_PF)} INR</p>
              </div>
            )}
            {(user.regimeOptedStatus === 1
              ? finalPayslip.newRegime.incomeTax !== 0
              : finalPayslip.oldRegime.incomeTax !== 0) && (
              <div className="single-allowance">
                <p>Income Tax</p>
                <p>
                  {user.regimeOptedStatus === 1
                    ? numformatter(
                        finalPayslip.newRegime.incomeTax +
                          (isNaN(parseInt(finalPayslip.advanceTax))
                            ? 0
                            : finalPayslip.advanceTax)
                      )
                    : numformatter(
                        finalPayslip.oldRegime.incomeTax +
                          (isNaN(parseInt(finalPayslip.advanceTax))
                            ? 0
                            : finalPayslip.advanceTax)
                      )}{" "}
                  INR
                </p>
              </div>
            )}
            {finalPayslip.proffTax !== 0 && (
              <div className="single-allowance">
                <p>Professional Tax</p>
                <p>{numformatter(finalPayslip.proffTax)} INR</p>
              </div>
            )}
            {finalPayslip.FOOD !== 0 && (
              <div className="single-allowance">
                <p>Food Allowance</p>
                <p>{numformatter(finalPayslip.FOOD)} INR</p>
              </div>
            )}
            {(user.regimeOptedStatus === 1
              ? finalPayslip.newRegime.deductions !== 0
              : finalPayslip.oldRegime.deductions !== 0) && (
              <div className="single-allowance">
                <p>Deduction</p>
                <p>
                  {user.regimeOptedStatus === 1
                    ? numformatter(finalPayslip.newRegime.deductions)
                    : numformatter(finalPayslip.oldRegime.deductions)}{" "}
                  INR
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="ctc-container-initial">
          <div className="ctc-amount">
            <p className="ctc-index">CTC:</p>
            <p className="ctc-actual-amount">
              {" "}
              {(Object.keys(ctc).length
                ? ctc.costToCompany[currentMonth]["ctc"]
                : 0) / 100000}{" "}
              LPA
            </p>
          </div>
          <div className="inhand-container">
            <p>
              {user.regimeOptedStatus === 1
                ? config.numformatter(finalPayslip.newRegime.takeHome)
                : config.numformatter(finalPayslip.oldRegime.takeHome)}{" "}
              INR/ Month
            </p>
            <span>IN HAND</span>
          </div>
        </div>
        <div className="submit-container">
          <Button
            onClick={async () => {
              await employeeReducer.savePayslip({
                user,
                finalPayslip,
                selectedIndex,
              });
              changePageState();
            }}
            className="submit-button"
          >
            Continue
          </Button>
        </div>
      </Content>
    </Spin>
  );
};

VerifyCTC.propTypes = {
  user: PropTypes.object.isRequired,
  ctc: PropTypes.object.isRequired,
  hra: PropTypes.object.isRequired,
  deductions: PropTypes.object.isRequired,
  allowances: PropTypes.object.isRequired,
  customAllowances: PropTypes.object.isRequired,
  organisationSettings: PropTypes.object.isRequired,
};

const VerifyCTCContainer = connect(
  (state) => ({
    user: state.auth.user,
    ctc: state.employee.ctc,
    finalPayslip: state.employee.finalPayslip,
    selectedIndex: state.employee.selectedIndex,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    employeeReducer: employee.getActions(dispatch),
  })
)(VerifyCTC);

export default withRouter(VerifyCTCContainer);
