module.exports = {
    Allowance: {
        HRA : "HRA",
        EXT_LTA : "LTA",
        EXT_DAILY : "daily",
        EXT_HELPER : "helper",
        EXT_RESEARCH : "research",
        EXT_UNIFORM : "uniform",
        EXT_TOUR_TRAVEL_TRANSFER : "tourTravel_transfer",
        EXT_CONVEYANCE : "conveyance",
        EXT_CHILD_EDU : "childrenEducation",
        EXT_HOSTEL_EDU : "hostelExpenditure",
        EXT_TRANSPORT_DIVYANG : "transportDivyangPerson",
        EXT_TRANSPORT_BUSINESS : "transportBusiness",
        EXT_DEARNESS : "dearness",
        EXT_OVERTIME : "overtime",
        EXT_FIXED_MEDICAL : "fixedMedical",
        EXT_PROJECT : "project",
        EXT_TIFFIN_LUNCH_DINNER : "tiffinLunchDinner",
        EXT_TRANSPORT_REGULAR : "transportRegular",
        SPECIAL : "special",
        EXT_MOB : "mobile",
        EXT_TEL : "telephone",
        EXT_LAPTOP_REIMBURSEMENT: "laptopReimbursement",
        EXT_BOOKS_OF_PERIODICALS: "booksOfPeriodicals",
        EXT_FUEL_AND_VEHICLE_MAINTENANCE: "fuelAndVehicleMaintenance"

    },
    Perks: {
        EXT_TEL_MOB : "telephoneMobile",
        EXT_CONC_TRAVEL_IN_TRAVEL_BIZ : "freeOrConcessionalTransportProvidedByEmployerInTransportBusiness",
        EXT_CONTR_STAFF_GRP_INSURANCE : "employerContributionToStaffGroupInsurance",
        EXT_PREMIUM_ON_ACCIDENT_POLICY : "annualPremiumByEmployerOnPersonalAccidentPolicy",
        EXT_REFRESHMENT_WORKING_HRS : "refreshmentsDuringWorkingHours",
        EXT_SUBSIDIZED_LUNCH_WORKING_HRS : "subsidizedLunchDuringWorkingHours",
        EXT_RECREATIONAL_FACILITIES : "recreationalFacilites",
        EXT_EMPL_TRAINING : "employeeTraining",
        EXT_MOTOR_CAR_CONVEYANCE : "motorCarConveyance",
        EXT_CONCESSIONAL_LOAN : "interestFreeOrConcessionalLoanNotExceeding20k",
        EXT_GIFT_VOUCHER : "giftVoucherCouponCeremony",
        EXT_EDU_FACILITIES : "educationFacilities",
        EXT_CONCESSIONAL_FOOD_NON_ALC_BEV : "freeOrConcessionalFoodAndNonAlcoholicBeverage",
        EXT_EMPLEE_OBLIG_PAID_BY_EMPLYER : "employeeObligationPaidByEmployer",
    },
    taxRules: {
        LAW_LIMIT_PERK_GIFT_VOUCHER : "GIFT_VOUCHER_ANNUAL",
        LAW_LIMIT_PERK_LOAN_LIMIT : "PETTY_LOAN_LIMIT",
        LAW_LIMIT_ALLWNCE_CHILD_EDU : "CHILD_EDUCATION_PER_CHILD",
        LAW_LIMIT_ALLWNCE_HOSTEL_EXP : "HOSTEL_PER_CHILD",
        LAW_LIMIT_ALLWNCE_TRANSPORT_DIVYANG : "TRANSPORT_DIVYANG",
        LAW_LIMIT_ALLWNCE_TRANS_BUSINESS_HARD : "TRANSPORT_TRASPORT_BUSINESS_HARD_LIMIT",
        LAW_LIMIT_ALLWNCE_TRANS_BUSINESS_PERCENT : "TRANSPORT_TRASPORT_BIZ_PRCNT_LIMIT",
        LAW_LIMIT_FREE_FOOD_BEVERAGE : "LIMIT_FREE_FOOD_BEVERAGE",
    }
}