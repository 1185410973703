import { useState, useEffect } from "react";
import { Button, DatePicker, Table } from "antd";
import { getOrgReport } from "../../../../apis/organisation";
import axios from "../../../../apis";
import { connect } from "react-redux";
import moment from "moment";
import { monthList, rootUrl } from "../../../../utils/config";
import { NormalButton } from "../../../ComponentLibrary";
import TrialPage from "../../../Common/TrialComponent";
import attendanceAttendanceBgImage from "../../../../assets/dummyPages/attendanceAttendanceBgImage.png"


const Report = ({ user, isTrial }) => {
  const [filter, setFilter] = useState(moment());
  const [attendanceReport, setAttendanceReport] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);


  useEffect(() => {
    getOrgReport(
      user.organisationId,
      new Date(filter).getMonth(),
      new Date(filter).getFullYear()
    ).then((res) => {
      setAttendanceReport([...res.data]);
    });
  }, [filter, isRefresh, user.organisationId]);

  const columns = [
    {
      title: "Employee ID",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
      key: "name",
    },
    {
      title: "Name",
      dataIndex: ["user", "displayName"],
      key: "name",
    },
    {
      title: "Average work hour",
      dataIndex: "workingMinutes",
      key: "awh",
      render: (data, row) => <p>{Math.round(data / 60 / row.workingDays)}</p>,
    },
    {
      title: "Missed login",
      dataIndex: "missedLogin",
      key: "missedLogin",
      render: (_data, _row) => <p>-</p>,
    },
    {
      title: "Leave",
      dataIndex: "leaves",
      key: "leave",
    },
    {
      title: "Working days",
      dataIndex: "workingDays",
      key: "workingDays",
    },
  ];

  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }

  const handleAction = async (url) => {
    let res = await axios({
      url, //your url
      method: "GET",
      responseType: "blob",
    });
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = urlDownload;
    link.setAttribute(
      "download",
      `Attendance-${monthList[new Date(filter).getMonth()]}.pdf`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  return (
    isTrial ? <TrialPage user={user} trialMessage={" Get access to India's first tax optimizing and salary personalization tool powering your payroll"} bgImage={attendanceAttendanceBgImage}
    /> :
    <div className="report-container">
      <div className="report-header-container">
        <p className="font-size-global-24 font-weight-global-700">Report</p>
        <div className="flex justify-between align-center">
          {/* <div className="year-container">
            <p className="year-name-heading">Year</p>
            <DatePicker
              value={year}
              onChange={(e) => {
                setYear(e)
              }}
              picker="year"
              placeholder=""
            />
          </div> */}
          <span style={{ float: "right" }}>
            <Button
              onClick={startRefresh}
              className={`button-lib button-type-13 p-10`}
              style={{ height: "2.5rem" }}
            >
              <div className="flex justify-between align-center">
                Refresh
              </div>
            </Button>
          </span>
          <NormalButton
            type={13}
            buttonText={"Download PDF"}
            onClick={() =>
              handleAction(
                `${rootUrl}/api/attendance/monthly/pdf/${user.organisationId
                }/${new Date(filter).getMonth()}/${new Date(
                  filter
                ).getFullYear()}`
              )
            }
            className="button-container"
          />
          <div className="year-container m-20">
            <DatePicker
              value={filter}
              picker="month"
              placeholder=""
              onChange={(e) => {
                setFilter(e);
              }}
            />
          </div>
        </div>
      </div>
      <Table
        pagination={attendanceReport.length <= 8 ? false : { pageSize: 8 }}
        columns={columns}
        dataSource={attendanceReport.reverse()}
      />
    </div>
  );
};

const ReportContainer = connect((state) => ({
  user: state.auth.user,
  isTrial: state.auth.user.isTrial,

}))(Report);

export default ReportContainer;
