import { useState, useEffect } from "react";
import { Input, Button, Spin, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as authAPI from "../../apis/auth";
import profilePhotoEmployee from "../../assets/Login/emojipng.com-35999@2x.png";
import skeleton2 from "../../assets/Login/Group 128.svg";
import profilePhotoOrganisation from "../../assets/Login/Group 190.svg";
import heroImage from "../../assets/Login/Group 233.png";
import skeleton1 from "../../assets/Login/Group225.png";
import auth from "../../redux/modules/auth";
import { googleClientId } from "../../utils/env";
import GoogleLogin from "react-google-login";
import googleicon from "../GoogleLogin/google.svg";



const Login = (props) => {
  const [loginDetais, setDetails] = useState({
    username: "",
    password: "",
  });
  const [userType, setUserType] = useState("");
  const [username, setusername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const setLoginDetails = async (loginData) => {
    setIsLoading(true);

    let userDetails = await authAPI.checkUser({ loginDetails: loginData });
    if (userDetails.data.success) {
      let { role, name, username } = userDetails.data.data;
      setUserType(role);
      setusername(name);
      setDetails({ ...loginDetais, username });
      setIsLoading(false);
    } else {
      notification.error({ message: "Sorry user not found" });
    }
    setIsLoading(false);
  };

  const signInUser = async (password) => {
    setIsLoading(true);
    setDetails({ ...loginDetais, password: password });
    const { authReducer } = props;
    authReducer.login({ username: loginDetais.username, password: password });
    setIsLoading(false);
  };

  const clearUser = () => {
    setusername("");
    setUserType("");
    setDetails({
      username: "",
      password: "",
    });
  };
  useEffect(() => {
    document.title = 'Login Page';
  });
  return (
    <div className="login-container">
      <div className="login-contianer">
        <div className="login-form-card">
          <div className="card-title-container">
            <div className="card-title-text">
              <div className="card-title-icon">
                <ExclamationCircleOutlined />
              </div>
              Organisation/ Employee Login
            </div>
          </div>
          {userType !== "" ? (
            <LoginFormPasswordDetails
              username={username}
              userType={userType}
              signInUser={signInUser}
              isLoading={isLoading}
              clearUser={clearUser}
              history={props.history}
              userDetails={loginDetais.username}
            />
          ) : (
            <LoginFormPanDetails
              isLoading={isLoading}
              setLoginDetails={setLoginDetails}
              authReducer={props.authReducer}
            />
          )}
        </div>
      </div>
      <HeroSection />
    </div>
  );
};

const LoginFormPanDetails = ({ setLoginDetails, isLoading, authReducer }) => {
  const clientId = googleClientId
  const onSuccess = async (res) => {
    try {
      authReducer.googleLogin(res)
    } catch (err) {
      notification.error({ message: "Failed to login with google" })
    }
  };
  const onFailure = (err) => {
    notification.error({ message: "Failed to login with google" })
  };
  const [loginData, setLoginData] = useState("");
  const submitLoginDetails = () => {
    if (!loginData) {
      notification.error({
        message: "Please enter the username or email address",
      });
      return;
    }
    setLoginDetails(loginData);
  };



  return (
    <>
      <div className="card-text-holder">
        <h3 className="card-text">
          Hi There,
          <br /> Welcome back
        </h3>
      </div>
      <Spin size="large" spinning={isLoading} delay={500}>
        <div className="login-info-holder " style={{ paddingTop: "3rem" }}>
          <label className="login-info-text">
            Username(PAN) / Email Address
            <br />
            <Input
              className="input-login"
              autoFocus={true}
              onPressEnter={submitLoginDetails}
              value={loginData}
              onChange={(e) => {
                setLoginData(e.target.value);
              }}
            />
          </label>
          <Button
            onClick={submitLoginDetails}
            className="button"
            type="primary"
            size={"large"}
            style={{ width: '100%', background: "#405cd2" }}
          >
            Continue
          </Button>
          <br />
          <GoogleLogin
            clientId={clientId}
            buttonText="Sign in with Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
            render={(renderProps) => {
              return (
                <Button
                  style={{
                    width: "100%",
                    height: "40px",
                    backgroundColor: "white",
                    cursor: "pointer",
                    fontSize: "14px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <img
                    src={googleicon}
                    alt="icon"
                    style={{
                      width: "40px",
                      height: "30px",
                      marginRight: "10px"
                    }}
                  />
                  <span >Sign in with Google</span>
                </Button>
              )
            }}
          />
        </div>
      </Spin>
      <div className="additional-links" style={{ paddingBottom: "2rem" }}>
        <hr style={{ color: "#EAEAEE" }}></hr>
        <a style={{ paddingTop: "0.5rem" }} href="/register">
          New organisation? create account
        </a>
      </div>
    </>
  );
};

const LoginFormPasswordDetails = ({
  username,
  userType,
  signInUser,
  isLoading,
  clearUser,
  history,
  userDetails,
}) => {
  const [password, setPassword] = useState("");
  const checkSignInUser = () => {
    if (!password) {
      notification.error({ message: "Please enter password" });
      return;
    }
    signInUser(password.trim());
  };

  return (
    <>
      <div className="card-text-holder">
        <h3 className="card-text">Enter your password</h3>
      </div>
      <div className="user-info-holder">
        <div style={{ marginRight: 15 }}>
          {userType === "employee" ? (
            <img
              src={profilePhotoEmployee}
              className="employee-avatar"
              alt="employee avatar"
            ></img>
          ) : userType === "org" ? (
            <img
              src={profilePhotoOrganisation}
              className="organisation-avatar"
              alt="organisation avatar"
            ></img>
          ) : userType === "ca" ? (
            <img
              src={profilePhotoEmployee}
              className="employee-avatar"
              alt="chartered accountant avatar"
            ></img>
          ) : (
            <></>
          )}
        </div>
        <div className="user-info">
          <p className="userType">{userType}</p>
          <p className="username">{username}</p>
        </div>
      </div>
      <Spin size="large" spinning={isLoading} delay={500}>
        <div className="login-info-holder">
          <label className="login-info-text">
            Password
            <br />
            <Input.Password
              onPressEnter={checkSignInUser}
              autoFocus={true}
              className="input-login"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <Button
            onClick={checkSignInUser}
            className="button"
            type="primary"
            size={"large"}
            style={{ width: '100%', background: "#405cd2" }}
          >
            Sign In
          </Button>
        </div>
      </Spin>
      <div className="additional-links-for-password">
        <div className="additional-links-for-password-hr"></div>
        <div className="m-bottom-16 cursor-pointer">
          <span
            onClick={() =>
              history.push("/password-reset", { username: userDetails })
            }
          >
            Forgot password
          </span>
        </div>
        <span className="cursor-pointer" onClick={clearUser}>
          Switch to a new account
        </span>
      </div>
    </>
  );
};

const HeroSection = () => {
  return (
    <div className="hero-container">
      <img alt="" src={skeleton1} className="skeleton1"></img>
      <img alt="" src={heroImage} className="heroImage"></img>
      <img alt="" src={skeleton2} className="skeleton2"></img>
    </div>
  );
};

Login.propTypes = {
  user: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

const LoginContainer = connect(
  (state) => ({
    user: state.auth.user,
    isLoggedIn: state.auth.isLoggedIn,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
  })
)(Login);

export default withRouter(LoginContainer);
