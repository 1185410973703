import { ArrowLeftOutlined, PlusOutlined, UsergroupAddOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Modal, notification, Row, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deletePayoutBeneficiary, newPayoutBeneficiary, payoutBeneficiary
} from "../../apis/organisation";
import { deleteLogo } from "../../assets/common/editAndDeleteLogos";
import payoutEmpty from "../../assets/organisationConfiguration/AllowanceEmpty.svg";
import { validateEmail } from "../../utils/config";
import { HeaderComponent } from "../Common";
import { NormalButton } from "../ComponentLibrary";
import UpdateBeneficiary from "./UpdateBeneficiary";
import { pincodeData } from "../../constants/common_functions"
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import TrialPage from "../Common/TrialComponent";
import beneficiaryBgImage from "../../assets/dummyPages/beneficiaryBgImage.png"


const PayoutBeneficiary = ({ user, deviceWidth, employeeList, payout, isTrial }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageState, setPageState] = useState(0);
  const [BeneficiaryList, setBeneficiaryList] = useState([]);
  const [newBeneficiaryDetails, setNewBeneficiaryDetails] = useState({
    id: "",
    name: "",
    email: "",
    phone: null,
    bankAccount: "",
    IFSC: "",
    address1: "",
    city: "",
    state: "",
    pincode: null,
    status: "VERIFIED",
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateBeneficiaryData, setUpdateBeneficiaryData] = useState({});

  const [payoutAvailable, setPayoutAvailable] = useState(false);


  useEffect(() => {
    if (payout && Object.keys(payout).length > 0 && payout.instanceStatus === 3) {
      setPayoutAvailable(true)
    }
  }, [payout])

  let getAllBeneficiary = async () => {
    setIsLoading(true);
    let res = await payoutBeneficiary(user.organisationId);
    if (res.status === 200 && res.data.success) {
      setBeneficiaryList(res.data.data.beneficiaries);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllBeneficiary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payout]);

  const onSave = async () => {
    setIsLoading(true);
    if (newBeneficiaryDetails.name === "") {
      notification.error({ message: "Beneficiary name cannot be empty.", description: "Name of beneficiary can not be blank!" });
      setIsLoading(false);
      return;
    }
    if (!validateEmail(newBeneficiaryDetails.email)) {
      notification.error({ message: "This Employee Does not have a valid Email", description: "Email is mandatory while adding the beneficiary!" });
      setIsLoading(false);
      return;
    }
    if (
      newBeneficiaryDetails.phone === null ||
      String(newBeneficiaryDetails.phone).length !== 10
    ) {
      notification.error({ message: "This Employee Does not have a valid Phone number", description: "Phone Number is mandatory while adding the beneficiary!" });
      setIsLoading(false);
      return;
    }
    if (newBeneficiaryDetails.bankAccount === "") {
      notification.error({ message: "Bank account cannot be empty." });
      setIsLoading(false);
      return;
    }
    if (newBeneficiaryDetails.IFSC === "") {
      notification.error({ message: "IFSC cannot be empty." });
      setIsLoading(false);
      return;
    }
    if (newBeneficiaryDetails.address1 === "") {
      notification.error({ message: "Address cannot be empty" });
      setIsLoading(false);
      return;
    }
    if (newBeneficiaryDetails.city === "") {
      notification.error({ message: "City cannot be empty." });
      setIsLoading(false);
      return;
    }
    if (newBeneficiaryDetails.state === "") {
      notification.error({ message: "State cannot be empty." });
      setIsLoading(false);
      return;
    }
    if (
      newBeneficiaryDetails.pincode === null ||
      String(newBeneficiaryDetails.pincode).length !== 6 ||
      !parseInt(newBeneficiaryDetails.pincode)
    ) {
      notification.error({ message: "Pincode is not valid." });
      setIsLoading(false);
      return;
    }
    let res = await newPayoutBeneficiary({
      ...newBeneficiaryDetails,
      organisationId: user.organisationId,
    });
    if (res.status === 200 && res.data.success) {
      notification.success({ message: "Successfully added the Beneficiary." });
      getAllBeneficiary();
      clearAndCloseAddNewBeneficiaryPage();
    } else {
      notification.error({
        message: "Failed to create Beneficiary, please try again.",
      });
      setIsLoading(false);
    }
  };

  const clearAndCloseAddNewBeneficiaryPage = () => {
    setNewBeneficiaryDetails({
      id: "",
      name: "",
      email: "",
      phone: null,
      bankAccount: "",
      IFSC: "",
      address1: "",
      city: "",
      state: "",
      pincode: null,
      status: "VERIFIED",
    });
    setShowEditModal(false);
    setUpdateBeneficiaryData({});
    setPageState(0);
  };

  const onDeleteConfirm = async () => {
    setIsLoading(true);
    try {
      let res = await deletePayoutBeneficiary(
        user.organisationId,
        updateBeneficiaryData.id
      );
      if (res.status === 200 && res.data.success) {
        notification.success({
          message: "Successfully deleted the Beneficiary.",
        });
        getAllBeneficiary();
        clearAndCloseAddNewBeneficiaryPage();
        setShowDeleteModal(false)
      } else {
        notification.error({
          message: "Failed to delete the Beneficiary, please try again.",
        });
        setIsLoading(false);
        setShowDeleteModal(false)
      }
    } catch (err) {
      notification.error({
        message: "Failed to delete the Beneficiary, please try again.",
      });
      setIsLoading(false);
      setShowDeleteModal(false)
      return;
    }
  };
  useEffect(() => {
    document.title = 'Payout | Beneficiary';
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Account Number",
      dataIndex: "bankAccount",
      ellipsis: true,
      width: deviceWidth > 1500 ? "10%" : "15%",
    },
    {
      title: "IFSC",
      dataIndex: "IFSC",
      ellipsis: true,
      width: deviceWidth > 1500 ? "10%" : "15%",
    },
    {
      title: "Address",
      dataIndex: "address1",
      ellipsis: true,
      width: deviceWidth > 1500 ? "10%" : "20%",
    },
    {
      title: "City",
      dataIndex: "city",
      ellipsis: true,
      width: deviceWidth > 1500 ? "10%" : "12%",
    },
    {
      title: "",
      dataIndex: "city",
      width: "20%",
      render(_city, rowData) {
        return (
          <div className="flex">
            {/* <NormalButton
                            type={5}
                            icon={editLogo}
                            margin={[3, 0]}
                            onClick={() => {
                                setNewBeneficiaryDetails(rowData)
                                setUpdateBeneficiaryData(rowData)
                                setShowEditModal(true)
                                setPageState(1)
                            }}
                        /> */}
            <NormalButton
              type={5}
              margin={[3, 0]}
              icon={deleteLogo}
              onClick={() => {
                setShowDeleteModal(true);
                setUpdateBeneficiaryData(rowData);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    isTrial ? <TrialPage user={user} trialMessage={" No need to depend on banks. Setup payouts & disburse salaries directly to employees bank account without any errors"} bgImage={beneficiaryBgImage}
    /> :
      <div className="site-layout">
        <HeaderComponent name="Beneficiary" />
        <div
          className={
            pageState === 0 || pageState === 1
              ? "app-main-content-container paper-wrapper"
              : "app-main-content-container app-white-card-1"
          }
        >
          {pageState === 2 && payoutAvailable && (
            <UpdateBeneficiary
              user={user}
              clearAndCloseAddNewBeneficiaryPage={
                clearAndCloseAddNewBeneficiaryPage
              }
              newBeneficiaryDetails={newBeneficiaryDetails}
              setNewBeneficiaryDetails={setNewBeneficiaryDetails}
              setPageState={setPageState}
              isLoading={isLoading}
              showEditModal={showEditModal}

            />
          )}
          {pageState === 1 && payoutAvailable && (
            <AddNewBeneficiary
              clearAndCloseAddNewBeneficiaryPage={
                clearAndCloseAddNewBeneficiaryPage
              }
              onSave={onSave}
              newBeneficiaryDetails={newBeneficiaryDetails}
              setNewBeneficiaryDetails={setNewBeneficiaryDetails}
              setPageState={setPageState}
              isLoading={isLoading}
              showEditModal={showEditModal}
              employeeList={employeeList}
            />
          )}
          {pageState === 0 && payoutAvailable && (
            <DisplayBeneficiary
              columns={columns}
              setPageState={setPageState}
              BeneficiaryList={BeneficiaryList}
              isLoading={isLoading}
            />
          )}
          {
            !payoutAvailable && <ZeroState />
          }
        </div>
        <Modal
          spinning={isLoading}
          centered
          closable={false}
          visible={showDeleteModal}
          className="modal-container"
          footer={null}
          onCancel={() => {
            setShowDeleteModal(false);
            setUpdateBeneficiaryData({});
          }}
        >
          <Spin spinning={isLoading}>
            <div className="">
              <div className="flex justify-between">
                <h1 className="app-heading-2">Delete Beneficiary</h1>
              </div>
              <div className="">
                <p className=" font-size-16 font-weight-500">
                  Are you sure you want to remove <b>{updateBeneficiaryData.name}</b> as
                  a Beneficiary.
                </p>
              </div>
              <div className="m-top-36  text-align-right">
                <NormalButton
                  type={5}
                  buttonText={"Cancel"}
                  margin={[0, 12]}
                  onClick={() => {
                    setShowDeleteModal(false);
                    setUpdateBeneficiaryData({});
                  }}
                />
                <NormalButton
                  type={5}
                  buttonText="Delete"
                  onClick={() => {
                    onDeleteConfirm();
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
  );
};


const ZeroState = () => {
  return (
    <div className="payout-state-0-container flex flex-direction-column align-center">
      <div className="my-30">
        <img alt="img" className="payout-state-0-image" src={payoutEmpty} />
      </div>
      <p className="title-gray-2 my-30">
        Auto-payouts has not been set up for your organisation
      </p>
    </div>
  );
};


const DisplayBeneficiary = ({
  columns,
  BeneficiaryList,
  setPageState,
  isLoading,
}) => {
  function handleMenuClick(e) {
    if (e.key === "1") {
      setPageState(1)
    } else {
      setPageState(2)
    }
  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserAddOutlined />}>
        Single Beneficiary
      </Menu.Item>
      <Menu.Item key="2" icon={<UsergroupAddOutlined />}>
        Multiple Beneficiary
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="flex justify-between">
        <h1 className="page-heading font-weight-700">Beneficiaries ( {BeneficiaryList ? BeneficiaryList.length : 0} )</h1>
        <div className="flex">
          <Dropdown overlay={menu}>
            <Button
              className={`button-lib button-type-13 p-10`}
              style={{ height: "2.5rem" }}
              icon={<PlusOutlined />}
              onClick={() => setPageState(1)}
            >
              Add Beneficiary
            </Button>
          </Dropdown>

        </div>
      </div>
      <Table
        style={{ fontWeight: 500, marginTop: 16 }}
        columns={columns}
        dataSource={BeneficiaryList}
        pagination={false}
        scroll={{ y: 650 }}
        loading={isLoading}
      />
    </div>
  );
};

const AddNewBeneficiary = ({
  clearAndCloseAddNewBeneficiaryPage,
  onSave,
  newBeneficiaryDetails,
  setNewBeneficiaryDetails,
  isLoading,
  showEditModal,
  employeeList
}) => {
  return (
    <Spin spinning={isLoading}>
      <div className='heading-style-3-container flex justify-between px-30'>
        <div>
          <div className='flex align-center'>
            <ArrowLeftOutlined
              height={18}
              onClick={clearAndCloseAddNewBeneficiaryPage}
              className='arrow-left-button'
            />
            <h1 className='font-size-global-24 font-weight-global-700'>
              Add Beneficiary
            </h1>
          </div>
        </div>
        <div>
          <NormalButton
            type={5}
            buttonText='Cancel'
            margin={[0, 16]}
            onClick={clearAndCloseAddNewBeneficiaryPage}
          />
          <NormalButton
            type={13}
            buttonText={showEditModal ? "Update" : "Save"}
            onClick={onSave}
          />
        </div>
      </div>
      <div className='payout-details-container px-36 p-bottom-36'>
        <div className='payout-details-sub-section-container'>
          <Row gutter={[100, 20]}>
            <Col sm={24} md={12} lg={9} xl={9}>
              <DropdownComponent
                className='general-ant-field-select'
                labeltext={"Employee Name"}
                options={employeeList.map((item) => item.displayName)}
                values={employeeList.map((item) => item._id)}
                value={newBeneficiaryDetails.id}
                onChange={(e) => {
                  let val = employeeList.filter((v) => v._id === e);
                  setNewBeneficiaryDetails({
                    ...newBeneficiaryDetails,
                    id: e,
                    name: val[0].displayName,
                    email: val[0].primaryEmail,
                    phone: val[0].phone ?? "",
                    bankAccount: val[0].bankInfo.accountNumber
                      ? val[0].bankInfo.accountNumber
                      : "",
                    IFSC:
                      val[0].bankInfo && val[0].bankInfo.ifsc
                        ? val[0].bankInfo.ifsc
                        : "",
                    address1:
                      val[0].bankInfo && val[0].bankInfo.bankAddress
                        ? val[0].bankInfo.bankAddress
                        : "",
                    state:
                      val[0].bankInfo && val[0].bankInfo.state
                        ? val[0].bankInfo.state
                        : "",
                    city:
                      val[0].bankInfo && val[0].bankInfo.city
                        ? val[0].bankInfo.city
                        : "",
                    pincode:
                      val[0].bankInfo && val[0].bankInfo.pinCode
                        ? val[0].bankInfo.pinCode
                        : "",
                  });
                }}
                style={{ width: "100%" }}
                bordered={false}
                size={"large"}
                filterOption={(input, option) =>
                  option.children &&
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                showSearch
              />
              {/* {employeeList.map((val) => {
                  return <Option value={val._id}>{val.displayName}</Option>;
                })}
              </Select> */}
            </Col>
            <Col sm={24} md={12} lg={9} xl={9}>
              <InputFieldComponent
                labeltext='Phone Number'
                value={newBeneficiaryDetails.phone}
                onChange={(e) =>
                  setNewBeneficiaryDetails({
                    ...newBeneficiaryDetails,
                    phone: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </div>
        <div className='payout-details-sub-section-container'>
          <p className='sub-section-heading'>BANK DETAILS</p>
          <Row gutter={[100, 20]}>
            <Col sm={24} md={12} lg={9} xl={9}>
              <InputFieldComponent
                labeltext='Account Number'
                value={newBeneficiaryDetails.bankAccount}
                onChange={(e) =>
                  setNewBeneficiaryDetails({
                    ...newBeneficiaryDetails,
                    bankAccount: e.target.value,
                  })
                }
              />
            </Col>
            <Col sm={24} md={12} lg={9} xl={9}>
              <InputFieldComponent
                labeltext='IFSC'
                value={newBeneficiaryDetails.IFSC}
                onChange={(e) =>
                  setNewBeneficiaryDetails({
                    ...newBeneficiaryDetails,
                    IFSC: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </div>
        <div className='payout-details-sub-section-container'>
          <p className='sub-section-heading'>ADDRESS</p>
          <Row gutter={[100, 20]}>
            <Col sm={24} md={12} lg={8} xl={9}>
              <InputFieldComponent
                labeltext='Pincode'
                value={newBeneficiaryDetails.pincode}
                type={Number}
                onChange={(e) => {
                  if (e.target.value.length > 6) {
                    return;
                  }
                  setNewBeneficiaryDetails({
                    ...newBeneficiaryDetails,
                    pincode: e.target.value,
                  });
                  if (e.target.value.length === 6) {
                    setNewBeneficiaryDetails({
                      ...newBeneficiaryDetails,
                      pincode: e.target.value,
                    });
                    const data = pincodeData(e.target.value);
                    Promise.resolve(data).then((res) => {
                      setNewBeneficiaryDetails({
                        ...newBeneficiaryDetails,
                        pincode: e.target.value,
                        city: res.Block,
                        state: res.State,
                      });
                    });
                  }
                }}
              />
            </Col>
            <Col sm={24} md={12} lg={9} xl={9}>
              <InputFieldComponent
                labeltext='Address'
                value={newBeneficiaryDetails.address1}
                onChange={(e) =>
                  setNewBeneficiaryDetails({
                    ...newBeneficiaryDetails,
                    address1: e.target.value,
                  })
                }
              />
            </Col>
            <Col sm={24} md={12} lg={8} xl={9}>
              <InputFieldComponent
                labeltext='City'
                value={newBeneficiaryDetails.city}
                onChange={(e) =>
                  setNewBeneficiaryDetails({
                    ...newBeneficiaryDetails,
                    city: e.target.value,
                  })
                }
              />
            </Col>
            <Col sm={24} md={12} lg={8} xl={9}>
              <InputFieldComponent
                labeltext='State'
                value={newBeneficiaryDetails.state}
                onChange={(e) =>
                  setNewBeneficiaryDetails({
                    ...newBeneficiaryDetails,
                    state: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
};

const PayoutBeneficiaryContainer = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    payout: state.organisation.payout,
    isTrial: state.auth.user.isTrial,
  }),
)(PayoutBeneficiary);

export default PayoutBeneficiaryContainer;
