
import { combineReducers } from 'redux';
import auth from './modules/auth';
import organisation from './modules/organisation';
import employee from './modules/employee';
import responsive from "./modules/responsive";

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    auth: auth.getReducer(),
    organisation: organisation.getReducer(),
    employee: employee.getReducer(),
    responsive: responsive.getReducer(),
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  return rootReducer;
}
