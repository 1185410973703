const widthOne = 150;

const DATA_SYNC = {
    TAB_PANES: [
        {
            name: "Payslips",
            key: 0,
            hasSubMenu: false
        },
        {
            name: "Declarations",
            key: 1,
            hasSubMenu: false
        },
        {
            name: "Allowances",
            key: 2,
            hasSubMenu: false
        },
        {
            name: "Monthly Attendance",
            key: 3,
            hasSubMenu: false
        },
        {
            name: "HRA",
            key: 4,
            hasSubMenu: false
        },
        {
            name: "Contact Information",
            key: 5,
            hasSubMenu: false
        },
        {
            name: "Credentials",
            key: 6,
            hasSubMenu: false
        },
        {
            name: "Bank Information",
            key: 7,
            hasSubMenu: false
        },
        {
            name: "Address",
            key: 8,
            hasSubMenu: false
        },
        {
            name: "Family Details",
            key: 9,
            hasSubMenu: false
        },
        {
            name: "Nominee",
            key: 10,
            hasSubMenu: false
        },
        {
            name: "Emergency Contact",
            key: 11,
            hasSubMenu: false
        },
        {
            name: "IP Address",
            key: 12,
            hasSubMenu: false
        },
        {
            name: "Regime Status",
            key: 13,
            hasSubMenu: false
        },
        {
            name: "Update Salary",
            key: 14,
            hasSubMenu: false
        },
        {
            name: "Date Of Joining",
            key: 15,
            hasSubMenu: false
        },
        {
            name: "Update Leaves",
            key: 16,
            hasSubMenu: false
        },
        {
            name: "Update Employee ID",
            key: 17,
            hasSubMenu: false
        },
        {
            name: "Advance Tax",
            key: 18,
            hasSubMenu: false
        },
        {
            name: "Total Working Days",
            key: 19,
            hasSubMenu: false
        },
        {
            name: "Overtime Days",
            key: 20,
            hasSubMenu: false
        },
        {
            name: "Form-C Bulk",
            key: 21,
            hasSubMenu: false
        },
    ],
    EMPLOYEE_DETAILS: {
        contactInfo: "Contact Info",
        credentials: "Credentials",
        bankInfo: "Bank Information",
        address: "Address",
        familyDetails: "Family Details",
        nominee: "Nominee",
        // educationAndQualification: "Educational Qualification",
        emergencyContact: "Emergency Contact",
        advanceTax: "Advance Tax",
    },
    UPLOAD_TABLE_COLUMN: [{
        title: "Employee ID",
        dataIndex: ["employeeId"],
        key: "Employee_ID",
        fixed: 'left',
        width: widthOne
    },
    {
        title: "Username",
        dataIndex: ["username"],
        key: "username",
        fixed: 'left',
        width: widthOne
    }, {
        title: "Name",
        dataIndex: ["displayName"],
        key: "Name",
        fixed: 'left',
        width: 200,
    }]
}

export { DATA_SYNC };