import { Col, Row, Table, Tabs } from 'antd';
import { HeaderComponent } from '../Common';
// import { getMoolCoinRequests, getMoolCoinsSubscription } from "../../apis/organisation";
import { useEffect, useState } from "react"
import { withRouter } from 'react-router';
import moment from 'moment';
import { FileViewer, NormalButton, Status } from '../ComponentLibrary';
import { connect } from 'react-redux';
import organisation from "../../redux/modules/organisation";
import { monthList } from "../../utils/config";


const { TabPane } = Tabs;

const OrganisationMoolCoins = (props) => {
    const [viewFile, setViewFile] = useState(false);
    const [viewFileDocumentProps, setViewFileDocumentProps] = useState({})
    const [viewFileDocumentVerifyProps, setViewFileDocumentVerifyProps] = useState({});
    const PAN = props.user.PAN;
    const [nextDueDate, setNextDueDate] = useState(0);

    useEffect(() => {
        props.organisationReducer.updateMoolCreditsHistoryList(PAN)
        props.organisationReducer.updateMoolCoinsSubscription(PAN)
        props.organisationReducer.updateMoolCreditsTransactionsList(PAN)
        setNextDueDate((moment().format("MM")) % 12)
    }, [PAN, props.organisationReducer])

    const handelViewDocument = (fileProps) => {
        setViewFile(true)
        let tempFileProps = {
            documentKey: fileProps.key,
            documentType: fileProps.key.split(".").pop(),
            documentName: fileProps.key.split("reimbursement-").pop(),
        }
        let tempViewFileDocumentVerifyProps = {
            showVerificationStatus: false,
        }
        setViewFileDocumentProps(tempFileProps);
        setViewFileDocumentVerifyProps(tempViewFileDocumentVerifyProps);
    }

    const TransactionsColumns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: "15%",
            render: createdAt => (
                moment(createdAt).format("ll")
            )
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            width: "40%",
            render: reason => (
                <p>{reason}</p>
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: "15%",
            render: type => (
                <p className={type === "credit" ? "success-text" : "danger-text"}>{type === "credit" ? "Credit" : "Debit"}</p>
            )
        },
        {
            title: 'amount',
            dataIndex: 'amount',
            key: 'amount',
            width: "15%",
            render: (amount, data) => (
                <p className={data.type === "credit" ? "success-text" : "danger-text"}>{amount} {data.currencyType}</p>
            )
        },
        {
            title: 'Balance',
            dataIndex: 'mooldhanAmount',
            key: 'mooldhanAmount',
            width: "15%",
            render: (mooldhanAmount, rowData) => (
                <p>{mooldhanAmount} {rowData.currencyType}</p>
            )
        },
    ]

    useEffect(() => {
        document.title = 'Organization | Mool Coins';
      });

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: "15%",
            render: date => (
                moment(date).format("ll")
            )
        },
        {
            title: 'Requested on',
            dataIndex: 'createdOn',
            key: 'createdOn',
            width: "15%",
            render: createdOn => (
                moment(createdOn).format("ll")
            )
        },
        {
            title: 'Attachment',
            dataIndex: 'proofs',
            key: 'proofs',
            width: "15%",
            render: proofs => (
                <NormalButton
                    type={5}
                    padding={[0, 0]}
                    buttonText={
                        proofs[0].name
                            ? proofs[0].name
                            : "attachement 1"
                    }
                    onClick={() => handelViewDocument(proofs[0])}
                    className="attachment-buttons"
                />
            )
        },
        {
            title: 'amount',
            dataIndex: 'amount',
            key: 'amount',
            width: "15%",
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: "15%",
            render: status => (
                <div>
                    {
                        status === 0
                            ? <Status
                                type={1}
                                text="Pending"
                                margin={[0, 8]}
                            />
                            : status === 1
                                ? <Status
                                    type={2}
                                    text="Accepted"
                                    margin={[0, 8]}
                                />
                                : <Status
                                    type={0}
                                    text="Rejected"
                                    margin={[0, 8]}
                                />
                    }
                </div>
            )
        },
    ]

    return (
        <>
            <FileViewer
                visible={viewFile}
                onclose={setViewFile}
                fileProps={viewFileDocumentProps}
                verifyProps={viewFileDocumentVerifyProps}
            />
            <div className="site-layout">
                <HeaderComponent name="Mool Credit" showMoolCoins={true} />
                <div className="app-main-content-container ">
                    <Row gutter={[30, 30]}>
                        <Col sm={24} md={18}  >
                            <div className="paper-wrapper">
                                <Tabs
                                    defaultActiveKey="1"
                                    style={{
                                        fontWeight: 600,
                                    }}
                                >
                                    <TabPane tab="Credit History" key="1">
                                        <Table
                                            pagination={{ pageSize: 7 }}
                                            columns={columns}
                                            dataSource={props.moolCreditsHistoryList.reverse()}
                                            scroll={{ x: 900 }}
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                            }}
                                        />
                                    </TabPane>
                                    <TabPane tab="Transactions" key="2">
                                        <Table
                                            pagination={{ pageSize: 10 }}
                                            columns={TransactionsColumns}
                                            dataSource={props.moolCreditsTransactionList.reverse()}
                                            scroll={{ x: 900 }}
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 600,
                                            }}
                                        />
                                    </TabPane>
                                </Tabs>
                            </div>
                        </Col>
                        <Col sm={24} md={6} >
                            <div className="paper-wrapper">
                                <div className="paper-header-container">
                                    <h3 className="paper-heading app-heading-2">Information</h3>
                                </div>
                                <div className="app-input-field-container-2">
                                    <label className="app-label-1">Credit Balance</label>
                                    <p className="app-label-value-1">{props.moolCoinsStore.currentBalance}</p>
                                </div>
                                <div className="app-input-field-container-2">
                                    <label className="app-label-1">Subscription type</label>
                                    <p className="app-label-value-1">{props.moolCoinsSubscription.plan.name ? props.moolCoinsSubscription.plan.name : "Large"}</p>
                                </div>
                                <div className="app-input-field-container-2">
                                    <label className="app-label-1">Amount</label>
                                    <p className="app-label-value-1">{props.moolCoinsSubscription.plan.name ? (props.moolCoinsSubscription.perUnitValue + " INR / user") : " 600 INR / user"}</p>
                                </div>
                                <div className="app-input-field-container-2">
                                    <label className="app-label-1">Next payment Date</label>
                                    <p className="app-label-value-1">1st of {monthList[nextDueDate]}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

const OrganisationMoolCoinsContainer = connect(
    state => ({
        user: state.auth.user,
        moolCoinsStore: state.organisation.moolCoins,
        moolCreditsHistoryList: state.organisation.moolCreditsHistoryList,
        moolCoinsSubscription: state.organisation.moolCoinsSubscription,
        moolCreditsTransactionList: state.organisation.moolCreditsTransactionList,
    }),
    (dispatch) => ({
        organisationReducer: organisation.getActions(dispatch),
    })
)(OrganisationMoolCoins)

export default withRouter(OrganisationMoolCoinsContainer);