

const SectionHeading = ({ sectionName, sectionInfo, sectionType, limit, notes }) => <div>
    <h3 className="section-full-data-heading">
        {sectionType}
    </h3>
    <h4 className="section-full-data-type">{sectionName}</h4>
    <p className="section-full-data-info">{sectionInfo}</p>
    {limit && (
        <p>
            <span className="app-label-value-1">Limit: </span>
            <span className="app-label-1">{limit}</span>
        </p>
    )}
    {notes && (
        <p className="section-full-data-info">
            <span style={{ color: "red" }}>*</span>
            {notes}
        </p>
    )}
</div>   
export default SectionHeading;
