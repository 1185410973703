import React, { useState, useEffect } from "react";
import { HeaderComponent } from "../Common";
import { Content } from "antd/lib/layout/layout";
import { Table, Button, message } from "antd";
import { NormalButton } from "../ComponentLibrary";
import { getOrgSettlements, deleteSettlement } from "../../apis/organisation";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { paths } from "../../constants/routes";
import TrialPage from "../Common/TrialComponent";
import separationsBgImage from "../../assets/dummyPages/separationsBgImage.png"

const Separation = ({ user, isTrial }) => {
  const [settlementData, setSettlementData]
    = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    employeeSettlement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  const employeeSettlement = async () => {
    let res = await getOrgSettlements(user.organisationId);
    let pendingSettlement = [];
    res.data.forEach((element) => {
      if (element.status === false) {
        pendingSettlement.push(element);
      }
    });
    setSettlementData(pendingSettlement);
  };
  const rejectFNFHandler = async (userID, userName, organisationID) => {
    let res = await deleteSettlement(`${userID}/${organisationID}`);
    if (res.data.success) {
      message.success(
        `Successfully rejected separation request for ${userName}`
      );
      setIsRefresh(!isRefresh);
    } else {
      message.error("Failed to delete separation request");
    }
  };
  const columns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
      render: (_name, rowData) => <p>{rowData.user.displayName}</p>,
    },
    {
      title: "Employee Id",
      dataIndex: "Id",
      key: "Id",
      render: (_Id, rowData) => (
        <p>{rowData.user.currentOrganisationMeta.employeeId}</p>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_date, rowData) => (
        <p>{moment(rowData.initiationDate).format("ll")}</p>
      ),
    },
    {
      dataIndex: "adId",
      key: "adId",
      width: "18%",
      render: (_adId, rowData) => (
        <>

          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginRight: "1rem",
              }}
            >
              <NormalButton
                type={9}
                buttonText="Reject"
                margin={[5, 0]}
                onClick={() => {
                  rejectFNFHandler(
                    rowData.user._id,
                    rowData.user.displayName,
                    user.organisationId
                  );
                }}
              />
            </div>
            <Link to={`${paths.org.settlement}/${rowData.user._id}`}>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <NormalButton
                  type={13}
                  minWidth={"10.5rem"}
                  buttonText="Make Settlement"
                  margin={[5, 0]}
                />
              </div>
            </Link>
          </div>
        </>
      ),
    },
  ];
  useEffect(() => {
    document.title = 'Payroll | Separations';
  });
  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Complete FnF on the day of release"} bgImage={separationsBgImage}
    /> :
    <div className="site-layout">
      <HeaderComponent show={false} showMoolCoins={true} name="Separations" />
      <Content className="app-main-content-container">
        <div className="paper-wrapper">
          <h1 className="font-size-global-24 font-weight-700" style={{ margin: "30px 0px" }}>List ({settlementData.length}) <span style={{ float: "right" }}>
            <Button
              onClick={startRefresh}
              className={`button-lib button-type-13 p-10`}
              style={{ height: "2.5rem" }}
            >
              <div className="flex justify-between align-center">
                Refresh
              </div>
            </Button>
          </span></h1>


          <Table
            style={{ fontWeight: 500 }}
            columns={columns}
            dataSource={settlementData.reverse()}
            pagination={settlementData.length >= 10 ? { pageSize: 10 } : false}
          />
        </div>
      </Content>
    </div>
  );
};

const SettlementData = connect((state) => ({
  user: state.auth.user,
  isTrial: state.auth.user.isTrial,

}))(Separation);

export default withRouter(SettlementData);
