import { useState, useEffect } from "react";
import { Row, Col, notification, message, Drawer } from "antd";
import { updateContactInfo } from "../../../../apis/user";
import { NormalButton } from "../../../ComponentLibrary";
import blueEditIcon from "../../../../assets/icons/blueEditIcon.png";
import config from "../../../../utils/config";
import InputFieldComponent from "../../../InputLibrary/InputFieldComponent/InputFieldComponent";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";

const ContactInfo = ({ user, contactInfo, updateInfo }) => {
  const [loading, setLoading] = useState(false);
  const [conInfo, setConInfo] = useState({
    personalPhone: "",
    linkedIn: "",
    skypeId: "",
    slackUser: "",
    personalEmail: "",
  });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    contactInfoUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInfo]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const contactInfoUpdate = () => {
    if (contactInfo) {
      setConInfo({
        personalPhone: contactInfo.personalPhone || "",
        linkedIn: contactInfo.linkedIn || "",
        skypeId: contactInfo.skypeId || "",
        slackUser: contactInfo.slackUser || "",
        personalEmail: contactInfo.personalEmail || "",
      });
    }
  };

  const submitForm = async () => {
    if (
      !config.validateEmail(conInfo.personalEmail) &&
      conInfo.personalEmail !== ""
    ) {
      message.error("Please enter correct email address");
      return;
    }
    setLoading(true);
    let res = await updateContactInfo({ ...conInfo, id: user._id });
    if (res.data.success) {
      await updateInfo();
      notification.success({
        message: "Contact Information Has Been Updated Successfully",
      });
    } else {
      contactInfoUpdate();
      notification.error({
        message: "Sorry unable to update the contact information",
      });
    }
    setLoading(false);
    onClose()
  };

  const updateField = (field, value) => {
    setConInfo({
      ...conInfo,
      [field]: value,
    });
  };

  const contact = (
    <>
      <div className="media-header">
        <h1 className="font-size-global-24 font-weight-global-700">Social Media Links</h1>
      </div>
      <Row gutter={[55, 0]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="company-address-individual-data-container modal-margin-top-add-global-2 ">
            <InputFieldComponent
              type={"number"}
              labeltext={"Personal Number"}
              value={conInfo.personalPhone}
              onChange={(e) => {
                updateField("personalPhone", e.target.value);
              }}
              name="personalPhone"
              block={true}
            ></InputFieldComponent>
          </div>
          <div className="company-address-individual-data-container ">
            <InputFieldComponent
              value={conInfo.linkedIn}
              labeltext={"LinkedIn Profile"}
              onChange={(e) => {
                updateField("linkedIn", e.target.value);
              }}
              name="linkedIn"
              block={true}
            ></InputFieldComponent>
          </div>
          <div className="company-address-individual-data-container">
            <InputFieldComponent
              value={conInfo.slackUser}
              labeltext={"Slack Profile"}
              onChange={(e) => {
                updateField("slackUser", e.target.value);
              }}
              name="slackUser"
              block={true}
            ></InputFieldComponent>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div className="company-address-individual-data-container modal-margin-top-add-global-2 ">
            <InputFieldComponent
              value={conInfo.personalEmail}
              labeltext={"Personal Email"}
              onChange={(e) => {
                updateField("personalEmail", e.target.value);
              }}
              name="personalEmail"
              block={true}
            ></InputFieldComponent>
          </div>
          <div className="company-address-individual-data-container">
            <InputFieldComponent
              value={conInfo.skypeId}
              labeltext={"Skype Profile"}
              onChange={(e) => {
                updateField("skypeId", e.target.value);
              }}
              name="skypeId"
              block={true}
            ></InputFieldComponent>
          </div>
        </Col>
      </Row>
    </>
  );

  return (
    <div
      className="company-address-container app-white-card"
      style={{ marginTop: "20px", height: "auto" }}
    >
      <div className="media-header">
        <h1 className="font-weight-global-700 font-size-global-24">Social Media Links</h1>
        <NormalButton
          type={12}
          icon={blueEditIcon}
          buttonText="Edit"
          onClick={showDrawer}
        ></NormalButton>
      </div>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Personal Number</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{conInfo.personalPhone}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">LinkedIn Profile</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{conInfo.linkedIn}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Slack Profile</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{conInfo.slackUser}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Personal Email</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{conInfo.personalEmail}</p>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <h3 className="fields-heading">Skype Profile</h3>
        </Col>
        <Col span={4}>:</Col>
        <Col span={10}>
          <p className="info">{conInfo.skypeId}</p>
        </Col>
      </Row>
      <Drawer
        title="Edit"
        placement="right"
        onClose={onClose}
        visible={visible}
        size={"large"}
      >
        <Spinner spinning={loading}>
          <div className="drawer">
            {contact}
            <div style={{marginLeft:"-0.5em"}} className="modal-margin-top-add-global-2">
            <NormalButton
                buttonText="save changes"
                type={13}
                onClick={submitForm}
              />
              <NormalButton buttonText="Cancel" type={5} onClick={onClose} />
            </div>
          </div>
        </Spinner>
      </Drawer>
    </div>
  );
};

export default ContactInfo;
