import { Col, Divider, Layout, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { changeRegisterState } from "../../apis/auth";
import * as chargebee from "../../apis/chargebee";
// import copyIcon from "../../assets/icons/copyIcon.svg";
import sidebar from "../../assets/sidebarlogo.svg";
import { pincodeData } from "../../constants/common_functions";
import { PHONENUMBER, states } from "../../utils/config";
import { NormalButton } from "../ComponentLibrary";
import DropdownComponent from "../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../InputLibrary/InputFieldComponent/InputFieldComponent";
import "./orderSummaryindex.less";
const { Content } = Layout;
function OrderSummary() {
  const [empCount, setEmpCount] = useState("1");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [empState, setEmpState] = useState("");
  const [empCity, setEmpCity] = useState("");
  const [empPincode, setEmpPincode] = useState("");
  const [billInterval] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [planPrice, setPlanPrice] = useState({});
  const [tax, setTax] = useState(1);
  const history = useHistory();
  const planDetails = async () => {
    let planDetailsResult = await chargebee.getPlanDetails(
      localStorage.getItem("organisationId")
    );
    setPlanPrice({ ...planDetailsResult.data.data.planData });
  };
  useEffect(() => {
    if (!localStorage.getItem("organisationId")) {
      history.push("/");
    }
  }, [history]);
  // TODO
  // const clickHandler = async () => {
  //   let billingdetails = await chargebee.addBillingInfo(
  //     localStorage.getItem("organisationId"),
  //     {
  //       line1: address1,
  //       line2: address2,
  //       city: empCity,
  //       state: empState,
  //       zip: empPincode
  //     },
  //     empCount,
  //     billInterval
  //   );
  //   let { customer } = billingdetails.data.data;
  //   let createOrderInRazorpay = await chargebee.createOrderInRazorpay(
  //     localStorage.getItem("organisationId"),
  //     customer
  //   );

  //   let { data: order } = createOrderInRazorpay.data;

  //   var options = {
  //     key: "",
  //     amount: order.amount.toString(),
  //     currency: "INR",
  //     name: "Mool Innovation Labs Pvt. Ltd",
  //     description: "Subscription Transaction",
  //     image: "https://example.com/your_logo",
  //     status: "authorized",
  //     order_id: order.id,
  //     callback_url: "http://localhost:3000/ordersuccess",
  //     prefill: {
  //       name: customer.company,
  //       email: customer.email,
  //       contact: customer.phone
  //     },
  //     handler: function(response) {
  //       const sendRespose = async (response, organisationID) => {
  //         await chargebee.sendpaymentResponse(response, organisationID);
  //       };
  //       sendRespose(response, localStorage.getItem("organisationId"));
  //     },
  //     notes: {
  //       address: "Razorpay Corporate Office"
  //     },
  //     theme: {
  //       color: "#3399cc"
  //     }
  //   };
  //   var rzp1 = new window.Razorpay(options);
  //   rzp1.on("payment.failed", function(response) {
  //     const sendRespose = async (response, organisationID) => {
  //       await chargebee.sendpaymentResponse(response, organisationID);
  //     };
  //     sendRespose(response, localStorage.getItem("organisationId"));
  //   });
  //   rzp1.open();

  //   // let createSubscriptionResult = await chargebee.createSubscription(
  //   //   localStorage.getItem("organisationId")
  //   // );

  // };

  const clickHandler = async () => {
    if (
      parseInt(empCount) <= 0 ||
      parseFloat(empCount) - parseInt(empCount) !== 0
    ) {
      message.error("Please enter valid employee count");
      return;
    }
    if (address1.length < 5) {
      message.warn("Please enter at lest five characters in address line 1");
      return;
    }
    if (address2.length < 5) {
      message.warn("Please enter at least five characters in address line 2");
      return;
    }
    if (!empState || !states[empState]) {
      message.error(
        "enter correct state name in the given format example Andhra Pradesh"
      );
      return;
    }
    if (!/^[1-9][0-9]{5}$/.test(empPincode)) {
      message.error("Enter Valid Pincode.");
      return;
    }
    if (parseInt(empCount) < 1) {
      message.error("Please enter valid employee count");
      return;
    }
    // if (
    //   parseInt(billInterval) <= 0 ||
    //   parseFloat(billInterval) - parseInt(billInterval) !== 0
    // ) {
    //   message.error("Please enter valid Billing Interval");
    //   return;
    // }
    if (couponCode === "BTS2022") {
      message.success("Coupon Code applied Successfully, please wait...");
      const registeredOrganisationId = localStorage.getItem("organisationId");
      await changeRegisterState({
        organisationId: registeredOrganisationId,
        registerState: 2,
      });
      history.push("/ordersuccess");
    } else {
      message.success("Please wait redirecting you to payment gateway");
      let billingdetails = await chargebee.addBillingInfo(
        localStorage.getItem("organisationId"),
        {
          line1: address1,
          line2: address2,
          city: empCity,
          state: empState,
          zip: empPincode,
        },
        empCount,
        billInterval
      );
      let { customer } = billingdetails.data.data;
      let createOrderInRazorpay = await chargebee.createOrderInRazorpay(
        localStorage.getItem("organisationId"),
        customer
      );

      let { data: order } = createOrderInRazorpay.data;
      const options = {
        key: process.env.REACT_APP_RAZORPAY_LIVE_SITE_KEY,
        amount: order.amount.toString(),
        currency: "INR",
        name: "Mool Innovation Labs Pvt. Ltd",
        description: "Subscription Transaction",
        image:
          "https://www.moolfinance.com/static/media/moolLogo.ac55750569415091ba6de4d22f36b053.svg",
        order_id: order.id,
        callback_url: "https://moolfinance.com/ordersuccess",
        prefill: {
          name: customer.company,
          email: customer.email,
          contact: customer.phone,
        },
        handler: async function (response) {
          const sendRespose = async (response, organisationID) => {
            await chargebee.sendpaymentResponse(response, organisationID);
          };
          const registeredOrganisationId = localStorage.getItem(
            "organisationId"
          );
          sendRespose(response, registeredOrganisationId);
          await changeRegisterState({
            organisationId: registeredOrganisationId,
            registerState: 2,
          });
          history.push("/ordersuccess");
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        const sendRespose = async (response, organisationID) => {
          await chargebee.sendpaymentResponse(response, organisationID);
        };
        sendRespose(response, localStorage.getItem("organisationId"));
      });
      rzp1.open();
      // TODO:
      // let createSubscriptionResult = await chargebee.createSubscription(
      //   localStorage.getItem("organisationId")
      // );

      message.success("Details added Successfully");
    }
  };
  useEffect(() => {
    planDetails();
  }, []);

  const changePlan = () => {
    history.push("/pricemodel");
  };
  const handleSignOut = () => {
    localStorage.setItem("mool_auth", "");
    localStorage.removeItem("mool_auth");
    localStorage.removeItem("organisationId");
    window.location = "/";
  };
  return (
    <Content>
      <div className="main" id="checkout">
        <div className="moolLogoDiv">
          <a href="https://www.moolfinance.com">
            <img
              src={sidebar}
              className="sidebar"
              alt=""
              style={{ position: "sticky", top: "2rem" }}
            />
          </a>
        </div>
        <div className="attachDiv">
          <div className="navbar">
            <div className="sign-outDiv">
              <p className="h6-bold-18 hover" onClick={handleSignOut}>
                Sign out
              </p>
            </div>
          </div>
          <div className="contentDiv">
            <p className="font-size-18">
              Step <b>2</b> of <b>2</b>
            </p>
            <h1 className="h1-bold-32">Order Summary</h1>
            <p className="font-size-18" style={{ color: "#5e5f62" }}>
              Your membership starts as soon as you setup the payment
            </p>
            <div className="formDiv">
              <Row gutter={[16, 16]}>
                <Col className="col1">
                  <h3 className="font-size-18 bold">
                    How much employee does your company have?
                  </h3>
                  <InputFieldComponent
                    labelColor={"white"}
                    labeltext={"Employee Count"}
                    width="25rem"
                    value={empCount}
                    onChange={(e) => {
                      if (parseInt(e.target.value) < 1) {
                        message.error("Enter valid employee count");
                      }
                      setEmpCount(e.target.value);
                    }}
                    margin={[18, 0, 0, 0]}
                  />
                  <h3
                    className="font-size-18 bold"
                    style={{ marginTop: "3.5rem" }}
                  >
                    Billing Information
                  </h3>
                  <InputFieldComponent
                    labeltext={"Address Line 1"}
                    width="29rem"
                    value={address1}
                    onChange={(e) => {
                      setAddress1(e.target.value);
                    }}
                    margin={[18, 0, 0, 0]}
                  />
                  <InputFieldComponent
                    labeltext={"Address Line 2"}
                    width="29rem"
                    value={address2}
                    onChange={(e) => {
                      setAddress2(e.target.value);
                    }}
                    margin={[0, 0, 0, 0]}
                  />
                  <Row>
                    <DropdownComponent
                      width="200px"
                      className="general-ant-field-select"
                      labeltext={"State"}
                      options={Object.keys(states)}
                      values={Object.keys(states)}
                      value={empState}
                      onChange={(e) => {
                        setEmpState(e);
                      }}
                      margin={[0, 10, 20, 0]}
                      bordered={false}
                      filterOption={(input, option) =>
                        option.children &&
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    />
                    <InputFieldComponent
                      labeltext={"City"}
                      width="120px"
                      margin={[0, 10, 0, 0]}
                      value={empCity}
                      onChange={(e) => {
                        setEmpCity(e.target.value);
                      }}
                    />
                    <InputFieldComponent
                      labeltext={"Pincode"}
                      width="120px"
                      margin={[0, 10, 0, 0]}
                      value={empPincode}
                      onChange={(e) => {
                        if (e.target.value.length > 6) {
                          return;
                        }
                        setEmpPincode(e.target.value);
                        if (parseInt(e.target.value) && e.target.value.length < 7) {
                          setEmpPincode(e.target.value);
                          if (e.target.value.length === 6) {
                            setEmpPincode(e.target.value);
                            const data = pincodeData(e.target.value);
                            Promise.resolve(data).then((res) => {
                              setEmpPincode(e.target.value);
                              setEmpCity(res.Block)
                              setEmpState(res.State)
                            });
                          }
                        }
                      }}
                    />
                  </Row>
                </Col>
                <Divider className="divider" type="vertical" style={{ marginLeft: "9vw", marginRight: "2vw" }} />
                <Col className="col2" style={{ padding: "0px 8px" }}>
                  {/* <InputFieldComponent
                    labeltext={"Billing Interval"}
                    width={"23.5rem"}
                    value={billInterval}
                    onChange={(e) => {
                      setBillInterval(e.target.value);
                    }}
                  /> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "-1rem",
                    }}
                  >
                    <p className="font-size-18 bold">{planPrice.name} Plan</p>
                    <p className="font-size-18 bold">
                      {/* {parseInt(empCount) * planPrice.price || planPrice.price}{" "} */}
                      {planPrice.price}
                      INR
                    </p>
                  </div>
                  <p className="font-size-14" style={{ color: "#808285" }}>
                    {planPrice.price} INR / employee / month
                  </p>
                  <p
                    className="font-size-14 hover"
                    style={{
                      color: "#405cd2",
                      fontWeight: "600",
                      margin: "10px 0px",
                    }}
                    onClick={changePlan}
                  >
                    Change Plan
                  </p>
                  <Divider style={{ marginTop: "1rem" }}></Divider>
                  <div
                    className="coupon"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "-1rem",
                    }}
                  >
                    <div className="coupon-input">
                      <InputFieldComponent
                        labeltext={"Coupon Code"}
                        width={"18rem"}
                        value={couponCode}
                        onChange={(e) => {
                          setCouponCode(e.target.value);
                          if (e.target.value !== "BTS2022") {
                            setTax(1);
                          }
                        }}
                      />
                    </div>
                    <div
                      className="coupon-apply"
                      style={{ marginTop: "0.34rem", padding: "0" }}
                    >
                      <NormalButton
                        type={13}
                        buttonText={"Apply"}
                        heightWidth={["3rem"]}
                        onClick={(e) => {
                          if (couponCode !== "BTS2022") {
                            message.error("Enter valid coupon code  ");
                            setTax(1);
                          } else {
                            setTax(0);
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: "#ebeefb",
                      padding: "1.125rem",
                      borderRadius: "10px",
                      marginTop: "-1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <p className="font-size-14 bold">BTS2022</p>
                      <p className="font-size-12">
                        Apply this code to get your free trial for 30 days!
                      </p>
                    </div>
                    <div style={{ marginTop: "0.35rem" }}>
                      <img
                        src={copyIcon}
                        alt=""
                        onClick={(e) => {
                          message.success("Coupon code copied");
                          setCouponCode("BTS2022");
                        }}
                      />
                    </div>
                  </div> */}
                  <Divider></Divider>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="font-size-16  b-500">Subtotal</p>
                    <p className="font-size-16 bold">
                      {tax &&
                        (
                          parseInt(Math.abs(empCount || 1)) *
                          planPrice.price *
                          planPrice.multiplier
                        ).toString() + ".00"}{" "}
                      INR
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "1rem 0",
                    }}
                  >
                    <p className="font-size-16  b-500">GST (18%)</p>
                    <p className="font-size-16 bold">
                      {tax &&
                        (
                          parseInt(Math.abs(empCount || 1)) *
                          0.18 *
                          planPrice?.price *
                          planPrice?.multiplier
                        ).toFixed(2)}{" "}
                      INR
                    </p>
                  </div>
                  <Divider></Divider>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <p className="font-size-16 b-500">Total</p>
                    <p className="font-size-16 bold">
                      {" "}
                      {tax &&
                        (
                          parseInt(Math.abs(empCount || 1)) *
                          0.09 *
                          planPrice?.price *
                          planPrice?.multiplier *
                          2 +
                          parseInt(Math.abs(empCount || 1)) *
                          planPrice.price *
                          planPrice.multiplier
                        ).toFixed(2)}{" "}
                      INR
                    </p>
                  </div>
                  <div style={{ marginLeft: "-8px" }}>
                    <NormalButton
                      type={13}
                      id="rzp-button1"
                      buttonText={"Make Payment"}
                      onClick={clickHandler}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="footer">
            <div style={{ padding: "10px 10px 5px 40px", width: "50vw" }}>
              <p className="font-size-16">
                <b>Questions? Call {PHONENUMBER}</b>
              </p>
              <p className="font-size-12">
                <a
                  href="https://www.moolfinance.com/termsAndConditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of service
                </a>{" "}
                |{" "}
                <a
                  href="https://www.moolfinance.com/privacyPolicy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                |
                <a
                  href="https://www.moolfinance.com/contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Contact Us
                </a>
              </p>
            </div>
            <div>
              <div
                style={{
                  width: "50vw",
                  textAlign: "right",
                  padding: "20px 10px 5px 40px",
                }}
              >
                <div className="font-size-16" style={{ fontWeight: "bold" }}>
                  <p>Mool Innovation Labs Pvt. Ltd.</p>
                  <p
                    className="font-size-12"
                    style={{ color: "grey", fontWeight: "500" }}
                  >
                    All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
}

export default withRouter(OrderSummary);
