import { Tabs } from 'antd';
import OtherAllowance from "./allowance/otherAllowance.js";
import Hra from "./allowance/hra.js";
import ManageAllowancesContainer from './allowance/ManageAllowances/ManageAllowances.js';

const { TabPane } = Tabs;

const listOfAllowanceTabs = [{
    key: "1",
    tabName: "HRA",
    component: Hra
},
// {
//     key: "2",
//     tabName: "Child Education",
//     component: ChildEducation
// },
// {
//     key: "3",
//     tabName: "Hostel Expenditure",
//     component: HostelExpenditure
// },
{
    key: "2",
    tabName: "Other Allowances",
    component: ManageAllowancesContainer
},
{
    key: "3",
    tabName: "Upload Proofs",
    component: OtherAllowance
}
]

const Allowance = () => {
    return (
        <div>
            <div className="blue-tabs-deduction-container">
                <Tabs tabPosition='left'>
                    {
                        listOfAllowanceTabs.map((data) => {
                            return (
                                <TabPane
                                    tab={
                                        <span className="left-side-tabs">
                                            {data.tabName}
                                        </span>
                                    }
                                    key={data.key}
                                >
                                    <data.component />
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        </div>
    )
}

export default Allowance;