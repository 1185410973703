import { DeleteOutlined } from "@ant-design/icons";
import {
    Col,
    message,
    notification,
    Radio,
    Row, Slider,
    Spin
} from 'antd';
import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import ReactGA from 'react-ga';
import {
    saveEmployeeDataAndGetGraphData
} from '../../apis/organisation';
import {
    deducationTabsLists,
    deductionData,
    newallowances,
    validateEmail
} from '../../utils/config';
import { HeaderComponent } from '../Common';
import { NormalButton } from '../ComponentLibrary';
import DropdownComponent from '../InputLibrary/DropdownComponent/DropdownComponent';
import InputFieldComponent from '../InputLibrary/InputFieldComponent/InputFieldComponent';
import InputNumberComponent from '../InputLibrary/InputNumberComponent/InputNumberComponent';
import './FreeCalculator.less';




const TRACKING_ID = 'G-PL4RY95LHG'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const tempChartOptions = {
    fill: {
        colors: ["#ef6922", "#ffb900", "#307be2"],
    },
    dataLabels: {
        enabled: false,
    },
    legend: {
        show: false,
    },
    states: {
        hover: {
            filter: {
                type: "darken",
                value: "0.85",
            },
        },
    },
    labels: ["Takehome", "Taxes", "Savings"],
    plotOptions: {
        pie: {
            donut: {
                labels: {
                    show: true,
                    label: "Takehome chart",
                },
            },
        },
    },
};

function FreeCalculator({
    gaEventTrackerActions,
    hamburgerMenuState,
    setHamburgerMenuState,
}) {
    const [allowances, setAllowances] = useState({});
    const [allowancesAmount, setAllowancesAmount] = useState(null);
    const [allowanceSelected, setAllowanceSelected] = useState('');
    const [calculatePage, setCalculatePage] = useState(false);
    const [graphData, setGraphData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [enabledPF, setEnabledPF] = useState(false);
    const [savings, setSavings] = useState({
        inhand: 0,
        taxes: 0,
    });
    const [oldSeries, setOldSeries] = useState([0, 0, 0]);
    const [newSeries, setNewSeries] = useState([0, 0, 0]);
    const [showSection, setShowSection] = useState(false);
    const [basicInfo, setBasicInfo] = useState({
        name: null,
        email: null,
        ctc: 0,
        hra: 0,
        rent: 0,
        isMetro: false,
        variable: 0,
        inhand: 0,
        tds: 0,
        pf: 0,
    });

    // eslint-disable-next-line no-unused-vars
    const dec = {
        '80C': 150000,
        '80D': 25000,
        '80E': 0,
        '80EE': 0,
        '80TTA': 0,
        '80G': 0,
        '80CCC': 0,
        '80CCD(2)': 0,
        '80CCD1(B)': 50000,
        '80CCG': 0,
        '80EEA': 0,
        '80TTB': 0,
        '80EEB': 0,
        '80DD': 0,
        '80U': 0,
        '80DDB': 0,
    };
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const reset = () => {
        setBasicInfo({
            ...{
                name: null,
                email: null,
                ctc: 0,
                hra: 0,
                rent: 0,
                isMetro: false,
                variable: 0,
                inhand: 0,
                tds: 0,
                pf: 0,
            },
        });
        setShowSection(false)
        setAllowances({});
    };

    const CalculateCTC = async () => {
        try {
            if (!basicInfo.name) {
                message.error("Please Enter Your Full Name");
                return;
            }
            if (!validateEmail(basicInfo.email)) {
                message.error("Please Enter A Valid Email");
                return;
            }
            if (!basicInfo.ctc) {
                message.error("Please Enter CTC Value");
                return;
            }
            if (!basicInfo.inhand) {
                message.error("Please Enter Inhand Value");
                return;
            }
            let declarations = deductionData;
            let chapter6A = [];
            let tempDeclaration = { ...dec };
            declarations.forEach((d) => {
                let newDecl = {
                    name: d.declaration,
                    amount: parseInt(tempDeclaration[d.section])
                        ? parseInt(tempDeclaration[d.section])
                        : 0,
                };
                chapter6A.push(newDecl);
                tempDeclaration[d.section] = 0;
            });

            let allowance = Object.keys(allowances).map((all) => {
                return {
                    name: all,
                    amount: allowances[all].amount ? parseInt(allowances[all].amount) : 0,
                    actualExpense: parseInt(allowances[all].amount)
                        ? parseInt(allowances[all].amount)
                        : 0,
                    meta: {},
                };
            });
            let hraRentPaid = 0;
            if (basicInfo.rent || basicInfo.hra) {
                let hraAmount = basicInfo.hra ? basicInfo.hra : 0;
                let rentAmount = basicInfo.rent ? basicInfo.rent : 0;
                hraRentPaid = rentAmount < hraAmount ? hraAmount : rentAmount;
            }
            let customAllowance = [];
            let houseRent = {
                status: true,
                isMetro: basicInfo.isMetro,
                rent: hraRentPaid < 7500 ? 7500 : hraRentPaid,
            };

            let pfEnabled =
                basicInfo.pf > 0 &&
                parseInt(basicInfo.pf) &&
                basicInfo.pf !== undefined &&
                basicInfo.pf !== null;
            let CTCValue = parseInt(basicInfo.ctc);
            setEnabledPF(pfEnabled);
            if (pfEnabled) {
                CTCValue += parseInt(basicInfo.pf) * 12;
            }
            let sendGraphData = pfEnabled;
            let dataCustomPoint = 0.66;
            let employeeData = {
                regimeOptedStatus: 0,
                previousData: {
                    pfPaid: 0,
                    monthRemaining: 12,
                    taxPaid: 0,
                    previousExemption: 0,
                    previousEmployeePFPaid: 0,
                    previousGrossIncome: 0,
                    totalMonth: 12,
                },
                customPoint: dataCustomPoint,
                pfContributionType: false,
                orgPFContribution: false,
                getGraphDataByKey: false,
                graphDataKey: 0,
                proffTaxEnabled: true,
                pfEnabled,
                sendGraphData,
                sendDebugInfo: false,
                annualCTC: CTCValue,
                stateAbbrev: "MH",
                feesOrCommissions: [],
                currentMonth: 4,
                lopDebits: 0,
                salaryAdjustDays: 0,
                ctcVariableComponent: {
                    status: true,
                    percentage: 0,
                    variableCompensationReceived: 0,
                    isOverAndAboveCTC: false,
                },
                gratuity: false,
                houseRent,
                allowances: allowance,
                employerContributionToNPS_80CCD_2: 0,
                bonuses: [],
                reimbursements: [],
                monthClose: [],
                customAllowances: customAllowance,
                perks: [],
                chapter6A,
            };
            setLoading(true);
            let res = await saveEmployeeDataAndGetGraphData(employeeData);

            let graph = res.data;
            setGraphData([...graph]);
            let initialData = {};
            let index = 0;
            for (let i = 1; i < graph.length; i++) {
                if (graph[index].oldRegime.takeHome < graph[i].oldRegime.takeHome) {
                    index = i;
                }
            }
            initialData = graph[index];
            setSelectedIndex(index);

            let taxPercentSave = basicInfo.tds ?
                ((basicInfo.tds - initialData.oldRegime.incomeTax) / basicInfo.tds) *
                100 : 0;
            let takeHomeIncrease =
                (initialData.oldRegime.takeHome - basicInfo.inhand) * 12;
            let takeHome = initialData.oldRegime.takeHome;
            setSavings({
                inhand: takeHomeIncrease,
                taxes: taxPercentSave,
            });
            setNewSeries([
                takeHome,
                initialData.oldRegime.incomeTax,
                initialData.savings,
            ]);
            let oldSavings = basicInfo.pf * 2;
            setOldSeries([basicInfo.inhand, basicInfo.tds, oldSavings]);

            setLoading(false);
            setCalculatePage(true);
        } catch (e) {
            setLoading(false);
            notification.error({ message: "Currently server is under maintainance" });
            reset();
        }
        gaEventTrackerActions("Running mool calculator", "calculator_actions");
    };

    const options = {
        fill: {
            colors: ["#ef6922", "#ffb900", "#307be2"],
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        states: {
            hover: {
                filter: {
                    type: "darken",
                    value: "0.85",
                },
            },
        },
        labels: ["Takehome", "Taxes", "Savings"],
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        label: "Takehome chart",
                    },
                },
            },
        },
    };
    const [pg, setPg] = useState(true);


    useEffect(() => {
        document.title = 'Mool Finance | Calculator'
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [calculatePage])

    return (
        <div>
            <HeaderComponent name="CTC Calculator" />
            <div style={{ backgroundColor: "white", paddingBottom: "2em" }}>
                <Spin spinning={loading}>
                    {!calculatePage && (
                        <>
                            <h1 style={{ fontSize: "24px", fontWeight: "700", marginLeft: "3em", paddingTop: "1em" }}>Input your basic salary slip details</h1>
                            <div className='calculatorDiv'>
                                <div style={{ marginLeft: "5.7%", marginBottom: "-1.5em" }} className="calculator-forms">
                                    <InputFieldComponent
                                        labeltext={"Full name *"}
                                        width={"300px"}
                                        value={basicInfo.name}
                                        onChange={(value) => {
                                            setBasicInfo({
                                                ...basicInfo,
                                                name: value.target.value,
                                            });
                                        }}
                                    />
                                    <div style={{ marginRight: "3%" }} >
                                        <InputFieldComponent
                                            labeltext={"Email *"}
                                            width={"300px"}
                                            value={basicInfo.email}
                                            onChange={(value) => {
                                                setBasicInfo({
                                                    ...basicInfo,
                                                    email: value.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="calculator-forms">
                                    <InputNumberComponent
                                        labeltext={"Gross Salary / Total Earning (per month) *"}
                                        value={basicInfo.ctc / 12}
                                        width={"300px"}
                                        onChange={(e) => {
                                            let value = Number.isNaN(e)
                                                ? 0
                                                : parseInt(e);
                                            setBasicInfo({
                                                ...basicInfo,
                                                ctc: value * 12,
                                            });
                                        }}
                                    />
                                    <InputNumberComponent
                                        labeltext={"Net Pay/ Inhand (per month) *"}
                                        value={basicInfo.inhand}
                                        type={"number"}
                                        width={"300px"}
                                        onChange={(e) => {
                                            let value = Number.isNaN(e)
                                                ? 0
                                                : parseInt(e);
                                            setBasicInfo({
                                                ...basicInfo,
                                                inhand: value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="calculator-forms">
                                    <InputNumberComponent
                                        labeltext={"House Rent Allowance (per month)*"}
                                        type={"number"}
                                        value={basicInfo.hra}
                                        width={"300px"}
                                        onChange={(e) => {
                                            let value = Number.isNaN(e)
                                                ? 0
                                                : parseInt(e);
                                            setBasicInfo({
                                                ...basicInfo,
                                                hra: value,
                                            });
                                        }}
                                    />
                                    <InputNumberComponent
                                        labeltext={"Total TDS Contribution (per month) *"}
                                        value={basicInfo.tds}
                                        width={"300px"}
                                        onChange={(e) => {
                                            let value = Number.isNaN(e)
                                                ? 0
                                                : parseInt(e);
                                            setBasicInfo({
                                                ...basicInfo,
                                                tds: value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="calculator-form">
                                    <InputNumberComponent
                                        labeltext={"Employee PF Contribution (per month) *"}
                                        value={basicInfo.pf}
                                        width={"300px"}
                                        onChange={(e) => {
                                            let value = Number.isNaN(e)
                                                ? 0
                                                : parseInt(e);
                                            setBasicInfo({
                                                ...basicInfo,
                                                pf: value,
                                            });

                                        }}
                                    />
                                    <div></div>
                                </div>
                                <div className='calculator-checkbox'>
                                    <div>
                                        <p className="checkBoxLabel">Do you live in a rented house / PG</p>
                                        <Radio.Group onChange={(e) => { setPg(e.target.value); }} value={pg}>
                                            <Radio value>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </div>
                                    {
                                        pg === true &&
                                        <div>
                                            <p className="checkBoxLabel">Are you currently staying in metro?</p>
                                            <p style={{ color: "#742EF8" }} >Note: Only Mumbai, Delhi, Chennai, Kolkata are considered as metro</p>
                                            <Radio.Group onChange={(e) => {
                                                setBasicInfo({
                                                    ...basicInfo,
                                                    isMetro: e.target.value,
                                                });
                                            }} value={basicInfo.isMetro}>
                                                <Radio value>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </div>
                                    }
                                </div>

                                {
                                    pg === true &&
                                    <div style={{ marginLeft: "5.7%" }} className='calculator-form'>
                                        <InputFieldComponent
                                            labeltext={"Actual rent paid (per month)"}
                                            type={"number"}
                                            value={basicInfo.rent}
                                            onChange={(e) => {
                                                setBasicInfo({
                                                    ...basicInfo,
                                                    rent: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                }
                                <h1 style={{ fontWeight: "600", marginTop: '20px', fontSize: "24px", marginLeft: "85px" }}>What allowances does your company give?</h1>

                                <div className='calculator-forms2 m-top-10'>
                                    <div>
                                        <DropdownComponent
                                            showSearch
                                            placeholder="Select an allowance"
                                            optionFilterProp="children"
                                            bordered={false}
                                            options={
                                                Object.keys(newallowances).map((item) => (
                                                    newallowances[item].label
                                                ))
                                            }
                                            values={Object.keys(newallowances).map((item) => (
                                                item
                                            ))}
                                            value={allowanceSelected}
                                            onChange={(e) => {
                                                setAllowanceSelected(e);
                                            }}
                                            className="select"
                                            labeltext={"Select Allowances"}
                                        />
                                    </div>
                                    <div>
                                        <InputFieldComponent
                                            labeltext={"Amount"}
                                            value={allowancesAmount}
                                            width={"300px"}
                                            onChange={(e) => {
                                                setAllowancesAmount(isNaN(Number(e.target.value)) ? 0 : Number(e.target.value));
                                            }}
                                        />
                                    </div>
                                    <div className='m-top-8'>
                                        <NormalButton buttonText={"Add"}
                                            onClick={() => {
                                                if (!allowanceSelected) {
                                                    notification.error({
                                                        message: 'Please select an allowance',
                                                    });
                                                    return;
                                                }
                                                if (allowancesAmount <= 0) {
                                                    notification.error({
                                                        message: 'Please enter Allowance amount',
                                                    });
                                                    return;
                                                }
                                                const newAllowanceObject = {
                                                    actualAmount: allowancesAmount,
                                                    amount: allowancesAmount,
                                                    label: newallowances[allowanceSelected].label,
                                                };

                                                const tempAllowance = allowances;
                                                tempAllowance[allowanceSelected] = newAllowanceObject;
                                                setAllowances({ ...tempAllowance });
                                                setAllowancesAmount(0);
                                                setAllowanceSelected("");
                                                gaEventTrackerActions(
                                                    `Adding allowances ${newallowances[allowanceSelected].label}`,
                                                    'calculator_actions'
                                                );
                                            }}
                                            type={13}
                                        />
                                    </div>
                                </div>
                                <div >
                                    <div className="flex justify-around align-center w-full m-bottom-10">
                                        <h1 className="h5-bold-20">Allowance Name</h1>
                                        <h1 className="h5-bold-20">Amount (INR)</h1>
                                        <h1 className="h5-bold-20">Action</h1>
                                    </div>
                                    <>
                                        {
                                            Object.keys(allowances).map(item => {
                                                return (
                                                    <>
                                                        <div key={item} className="flex justify-around align-center w-full m-bottom-10">
                                                            <h1>{allowances[item].label}</h1>
                                                            <h1>{allowances[item].amount}</h1>
                                                            <h1><DeleteOutlined onClick={() => {
                                                                const tempAllowance = {};
                                                                for (let key in allowances) {
                                                                    if (key !== item) {
                                                                        return allowances[key];
                                                                    }
                                                                }
                                                                setAllowances({ ...tempAllowance });
                                                            }} /></h1>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                </div>

                                <h1 style={{ fontWeight: "600", marginTop: '20px', fontSize: "24px", marginLeft: "85px" }}> Have you made some IT declaration this financial year?</h1>
                                <div className='calculator-forms2 m-top-10 m-bottom-10'>
                                    <div >
                                        <Radio.Group onChange={(e) => {
                                            setShowSection(e.target.value);
                                        }} value={showSection}>
                                            <Radio value>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                                {showSection &&
                                    deducationTabsLists.map((deduction) => (
                                        <>
                                            <div className="text-16 flex justify-between" style={{ margin: "0 6rem" }}>
                                                <p className="display-invisible">
                                                    {deduction.sectionType} ({deduction.Category}){' '}
                                                </p>
                                                <InputNumberComponent
                                                    placeholder="Amount"
                                                    controls={false}
                                                    labeltext="Amount"
                                                    min={0}
                                                    className="input__field"
                                                />
                                            </div>
                                        </>
                                    ))}


                                <div style={{ marginTop: "20px" }} className='calculator-button'>
                                    <NormalButton
                                        buttonText={"Check now"}
                                        type={13}
                                        onClick={() => {
                                            CalculateCTC();
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </Spin>
                {calculatePage && (
                    <div className="calculator-container" style={{ padding: "3rem" }}>
                        <Row gutter={[100, 35]} className="result-section">
                            <Col xs={24} sm={24} md={24} lg={24} className="">
                                <h2 className="mt-8">
                                    We crunched some numbers and ran our algorithm through your
                                    current pay structure and found something
                                </h2>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <p className="sub-header-1">Your Current Structure</p>
                                <div className="chart-container">
                                    <Chart
                                        options={options}
                                        series={oldSeries}
                                        type="donut"
                                        width="300"
                                        className="chart"
                                    />
                                    <div className="legend-container">
                                        <div className="flex-calculator">
                                            <div className="line takehome"></div>
                                            <div className="info">
                                                <span className="label-2">Takehome: </span>
                                                <span className="label-3">{basicInfo.inhand}</span>
                                            </div>
                                        </div>
                                        <div className="flex-calculator">
                                            <div className="line tax"></div>
                                            <div className="info">
                                                <span className="label-2">Tax: </span>
                                                <span className="label-3">{basicInfo.tds}</span>
                                            </div>
                                        </div>
                                        <div className="flex-calculator">
                                            <div
                                                className="line tax"
                                                style={{ backgroundColor: "#307be2" }}
                                            ></div>
                                            <div className="info">
                                                <span className="label-2">Savings: </span>
                                                <span className="label-3">{basicInfo.pf * 2}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <p className="sub-header-1">
                                    After using <img src="./assets/images/logo.png" alt="" />
                                </p>
                                <div className="chart-container">
                                    <Chart
                                        options={options}
                                        series={newSeries}
                                        type="donut"
                                        width="300"
                                    />
                                    <div className="legend-container">
                                        <div className="flex-calculator">
                                            <div className="line takehome"></div>
                                            <div className="info">
                                                <span className="label-2">Takehome: </span>
                                                <span className="label-3">{newSeries[0]}</span>
                                            </div>
                                        </div>
                                        <div className="flex-calculator">
                                            <div className="line tax"></div>
                                            <div className="info">
                                                <span className="label-2">Tax: </span>
                                                <span className="label-3">{newSeries[1]}</span>
                                            </div>
                                        </div>
                                        <div className="flex-calculator">
                                            <div
                                                className="line tax"
                                                style={{ backgroundColor: "#307be2" }}
                                            ></div>
                                            <div className="info">
                                                <span className="label-2">Savings: </span>
                                                <span className="label-3">{newSeries[2]}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <h2 className="sub-header-2">
                                    For your current CTC of{" "}
                                    <span style={{ color: "#405cd2" }}>{basicInfo.ctc}</span> INR
                                    your salary structure can be further optimized to get you:
                                </h2>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                {console.log(savings)}
                                <h2 className="tax-savings">
                                    {Math.round((savings.taxes + Number.EPSILON) * 100) / 100} %
                                </h2>
                                <span>Reduction in taxes per month</span>
                                {/* <p className="tag">‘X’ % more than your previous payslip </p> */}
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <h2 className="takehome">
                                    {Math.round((savings.inhand + Number.EPSILON) * 100) / 100}{" "}
                                    INR
                                </h2>
                                <span>
                                    {Math.round((savings.inhand + Number.EPSILON) * 100) / 100 > 0
                                        ? "More"
                                        : "Less"}{" "}
                                    in-hand amount / year
                                </span>
                                {/* <p className="tag">That’s like Rs ’x’ more per year</p> */}
                            </Col>
                            {enabledPF && (
                                <>
                                    <Col>
                                        <h2 className="header-tag" style={{ marginBottom: 0 }}>
                                            Our System just doesn’t stop at that, Interested? See
                                            how👇
                                        </h2>
                                    </Col>
                                    <Col>
                                        <Row gutter={[20, 20]}>
                                            <Col xs={24} sm={24} md={12} lg={12}>
                                                <div className="slider-value-container">
                                                    <img
                                                        src="./assets/images/slider-background.svg"
                                                        alt=""
                                                        style={{
                                                            position: "absolute",
                                                            width: "34rem",
                                                            top: 0,
                                                            right: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                        className="sm-display-none"
                                                    />
                                                    <h2 style={{ color: "white", marginBottom: "1rem" }}>
                                                        Modification Scale
                                                    </h2>
                                                    <div
                                                        className="flex-container justify-between align-center"
                                                        style={{
                                                            minWidth: "5rem",
                                                            maxWidth: "34rem",
                                                            color: "white",
                                                            zIndex: "5",
                                                            padding: "0 0.3rem",
                                                        }}
                                                    >
                                                        <p style={{ marginLeft: "0", width: 20, margin: 0 }}>Max Takehome</p>
                                                        <p style={{ zIndex: "5", marginLeft: "0", width: 20, margin: 0 }}>
                                                            Max Savings
                                                        </p>
                                                    </div>
                                                    <Slider
                                                        // tooltipVisible
                                                        trackStyle={{ backgroundColor: "#74DDC2" }}
                                                        max={1000}
                                                        disabled={!enabledPF}
                                                        min={0}
                                                        value={selectedIndex}
                                                        onChange={(val) => {
                                                            if (enabledPF) {
                                                                setSelectedIndex(val);
                                                            }
                                                        }}
                                                    />
                                                    <p className="slider-note">
                                                        NOTE: Move the scale to see how Mool is adding value
                                                        to your current payslip 🥳
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12}>
                                                <div className="chart-container" style={{ flex: 1 }}>
                                                    <Chart
                                                        options={tempChartOptions}
                                                        series={[
                                                            graphData[selectedIndex].oldRegime.takeHome,
                                                            graphData[selectedIndex].oldRegime.incomeTax,
                                                            graphData[selectedIndex].savings,
                                                        ]}
                                                        type="donut"
                                                        width="300"
                                                        className="chart"
                                                    />
                                                    <div className="legend-container">
                                                        <div className="flex-calculator">
                                                            <div className="line takehome"></div>
                                                            <div className="info">
                                                                <span className="label-2">Takehome</span>
                                                                <span className="label-3">
                                                                    {graphData[selectedIndex].oldRegime.takeHome}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex-calculator">
                                                            <div className="line tax"></div>
                                                            <div className="info">
                                                                <span className="label-2">Tax</span>
                                                                <span className="label-3">
                                                                    {graphData[selectedIndex].oldRegime.incomeTax}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex-calculator">
                                                            <div
                                                                className="line tax"
                                                                style={{ backgroundColor: "#307be2" }}
                                                            ></div>
                                                            <div className="info">
                                                                <span className="label-2">Savings</span>
                                                                <span className="label-3">
                                                                    {graphData[selectedIndex].savings}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <p className="note">
                                            NOTE: The taxes, savings and the take-home data that are
                                            shown are year wise.
                                        </p>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <h2>Benefits after modifying the data of your payslip </h2>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <h2 className="tax-savings">
                                            {(basicInfo.tds -
                                                graphData[selectedIndex].oldRegime.incomeTax) *
                                                12}{" "}
                                            INR
                                        </h2>
                                        <span>Amount saved on taxes per year</span>
                                        <p className="tag">
                                            <span>
                                                {Math.round(
                                                    (10000 *
                                                        (basicInfo.tds -
                                                            graphData[selectedIndex].oldRegime.incomeTax)) /
                                                    basicInfo.tds
                                                ) / 100}{" "}
                                            </span>{" "}
                                            <span>{" % "}</span> more than your previous payslip{" "}
                                        </p>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12}>
                                        <h2 className="takehome">
                                            {Math.abs(graphData[selectedIndex].oldRegime.takeHome -
                                                basicInfo.inhand)}{" "}
                                            INR{" "}
                                        </h2>
                                        <span>
                                            {graphData[selectedIndex].oldRegime.takeHome -
                                                basicInfo.inhand >
                                                0
                                                ? "More"
                                                : "Less"}{" "}
                                            amount in your takehome / month
                                        </span>
                                        <p className="tag">
                                            That’s like{" "}
                                            <span style={{ marginLeft: "0.2rem" }}>
                                                Rs{" "}
                                                {Math.abs(graphData[selectedIndex].oldRegime.takeHome -
                                                    basicInfo.inhand) *
                                                    12}
                                            </span>{" "}
                                            {(graphData[selectedIndex].oldRegime.takeHome -
                                                basicInfo.inhand) *
                                                12 >
                                                0
                                                ? "more"
                                                : "less"}{" "}
                                            per year
                                        </p>
                                    </Col>{" "}
                                </>
                            )}
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <div className="result-page-cta">
                                    <button
                                        className="btn"
                                        onClick={() => {
                                            setCalculatePage(!calculatePage);
                                            gaEventTrackerActions("Checking another payslip", "calculator_actions")
                                            // reset();
                                        }}
                                    >
                                        <a href="#top-most-label">Check another payslip</a>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>



        </div >
    )
}


export default FreeCalculator;