import tick from "../../assets/icons/Tick.svg";
import { connect } from "react-redux";
import employee from "../../redux/modules/employee";
import organisation from "../../redux/modules/organisation";
import React, { useState } from "react";
import { HashLink } from 'react-router-hash-link';
import { categoryMappring } from "../../constants/routes";
import rightArrow from "../../assets/employeeDashboard/right-arrow.svg";

const UploadSpace = ({ user, docs, width, onboarding, empOnboarding, authReducer }) => {
  const titlesArray = ['profilePicture', 'department', 'designation', 'groups', 'status', 'location', 'employeeAddition', 'profile', 'previousDataSync']
  const [hover, setHover] = useState(false);
  const [val, setVal] = useState('');
  const renderComponent = (val) => {
    authReducer.updateCurrentTab(val)
    authReducer.updateSelectedCategory(categoryMappring[user.role][val])
  }
  const OnboardingItem = ({ item, onboard, doc }) => <div 
    className="entry"  
    style={{ width: '100%', backgroundColor: hover && doc[item]["name"] === val ? '#f5f9ff' : '#fff' }}
  >
    <HashLink onClick={onboard["fields"][item] ? '' : (() => renderComponent(doc[item].subcategory)) } to={!onboard["fields"][item] && (doc[item]["path"])} style={{ cursor: onboard["fields"][item] ? 'default' : 'pointer' }} >
      <div 
      onMouseEnter={()=>{
        setHover(true);
        setVal(doc[item]["name"])
      }}
      onMouseLeave={()=>{
        setHover(false);
        setVal('')
      }}
      style={{ display: 'flex' , justifyContent: 'space-between', alignItems: 'center' ,overflow: 'hidden', width: '57vw'  }}>
        <div style={{ display: 'flex' }}>
          <div>
            {
              onboard["fields"][item] ? (
                <div className="bigger-container">
                  <div
                    className="img-container"
                    style={{ backgroundColor: "#10d3a0", height: '2.90rem' }}
                  >
                    <img src={tick} alt="" style={{ height: '50px' }} />
                  </div>
                </div>
              ) : (
                <div className="bigger-container" style={{ border: 'solid #9ea0a8', borderWidth: '2px', borderRadius: '100%', padding: '0' }}>
                  <div
                    className="img-container"
                    style={{ backgroundColor: hover ? '#f5f9ff' : "#fff" , color: '#9ea0a8' }}
                  >
                    {(doc[item]['num'])}
                  </div>
                </div>
              )
            }
          </div>
          <div>
            <h3 style={{ marginLeft: '.4rem', fontSize: '15px', fontWeight: '700', color: onboard["fields"][item]  ? '#9ea0a8' : '#000' }}>{doc[item]["name"]}</h3>
            <h4 style={{ marginLeft: '.4rem', fontSize: '13px', fontWeight: '400', color: onboard["fields"][item]  ? '#9ea0a8' : '#5e5f62' }}>{doc[item]["sub"]}</h4>
          </div>
        </div>
        
        
        <div>
          <div>
            {
              onboard["fields"][item] ? (
                <div>
                </div>
              ) : (
                <HashLink onClick={() => renderComponent(doc[item].subcategory)} to={doc[item]["path"]}>
                  <div
                    className="btn"
                    style={{
                      color: "#fea101",
                      cursor: "pointer",
                    }}
                  >
                    <img src={rightArrow} alt='right arrow' />
                  </div>
                </HashLink>
              )
            }
          </div>
        </div>
      </div>
    </HashLink>
  </div >
  return (user.role === "org" && onboarding) || (user.role === "employee" && empOnboarding) ? (
    <div className="right">
      <div className="top">
        <div className="title">
          <h3>Getting started</h3>
        </div>
      </div>

      {user.role === "org" && onboarding && (
        <div className="bottom" style={{ marginBottom: '-1rem' }}>
          {titlesArray.map((item) => <OnboardingItem doc={docs} item={item} onboard={onboarding} renderComponent={renderComponent} />)}
        </div>
      )}
    </div>
  ) : <></>;
};

const UploadSpaceContainer = connect(
  (state) => ({
    user: state.auth.user,
    empLoading: state.employee.loading,
    isLoggedIn: state.auth.isLoggedIn,
    loginstate: state.auth.loginstate,
    onboarding: state.organisation.onboarding,
    empOnboarding: state.employee.onboarding,
  }),
  (dispatch) => ({
    employeeReducer: employee.getActions(dispatch),
    organisationReducer: organisation.getActions(dispatch),
  })
)(UploadSpace);
export default UploadSpaceContainer;
