import { notification, Layout, Spin, Select } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteCurrentPayslip,
  updateDeclarations,
  updateEmployeeOnBoarding,
} from "../../../../../apis/employee";
import { NormalButton } from "../../../../ComponentLibrary";
import employee from "../../../../../redux/modules/employee";
import { checkFinancialYear } from "../../../../../utils/config";
import { createLog } from "../../../../../apis/user";
import InputNumberComponent from "../../../../InputLibrary/InputNumberComponent/InputNumberComponent";

const { Content } = Layout;
const { Option } = Select;

const ManageAllowances = ({
  allowances,
  customAllowances,
  user,
  declaration,
  structureOfMool,
  employeeReducer,
  organisationFreeze,
  ctc,
  onboarding,
  isPercentageStructure,
}) => {
  const [displayAllowances, setDisplayAllowances] = useState({});
  const [displayCustomAllowances, setDisplayCustomAllowances] = useState({});
  const [percentage, setPercentage] = useState({});
  const currentMonth = new Date().getMonth();
  const [financialYear, setFinancialYear] = useState(checkFinancialYear().financialYear);
  const [financialYearList, setFinancialYearList] = useState([]);

  useEffect(() => {
    employeeReducer.updateDeclarations({ id: user._id, financialYear })
  }, [employeeReducer, financialYear, user._id])

  useEffect(() => {
    function calculateFinancialYear() {
      let startFYear = 2020;
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let resFinancialYearList = [];
      for (let i = startFYear; i < currentYear + 1; i++) {
        let nextYear = i + 1;
        let tempFYear = `${i}-${nextYear}`;
        resFinancialYearList.push(tempFYear);
      }
      resFinancialYearList.reverse();
      setFinancialYearList([...resFinancialYearList]);
    }
    calculateFinancialYear();
  }, []);

  useEffect(() => {
    setDisplayAllowances({ ...allowances });
    setDisplayCustomAllowances({ ...customAllowances });
    let { declarations } = declaration;
    if (declarations) {
      if (isPercentageStructure) {
        setPercentage({ ...declarations.percentage })
      }
    }
  }, [allowances, isPercentageStructure, declaration, customAllowances]);
  const [loading, setLoading] = useState(false);

  const changeAllowancesState = (key, value, type) => {
    if (
      organisationFreeze.allowancesFreeze &&
      organisationFreeze.allowances[user._id]
    ) {
      notification.warn({
        message: `Organisation has freezed the allowance for the current financial year`,
      });
      return;
    }
    if (type === "allowance") {
      if (
        !allowances[key]["applicable"]
      ) {
        notification.error({ message: "Please Enter Valid Amount" });
        return;
      }
      let newAllowances = displayAllowances;
      let amount = newAllowances[key]["isCalculationLimit"] && value > newAllowances[key]["limit"] ? newAllowances[key]["limit"] : value;
      newAllowances[key]["amount"] = amount;
      setDisplayAllowances({ ...newAllowances });
    }
    if (type === "custom") {
      if (
        !customAllowances[key]["applicable"]
      ) {
        notification.error({ message: "Please Enter Valid Amount" });
        return;
      }
      let newAllowances = displayCustomAllowances;
      let amount = newAllowances[key]["isCalculationLimit"] && value > newAllowances[key]["limit"] ? newAllowances[key]["limit"] : value;
      newAllowances[key]["amount"] = amount;
      setDisplayCustomAllowances({ ...newAllowances });
    }
  };

  const submitAllowances = async () => {
    if (
      organisationFreeze.allowancesFreeze &&
      organisationFreeze.allowances[user._id]
    ) {
      notification.warn({
        message: `Organisation has freezed the allowance for the current financial year`,
      });
      return;
    }
    setLoading(true);
    if (onboarding && onboarding.allowances) {
      await updateEmployeeOnBoarding({
        field: "allowances",
        organisationId: user.organisationId,
        id: user._id,
      });
      employeeReducer.updateEmployeeOnBoarding({
        organisationId: user.organisationId,
        id: user._id,
      });
    }
    let dcl = declaration;
    const { financialYear, month } = checkFinancialYear();

    dcl.declarations.allowances = { ...displayAllowances };
    dcl.declarations.customAllowances = { ...displayCustomAllowances };
    await updateDeclarations({
      id: user._id,
      declarations: dcl.declarations,
      month
    });

    await createLog({
      name: user.displayName,
      userId: user._id,
      organisationId: user.organisationId,
      message: `User has updated the Allowances`,
      category: 4,
    });

    await deleteCurrentPayslip(user._id);
    await employeeReducer.askMool({
      financialYear,
      month,
      id: user._id,
      user,
      ctc
    });
    notification.success({ message: "Successfully updated employee allowances." })
    setLoading(false);
  };

  return (
    <div className="manage-allowances-container">
      <Spin spinning={loading} size="large">
        <Content className="stages">
          <div className="main-container">
            <div className="header-display">
              <div>
                <h2>Allowances</h2>
                <p className="header-title">
                  Provide some information of your spend so that our system
                  generates a payslip that's best suited for you
                </p>
              </div>
              <div
                className="flex align-center justify-end"
                style={{ marginLeft: "2rem" }}
              >
                <h3 className="font-weight-700 text-size-21 mb-2 ml-5">Financial Year</h3>
                <Select
                  defaultValue={financialYear}
                  className="general-ant-field-select ml-2"
                  style={{
                    width: "8rem",
                    marginLeft: "1rem",
                    background: "none",
                    padding: 0
                  }}
                  size="large"
                  onChange={e => {
                    setFinancialYear(e);
                  }}
                  bordered={false}
                >
                  {financialYearList.map((val, index) =>
                    <Option key={`opt-${index}`} value={val}>
                      <h3>{val}</h3>
                    </Option>
                  )}
                </Select>
              </div>
            </div>
            <div className="allowances-container" >
              {isPercentageStructure ? (
                <>
                  {percentage.allowances && percentage.allowances[currentMonth] &&
                    Object.keys(percentage.allowances[currentMonth]).map((val, _index) => {
                      if (!allowances[val] || !allowances[val].label) return "";
                      return (
                        <div className="single-allowance" key={val}>
                          <p>{percentage.allowances[currentMonth][val].displayName ? percentage.allowances[currentMonth][val].displayName : percentage.allowances[currentMonth][val].label}</p>
                          <InputNumberComponent
                            labeltext={"Amount"}
                            size="medium"
                            min={0}
                            // onChange={value => {
                            //   if (!percentage.allowances[currentMonth][val].isPercent) {
                            //     // Add the actual Expencense 
                            //     // Check the limit and replace it with it
                            //     updatePercentageStructure(val, value, 0)
                            //   }
                            // }}
                            value={percentage.allowances[currentMonth][val].isPercent ? Math.floor(percentage.allowances[currentMonth][val].value * parseInt(ctc.costToCompany[currentMonth].ctc) / 12) : percentage.allowances[currentMonth][val].value
                            }
                            disabled={percentage.allowances[currentMonth][val].isPercent}
                          />
                        </div>
                      )
                    })}
                  {percentage.customAllowances &&
                    Object.keys(percentage.customAllowances).map((val, _index) => (
                      <div className="single-allowance" key={val}>
                        <p>{customAllowances[val].displayName ? customAllowances[val].displayName : customAllowances[val].label}</p>
                        <InputNumberComponent
                          labeltext={"Amount"}
                          size="medium"
                          min={0}
                          // onChange={value => {
                          //   if (!percentage.customAllowances[val].isPercent) {
                          //     // Add the actual Expencense 
                          //     // Check the limit and replace it with it
                          //     updatePercentageStructure(val, value, 1)
                          //   }
                          // }}
                          value={percentage.customAllowances[val].isPercent ? Math.floor(
                            (parseInt(ctc.costToCompany[currentMonth].ctc) * percentage.customAllowances[val].value) / 12) : percentage.customAllowances[val].value
                          }
                          disabled={percentage.customAllowances[val].isPercent}
                        />
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {Object.keys(displayAllowances).map((val, _index) => (
                    <div className="single-allowance" key={val}>
                      <p>{displayAllowances[val].displayName ? displayAllowances[val].displayName : displayAllowances[val].label}</p>
                      <InputNumberComponent
                        labeltext={"Amount"}
                        size="medium"
                        min={0}
                        disabled={
                          organisationFreeze.allowancesFreeze &&
                          organisationFreeze.allowances[user._id]
                        }
                        max={
                          allowances[val]["isCalculationLimit"] && allowances[val]["limit"]
                        }
                        value={displayAllowances[val]["amount"]}
                        onChange={(value) =>
                          changeAllowancesState(val, value, "allowance")
                        }
                      />
                    </div>
                  ))}
                  {Object.keys(displayCustomAllowances).map((val, _index) => (
                    <div className="single-allowance" key={val}>
                      <p>{displayCustomAllowances[val].displayName ? displayCustomAllowances[val].displayName : displayCustomAllowances[val].label}</p>
                      <InputNumberComponent
                        labeltext={"Amount"}
                        size="medium"
                        min={0}
                        disabled={
                          organisationFreeze.allowancesFreeze &&
                          organisationFreeze.allowances[user._id]
                        }
                        max={displayCustomAllowances[val]["isCalculationLimit"] && displayCustomAllowances[val]["limit"]}
                        value={displayCustomAllowances[val]["amount"]}
                        onChange={(value) =>
                          changeAllowancesState(val, value, "custom")
                        }
                      />

                      {/* <InputFieldComponent
                        labeltext={"Monthly Rent in INR"}
                        className="general-ant-field-input"
                        type={"text"}
                        disabled={
                          organisationFreeze.allowancesFreeze &&
                          organisationFreeze.allowances[user._id]
                        }
                        min={0}
                        style={{ width: "100%" }}
                        value={displayCustomAllowances[val]["amount"]}
                        onChange={(value) =>
                          changeAllowancesState(val, value, "custom")
                        }
                      /> */}
                    </div>
                  ))}
                </>)}
            </div>
          </div>
          <div className="submit-container" style={{ marginRight: 15 }}>
            <NormalButton
              onClick={submitAllowances}
              type={13}
              buttonText="Update"
            />
          </div>
        </Content>
      </Spin>
    </div>
  );
};

const ManageAllowancesContainer = connect(
  (state) => ({
    allowances: state.employee.allowances,
    customAllowances: state.employee.customAllowances,
    user: state.auth.user,
    declaration: state.employee.declaration,
    structureOfMool: state.employee.structureOfMool,
    organisationFreeze: state.employee.organisationFreeze,
    onboarding: state.employee.onboarding,
    isPercentageStructure: state.employee.isPercentageStructure,
    ctc: state.employee.ctc,
    percentage: state.employee.percentage,
  }),
  (dispatch) => ({
    employeeReducer: employee.getActions(dispatch),
  })
)(ManageAllowances);

export default ManageAllowancesContainer;
