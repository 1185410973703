import React, { useState } from 'react'
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './PasswordComponent.less'

function PasswordComponent({ required, maxWidth, setPassworderror, passworderror, disabled, height, width,
  labeltext, display, value, margin, onChange }) {
  const changeErrorstate = (e) => {
    setPassworderror(0);
    if (passwordprevvisible !== "") {
      e.target.type = passwordprevvisible;
    }
  }
  const [passwordprevvisible, setPasswordPrevVisible] = useState("");
  const handlepasswordvisible = (e) => {
    if (passworderror === -1) setPassworderror(0);
    setPasswordPrevVisible(e.target.type);
    e.target.type = "password";
  }
  let styles = {
    borderWidth: passworderror && passworderror !== 3 ? '2px' : `${value ? '2px' : '1px'}`,
    borderColor: passworderror && passworderror !== 3 ? `#F05050` : `${value ? '#5E5F62' : '#d9d9d9'}`,
    border: value ? '2px #5E5F62 solid' : '1px #d9d9d9 solid',
    width: width ? `${width}` : "288px",
    maxWidth: maxWidth ? `${maxWidth}` : "100%",
    height: height ? `${height}` : "50px",
    borderRadius: '8px',
    background: 'transparent'
  }
  let stylediv = {
    position: 'relative',
    display: display ? display : 'block',
    margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : "10px 0px",
  }
  return (
    <div className='passworddiv' style={stylediv}>
      <Input.Password
        required={required}
        className={passworderror ? 'error' : ''}
        onBlur={handlepasswordvisible}
        onPaste={e => e.preventDefault()}
        onFocus={changeErrorstate}
        disabled={disabled}
        style={styles}
        value={value}
        onChange={onChange}
        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
      <label className={`${value === "" ? 'labelnormal' : 'labelup'} ${passworderror &&
        'error'}`}>{labeltext ? labeltext : 'Your Label'}</label>
      <p className={passworderror ? 'activeerrormessage' : 'errormessage'}>
        {value ? `All requirements must be met.`
          : 'This is a required field'}</p>
    </div>
  )
}

export default PasswordComponent