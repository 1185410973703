/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, DatePicker, Table } from "antd";
import { HeaderComponent } from "../../Common";
import { Content } from "antd/lib/layout/layout";
import { Link, withRouter } from "react-router-dom";
import axios from "../../../apis";
import { useEffect, useState } from "react";
import moment from "moment";
import yellowDownloadIcon from "../../../assets/common/yellowDownloadIcon.svg"
import { getFinancialYear, monthList, rootUrl } from '../../../utils/config'
import { getOrgReportInJsonByReportIdAndDate } from "../../../apis/organisation.js"
import { connect } from "react-redux";

const CustomReportTable = ({ history, user, childOrganisations }) => {

    let reportState = history.location.state.data;

    const [reportDate, setReportDate] = useState(moment());
    const [month, setMonth] = useState(Number(moment().format("M")) - 1);
    const [financialYear, setFinancialYear] = useState({});
    const [reportData, setReportData] = useState([])
    const [reportColumns, setReportColumns] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    // const [isRefresh, setIsRefresh] = useState(false);

    useEffect(() => {
        document.title = 'Reports | Custom Report Table';
      });
    useEffect(() => {
        handelDateChange(moment())
        let columns = []
        reportState.fields.forEach((reportField) => {
            let colObj = {
                title: reportField.title,
                dataIndex: reportField.key,
            }
            columns.push(colObj)
        })
        setReportColumns(columns)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportState.fields])

    // const startRefresh = ()=>{
    //     setIsRefresh(!isRefresh)
    // }
    const handelDateChange = (date) => {
        setReportDate(date)
        let { month, financialYear } = getFinancialYear(date)
        setMonth(month)
        setFinancialYear(financialYear)
        getReportData(month, financialYear)
    }

    const getReportData = async (month, financialYear) => {
        setIsLoading(true)
        let res = await getOrgReportInJsonByReportIdAndDate(user.organisationId, reportState.reportId, month, financialYear)
        if(res.data.data===null)
        {
            setIsLoading(false)
            setReportData([])
        }
        if (res.status === 200 && typeof res.data.data === "object") {
            let reportDataArray = []
            Object.keys(res.data.data).forEach((item) => {
                reportDataArray.push(res.data.data[item])
            })
            setReportData(reportDataArray)
        }
        else {
            setReportData([])
        }
        setIsLoading(false)
    }
   

    const handleAction = async (url) => {
        let res = await axios({
            url, //your url
            method: 'GET',
            responseType: 'blob',
        })
        const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = urlDownload;
        link.setAttribute('download', `${reportState.name}-${monthList[month]}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
    return (
        <div>
            <HeaderComponent />
            <div className="app-breadcrum-container">
                <Breadcrumb className="app-breadcrum">
                    <Breadcrumb.Item>
                        <Link to={`/org/reports/custom`}>Reports</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="app-breadcrum-active">
                        {reportState.name}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Content className="app-main-content-container app-white-card">
                <div className="app-flex-container-1">
                    <h1 className="app-heading-1">{reportState.name}</h1>
                    <div className="flex align-center">
                        <span style={{paddingBottom:"1.5em"}} className="app-label-1">Financial year</span>
                        <DatePicker
                            format={"YYYY - MM"}
                            className="app-date-picker month-picker"
                            onChange={handelDateChange}
                            picker={"month"}
                            value={reportDate}
                        />
                        <div className="m-left-16">
                            <a
                                className={` anchor-lib anchor-type-9 flex align-center`}
                                onClick={() => handleAction(`${rootUrl}/api/payslip/dynamic/${user.organisationId}/${reportState.reportId}/${month}/${financialYear}`)}
                            >
                                <img alt="" src={yellowDownloadIcon} className="button-lib-icon" style={{ width: "20px", height: "20px" }}></img>
                                Download csv
                            </a>
                        </div>
                        <span style={{marginLeft:"1em"}}>
                            {/* <Button
                                onClick={startRefresh}
                                className={`button-lib button-type-13 p-10`}
                                style={{ height: "2.5rem" }}
                            >
                                <div className="flex justify-between align-center">                      
                                    Refresh                      
                                </div>
                            </Button>  */}
                        </span>
                    </div>
                </div>
                <Table
                    loading={isLoading}
                    scroll={{ x: 600 }}
                    columns={reportColumns}
                    dataSource={reportData}
                />
            </Content>
        </div>
    )
}

const CustomReportTableContainer = connect(
    (state) => ({
        user: state.auth.user,
        designation: state.organisation.designation,
        childOrganisations: state.organisation.childOrganisations
    }),
    (dispatch) => ({})
)(CustomReportTable);

export default withRouter(CustomReportTableContainer)