import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Divider, Drawer, Dropdown, Menu, notification, Radio, Row, Space, Table
} from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMotorCar, postMotorCar } from "../../../apis/perqs";
import actionIcon from "../../../assets/icons/action.svg";
import organisation from "../../../redux/modules/organisation";
import { getFullToolTip } from "../../../utils/common";
import { HeaderComponent } from "../../Common";
import { NormalButton } from "../../ComponentLibrary";
import DropdownComponent from "../../InputLibrary/DropdownComponent/DropdownComponent";
import InputFieldComponent from "../../InputLibrary/InputFieldComponent/InputFieldComponent";
import InputNumberComponent from "../../InputLibrary/InputNumberComponent/InputNumberComponent";
import TrialPage from "../../Common/TrialComponent";
import perqsBgImage from "../../../assets/dummyPages/perqsBgImage.png"

const Motor_Car = ({ employeeList, user, month, financialYear, isTrial }) => {
  const [open, setOpen] = useState(false);
  const [editClicked, setEditClicked] = useState(true);
  const [dataSource, setDataSource] = useState([]);

  const [motorCarData, setMotorCarData] = useState({
    isCarOwnedOrHiredByEmployer: true,
    isChauffeurProvidedByEmployer: false,
    isFullRecovery: false,
    isLessThanEqualsTo1600CC: true,
    isMaintenanceReimbursedByEmployer: true,
    isUsedForOfficialPurpose: 0,
    isVehicleMotorCar: true,
    vehicleName: "",
    registrationNo: "",
    recoveryAmount: 0,
    recoveryAmountOfChauffeur: 0,
    bifurcatedAmount: 0,
    bifurcatedAmountForChauffeur: 0,
    isFullRecoveryForChauffeur: false,
    isRecovery: true,
    isRecoveryForChauffeur: true,
    id: ""
  });

  useEffect(() => {
    getMotorCar(user.organisationId).then((data) =>
      setDataSource(data.data.data)
    );
  }, [user.organisationId]);
  const getActionMenu = (data) => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            //edit data
            setMotorCarData({ ...data, isRecovery: data && data["perquisites"] && data["perquisites"][month] ? data["perquisites"][month]["isRecovery"] : true, isRecoveryForChauffeur: data && data["perquisites"] && data["perquisites"][month] ? data["perquisites"][month]["isRecoveryForChauffeur"] : true });
            setEditClicked(true)
            openDrawer();
          }}
        >
          Edit
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "Employee Id",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
    },
    {
      title: "Name",
      dataIndex: ["user", "displayName"],
    },
    {
      title: "Category",
      dataIndex: "isCarOwnedOrHiredByEmployer",
      render: (data) =>
        data ? <p>Owned by Organisation</p> : <p>Owned by Employee</p>,
    },
    {
      title: "Vehicle Type",
      dataIndex: "isVehicleMotorCar",
      render: (data) => (data ? <p>Car</p> : <p>Other</p>),
    },
    {
      title: "Vehicle Name",
      dataIndex: ["vehicleName"],
    },
    {
      title: "Registration No.",
      dataIndex: "registrationNo",
    },
    {
      title: "Engine Capacity",
      dataIndex: "isLessThanEqualsTo1600CC",
      render: (data) =>
        data ? <p>Less than 1600 cc</p> : <p>More than 1600 cc</p>,
    },
    {
      title: "Assigned to",
      dataIndex: ["user", "username"],
    },
    {
      title: "Used For",
      dataIndex: "isUsedForOfficialPurpose",
      render: (data) => (data === 0 ? <p>Official Purpose</p> : (data === 1 ? <p>Personal Purpose</p> : <p>Official/personal</p>))

    },
    {
      title: "",
      render: (data) => {
        const actionMenu = getActionMenu(data);
        return (
          <Space
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown
              trigger={["click"]}
              overlay={actionMenu}
              placement="bottomLeft"
            >
              <div onClick={(e) => e.stopPropagation()}>
                <img src={actionIcon} alt="actionIcon" />
              </div>
            </Dropdown>
          </Space>
        );
      },
    },
  ];
  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const reset = () => {
    //resetting state
    setMotorCarData({
      isCarOwnedOrHiredByEmployer: true,
      isChauffeurProvidedByEmployer: false,
      isFullRecovery: false,
      isLessThanEqualsTo1600CC: true,
      isMaintenanceReimbursedByEmployer: true,
      isUsedForOfficialPurpose: 0,
      isVehicleMotorCar: true,
      vehicleName: "",
      registrationNo: "",
      recoveryAmount: 0,
      recoveryAmountOfChauffeur: 0,
      bifurcatedAmount: 0,
      bifurcatedAmountForChauffeur: 0,
      isFullRecoveryForChauffeur: false,
      isRecovery: false,
      isRecoveryForChauffeur: false,
      id: ""
    });
  }

  const onSave = () => {
    postMotorCar({ ...motorCarData, financialYear, month })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: "Motor Car Saved Successfully!",
          });
        } else {
          notification.error({
            message: "Error while saving the motor car perquisites!",
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Error while saving the motor car perquisites!",
        });
      });

    reset()
    closeDrawer();
  };
  const addNewAccomodation = (
    <div className="section">
      <div className="section-top">
        <h3 className="font-size-16" style={{ fontWeight: "bold" }}>
          What type of category does the vehicle fall into
        </h3>
        <Radio.Group
          className="section-top"
          style={{ display: "flex" }}
          value={motorCarData.isCarOwnedOrHiredByEmployer}
          name="ownedby"
          onChange={(radioProps) => {
            setMotorCarData({
              ...motorCarData,
              isCarOwnedOrHiredByEmployer: radioProps.target.value
            })
          }}
        >
          <Radio value={true}>Owned/Hired by organisation</Radio>
          <Radio value={false}>Owned by employee</Radio>
        </Radio.Group>
        {!motorCarData.isCarOwnedOrHiredByEmployer && (
          <>
            <h3
              className="font-size-16"
              style={{ fontWeight: "bold", marginTop: "1rem" }}
            >
              What type of Vehicle is it?
            </h3>
            <Radio.Group
              className="section-top"
              style={{ display: "flex" }}
              value={motorCarData.isVehicleMotorCar}
              name="typeOfVehicle"
              onChange={(radioProps) => setMotorCarData({
                ...motorCarData,
                isVehicleMotorCar: radioProps.target.value
              })}
            >
              <Radio value={true}>Car</Radio>
              <Radio value={false}>Other</Radio>
            </Radio.Group>
          </>
        )}
        {
          !editClicked ? <>
            <DropdownComponent
              className="general-ant-field-select"
              labeltext={"Employee Name"}
              options={employeeList.map((item) => item.displayName)}
              values={employeeList.map((item) => item._id)}
              margin={[18, 0, 18, 0]}
              value={motorCarData.id}
              onChange={(e) => {
                setMotorCarData({ ...motorCarData, id: e });
              }}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            />
            <div
              className="flex justify-between align-center"
              style={{
                backgroundColor: "#f8fbff",
                color: "#405cd2",
              }}
            >
              <InfoCircleOutlined style={{ fontSize: "1.5rem", marginRight: "1rem" }} />
              <p>
                Note: input the name of the employee if the individual is adding
                their vehicle related to official use.
              </p>
            </div>
          </> : <InputFieldComponent
            labeltext={"Employee Name"}
            width={"500px"}
            disabled={true}
            margin={[18, 0, 5, 0]}
            labelColor="white"
            value={motorCarData && motorCarData.user && motorCarData.user.displayName ? motorCarData.user.displayName : ""}
          />
        }

        <InputFieldComponent
          labeltext={"Registration No."}
          width={"500px"}
          margin={[18, 0, 5, 0]}
          disabled={editClicked}
          value={motorCarData.registrationNo}
          onChange={(e) =>
            setMotorCarData({ ...motorCarData, registrationNo: e.target.value })
          }
        />
        <InputFieldComponent
          labeltext={"Vehicle Name"}
          width={"500px"}
          margin={[5, 0, 5, 0]}
          labelColor="white"
          disabled={editClicked}
          value={motorCarData.vehicleName}
          onChange={(e) =>
            setMotorCarData({ ...motorCarData, vehicleName: e.target.value })
          }
        />
        {!motorCarData.isCarOwnedOrHiredByEmployer &&
          !motorCarData.isVehicleMotorCar ? (
          <></>
        ) : (
          <>
            <h3 className="font-size-16" style={{ fontWeight: "bold" }}>
              What is the engine capacity of the vehicle
            </h3>
            <Radio.Group
              className="section-top"
              style={{ display: "flex" }}
              value={motorCarData.isLessThanEqualsTo1600CC}
              name="engineCapcity"
              onChange={(radioProps) => {
                setMotorCarData({
                  ...motorCarData,
                  isLessThanEqualsTo1600CC: radioProps.target.value
                })
              }}
            >
              <Radio value={true}>Less than 1600 cc</Radio>
              <Radio value={false}>More than 1600 cc</Radio>
            </Radio.Group>
          </>
        )}

        {!motorCarData.isCarOwnedOrHiredByEmployer ? (
          <>
            <h3
              className="font-size-16 "
              style={{ fontWeight: "bold", margin: "1rem 0 0 0" }}
            >
              What will be the car used for?
            </h3>
            <Radio.Group
              className="section-top"
              value={motorCarData.isUsedForOfficialPurpose}
              name="carUsage"
              onChange={(radioProps) => {
                setMotorCarData({
                  ...motorCarData,
                  isUsedForOfficialPurpose: radioProps.target.value
                })
              }}
            >
              <Radio value={0}>For Official Purpose</Radio>
              {
                motorCarData.isCarOwnedOrHiredByEmployer && <Radio value={1}>For Personal Purpose</Radio>
              }
              <Radio value={2}>Partly Personal Partly Official</Radio>
            </Radio.Group>
            <h3
              className="font-size-16 "
              style={{ fontWeight: "bold", margin: "1rem 0 1rem 0" }}
            >
              Amount spend by the organisation ( per month )
            </h3>
            <InputNumberComponent
              labeltext={"Amount"}
              width={"500px"}
              margin={[18, 0, 18, 0]}
              value={motorCarData.bifurcatedAmount}
              onChange={(val) =>
                setMotorCarData({ ...motorCarData, bifurcatedAmount: val })
              }
            />
          </>
        ) : (
          <>
            <h3
              className="font-size-16 "
              style={{ fontWeight: "bold", margin: "1rem 0 0 0" }}
            >
              What will be the car used for?
            </h3>
            <Radio.Group
              className="section-top"
              value={motorCarData.isUsedForOfficialPurpose}
              name="carUsage"
              onChange={(radioProps) => {
                setMotorCarData({
                  ...motorCarData,
                  isUsedForOfficialPurpose: radioProps.target.value
                })
              }}
            >
              <Radio value={0}>For Official Purpose</Radio>
              <Radio value={1}>For Personal Purpose</Radio>
              <Radio value={2}>Partly Personal Partly Official</Radio>
            </Radio.Group>
          </>
        )}
        {motorCarData.isUsedForOfficialPurpose === 2 &&
          motorCarData.isCarOwnedOrHiredByEmployer ? (
          <>
            <h3
              className="font-size-16 "
              style={{ fontWeight: "bold", margin: "1rem 0 1rem 0" }}
            >
              The running and maintaining cost is incurred by ?
            </h3>
            <Radio.Group
              className="section-top"
              style={{ display: "flex" }}
              value={motorCarData.isMaintenanceReimbursedByEmployer}
              name="isIncurredby"
              onChange={(radioProps) => setMotorCarData({
                ...motorCarData,
                isMaintenanceReimbursedByEmployer: radioProps.target.value
              })}
            >
              <Radio value={true}>Organisation</Radio>
              <Radio value={false}>Employee</Radio>
            </Radio.Group>
            <h3
              className="font-size-16 "
              style={{ fontWeight: "bold", margin: "1rem 0 1rem 0" }}
            >
              The sum spent by the organisation on running and maintenance (per
              month)
            </h3>
            <InputNumberComponent
              labeltext={"Amount"}
              width={"500px"}
              margin={[18, 0, 18, 0]}
              value={motorCarData.bifurcatedAmount}
              onChange={(val) =>
                setMotorCarData({ ...motorCarData, bifurcatedAmount: val })
              }
            />
          </>
        ) : (
          <>
            {motorCarData.isCarOwnedOrHiredByEmployer && (
              <>
                <h3
                  className="font-size-16 "
                  style={{ fontWeight: "bold", margin: "1rem 0 1rem 0" }}
                >
                  Amount Spend By Employer ( per month )
                </h3>
                <InputNumberComponent
                  labeltext={"Amount"}
                  width={"500px"}
                  margin={[18, 0, 18, 0]}
                  value={motorCarData.bifurcatedAmount}
                  onChange={(val) =>
                    setMotorCarData({ ...motorCarData, bifurcatedAmount: val })
                  }
                />
              </>
            )}
          </>
        )}
        {(motorCarData.isUsedForOfficialPurpose !== 0 && (motorCarData.isUsedForOfficialPurpose !== 2)) &&
          (
            <>
              <h3
                className="font-size-16 m-top-16"
                style={{ fontWeight: "bold" }}
              >
                How much amount do you want to recover from employee ( per month ) ?
              </h3>
              <Radio.Group
                className="section-top"
                style={{ display: "flex" }}
                value={motorCarData.isFullRecovery}
                name="isFullRecover"
                onChange={(radioProps) => {
                  setMotorCarData({
                    ...motorCarData,
                    isFullRecovery: radioProps.target.value,
                    recoveryAmount: radioProps.target.value ? motorCarData.bifurcatedAmount : 0
                  })
                }}
              >
                <Radio value={true}>Fully {getFullToolTip("Actual amount of expenditure incurred by on running and maintenance of vehicle + remuneration paid to the chauffeur (if any) + Amount representing normal wear and tear of car")}</Radio>
                <Radio value={false}>Custom</Radio>
              </Radio.Group>
              {
                !motorCarData.isFullRecovery && <InputNumberComponent
                  labeltext={"Amount"}
                  width={"500px"}
                  margin={[18, 0, 18, 0]}
                  disabled={motorCarData.isFullRecovery}
                  value={motorCarData.recoveryAmount}
                  onChange={(val) =>
                    setMotorCarData({ ...motorCarData, recoveryAmount: val })
                  }
                />
              }
              <h3
                className="font-size-16 m-top-16"
                style={{ fontWeight: "bold" }}
              >
                Do you want to recover the amount from employee's salary
              </h3>
              <Radio.Group
                className="section-top"
                style={{ display: "flex" }}
                value={motorCarData.isRecovery}
                name="recoverFromSalary"
                onChange={(radioProps) => {
                  setMotorCarData({
                    ...motorCarData,
                    isRecovery: radioProps.target.value
                  })
                }}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </>
          )}
        <h3
          className="font-size-16 "
          style={{ fontWeight: "bold", margin: "1rem 0 1rem 0" }}
        >
          Do you want to appoint a chauffeur for this vehicle?
        </h3>
        <Radio.Group
          className="section-top"
          style={{ display: "flex" }}
          value={motorCarData.isChauffeurProvidedByEmployer}
          name="appointChauffeur"
          onChange={(radioProps) => setMotorCarData({
            ...motorCarData,
            isChauffeurProvidedByEmployer: radioProps.target.value
          })}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
        {motorCarData.isChauffeurProvidedByEmployer && (
          <>
            <Divider />
            <h3
              className="font-size-16 m-top-16"
              style={{ fontWeight: "bold" }}
            >
              Amount spend by employer on chauffeur's salary ( per month )
            </h3>
            <InputNumberComponent
              labeltext={"Amount"}
              width={"500px"}
              margin={[18, 0, 18, 0]}
              value={motorCarData.bifurcatedAmountForChauffeur}
              onChange={(val) =>
                setMotorCarData({
                  ...motorCarData,
                  bifurcatedAmountForChauffeur: val,
                })
              }
            />
            {
              (motorCarData.isUsedForOfficialPurpose !== 0 && (motorCarData.isUsedForOfficialPurpose !== 2)) &&
              <>
                <h3
                  className="font-size-16 m-top-16"
                  style={{ fontWeight: "bold" }}
                >
                  How much amount do you want to recover from employee ( per month ) ?
                </h3>
                <Radio.Group
                  className="section-top"
                  style={{ display: "flex" }}
                  value={motorCarData.isFullRecoveryForChauffeur}
                  name="isFullRecover"
                  onChange={(radioProps) => setMotorCarData({
                    ...motorCarData,
                    isFullRecoveryForChauffeur: radioProps.target.value,
                    recoveryAmountOfChauffeur: radioProps.target.value ? motorCarData.bifurcatedAmountForChauffeur : 0
                  })}
                >
                  <Radio value={true}>Fully {getFullToolTip("Amount spend by employer on salary of chauffeur (per month)")}</Radio>
                  <Radio value={false}>Custom</Radio>
                </Radio.Group>
                {
                  !motorCarData.isFullRecoveryForChauffeur &&
                  <InputNumberComponent
                    labeltext={"Amount"}
                    width={"500px"}
                    margin={[18, 0, 18, 0]}
                    disabled={motorCarData.isFullRecoveryForChauffeur}
                    value={motorCarData.recoveryAmountOfChauffeur}
                    onChange={(val) =>
                      setMotorCarData({
                        ...motorCarData,
                        recoveryAmountOfChauffeur: val,
                      })
                    }
                  />
                }
                <h3
                  className="font-size-16 m-top-16"
                  style={{ fontWeight: "bold" }}
                >
                  Do you want to recover the amount from employee's salary
                </h3>
                <Radio.Group
                  className="section-top"
                  style={{ display: "flex" }}
                  value={motorCarData.isRecoveryForChauffeur}
                  name="recoverFromSalary"
                  onChange={(radioProps) => setMotorCarData({
                    ...motorCarData,
                    isRecoveryForChauffeur: radioProps.target.value
                  })}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </>
            }
          </>
        )}

        <div style={{ display: "flex", width: "15rem", margin: "2rem 0 0 0" }}>
          <NormalButton type={13} buttonText="Save" onClick={onSave} />
          <NormalButton type={15} buttonText="Cancel" onClick={closeDrawer} />
        </div>
      </div>
    </div>
  );

  return (
    isTrial ? <TrialPage user={user} trialMessage={"Explore the tax heaven for salaried employees without getting stressed about compliances"} bgImage={perqsBgImage}
    /> :
    <>
      <section className="site-layout">
        <HeaderComponent name="Motor Car" />
        <div className="app-main-content-container paper-wrapper">
          <h1 style={{ fontSize: "24px", fontWeight: "700", margin: "2rem 0" }}>
            Motor Car
          </h1>

          <Row justify="space-between">
            <h2
              style={{
                fontSize: "1.125rem",
                fontWeight: "700",
                margin: "2rem 0 ",
              }}
            >
              {dataSource.length} Entries
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NormalButton
                type={13}
                buttonText={"Add New"}
                heightWidth={["50px", "150px"]}
                margin={[0, 32]}
                onClick={() => {
                  openDrawer()
                  reset()
                  setEditClicked(false)
                }}
              ></NormalButton>
            </div>
          </Row>
          <Table columns={columns} dataSource={dataSource.reverse()} />
        </div>
        <Drawer
          title={<p className="subtitle3-med-16">Add New Entry</p>}
          placement="right"
          onClose={closeDrawer}
          visible={open}
          width={"495px"}
        >
          <div className="section">{addNewAccomodation}</div>
        </Drawer>
      </section>
    </>
  );
};

const MotorCar = connect(
  (state) => ({
    user: state.auth.user,
    employeeList: state.organisation.employeeList,
    month: state.organisation.month,
    financialYear: state.organisation.financialYear,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch),
  })
)(Motor_Car);

export default MotorCar;
