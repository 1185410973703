import { Modal, Popconfirm } from "antd";
import MonthClosingFields from "./components/monthClosingFields.js";
import MonthClosingFiles from "./components/monthClosingFiles.js";
import CurrentMonthClosingFields from "./components/currentMonthClosingFields.js";
import config from "../../utils/config";
import moment from "moment";
import { useEffect } from "react";
import { NormalButton } from "../ComponentLibrary";
import { deleteLogo } from "../../assets/common/editAndDeleteLogos";
import { useState } from "react";
import axios from "../../apis"

const General = ({ monthCloseFields, user, employeeList, organisation, deleteData, loading }) => {
    const [singleModalShow, setSingleModalShow] = useState(false);
    const [bulkModalShow, setBulkModalShow] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);


    const handleAction = async (url, name) => {
        let res = await axios({
            url, //your url
            method: 'GET',
            responseType: 'blob',
        })
        const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = urlDownload;
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();
    }
    useEffect(() => {
        organisation.updateMonthClose({ organisationId: user.organisationId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefresh]);
    const columns = [
        {
            title: "Employee Name",
            dataIndex: ["user", "displayName"]
        },
        {
            title: "Employee Id",
            dataIndex: ["user", "currentOrganisationMeta", "employeeId"]
        },
        {
            title: "Added On",
            dataIndex: "createdAt",
            defaultSortOrder: "aescend",
            render: (text) => <p>{moment(text).format("ll")}</p>
        },
        {
            title: "Applicable On",
            dataIndex: "month",
            defaultSortOrder: "aescend",
            render: (text) => <p>{config.monthList[text]}</p>
        },
        {
            title: "Type",
            dataIndex: "type"
        },
        {
            title: "Reason",
            dataIndex: "details"
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (amount, row) => (
                <p className={row.type === "debit" ? "amount-debit" : "amount-credit"}>
                    {row.type === "debit" ? "-" : "+"}
                    {amount}
                </p>
            )
        },
        {
            title: "Action",
            dataIndex: "_id",
            render: (data, row) => (
                <Popconfirm title={"Are you sure ?"} onConfirm={() => deleteData(data)}>
                    <NormalButton
                        type={5}
                        icon={deleteLogo}
                    />
                </Popconfirm>

            )
        }
    ];
    const startRefresh = () => {
        setIsRefresh(!isRefresh)
    }
    return (
        <>
            <Modal
                visible={singleModalShow}
                centered
                width={400}
                footer={null}
                onCancel={() => setSingleModalShow(false)}
            >
                <MonthClosingFields
                    employeeList={employeeList}
                    user={user}
                    organisation={organisation}
                    setSingleModalShow={setSingleModalShow}
                />
            </Modal>
            <Modal
                visible={bulkModalShow}
                centered
                width={400}
                footer={null}
                onCancel={() => setBulkModalShow(false)}
            >
                <MonthClosingFiles
                    organisationId={user.organisationId}
                    employeeList={employeeList}
                    user={user}
                    organisation={organisation}
                    setBulkModalShow={setBulkModalShow}
                    handleAction={handleAction}
                />
            </Modal>
            <CurrentMonthClosingFields
                monthCloseFields={monthCloseFields}
                columns={columns}
                startRefresh={startRefresh}
                loading={loading}
                setSingleUploadModal={setSingleModalShow}
                setBulkUploadModal={setBulkModalShow}
                name={"General"}
            />
        </>
    );
};


export default General;
