import { connect } from "react-redux";
import { HeaderComponent } from "../Common";
import { useState, useEffect } from "react";
import { getAllTransactions, refreshStatusOfCashfree } from "../../apis/organisation"
import moment from "moment";
import { DatePicker, Table, Tag } from "antd";
import payoutEmpty from "../../assets/organisationConfiguration/AllowanceEmpty.svg";
import { ReloadOutlined } from "@ant-design/icons";
import TrialPage from "../Common/TrialComponent";
import transactionHistoryBgImage from "../../assets/dummyPages/transactionHistoryBgImage.png"

const PayoutTransactionHistory = ({ user, payout, isTrial }) => {

    const [transactionList, setTransactionList] = useState([])
    const [duplicateTransactionList, setDuplicateTransactionList] = useState([])
    const [selectedDate, setSelectedDate] = useState(moment())
    const [isLoading, setIsLoading] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    const [payoutAvailable, setPayoutAvailable] = useState(false);
    const [currYear, setCurrYear] = useState(new Date().getFullYear());

    useEffect(() => {
        if (payout && Object.keys(payout).length > 0 && payout.instanceStatus === 3) {
            setPayoutAvailable(true)
        }
    }, [payout])

    let getTransactionHistory = async (e = null) => {
        setIsLoading(true)
        let year = currYear;
        if (e !== null) {
            year = e.year();
        }
        let res = await getAllTransactions(user.organisationId, year)
        if (res.status === 200) {
            setTransactionList(res.data)
            setDuplicateTransactionList(res.data)
        }
        setIsLoading(false)
    }

    let filterTransactionHistory = async (e = null) => {
        setIsLoading(true)
        if (!e) {
            setTransactionList(duplicateTransactionList)
        } else {
            if (e.year() !== currYear) {
                setCurrYear(e.year())
                console.log(e.year(), currYear)
                await getTransactionHistory(e)
            } else {
                setTransactionList([...duplicateTransactionList.filter(item => new Date(item.createdAt).getMonth() === e.month())])
            }
        }
        setIsLoading(false)
    }
    useEffect(() => {
        getTransactionHistory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshedData])

    const columns = [
        {
            title: "Beneficiary ID",
            dataIndex: "id",
            ellipsis: true,
        },
        {
            title: "Name",
            dataIndex: ["user", "displayName"],
            ellipsis: true,
        },
        {
            title: "Amount ",
            dataIndex: "amount",
            ellipsis: true,
            render: (amount) => {
                return `${amount} INR`
            }
        },
        {
            title: "Transaction ID",
            dataIndex: "transferId",
            ellipsis: true,
            render: (id) => {
                return (
                    <code>
                        {id}
                    </code>
                )

            }
        },
        {
            title: "Created On",
            dataIndex: "createdAt",
            ellipsis: true,
            render: (data) => {
                return (
                    data ? <p>{new Date(data).toDateString()}</p> : ""
                )

            }
        },
        {
            title: "Status",
            dataIndex: "cashFreeDetails",
            ellipsis: true,
            render: (cashFreeDetails) => (<>{
                cashFreeDetails.status === "SUCCESS"
                    ? <Tag color="success">Success</Tag>
                    : cashFreeDetails.status === "PENDING" ? <Tag color="orange">Pending</Tag> : <Tag color="error">Failed</Tag>
            }</>)
        },
        {
            title: "",
            dataIndex: "transferId",
            ellipsis: true,
            render: (transferId, result) => {
                const { data } = result.cashFreeDetails;
                return (
                    <>
                        {result.cashFreeDetails.status === "PENDING" ? <ReloadOutlined onClick={() => { onRefresh({ transferId, referenceId: data.referenceId, organisationId: result.user.organisationId }) }} /> : ""}
                    </>
                )
            }
        },
    ]
    useEffect(() => {
        document.title = 'Payout | Transaction History';
    });
    const onRefresh = async ({ organisationId, transferId, referenceId }) => {
        setIsLoading(true)
        await refreshStatusOfCashfree({ organisationId, transferId, referenceId })
        setRefreshedData(!refreshedData)
        setIsLoading(false)
    }
    return (
        isTrial ? <TrialPage user={user} trialMessage={"No need to depend on banks. Setup payouts & disburse salaries directly to employees bank account without any errors"} bgImage={transactionHistoryBgImage}
        /> :
            <div>
                <div className="site-layout">
                    <HeaderComponent name="Transaction History" />
                    <div className="app-main-content-container reimbursement-container">
                        {payoutAvailable && <div className="flex align-center">
                            <p className="app-heading-5 mx-8 " style={{ marginRight: "20px" }}>Filter Date</p>
                            <DatePicker
                                value={selectedDate}
                                onChange={async (e) => {
                                    setSelectedDate(e)
                                    await filterTransactionHistory(e)
                                }}
                                picker="month"
                            />
                        </div>}

                        {payoutAvailable && <Table
                            style={{ fontWeight: 500, marginTop: 16 }}
                            columns={columns}
                            dataSource={transactionList ? transactionList.reverse() : []}
                            pagination={false}
                            scroll={{ y: 650 }}
                            loading={isLoading}
                        />}
                        {
                            !payoutAvailable && <ZeroState />
                        }
                    </div>
                </div>
            </div>
    )
}

const ZeroState = () => {
    return (
        <div className="payout-state-0-container flex flex-direction-column align-center">
            <div className="my-30">
                <img alt="img" className="payout-state-0-image" src={payoutEmpty} />
            </div>
            <p className="title-gray-2 my-30">
                Auto-payouts has not been set up for your organisation
            </p>
        </div>
    );
};

const PayoutTransactionHistoryContainer = connect(
    (state) => ({
        user: state.auth.user,
        payout: state.organisation.payout,
        isTrial: state.auth.user.isTrial,

    })
)(PayoutTransactionHistory);

export default PayoutTransactionHistoryContainer;