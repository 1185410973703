import { notification, Layout, Spin, InputNumber } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import {
  deleteCurrentPayslip,
  updateDeclarations,
} from "../../../apis/employee";
import { NormalButton } from "../../ComponentLibrary";
import employee from "../../../redux/modules/employee";
import { checkFinancialYear } from "../../../utils/config";

const { Content } = Layout;

const ManagePerquisites = ({
  user,
  declaration,
  structureOfMool,
  organisationSettings,
  perquisites,
  organisationFreeze,
  ctc,
  employeeReducer,
}) => {
  const [nonTaxablePerqs, setNonTaxableperqs] = useState({});

  useState(() => {
    let { perquisites: perqs } = organisationSettings;
    if (!perqs) {
      perqs = {};
    }
    let resultantPerqs = {};
    Object.keys(perqs).forEach((prq) => {
      if (perqs[prq].applicable) {
        let amount = !isNaN(parseFloat(perqs[prq].amount))
          ? perqs[prq].amount
          : 0;
        let actualExpense =
          perquisites[prq] && !isNaN(parseFloat(perquisites[prq].actualExpense))
            ? parseFloat(perquisites[prq].actualExpense)
            : amount;
        resultantPerqs[prq] = {
          ...perqs[prq],
          amount,
          limit: amount,
          actualExpense,
        };
      }
    });
    setNonTaxableperqs({ ...resultantPerqs });
  }, [perquisites, organisationSettings]);
  const [loading, setLoading] = useState(false);

  const changePerquisitesState = (key, value) => {
    if (
      (!structureOfMool && perquisites[key]["amount"] < value) ||
      !perquisites[key]["applicable"]
    ) {
      notification.error({ message: "Please Enter Valid Amount" });
      return;
    }
    let newPerqs = nonTaxablePerqs;
    newPerqs[key]["actualExpense"] = value;
    setNonTaxableperqs({ ...newPerqs });
  };

  const submitPerquisites = async () => {
    if (
      organisationFreeze.perquisitesFreeze &&
      organisationFreeze.perquisites[user._id]
    ) {
      notification.warn({
        message: `Organisation has freezed the perqs for the current financial year`,
      });
      return;
    }
    setLoading(true);
    let dcl = declaration;

    const { financialYear, month } = checkFinancialYear();
    dcl.declarations.perquisites = { ...nonTaxablePerqs };
    await updateDeclarations({
      id: user._id,
      declarations: dcl.declarations,
      month
    });

    await deleteCurrentPayslip(user._id);
    await employeeReducer.askMool({
      financialYear,
      month,
      id: user._id,
      user,
      ctc,
    });
    notification.success({ message: "Successfully saved the perqs" });
    setLoading(false);
  };

  return (
    <div className="manage-allowances-container">
      <Spin spinning={loading} size="large">
        <Content className="stages">
          <div className="main-container">
            <div className="header-display">
              <p className="header-title">
                Provide some information of your spend so that our system
                generates a payslip that's best suited for you
              </p>
            </div>
            <div className="allowances-container">
              {Object.keys(nonTaxablePerqs).map((val, _index) => (
                <div className="single-allowance" key={val}>
                  <p>{nonTaxablePerqs[val].label}</p>
                  <InputNumber
                    size="medium"
                    min={0}
                    disabled={
                      organisationFreeze.perquisitesFreeze &&
                      organisationFreeze.perquisites[user._id]
                    }
                    max={
                      structureOfMool
                        ? 1000000000
                        : nonTaxablePerqs[val]["amount"]
                    }
                    value={nonTaxablePerqs[val]["actualExpense"]}
                    onChange={(value) => changePerquisitesState(val, value)}
                  />
                </div>
              ))}
            </div>
            <div className="submit-container">
              <NormalButton
                onClick={submitPerquisites}
                type={13}
                buttonText="Update"
              />
            </div>
          </div>
        </Content>
      </Spin>
    </div>
  );
};

const ManagePerquisitesContainer = connect(
  (state) => ({
    user: state.auth.user,
    declaration: state.employee.declaration,
    structureOfMool: state.employee.structureOfMool,
    organisationSettings: state.employee.organisationSettings,
    monthClose: state.employee.monthClose,
    group: state.employee.group,
    hra: state.employee.hra,
    deductions: state.employee.deductions,
    reimbursements: state.employee.reimbursements,
    ctc: state.employee.ctc,
    organisationFreeze: state.employee.organisationFreeze,
    onboarding: state.employee.onboarding,
    location: state.employee.location,
    isPercentageStructure: state.employee.isPercentageStructure,
    previousEmployement: state.employee.previousEmployement,
    payslips: state.employee.payslips,
    percentage: state.employee.percentage,
    advanceTaxConfig: state.employee.advanceTaxConfig,
    perquisites: state.employee.perquisites,
    allowances: state.employee.allowances,
    customAllowances: state.employee.customAllowances,
  }),
  (dispatch) => ({
    employeeReducer: employee.getActions(dispatch),
  })
)(ManagePerquisites);

export default ManagePerquisitesContainer;
