/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Spin,
  Select,
  message,
  Upload,
  notification,
  Row,
  Col,
  Table,
} from "antd";
import {
  updateEmployerOnBoarding,
  uploadPayslips,
} from "../../apis/organisation";
import { Content } from "antd/lib/layout/layout";
import config, { monthList, numformatter, checkFinancialYear } from "../../utils/config";
import uploadLogo from "../../assets/icons/savefile.svg";
import { useEffect, useState } from "react";
import logo from "../../assets/moolLogo.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { createLog } from "../../apis/user";
import NormalButton from "../ComponentLibrary/ButtonComponents/NormalButton";

const { Dragger } = Upload;
const { Option } = Select;

const Payslip = ({
  user,
  onboarding,
  organisationReducer,
  handleAction,
  commonColumn,
}) => {
  const [pageState, setPageState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const migration = [
    {
      title: "Default",
      logoSrc: logo,
    },
  ];
  const [financialYearList, setFinancialYearList] = useState([]);
  const [payslipData, setPayslipData] = useState({
    financialYear: "",
    month: 0,
    employeeData: [],
  });

  const updateOnboarding = async (type) => {
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployerOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id,
      });
      organisationReducer.updateEmployerOnBoarding({
        organisationId: user.organisationId,
        id: user._id,
      });
    }
  };
  const [platformIndex, setPlatformIndex] = useState(0);
  useEffect(() => {
    let startFYear = 2015;
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let resFinancialYearList = [];
    for (let i = startFYear; i < currentYear + 1; i++) {
      let nextYear = i + 1;
      let tempFYear = `${i}-${nextYear}`;
      resFinancialYearList.push(tempFYear);
    }
    setFinancialYearList([...resFinancialYearList]);
  }, [user]);
  const heading = [
    "Select The Platform",
    "Upload the Document",
    "Verify and Update",
  ];
  const uploadProps = {
    name: "file",
    action: `${config.rootUrl}/api/validate/payslip`,
    headers: {
      authorization: localStorage.getItem("mool_auth"),
    },
    multiple: false,
    async onChange(info) {
      setIsLoading(true);
      let { response } = info.file;
      if (response) {
        setIsLoading(false);
        setPageState(2);
        setPayslipData({ ...payslipData, employeeData: response });
      }
    },
  };

  const platforms = [
    `${config.rootUrl}/api/validate/payslip`,
    `${config.rootUrl}/api/validate/payslip`,
  ];

  const selectPlatform = (index) => {
    setPageState(1);
    setPlatformIndex(index);
  };

  const reset = () => {
    setPayslipData({
      financialYear: "",
      month: 0,
      employeeData: [],
    });
    setPageState(0);
    setPlatformIndex(0);
  };

  const uploadPayslip = async () => {
    if (!/^[0-9]{4}-[0-9]{4}$/.test(payslipData.financialYear)) {
      message.error("Please Enter Financial Year");
      return;
    }
    if (payslipData.month > 11 && payslipData.month < 0) {
      message.error("Please Enter Month");
      return;
    }
    if (payslipData.employeeData.length === 0) {
      message.error("Please Upload CSV File");
      return;
    }
    setIsLoading(true);
    const { financialYear } = checkFinancialYear();
    let data = await uploadPayslips(payslipData);
    if (data.data.success) {
      await createLog({
        name: user.displayName,
        userId: user._id,
        organisationId: user.organisationId,
        message: `Organisation user updated ${payslipData.length} number of payslips for ${monthList[payslipData.month]}`,
        category: 15,
      });
      await updateOnboarding("previousDataSync");
      organisationReducer.freezeResult(
        `${user.organisationId}/${financialYear}`
      );
      notification.success({ message: "Successfully Uploaded the payslip" });
    } else {
      notification.error({ message: "Unable to upload payslip" });
    }
    setIsLoading(false);
    reset();
  };

  return (
    <div className="">
      <div className="flex align-center">
        {pageState !== 0 && (
          <ArrowLeftOutlined
            onClick={() => {
              let page = pageState;
              page = page - 1;
              setPageState(page);
            }}
            style={{ marginRight: 5 }}
            className="arrow-left-button font-weight-700"
          />
        )}
        <h3 className="font-weight-global-700 font-size-global-24">{heading[pageState]}</h3>
      </div>
      {pageState === 0 && (
        <div className="platforms-list">
          {migration.map((val, index) => (
            <SelectPlatform
              key={`platform-${index}`}
              index={index}
              selectPlatform={selectPlatform}
              {...val}
            />
          ))}
        </div>
      )}
      {pageState === 1 && (
        <UploadDocument
          platforms={{ ...uploadProps, action: platforms[platformIndex] }}
          user={user}
          isLoading={isLoading}
          handleAction={handleAction}
        />
      )}
      {pageState === 2 && (
        <VerifyAndUpload
          uploadPayslip={uploadPayslip}
          setPayslipData={setPayslipData}
          payslipData={payslipData}
          reset={reset}
          isLoading={isLoading}
          financialYearList={financialYearList}
          commonColumn={commonColumn}
        />
      )}
    </div>
  );
};

const SelectPlatform = ({ title, logoSrc, selectPlatform, index }) => {
  return (
    <div className="platform" onClick={() => selectPlatform(index)}>
      <img alt={title} src={logoSrc} className="platform-img" />
      {/* <p className="font-weight-900">{title}</p> */}
    </div>
  );
};

const UploadDocument = ({ platforms, user, isLoading, handleAction }) => {
  return (
    <Content className="bulk-wrapper-left">
      <div className="paper-header-container">
        <div>
          <p className="paper-title">
            Download Sample in{" "}
            <a
              className="download-employee-list"
              onClick={() =>
                handleAction(
                  `${config.rootUrl}/api/organisation/csv/payslip/${user.organisationId}`,
                  "PayslipStructure.csv"
                )
              }
            >
              .csv format
            </a>{" "}
            file to ensure that you have the correct file ready to import.
          </p>
        </div>
      </div>
      <Spin spinning={isLoading} size="large">
        <div>
          <div>
            <Dragger
              {...platforms}
              showUploadList={false}
              height={300}
              className="bulk-wrapper-dragger"
            >
              <p className="ant-upload-drag-icon">
                <img alt="" src={uploadLogo} height="80" width="80" />
              </p>

              <p className="ant-upload-text">Drag and drop CSV file</p>
              <p className="ant-upload-hint">
                or <u>Click Here</u> to upload
              </p>
            </Dragger>
          </div>
        </div>
      </Spin>
    </Content>
  );
};

const VerifyAndUpload = ({
  setPayslipData,
  payslipData,
  uploadPayslip,
  reset,
  financialYearList,
  isLoading,
  commonColumn,
}) => {
  const tableData = [
    {
      title: "Employee ID",
      dataIndex: ["user", "currentOrganisationMeta", "employeeId"],
      key: "Employee_ID",
      fixed: "left",
    },
    {
      title: "Username",
      dataIndex: ["user", "username"],
      key: "username",
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: ["user", "displayName"],
      key: "Name",
      fixed: "left",
    },
    {
      title: "IT Regime",
      dataIndex: ["user", "regimeOptedStatus"],
      key: "takeHome",
      render: (text) => <p>{text === 1 ? "New Regime" : "Old Regime"}</p>,
    },
    {
      title: "Total Earning",
      dataIndex: ["finalPayslip", "totalFixedIncome"],
      key: "takeHome",
      render: (data) => (!data ? <p>N/A</p> : <p>{numformatter(data)}</p>),
    },
    {
      title: "Income Tax",
      dataIndex: ["user", "regimeOptedStatus"],
      key: "takeHome",
      render: (text, row) => (
        <p>
          {text === 1
            ? numformatter(
                row.finalPayslip.newRegime.incomeTax +
                  isNaN(parseInt(row.finalPayslip.advanceTax))
                  ? 0
                  : row.finalPayslip.advanceTax
              )
            : numformatter(
                row.finalPayslip.oldRegime.incomeTax +
                  isNaN(parseInt(row.finalPayslip.advanceTax))
                  ? 0
                  : row.finalPayslip.advanceTax
              )}
        </p>
      ),
    },
    {
      title: "Total Deduction",
      dataIndex: ["user", "regimeOptedStatus"],
      key: "takeHome",
      render: (text, row) => (
        <p>
          {text === 1
            ? numformatter(row.finalPayslip.newRegime.deductions)
            : numformatter(row.finalPayslip.oldRegime.deductions)}
        </p>
      ),
    },
    {
      title: "Take Home Amount",
      dataIndex: ["user", "regimeOptedStatus"],
      key: "takeHome",
      render: (text, row) => (
        <p>
          {text === 1
            ? numformatter(row.finalPayslip.newRegime.takeHome)
            : numformatter(row.finalPayslip.oldRegime.takeHome)}
        </p>
      ),
    },
  ];
  return (
    <Spin size="large" spinning={isLoading}>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={8} lg={8}>
          <div className="radio-group">
            <p className="general-ant-field-label">Financial Year</p>
            <Select
              className="general-ant-field-select"
              style={{ width: "100%", marginTop: 10 }}
              size="large"
              value={payslipData.financialYear}
              onChange={(e) => {
                setPayslipData({ ...payslipData, financialYear: e });
              }}
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              showSearch
            >
              <Option value={""}>Financial Year...</Option>
              {financialYearList.map((val) => (
                <Option value={val}>{val}</Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8}>
          <div className="radio-group">
            <p className="general-ant-field-label">Select Month</p>
            <Select
              className="general-ant-field-select"
              size="large"
              value={payslipData.month}
              style={{ width: "100%", marginTop: 10 }}
              onChange={(e) =>
                setPayslipData({
                  ...payslipData,
                  month: parseInt(e),
                })
              }
              placeholder="month"
              bordered={false}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {config.monthList.map((val, index) => (
                <Option value={index} key={`month-${index}`}>
                  {val}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} style={{display:"flex",alignItems:"center"}}>
              <div className="flex w-full justify-end">
                <NormalButton
                  onClick={reset}
                  type={5}
                  buttonText="Cancel"
                  className="cancel-button"
                  style={{
                    border: "none",
                    boxShadow: "none",
                    marginRight: "0px",
                  }}
                />
                <NormalButton
                  onClick={uploadPayslip}
                  type={13}
                  buttonText="Upload Data"
                  className="submit-button"
                  style={{
                    marginRight: "0px",
                    padding: "0px 24px",
                  }}
                />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Table
            scroll={{ x: 600 }}
            columns={tableData}
            dataSource={payslipData.employeeData}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default Payslip;
