import { NormalButton } from "../../../ComponentLibrary";
import plusSymbol from "../../../../assets/icons/whitePlus.svg";
import upload from "../../../../assets/icons/cloud-upload-outline.svg";
import { Table } from "antd";
import { useState, useEffect } from "react";
import organisation from "../../../../redux/modules/organisation";
import NewOrUpdateDepartment from "./NewOrUpdateDepartment";
import { connect } from "react-redux";
import BulkUpload from "../BulkUpload";
import { updateEmployerOnBoarding } from "../../../../apis/organisation";
import { HeaderComponent } from "../../../Common";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"

const Department = ({
  employeeList,
  organisationReducer,
  user,
  deviceWidth,
  loading,
  departmentList,
  onboarding,
  handleAction,
  isTrial
}) => {
  const [pageState, setPageState] = useState(0);
  const [updateOrView, setUpdateOrView] = useState("");
  const [modalStatus, setModalStatus] = useState(false);

  const updateOnboarding = async (type) => {
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployerOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id
      });
      organisationReducer.updateEmployerOnBoarding({
        organisationId: user.organisationId,
        id: user._id
      });
    }
  };

  const uploadFunction = async () => {
    await updateOnboarding("department");
    await organisationReducer.getDepartment(user.organisationId);
  };
  const columns = [
    {
      title: "",
      dataIndex: "departmentName",
      width: "1%",
      render: (_departmentName, rowData) => (
        <div className="flex align-center">
          <div
            className="dept-color-circle"
            style={{ backgroundColor: rowData.color }}
          ></div>
        </div>
      )
    },
    {
      title: "Name",
      dataIndex: "departmentName"
    },
    {
      title: "Employees",
      dataIndex: "totalNoOfEmployees"
    },
    {
      title: "",
      dataIndex: "departmentId",
      width: deviceWidth > 1500 ? "10%" : deviceWidth > 1000 ? "50%" : "20%",
      render(departmentId) {
        return (
          <NormalButton
            type={5}
            buttonText="View"
            onClick={() => {
              setPageState(1);
              setUpdateOrView(departmentId);
            }}
          />
        );
      }
    }
  ];
  useEffect(() => {
    document.title = 'Settings | Departments';
  });
  return (
    isTrial ? <TrialPage user={user} trialMessage={"Configure Mool as per your company policy"} bgImage={configurationsBgImage}
    /> :
    <div className="site-layout">
      <HeaderComponent show={false}
        name="Departments" />
      <div className="app-main-content-container paper-wrapper">

        {pageState === 0 && (
          <div>
            <h1 className="h1-bold-24">Department</h1>
            <p className="tab-subtitle-1">
              Create departments that represent your organization structure
            </p>
            <div className="flex justify-between align-center">
              <p className="h5-bold-20">List ({departmentList.length})</p>
              <div>
                <NormalButton
                  icon={upload}
                  buttonText="Bulk Upload"
                  type={16}
                  onClick={() => {
                    setModalStatus(true);
                  }}
                />
                <NormalButton
                  icon={plusSymbol}
                  buttonText="Create New"
                  type={13}
                  onClick={() => {
                    setPageState(1);
                  }}
                />
              </div>
            </div>
            <Table
              style={{ fontWeight: 500, marginTop: 16, }}
              columns={columns}
              dataSource={departmentList.reverse()}
              pagination={false}
              scroll={{ y: 520 }}
            />
            <BulkUpload
              modalStatus={modalStatus}
              setModalStatus={setModalStatus}
              type="dept"
              updateFunction={uploadFunction}
              handleAction={handleAction}
            />
          </div>
        )}
        {pageState === 1 && (
          <NewOrUpdateDepartment
            loading={loading}
            setPageState={setPageState}
            employeeList={employeeList}
            organisationReducer={organisationReducer}
            setUpdateOrView={setUpdateOrView}
            updateOrView={updateOrView}
            user={user}
            deviceWidth={deviceWidth}
            onboarding={onboarding}
            updateOnboarding={updateOnboarding}
          />
        )}
      </div>
    </div>
  );
};

const DepartmentContainer = connect(
  (state) => ({
    deviceWidth: state.responsive.deviceWidth,
    departmentList: state.organisation.department,
    loading: state.organisation.loading,
    employeeList: state.organisation.employeeList,
    user: state.auth.user,
    onboarding: state.organisation.onboarding,
    isTrial: state.auth.user.isTrial,

  }),
  (dispatch) => ({
    organisationReducer: organisation.getActions(dispatch)
  })
)(Department);

export default DepartmentContainer;
