import auth from "../../../redux/modules/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./Header.less";
import { Layout, Menu, Dropdown, Button, notification } from "antd";
import DefaultAvatar from "../DefaultAvatar/DefaultAvatar";
import userProfileDropdown from "../../../assets/icons/activeIcons/userProfileDropdown.svg";
import LogoutProfileDropdown from "../../../assets/icons/activeIcons/LogoutProfileDropdown.svg";
import { withRouter } from "react-router-dom";
import moment from "moment";
import employee from "../../../redux/modules/employee";
import backArrow from "../../../assets/icons/white-arrow-back-outline.svg";
import downoutined from "../../../assets/icons/downoutlined.svg";
import { checkAuthToken } from "../../../apis/auth";
import React, { useEffect, useState } from "react";
// import organisationLogo from "../../../assets/common/organisation_temporary_logo.svg";
// import EmployeeLogo from "../../../assets/employeeProfile/EmployeeLogo.svg";

import { categoryMappring, paths } from "../../../constants/routes";
import ColorGenerator from "../../../utils/ColorGenerator";
// import {useGoogleLogout} from "react-google-login";

const { Header } = Layout;

const HeaderComponent = ({
  authReducer,
  image,
  user,
  parentOrganisation,
  backButton,
  history,
  icon,
  name,
  show
}) => {
  const backgroundColor = ColorGenerator();
  // const { signOut } = useGoogleLogout({})

  const lastDate = new moment();
  lastDate.add(60, "days").calendar();
  function handleMenuClick() {
    authReducer.logout();
    window.location = "/";
  }

  const [authList, setAuthList] = useState({});

  useEffect(() => {
    let inheritance = localStorage.getItem("inheritance");
    inheritance = JSON.parse(inheritance);
    setAuthList({ ...inheritance });
  }, []);

  const authenticate = token => {
    const postData = {
      token: token
    };
    checkAuthToken(postData)
      .then(data => {
        if (data.data) {
          localStorage.setItem("mool_auth", token);
          window.location = "/";
        } else {
          notification.success({ message: "Sorry unable to authenticate" });
        }
      })
      .catch(_err => {
        notification.success({ message: "Sorry unable to authenticate" });
      });
  };

  const renderComponent = type => {
    authReducer.updateCurrentTab(type);
    authReducer.updateSelectedCategory(categoryMappring[user.role][type]);
    history.push(paths[user.role][type]);
  };

  const menu = (
    <Menu className="header-menu-slab flex justify-center">
      <div className="header-menu-container flex align-center justify-center">
        <div className="image-container flex align-center justify-center">
          {image ? (
            <img
              alt="logo"
              className="header-menu-logo"
              src={image}
              style={
                image
                  ? {}
                  : user.role === "org"
                  ? { backgroundColor: "#fff1de" }
                  : { backgroundColor: "#fea101" }
              }
            />
          ) : (
            <DefaultAvatar
              user={user}
              backgroundColor={backgroundColor}
              textSize={"big"}
              size={108}
            />
          )}
        </div>
        <p className="header-menu-displayName" style={{ fontSize: "1.1em" }}>
          {user.displayName}
        </p>
        <p className="header-menu-email" style={{ fontSize: "0.8em" }}>
          {user.primaryEmail}
        </p>
        <p
          className="header-menu-PAN"
          style={{ fontSize: "0.85em", marginTop: "5px" }}
        >{`PAN: ${user.PAN}`}</p>
        {/* <p className="header-menu-PAN">{`Username: ${user.username}`}</p> */}
        <div className="header-menu-line"></div>
      </div>
      {parentOrganisation !== null &&
        Object.keys(parentOrganisation).length > 0 && (
          <div className="header-menu-container flex align-center justify-center">
            <p className="font-size-700 sub-info-text font-size-12">
              Parent Organisation
            </p>
            <p className="header-menu-displayName">
              {parentOrganisation.displayName}
            </p>
            <p className="header-menu-email">
              {parentOrganisation.primaryEmail}
            </p>
            <p className="header-menu-PAN">{`PAN: ${parentOrganisation.PAN}`}</p>
            <div className="header-menu-line"></div>
          </div>
        )}
      <div className="menu-actions">
        <span
          onClick={() => renderComponent("profile")}
          className="flex align-center justify-start action-item"
        >
          <img alt="" src={userProfileDropdown} />
          <span
            className="menu-text"
            style={{ marginLeft: "0.5rem", fontSize: ".95em" }}
          >
            Account
          </span>
        </span>
        {/* <span
          onClick={() => renderComponent("support")}
          className="flex align-center justify-start action-item"
        >
          <ChromeOutlined style={{ color: "#000" }} />
          <span className="menu-text" style={{ marginLeft: "0.5rem" }}>
            Support
          </span>
        </span> */}
        <span
          onClick={handleMenuClick}
          className="flex align-center justify-start action-item"
        >
          <img alt="" src={LogoutProfileDropdown} />
          <span
            className="menu-text"
            style={{ marginLeft: "0.5rem", fontSize: ".95em" }}
          >
            Logout
          </span>
        </span>
      </div>
    </Menu>
  );

  const MenuItems = props => (
    <span
      onClick={() => props._authenticate(props.auth)}
      className="flex align-center justify-start action-item"
    >
      <span className="menu-text" style={{ marginLeft: "0.5rem" }}>
        {props.displayName}
      </span>
    </span>
  );

  const authMenu = (
    <Menu className="header-menu-slab flex justify-center">
      <div className="menu-actions">
        {Object.keys(authList).map((val, _index) => (
          <MenuItems {...authList[val]} _authenticate={authenticate} />
        ))}
      </div>
    </Menu>
  );

  return (
    <Header className="site-layout-header">
      <div className="header-title">
        {backButton && (
          <div className="backArrow-container">
            <img
              src={backArrow}
              alt=""
              className={"arrow-left-outline"}
              onClick={() => {
                history.goBack();
              }}
            />
          </div>
        )}
        {/* {icon ? (
          <img alt="" src={icon} className="header-top-icon" />
        ) : (
          <React.Fragment></React.Fragment>
        )} */}
        {name ? <h2 className="header-top-name">{name}</h2> : <></>}
      </div>
      <div className="logout-form">
        {/* <MoolCoins showMoolCoins={showMoolCoins}></MoolCoins> */}
        {parentOrganisation !== null &&
          Object.keys(parentOrganisation).length > 0 &&
          Object.keys(authList).length > 0 && (
            <Dropdown
              overlay={authMenu}
              className="header-dropdown"
              trigger={["click"]}
              style
            >
              <Button
                className={`button-lib button-type-13 p-10`}
                style={{ height: "2.5rem", marginRight: "1rem" }}
              >
                <div className="flex justify-between align-center">
                  Switch User
                  <img
                    style={{ height: 18, width: 18 }}
                    src={downoutined}
                    alt="sec-icon"
                    className="button-lib-icon m-left-10"
                  />
                </div>
              </Button>
            </Dropdown>
          )}
        {show && (
          <Button
            style={{ marginRight: 30 }}
            type="primary"
            size="large"
            onClick={() => history.push(paths.org.payroll_history)}
          >
            Payroll History
          </Button>
        )}

        <Dropdown
          overlay={menu}
          className="header-dropdown"
          trigger={["click"]}
        >
          <Button className="dropdown-logo-container">
            {image ? (
              <img
                alt="logo"
                className="user-logo"
                src={image}
                style={
                  image
                    ? {}
                    : user.role === "org"
                    ? { backgroundColor: "#fff1de" }
                    : { backgroundColor: "#fea101" }
                }
              ></img>
            ) : (
              <DefaultAvatar
                user={user}
                backgroundColor={backgroundColor}
                textSize={"small"}
                size={40}
              />
            )}
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

HeaderComponent.propTypes = {
  user: PropTypes.object.isRequired
};

const HeaderComponentContainer = connect(
  state => ({
    user: state.auth.user,
    image: state.auth.image,
    organisationSettings: state.organisation.organisationSettings,
    separations: state.employee.separations,
    parentOrganisation: state.organisation.parentOrganisation
  }),
  dispatch => ({
    authReducer: auth.getActions(dispatch),
    employeeReducer: employee.getActions(dispatch)
  })
)(HeaderComponent);

export default withRouter(HeaderComponentContainer);
