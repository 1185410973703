import { EditOutlined } from "@ant-design/icons";
import { Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDeclarationAnnouncementStatus, sendDeductionMailToSelectedEmployees, updateDeclarationAnnouncementStatus } from '../../../apis/organisation';
import announcementImage from '../../../assets/organisationDashboard/announcement.svg';
import { checkFinancialYear } from "../../../utils/config";
import { getPreviousFinancialYear } from '../../../utils/dateCheckUtil';
import { NormalButton } from '../../ComponentLibrary';
import DateComponent from '../../InputLibrary/DateComponent/DateComponent';

const columns = [
	{
		key: "ID",
		title: "ID",
		dataIndex: ["currentOrganisationMeta", "employeeId"]
	},
	{
		key: "displayName",
		title: "Name",
		dataIndex: "displayName",
	},
	{
		key: "emailID",
		title: "Email ID",
		dataIndex: "primaryEmail",
	},
]
const MailNotification = ({ employeeList, user }) => {
	const [modalVisible, setModalVisible] = useState(false);
	const [secondModalVisible, setSecondModalVisible] = useState(false);
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [deadLineDate, setDeadLineDate] = useState();
	const [announcementStatus, setAnnouncementStatus] = useState(false);
	const [refresh, setRefresh] = useState(false);


	useEffect(() => {
		getDeclarationAnnouncementStatus({ organisationId: user.organisationId, financialYear: getPreviousFinancialYear() }).then(res => {
			if (res.data.success) {
				setAnnouncementStatus(res.data.data.announcementStatus)
			}
		}).catch(err => {
			setAnnouncementStatus(false)
		});
	}, [user, refresh])

	// const handleSetCancelAnnouncement = () => {
	// 	updateDeclarationAnnouncementStatus({ organisationId: user.organisationId, financialYear: getPreviousFinancialYear(), announcementStatus: false, deadLineDate: null });
	// }
	const sendDeductionMailer = () => {
		updateDeclarationAnnouncementStatus({ organisationId: user.organisationId, financialYear: getPreviousFinancialYear(), announcementStatus: false, deadLineDate });
		sendDeductionMailToSelectedEmployees({ organisationId: user.organisationId, financialYear: checkFinancialYear().financialYear, employeeList: selectedEmployees, deadLineDate }).then(res => {
			setRefresh(true);
		})
		setSecondModalVisible(false);
	}

	const saveAndContinueEmployees = () => {
		const selectedEmployeesSection = [];
		const selectedRows = [];
		for (let rowID of selectedRowKeys) {
			const employees = employeeList.filter(item => {
				return item._id.toString() === rowID.toString();
			});
			const employee = employees[0];
			if (!employee.primaryEmail) {
				continue
			}
			selectedRows.push(employee._id)
			selectedEmployeesSection.push({
				primaryEmail: employee.primaryEmail,
				displayName: employee.displayName,
			});
		}
		setSelectedEmployees([...selectedEmployeesSection]);
		setSelectedRowKeys([...selectedRows])
		setModalVisible(false);
		setSecondModalVisible(true);
	}

	const onSelectChange = (rows) => {
		setSelectedRowKeys([...rows]);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		selections: [
			Table.SELECTION_ALL,
			Table.SELECTION_INVERT,
			Table.SELECTION_NONE,
		],
	};

	return (
		<>
			<Modal visible={secondModalVisible} onCancel={() => { setSecondModalVisible(false) }} footer={[]} width={400} >
				<div style={{ margin: "1.5rem" }}>
					<div style={{ marginBottom: "1rem" }}>
						<h2>Define a Deadline</h2>
						<p>Select a deadline to inform the employees</p>
					</div>
					<p>Employees selected : <b>{selectedEmployees.length}</b> <Link onClick={() => {
						setSecondModalVisible(false);
						setModalVisible(true);
					}}><EditOutlined /> Change</Link></p>

					<div style={{ marginTop: "2rem" }}>
						<DateComponent
							className="app-date-picker block"
							format="MM/DD/YYYY"
							value={deadLineDate}
							labelText={"Select a deadline"}
							onChange={(e) => { setDeadLineDate(e) }}
						/>

					</div>
					<div className='m-top-20'>
						<NormalButton type={28} buttonText={"Cancel"} onClick={() => {
							setSecondModalVisible(false);
						}} />
						<NormalButton type={13} buttonText={"Send Reminder"} onClick={() => {
							sendDeductionMailer();
						}} />
					</div>
				</div>
			</Modal>
			<Modal visible={modalVisible} footer={[]} width={800} onCancel={() => { setModalVisible(false) }}>
				<div style={{ margin: "1.5rem" }}>
					<div style={{ marginBottom: "1rem" }}>
						<h2>Employee List</h2>
						<p>Select the employees who you want to send notification for making declaration</p>
					</div>
					<Table dataSource={employeeList}
						columns={columns}
						rowKey={"_id"}
						rowSelection={rowSelection}
						pagination={false} />
					<div className='m-top-20'>
						<NormalButton type={28} buttonText={"Cancel"} onClick={
							() => {
								setModalVisible(false);
							}
						} />
						<NormalButton type={13} buttonText={"Select And Continue"} onClick={saveAndContinueEmployees} />
					</div>
				</div>
			</Modal>


			{announcementStatus && <div id="declaration-notification">
				<div className='left-declaration-side'>
					<img src={announcementImage} alt="Announcement" className='announcement-img' />
					<div className='declaration-messages'>
						<h1>Send Declaration Submission Reminder</h1>
						<p>Send reminders to all your employees to make declarations for the FY {checkFinancialYear().financialYear}</p>
					</div>
				</div>
				<div>
					{/* <CloseOutlined className="absolute-close-notification" onClick={handleSetCancelAnnouncement} /> */}
					<NormalButton type={28} buttonText={"Do it now"} onClick={() => { setModalVisible(true) }} />
				</div>
			</div>}
		</>
	)
}

const MailNotificationContainer = connect((state) => ({
	user: state.auth.user,
	employeeList: state.organisation.employeeList,
}))(MailNotification);


export default MailNotificationContainer;
