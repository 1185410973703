import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Drawer, message, notification, Table } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getLocationByOrgId, getMinWagesExistingStructure, minWagesCurrentStructure, minWagesDynamicInputs, minWagesGeneralStructure, updateApplicableStructure } from "../../../../apis/organisation";
import organisation from "../../../../redux/modules/organisation";
import { checkFinancialYear, statesCodes } from "../../../../utils/config";
import { camelToSpaceSeparated } from "../../../../utils/utility";
import { HeaderComponent } from "../../../Common";
import { NormalButton } from "../../../ComponentLibrary";
import Spinner from "../../../ComponentLibrary/Spinner/Spinner";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";
import { PayStructureUpdate } from "./PaystructureUpdate";
import TrialPage from "../../../Common/TrialComponent";
import configurationsBgImage from "../../../../assets/dummyPages/configurationsBgImage.png"

const { financialYear } = checkFinancialYear();

const MinWages = ({ user, organisationReducer, isTrial }) => {

    const [refresh, setRefresh] = useState(false);
    const [isPayrollChecked, setIsPayrollChecked] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [locationMapper, setLocationMapper] = useState({});
    const [currentState, setCurrentState] = useState("");
    const [data, setData] = useState({});
    const [listData, setListData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [generatedStructure, setGeneratedStructure] = useState({});
    const [structure, setStructure] = useState({});
    const [structureDuplicate, setStructureDuplicate] = useState({});
    const [existingStructure, setExistingStructure] = useState({});
    const [employeeLoading, setEmployeeLoading] = useState(false);


    useEffect(() => {
        if (user) {
            setEmployeeLoading(true);
            setEmployeeList([])
            organisationReducer.getMinimumWagesEmployees(user.organisationId).then(res => {
                if (res.data) {
                    setEmployeeList(res.data);
                    setEmployeeLoading(false);
                } else {
                    setEmployeeList([])
                    setEmployeeLoading(false);
                }
            }).catch(err => {
                setEmployeeList([])
                setEmployeeLoading(false);
            });
        }
    }, [organisationReducer, user, refresh])


    useEffect(() => {
        setLoading(true)
        getLocationByOrgId(user.organisationId).then(res => {
            let mapper = {}
            if (res.data) {
                for (let item of res.data) {
                    mapper[item.locationId] = item.state;
                }
            }
            setLocationMapper(mapper)
        })
        setLoading(false)
    }, [user])



    useEffect(() => {
        if (currentState) {
            setLoading(true)
            minWagesDynamicInputs(currentState).then(res => {
                const list = res.data.data;
                setListData(list)
            });
            setLoading(false)
        }
    }, [currentState]);

    useEffect(() => {
        if (currentData._id) {
            setLoading(true)
            getMinWagesExistingStructure({ id: currentData._id, financialYear }).then(res => {
                if (res.data.success && res.data.data) {
                    setData(res.data.data.inputSet);
                    setExistingStructure(res.data.data.structure);
                    setIsPayrollChecked(res.data.data.isPayrollChecked ?? false)
                } else {
                    setExistingStructure({});
                }
            }).catch(err => {
                setExistingStructure({});
            })
            setLoading(false);
        }
    }, [currentData])

    function getInputData(name, listOptions, listValues) {
        return <DropdownComponent
            labeltext={camelToSpaceSeparated(name)}
            key={name}
            width={"450px"}
            margin={[30, 0, 0, 0]}
            options={listOptions}
            values={listValues}
            disabled={false}
            onChange={(e) => {
                resetUpdatePayStructure()
                setData({
                    ...data,
                    [name]: e
                })
            }}
            value={data[name]}
            showSearch
        />
    };

    const getInputFieldSection = (item, listDataSet) => {
        const listOptions = listDataSet[item].map(item => item.value)
        const listValues = listDataSet[item].map(item => item.key)
        return getInputData(item, listOptions, listValues);
    }

    const generatePayStructure = () => {
        const keyCombination = {};
        for (let item in listData) {
            keyCombination[item] = data[item];
        }
        setLoading(true);
        minWagesGeneralStructure({
            id: currentData._id,
            financialYear,
            stateCode: currentState,
            keyCombination: Object.values(keyCombination).join('-'),
            inputSet: keyCombination
        }).then((res) => {
            if (res.data.success && res.data.data) {
                setGeneratedStructure(res.data.data);
                setShowModal(true);
            } else {
                setGeneratedStructure({});
                message.warning("No data found for this combination, please try again or contact admin.")
            }
        }).catch(err => {
            setGeneratedStructure({});
        })

        minWagesCurrentStructure(currentData._id).then(res => {
            if (!res.data) {
                setStructure({});
            } else {
                setStructureDuplicate({ ...res.data });
                setStructure({ ...res.data });
            }
        }).catch(err => {
            setStructure({});

        })
        setLoading(false)
    }



    const onSave = () => {
        setLoading(true);
        updateApplicableStructure({
            id: currentData._id,
            financialYear,
            isApplicable: true
        }).then(res => {
            if (res.data.success) {
                notification.success({
                    message: "Structure applied successfully!",
                    description: `For ${currentData.displayName}, updated pay structure has been designated.`
                })
            } else {
                notification.error({
                    message: "Structure is not yet defined, Please define a structure for this employee.",
                })
            }
        }).catch(err => {
            notification.error({
                message: "Error while applying the structure!",
            })
        })
        setOpen(false);
        setLoading(false)
    }

    const reset = () => {
        setListData({});
        setCurrentData({})
        setExistingStructure({})
        setCurrentState("")
        setData({});
    }

    const onConfigure = (record) => {
        setLoading(true)
        showDrawer();
        setCurrentData(record)
        setCurrentState(locationMapper[record.locationId])
        setLoading(false)
    };


    const columns = [
        // {
        //     title: "",
        //     dataIndex: "flag",
        //     render: (flag) => {
        //         return <Checkbox checked={flag} disabled={true} />
        //     }
        // },
        {
            title: "ID",
            dataIndex: ["currentOrganisationMeta", "employeeId"],
        },
        {
            title: "Name",
            dataIndex: "displayName",
        },
        {
            title: "",
            dataIndex: "_id",
            render: (text, record, index) => {
                return (
                    <NormalButton
                        buttonText={"Configure"}
                        type={15}
                        onClick={() => { onConfigure(record) }}
                    ></NormalButton>
                );
            },
        },
    ];


    const employeeListDiv = (
        <>
            <div className="flex justify-between align-center">
                <h1 className="h5-bold-20">Employee List</h1>
                <NormalButton
                    type={13}
                    buttonText="Refresh List"
                    onClick={() => {
                        setRefresh(!refresh)
                    }}
                ></NormalButton>
            </div>
            <div className="section">
                <Spinner spinning={employeeLoading}>
                    <Table
                        columns={columns}
                        pagination
                        dataSource={employeeList ?? []}
                    />
                </Spinner>
            </div>
        </>
    );



    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        reset()
    };

    const info = (
        <div className="section">
            {employeeListDiv}
        </div>
    );


    const fieldSection = (
        <div className="section">
            Assigned State:
            <div>
                <DropdownComponent
                    labeltext={"Assigned Location"}
                    width={statesCodes[currentState] && "450px"}
                    options={Object.values(statesCodes)}
                    values={Object.keys(statesCodes)}
                    disabled={false}
                    labelColor="white"
                    onChange={(state) => setCurrentState(state)}
                    value={currentState}
                    showSearch
                />
            </div>
            <div
                className="flex justify-between align-center"
                style={{
                    backgroundColor: "#95a8fb42",
                    color: "#405cd2",
                    marginTop: "20px",
                    borderRadius: "5px",
                    padding: "10px"
                }}
            >
                <InfoCircleOutlined style={{ fontSize: "1.5rem", marginRight: "1rem" }} />
                <p>
                    Note: input fields will generated/collected automatically according to the state ( employee assigned ).
                </p>
            </div>
            <div>
                {
                    listData && Object.keys(listData).length ?
                        <div
                        >
                            {
                                Object.keys(listData).map(item => getInputFieldSection(item, listData))
                            }
                            <NormalButton heightWidth={[40, 430]} type={12} margin={[20, 0, 0, 0]} onClick={() => { generatePayStructure() }} buttonText="Fetch & Update Structure" />
                            {
                                existingStructure && Object.keys(existingStructure).length > 0 ?
                                    <div className="structure-container">
                                        <div className="m-bottom-20 text-center">
                                            <h2>Current Structure</h2>
                                            <p>According to minimum wage act.</p>
                                        </div>
                                        <div className="flex justify-between align-center">
                                            <h3>Basic</h3>
                                            <b>{existingStructure.basic}</b>
                                        </div>
                                        <div className="flex justify-between align-center m-top-10">
                                            <h3>HRA</h3>
                                            <b>{existingStructure.HRA}</b>
                                        </div>
                                        <div className="flex justify-between align-center m-top-10">
                                            <h3>LTA</h3>
                                            <b>{existingStructure.LTA}</b>
                                        </div>
                                        <div className="flex justify-between align-center m-top-10">
                                            <h3>DA</h3>
                                            <b>{existingStructure.DA}</b>
                                        </div>
                                        <div className="flex justify-between align-center m-top-10">
                                            <h3>FOOD</h3>
                                            <b>{existingStructure.FOOD}</b>
                                        </div>
                                        <div className="flex justify-between align-center m-top-10">
                                            <h3>Statutory Bonus</h3>
                                            <b>{existingStructure.statutoryBonuse}</b>
                                        </div>
                                        <div className="flex justify-between align-center m-top-10">
                                            <h3>Special Allowance</h3>
                                            <b>{existingStructure.specialAllowance}</b>
                                        </div>
                                        {
                                            existingStructure && existingStructure.allowances ? Object.keys(existingStructure.allowances).map(item => {
                                                return (
                                                    <div className="flex justify-between align-center m-top-10" key={item}>
                                                        <h3>{camelToSpaceSeparated(item)}</h3>
                                                        <b>{existingStructure.allowances[item]}</b>
                                                    </div>
                                                )
                                            }) : <></>
                                        }
                                        {
                                            existingStructure && existingStructure.customAllowances ? Object.keys(existingStructure.customAllowances).map(item => {
                                                return (
                                                    <div className="flex justify-between align-center m-top-10" key={item}>
                                                        <h3>{camelToSpaceSeparated(item)}</h3>
                                                        <b>{existingStructure.customAllowances[item]}</b>
                                                    </div>
                                                )
                                            }) : <></>
                                        }
                                    </div> : <></>
                            }
                        </div>
                        :
                        (<div
                            className="flex justify-between align-center"
                            style={{
                                backgroundColor: "#d7b47224",
                                color: "#da9711",
                                marginTop: "20px",
                                borderRadius: "5px",
                                padding: "10px"
                            }}
                        >
                            <WarningOutlined style={{ fontSize: "1.5rem", marginRight: "1rem" }} />
                            <p>
                                Note: according to minimum wages act, our system can not found any input fields.
                            </p>
                        </div>)
                }
            </div>
            {/* dynamic Input field goes here */}
            <div className="flex " style={{ marginTop: "5rem", justifyContent: 'end' }} >
                <NormalButton type={5} onClick={onClose} buttonText="Cancel" />
                <NormalButton
                    type={13}
                    disabled={!isPayrollChecked ? true : false}
                    onClick={onSave}
                    buttonText="Apply Structure"
                />
            </div>
        </div>
    );

    const resetUpdatePayStructure = () => {
        setStructure({
            ...structureDuplicate
        })
        setShowModal(false)
    }

    return (
        isTrial ? <TrialPage user={user} trialMessage={"Stay assured. Mool handles any compliance related changes in the law for you."} bgImage={configurationsBgImage}
        /> :

            <section className="site-layout">
                <HeaderComponent name="Minimum Wages Configuration" />
                <div className="app-main-content-container paper-wrapper">
                    <Spinner spinning={loading}>
                        <div>
                            <h2 className="app-heading-1 font-weight-700">
                                Minimum Wages Configuration
                            </h2>
                            {info}
                        </div>
                    </Spinner>
                </div>
                <Drawer
                    width={500}
                    title={<><p className="subtitle3-med-16">{currentData.displayName}</p></>}
                    placement="right"
                    onClose={onClose}
                    visible={open}
                >
                    <div className="section">{fieldSection}</div>
                </Drawer>
                {
                    generatedStructure && structure &&
                    <PayStructureUpdate user={currentData} setShowModal={setShowModal} loading={loading} setLoading={setLoading} showModal={showModal} resetUpdatePayStructure={resetUpdatePayStructure} generatedStructure={generatedStructure} structure={structure} setStructure={setStructure} />
                }

            </section>
    );
};

const MinimumWages = connect(
    (state) => ({
        user: state.auth.user,
        organisationSettings: state.organisation.organisationSettings,
        isTrial: state.auth.user.isTrial,
    }),
    (dispatch) => ({
        organisationReducer: organisation.getActions(dispatch),
    })
)(MinWages);

export default MinimumWages;
