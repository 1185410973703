import ReducerFactory from "../utils/reducerFactory";
import * as organisation from "../../apis/organisation";
import authReducer from "./auth";
import { notification, message } from "antd";
import config from "../../utils/config";
const initialState = {
  loading: false,
  employeeList: [],
  organisationSettings: {},
  payslipDetails: {},
  monthCloseFields: [],
  orgUniqueCode: {},
  freezeInfo: {},
  caUser: {},
  financialYear: "",
  month: 0,
  error: null,
  showAddContdModal: false,
  reimbursements: [],
  moolCoins: { currentBalance: 0 },
  moolCreditsHistoryList: [],
  moolCreditsTransactionList: [],
  organisationFreeze: {},
  moolCoinsSubscription: {
    perUnitValue: 0,
    plan: { name: "" },
  },
  freezeResult: [],
  broadcastMessage: [],
  groups: [],
  location: [],
  department: [],
  designation: [],
  payout: null,
  status: [],
  onboarding: null,
  taxSettlementData: [],
  childOrganisations: [],
  parentOrganisation: null,
  leaveData: {},
  leaveList: [],
  applicationWithApiAccessKey: []
};
const reducerFactory = new ReducerFactory("organisation", initialState);

reducerFactory.addAction(
  "LOADING",
  "loading",
  (status) => status,
  (state, action) => {
    state.loading = action.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "RESET_CA",
  "resetCA",
  (data) => data,
  (state, _action) => {
    state.caUser = {};
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "INITIATE_STATE",
  "initiateState",
  async (data) => {
    const { organisationId } = data;
    reducerFactory.action("loading", true);
    const responseData = await organisation.getOrgInfo(organisationId);
    let res = await organisation.getLeaveListData(data.organisationId);
    if (!res.data) {
      await organisation.creteOrgBlankLeave(data.organisationId)
      res = await organisation.getLeaveListData(data.organisationId);
    }
    const {
      employeeList,
      monthCloseData,
      user,
      caUser,
      orgUniqueCode,
      freezeInfo,
      moolcoins,
      location,
      groups,
      department,
      designation,
      payout,
      status,
      payslipFreeze,
      broadcast,
      reimbursement,
      organisationFreeze,
      employeeTaxSettlement,
      childOrganisations,
      parentOrganisation

    } = responseData.data;
    const onboarding = await organisation.getEmployerOnBoarding(
      responseData.data.user.organisationId,
      responseData.data.user._id
    );
    return {
      organisationSettings: user.organisationSettings,
      employeeList,
      monthCloseData,
      orgUniqueCode,
      caUser,
      location,
      groups,
      department,
      designation,
      organisationFreeze,
      payout,
      status,
      payslipFreeze,
      broadcast,
      reimbursement,
      orgCode: orgUniqueCode,
      payslipFreezeInfo: freezeInfo,
      moolCoins: moolcoins,
      onboarding: onboarding.data,
      employeeTaxSettlement,
      childOrganisations,
      parentOrganisation,
      leaveData: res.data,
    };
  },
  (state, action) => {
    const { month, financialYear } = config.checkFinancialYear();
    state.financialYear = financialYear;
    state.month = month;
    state.organisationSettings = action.data.organisationSettings;
    state.monthCloseFields = action.data.monthCloseData;
    state.freezeInfo = action.data.payslipFreezeInfo;
    state.orgUniqueCode = action.data.orgCode;
    state.employeeList = action.data.employeeList;
    state.caUser = action.data.caUser;
    state.location = action.data.location;
    state.groups = action.data.groups;
    state.department = action.data.department;
    state.organisationFreeze = action.data.organisationFreeze;
    state.designation = action.data.designation;
    state.payout = action.data.payout;
    state.status = action.data.status;
    state.freezeResult = action.data.payslipFreeze;
    if (action.data.moolCoins && Object.keys(action.data.moolCoins).length !== 0) {
      state.moolCoins = action.data.moolCoins;
    } else {
      state.moolCoins = { currentBalance: 0 };
    }
    state.reimbursements = action.data.reimbursement;
    state.loading = false;
    state.broadcastMessage = action.data.broadcast;
    state.loading = false;
    state.onboarding = action.data.onboarding;
    state.taxSettlementData = action.data.employeeTaxSettlement
    state.childOrganisations = action.data.childOrganisations
    state.parentOrganisation = action.data.parentOrganisation;
    state.leaveData = action.data.leaveData;
    let leaveList = Object.keys(action.data.leaveData.leaveTypes).map(
      (val) => action.data.leaveData.leaveTypes[val]
    );
    state.leaveList = [...leaveList];
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_EMPLOYEE_LIST",
  "updateEmployeeList",
  async (data) => {
    const { organisationId } = data;
    let employeeList = await organisation.fetchEmployees({ organisationId });
    return { employeeList };
  },
  (state, action) => {
    state.employeeList = action.data.employeeList.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_GROUPS_LIST",
  "updateGroupsList",
  async () => {
    const groupsData = await organisation.getAllGroups();
    return { groupsData };
  },
  (state, action) => {
    state.groups = action.data.groupsData.data;
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_CHILD_ORG_LIST",
  "updateChildOrganisationList",
  async () => {
    const childOrgDetails = await organisation.getChildOrgs();
    return { childOrgDetails };
  },
  (state, action) => {
    state.childOrganisations = action.data.childOrgDetails.data;
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_TAXSETTLEMENT_LIST",
  "updateTaxSettlement",
  async (data) => {
    const { organisationId, financialYear } = data
    const employeeTaxSettlement = await organisation.getTaxSettlementData(organisationId, financialYear);
    return { employeeTaxSettlement };
  },
  (state, action) => {
    state.taxSettlementData = action.data.employeeTaxSettlement.data;
    state.loading = false;
    return Object.assign({}, state);
  }
);


reducerFactory.addAction(
  "FREEZE_RESULT",
  "freezeResult",
  async (data) => {
    let freezeResult = await organisation.getAllPayslipFreeze(data);
    return { freezeResult };
  },
  (state, action) => {
    state.freezeResult = action.data.freezeResult.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "LOADING",
  "loading",
  (data) => data,
  (state, action) => {
    state.loading = action.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "HIDE_ADD_CONTD_MODAL",
  "hideAddContdModal",
  (status) => status,
  (state, _action) => {
    state.showAddContdModal = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_SETTINGS",
  "updateSettings",
  async (data) => {
    let res = await organisation.updateSettings(data);
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      state.organisationSettings = action.data.data.organisationSettings;
      notification.success({
        message: "Successfully updated organistion settings",
      });
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_ORG_SETTINGS",
  "orgSettingsUpdate",
  async (data) => data,
  (state, action) => {
    state.organisationSettings = action.data.organisationSettings;
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "FETCH_UPDATE_ORG_SETTINGS",
  "fetchAndUpdateOrganisationSettings",
  async (data) => {
    let res = await organisation.fetchOrganisationSettings(data);
    if (res.data.success) {
      return res.data.data
    } else {
      return {}
    }
  },
  (state, action) => {
    state.organisationSettings = action.data;
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "ONBOARD_EMPLOYEES",
  "onboardEmployees",
  async (data) => {
    let res = await organisation.uploadEmployees(data);
    if (res.data.success) {
      authReducer.action("check");
      reducerFactory.action("fetchEmployees", {
        organisationId: res.data.data.organisationId,
        pageNumber: 1,
        pageSize: 10,
      });
    }
    return res.data;
  },
  (state, action) => {
    if (action.data.success) {
      message.success("Successfully onboarded employees");
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "FETCH_EMPLOYEES",
  "fetchEmployees",
  async (data) => {
    let res = await organisation.fetchEmployees(data);
    return res;
  },
  (state, action) => {
    if (action.data.status === 200 && typeof action.data.data === "object") {
      state.employeeList = action.data.data;
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "ADD_MCOLSE_FIELD",
  "addMonthClose",
  async (data) => {
    let res = await organisation.addMonthClose(data);
    let monthCloseData = await organisation.getMcloseData(
      data.monthClose.organisationId
    );
    reducerFactory.action("check");
    return { data: res.data, monthCloseData };
  },
  (state, action) => {
    state.monthCloseFields = action.data.monthCloseData.data;
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_MONTH_CLOSE",
  "updateMonthClose",
  async (data) => {
    let url = data.organisationId;
    let financialYear = data.financialYear;
    if (financialYear) {
      url += "/" + financialYear;
    }
    let monthCloseData = await organisation.getMcloseData(url);
    return { monthCloseData };
  },
  (state, action) => {
    state.monthCloseFields = action.data.monthCloseData.data;
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "ORG_REIMBURSEMENT",
  "orgReimbursement",
  async (data) => {
    const { organisationId, userRole, PAN } = data;
    if (userRole === "org") {
      let res = await organisation.getOrgReimbursement(organisationId);
      return res.data;
    } else {
      let res = await organisation.managerReimbursement({
        PAN,
        organisationId,
      });
      return res.data;
    }
  },
  (state, action) => {
    if (action.data) {
      const reimbursements = action.data;
      state.reimbursements = reimbursements;
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_MOOL_CREDITS_HISTORY_LIST",
  "updateMoolCreditsHistoryList",
  async (data) => {
    let historyList = await organisation.getMoolCoinRequests(data);
    return { historyList };
  },
  (state, action) => {
    state.moolCreditsHistoryList = action.data.historyList.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_MOOL_CREDITS_TRANSACTIONS_LIST",
  "updateMoolCreditsTransactionsList",
  async (data) => {
    let historyList = await organisation.getMoolCoinTransactions(data);
    return { historyList };
  },
  (state, action) => {
    state.moolCreditsTransactionList = action.data.historyList.data;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_MOOL_COINS_SUBSCRIPTION",
  "updateMoolCoinsSubscription",
  async (data) => {
    let coinsSubscription = await organisation.getMoolCoinsSubscription(data);
    return { coinsSubscription };
  },
  (state, action) => {
    if (Object.keys(action.data.coinsSubscription.data).length !== 0) {
      state.moolCoinsSubscription = action.data.coinsSubscription.data;
    } else {
      state.moolCoinsSubscription = {
        perUnitValue: 0,
        plan: { name: "" },
      };
    }
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "ORGANISATION_BROADCAST_MESSAGE",
  "organisationBroadcastMessage",
  async (data) => {
    let res = await organisation.getBroadcastMessages(data);
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      state.broadcastMessage = action.data;
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "GET_LOCATION",
  "getLocation",
  async (data) => {
    let res = await organisation.getLocationByOrgId(data);
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      state.location = action.data;
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "GET_STATUS",
  "getStatus",
  async (data) => {
    let res = await organisation.statusByOrgId(data);
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      state.status = action.data;
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "GET_DESIGNATION",
  "getDesignation",
  async (data) => {
    let res = await organisation.designationByOrgId(data);
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      state.designation = action.data;
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "GET_DEPARTMENT",
  "getDepartment",
  async (data) => {
    let res = await organisation.departmentByOrgId(data);
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      state.department = action.data;
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_FREEZE",
  "updateFreeze",
  async (data) => {
    let res = await organisation.getFreeze(data);
    return res.data;
  },
  (state, action) => {
    if (action.data) {
      state.organisationFreeze = action.data;
    }
    state.loading = false;
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE_EMPLOYER_ONBOARDING",
  "updateEmployerOnBoarding",
  async (data) => {
    const { organisationId, id } = data;
    let res = await organisation.getEmployerOnBoarding(organisationId, id);
    return res.data;
  },
  (state, action) => {
    if (action.data || action.data === "") {
      state.onboarding = action.data;
    }
    return Object.assign({}, state);
  }
);
reducerFactory.addAction(
  "GET_MINIMUM_WAGES_EMPLOYEES",
  "getMinimumWagesEmployees",
  async (data) => {
    let res = await organisation.getAllEmployeesAccordingToMinWages(data);
    return res.data;
  },
  (state, action) => {
    return Object.assign({}, state);
  }
);

reducerFactory.addAction(
  "UPDATE LEAVE DATA",
  "updateLeaveData",
  async (data) => {
    let res = await organisation.getLeaveListData(data);
    return {
      leaveData: res.data,
    };
  },
  (state, action) => {
    state.leaveData = action.data.leaveData;
    let leaveList = Object.keys(action.data.leaveData.leaveTypes).map(
      (val) => action.data.leaveData.leaveTypes[val]
    );
    state.leaveList = [...leaveList];
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_APPLICATION_WITH_API_KEYS",
  "fetchApplicationWithApiKeys",
  async (organisationId) => {
    let res = await organisation.fetchApplicationsListWithApiAccessToken(organisationId);
    return {
      applicationWithApiAccessKey: res.data.data,
    };
  },
  (state, action) => {
    state.applicationWithApiAccessKey = action.data.applicationWithApiAccessKey;
    const newState = Object.assign({}, state);
    return newState;
  }
);

export default reducerFactory;
