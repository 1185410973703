import { Table } from "antd";
import { connect } from "react-redux";
import { HeaderComponent } from "../../Common";
import { withRouter } from "react-router";
import { useEffect, useState } from "react";
import { NormalButton } from "../../ComponentLibrary";
import employee from "../../../redux/modules/employee";
import ApplyLeave from "../ApplyLeave/ApplyLeave";

const LeaveReport = ({
  leaveLogs,
  leaveList,
  leaveBalanceReport,
  user,
  employeeReducer,
  state
}) => {
  const status = ["Pending", "Approved", "Rejected"];
  const [modal, setModal] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [leavesReport, setLeavesReport] = useState([]);

  useEffect(
    () => {
      let leaves = leaveLogs.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setLeavesReport([...leaves]);
    },
    [leaveLogs,]
  );

  useEffect(() => {
    employeeReducer.updateLeaveLogs({
      id: user._id,
      organisationId: user.organisationId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);

  const columns = [
    {
      title: "Name",
      dataIndex: ["user", "displayName"],
      key: "name"
    },
    {
      title: "Type",
      dataIndex: ["leaveType", "name"],
      key: "type"
    },
    {
      title: "Leave",
      dataIndex: "startDate",
      key: "leave",
      render: (data, row) =>
        <p>{`${new Date(data).toDateString()} - ${new Date(
          row.endDate
        ).toDateString()}`}</p>
    },
    {
      title: "Requested",
      dataIndex: "createdAt",
      key: "requested",
      render: data =>
        <p>
          {new Date(data).toDateString()}
        </p>
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "approved",
      render: data =>
        <p className={`approved-${data}`}>
          {status[data]}
        </p>
    },
    {
      title: "Days",
      dataIndex: "duration",
      key: "days",
      render: (key) => {
        return (
          <p>
            {key}
          </p>
        );
      }
    }
  ];
  const startRefresh = () => {
    setIsRefresh(!isRefresh)
  }
  useEffect(() => {
    document.title = 'Attendance | Leave Reports';
  });
  return (
    <div className="site-layout">
      <HeaderComponent
        name={"Leave Reports"}
      />
      <div className="app-main-content-container">
        <div className="paper-wrapper">
          <div className="paper-header-container">
            <h1 className="paper-heading">Leave Report</h1>
            <div>
              <NormalButton
                buttonText={"Refresh"}
                type={13}
                onClick={startRefresh}
              />
              <NormalButton buttonText={"Apply For Leave"} type={13} onClick={() => setModal(true)} />
            </div>
          </div>
          <ApplyLeave
            leaveList={leaveList}
            leaveBalanceReport={leaveBalanceReport}
            user={user}
            employeeReducer={employeeReducer}
            modal={modal}
            setModal={setModal}
          />
          <Table
            pagination={6}
            columns={columns}
            dataSource={leavesReport}
            expandable={{
              expandedRowRender: record =>
                <p style={{ margin: 0 }}>
                  {record.comments}
                </p>,
              rowExpandable: record => record.comments !== ""
            }}
          />
        </div>
      </div>
    </div>
  );
};

const LeaveReportComponent = connect(state => ({
  user: state.auth.user,
  leaveLogs: state.employee.leaveLogs,
  leaveList: state.employee.leaveList,
  leaveBalanceReport: state.employee.leaveBalanceReport,
}), dispatch => ({
  employeeReducer: employee.getActions(dispatch)
}))(LeaveReport);

export default withRouter(LeaveReportComponent);
