import React from "react";
import { HeaderComponent } from "../Common";
import activeEmployee from "../../assets/icons/activeIcons/activeEmployee.svg";
import { Row, Col } from "antd";
import EmployeeInfo from "./Components/EmployeInfoContainer";
import EmployeeDetails from "./Components/employeeDetails";
import auth from "../../redux/modules/auth";
import employee from "../../redux/modules/employee";
import { connect } from "react-redux";
import { updateEmployeeOnBoarding } from "../../apis/employee";

const employeeProfile = ({
  user,
  authReducer,
  image,
  separations,
  orgInfo,
  familyDetail,
  address,
  document,
  credentials,
  contactInfo,
  employeeReducer,
  bankInformation,
  department,
  status,
  designation,
  groups,
  location,
  onboarding,
  supervisor
}) => {

  const updateOnboarding = async (type) => {
    if (onboarding && !onboarding.fields[type]) {
      let fields = {};
      fields[type] = true;
      await updateEmployeeOnBoarding({
        fields,
        organisationId: user.organisationId,
        id: user._id,
      });
      employeeReducer.updateEmployeeOnBoarding({
        organisationId: user.organisationId,
        id: user._id,
      });
    }
  };
  return (
    <div className="site-layout">
      <HeaderComponent
        show={false}
        showMoolCoins={false}
        name="Profile"
        icon={activeEmployee}
      />
      <div className="app-main-content-container">
        <Row gutter={[20, 20]}>
          <Col sm={24} xs={24} md={6} lg={6}>
            <EmployeeInfo
              isEmployeeView={true}
              employeeReducer={employeeReducer}
              user={user}
              authReducer={authReducer}
              image={image}
              separations={separations}
              orgInfo={orgInfo}
              department={department}
              statusName={status}
              designation={designation}
              groups={groups}
              location={location}
              supervisor={supervisor}
              updateOnboarding={updateOnboarding}
            />
          </Col>
          <Col sm={24} xs={24} md={18} lg={18}>
            <EmployeeDetails
              separations={separations}
              employeeReducer={employeeReducer}
              onboarding={onboarding}
              user={user}
              address={address}
              familyDetail={familyDetail}
              authReducer={authReducer}
              document={document}
              credentials={credentials}
              contactInfo={contactInfo}
              bankInformation={bankInformation}
              updateOnboarding={updateOnboarding}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const EmployeeProfileContainer = connect(
  (state) => ({
    user: state.auth.user,
    address: state.auth.address,
    image: state.auth.image,
    separations: state.employee.separations,
    orgInfo: state.employee.orgInfo,
    familyDetail: state.auth.familyDetails,
    document: state.auth.document,
    credentials: state.auth.credentials,
    contactInfo: state.auth.contactInfo,
    bankInformation: state.auth.bankInfo,
    department: state.employee.department,
    status: state.employee.status,
    designation: state.employee.designation,
    groups: state.employee.groups,
    location: state.employee.location,
    settlement: state.employee.settlement,
    onboarding: state.employee.onboarding,
    supervisor: state.employee.supervisor,
  }),
  (dispatch) => ({
    authReducer: auth.getActions(dispatch),
    employeeReducer: employee.getActions(dispatch),
  })
)(employeeProfile);
export default EmployeeProfileContainer;
