import { withRouter } from "react-router";
import employeeLogo from "../../../assets/OrganisationDashboardd/employee.svg";
import pfLogo from "../../../assets/OrganisationDashboardd/pf.svg";
import tdsLogo from "../../../assets/OrganisationDashboardd/tds.svg";
import config, { numformatter } from "../../../utils/config";
import { NormalButton } from "../../ComponentLibrary";
import { Row, Col } from "antd";
import moment from "moment";
import { categoryMappring, paths } from "../../../constants/routes";
const PayrollAndDeductionCards = ({
  numberOfEmployee,
  history,
  totalPay,
  employees,
  createdAt,
  pf,
  taxs,
  authReducer,
  user,
}) => {
  const renderComponent = () => {
    authReducer.updateCurrentTab("payroll_history");
    authReducer.updateSelectedCategory(
      categoryMappring[user.role]["payroll_history"]
    );
    history.push(paths[user.role]["payroll_history"]);
  };

  return (
    <div className="payroll-and-deduction-cards ">
      {totalPay > -1 && (
        <div>
          <h5 className="h6-bold-18 m-bottom-15">Process Payroll</h5>
          <div className="border-left-blue app-white-card-1 flex justify-between p-36 m-bottom-36">
            <div style={{width:"25%"}} className="break-word">
              <h1 className="font-size-16 color-grey font-weight-500 m-bottom-4">
                Total Employee Pay
              </h1>
              <p className="h5-bold-20">{config.numformatter(totalPay)} INR</p>
            </div>
            <div className="flex">
              <div className="separator"></div>
              <div className="m-left-36 break-word">
                <h1 className="font-size-16 color-grey font-weight-500 m-bottom-4">
                  No. of Employees
                </h1>
                <p className="h5-bold-20">{employees}</p>{" "}
              </div>
            </div>
            {moment(createdAt).format("MM") === moment().format("MM") && (
              <div className="flex">
                <div className="separator"></div>
                <div className="m-left-36">
                  <h1 className="font-size-14 color-grey font-weight-500">
                    Last Run Date
                  </h1>
                  <p className="h6-bold-18">{createdAt}</p>{" "}
                </div>
              </div>
            )}
            <div className="flex flex-direction-column justify-center">
              {
                <NormalButton
                  type={13}
                  buttonText={
                    moment(createdAt).format("MM") === moment().format("MM")
                      ? "View Details"
                      : "Explore"
                  }
                  onClick={renderComponent}
                />
              }
            </div>
          </div>
        </div>
      )}
      <div>
        <h5 className="h6-bold-18 m-bottom-15">Deductions</h5>{" "}
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={17}>
            <div className="paper-wrapper flex justify-between h-full break-word">
              <div className="flex align-center" style={{ width: "45%" }}>
                <img src={tdsLogo} height="60" width="60" alt="employees"></img>
                <div className="m-left-24">
                  <h1 className="font-size-16 color-grey font-weight-500">
                    TDS
                  </h1>
                  <p className="h5-bold-20">{numformatter(taxs)} INR</p>
                </div>
              </div>
              <div className="separator "></div>
              <div className="flex align-center" style={{ width: "45%" }}>
                <img src={pfLogo} height="60" width="60" alt="employees"></img>
                <div className="m-left-24">
                  <h1 className="font-size-16 color-grey font-weight-500">
                    PF
                  </h1>
                  <p className="h5-bold-20">{numformatter(pf)} INR</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={7}>
            <div className="paper-wrapper flex justify-start h-full align-center">
              <img
                src={employeeLogo}
                height="60"
                width="60"
                alt="employees"
              ></img>
              <div className="m-left-24">
              <h1 className="font-size-16 color-grey font-weight-500">
                  Employee
                </h1>
                <p className="h5-bold-20">{numberOfEmployee}</p>{" "}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(PayrollAndDeductionCards);
