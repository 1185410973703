import { useState } from "react";
import { Layout, Button, Radio, notification } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { withRouter } from "react-router-dom";
import config from "../../../../utils/config";
import InputNumberComponent from "../../../InputLibrary/InputNumberComponent/InputNumberComponent";
import DropdownComponent from "../../../InputLibrary/DropdownComponent/DropdownComponent";
import {
  options,
  orgPFContribution,
} from "../../../../constants/radioOptions";
const { Content } = Layout;

const OrganisationSetting = ({user, organisationReducer, changePageState}) => {
  const [loading, setLoading] = useState(false);
  const localStorageOrgData = JSON.parse(
    localStorage.getItem("localOrgSettings")
  );
  const [orgSettings, setOrgSettings] = useState({
    allowances: localStorageOrgData
      ? localStorageOrgData.allowances
      : user.organisationSettings.allowances,
    customAllowances: localStorageOrgData
      ? localStorageOrgData.customAllowances
      : user.organisationSettings.customAllowances,
    gratuity: localStorageOrgData ? localStorageOrgData.gratuity : true,
    pfEnabled: localStorageOrgData ? localStorageOrgData.pfEnabled : true,
    gratuityTenure: localStorageOrgData
      ? localStorageOrgData.gratuityTenure
      : 5,
    payrollFreezeDate: localStorageOrgData
      ? localStorageOrgData.payrollFreezeDate
      : 15,
    variableAboveCTC: localStorageOrgData
      ? localStorageOrgData.variableAboveCTC
      : false,
    variablePayoutMonth: localStorageOrgData
      ? localStorageOrgData.variablePayoutMonth
      : 3,
    organisationPFContribution: localStorageOrgData
      ? localStorageOrgData.organisationPFContribution
      : false,
    employeePFContribution: localStorageOrgData
      ? localStorageOrgData.employeePFContribution
      : false,
    pfContibutionType: localStorageOrgData
      ? localStorageOrgData.pfContibutionType
      : true,
    orgPFContribution: localStorageOrgData
      ? localStorageOrgData.orgPFContribution
      : false,
    percentageStructure: localStorageOrgData
      ? localStorageOrgData.percentageStructure
      : {},
    autoRebalance: localStorageOrgData
      ? localStorageOrgData.autoRebalance
      : false,
    esicEnabled: localStorageOrgData ? localStorageOrgData.esicEnabled : true,
    isEmployerContributionIncludedInCTC: localStorageOrgData
      ? localStorageOrgData.isEmployerContributionIncludedInCTC
      : true,

    isStatutoryBonusIncludedInSalary: localStorageOrgData
      ? localStorageOrgData.isStatutoryBonusIncludedInSalary
      : true,

    isWeekOffsExcludedInLOP: localStorageOrgData
      ? localStorageOrgData.isWeekOffsExcludedInLOP
      : false,
    isHolidayExcludedInLOP: localStorageOrgData
      ? localStorageOrgData.isHolidayExcludedInLOP
      : false,
    isStatutoryBonusIncludeHRA: localStorageOrgData
      ? localStorageOrgData.isStatutoryBonusIncludeHRA
      : false,
    isStatutoryBonusIncludedInPF: localStorageOrgData
      ? localStorageOrgData.isStatutoryBonusIncludedInPF
      : true,
    isLopCalculatedFromCTC: localStorageOrgData
      ? localStorageOrgData.isHolidayExcludedInLOP
      : true,
    salaryCycleDate: localStorageOrgData
      ? localStorageOrgData.isHolidayExcludedInLOP
      : -1,
    isCurrentMonth: localStorageOrgData
      ? localStorageOrgData.isHolidayExcludedInLOP
      : true,
    lwfEligible: localStorageOrgData
      ? localStorageOrgData.isHolidayExcludedInLOP
      : true,
  });

  const setOrganisationSettings = async () => {
    if (orgSettings.gratuity && !orgSettings.gratuityTenure) {
      notification.error({ message: "Fill all the required fields" });
      return;
    }
    setLoading(true);
    organisationReducer.updateSettings({
      organisationSettings: orgSettings,
      organisationId: user.organisationId,
    });
    localStorage.setItem("localOrgSettings", JSON.stringify(orgSettings));
    changePageState();
    setLoading(false);
  };

  return (
    <Content className="stages">
      <div className="configuration-container">
        <p className="configuration-header-display">
          You can always modify them later
        </p>
        <div className="org-setting-container">
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Is gratuity a part of your employee's CTC?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({ ...orgSettings, gratuity: e.target.value })
              }
              value={orgSettings.gratuity}
            />
          </div>
          <div className="radio-group">
            {orgSettings.gratuity && (
              <InputNumberComponent
                width={"100%"}
                maxWidth={"35rem"}
                value={orgSettings.gratuityTenure}
                onChange={(value) =>
                  setOrgSettings({ ...orgSettings, gratuityTenure: value })
                }
                min={"1"}
                max={"50"}
                margin={["10", "0", "10", "0"]}
                disabled={!orgSettings.gratuity}
                labeltext={"Eligibility (in years)"}
              />
            )}
          </div>
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Does your company hand out LWF to its employees?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  lwfEligible: e.target.value,
                })
              }
              value={orgSettings.lwfEligible}
            />
          </div>
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Does your company hand out PF to its employees?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  pfEnabled: e.target.value,
                  pfContibutionType: !e.target.value
                    ? true
                    : orgSettings.pfContibutionType,
                  orgPFContribution: !e.target.value
                    ? false
                    : orgSettings.orgPFContribution,
                })
              }
              value={orgSettings.pfEnabled}
            />
          </div>
          {orgSettings.pfEnabled && (
            <div className="radio-group">
              <p className="fieldQuestionsHeading">PF Type</p>
              <div className="pfcard-container">
                <div
                  className="site-card-border-less-wrapper"
                  onClick={() => {
                    setOrgSettings({
                      ...orgSettings,
                      pfContibutionType: true,
                      orgPFContribution: false,
                    });
                  }}
                >
                  <Card
                    className={`pfcard ${
                      orgSettings.pfContibutionType ? "active" : ""
                    }`}
                    title="Mool recommended"
                    bordered={false}
                  >
                    <p>
                      Allow your employees to choose their PF contributions
                      between maximum inhand and maximum savings
                    </p>
                  </Card>
                </div>
                <div
                  className="site-card-border-less-wrapper"
                  onClick={() => {
                    setOrgSettings({
                      ...orgSettings,
                      pfContibutionType: false,
                      orgPFContribution: orgSettings.orgPFContribution,
                    });
                  }}
                >
                  <Card
                    className={`pfcard ${
                      orgSettings.pfContibutionType ? "" : "active"
                    }`}
                    title="Custom"
                    bordered={false}
                  >
                    <p>
                      Centrally define PF contribution rate for all employees.
                      Note: this reduces flexibility offered to employees
                    </p>
                  </Card>
                </div>
              </div>
            </div>
          )}
          {!orgSettings.pfContibutionType && (
            <div className="radio-group employeecontributionrate">
              <div className="employeecontributionrate-cont">
                <p className="fieldQuestionsHeading">
                  Employee Contribution Rate
                </p>
                <Radio.Group
                  options={orgPFContribution}
                  onChange={(e) =>
                    setOrgSettings({
                      ...orgSettings,
                      orgPFContribution: e.target.value,
                    })
                  }
                  value={orgSettings.orgPFContribution}
                />
              </div>
            </div>
          )}
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Does your company enable ESIC?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  esicEnabled: e.target.value,
                })
              }
              value={orgSettings.esicEnabled}
            />
          </div>
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Do your company gives variable above CTC?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  variableAboveCTC: e.target.value,
                })
              }
              value={orgSettings.variableAboveCTC}
            />
          </div>
          <div className="radio-group">
            <DropdownComponent
              width={"100%"}
              maxWidth={"35rem"}
              labeltext={"Variable Payout Month"}
              filterOption={(input, option) =>
                option.children &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  variablePayoutMonth: e,
                })
              }
              options={config.monthList}
              value={orgSettings.variablePayoutMonth}
            />
          </div>
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Do you want to opt-in company for auto rebalancing of taxes?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  autoRebalance: e.target.value,
                })
              }
              value={orgSettings.autoRebalance}
            />
          </div>
          <div className="info-para">
            <p>
              <InfoCircleOutlined style={{ marginRight: "10px" }} />
              Switching on auto-rebalancing will reconcile employees’ tax
              dynamically based on the remaining months of the financial year
            </p>
          </div>
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Is employer contribution included in CTC?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  isEmployerContributionIncludedInCTC: e.target.value,
                })
              }
              value={orgSettings.isEmployerContributionIncludedInCTC}
            />
          </div>
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Is statutory bonus included in salary?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  isStatutoryBonusIncludedInSalary: e.target.value,
                })
              }
              value={orgSettings.isStatutoryBonusIncludedInSalary}
            />
          </div>
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Is WeeklyOff Excluded In LOP?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  isWeekOffsExcludedInLOP: e.target.value,
                })
              }
              value={orgSettings.isWeekOffsExcludedInLOP}
            />
          </div>
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Is Holidays Excluded In LOP?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  isHolidayExcludedInLOP: e.target.value,
                })
              }
              value={orgSettings.isHolidayExcludedInLOP}
            />
          </div>
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Is Statutory Bonus Includes HRA?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  isStatutoryBonusIncludeHRA: e.target.value,
                })
              }
              value={orgSettings.isStatutoryBonusIncludeHRA}
            />
          </div>
          <div className="radio-group">
            <p className="fieldQuestionsHeading">
              Is Statutory Bonus Included In PF Salary?
            </p>
            <Radio.Group
              options={options}
              onChange={(e) =>
                setOrgSettings({
                  ...orgSettings,
                  isStatutoryBonusIncludedInPF: e.target.value,
                })
              }
              value={orgSettings.isStatutoryBonusIncludedInPF}
            />
          </div>

          {/* <div className="radio-group">
              <p className="app-label-2">Organisation PF Contribution</p>
              <Radio.Group
                options={organisationPFContribution}
                onChange={(e) =>
                  setOrgSettings({
                    ...orgSettings,
                    organisationPFContribution: e.target.value,
                  })
                }
                value={orgSettings.organisationPFContribution}
              />
            </div>
            <div className="radio-group">
              <p className="app-label-2">Employee PF Contribution</p>
              <Radio.Group
                options={employeePFContribution}
                onChange={(e) =>
                  setOrgSettings({
                    ...orgSettings,
                    employeePFContribution: e.target.value,
                  })
                }
                value={orgSettings.employeePFContribution}
              />
            </div> */}
          {/* <div className="radio-group">
              <p>Salary Freeze Date</p>
              <InputNumber
                className="configuration-input-field"
                size="large"
                min={1}
                max={31}
                value={orgSettings.payrollFreezeDate}
                onChange={(value) =>
                  setOrgSettings({ ...orgSettings, payrollFreezeDate: value })
                }
              />
            </div> */}
        </div>
      </div>
      <div className="submit-container">
        <Button
          loading={loading}
          className="submit-org"
          onClick={setOrganisationSettings}
          style={{
            background: "#405cd2",
            padding: "15px",
            height: "60px",
            fontSize: "1.125rem",
          }}
          htmlType="submit"
        >
          Save and Continue
        </Button>
      </div>
    </Content>
  );
};

export default withRouter(OrganisationSetting);