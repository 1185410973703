import "./inputField.less";

const InputField = ({
    block,
    background,
    value,
    type,
    name,
    onChange,
    border,
    placeholder,
    disabled,
    maxLength,
    min,
    max,
    fieldType,
    width,
    className
}) => {

    let styles = {
        cursor: disabled ? "default" : "",
        display: block ? "block" : "inline-block",
        backgroundColor: background
            ? background === "transparent"
                ? "transparent"
                : background
            : "",
        borderBottom: border ? `${border} 2px solid` : "",
        width: width ? width : "",
    }

    return (
        <input
            className={`input-field-container input-field-${fieldType} ${className}`}
            style={styles}
            value={value}
            type={type}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            autoComplete={"off"}
            min={min}
            max={max}
        >

        </input>
    )
}

export default InputField;