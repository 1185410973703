// import { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
// import { getAdsByTargeting } from "../../../apis/organisation";
import SwiperCore, { Autoplay } from "swiper/core";
import { Swiper } from "swiper/react";
import "swiper/swiper.less";
import blueFolder from "../../../assets/organisationDashboard/blueFolder.svg"
import { paths } from "../../../constants/routes";

SwiperCore.use([Autoplay]);
const BroadcastMessageAndAdvertisement = ({ broadcastMessage, authReducer, user }) => {
  // const [adDetails, setAdDetails] = useState([]);
  // useEffect(() => {
  //   getCampaign();
  // }, []);
  // const getCampaign = async () => {
  //   let response = await getAdsByTargeting(1);
  //   setAdDetails(response.data);
  // };
  // let photos = [];
  // adDetails.forEach((element) => {
  //   photos.push(element.ad.graphicsUrl);
  // });

  const renderComponent = () => {
    authReducer.updateCurrentTab("broadcast")
    authReducer.updateSelectedCategory("common")
  }
  return (
    <>
      <div className="paper-wrapper">
        <div className="header-component">
          <h3 className="font-size-20 font-weight-700 m-bottom-12">Broadcast Messages</h3>

          <Link onClick={renderComponent} to={paths[user.role].broadcast} className="header-link">
            View All
          </Link>
        </div>
        {
          broadcastMessage.length > 0
            ? <>
              {broadcastMessage
                .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
                .slice(0, 4)
                .map((val, index) => (
                  <BroadCastList key={`broadcast-${index}`} {...val} />
                ))}
            </>
            : <div className="text-align-center py-36">
              <img alt="img" src={blueFolder}></img>
            </div>
        }
        <div>
          <h1 style={{ textAlign: "center", color: "#5e5f62" }}>{`${broadcastMessage ? "" : "No Messages"}`}</h1>

        </div>
      </div>

      <div className="ads-container">
        <Swiper autoplay={{ delay: 3000 }}>
          {
            // photos.map((item) =>
            //   (
            //   <SwiperSlide>
            //     <div className="photo-holder">
            //         <img src={item} alt="Ads not available" />
            //     </div>
            //   </SwiperSlide>
            //   )
            // )

            // <>
            //   <SwiperSlide>
            //     <div className="photo-holder">
            //       <img src="https://i.picsum.photos/id/222/780/120.jpg?hmac=N1xyi6pjcQOfJKdtxmaMasB-CVzXBZdtyMowhAHw-Gk" alt="Ads not available" />
            //     </div>
            //   </SwiperSlide>
            //   <SwiperSlide>
            //     <div className="photo-holder">
            //       <img src="https://i.picsum.photos/id/308/780/120.jpg?hmac=k3I_1aI8PEBKWxJczRDRsdlTVAGrq4swZJndcoZIIaA" alt="Ads is not available" />
            //     </div>
            //   </SwiperSlide>
            //   <SwiperSlide>
            //     <div className="photo-holder">
            //       <img src="https://i.picsum.photos/id/862/780/120.jpg?hmac=5ON8vUk7GsBaYr1pLknXG5X8_J8JBhrUiQLUAhTNwrg" alt="Ads  43 not available" />
            //     </div>
            //   </SwiperSlide>
            // </>
          }
        </Swiper>
      </div>
    </>
  );
};

const BroadCastList = ({ updatedAt, title, message }) => (
  <div
    style={{ marginBottom: 20 }}
    className="flex justify-between align-center"
  >
    <div>
      <p style={{ fontWeight: 600, fontSize: 15 }}>{title}</p>
      <p className="app-label-3">{message}</p>
    </div>
    <p className="app-label-4">{moment(updatedAt).format("ll")}</p>
  </div>
);

export default BroadcastMessageAndAdvertisement;
