import ReducerFactory from "../utils/reducerFactory";

const initialState = {
    deviceWidth: window.innerWidth,
    deviceHeight: window.innerHeight,
}

const reducerFactory = new ReducerFactory("responsive", initialState);

reducerFactory.addAction(
    "UPDATE_SCREEN_RESOLUTION",
    "updateScreenResolution",
    () => { },
    (state, action) => {
        state.deviceWidth = window.innerWidth;
        state.deviceHeight = window.innerHeight;
        const newState = Object.assign({}, state);
        return newState;
    }
);

export default reducerFactory;